import React, { useEffect, useRef, useState } from "react";
import "./clientPurchaseOrder.scss";
import axios from "axios";
import Snacky from "../../Shared/Snacky";
import { isEmpty } from "../../../utils/GeneralUtils";
import { Skeleton } from "@material-ui/lab";
import NewPurchaseOrderButton from "./NewPurchaseOrderButton";
import PurchaseOrderTable from "./PurchaseOrderTable";
import PurchaseOrderForm from "./PurchaseOrderForm";
import ConfirmationDialog from "../../Shared/ConfirmationDialog";
import { useAuth } from "../../../context/auth/AuthContext";

const ClientPurchaseOrder = ({
  budgetItems,
  companyId,
  fetchAllBudget,
  baseCurrency = "NZD",
}) => {
  const { user } = useAuth();
  const bottomElementRef = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSavingForm, setIsSavingForm] = useState(false);
  const [openNotEnoughBudgetDialog, setOpenNotEnoughBudgetDialog] =
    useState(false);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null);
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });
  //Infinite Scroll

  useEffect(() => {
    if (!isLoading && hasMoreData && !isLoadingMore) {
      const observer = new IntersectionObserver(
        async (entries) => {
          if (entries[0].isIntersecting) {
            setIsLoadingMore(true); // prevent making multiple calls
            await searchPurchaseOrders();
            setIsLoadingMore(false);
          }
        },
        { threshold: [1] }
      ); // By specifying [1] as the threshold value, you're instructing the Intersection Observer to trigger when the target element becomes fully visible.

      if (bottomElementRef.current) {
        observer.observe(bottomElementRef.current);
      }

      return () => {
        if (bottomElementRef.current) {
          observer.unobserve(bottomElementRef.current);
        }
      };
    }
  }, [isLoading, hasMoreData, isLoadingMore]);

  useEffect(() => {
    searchPurchaseOrders();
  }, []);

  useEffect(() => {
    if (keyword) {
      setCurrentPage(0);
    }
  }, [keyword]);

  const handleSearchPurchaseOrders = () => {
    setHasMoreData(true);
    searchPurchaseOrders(true);
  };

  // const filterPos = (data) => {
  //   var filteredData = [];
  //   for (var i = 0; i < data.length; i++) {
  //     var index = budgetItems.findIndex(d => d.budgetId == data[i].budgetId);
  //     if (index > -1 && !filteredData.includes(data[index])) {
  //       if(data[index]){
  //         filteredData.push(data[index]);
  //       }
  //     }
  //   }
  //   return filteredData;
  // }

  const filterPos = (data) => {
    return data.filter((item) => {
      const index = budgetItems.findIndex(
        (budget) => budget.budgetId === item.budgetId
      );
      return index > -1 && !data.includes(budgetItems[index]);
    });
  };

  const searchPurchaseOrders = (shouldBypass = false) => {
    if (!shouldBypass) {
      if (!hasMoreData || isLoading || isLoadingMore) {
        return; // No more data to fetch
      }
    }

    setIsLoading(true);
    axios
      .get(`PurchaseOrder/SearchPurchaseOrder`, {
        params: {
          keyword: keyword,
          companyId: companyId,
          page: currentPage,
        },
      })
      .then(({ data }) => {
        if (data.length === 0) {
          setHasMoreData(false);
        } else {
          if (currentPage === 0) {
            var filteredData = filterPos(data);
            setPurchaseOrders([...filteredData]);
          } else {
            var filteredData = filterPos(data);
            setPurchaseOrders((prevPo) => [...prevPo, ...filteredData]);
          }
          setCurrentPage(currentPage + 1);
        }
      })
      .finally(() => setIsLoading(false));

    return;
  };
  const handleCreateNewPurchaseOrder = () => {
    setSelectedPurchaseOrder({
      companyFk: companyId,
      currency: baseCurrency,
    });
  };

  const handleChangeTotal = (currency, total, totalInNzd) => {
    setSelectedPurchaseOrder({
      ...selectedPurchaseOrder,
      currency: currency || "NZD",
      total: total || null,
      totalInNzd: totalInNzd || null,
    });
  };

  const handlePurchaseOrderOnChange = (field, value) => {
    if (field === "xeroContactId") {
      setSelectedPurchaseOrder({
        ...selectedPurchaseOrder,
        xeroContactName: value?.Name || null,
        xeroContactId: value?.ContactID || null,
      });
    } else if (field === "budget") {
      setSelectedPurchaseOrder({
        ...selectedPurchaseOrder,
        budgetCategory: value?.projectCategory || null,
        budgetId: value?.budgetId || null,
      });
    } else {
      setSelectedPurchaseOrder({ ...selectedPurchaseOrder, [field]: value });
    }
  };

  const handleSelectPurchaseOrder = (purchaseOrder) => {
    setSelectedPurchaseOrder(purchaseOrder);
  };

  const isValidForm = async () => {
    const selectedBudget = await budgetItems.find(
      (bi) => bi.budgetId === selectedPurchaseOrder.budgetId
    );
    if (
      isEmpty(selectedPurchaseOrder?.currency) ||
      isEmpty(selectedPurchaseOrder?.total) ||
      isEmpty(selectedPurchaseOrder?.budgetCategory) ||
      isEmpty(selectedPurchaseOrder?.budgetId) ||
      isEmpty(selectedPurchaseOrder?.serviceName)
    ) {
      setSnackBarProps({
        open: true,
        severity: "warning",
        text: "Some fields are missing values.",
      });

      return false;
    } else if (
      Number(selectedPurchaseOrder.totalInNzd) > selectedBudget.remaining
    ) {
      setOpenNotEnoughBudgetDialog(true);
      return false;
    }

    return true;
  };

  const handleCreatePurchaseOrder = async () => {
    if (await isValidForm()) {
      setIsSavingForm(true);
      axios
        .post(`purchaseOrder/CreatePurchaseOrder`, selectedPurchaseOrder, {
          params: {
            createdBy: user.email,
          },
        })
        .then(({ data }) => {
          setPurchaseOrders([...purchaseOrders, data]);
          fetchAllBudget();
          setSelectedPurchaseOrder(null);
          setSnackBarProps({
            open: true,
            severity: "success",
            text: "Fantastic! Your changes have been saved.",
          });
        })
        .catch((err) =>
          setSnackBarProps({
            open: true,
            severity: "warning",
            text: "Unable to save changes",
          })
        )
        .finally(() => setIsSavingForm(false));
    }
  };

  const removePurchaseOrder = (selectedPo) => {
    axios
      .delete(`purchaseOrder/DeletePurchaseOrder/${selectedPo.id}`)
      .then(({ data }) => {
        setPurchaseOrders(
          purchaseOrders.map((po) => (po.id === data.id ? data : po))
        );
        fetchAllBudget();
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Fantastic! Your changes have been saved.",
        });
      })
      .catch((err) => {
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to remove purchase order",
        });
      });
  };

  return (
    <div className="client-purchase-order-root">
      <Snacky snackprops={snackBarProps} setSnackBarProps={setSnackBarProps} />
      <NewPurchaseOrderButton handleClick={handleCreateNewPurchaseOrder} />
      {selectedPurchaseOrder && !isSavingForm && (
        <PurchaseOrderForm
          budgetItems={budgetItems}
          purchaseOrder={selectedPurchaseOrder}
          handlePurchaseOrderOnChange={handlePurchaseOrderOnChange}
          handleCreatePurchaseOrder={handleCreatePurchaseOrder}
          handleChangeTotal={handleChangeTotal}
          baseCurrency={baseCurrency}
        />
      )}
      {openNotEnoughBudgetDialog && (
        <ConfirmationDialog
          show={openNotEnoughBudgetDialog}
          titleText="Not Enough Budget"
          contentText="Sorry, but the selected budget doesn't cover this PO's costs. Reduce the amount or select another budget."
          handleClose={() => setOpenNotEnoughBudgetDialog(false)}
          buttonText="Ok, got it!"
        />
      )}
      {isSavingForm && <Skeleton height={150} />}
      <PurchaseOrderTable
        keyword={keyword}
        setKeyword={setKeyword}
        searchPOs={handleSearchPurchaseOrders}
        purchaseOrders={purchaseOrders}
        isLoading={isLoading}
        handleSelectPurchaseOrder={handleSelectPurchaseOrder}
        removePurchaseOrder={removePurchaseOrder}
      />
      <div ref={bottomElementRef}></div>
    </div>
  );
};

export default ClientPurchaseOrder;
