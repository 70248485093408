import React, { useState, useEffect } from "react";
import {
    Stepper,
    Step,
    StepLabel,
    StepConnector,
    Tooltip,
    Collapse
} from "@material-ui/core";
import axios from "axios";
import { getDatePortion } from "../../utils/TimeHelpers";
import ConfirmationDialogActions from "../Shared/ConfirmationDialogActions";

const FlyingTigerSteps = [
    "briefCompleted",
    "contentCreated",
    "contentSentToClient",
    "contentApproved",
    "contentGoLive",
    "clientReportSent",
    "jobReflection",
];

const TheSocialClubSteps = [
    "briefCompleted",
    "outreachSent",
    "contentReceived",
    "contentApproved",
    "contentGoLive",
    "mediaAndCmBriefed",
    "reportsReceived",
    "clientReportSent",
    "influencersInvoiced",
]

const SocialitesSteps = [
    "briefCompleted",
    "outreachSent",
    "contentReceived",
    "contentApproved",
    "contentGoLive",
    "mediaAndCmBriefed",
    "reportsReceived",
    "clientReportSent",
    "influencersInvoiced",
]

const PeopleOfInfluenceSteps = [
    "briefCompleted",
    "contentReceived",
    "contentApproved",
    "contentGoLive",
    "insightsReceived",
    "insightsSent",
]

const HomepageProjectRow = ({
    companyId,
    jobId,
    projectStatus,
    updateProjectStatus,
    project
}) => {
    const [activeStep, setActiveStep] = useState(0);
    const [steps, setSteps] = useState([])
    const [openCompleteConfirmation, setOpenCompleteConfirmation] = useState(false);
    const [openIncompleteConfirmation, setOpenIncompleteConfirmation] = useState(false);
    const [projectStatusData, setProjectStatusData] = useState([]);

    const splitCamelCase = (str) => {
        return str.replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/(^\w|\s\w)/g, m => m.toUpperCase());
    }

    const handleRedirect = (projectStatus) => {
        const departmentUrls = {
            "People Of Influence": `https://hub.thompsonspencer.com/Client/Home/${companyId}/0`,
            "Flying Tiger": `https://hub.thompsonspencer.com/Client/Home/${companyId}/3`,
            "Socialites": `https://hub.thompsonspencer.com/Client/Home/${companyId}/1`,
            "The Social Club": `https://hub.thompsonspencer.com/Client/Home/${companyId}/1`,
        };
        window.open(departmentUrls[projectStatus.department], "_blank");
    };

    useEffect(() => {
        const departmentSteps = {
            "Flying Tiger": FlyingTigerSteps,
            "Socialites": SocialitesSteps,
            "People Of Influence": PeopleOfInfluenceSteps,
            "The Social Club": TheSocialClubSteps
        };

        setSteps(departmentSteps[projectStatus.department]);
    }, [projectStatus]);

    useEffect(() => {
        var activeStep = 0;
        for (var i = 0; i < steps.length; i++) {
            if (projectStatus[steps[i]] != null) {
                activeStep = i + 1;
            }
        }
        setActiveStep(activeStep);
    }, [steps, projectStatus])

    return (
        <div className="homepage-project-row-container">
            {openCompleteConfirmation && (
                <ConfirmationDialogActions
                    title={"Mark as completed?"}
                    open={openCompleteConfirmation}
                    contentText={`This action will mark ${splitCamelCase(projectStatusData.value)} as complete`}
                    button1Action={() => setOpenCompleteConfirmation(false)}
                    button1Text={"Go Back"}
                    button1Class="default-button-grey"
                    button2Text={"Confirm"}
                    button2Class="default-button"
                    button2Action={() => {
                        updateProjectStatus(
                            projectStatusData.companyId,
                            projectStatusData.jobId,
                            projectStatusData.projectStatusId,
                            projectStatusData.value,
                            null);
                        setOpenCompleteConfirmation(false);
                    }}
                    handleClose={() => setOpenCompleteConfirmation(false)}
                />
            )}
            {openIncompleteConfirmation && (
                <ConfirmationDialogActions
                    title={"Mark as incompleted?"}
                    open={openIncompleteConfirmation}
                    contentText={`This action will mark ${splitCamelCase(projectStatusData.value)} as incomplete`}
                    button1Action={() => setOpenIncompleteConfirmation(false)}
                    button1Text={"Go Back"}
                    button1Class="default-button-grey"
                    button2Text={"Confirm"}
                    button2Class="default-button"
                    button2Action={() => {
                        updateProjectStatus(
                            projectStatusData.companyId,
                            projectStatusData.jobId,
                            projectStatusData.projectStatusId,
                            projectStatusData.value,
                            true
                        );
                        setOpenIncompleteConfirmation(false);
                    }}
                    handleClose={() => setOpenIncompleteConfirmation(false)}
                />
            )}
            <div className="homepage-project-row-project-title-container">
                <span className="homepage-project-row-project-title">
                    <Tooltip title="Open project in Hub" placement="top" arrow>
                        <span onClick={() => handleRedirect(projectStatus)} className={"homepage-project-row-department link"}>
                            {`${projectStatus.department}`}
                        </span>
                    </Tooltip>
                    <span onClick={(e) => e.stopPropagation()}>{`[${project.jobId}] ${project.projectName}`}</span>
                </span>
            </div>
            <div className="homepage-project-row-stepper-container">
                <Stepper alternativeLabel activeStep={activeStep}
                    connector={<StepConnector
                        className="connector-alternativeLabel"
                        classes={{
                            active: "connector-active",
                            completed: "connector-completed",
                            line: "connector-line",
                        }}
                    />
                    }>
                    {steps.map((value, index) => (
                        <Step
                            key={value}
                        >
                            <StepLabel
                                onClick={() => {
                                    setProjectStatusData({
                                        companyId: companyId,
                                        jobId: jobId,
                                        projectStatusId: projectStatus.id,
                                        value
                                    })
                                    if (projectStatus[value] != null) {
                                        setOpenIncompleteConfirmation(true);
                                    } else {
                                        setOpenCompleteConfirmation(true);
                                    }
                                }}
                            >
                                {`${splitCamelCase(value)} ${projectStatus[value] != null ? getDatePortion(projectStatus[value]) : ""}`}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </div>
        </div>
    )
}

export default HomepageProjectRow;