import {
  AppBar,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import "./updateInfluencerDialog.scss";
import UpdateInfluencerForm from "./UpdateInfluencerForm";
const UpdateInfluencerDialog = ({
  open,
  handleClose,
  handleSubmit,
  selectedInfluencer,
  personCategoryToBeAdded,
  isSaving
}) => {
  const [shouldMoveDialog, setShouldMoveDialog] = useState(false);
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
        PaperProps={{
          style: {
            backgroundColor: "#ededed",
          },
        }}
        className={`${
          shouldMoveDialog ? "update-influencer-dialog-move-container" : ""
        }`}
      >
        <AppBar position="static" style={{ background: "#000000" }}>
          <Toolbar variant="dense" style={{ paddingRight: 0 }}>
            <Typography
              variant="subtitle1"
              align="center"
              style={{ flexGrow: 1 }}
            >
              Influencer Details
            </Typography>
            <span style={{ display: "flex" }}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </span>
          </Toolbar>
        </AppBar>
        <DialogContent style={{padding: "24px 24px"}}>
          <UpdateInfluencerForm
            selectedInfluencer={selectedInfluencer}
            handleCancel={handleClose}
            handleSubmit={handleSubmit}
            setShouldMoveDialog={setShouldMoveDialog}
            personCategory={personCategoryToBeAdded}
            isSaving={isSaving}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UpdateInfluencerDialog;
