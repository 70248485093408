import { DateTimePicker } from "@material-ui/pickers";
import {
  GET_OUTPUTS,
  SPLICE_OUTPUTS_FROM_COLUMN,
  SPLICE_OUTPUTS_TO_COLUMN,
  SPLICE_ONE_OUTPUT_FROM_COLUMN,
  RESET_OUTPUTS_LOADING,
} from "../actions/types";
const initialState = {
  outputs: [],
  //Orphans
  O: [],
  E: [],
  C: [],
  Sc: [],
  Sch: [],
  Sh: [],
  loading: true,
  error: {}
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_OUTPUTS:
      return {
        ...state,
        outputs: payload,
        O: payload.filter((op) => op.orphanStage == "O"),
        Sc: payload.filter((op) => op.orphanStage == "Sc"),
        Sch: payload.filter((op) => op.orphanStage == "Sch"),
        E: payload.filter((op) => op.orphanStage == "E"),
        Sh: payload.filter((op) => op.orphanStage == "Sh"),
        C: payload.filter((op) => op.orphanStage == "C"),
        loading: false
      };
    case SPLICE_OUTPUTS_FROM_COLUMN:
      // call this when moving a shell project
      // payload: { source, projectId },
      console.log(payload);
      state[payload.source] = state[payload.source].filter(
        (op) => op.projectFk != payload.projectId
      );
      return {
        ...state,
      };
    case SPLICE_ONE_OUTPUT_FROM_COLUMN:
      // call this when moving a shell project
      // payload: { column, output },
      state[payload.column] = state[payload.column].filter(
        (op) => op.id != payload.output.id
      );
      return {
        ...state,
      };
    case SPLICE_OUTPUTS_TO_COLUMN:
      // call this when moving a shell project
      // payload: { destination, outputs },
      console.log(payload.outputs);
      state[payload.destination] = [
        ...state[payload.destination],
        ...payload.outputs,
      ];
      return {
        ...state,
      };
    case RESET_OUTPUTS_LOADING:
      return initialState;
    default:
      return state;
  }
}
