import { Button, Menu, MenuItem } from "@material-ui/core";
import React, { useState } from "react";
import { Add as AddIcon } from "@material-ui/icons";

const AddNewActionButton = ({ handleAddNewRecord }) => {
  const [contextMenu, setContextMenu] = useState(null);
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handleMenuClick = (type) => {
    handleAddNewRecord(type);
    handleCloseContextMenu();
  }

  return (
    <div>
      <Button
        className="default-button  add-budget-button"
        size="small"
        onClick={handleContextMenu}
        startIcon={<AddIcon />}
      >
        Add New Record
      </Button>
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={(e) => {
            handleMenuClick("existing");
          }}
        >
          Add Existing Project
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleMenuClick("new_business");
          }}
        >
          New Business
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleMenuClick("internal_wip");
          }}
        >
          Internal Wip
        </MenuItem>

        <MenuItem
          onClick={(e) => {
            handleMenuClick("ts_marketing");
          }}
        >
          TS Marketing
        </MenuItem>
      </Menu>
    </div>
  );
};

export default AddNewActionButton;
