import { TextField } from "@material-ui/core";
import React from "react";

const MediaCampaignsFilter = ({ keyword, setKeyword }) => {
  return (
    <div className="media-campaigns-filter-root">
      <TextField
        className="media-campaigns-filter-input"
        label="Global Search"
        variant="filled"
        fullWidth={true}
        value={keyword}
        size="small"
        onChange={(e) => {
          setKeyword(e.target.value);
        }}
        // onKeyDown={(e) => {
        //   if (e.key === 13 || e.key === "Enter") {
        //     updateClientFilter("keyword",keyword);
        //   }
        // }}
      />
    </div>
  );
};

export default MediaCampaignsFilter;
