import React, { useEffect, useState } from "react";
import "./masterStatus.scss";
import Header from "../../Header";
import axios from "axios";
import { Skeleton } from "@material-ui/lab";
import MasterStatusItem from "./MasterStatusItem";
import { useUserContext } from "../../../context/user/UserContext";
import {
  Container,
  Typography,
  Divider,
  TextField,
  Grid,
  Button
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../context/auth/AuthContext";

const MasterStatus = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [clientsDto, setClientsDto] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const searchMasterStatus = (newKeyword = "") => {
    setIsLoading(true);
    axios
      .get(`influencer/SearchInfluencerMasterStatus`, {
        params: {
          email: selectedUser?.email,
          keyword: newKeyword
        }
      })
      .then(({ data }) => {
        setClientsDto(data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  };

  const getUsers = () => {
    axios
      .get(`Users/GetUsers`, {
        params: {
          includeArchive: false
        }
      })
      .then(({ data }) => {
        setUsers(data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  }

  const HandleSearch = (keyword) => {
    searchMasterStatus(keyword)
  }

  useEffect(() => {
    getUsers()
  }, []);


  useEffect(() => {
    if (user?.email && users.length > 0) {
      setSelectedUser(users.find(u => u.email == user.email));
    }
  }, [user, users])

  useEffect(() => {
    if (selectedUser) {
      HandleSearch(keyword);
    }
  }, [selectedUser]);

  return (
    <div>
      <div className="master-status-top-container">
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item sm={8} md={8} lg={8}>
              <Typography variant="h6">{`Hello ${user.firstName},`}</Typography>
              <Typography variant="subtitle1">{`Below are the status of your influencers`}</Typography>
            </Grid>
            <Grid item sm={4} md={4} lg={4}>
              <div style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: 10
              }}>
                <Button
                  className="default-button"
                  onClick={() => { history.push("/v2/clients"); }}
                >
                  Go to Client list
                </Button>
              </div>
            </Grid>
          </Grid>
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <Grid container spacing={2}>
              <Grid item sm={8} md={8} lg={8}>
                <TextField
                  label="Project Search"
                  variant="filled"
                  fullWidth={true}
                  value={keyword}
                  size="small"
                  onChange={(e) => {
                    setKeyword(e.target.value);
                    if (e.target.value == "") {
                      HandleSearch("");
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 13 || e.key === "Enter") {
                      HandleSearch(keyword);
                    }
                  }}
                />
              </Grid>
              <Grid item sm={4} md={4} lg={4}>
                <Autocomplete
                  disableClearable
                  options={users}
                  getOptionLabel={(user) => user.firstName + " " + user.lastName}
                  onChange={(e, newValue) => {
                    setSelectedUser(newValue || null);
                  }}
                  value={selectedUser}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Select User"}
                      variant={"filled"}
                      size="small"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
          <div>
            <div>
              {[...clientsDto].map((dto, index) => (
                <div key={index}>
                  <MasterStatusItem clientsDto={dto} />
                </div>
              ))}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default MasterStatus;
