import React from "react";
import { Slide } from "react-slideshow-image";
import LocationDetailsPopUp from "./LocationDetailsPopUp";
import "./Slideshow.css";

const properties = {
  duration: 3000,
  transitionDuration: 200,
  autoplay: false,
  infinite: true,
  indicators: false,
  scale: 0.4,
  arrows: true,
};

const Slideshow = (props) => {
  //console.log(props.images)
  const [
    locationDetailsModalState,
    setLocationDetailsModalState,
  ] = React.useState(false);
  const [currentLocation, setCurrentLocation] = React.useState({});

  const handleLocationDetailsModal = () => {
    console.log("LocationDetailModalState");
    console.log(locationDetailsModalState);
    if (locationDetailsModalState) {
      setLocationDetailsModalState(false);
      props.refreshLocations();
      // props.setUpdatingImageFalse();
    } else {
      setLocationDetailsModalState(true);
    }
  };

  return props.images.length > 0 ? (
    <div style={{ minHeight: props.height, backgroundColor: "black" }}>
      <Slide {...properties}>
        {props.images.map((each, index) => (
          <div key={index} onClick={() => handleLocationDetailsModal()}>
            <img
              style={{
                maxHeight: props.height,
                maxWidth: "100%",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "auto",
                marginBottom: "auto",
              }}
              src={each.locationUrl}
            />
          </div>
        ))}
      </Slide>
      {/* {console.log(locationDetailsModalState)} */}
      {locationDetailsModalState && (
        <LocationDetailsPopUp
          location={props.location}
          modalState={locationDetailsModalState}
          handleModal={() => handleLocationDetailsModal()}
          setLocation={props.setLocation}
          //toggleLoadingImages={props.toggleLoadingImages}
          setLoadingImagesFalse={() => props.setLoadingImagesFalse()}
          setLoadingImagesTrue={() => props.setLoadingImagesTrue()}
        />
      )}
    </div>
  ) : (
    <div style={{ height: "100%" }}>
      {/* {console.log(locationDetailsModalState)} */}
      <div
        style={{ height: "100%", backgroundColor: "black" }}
        onClick={() => handleLocationDetailsModal()}
      ></div>
      {locationDetailsModalState && (
        <LocationDetailsPopUp
          location={props.location}
          modalState={locationDetailsModalState}
          handleModal={() => handleLocationDetailsModal()}
          setLocation={props.setLocation}
          setLoadingImagesFalse={() => props.setLoadingImagesFalse()}
          setLoadingImagesTrue={() => props.setLoadingImagesTrue()}
        />
      )}
    </div>
  );
};

export default Slideshow;
