import React, { useEffect, useState } from "react";
import {
    Typography,
    Grid,
    TextField,
    CircularProgress,
    Select,
    MenuItem,
} from "@material-ui/core";
import InfluencerCard from "./InfluencerCard";
import axios from "axios";

const InfluencerSearch = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [influencer, setInfluencer] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchType, setSearchType] = useState("instagram");
    const [currentSearchType, setCurrentSearchType] = useState("instagram");

    const searchInfluencer = () => {
        setLoading(true);
        setCurrentSearchType(searchType);
        var url = `Influencer/GetInstagramBasic/${searchTerm}`;
        if(searchType === "tikTok"){
            url = `Influencer/GetTikTokBasic/${searchTerm}`;
        }
        axios.get(url)
            .then(res => {
                setInfluencer(res.data.data);
                console.log(res.data.data);
            }).catch(e => {
                console.log(e);
            }).finally(() => setLoading(false))
    }

    return (
        <div style={{ margin: "0px 20%" }}>
            <Grid container style={{ padding: "15px 0px" }}>
                <Grid item xs={4} md={4} lg={4}>
                    <Typography
                        variant="h6"
                        gutterBottom
                        component="div"
                        alignSelf={"center"}
                    >
                        Influencer Search
                    </Typography>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <div style={{ width: "100%" }}>
                        <TextField
                            label="Search Influencer"
                            fullWidth={true}
                            variant="outlined"
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                            }}
                            value={searchTerm}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                    searchInfluencer();
                                }
                            }}
                            disabled={loading}
                            placeholder="Type Id and press 'Enter'"
                        />
                    </div>
                </Grid>
                <Grid item xs={2} md={2} lg={2}>
                    <div
                        style={{ width: "100%" }}
                    >
                        <Select
                            value={searchType}
                            onChange={(e) => {
                                setSearchType(e.target.value)
                            }}
                            variant="outlined"
                            fullWidth
                        >
                            <MenuItem value={"instagram"}>Instagram</MenuItem>
                            <MenuItem value={"tikTok"}>TikTok</MenuItem>
                        </Select>
                    </div>
                </Grid>
            </Grid>
            <div id="profile-container">
                {influencer !== null && influencer !== "" && !loading && (
                    <InfluencerCard influencer={influencer} type={currentSearchType} />
                )}
                {loading && <CircularProgress />}
            </div>
        </div>
    )
}

export default InfluencerSearch;