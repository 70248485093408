import React from "react";
import { Tooltip, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: 12,
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const CustomHtmlTooltip = ({ title, children }) => {
  return (
    <HtmlTooltip
      title={
        <div>
          <Typography variant="caption">
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </Typography>
        </div>
      }
    >
      {children}
    </HtmlTooltip>
  );
};

export default CustomHtmlTooltip;
