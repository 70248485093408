import React, { useEffect, useState, useRef } from "react";
import TimeTrackerAllocationFilters from "./TimeTrackerAllocationFilters";
import TimeTrackerAllocationTable from "./TimeTrackerAllocationTable";
import axios from "axios";
import { Skeleton } from "@material-ui/lab";
import { useAuth } from "../../../context/auth/AuthContext";
import { Button, Typography } from "@material-ui/core";
import TimeTrackerAddProjectDialog from "./TimeTrackerAddProjectDialog";
import Snacky from "../../Shared/Snacky";
import moment from "moment";
import AddNewActionButton from "./AddNewActionButton";
const TimeTrackerAllocation = () => {
  const { user } = useAuth();
  const debounceTimeoutRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldAddNewProject, setShouldAddNewProject] = useState(false);
  const [timeEntriesByProjects, setTimeEntriesByProjects] = useState([]);
  const [originalTimeEntriesByProjects, setOriginalTimeEntriesByProjects] =
    useState([]);
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });
  const [keyword, setKeyword] = useState("");
  const [datesToDisplay, setDatesToDisplay] = useState([]);

  useEffect(() => {
    searchTimeEntries();
  }, []);
  const searchTimeEntries = () => {
    setIsLoading(true);
    axios
      .get(`/TimeEntry/GetTimeEntriesForUser/${user.id}`)
      .then(({ data }) => {
        setTimeEntriesByProjects(data);
        setOriginalTimeEntriesByProjects(data);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleAddExistingProject = () => {
    setShouldAddNewProject(true);
  };

  const handleAddNewRecord = (type) => {
    switch (type) {
      case "existing":
        handleAddExistingProject();
        break;
      case "new_business":
        handleSaveNewProject({
          id: 1961,
          projectName: "New Business Tracking",
        });
        break;
      case "internal_wip":
        handleSaveNewProject({
          id: 1962,
          projectName: "Internal Wip Tracking",
        });
        break;
      case "ts_marketing":
        handleSaveNewProject({
          id: 1963,
          projectName: "TS Marketing Tracking",
        });
        break;
    }
  };

  const handleSaveNewProject = (newProject) => {
    if (newProject) {
      axios
        .post(`/TimeEntry/SaveTimeEntry`, {
          projectId: newProject.id,
          projectName: newProject.projectName,
          userId: user.id,
          userEmail: user.email,
        })
        .then(({ data }) => {
          const _newTimeEntriesByProjects = [
            ...timeEntriesByProjects,
            {
              projectId: newProject.id,
              projectName: newProject.projectName,
              timeEntries: [],
            },
          ];
          setTimeEntriesByProjects(_newTimeEntriesByProjects);
          setOriginalTimeEntriesByProjects(_newTimeEntriesByProjects);
          setShouldAddNewProject(false);
          setSnackBarProps({
            open: true,
            severity: "success",
            text: "Project added successfully",
          });
        })
        .catch((err) => {
          console.log(err);
          setSnackBarProps({
            open: true,
            severity: "warning",
            text: "Unable to find projects",
          });
        });
    }
  };

  const saveTimeEntry = async (timeEntry, formattedDate, projectId) => {
    try {
      const { data: savedTimeEntry } = await axios.post(
        "/TimeEntry/SaveTimeEntry",
        timeEntry
      );

      const updateEntries = (prevEntries) =>
        prevEntries.map((project) => {
          if (project.projectId !== projectId) return project;

          const existingEntryIndex = project.timeEntries.findIndex((entry) => {
            const entryDate = entry.date
              ? moment(entry.date).format("YYYY-MM-DD")
              : null;
            return entryDate === formattedDate;
          });

          // If entry exists, update it
          if (existingEntryIndex !== -1) {
            const updatedEntries = [...project.timeEntries];
            updatedEntries[existingEntryIndex] = savedTimeEntry;
            return {
              ...project,
              timeEntries: updatedEntries,
            };
          }

          // If entry does not exist, add the new one from the API response
          return {
            ...project,
            timeEntries: [...project.timeEntries, savedTimeEntry],
          };
        });

      setTimeEntriesByProjects(updateEntries);
      setOriginalTimeEntriesByProjects(updateEntries);

      setSnackBarProps({
        open: true,
        severity: "success",
        text: "Time entry saved successfully",
      });
    } catch (error) {
      console.error("Failed to save time entry:", error);
      setSnackBarProps({
        open: true,
        severity: "warning",
        text: "Failed to save time entry",
      });
    }
  };

  const handleTimeEntryChange = async (
    projectId,
    date,
    timeSpentInMinutes,
    description,
    oldTimeEntry
  ) => {
    console.info(
      `Project ID: ${projectId}, Date: ${date}, Time In Minutes: ${timeSpentInMinutes}, Description: ${description}`
    );
    const formattedDate = date ? moment(date).format("YYYY-MM-DD") : null;

    // Prepare the time entry object
    const timeEntry = {
      id: oldTimeEntry?.timeEntryId || 0,
      projectId,
      date: formattedDate,
      timeSpentInMinutes,
      description,
      userId: user.id,
      userEmail: user.email,
    };

    await saveTimeEntry(timeEntry, formattedDate, projectId);
  };

  const closeProject = (projectId) => {
    setIsLoading(true);
    axios
      .put(`/TimeEntry/HideProjectEntries/${projectId}`)
      .then(({ data }) => {
        setTimeEntriesByProjects(
          timeEntriesByProjects.filter(
            (project) => project.projectId !== projectId
          )
        );
        setOriginalTimeEntriesByProjects(
          originalTimeEntriesByProjects.filter(
            (project) => project.projectId !== projectId
          )
        );
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleKeywordChange = (value) => {
    const newKeyword = value;
    setKeyword(newKeyword);

    // Clear the existing debounce timeout
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    // Set a new debounce timeout
    debounceTimeoutRef.current = setTimeout(() => {
      filterEntries(newKeyword);
    }, 150);
  };

  const filterEntries = (keyword) => {
    if (keyword) {
      const filteredEntried = originalTimeEntriesByProjects.filter(
        (project) =>
          (project.projectName &&
            project.projectName
              ?.toLowerCase()
              .includes(keyword.toLowerCase())) ||
          (project.jobId &&
            project.jobId.toString().includes(keyword.toLowerCase()))
      );
      setTimeEntriesByProjects(filteredEntried);
    } else {
      setTimeEntriesByProjects(originalTimeEntriesByProjects);
    }
  };

  return (
    <div className="time-tracker-allocation-root">
      <Snacky snackprops={snackBarProps} setSnackBarProps={setSnackBarProps} />
      {shouldAddNewProject && (
        <TimeTrackerAddProjectDialog
          open={shouldAddNewProject}
          handleSubmit={handleSaveNewProject}
          handleClose={() => setShouldAddNewProject(false)}
        />
      )}
      <TimeTrackerAllocationFilters
        setDatesToDisplay={setDatesToDisplay}
        handleKeywordChange={handleKeywordChange}
        keyword={keyword}
      />
      {isLoading ? (
        <div style={{ display: "grid" }}>
          <Skeleton height={40} />
          <Skeleton height={40} />
          <Skeleton height={40} />
        </div>
      ) : (
        <>
          {timeEntriesByProjects.length === 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>
                No projects found. Click on the 'Add project' button to start
                tracking time.
              </Typography>
            </div>
          ) : (
            <TimeTrackerAllocationTable
              timeEntriesByProjects={timeEntriesByProjects}
              handleTimeEntryChange={handleTimeEntryChange}
              closeProject={closeProject}
              datesToDisplay={datesToDisplay}
            />
          )}

          <AddNewActionButton handleAddNewRecord={handleAddNewRecord} />
        </>
      )}
    </div>
  );
};

export default TimeTrackerAllocation;
