import { GET_BUDGET, EDIT_BUDGET, RESET_BUDGET } from "./budgetTypes";
import { createAction } from "@reduxjs/toolkit";
import axios from "axios";

const getAllExpenseCodesSuccess = createAction("GET_ALL_EXPENSE_CODES_SUCCESS");
const getAllExpenseCodesFailure = createAction("GET_ALL_EXPENSE_CODES_FAILURE");

export const getExpenseCodes = () => async (dispatch) => {
  try {
    var res = await axios.get(`ExpenseCodes/GetExpenseCodes`);
    console.log("Account Code "+res.data.length)
    dispatch(getAllExpenseCodesSuccess(res.data));
  } catch (err) {
    dispatch(getAllExpenseCodesFailure(err));
  }
};

export const getBudget = (projectId) => async (dispatch) => {
  console.log("Getting Budgets");
  try {
    var reqOne = axios.get(`/BudgetItems/GetBudgetForProjectInCore/${projectId}`);
    await axios.all([reqOne]).then(
      axios.spread((...res) => {
        dispatch({
          type: GET_BUDGET,
          payload: { budget: res[0].data },
        });
      })
    );
  } catch (err) {
    dispatch({
      type: "GET_BUDGET_ERROR",
      payload: err,
    });
  }
};

export const getTasks = (projectId) => async (dispatch) => {
  try {
    var reqTwo = axios.get(`/BudgetItems/GetTasksForProject/${projectId}`);
    await axios.all([reqTwo]).then(
      axios.spread((...res) => {
        dispatch({
          type: "GET_TASKS",
          payload: { tasks: res[0].data },
        });
      })
    );
  } catch (err) {
    dispatch({
      type: "GET_TASKS_ERROR",
      payload: err,
    });
  }
};

export const editBudget = (category, amount) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_BUDGET,
      payload: { category, amount },
    });
  } catch (err) {
    dispatch({
      type: "EDIT_BUDGET_ERROR",
      payload: err,
    });
  }
};

export const resetBudget = () => async (dispatch) => {
  try {
    dispatch({
      type: RESET_BUDGET,
    });
  } catch (err) {
    dispatch({
      type: "RESET_BUDGET_ERROR",
      payload: err,
    });
  }
};
