import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReconcileReceiptBudgetRow from "./ReconcileReceiptBudgetRow";

const ReconcileReceiptBudgetTable = ({
    payable,
    handleUpdateSelectedReconLines,
    handleRemoveSelectedReconLine
}) => {
    const [expenses, setExpenses] = useState([]);

    const getBudgetItems = () => {
        axios.get(`Reconciliation/GetBudgetForCompanyByProject`, {
            params: {
                "projectId": payable.projectId,
                "companyId": payable.companyId
            }
        })
            .then(res => {
                setExpenses(res.data)
            }).catch(e => {
                console.log(e)
            })
    }

    useEffect(() => {
        getBudgetItems()
    }, [payable]);

    return (
        <Table className="reconcile-centre-table-root" size="small">
            <TableHead>
                <TableRow>
                    <TableCell className="reconcile-table-head-cell-root" colSpan={2}>Client</TableCell>
                    <TableCell className="reconcile-table-head-cell-root">Project</TableCell>
                    <TableCell className="reconcile-table-head-cell-root">Pull From</TableCell>
                    <TableCell className="reconcile-table-head-cell-root">Remaining Budget</TableCell>
                    {/* <TableCell className="reconcile-table-head-cell-root">Use All</TableCell> */}
                    <TableCell
                        className="reconcile-table-head-cell-root"
                        style={{ width: 70 }}>
                        Use Partial
                    </TableCell>
                    <TableCell className="reconcile-table-head-cell-root" style={{ width: 120 }}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {expenses.map(e => {
                    return (
                        <ReconcileReceiptBudgetRow
                            payable={payable}
                            reconLine={e}
                            handleUpdateSelectedReconLines={handleUpdateSelectedReconLines}
                            handleRemoveSelectedReconLine={handleRemoveSelectedReconLine}
                        />
                    )
                })}

            </TableBody>
        </Table>
    )
}

export default ReconcileReceiptBudgetTable;