import { Skeleton } from "@material-ui/lab";
import axios from "axios";
import React, { useEffect, useState } from "react";
import PersonRatingsTable from "./PersonRatingsTable";
import "./personRatings.scss";
import { useAuth } from "../../../context/auth/AuthContext";
import PersonRatingsForm from "./PersonRatingsForm";
import Snacky from "../Snacky";

const PersonRatings = ({ personId }) => {
  
  const [ratings, setRatings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });

  useEffect(() => {
    getRatings();
  }, [personId]);

  const getRatings = () => {
    if (personId) {
      setIsLoading(true);
      axios
        .get(`/people/getRatings/${personId}`)
        .then(({ data }) => {
          setRatings(data);
        })
        .catch(() => {
          console.error("Failed to fetch ratings");
          setSnackBarProps({
            open: true,
            severity: "warning",
            text: "Failed to fetch ratings",
          });
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleSaveCallback = (rating) => {
    if (rating) {
      setRatings([rating, ...ratings]);
      setSnackBarProps({
        open: true,
        severity: "success",
        text: "Fantastic! Rating saved successfully",
      });
    } else {
      setSnackBarProps({
        open: true,
        severity: "warning",
        text: "Failed to save ratings",
      });
    }
  };

  
  return (
    <div>
      <Snacky snackprops={snackBarProps} setSnackBarProps={setSnackBarProps} />
      {isLoading ? (
        <div style={{ display: "grid" }}>
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Skeleton height={20} />
        </div>
      ) : (
        <div className="person-ratings-table-wrapper">
          <PersonRatingsForm
            handleSaveCallback={handleSaveCallback}
            personId={personId}
            displayForm={false}
          />
          <PersonRatingsTable ratings={ratings} />
        </div>
      )}
    </div>
  );
};

export default PersonRatings;
