import axios from "axios";
import React, { useEffect, useState } from "react";
import PurchaseOrderForm from "../../../Client/PurchaseOrder/PurchaseOrderForm";
import { Skeleton } from "@material-ui/lab";
import { isEmpty } from "../../../../utils/GeneralUtils";
import ConfirmationDialog from "../../../Shared/ConfirmationDialog";
import { useAuth } from "../../../../context/auth/AuthContext";

const ReconcileNewPurchaseOrder = ({
  payable,
  setSnackBarProps,
  addToReconLines,
}) => {
  const { user } = useAuth();
  const [budgetItems, setBudgetItems] = useState([]);
  const [isLoadingBudget, setIsLoadingBudget] = useState(false);
  const [isSavingForm, setIsSavingForm] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState({
    companyFk: payable.companyId,
    currency: payable.currency || "NZD",
  });
  const [openNotEnoughBudgetDialog, setOpenNotEnoughBudgetDialog] =
    useState(false);

  useEffect(() => {
    getBudgetItems();
    setPurchaseOrder({
      companyFk: payable.companyId,
      currency: payable.currency || "NZD",
    });
  }, []);

  useEffect(() => {
    if (purchaseOrder && payable?.currency !== purchaseOrder?.currency) {
      setPurchaseOrder({
        ...purchaseOrder,
        currency: payable.currency || "NZD",
      });
    }
  }, [payable?.currency]);

  const getBudgetItems = () => {
    setIsLoadingBudget(true);
    axios
      .get(`BudgetItems/GetBudgetForCompanyByProject/${payable.companyId}`)
      .then(({ data }) => {
        setBudgetItems(data);
      })
      .catch((e) => {
        console.log(e);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to pull available budgets",
        });
      })
      .finally(() => setIsLoadingBudget(false));
  };

  const handlePurchaseOrderOnChange = (field, value) => {
    if (field === "xeroContactId") {
      setPurchaseOrder({
        ...purchaseOrder,
        xeroContactName: value?.Name || null,
        xeroContactId: value?.ContactID || null,
      });
    } else if (field === "budget") {
      setPurchaseOrder({
        ...purchaseOrder,
        budgetCategory: value?.projectCategory || null,
        budgetId: value?.budgetId || null,
      });
    } else {
      setPurchaseOrder({ ...purchaseOrder, [field]: value });
    }
  };

  const isValidForm = async () => {
    const selectedBudget = await budgetItems.find(
      (bi) => bi.budgetId === purchaseOrder.budgetId
    );
    if (
      isEmpty(purchaseOrder?.currency) ||
      isEmpty(purchaseOrder?.total) ||
      isEmpty(purchaseOrder?.budgetCategory) ||
      isEmpty(purchaseOrder?.budgetId) ||
      isEmpty(purchaseOrder?.serviceName)
    ) {
      setSnackBarProps({
        open: true,
        severity: "warning",
        text: "Some fields are missing values.",
      });

      return false;
    } else if (Number(purchaseOrder.totalInNzd) > selectedBudget.remaining) {
      setOpenNotEnoughBudgetDialog(true);
      return false;
    }

    return true;
  };

  const handleChangeTotal = (currency, total, totalInNzd) => {
    setPurchaseOrder({
      ...purchaseOrder,
      currency: currency || "NZD",
      total: total || null,
      totalInNzd: totalInNzd || null,
    });
  };

  const handleCreatePurchaseOrder = async () => {
    if (await isValidForm()) {
      setIsSavingForm(true);
      axios
        .post(`purchaseOrder/CreatePurchaseOrder`, purchaseOrder, {
          params: {
            createdBy: user.email,
          },
        })
        .then(({ data }) => {
          addToReconLines(data);
          getBudgetItems();
          setPurchaseOrder({
            companyFk: payable.companyId,
            currency: payable.currency || "NZD",
          });
          setSnackBarProps({
            open: true,
            severity: "success",
            text: "Fantastic! Your changes have been saved.",
          });
        })
        .catch((err) =>
          setSnackBarProps({
            open: true,
            severity: "warning",
            text: "Unable to save changes",
          })
        )
        .finally(() => setIsSavingForm(false));
    }
  };

  return (
    <div>
      {openNotEnoughBudgetDialog && (
        <ConfirmationDialog
          show={openNotEnoughBudgetDialog}
          titleText="Not Enough Budget"
          contentText="Sorry, but the selected budget doesn't cover this PO's costs. Reduce the amount or select another budget."
          handleClose={() => setOpenNotEnoughBudgetDialog(false)}
          buttonText="Ok, got it!"
        />
      )}
      {isLoadingBudget ? (
        <>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </>
      ) : isSavingForm ? (
        <Skeleton height={150} />
      ) : (
        <PurchaseOrderForm
          budgetItems={budgetItems}
          purchaseOrder={purchaseOrder}
          handlePurchaseOrderOnChange={handlePurchaseOrderOnChange}
          handleCreatePurchaseOrder={handleCreatePurchaseOrder}
          handleChangeTotal={handleChangeTotal}
          baseCurrency={payable.currency}
        />
      )}
    </div>
  );
};

export default ReconcileNewPurchaseOrder;
