import React, { useState, useEffect } from "react";
import { EditorState, Modifier } from "draft-js";
import {
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  Button,
} from "@material-ui/core";
import { CloudUpload as CloudUploadIcon } from "@material-ui/icons";

const mergeTags = [
  { name: "Health & Safety Contact", value: "{HandS Contact}" },
  { name: "Health & Safety Contact Image", value: "{ContactImage}" },
];

const sendScheduleMergeTags = [
  { name: "Project Name", value: "{ProjectName}" },
  { name: "Company Name", value: "{CompanyName}" },
  { name: "Meal Preference", value: "{MealPreference}" },
  { name: "Schedule Link", value: "{ScheduleLink}" },
  { name: "Shoot Name", value: "{ShootName}" },
  { name: "Shoot Date", value: "{ShootDate}" },
  { name: "First Name", value: "{FirstName}" },
  { name: "Start Time", value: "{StartTime}" },
  { name: "End Time", value: "{EndTime}" },
  { name: "Location Address", value: "{LocationAddress}" },
  { name: "Talent Notes", value: "{TalentNotes}" },
];

export const MergeTagOption = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const addTag = () => {
    const { editorState, onChange } = props;

    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      selectedOption,
      editorState.getCurrentInlineStyle()
    );

    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  useEffect(() => {
    if (selectedOption !== null) {
      addTag();
      setSelectedOption(null);
    }
  }, [selectedOption]);

  return (
    <Select
      value={"MergeTags"}
      onChange={(event) => {
        setSelectedOption(event.target.value);
      }}
    >
      <MenuItem key={1} value={"MergeTags"} disabled>
        Merge Tags
      </MenuItem>
      {mergeTags.map((tag, index) => {
        return (
          <MenuItem key={index + 1} value={tag.value}>
            {tag.name}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export const SendScheduleMergeTagOption = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const addTag = () => {
    const { editorState, onChange } = props;

    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      selectedOption,
      editorState.getCurrentInlineStyle()
    );

    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  useEffect(() => {
    if (selectedOption !== null) {
      addTag();
      setSelectedOption(null);
    }
  }, [selectedOption]);

  return (
    <Select
      value={"MergeTags"}
      onChange={(event) => {
        setSelectedOption(event.target.value);
      }}
    >
      <MenuItem key={1} value={"MergeTags"} disabled>
        Merge Tags
      </MenuItem>
      {sendScheduleMergeTags.map((tag, index) => {
        return (
          <MenuItem key={index + 1} value={tag.value}>
            {tag.name}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export const UploadImageButton = (props) => {
  
  const handleFilePicker = (files) => {
    const { editorState, onChange } = props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      "<div>Hello</div>",
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, "change-inline-style"));
    props.handleImageSelectChange(files)
  };

  return (
    <Button
      color="primary"
      variant="contained"
      size="small"
      onClick={() => document.getElementById("file-input").click()}
    >
      <span style={{ marginRight: "5px" }}>Upload Images</span>
      <input
        id="file-input"
        style={{ display: "none" }}
        type="file"
        // accept="image/x-png,image/gif,image/jpeg"
        onChange={(e) => handleFilePicker(e.target.files)}
        multiple={true}
      />
      <CloudUploadIcon />
    </Button>
  );
};
