import React, { useState, useEffect } from "react";
import axios from "axios";
import TableFilter from "react-table-filter";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import "../ProjectOutputs/PopUps/ContractorPopUp.css";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Button,
} from "@material-ui/core";
import DocumentViewer from "../Schedule/DocumentViewer";

const headCells = [
  {
    id: "dateSigned",
    label: "Date Signed",
  },
  {
    id: "projectName",
    label: "Project Name",
  },
  {
    id: "scheduleName",
    label: "Schedule",
  },
  {
    id: "type",
    label: "Type",
  },
  {
    id: "amount",
    label: "Amount",
  },
  {
    id: "contractStatus",
    label: "Status",
  },
  {
    id: "fileId",
    label: "Document",
  },
];

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: 12,
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const HistoryTable = (props) => {
  let history = useHistory();

  const [contractList, setContractList] = useState(
    props.contractList ? props.contractList : []
  );

  const [redirectToProject, setRedirectToProject] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [showDocumentViewer, setShowDocumentViewer] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleProjectClicked = (projId) => {
    setSelectedProject(projId);
    setRedirectToProject(true);
  };

  const showAmount = (type) => {
    switch (type) {
      case "Contractor Contract":
      case "Contractor Invoice":
      case "Contractor Statement":
      case "Talent Contract":
      case "Talent Invoice":
      case "Talent Statement":
      case "Location Invoice":
      case "Location Contract":
      case "Preimbursement":
        return true;
      default:
        return false;
    }
  };

  const handleHeadCell = (cell) => {
    if (cell.id === "dateSigned") {
      return (
        <th
          filterkey={cell.id}
          itemDisplayValueFunc={(itemValue) => {
            if (itemValue !== null) {
              return itemValue.toDateString();
            }
          }}
          itemSortValueFunc={(itemValue) => {
            if (itemValue !== "(blank)") {
              return itemValue.toDateString();
            }
          }}
          alignleft="true"
        >
          {cell.label}
        </th>
      );
    } else {
      return (
        <th filterkey={cell.id} alignleft="true">
          {cell.label}
        </th>
      );
    }
  };

  useEffect(() => {
    console.log(contractList);
  }, [contractList]);
  if (redirectToProject) {
    history.push(`/Project/Home/${selectedProject}/0`);
    return <React.Fragment></React.Fragment>;
  } else {
    return (
      <Table size="small" style={{ tableLayout: "fixed" }}>
        <TableFilter
          rowClass="history-table-header"
          rows={contractList}
          onFilterUpdate={(updatedData, filterConfiguration) => {
            setContractList(updatedData);
            console.log("filterConfiguration");
            console.log(filterConfiguration);
          }}
          sortBy={[
            {
              id: "columnId",
              desc: false,
            },
          ]}
        >
          {headCells.map((cell) => handleHeadCell(cell))}
        </TableFilter>
        <TableBody>
          {showDocumentViewer && (
            <DocumentViewer
              modalState={showDocumentViewer}
              handleCloseModal={() => {
                setSelectedRow(null);
                setShowDocumentViewer(false);
              }}
              driveId={selectedRow.fileId}
              docType={"PDF"}
            />
          )}
          {contractList.length > 0 ? (
            contractList.map((row, index) => {
              console.log(row);
              return (
                <TableRow key={row.scheduleItemId} hover={true}>
                  <TableCell>
                    {row.dateSigned !== null
                      ? format(row.dateSigned, "dd/MM/yyyy")
                      : ""}
                  </TableCell>
                  <TableCell>
                    <span
                      className={row.projectId !== null ? "clickable" : ""}
                      onClick={() => {
                        if (row.campaignLink) {
                          window.location.href = row.campaignLink
                        } else {
                          if (row.projectId !== null) {
                            handleProjectClicked(row.projectId)
                          }
                        }
                      }
                      }
                    >
                      {row.projectName}
                    </span>
                  </TableCell>
                  <TableCell>{row.scheduleName}</TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>
                    {showAmount(row.type) ? (
                      `$${row.amount}`
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </TableCell>
                  <TableCell>{row.contractStatus}</TableCell>
                  <TableCell>
                    {row.fileId !== null ? (
                      <span
                        onClick={() => {
                          setSelectedRow(row);
                          setShowDocumentViewer(true);
                        }}
                        className="clickable pointer"
                      >
                        View Document
                      </span>
                    ) : (
                      ""
                    )}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </TableBody>
      </Table>
    );
  }
};

export default HistoryTable;
