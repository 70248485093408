import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import {
  Close as CloseIcon,
  CloudUpload as CloudUploadIcon,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import "../BudgetTransfer/budgetTransfer.scss";
import axios from "axios";
import { useUserContext } from "../../../context/user/UserContext";
import { useAuth } from "../../../context/auth/AuthContext";

//FilePond
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import ProcessingSpinner from "../../Shared/Spinner/ProcessingSpinner";
import moment from "moment";
import { currencyFormat } from "../../../utils/GeneralUtils";
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

const AddBudgetPopup = ({
  open,
  handleClose,
  handleSaveCallback,
  setSnackBarProps,
  company,
  newBudgetType,
  budgetItems,
}) => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedBudget, setSelectedBudget] = useState(null);
  const [amount, setAmount] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const imageFileTypes = ["jpg", "jpeg", "png", "gif", "svg"];

  const uploadToCloud = async () => {
    let formData = new FormData();
    formData.append("file", selectedFiles[0].file);
    const res = await axios.post(
      "BudgetItems/UploadNewBudgetScreenshot",
      formData,
      {
        headers: { "content-type": "multipart/form-data" },
      }
    );
    return res.data;
  };

  const handleSave = async () => {
    if (!validateNewBudget()) {
      setSnackBarProps({
        open: true,
        severity: "warning",
        text: "Please fill in all the required details",
      });
      return;
    }
    setIsLoading(true);
    var link = await uploadToCloud();
    axios
      .post(
        `BudgetItems/AddToBudget`,
        {
          budgetId: selectedBudget.budgetId,
          amount: amount === "" ? "0" : amount,
          screenshotLink: link,
          approvedBy: selectedContact?.id,
          approvedByUserEmail: user?.email,
        },
        { params: { user: user?.email } }
      )
      .then(() => {
        setSelectedBudget(null);
        setAmount(null);
        setSelectedFiles([]);
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Budget has been created.",
        });
        handleSaveCallback();
        handleClose();
      })
      .catch((e) => {
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to create budget.",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getCompanyContacts = () => {
    axios
      .get(`Companies/GetCompanyContacts/${company.id}`)
      .then((res) => {
        setContacts(res.data);
      })
      .catch((e) => {
        setContacts([]);
      });
  };

  const validateNewBudget = () => {
    if (!setSelectedBudget || amount <= 0 || selectedFiles.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    getCompanyContacts();
    setSelectedBudget(null);
  }, [company]);

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      id="add-budget-dialog"
      onClose={(e, reason) => {
        if (reason !== "backdropClick" && !isLoading) {
          handleClose();
        }
      }}
    >
      <div className="close-and-transfer-dialog-close-icon-container">
        <IconButton
          size="small"
          className="close-and-transfer-dialog-close-icon"
          onClick={() => {
            if (!isLoading) {
              handleClose();
            }
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <DialogTitle>
        <Typography className="roboto-bold add-budget-title">
          {"Add budget"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: "left" }}>
          <Typography className="roboto-regular add-budget-description">
            Fill in the details below and upload a screenshot of your client's
            approval.
          </Typography>
        </DialogContentText>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="add-budget-popup-input-container">
            <Autocomplete
              options={budgetItems.filter(b => b.jobId && b.invoiceVia !== "PROJECKTOR")}
              value={budgetItems.find(
                (bi) => bi.budgetId === setSelectedBudget.budgetId
              )}
              getOptionLabel={(option) =>
                `${option.jobId ? `[${option.jobId}] ` : ""}${
                  option.projectCategory
                } ${
                  !!option.billingDate
                    ? " - " + moment(option.billingDate).format("MMM/YY")
                    : ""
                } - ${currencyFormat(Number(option.remaining))} remaining`
              }
              onChange={(event, newValue) => {
                setSelectedBudget(newValue || null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="roboto-regular"
                  size="small"
                  label="Add To Budget"
                  variant="filled"
                />
              )}
              disableClearable
              fullWidth
            />
          </div>
          <div className="add-budget-popup-input-container">
            <Autocomplete
              value={selectedContact}
              options={contacts}
              getOptionLabel={(option) =>
                `${option.firstName} ${option.lastName}`
              }
              onChange={(e, newValue) => {
                setSelectedContact(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="roboto-regular"
                  label="Approved by (optional)"
                  size="small"
                  fullWidth={true}
                  variant="filled"
                />
              )}
              disabled={isLoading}
              disableClearable={true}
              size="small"
            />
          </div>
          <div
            style={{ display: "flex" }}
            className="add-budget-popup-input-container roboto-medium"
          >
            <TextField
              id="amount"
              variant="filled"
              className="add-budget-text-field"
              label="Amount"
              fullWidth
              style={{ marginRight: 5 }}
              value={amount}
              onChange={(e) => {
                var regexp = /^(\d)*(\.)?(\d{0,2})?$/;
                if (regexp.test(e.target.value) || e.target.value === "") {
                  setAmount(e.target.value);
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              disabled={isLoading}
              size="small"
            />
          </div>
        </div>
        <div className="add-budget-popup-input-container roboto-medium">
          <Typography className="upload-client-header">
            Upload Client Approval
          </Typography>
          <FilePond
            files={selectedFiles}
            onupdatefiles={(files) => {
              if (files[0] && imageFileTypes.includes(files[0].fileExtension)) {
                console.log("Pass");
                setSelectedFiles(files);
              } else {
                setSelectedFiles([]);
              }
            }}
            allowMultiple={false}
            name="screenshot" /* sets the file input name */
            labelIdle='<div class="roboto-regular">
                        <div><span class="filepond--label-action">Click to upload</span> or drag and drop<div>
                        <div class="drag-drop-subline">SVG, PNG, JPG or GIF<div>
                        </div>'
            allowFileTypeValidation={true}
            acceptedFileTypes={["image/*"]}
            labelFileTypeNotAllowed="Must be an image"
            disabled={isLoading}
          />
        </div>
      </DialogContent>
      {isLoading && <ProcessingSpinner duration={60} unitTime="seconds" />}
      <DialogActions>
        {!isLoading && (
          <div className="add-budget-popup-action-container">
            <Button
              style={{ marginRight: 5 }}
              className="default-button-grey add-budget-button"
              onClick={(e) => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ marginLeft: 5 }}
              className="default-button add-budget-button"
              onClick={() => {
                handleSave();
              }}
            >
              Add Budget
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddBudgetPopup;
