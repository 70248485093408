import React, { useEffect, useState } from "react";
import {
  EditorState,
  convertToRaw,
  compositeDecorator,
  convertFromHTML,
  ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { Button, Divider, Typography } from "@material-ui/core";

const InfluencerDeliverableItemCaption = ({ deliverable, handleSaveCopy }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [copy, setCopy] = useState(deliverable.copy);

  useEffect(() => {
    loadCopyEditor();
  }, []);

  const onEditorStateChange = (editorState) => {
    setCopy(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setEditorState(editorState);
  };

  const loadCopyEditor = () => {
    if (deliverable?.copy) {
      const blocksFromHTML = convertFromHTML(deliverable?.copy);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(state, compositeDecorator));
    }
  };

  return (
    <div className="influencer-deliverable-item-copy">
      {deliverable?.copy ? (
        <>
          <Typography
            className="update-deliverables-line-mobile-label"
            variant="h6"
          >
            Caption
          </Typography>
          <Divider />
          <Typography variant="caption">
            <div dangerouslySetInnerHTML={{ __html: deliverable.copy }}></div>
          </Typography>
        </>
      ) : (
        <>
          <Typography
            className="update-deliverables-line-mobile-label"
            variant="h6"
          >
            Caption
          </Typography>
          <Divider />
          <Typography variant="caption">No Caption Added</Typography>
          <Divider />
        </>
      )}
      {/* <Button
          onClick={(e) => handleSaveCopy(deliverable?.id, copy)}
          className="update-deliverables-line-copy-save-btn"
          variant="contained"
          size="small"
        >
          Save Caption
        </Button> */}
    </div>
  );
};

export default InfluencerDeliverableItemCaption;
