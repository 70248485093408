import axios from "axios";
import React, { createContext, useContext, useState } from "react";

const DataContext = createContext();
export const DataContextProvider = ({ children }) => {
  const [industries, setIndustries] = useState([]);
  const [ageCategories, setAgeCategories] = useState([]);

  const getIndustries = async () => {
    const { data } = await axios.get(`Industry/GetIndustries`);
    setIndustries(data);
  };

  const getAgeCategories = async () => {
    const { data } = await axios.get(`People/GetAgeCategories`);
    setAgeCategories(data);
  };

  return (
    <DataContext.Provider
      value={{
        industries,
        ageCategories,
        getIndustries,
        getAgeCategories,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export function useDataContext() {
  return useContext(DataContext);
}
