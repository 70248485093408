import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";
import {
  Button,
  Snackbar,
  TextField,
  Paper,
  AppBar,
  Toolbar,
  Typography,
  Input,
  Select,
  FormControl,
  Fab,
  MenuItem,
  FormHelperText,
  IconButton,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  List,
  InputLabel,
  CircularProgress,
} from "@material-ui/core";
import "../Admin.css";
import Snacky from "../../Shared/Snacky";
import { setSnackBarProps } from "../../../actions/snackbar";
import { connect } from "react-redux";
import Loader from "../../Loader";
import ContractorContract from "./ContractorContract";
import TalentContract from "./TalentContract";
import LocationContract from "./LocationContract";
import LocationRelease from "./LocationRelease";
import TalentRelease from "./TalentRelease";
import MediaManagementContract from "./MediaManagementContract";
import ApprovalsTsAndCs from "./ApprovalsTsAndCs";


const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

export default function ContractsAndReleases() {
  const classes = useStyles();
  const [menu, setMenu] = useState("");
  const [list, setList] = useState([
    "Contractor Contract",
    "Media Management Contract",
    "Talent Contract",
    "Location Contract",
    "Talent Release",
    "Location Release",
    "Approvals Terms And Conditions"
  ]);

  const handleButtonClick = (value) => {
    setMenu(value);
  };

  return (
    <main className={classes.content}>
      <Grid container spacing={2}>
        <Grid item xs={3} md={3} lg={3}>
          <div className="main-content">
            <Typography variant="h4">Contracts & Releases</Typography>
            <Paper className="contract-releases-list">
              <List component="nav">
                {list.map((item) => {
                  return (
                    <ListItem
                      className={menu === item ? "selected-menu" : ""}
                      button
                      key={item}
                      onClick={() => handleButtonClick(item)}
                    >
                      <ListItemText primary={item} />
                    </ListItem>
                  );
                })}
              </List>
            </Paper>
          </div>
        </Grid>
        <Grid item xs={9} md={9} lg={9}>
          {menu === "Contractor Contract" ? (
            <ContractorContract />
          ) : menu === "Talent Contract" ? (
            <TalentContract />
          ) : menu === "Location Contract" ? (
            <LocationContract />
          ) : menu === "Talent Release" ? (
            <TalentRelease />
          ) : menu === "Location Release" ? (
            <LocationRelease />
          ) : menu === "Media Management Contract" ? (
            <MediaManagementContract />
          ) : menu === "Approvals Terms And Conditions" ? (
            <ApprovalsTsAndCs />
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </Grid>
        {/*  */}
      </Grid>
    </main>
  );
}
