export const getAllOrganisations = () => {
  return [
    { id: 1, name: "Magnesium" },
    { id: 2, name: "Socialites" },
    { id: 3, name: "The Social Club" },
    { id: 4, name: "Flying Tiger" },
    { id: 5, name: "People Of Influence" },
    { id: 6, name: "Media X" },
  ];
};

export const getOrganisationName = (organisationId) => {
  switch (organisationId) {
    case 1:
      return "Magnesium";
    case 2:
      return "Socialites";
    case 3:
      return "The Social Club";
    case 4:
      return "Flying Tiger";
    case 5:
      return "People Of Influence";
    case 6:
      return "Media X";
    default:
      return "Unknown";
  }
};

export const internalOrganisationIds = ["3cd0f017-31d0-4d16-ac5c-d3d894d4a45c", 
"ed6383f1-01a9-4656-b842-69fa90bed119", "ed6383f1-01a9-4656-b842-69fa90bed119", "a2b6be95-db30-4d93-a9a4-6f811ede4df4", 
"bd6e5973-3858-4c89-9194-3be95e896828", "d1ae4554-2160-4670-97fa-0c7b6de0c93a","81e08c08-8a50-45c7-a06a-cac16bbee0ab"];