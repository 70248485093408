import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { isEmpty } from "../../../utils/GeneralUtils";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import axios from "axios";
import React, { useEffect, useState } from "react";

const filter = createFilterOptions();

const XeroContactAutocomplete = ({
  organisationId,
  handleOnChange,
  variant,
  showBankAccount = false  ,
  xeroContactId, 
  xeroContactName,
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openCreateContactDialog, setOpenCreateContactDialog] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [newContactName, setNewContactName] = useState("");
  const [bankAccount, setBankAccount] = useState(null);
  const [newContactEmail, setNewContactEmail] = useState(null);
  let timer = null;

  useEffect(() => {
    if(xeroContactId && xeroContactName){
      const xeroContact = {Name: xeroContactName, ContactID: xeroContactId}
      setSelectedContact(xeroContact)
        setOptions([...options, xeroContact]);
    }
  }, [])
  

  const fetchOptions = async (keyword) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/accounting/SearchXeroContact/${keyword}/${organisationId}`
      );
      setOptions(response.data);
    } catch (error) {
      console.error("Error fetching options:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = async (event, value) => {
    // Implement debouncing to delay API call
    clearTimeout(timer); // Clear previous timeout
    if (value) {
      timer = setTimeout(() => {
        fetchOptions(value);
      }, 500);
    } else {
      setOptions([]);
    }
  };

  const handleCreateContact = () => {
    axios
      .post("/accounting/CreateXeroContact", {
        name: newContactName,
        email: newContactEmail,
        bankAccount: bankAccount,
        organisationId: organisationId,
      })
      .then(({ data }) => {
        setSelectedContact(data)
        setOptions([...options, data]);
        handleOnChange(data);
        handleCloseDialog();
      })
      .catch((error) => {
        alert("Unable to create contact");
        console.error("Error creating contact:", error);
      });
  };

  const handleCloseDialog = () => {
    setNewContactName(null);
    setNewContactEmail(null);
    setOpenCreateContactDialog(false);
  };

  return (
    <div>
      {openCreateContactDialog && (
        <Dialog
          maxWidth="sm"
          open={openCreateContactDialog}
          onClose={() => setOpenCreateContactDialog(false)}
        >
          <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
            Create New Contact
          </DialogTitle>
          <DialogContent
            style={{ display: "flex", flexDirection: "column", gap: 15 }}
          >
            <TextField
              label="Contact Name"
              variant="outlined"
              fullWidth
              size="small"
              error={isEmpty(newContactName)}
              value={newContactName || ""}
              onChange={(e) => setNewContactName(e.target.value)}
            />
            <TextField
              label="Email Address"
              variant="outlined"
              fullWidth
              size="small"
              value={newContactEmail || ""}
              onChange={(e) => setNewContactEmail(e.target.value)}
            />
            {showBankAccount && (
              <TextField
                label="Bank Account"
                variant="outlined"
                fullWidth
                size="small"
                value={bankAccount || ""}
                onChange={(e) => setBankAccount(e.target.value)}
              />
            )}
            <div style={{ display: "flex", gap: 20 }}>
              <Button
                variant="contained"
                onClick={handleCloseDialog}
                size="small"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                color="primary"
                disabled={isEmpty(newContactName)}
                onClick={handleCreateContact}
              >
                Create Contact
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
      <Autocomplete
        options={options}
        loading={loading}
        value={selectedContact}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return newContactName;
          }

          // Regular option
          return option.Name;
        }}
        onInputChange={handleInputChange}
        onChange={(e, newValue) => {
          if (newValue?.inputValue) {
            setNewContactName(newValue.inputValue);
            setOpenCreateContactDialog(true);
          } else {
            setSelectedContact(newValue || null)
            handleOnChange(newValue || null);
          }
        }}
        filterOptions={(options, params) => {
          // if (params.inputValue === "") {
          //   return options;
          // }
          const filtered = filter(options, params);
          // if(!loading && options.length === 0){
          //   const createOption = [
          //     {
          //       inputValue: params.inputValue,
          //       title: `Create "${params.inputValue}"`,
          //     },
          //   ];
          //   return createOption.concat(filtered);
          // }
          var filteredArr = [
            {
              inputValue: params.inputValue,
              title: `Add New Supplier`,
            },
          ];
          return filteredArr.concat(filtered);
        }}
        renderOption={(option, state) => {
          if (option?.title === "Add New Supplier") {
            return (
              <Button
                size="small"
                variant="contained"
                color="primary"
                fullWidth
                onClick={(e) => {
                  setNewContactName(option.inputValue);
                  setOpenCreateContactDialog(true);
                }}
                //style={{ position: "fixed", bottom: -10, right: -1 }}
                //, width: "100%", backgroundColor: "white"
              >
                Add New Supplier
              </Button>
            );
          } else {
            return (
              <li key={option?.ContactID}>
                <div>
                  <span>{option.Name ? option.Name : option.title}</span>
                </div>
              </li>
            );
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search Supplier"
            variant={variant ? variant : "filled"}
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress size={12} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export default XeroContactAutocomplete;
