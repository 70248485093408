import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  activeItemsForProject: [],
};

const closeProject = createReducer(initialState, {
  GET_ALL_PROJECT_ACTIVE_ITEMS: (state, action) => {
    let { payload } = action;
    return {
      ...state,
      activeItemsForProject: payload,
    };
  },
  EDIT_ACTIVE_ITEM: (state, action) => {
    let { payload } = action;
    return {
      ...state,
      activeItemsForProject: state.activeItemsForProject.map((aItem) =>
        aItem.id === payload.id ? aItem.status === payload.value : aItem
      ),
    };
  },
  SPLICE_ACTIVE_ITEM: (state, action) => {
    let { payload } = action;
    return {
      ...state,
      activeItemsForProject: state.activeItemsForProject.filter(
        (i) => i.id != payload.id
      ),
    };
  },
});

export default closeProject;
