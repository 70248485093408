/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles, styled, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  getLowerCasePeople,
  getContractors,
  getTalent,
  getAllPeople,
} from "../../../../actions/people";
import { setSnackBarProps } from "../../../../actions/snackbar";
import { Fab, Menu, MenuItem } from "@material-ui/core";
import { PersonAdd } from "@material-ui/icons";
import AddContactPopUp from "../NewContact/AddContactPopUp";
import "date-fns";
import ContractorPopUp from "../../ProjectOutputs/PopUps/ContractorPopUp"; //./ProjectOutputs/PopUps/ContractorPopUp
import ExistingContactsPopup from "../NewContact/ExistingContactsPopup";
import Snacky from "../../../Shared/Snacky";
import { setDialogProps } from "../../../../actions/dialog";
import { postScheduleItem } from "../../../../actions/scheduleItems";
import { useUserContext } from "../../../../context/user/UserContext";
import { useAuth } from "../../../../context/auth/AuthContext";
import AddNewScheduleItemForm from "../ScheduleItem/AddNewScheduleItemForm";
import { isEmpty } from "../../../../utils/GeneralUtils";
import moment from "moment";

const StyledFab = withStyles((theme) => ({
  root: {
    background: "#000000",
    color: "white",
    "&:hover": {
      background: "#757575",
    },
  },
}))(Fab);

//---------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
  margin: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  alignRight: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

const NewPrePost = (props) => {
  const { user } = useAuth();
  const { project } = useUserContext();

  const classes = useStyles();
  const [addContactModalState, setAddContactModalState] = useState(false);
  const [personFk, setPersonId] = useState();
  const [openContractorPopup, setOpenContractorPopUp] = useState(false);
  const [providerType, setProviderType] = useState(null);
  const [scheduleItem, setScheduleItem] = useState({
    startTime: new Date(),
  });
  const [isSavingScheduleItem, setIsSavingScheduleItem] = useState(false);
  const [contextMenuCategory, setContextMenuCategory] = useState(null);
  //----------------------
  const [existingContactsModalState, setExistingContactsModalState] =
    useState(false);
  const [duplicates, setDuplicates] = useState([]);
  const [newContact, setNewContact] = useState([]);
  const [goAhead, setGoAhead] = useState(false);

  const handleExistingContactModal = () => {
    if (existingContactsModalState) {
      setExistingContactsModalState(false);
    } else {
      setExistingContactsModalState(true);
    }
  };

  useEffect(() => {
    props.getAllPeople();
  }, []);

  const handleEditExisting = (contact) => {
    setPersonId(contact.id);
    setOpenContractorPopUp(true);
  };

  const handleContinue = () => {
    setGoAhead(true);
    setExistingContactsModalState(false);
    setAddContactModalState(true);
  };

  const selectExisting = (contact) => {
    let contactToAdd = {
      firstName: contact.firstName,
      lastName: contact.lastName,
      id: contact.id,
    };
    selectNewPerson(contactToAdd);
    handleExistingContactModal();
  };

  const closePopUps = () => {
    setOpenContractorPopUp(false);
  };

  useEffect(() => {
    if (duplicates.length > 0) {
      setAddContactModalState(false);
      setExistingContactsModalState(true);
    }
  }, [duplicates]);

  const handleAddContactModal = () => {
    if (addContactModalState) {
      setAddContactModalState(false);
    } else {
      setAddContactModalState(true);
    }
  };

  const handleChangeProviderType = (type) => {
    props.getLowerCasePeople(type);
    if (type === "Contractor") {
      props.getContractors();
    } else {
      props.getTalent();
    }
    setProviderType(type);
    handleAddContactModal();
  };

  const selectNewPerson = (newPerson) => {
    console.log("selectNewPerson", newPerson);
    setScheduleItem({
      ...scheduleItem,
      personFk: newPerson.id,
      personFkNavigation: newPerson,
      scheduleItemType: newPerson.personCategory,
    });
  };

  const checkAllFieldsValid = () => {
    if (
      scheduleItem.allocatedRates === null ||
      isEmpty(scheduleItem.category) ||
      !scheduleItem.budgetId ||
      (scheduleItem.category !== "Unpaid" &&
        Number(scheduleItem.allocatedRates) === 0) ||
      isEmpty(scheduleItem.scheduleItemType) ||
      !scheduleItem.roleFk
    ) {
      props.setSnackBarProps("warning", "Missing fields", true);
      return false;
    } else {
      return true;
    }
  };

  const submitNewScheduleItem = () => {
    if (checkAllFieldsValid()) {
      setIsSavingScheduleItem(true);
      let url = `/ScheduleItems/PostScheduleItemFromPrePost/${props.project.id}`;
      if (scheduleItem.scheduleItemType === "Talent") {
        url = `/ScheduleItems/PostTalentScheduleItemFromPrePost/${props.project.id}`;
      }
      axios
        .post(url, {
          category: scheduleItem.category || "",
          scheduleItemType: scheduleItem.scheduleItemType,
          budgetId: scheduleItem.budgetId || null,
          startTime: moment(scheduleItem.startTime).format("YYYY-MM-DD"),
          allocatedRates: scheduleItem.allocatedRates || 0,
          allocatedRatesInNzd: scheduleItem.allocatedRatesInNzd || 0,
          providerName:
            scheduleItem.personFkNavigation?.firstName +
            " " +
            scheduleItem.personFkNavigation?.lastName,
          isStaff:
            scheduleItem.personFkNavigation?.personCategory === "Staff"
              ? 1
              : scheduleItem.personFkNavigation?.personCategory === "Talent"
              ? 2
              : 0,
          isClient: 0,
          prePostNotes: "-",
          companyFk: project?.companyFk ? project?.companyFk : null,
          personFk: scheduleItem.personFk,
          contractStatus: scheduleItem?.personFkNavigation?.currency !== "NZD" ? "Send" :
            scheduleItem.scheduleItemType === "Talent" ? "Disabled" : "Send",
          talentContractType: scheduleItem?.personFkNavigation?.currency !== "NZD" ? "BuyOut" :
            scheduleItem.scheduleItemType === "Talent"
              ? "Restricted"
              : scheduleItem.scheduleItemType === "Contractor"
              ? "BuyOut"
              : null,
          talentContractStatus:
            scheduleItem.scheduleItemType === "Talent" ? "Incomplete" : null,
          roleFk: scheduleItem.roleFk || null,
          createdBy: user?.email,
        })
        .then((response) => {
          props.passNewScheduleUp(response.data);
          props.setSnackBarProps(
            "success",
            "All done! Your record has been saved.",
            true
          );
          setScheduleItem({ startTime: new Date() });
        })
        .catch((error) => {
          props.setSnackBarProps(
            "warning",
            "Oh no! Something went wrong.",
            true
          );
          console.log(error);
        })
        .finally(() => setIsSavingScheduleItem(false));
    }
  };

  const handleContextMenuCategory = (event) => {
    event.preventDefault();
    setContextMenuCategory(
      contextMenuCategory === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : null
    );
  };

  return (
    <div className={classes.root}>
      <Snacky
        snackprops={props.snackbar}
        setSnackBarProps={props.setSnackBarProps}
      />
      {props.project.isArchived !== 1 && (
        <StyledFab
          size="small"
          style={{ position: "absolute", zIndex: "10", marginTop: "-20px" }}
          onClick={handleContextMenuCategory}
        >
          <PersonAdd />
          <Menu
            open={contextMenuCategory !== null}
            onClose={() => setContextMenuCategory(null)}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenuCategory !== null
                ? {
                    top: contextMenuCategory.mouseY,
                    left: contextMenuCategory.mouseX,
                  }
                : undefined
            }
          >
            <MenuItem onClick={() => handleChangeProviderType("Talent")}>
              Create New Talent
            </MenuItem>
            <MenuItem onClick={() => handleChangeProviderType("Contractor")}>
              Create New Contractor
            </MenuItem>
          </Menu>
        </StyledFab>
      )}
      {existingContactsModalState && (
        <ExistingContactsPopup
          handleModal={() => handleExistingContactModal()}
          duplicates={duplicates}
          handleEdit={handleEditExisting}
          modalState={existingContactsModalState}
          setNewContact={setNewContact}
          handleContinue={handleContinue}
          selectExisting={selectExisting}
        />
      )}
      {addContactModalState && providerType != "" ? (
        <AddContactPopUp
          handleModal={() => handleAddContactModal()}
          handleAddContactModal={() => handleAddContactModal()}
          modalState={addContactModalState}
          category={providerType}
          selectNewPerson={selectNewPerson}
          people={props.people}
          setDuplicates={setDuplicates}
          goAhead={goAhead}
          setGoAhead={setGoAhead}
          goAheadContact={newContact}
          setNewContact={setNewContact}
          parent={"NewScheduleItem"}
        />
      ) : (
        ""
      )}
      {openContractorPopup && (
        <ContractorPopUp
          handleModal={() => setOpenContractorPopUp(false)}
          modalState={openContractorPopup}
          personId={personFk}
          reloadPersonList={props.reloadPersonList}
          type="NewScheduleItem"
          providerType={props.providerType}
          closePopUps={() => closePopUps()}
          selectExisting={selectExisting}
        />
      )}
      <AddNewScheduleItemForm
        budgetItems={props.budgetItems}
        scheduleItem={scheduleItem}
        setScheduleItem={setScheduleItem}
        handleSubmitForm={submitNewScheduleItem}
        isSavingScheduleItem={isSavingScheduleItem}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  snackbar: state.snackbar,
  people: state.people,
  dialog: state.dialog,
});
// https://react-redux.js.org/api/connect
export default connect(mapStateToProps, {
  setSnackBarProps,
  getLowerCasePeople,
  getContractors,
  getTalent,
  getAllPeople,
  setDialogProps,
  postScheduleItem,
})(NewPrePost);
