import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Axios from "axios";
import {
    Modal,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    FormControl,
    FormLabel,
    Switch,
    Grid,
    TextField,
    Button,
    Snackbar
} from "@material-ui/core";
import { getValueOrEmptyString } from "../../../utils/utils";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        minHeight: 390,
        width: "25vW",
        overflow: "hidden"
    },
}));

function AgentPopUp(props) {
    const classes = useStyles();
    const agent = props.selectedContact;

    const [isEditing, setIsEditing] = useState(false);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [emailAddress, setEmailAddress] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [enableSms, setEnableSms] = useState(null);

    const getTextField = (text, value) => {
        return (
            <div className="textfield-padding">
                <TextField
                    id={text}
                    disabled={!isEditing}
                    label="Outlined"
                    variant="outlined"
                    label={text}
                    value={value.toString()}
                    onChange={(event) => handleChange(event, text)}
                    fullWidth
                    size="small"
                    error={value === null || value.toString().trim() === ""}
                />
            </div>
        )
    };

    const getSmsSwitch = () => {
        return (
            <Grid
                component="label"
                container
                alignItems="center"
                spacing={1}
                className="agent-sms-switch"
            >
                <Grid
                    item
                    style={
                        enableSms === 1
                            ? { color: "grey" }
                            : {
                                borderBottom: "solid",
                                borderColor: isEditing ? "#3f51b5" : "grey",
                            }
                    }
                >
                    Disable SMS Notifications
                        </Grid>
                <Grid item>
                    <Switch
                        color="primary"
                        checked={enableSms === 1 ? true : false}
                        name="enableSms"
                        disabled={isEditing ? false : true}
                        onChange={handleEnableSms}
                        className={isEditing ? "blueSwitch" : ""}
                    />
                </Grid>
                <Grid
                    item
                    style={
                        enableSms === 1
                            ? {
                                borderBottom: "solid",
                                borderColor: isEditing ? "#3f51b5" : "grey",
                            }
                            : { color: "grey" }
                    }
                >
                    Enable SMS Notifcations
                </Grid>
            </Grid>
        )
    }

    const handleChange = (event, text) => {
        switch (text) {
            case "FirstName":
                setFirstName(event.target.value);
                break;
            case "LastName":
                setLastName(event.target.value);
                break;
            case "EmailAddress":
                setEmailAddress(event.target.value);
                break;
            case "PhoneNumber":
                setPhoneNumber(event.target.value);
                break;
            default:
                break;
        }
    };

    const handleEnableSms = (event) => {
        if (event.target.checked) {
            setEnableSms(1);
        } else {
            setEnableSms(0);;
        }
    };

    const handleSave = () => {
        props.rowUpdated();
        var newAgent = agent;
        newAgent["firstName"] = firstName;
        newAgent["lastName"] = lastName;
        newAgent["emailAddress"] = emailAddress;
        newAgent["phoneNumber"] = phoneNumber;
        newAgent["enableSms"] = enableSms;

        props.handleEditAgent(newAgent);
    }

    useEffect(() => {
        setFirstName(agent.firstName);
        setLastName(agent.lastName);
        setEmailAddress(agent.emailAddress);
        setPhoneNumber(agent.phoneNumber);
        setEnableSms(agent.enableSms);
    }, [])

    return (
        <Modal
            open={props.modalState}
            onClose={props.handleModal}
            className={classes.modal}
        >
            <div>
                <AppBar position="static" style={{ background: "#217F8B" }}>
                    <Toolbar variant="dense" style={{ paddingRight: 0 }}>
                        <Typography
                            variant="subtitle1"
                            align="center"
                            style={{ flexGrow: 1 }}
                        >
                            Agent Details
                        </Typography>
                        <span style={{ display: "flex" }}>
                            <IconButton
                                variant="contained"
                                style={
                                    isEditing
                                        ? {}
                                        : {
                                            display: "none",
                                            color: "black",
                                        }
                                }
                                onClick={() => {
                                    handleSave();
                                    setIsEditing(false);
                                }}
                            >
                                <SaveIcon />
                            </IconButton>
                            <IconButton
                                variant="contained"
                                style={
                                    isEditing
                                        ? {
                                            display: "none",
                                            color: "black",
                                        }
                                        : {}
                                }
                                onClick={() => setIsEditing(true)}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                onClick={props.handleModal}
                            >
                                <CloseIcon />
                            </IconButton>
                        </span>
                    </Toolbar>
                </AppBar>
                <div
                    className={classes.paper}
                >
                    {getTextField("FirstName", getValueOrEmptyString(firstName))}
                    {getTextField("LastName", getValueOrEmptyString(lastName))}
                    {getTextField("EmailAddress", getValueOrEmptyString(emailAddress))}
                    {getTextField("PhoneNumber", getValueOrEmptyString(phoneNumber))}
                    {getSmsSwitch()}
                </div>
            </div>
        </Modal>
    );
}

export default AgentPopUp;