import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

export const DeleteItemDialog = (props) => {
  let {
    handleDeleteItemDialogClose,
    openDeleteItemDialog,
    setShowInvoicesModal,
    deleteScheduleItem,
    setSnackBarProps,
    scheduleItem,
  } = props;
  return (
    <Dialog
      open={openDeleteItemDialog}
      onClose={() => handleDeleteItemDialogClose()}
    >
      <DialogTitle id="delete-scheduleItem-dialog-title">
        {"Are you sure you want to delete this item?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-scheduleItem-dialog-description">
          Deleting this will permanantly remove it from database
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleDeleteItemDialogClose()} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (scheduleItem.contractStatus === "Invoiced") {
              handleDeleteItemDialogClose();
              setShowInvoicesModal(true);
            } else {
              deleteScheduleItem(scheduleItem);
              setSnackBarProps("success", "Item deleted", true);
              handleDeleteItemDialogClose();
            }
          }}
          color="secondary"
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const InvoicesDialog = (props) => {
  let {
    setShowInvoicesModal,
    showInvoicesModal,
    setActiveScheduleItem,
  } = props;
  return (
    <Dialog
      open={showInvoicesModal}
      onClose={() => setShowInvoicesModal(false)}
    >
      <DialogTitle style={{ textAlign: "center" }}>
        Sorry, can't delete this item
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: "center" }}>
          It has already been invoiced
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setShowInvoicesModal(false);
            setActiveScheduleItem();
          }}
          color="primary"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
