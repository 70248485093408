import React, { useEffect, useState } from "react";
import {
    Container,
    Typography,
    Paper,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@material-ui/core";
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
    Edit as EditIcon,
    PictureAsPdf as PictureAsPdfIcon
} from "@material-ui/icons";
import moment from "moment";
import { currencyFormat } from "../../../..//utils/GeneralUtils"

const MasterPageMediaInvoiceTable = ({
    mediaInvoices
}) => {
    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Invoice Number</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Reference</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Subtotal</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {mediaInvoices.map((mediaInvoice) => (
                    <TableRow>
                        <TableCell>
                            <Typography variant="caption">
                                {mediaInvoice.invoiceNumber}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="caption">
                                {moment(mediaInvoice.date).format("DD/MM/YYYY")}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="caption">
                                {mediaInvoice.reference !== ""
                                    ? mediaInvoice.reference
                                    : "-"}
                                {/* {mediaInvoice.status === "DRAFT" ||
                                    mediaInvoice.status === "SUBMITTED" ? (
                                    <EditIcon
                                        fontSize="small"
                                        style={{
                                            cursor: "pointer",
                                            marginLeft: 10,
                                        }}
                                    //onClick={(e) => { handleReferenceDialog(mediaInvoice) }}
                                    />
                                ) : (
                                    ""
                                )} */}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="caption">{mediaInvoice.status}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="caption">
                                {currencyFormat(Number(mediaInvoice.subtotal))}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            {mediaInvoice.xeroInvoiceId && (
                                <IconButton
                                    aria-label="pdf"
                                    size="small"
                                    onClick={(e) => { }
                                        //handleOpenPdf(e, mediaInvoice.xeroInvoiceId)
                                    }
                                >
                                    <PictureAsPdfIcon />
                                </IconButton>
                            )}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default MasterPageMediaInvoiceTable;