import React, { useEffect, useState } from "react";
import {
    DirectionsCar as DirectionsCarIcon,
    Close as CloseIcon
} from "@material-ui/icons";
import axios from "axios";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
    Tooltip,
    Typography,
    IconButton,
    Button,
    CircularProgress,
    Modal,
    AppBar,
    Toolbar,
    Grid,
    TextField
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getScheduleItems } from "../../../../../actions/scheduleItems";
import { connect } from "react-redux";

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        height: "auto",
        width: "600px",
        overflowY: "auto",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    container: {
        maxHeight: 350,
    }
}));

const ReimbursmentPopup = (props) => {
    const {
        modalState,
        handleCloseModal,
        accounts,
        travelDistance,
        travelContribution,
        disableFields,
        scheduleItem,
        getScheduleItems,
        getBudget,
        projectId,
        scheduleId,
        handleSnacky
    } = props;

    const classes = useStyles();

    const [travelContributionFee, settravelContributionFee] = useState({
        account: null,
        fee: null,
    });

    const [loading, setloading] = useState(false);

    const handleTravelContribution = (field, value) => {
        settravelContributionFee({
            ...travelContributionFee,
            [field]: value,
        });
    };

    const handleSave = () => {
        setloading(true);
        axios.post(`TravelReimbursments/PostTravelReimbursment/${scheduleItem.id}`, {
            travelContributionFee: travelContributionFee == null ? null : travelContributionFee.fee,
            travelContributionAccount: travelContributionFee.account !== null ? travelContributionFee.account.costAccount : null,
            travelContributionAccountName: travelContributionFee.account !== null ? travelContributionFee.account.category : null,
        }).then((res) => {
            getScheduleItems(scheduleId);
            getBudget(projectId);
            handleCloseModal();
            setloading(false);
            handleSnacky(true, "Success");
        })
            .catch((err) => {
                console.log(err);
                setloading(false);
                handleSnacky(true, "Failed");
            });
    }

    useEffect(() => {
        if (accounts) {
            var copyOfAccounts = [...accounts];
            var foundAccount = copyOfAccounts.findIndex(a => a.costAccount === scheduleItem.travelReimbursment?.accountCode);
            console.log("copyOfAccounts[foundAccount]");
            console.log(copyOfAccounts[foundAccount]);
            settravelContributionFee({
                ...travelContributionFee,
                ["account"]: foundAccount >= 0 ? copyOfAccounts[foundAccount] : null,
                ["fee"]: scheduleItem.travelReimbursment ? `${scheduleItem.travelReimbursment?.amount}` : null
            })
        }
    }, [accounts]);

    return (
        <div>
            <Modal
                open={modalState}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleCloseModal()
                    }
                }}
                className={classes.modal}
            >
                <div>
                    <AppBar position="static" style={{ background: "#217F8B" }}>
                        <Toolbar disableGutters variant="dense">
                            <Typography
                                variant="subtitle1"
                                align="center"
                                style={{ flexGrow: 1 }}
                            >
                                {`Travel Contribtion`}
                            </Typography>
                            <IconButton
                                onClick={() => handleCloseModal()}
                                color="inherit"
                                disabled={loading}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.paper}>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Grid container spacing={3}>
                                <Grid item xs={9}>
                                    <HtmlTooltip
                                        title={
                                            <Typography color="inherit">
                                                Change this if you want to pay for the travel
                                                contribution out of another account
                                            </Typography>
                                        }
                                        interactive
                                        arrow
                                        placement="left"
                                    >
                                        <Autocomplete
                                            id="charge-accounts"
                                            options={accounts}
                                            selectOnFocus
                                            fullWidth={true}
                                            autoHighlight={true}
                                            autoSelect={true}
                                            onChange={(event, newValue) => {
                                                handleTravelContribution("account", newValue);
                                            }}
                                            size="small"
                                            getOptionLabel={(option) => `${option.category}`}
                                            value={travelContributionFee.account}
                                            disabled={
                                                Number(travelDistance) <
                                                travelContribution.travelDistance || disableFields
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Budget account"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </HtmlTooltip>
                                </Grid>
                                <Grid item xs={3}>
                                    <HtmlTooltip
                                        title={
                                            <Typography color="inherit">
                                                Enter the travel contribution here
                                            </Typography>
                                        }
                                        interactive
                                        arrow
                                        placement="left"
                                    >
                                        <TextField
                                            fullWidth
                                            label="Contribution"
                                            size="small"
                                            variant="outlined"
                                            onChange={(e) =>
                                                handleTravelContribution("fee", e.target.value)
                                            }
                                            value={travelContributionFee.fee}
                                            disabled={
                                                Number(travelDistance) <
                                                travelContribution.travelDistance || disableFields
                                            }
                                        ></TextField>
                                    </HtmlTooltip>
                                </Grid>
                                {Number(travelDistance) >=
                                    travelContribution.travelDistance && (
                                        <Grid item xs={12}>
                                            <div style={{ textAlign: "right" }}>
                                                <Typography color="secondary">
                                                    The recommended contribution is $
                                                    {(
                                                        Number(travelDistance) * travelContribution.rate
                                                    ).toFixed(2)}
                                                </Typography>
                                            </div>
                                        </Grid>
                                    )}
                                {Number(travelDistance) < travelContribution.travelDistance && (
                                    <Grid item xs={12}>
                                        <Typography
                                            color="secondary"
                                            align="right"
                                            style={{ textAlign: "right" }}
                                        >
                                            {`* This distance is below Magnesiums travel reimbursement
                      distance. Only distances greater than ${travelContribution.travelDistance}km should be
                      contributed to.`}
                                        </Typography>
                                    </Grid>
                                )}
                                {!(Number(travelDistance) < travelContribution.travelDistance) && !(disableFields) && (
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => {
                                                    settravelContributionFee({
                                                        account: null,
                                                        fee: null,
                                                    });
                                                }}
                                            >
                                                Remove Travel Contribution
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => handleSave()}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </React.Fragment>
                                )}
                            </Grid>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

//export default ReimbursmentPopup;
const mapStateToProps = (state, ownProps) => ({});
export default connect(mapStateToProps, {
    getScheduleItems,
})(ReimbursmentPopup);
