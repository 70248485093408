import React, { useEffect, useState } from "react";
import {
    Close as CloseIcon,
    CloudUpload as CloudUploadIcon
} from "@material-ui/icons";
import {
    AppBar,
    Button,
    Dialog,
    DialogTitle,
    IconButton,
    Modal,
    Toolbar,
    Typography,
    Grid
} from "@material-ui/core"
import "./statisticsPopup.scss";
import FilePreview from "../../Shared/FilePreview";
import axios from "axios";
import ProcessingSpinnerDialog from "../../Shared/Spinner/ProcessingSpinnerDialog";
import { v1 as uuidv1 } from "uuid";
import Snacky from "../../Shared/Snacky";

const StatisticsPopup = ({
    open,
    handleClose,
    deliverableFiles,
    deliverableId,
    handleMarkAsCompleteCallback,
    isReadOnly = false
}) => {
    const [statFiles, setStatFiles] = useState([]);
    const [loadStatFiles, setLoadFileStats] = useState(true);
    const [showProcessingSpinnerDialog, setShowProcessingSpinnerDialog] = useState(false);
    const [snackBarProps, setSnackBarProps] = useState({
        open: false,
        severity: "success",
        text: "",
    });

    useEffect(() => {
        if (loadStatFiles) {
            setLoadFileStats(false);
            axios.get(`Services/GetDeliverableStatistics/${deliverableId}`)
                .then(res => {
                    setStatFiles(res.data)
                }).catch(e => {
                    console.log(e)
                })
        }
    }, [loadStatFiles])

    const handleStatsUpload = async (selectedFile) => {
        setShowProcessingSpinnerDialog(true);
        const bucketName = "ts-influencer-content";
        const fileName = `${selectedFile.name}`
        const guid = uuidv1();
        const url = `https://storage.googleapis.com/${bucketName}/${guid}-${fileName}`;
        const response = await fetch(url, {
            method: "PUT",
            body: selectedFile,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        });

        if (response.ok) {
            await axios.post("Services/UploadDeliverableFile", {
                "deliverableId": deliverableId,
                "fileUrl": `https://storage.googleapis.com/${bucketName}/${guid}-${fileName}`,
                "fileName": fileName,
                "isStats": 1
            }).then(res => {
                setSnackBarProps({
                    open: true,
                    severity: "success",
                    text: "All done! The file has been uploaded.",
                });
                setLoadFileStats(true)
            }).catch(e => {
                setSnackBarProps({
                    open: true,
                    severity: "warning",
                    text: "Error. Could not upload file.",
                });
            }).finally(() => {
                setShowProcessingSpinnerDialog(false);
            })
        } else {
            setShowProcessingSpinnerDialog(false);
        }
    }

    const handleMarkAsComplete = () => {
        axios.post(`Services/MarkDeliverableStatsAsComplete/${deliverableId}`)
            .then(res => {
                handleMarkAsCompleteCallback();
            }).catch(e => {
                console.log(e)
            })
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
            <Snacky snackprops={snackBarProps} setSnackBarProps={setSnackBarProps} />
            {showProcessingSpinnerDialog && (
                <ProcessingSpinnerDialog loading={showProcessingSpinnerDialog} />
            )}
            <AppBar position="static" style={{ background: "#217F8B", width: "100%" }}>
                <Toolbar variant="dense" style={{ paddingRight: 0 }}>
                    <Typography
                        variant="subtitle1"
                        align="center"
                        style={{ flexGrow: 1 }}
                    >
                        Statistics
                    </Typography>
                    <IconButton onClick={handleClose} color="inherit">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div className="statistics-popup-body-container">
                <Grid container spacing={1}>
                    <Grid item xs={10} md={10} lg={10}>
                        {statFiles.length > 0 && (
                            <div style={{
                                display: "flex",
                                overflowX: "auto",
                            }}>
                                {statFiles.map((file, index) => {
                                    if (file.isStats == 1) {
                                        return (
                                            <div style={{ padding: "0px 2px" }}>
                                                <FilePreview fileUrl={file.driveId} showControls={false}
                                                    containerClassName="statistics-popup-file-containier" />
                                            </div>
                                        );
                                    } else {
                                        return "";
                                    }
                                }
                                )}
                            </div>
                        )}
                        {statFiles.length === 0 && (
                            <React.Fragment>
                                <Typography>No statistics have been uploaded.</Typography>
                                <Typography>Please get the influencer to upload the stats or manually upload them here using the upload button.</Typography>
                            </React.Fragment>
                        )}
                    </Grid>
                    <Grid item xs={2} md={2} lg={2} alignItems="flex-end" style={isReadOnly ? {display: "none"} : {}}>
                        <div style={{ marginBottom: 10 }}>
                            <input
                                accept="video/*, image/*"
                                className={"upload-deliverables-upload-button"}
                                id={`contained-button-file-upload-statistics`}
                                type="file"
                                onChange={(e) => handleStatsUpload(e.target.files[0])}
                            />
                            <label htmlFor={`contained-button-file-upload-statistics`}>
                                <Button
                                    variant="contained" color="primary"
                                    size="small" component="span" fullWidth
                                    startIcon={<CloudUploadIcon />}
                                >
                                    Upload Stats
                                </Button>
                            </label>
                        </div>
                        <div>
                            <Button variant="contained" color="primary"
                                size="small" component="span" fullWidth
                                onClick={() => handleMarkAsComplete()}>
                                <span style={{ textAlign: "center" }}>Mark as Complete</span>
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Dialog>
    )
}

export default StatisticsPopup;