import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  createMuiTheme,
  Chip,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import DeliverPopup from "./PopUps/DeliverPopUp";
import { forwardTransform } from "./outputsWrapperUtils";
import OutputEditPopup from "./PopUps/OutputEditPopup";
import axios from "axios";

import PropTypes from "prop-types";

import "./tableStyles.css";
import "./DatePickerCustom.css";
import "./Outputs.scss";

import {
  FormControl,
  Table,
  AppBar,
  Toolbar,
  Typography,
  TableBody,
  TableHead,
  TableSortLabel,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  IconButton,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Input,
  Menu,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  Tooltip,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { FileCopy, Delete, Edit, AddCircle, Save } from "@material-ui/icons";
import daysLeft from "../../../utils/workingDaysLeft";
import DeleteOutputErrorPopup from "./PopUps/DeleteOutputErrorPopup";
import { Skeleton } from "@material-ui/lab";

// import ContextMenu from "react-context-menu";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  body: {
    padding: "20px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  root: {
    padding: "0px 10px 0px 10px",
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);
const StyledInput = withStyles((theme) => ({
  root: {
    fontSize: "14px",
  },
}))(Input);
const StyledTypography = withStyles((theme) => ({
  root: {
    fontSize: "14px",
  },
}))(Typography);

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
});

const initialState = {
  mouseX: null,
  mouseY: null,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "Days Left",
    align: "left",
    disablePadding: false,
    label: "T-",
    className: "output-header-days-left",
  },
  {
    id: "Creative Deadline",
    align: "left",
    disablePadding: false,
    label: "Deadline",
    className: "output-header-creative-deadline",
  },
  {
    id: "Ad Duration",
    align: "left",
    disablePadding: false,
    label: "Dur",
    className: "output-header-ad-deadline",
  },
  {
    id: "Creative Name",
    align: "left",
    disablePadding: false,
    label: "Creative Name",
    className: "output-header-creative-name",
  },
  { id: "Platform", align: "left", disablePadding: false, label: "Platform" },
  {
    id: "Publisher",
    align: "left",
    disablePadding: false,
    label: "Publisher",
    className: "output-header-publisher",
  },
  {
    id: "audience",
    align: "left",
    disablePadding: false,
    label: "Target Audience",
    className: "output-header-target-audience",
  },
  {
    id: "Key Number",
    align: "left",
    disablePadding: false,
    label: "Key # (automatically generated on save)",
    className: "output-header-key-number",
  },
  {
    id: "Notes",
    align: "left",
    disablePadding: false,
    label: "Notes",
    className: "output-header-notes",
  },
  {
    id: "action",
    align: "center",
    disablePadding: false,
    label: "Actions",
    className: "output-header-actions",
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => {
          if (headCell.id === "action") {
            if (!props.editMode) {
              return "";
            }
          }
          return (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              padding={headCell.disablePadding ? "none" : "default"}
              sortDirection={orderBy === headCell.id ? order : false}
              className={`${headCell.className} output-header`}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {index !== headCells.length - 1
                  ? headCell.label
                  : props.editMode
                  ? headCell.label
                  : ""}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export const DynamicDropdownPublisher = (props) => {
  const [publisher, setPublisher] = useState(
    props.row.publisher === "" || props.row.publisher == null
      ? []
      : props.row.publisher.replaceAll(/[\"\[\]"]/g, "").split(",")
  );
  const [rowObj, setRowObj] = useState();

  useEffect(() => {
    props.handleDropDownChange("publisher", publisher.join(","), rowObj);
  }, [publisher]);

  useEffect(() => {
    if (props.row.platform != null) {
      if (
        props.row.platform.indexOf("TV") === -1 &&
        props.row.platform.indexOf("VOD") === -1
      ) {
        setPublisher([]);
      }
    }
  }, []);

  const publishers = ["Discovery", "Sky", "TVNZ"];

  const handleChange = (event, rowObj) => {
    setRowObj(rowObj);
    setPublisher(event.target.value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <div className={"output-dropdown"}>
      <FormControl
        style={{
          display:
            props.row.platform != null &&
            props.row.platform.indexOf("TV") === -1 &&
            props.row.platform.indexOf("VOD") === -1
              ? "none"
              : "",
        }}
      >
        <Select
          labelId="publisher-multiple-checkbox-label"
          id="publisher-multiple-checkbox"
          multiple
          value={publisher}
          disabled={!props.editMode}
          input={<Input />}
          MenuProps={MenuProps}
          disableUnderline={true}
          //renderValue={(selected) => selected.join(", ")}
          renderValue={(selected) => (
            <div className={"output-select-chips"}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  className={"output-select-chip"}
                />
              ))}
            </div>
          )}
          onChange={(event) => handleChange(event, props.row)}
          className={
            publisher.length === 0
              ? "output-table-publisher-dropdown output-table-body-required"
              : "output-table-publisher-dropdown"
          }
        >
          {publishers.map((publisherName) => (
            <MenuItem
              key={publisherName}
              value={publisherName}
              style={{ fontSize: "14px", maxHeight: "18px", margin: "1px" }}
            >
              <Checkbox checked={publisher.indexOf(publisherName) > -1} />
              <ListItemText primary={publisherName} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export const DynamicDropdownPlatform = (props) => {
  const [platform, setPlatform] = useState(
    props.row.platform === "" || props.row.platform == null
      ? []
      : props.row.platform.replaceAll(/[\"\[\]"]/g, "").split(",")
  );
  const [rowObj, setRowObj] = useState();

  const handleChange = (event, rowObj) => {
    setRowObj(rowObj);
    setPlatform(event.target.value);
  };

  useEffect(() => {
    //handlePublisherReset
    if (platform.indexOf("TV") === -1 && platform.indexOf("VOD") === -1) {
      props.handlePublisherReset(platform.join(","), "", rowObj);
    } else {
      props.handleDropDownChange("platform", platform.join(","), rowObj);
    }
  }, [platform]);

  useEffect(() => {
    console.log("props_____");
    console.log(props);
  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 2;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
        maxWidth: 250,
        fontSize: "14px",
      },
    },
  };

  const platforms = [
    "TV",
    "VOD",
    "Youtube",
    "Facebook",
    "Instagram",
    "LinkedIn",
    "Snapchat",
    "Website",
    "Radio",
    "Poster",
    "Photo",
    "External",
    "Cinema",
    "Tik Tok",
  ];

  return (
    <div
      className={
        platform.length === 0
          ? "output-table-body-required output-dropdown"
          : "output-dropdown"
      }
    >
      <FormControl>
        <Select
          multiple
          value={platform === "" ? null : platform}
          input={<Input />}
          MenuProps={MenuProps}
          disabled={!props.editMode}
          //renderValue={(selected) => selected.join(", ")}
          renderValue={(selected) => (
            <div className={"output-select-chips"}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  className={"output-select-chip"}
                />
              ))}
            </div>
          )}
          disableUnderline={true}
          onChange={(event) => handleChange(event, props.row)}
          className={
            platform.length === 0
              ? "output-table-platform-dropdown output-table-body-required"
              : "output-table-platform-dropdown"
          }
        >
          {platforms.map((platformName) => (
            <MenuItem
              key={platformName}
              value={platformName}
              style={{ fontSize: "14px", maxHeight: "18px", margin: "1px" }}
            >
              <Checkbox checked={platform.indexOf(platformName) > -1} />
              <ListItemText primary={platformName} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export const RequiredFieldsDialog = (props) => {
  const handleDialogClose = () => {
    props.handleClose();
    props.handleEditMode(true);
  };
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Ooops!"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You seem to have missed some fields.
          <br /> <br />
          Please complete all required fields(highlighted green) and try again.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default function OtherOutputs(props) {
  const classes = useStyles();
  const [visibleModal, setVisibleModal] = useState(null);
  const [open, setOpen] = useState(false);
  //const [editMode, setEditMode] = useState();
  const [showOtherOutputsTable, setShowOtherOutputsTable] = useState(true);
  const [state, setState] = React.useState(initialState);
  const [outputIndex, setOutputIndex] = React.useState();
  const [showDeliverPopUp, setShowDeliverPopUp] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);

  const [showOutputEditPopUp, setShowOutputEditPopUp] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [deliveryClicked, setDeliveryClicked] = useState(false);

  const handleOpen = (index) => {
    setOpen(true);
    setVisibleModal(index);
  };
  const [rows, setRows] = useState(props.outputs);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [showDeleteOutputError, setShowDeleteOutputError] = useState(false);
  const [scheduleItemsForDeleteOutput, setScheduleItemsForDeleteOutput] =
    useState(null);
  const [outputToDelete, setOutputToDelete] = useState(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleInputChange = (event, rowObj) => {
    props.handleInputChange(event, rowObj);
  };

  const handleDateChange = (name, dateValue, rowObj) => {
    props.handleDateChange(name, dateValue, rowObj);
  };

  const handleCopy = (row, index) => {
    props.handleEditMode(true);
  };

  const handleCopyEmpty = () => {
    props.copyEmpty();
    props.handleEditMode(true);
  };

  const handleToggleEditMode = () => {
    if (props.editMode) {
      props.handleEditMode(false);
    } else {
      props.handleEditMode(true);
    }
  };

  const handleDelete = (row) => {
    setOutputToDelete(null);
    setScheduleItemsForDeleteOutput(null);
    axios
      .delete(`/OutputsV2/DeleteOutput/${row.id}`)
      .then((res) => {
        console.log(res);
        props.removeOutput(row);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data !== "") {
          setOutputToDelete(row);
          setScheduleItemsForDeleteOutput(err.response.data);
          setShowDeleteOutputError(true);
        }
      });
  };

  const handleClose = () => {
    setOpen(false);
    setVisibleModal(null);
  };

  const getUniqueDestinations = (items) => {
    var arr = items.split(";");
    const uniqueValues = [...new Set(arr)];
    var uniqueItems = uniqueValues.join(", ");
    return uniqueItems;
  };

  const splitSemiColon = (items) => {
    var arr = items.split(";");
    const uniqueValues = [...new Set(arr)];

    return uniqueValues;
  };

  const handleClick = (event, index, row) => {
    event.preventDefault();
    event.stopPropagation();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    setOutputIndex(index);
    setSelectedRow(row);
  };

  const handleCloseContext = () => {
    setState(initialState);
  };

  const handleShowDeliverModal = () => {
    setState(initialState);
    toggleShowDeliverPopUp();
  };

  const handleSaveChanges = () => {
    props.handleEditMode(false);
    props.handleSaveChanges(props.outputs);
  };

  const toggleShowDeliverPopUp = () => {
    if (showDeliverPopUp) {
      setDeliveryClicked(false);
      setShowDeliverPopUp(false);
    } else {
      setShowDeliverPopUp(true);
    }
  };

  const toggleShowOutputEditPopUp = () => {
    if (showOutputEditPopUp) {
      setCurrentIndex(null);
      setShowOutputEditPopUp(false);
    } else {
      setShowOutputEditPopUp(true);
    }
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  const handleSetEditFromPopup = () => {};

  const checkIfInvalid = (value) => {
    if (value === null || value === undefined) {
      return true;
    } else if (typeof value === "string") {
      if (value.trim() === "") {
        return true;
      }
    } else {
      return false;
    }
  };

  // const handleSaveEditManualOutput = (output) => {
  //   axios.patch(`OutputsV2/SaveManualOutputs`, {
  //     outputs: [output]
  //   }).then(res => {
  //     props.setGetOutputs(true);
  //   })
  //     .catch(e => {
  //       props.setGetOutputs(true);
  //     })
  // }

  return (
    <TableContainer component={Paper} style={{ marginTop: "15px" }}>
      {showDeleteOutputError ? (
        <DeleteOutputErrorPopup
          modalState={showDeleteOutputError}
          handleModal={() => {
            setShowDeleteOutputError(false);
          }}
          scheduleItemsForDeleteOutput={scheduleItemsForDeleteOutput}
          outputToDelete={outputToDelete}
          removeOutput={() => props.removeOutput(outputToDelete)}
        />
      ) : (
        ""
      )}
      <RequiredFieldsDialog
        open={props.open}
        setEditMode={handleSetEditFromPopup}
        editMode={props.editMode}
        handleClose={props.handleClosePopup}
        handleEditMode={props.handleEditMode}
      />
      <AppBar
        position="static"
        className="output-header-container"
        style={{
          background: props.editMode ? "#ff9e00" : "",
        }}
      >
        <Toolbar style={{ minHeight: "30px" }}>
          <Typography
            variant="h6"
            align="center"
            style={{ flexGrow: 1 }}
          >
            Outputs
          </Typography>
          {props.editMode ? (
            <IconButton color="inherit" onClick={handleSaveChanges}>
              <Save style={{ color: "#2d2d2d" }} />
            </IconButton>
          ) : (
            ""
          )}
          {!props.editMode && props.projectDetails.isArchived !== 1 ? (
            <IconButton
              aria-label="Toggle Edit Mode"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => handleToggleEditMode()}
              color="inherit"
            >
              <Edit style={{ color: "#2d2d2d" }} />
            </IconButton>
          ) : (
            ""
          )}
          {props.editMode ? (
            <IconButton
              aria-label="Copy Empty Row"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => handleCopyEmpty()}
              color="inherit"
            >
              <AddCircle
                style={{
                  color: "2d2d2d",
                }}
              />
            </IconButton>
          ) : (
            ""
          )}
        </Toolbar>
      </AppBar>
      {props.outputs && showDeliverPopUp && (
        <DeliverPopup
          modalState={showDeliverPopUp}
          handleModal={toggleShowDeliverPopUp}
          outputDetails={selectedRow} //{forwardTransform(selectedRow, props.projectDetails)}
          splitSemiColon={getUniqueDestinations}
          handleInputChangeDelivery={props.handleInputChangeDelivery}
          parentTable={"allOutputs"}
          projectDetails={props.projectDetails}
        />
      )}
      {props.outputsLoaded ? (
        <Table
          className={classes.table}
          size="small"
          aria-label="customized table"
          style={{ tableLayout: "fixed", width: "100%" }}
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={props.outputs ? props.outputs.length : 0}
            editMode={props.editMode}
          />
          <TableBody>
            {props.outputs
              ? stableSort(props.outputs, getComparator(order, orderBy)).map(
                  (row, index) => {
                    var isOverdue = false;
                    if (row.startDate) {
                      isOverdue = new Date(row.startDate) < new Date();
                    }
                    return (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <StyledTableRow
                          key={`${row.id}-other-output`}
                          hover={true}
                          onContextMenu={
                            !showOutputEditPopUp
                              ? (event) => {
                                  event.stopPropagation();
                                  setDeliveryClicked(true);
                                  handleClick(event, index, row);
                                }
                              : ""
                          }
                          style={
                            isOverdue
                              ? {
                                  cursor: "context-menu",
                                  backgroundColor: "#ffd2b1",
                                }
                              : { cursor: "context-menu" }
                          }
                          onClick={(event) => {
                            if (
                              !props.editMode &&
                              !showOutputEditPopUp &&
                              !deliveryClicked &&
                              props.projectDetails.isArchived !== 1
                            ) {
                              event.stopPropagation();
                              setCurrentIndex(index);
                              toggleShowOutputEditPopUp();
                              //handleToggleEditMode();
                            }
                          }}
                        >
                          {!props.editMode && index === currentIndex ? (
                            <OutputEditPopup
                              modalState={showOutputEditPopUp}
                              handleModal={toggleShowOutputEditPopUp}
                              outputDetails={row}
                              handleInputChange={handleInputChange}
                              handleDateChange={handleDateChange}
                              splitSemiColon={splitSemiColon}
                              handleDropDownChange={props.handleDropDownChange}
                              handleSaveChanges={handleSaveChanges}
                              handleSaveEditManualOutput={(output) => {
                                toggleShowOutputEditPopUp();
                                props.handleSaveEditManualOutput(output);
                              }}
                              handleRowUpdate={props.handleRowUpdate}
                            />
                          ) : (
                            ""
                          )}
                          <StyledTableCell
                            className={
                              "output-table-body output-table-days-left-cell"
                            }
                          >
                            <StyledTypography
                              className={"output-table-days-left"}
                            >
                              {row.startDate
                                ? daysLeft(new Date(row.startDate))
                                : ""}
                            </StyledTypography>
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={"outputable-body"}
                          >
                            {props.editMode ? (
                              <div>
                                <KeyboardDatePicker
                                  autoOk
                                  format="dd/MM/yyyy"
                                  disablePast={true}
                                  minDateMessage={""}
                                  value={row.startDate ? row.startDate : ""}
                                  onChange={(date, value) => {
                                    handleDateChange("startDate", date, row);
                                  }}
                                  InputAdornmentProps={{
                                    position: "end",
                                  }}
                                  // className={"output-table-deadline-keyboard-picker"}
                                  className={
                                    row.startDate
                                      ? checkIfInvalid(row.startDate)
                                        ? "output-table-body-required output-table-deadline-keyboard-picker"
                                        : "output-table-deadline-keyboard-picker"
                                      : {}
                                  }
                                  KeyboardButtonProps={{
                                    size: "small",
                                    style: {
                                      ...{
                                        padding: "0px 0px 0px 0px",
                                        margin: "0px 0px 0px 0px",
                                      },
                                    },
                                  }}
                                />
                              </div>
                            ) : (
                              <StyledTypography id="Creative Deadline">
                                {row.startDate
                                  ? format(
                                      new Date(row.startDate),
                                      "dd/MM/yyyy"
                                    )
                                  : ""}
                              </StyledTypography>
                            )}
                          </StyledTableCell>
                          {/* Duration */}
                          <StyledTableCell
                            align="left"
                            className={"output-table-body"}
                          >
                            {props.editMode ? (
                              <StyledInput
                                name="duration"
                                disableUnderline={true}
                                className={
                                  checkIfInvalid(row.duration)
                                    ? "output-table-body-required"
                                    : ""
                                }
                                value={row.duration}
                                onChange={(event) =>
                                  handleInputChange(event, row)
                                }
                              />
                            ) : (
                              <StyledTypography id="Duration">
                                {row.duration}
                              </StyledTypography>
                            )}
                          </StyledTableCell>
                          {/* Creative Name */}
                          <StyledTableCell
                            align="left"
                            className={"output-table-body"}
                          >
                            {props.editMode ? (
                              <StyledInput
                                name="creativeName"
                                disableUnderline={true}
                                value={row.creativeName}
                                className={
                                  checkIfInvalid(row.creativeName)
                                    ? "output-table-body-required output-table-creative-name-text-input"
                                    : "output-table-creative-name-text-input"
                                }
                                onChange={(event) =>
                                  handleInputChange(event, row)
                                }
                                required={true}
                              />
                            ) : (
                              <HtmlTooltip
                                title={row.creativeName}
                                interactive
                                arrow
                                placement={"left"}
                              >
                                <StyledTypography
                                  id="Creative Name"
                                  className={
                                    "output-table-creative-name-text-input truncate-text"
                                  }
                                >
                                  {row.creativeName}
                                </StyledTypography>
                              </HtmlTooltip>
                            )}
                          </StyledTableCell>
                          {/* Platform */}
                          <StyledTableCell className={"output-table-body"}>
                            <DynamicDropdownPlatform
                              handleDropDownChange={props.handleDropDownChange}
                              row={row}
                              type={"Platform"}
                              handlePublisherReset={props.handlePublisherReset}
                              editMode={props.editMode}
                            />
                          </StyledTableCell>
                          {/* Publisher */}
                          <StyledTableCell
                            align="left"
                            className={"output-table-body"}
                          >
                            <DynamicDropdownPublisher
                              handleDropDownChange={props.handleDropDownChange}
                              row={row}
                              type={row.platform}
                              editMode={props.editMode}
                            />
                          </StyledTableCell>
                          {/* Target Audience */}
                          <StyledTableCell
                            align="left"
                            className={"output-table-body"}
                          >
                            {props.editMode ? (
                              <StyledInput
                                name="targetAudience"
                                disableUnderline={true}
                                value={row.targetAudience}
                                className={
                                  checkIfInvalid(row.targetAudience)
                                    ? "output-table-body-required"
                                    : ""
                                }
                                onChange={(event) =>
                                  handleInputChange(event, row)
                                }
                              />
                            ) : (
                              <StyledTypography id="Audience">
                                {row.targetAudience}
                              </StyledTypography>
                            )}
                          </StyledTableCell>
                          {/* KeyNumber */}
                          <StyledTableCell
                            align="left"
                            className={"output-table-body"}
                          >
                            {row.platform !== undefined &&
                            row.platform.indexOf("TV") !== -1 ? (
                              <StyledTypography id="Key Number">
                                {row.keyNumberFkNavigation !== undefined &&
                                row.keyNumberFkNavigation !== null
                                  ? row.keyNumberFkNavigation.keynumber
                                  : ""}
                              </StyledTypography>
                            ) : (
                              <StyledTypography id="Key Number">
                                {""}
                              </StyledTypography>
                            )}
                          </StyledTableCell>
                          {/* Notes */}
                          <StyledTableCell
                            align="left"
                            className={"output-table-body"}
                          >
                            {props.editMode ? (
                              <StyledInput
                                name="notes"
                                disableUnderline={true}
                                value={row.notes}
                                className={
                                  checkIfInvalid(row.notes)
                                    ? "output-table-body-required truncate-text"
                                    : "truncate-text"
                                }
                                onChange={(event) =>
                                  handleInputChange(event, row)
                                }
                                multiline
                                rowsMax={6}
                              />
                            ) : (
                              <HtmlTooltip
                                title={row.notes}
                                interactive
                                arrow
                                placement={"left"}
                              >
                                <StyledTypography>
                                  <div className={"truncate-text"}>
                                    {row.notes}
                                  </div>
                                </StyledTypography>
                              </HtmlTooltip>
                            )}
                          </StyledTableCell>
                          {props.editMode ? (
                            <StyledTableCell
                              align="right"
                              className={"output-table-body"}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Backdrop open={open} onClick={handleClose}>
                                  <Dialog
                                    open={visibleModal == index ? true : false}
                                    onClose={handleClose}
                                    BackdropProps={{
                                      style: {
                                        backgroundColor: "rgba(0,0,0,.25)",
                                      },
                                    }}
                                  >
                                    <DialogTitle id="alert-dialog-title">
                                      {"Do you want to delete " +
                                        row.creativeName +
                                        "?"}
                                    </DialogTitle>
                                    <DialogActions>
                                      <Button
                                        onClick={handleClose}
                                        color="primary"
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        onClick={() => handleDelete(row)}
                                        color="primary"
                                        autoFocus
                                      >
                                        Delete
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                </Backdrop>
                                <IconButton
                                  onClick={() => handleCopy(row, index)}
                                  style={{
                                    visibility: !props.editMode ? "hidden" : "",
                                  }}
                                >
                                  <FileCopy style={{ cursor: "pointer" }} />
                                </IconButton>
                                <IconButton
                                  onClick={() => handleOpen(index)}
                                  style={{
                                    visibility: !props.editMode ? "hidden" : "",
                                  }}
                                >
                                  <Delete
                                    style={{ color: "rgba(0, 0, 0, 0.54)" }}
                                  />
                                </IconButton>
                              </div>
                            </StyledTableCell>
                          ) : (
                            ""
                          )}
                        </StyledTableRow>
                      </MuiPickersUtilsProvider>
                    );
                  }
                )
              : ""}
            <Menu
              keepMounted
              open={state.mouseY !== null}
              onClose={handleCloseContext}
              anchorReference="anchorPosition"
              anchorPosition={
                state.mouseY !== null && state.mouseX !== null
                  ? { top: state.mouseY, left: state.mouseX }
                  : undefined
              }
              onExited={() => {
                if (deliveryClicked) {
                  setDeliveryClicked(false);
                }
              }}
            >
              {props.projectDetails.isArchived === 1 && (
                <MenuItem>Cannot deliver from an archived project</MenuItem>
              )}
              {props.projectDetails.isArchived !== 1 && (
                <MenuItem onClick={() => handleShowDeliverModal()}>
                  Deliver
                </MenuItem>
              )}
            </Menu>
          </TableBody>
        </Table>
      ) : (
        <div style={{display: "flex", flexDirection: "column", gap: 15, padding: 20}}>
          <Skeleton variant="rect" width={"auto"} height={30} />
          <Skeleton variant="rect" width={"auto"} height={80} />
        </div>
      )}
    </TableContainer>
  );
}
