import { Avatar, Box, Typography } from "@material-ui/core";
import React from "react";

const CurrencyOptionItem = ({ option }) => {
  return !!option ? (
    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }}>
      <div className="currency-exchange-form-select-currency-wrapper">
        <Avatar
          srcSet={`https://flagcdn.com/w320/${option.code.toLowerCase()}.png 2x`}
          src={`https://flagcdn.com/w160/${option.code.toLowerCase()}.png`}
          alt=""
        />
        <Typography>
          <strong>{option.value}</strong>
        </Typography>
      </div>
    </Box>
  ) : (
    <div></div>
  );
};

export default CurrencyOptionItem;
