import React from "react";

const ContractStatusChips = ({
    awaitingSign = 0,
    signed = 0,
    awaitingInvoice = 0
}) => {
    return (
        <div style={{ display: "flex", fontSize: 10 }}>
            <div className="chip red-chip roboto-medium" style={awaitingSign <= 0 ? { visibility: "hidden" } : {}}>
                {`AWAITING SIGNING (${awaitingSign})`}
            </div>
            <div className="chip-short green-chip roboto-medium" style={signed <= 0 ? { visibility: "hidden" } : {}}>
                {`SIGNED (${signed})`}
            </div>
            <div className="chip light-blue-chip roboto-medium" style={awaitingInvoice <= 0 ? { visibility: "hidden" } : {}}>
                {`AWAITING PAYMENT (${awaitingInvoice})`}
            </div>
        </div>
    )
}

export default ContractStatusChips;