import React from "react";
import { Menu, MenuItem } from "@material-ui/core";
import axios from "axios";
import { useUserContext } from "../../context/user/UserContext";
import { useAuth } from "../../context/auth/AuthContext";

export const RightClickAvatarMenu = (props) => {
    let { handleCloseContext, state, influencer, handleSaveInfluencer } = props;
    const { user } = useAuth();
    return (
        <Menu
            keepMounted
            open={state.mouseY !== null}
            onClose={handleCloseContext}
            anchorReference="anchorPosition"
            anchorPosition={
                state.mouseY !== null && state.mouseX !== null
                    ? { top: state.mouseY, left: state.mouseX }
                    : undefined
            }
        >
            <MenuItem disabled>
                Choose main profile
            </MenuItem>
            {influencer.socialMediaProfile.map(s => {
                return (
                    <MenuItem onClick={() => {
                        handleSaveInfluencer({...influencer, "primarySocial": s.application});
                        handleCloseContext();
                        axios.patch(`People/UpdatePrimarySocial/${influencer.id}/${s.application}?user=${user?.email}`)
                    }}>
                        {s.application}
                    </MenuItem>
                )
            })}
        </Menu>
    );
};
