import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';

const LocationChangeTravelReimbursmentWarning = (props) => {
    const {
        open,
        handleClose
    } = props;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="travel-reimbursment-warning-dialog-title"
            aria-describedby="travel-reimbursment-warning-dialog-description"
        >
            <DialogTitle id="travel-reimbursment-warning-dialog-title">
                Warning
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="travel-reimbursment-warning-dialog-description">
                    There are contractors with travel contributions applied to this location. Please cancel these before continuing.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default LocationChangeTravelReimbursmentWarning;
