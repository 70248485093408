import React, { createContext, useContext, useEffect, useState } from "react";

const initialState = {
  filter: {
    keyword: "",
    organisationId: 0,
    budgetCategory: "Media Spend",
  },
};

export const ClientContext = createContext({});

export const ClientContextProvider = ({ children }) => {
  const [state, setState] = useState(initialState);
  
  const updateClientFilter = (field, value) => {
    setState({
      ...state,
      filter: { 
        ...state.filter,
        [field]: value
       },
    });
  };

  return (
    <ClientContext.Provider
      value={{
        filter: state.filter,
        updateClientFilter,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};
export function useClientContext() {
  return useContext(ClientContext);
}
