import { Dialog, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import React from "react";
import { Close } from "@material-ui/icons";
import Influencers from "../../../../InfluencersNetwork/Influencers";
import "./searchInfluencersDialog.scss"
const SearchInfluencersDialog = ({ open, handleClose, addInfluencerToProject, cateogries = [] }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      PaperProps={{
        style: {
          backgroundColor: "#ededed",
          maxHeight: "85vH"
        },
      }}
    >
      <DialogTitle className="search-influencers-dialog-title">
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className="search-influencers-dialog-content">
        <Influencers parent="dialog" addInfluencerToProject={addInfluencerToProject} cateogries={cateogries}/>
      </DialogContent>
    </Dialog>
  );
};

export default SearchInfluencersDialog;
