import { createReducer } from "@reduxjs/toolkit";
// These reducers are using redux toolkit and Immer. This means the state is not being mutated, even though it looks like it is.

const initialState = {
  schedulesForTable: [],
  loadingSchedulesForTable: true,
  schedule: { scheduleLocations: [] },
  scheduleDates: [],
  loadingSchedule: true,
  noLocation: false,
  project: {},
  loadingProject: true,
  errorMessage: "",
};

const schedules = createReducer(initialState, {
  RESET_PROJECT: (state, action) => {
    return {
      ...state,
      project: {},
      loadingProject: true,
    };
  },
  GET_PROJECT: (state, action) => {
    return {
      ...state,
      project: action.payload.project ?? action.payload,
      loadingProject: false,
    };
  },
  EDIT_PROJECT: (state, action) => {
    console.log(action);
    return {
      ...state,
      project: {
        ...state.project,
        [action.payload.key]: action.payload.value,
      },
    };
  },
  ADD_SCHEDULE_LOCATION: (state, action) => {
    return {
      ...state,
      schedule: {
        ...state.schedule,
        scheduleLocations: [
          ...state.schedule.scheduleLocations,
          action.payload,
        ],
      },
    };
  },
  ADD_SCHEDULE_LOCATION_ON_CREATE: (state, action) => {
    return {
      ...state,
      schedule: {
        ...state.schedule,
        scheduleLocations: [action.payload],
      },
    };
  },
  DELETE_SCHEDULE_LOCATION: (state, action) => {
    return {
      ...state,
      schedule: {
        ...state.schedule,
        scheduleLocations: state.schedule.scheduleLocations.filter(
          (sL) => sL.id != action.payload.sl.id
        ),
      },
    };
  },
  DELETE_SCHEDULE_LOCATION_ERROR: (state, action) => {
    console.log(action.payload);
  },
  PROMOTE_RETURN_SCHEDULE: (state, action) => {
    return {
      ...state,
      schedule: {
        ...state.schedule,
        scheduleLocations: state.schedule.scheduleLocations.map((sl) =>
          sl.locationFk == action.payload.sl.locationFk
            ? {
                ...sl,
                contractStatus: "Send",
              }
            : sl
        ),
      },
    };
  },
  CHANGE_LOCATION: (state, action) => {
    // action.payload = {oldLocation, newLocation}
    return {
      ...state,
      schedule: {
        ...state.schedule,
        scheduleLocations: state.schedule.scheduleLocations.map((sl) =>
          sl.id == action.payload.id
            ? {
                ...sl,
                locationFk: action.payload.newLocation.id,
                locationFkNavigation: action.payload.newLocation,
              }
            : sl
        ),
      },
    };
  },
  NO_LOCATION_ALERT: (state, action) => {
    return {
      ...state,
      noLocation: action.payload,
    };
  },
  CHANGE_LOCATION_ON_CREATE: (state, action) => {
    return {
      ...state,
      schedule: {
        ...state.schedule,
        scheduleLocations: state.schedule.scheduleLocations.map((sl) =>
          sl.locationFk == "blank"
            ? {
                ...sl,
                id: action.payload.id,
                locationFk: action.payload.newLocation.id,
                locationFkNavigation: action.payload.newLocation,
              }
            : sl
        ),
      },
    };
  },
  EDIT_SCHEDULE_LOCATION: (state, action) => {
    // action.payload = {location, field, value}
    return {
      ...state,
      schedule: {
        ...state.schedule,
        scheduleLocations: state.schedule.scheduleLocations.map((sl) =>
          sl.id === action.payload.scheduleLocation.id
            ? {
                ...sl,
                [action.payload.field]: action.payload.value,
              }
            : sl
        ),
      },
    };
  },
  UPDATE_SCHEDULE_TIMES: (state, action) => {
    console.log(action.payload);
    return {
      ...state,
      schedule: {
        ...state.schedule,
        startTime: action.payload.startTime,
        endTime: action.payload.endTime,
      },
    };
  },
  // ---------------------------------- Schedule Table reducers ----------------------- //

  GET_SCHEDULES_FOR_TABLE: (state, action) => {
    return {
      ...state,
      schedulesForTable: action.payload,
      loadingSchedulesForTable: false,
    };
  },
  ARCHIVE_SCHEDULE: (state, action) => {
    return {
      ...state,
      schedulesForTable: [
        ...state.schedulesForTable.filter((elem, idx) => {
          return idx !== action.payload.index;
        }),
      ],
      loadingSchedulesForTable: false,
    };
  },

  //------------------------------- ScheduleWrapper reducers----------------------------//

  CREATE_SCHEDULE: (state, action) => {
    return {
      ...state,
      schedule: action.payload,
    };
  },
  GET_SCHEDULE: (state, action) => {
    return {
      ...state,
      schedule: action.payload,
      loadingSchedule: false,
    };
  },
  RESET_SCHEDULE: (state, action) => {
    return {
      ...state,
      schedule: {},
      loadingSchedule: true,
    };
  },
  RESET_SCHEDULE_TABLE: (state, action) => {
    return {
      ...state,
      schedule: {},
      loadingSchedule: true,
      schedulesForTable: [],
      loadingSchedulesForTable: true,
    };
  },
  EDIT_SCHEDULE: (state, action) => {
    return {
      ...state,
      schedule: {
        ...state.schedule,
        [action.payload.field]: action.payload.value,
      },
    };
  },
  EDIT_SCHEDULE_DATE: (state, action) => {
    // NB this is the exact opposite of what you should do in a normal reducer without Immer
    var stArr = [];
    var etArr = [];
    var siSTArr = [];
    var siETArr = [];
    state.schedule.startTime =
      action.payload.value + "T" + state.schedule.startTime.substr(11, 8);
    state.schedule.endTime =
      action.payload.value + "T" + state.schedule.endTime.substr(11, 8);
    state.schedule.scheduleLocations.map((sl) => {
      stArr.push(action.payload.value + "T" + sl.startTime.substr(11, 8));
      etArr.push(action.payload.value + "T" + sl.endTime.substr(11, 8));
    });
    state.schedule.scheduleLocations.forEach((sl, index) => {
      sl.startTime = stArr[index];
      sl.endTime = etArr[index];
    });
    state.schedule.scheduleItem.map((sI) => {
      siSTArr.push(action.payload.value + "T" + sI.startTime.substr(11, 8));
      siETArr.push(action.payload.value + "T" + sI.endTime.substr(11, 8));
    });
    state.schedule.scheduleItem.forEach((si, index) => {
      si.startTime = siSTArr[index];
      si.endTime = siETArr[index];
    });
  },
  GET_SCHEDULE_DATES: (state, action) => {
    return {
      ...state,
      scheduleDates: action.payload,
    };
  },
  CREATE_NEW_SCHEDULE: (state, action) => {
    return {
      ...state,
      schedule: action.payload,
    };
  },
  //---------------------------------- Edit Locations ---------------------------------//
  UPDATE_LOCATION_CONTACT: (state, action) => {
    const { payload } = action;
    for (let i = 0; i < state.schedule.scheduleLocations.length; i++) {
      if (
        state.schedule.scheduleLocations[i].locationFk ==
        action.payload.locationFk
      ) {
        let contact =
          state.schedule.scheduleLocations[i].locationFkNavigation
            .primaryContactNavigation;
        contact.id = payload.contact.id;
        contact.firstName = payload.contact.firstName;
        contact.lastName = payload.contact.lastName;
        contact.fullName = payload.contact.fullName;
        contact.emailAddress = payload.contact.emailAddress;
        contact.phoneNumber = payload.contact.phoneNumber;

        if (payload.contact.id === null) {
          let scheduleLocation = state.schedule.scheduleLocations[i];
          scheduleLocation.fee = null;
        }
      }
    }
  },
  ERROR: (state, action) => {
    return {
      ...state,
      errorMessage: action.payload,
    };
  },
  //---------------------------------- Catering ---------------------------------//
  UPDATE_CATERING: (state, action) => {
    return {
      ...state,
      schedule: {
        ...state.schedule,
        catering: action.data
      },
    };
  }
});

export default schedules;
