import React, { useEffect, useState } from "react";
import Header from "../Header";
import {
  Paper,
  TableContainer,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import {
  Launch as LaunchIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@material-ui/icons";
import CustomTableHead from "../Shared/CustomTableHead";
import { getComparator } from "../../utils/TableUtils";
import LoadingTableSkeleton from "../Shared/LoadingTableSkeleton";
import { connect } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";
import "./clientsHome.scss";
import Snacky from "../Shared/Snacky";
import ConfirmationDialogActions from "../Shared/ConfirmationDialogActions";
import BrandBadge from "../Shared/Badge/BrandBadge";
import { currencyFormat } from "../../utils/GeneralUtils";
import { Autocomplete } from "@material-ui/lab";
import ClientsFilters from "./ClientsFilters";
import {
  get12MonthsBackAnd1MonthForwardArr,
  getLastDayOfPreviousMonthForDropdown,
} from "../../utils/TimeHelpers";
import { useClientContext } from "../../context/client/ClientContext";
import ClientsMediaInvoiceStatus from "./ClientsMediaInvoiceStatus";
import ClientMediaInvoiceStatusLegend from "./ClientMediaInvoiceStatusLegend";
import MasterStatusButton from "../Client/MasterStatus/MasterStatusButton";
import { useUserContext } from "../../context/user/UserContext";
import { useAuth } from "../../context/auth/AuthContext";

const tableHeader = [
  {
    field: "companyName",
    name: "Client",
    fieldType: "string",
    alignment: "left",
  },
  {
    field: "companyName",
    name: "Media Invoice Status",
    fieldType: "string",
    alignment: "left",
  },
];

const ClientsHome = ({ projectFilter }) => {
  const history = useHistory();
  const { user } = useAuth();
  const { filter } = useClientContext();
  const [showLiability, setShowLiability] = useState(false);
  const [billingMonth, setBillingMonth] = useState(
    getLastDayOfPreviousMonthForDropdown()
  );
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [keyword, setKeyword] = useState(filter?.keyword || "");
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState({
    field: "companyName",
    name: "Client Name",
    fieldType: "string",
  });
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });
  const [selectedClient, setSelectedClient] = useState(null);
  const [openArchiveModal, setOpenArchiveModal] = useState(false);
  const [showHiddenCol, setShowHiddenCol] = useState(false);

  useEffect(() => {
    searchClients();
  }, [filter, billingMonth]);

  const handleOnRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const goToClientPage = (e, client) => {
    e.preventDefault();
    history.push(
      `/Client/Home/${client.companyId}/${
        client?.organisationFk === 5
          ? "0"
          : client?.organisationFk === 1
          ? "2"
          : "3"
      }`
    );
  };

  const searchClients = () => {
    setIsLoading(true);
    axios
      .get("Companies/FetchCompanyDetailsForCampaigns", {
        params: {
          organisationId: filter.organisationId,
          keyword: filter.keyword?.trim(),
          budgetCategory: filter.budgetCategory,
          isArchived: 0,
          liabilityDate: billingMonth?.value,
        },
      })
      .then(({ data }) => {
        setClients(data.companyDetailsDto);
      })
      .finally(() => setIsLoading(false));
  };

  const goToMasterMedia = (e) => {
    e.preventDefault();
    history.push("/Media/MasterMedia");
  };

  const handleAchiveClient = (e, client) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedClient(client);
    setOpenArchiveModal(true);
  };

  const handleCloseArchiveModal = () => {
    setSelectedClient(null);
    setOpenArchiveModal(false);
  };

  const handleConfirmArchiveClient = () => {
    axios
      .post(`/projects/archiveCompanyProjects/${selectedClient.companyId}`)
      .then(({ data }) => {
        setClients(
          [...clients].filter((c) => c.companyId !== selectedClient.companyId)
        );
        handleCloseArchiveModal();
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Voilà! We have archived all projects for this client.",
        });
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to archive projects.",
        });
      });
  };

  const handleChangeLiabilityDate = (date) => {
    setBillingMonth(date);
  };

  return (
    <div className="client-home-root">
      <Snacky snackprops={snackBarProps} setSnackBarProps={setSnackBarProps} />
      {openArchiveModal && selectedClient && (
        <ConfirmationDialogActions
          title={"Are you sure?"}
          open={openArchiveModal}
          contentText={
            "This action will archive any active project for this client"
          }
          button1Action={handleCloseArchiveModal}
          button1Text={"Cancel"}
          button2Text={"Confirm"}
          button2Action={handleConfirmArchiveClient}
        />
      )}
      <ClientsFilters setKeyword={setKeyword} keyword={keyword} />
      <ClientMediaInvoiceStatusLegend />

      <TableContainer component={Paper}>
        <table className="custom-table custom-table-border custom-table-hover socialites-projects-table">
          <CustomTableHead
            headers={tableHeader}
            orderBy={orderBy}
            order={order}
            onRequestSort={handleOnRequestSort}
            tableName={"client"}
            hasHiddenColumns={true}
            hiddenColHeaderName={"Remaining"}
            handleHiddenColumnIconClick={() => setShowHiddenCol(!showHiddenCol)}
            showHidden={showHiddenCol}
          />
          <tbody>
            {isLoading ? (
              <LoadingTableSkeleton
                loadingMessage="Loading clients..."
                colspan="13"
              />
            ) : (
              [...clients].sort(getComparator(order, orderBy)).map((client) => (
                <a
                  key={client?.companyId}
                  className="clients-home-row-wrapper"
                  onClick={(e) => goToClientPage(e, client)}
                  href={`/Client/Home/${client.companyId}/${
                    client?.organisationFk === 5 ? "0" : "1"
                  }`}
                >
                  <td className="clients-home-company-name-container">
                    <div className="clients-home-flex-container">
                      <Typography variant="caption" className="roboto-medium">
                        {client.companyName}
                      </Typography>
                    </div>
                  </td>
                  <td>
                    <ClientsMediaInvoiceStatus client={client} />
                  </td>
                </a>
              ))
            )}
            <tr
              key={"total-remaining-row"}
              className="clients-home-row-wrapper"
            >
              <td></td>
              <td>
                
              </td>
            </tr>
          </tbody>
        </table>
      </TableContainer>
    </div>
  );
};
const mapStateToProps = (state) => ({
  projects: state.projects.projects,
  projectFilter: state.thompsonSpencerProject.projectFilter,
});
export default connect(mapStateToProps, {})(ClientsHome);
