export const setLoaderProps = (open) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_LOADER_PROPS",
      payload: { open },
    });
  } catch (err) {
    dispatch({
      type: "SET_LOADER_PROPS_ERROR",
      payload: err,
    });
  }
};
