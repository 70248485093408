import React, { useState, useEffect } from "react";
import Axios from "axios";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableSortLabel,
  TextField,
  Button,
  Snackbar,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Brightness1, CheckCircle } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import MuiAlert from "@material-ui/lab/Alert";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
// import "../../../../utils/utils";
import { unique } from "../../../../utils/utils";
import { getDatePortion } from "../../../../utils/TimeHelpers";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  checkBox: {
    color: "c8c8c8",
    "&$checked": { color: "#4dfa59" },
    marginLeft: 23,
  },
  autocomplete: {
    width: 120,
  },
  completeButton: {
    color: "white",
    backgroundColor: "#3f51b5",
    margin: "10px 5px 0px 5px",
    "&:hover": {
      backgroundColor: "#3f51b5",
    },
    "&$disabled": {
      backgroundColor: "rgba(63,81,181, 0.15)",
    },
  },
});

const headCells = [
  {
    id: "Output Check",
    align: "left",
    disablePadding: false,
    label: "Include",
  },
  {
    id: "Start Date",
    align: "left",
    disablePadding: false,
    label: "Start Date",
  },
  {
    id: "Creative Name",
    align: "left",
    disablePadding: false,
    label: "Creative Name",
  },
  {
    id: "Ad Duration",
    align: "left",
    disablePadding: false,
    label: "Ad Duration",
  },
  { id: "Platform", align: "left", disablePadding: false, label: "Platform" },
  {
    id: "contractDuration",
    align: "left",
    disablePadding: false,
    label: "Duration (months)",
  },
];

let monthsArr = ["0", "1", "2", "3", "6", "12", "18", "24", "36"];
const platformPublisherRegex = /\[|\]|"/g;
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  body: {
    padding: "20px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: "0px",
    textOverflow: "ellipsis",
  },
  root: {
    padding: "0px 0px 0px 0px",
  },
}))(TableCell);

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              paddingLeft: index === 0 ? "15px" : 0,
              paddingRight: (index = 5 ? "5px" : 0),
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export const TalentContractOutputs = (props) => {
  const classes = useStyles();
  const filter = createFilterOptions();

  const [outputs, setOutputs] = useState([]);
  const [outputsFetched, setOutputsFetched] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selectionsValid, setSelectionsValid] = useState(false);
  const [highlightError, setHighlightError] = useState(false);
  const [rowinError, setRowinError] = useState([]);
  const [showErrorSnackBar, setShowErrorSnackBar] = useState(false);
  // const [editMode, setEditMode] = useState(
  //   props.scheduleItem.talentContractStatus === "Complete"
  // );
  const [editMode, setEditMode] = useState(
    (props.scheduleItem.talentContractStatus === "Draft" && props.scheduleItem.isAucklandStandardContract === 1) ||
    (props.scheduleItem.talentContractStatus === "Complete" && props.scheduleItem.isAucklandStandardContract === 0)
  );
  const [outputsForContract, setOutputsForContract] = useState([]);
  const [contractId, setContractId] = useState(null);
  const [contract, setContract] = useState(null);
  const [selectedOutputs, setSelectedOutputs] = useState([]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [outputsCompleted, setOutputsCompleted] = useState(
    props.scheduleItem.contractStatus == "Disabled" ||
      props.scheduleItem.contractStatus == "Send"
      ? false
      : true
  );

  const handleCheck = (e, index, output) => {
    let selectedOutputIndex = selectedOutputs.findIndex(o => o.id === output.id);
    let outputIndex = outputs.findIndex(o => o.id === output.id);
    if(selectedOutputIndex != -1){
      //remove from selected list
      let outputsClone = [...outputs];
      outputsClone[outputIndex].checked = 0;
      outputsClone[outputIndex].contractDuration = null;
      setOutputs(outputsClone);
      setSelectedOutputs(selectedOutputs.filter(o => o.id !== output.id))
    }else{
      //adds to the selected list
      let outputsClone = [...outputs];
      outputsClone[outputIndex].checked = 1;
      outputsClone[outputIndex].contractDuration = output.contractDuration;
      setOutputs(outputsClone);
      setSelectedOutputs([...selectedOutputs, {...output} ])
    }
  };
  const handleChangeMonth = (monthVal, index, output) => {
    let selectedOutputIndex = selectedOutputs.findIndex(o => o.id === output.id);
    if(selectedOutputIndex != -1){
      let selectedOutputsClone = [...selectedOutputs];
      selectedOutputsClone[selectedOutputIndex].contractDuration = monthVal;
      setSelectedOutputs(selectedOutputsClone);
    }
    setOutputs(
      outputs.map((output) =>
        outputs.indexOf(output) === index
          ? {
            ...output,
            contractDuration: monthVal,
          }
          : output
      )
    );
    setRowinError(rowinError.filter((rowIndex) => rowIndex !== index));
  };

  const handleHighlightErrors = () => {
    var errorCount = 0;
    var rowsinError = [];
    for (let i = 0; i < outputs.length; i++) {
      if (outputs[i].contractDuration === null && outputs[i].checked === 1) {
        errorCount++;
        rowsinError.push(i);
      }
    }
    if (errorCount !== 0) {
      setHighlightError(true);
      setShowErrorSnackBar(true);
      setRowinError(rowsinError);
    }
  };
  useEffect(() => {
    if (rowinError.length === 0) {
      setHighlightError(false);
    }
  }, [rowinError]);

  useEffect(()=>{
    if(!outputsFetched){
      Axios.get(
        "/OutputsV2/GetOutputsForTalentContract/" + props.projectid
      ).then(async res => {
        if(res.data.length > 0){
          let outputsForClient = res.data;
          outputsForClient = outputsForClient.map((o) => {
              o.checked = 0;
              o.contractDuration = null;
              return o;
          });
          if(editMode){
           await Axios.get(`TalentContracts/GetTalentContractForScheduleItem/${props.scheduleItem.id}`
              ).then(contractRes => {
                if(contractRes.data){
                  // let contractOutputs = JSON.parse(contractRes.data.outputs);
                  let contractOutputs = contractRes.data.talentContractOutput;
                  setOutputsForContract(contractOutputs);
                  setContractId(contractRes.data.id);
                  setContract(contractRes.data);
                  let selectedOutputsClone = [...selectedOutputs];
                  contractOutputs.forEach(contractOutput => {
                    let index = outputsForClient.findIndex(o => o.id === contractOutput.outputFk); // check DB for outputs without the id field. 
                    if(index !== -1){
                      outputsForClient[index].checked = 1;
                      outputsForClient[index].contractDuration = contractOutput.contractDuration.toString();
                      selectedOutputsClone.push(outputsForClient[index])
                      setSelectedOutputs(selectedOutputsClone);
                    }
                  })
                }
              })
          }
          setOutputs(outputsForClient);
          setOutputsFetched(true);
        }else{
          setOutputsFetched(true);
          console.log("No outputs for this project");
        }
      });
    }
    
  },[outputsFetched])

  let monthProps = {
    options: monthsArr,
  };

  const createTalentContractOutputList = async () => {
    let talentContractOutput = [];
    await selectedOutputs.forEach(i => {
      let output = {};
      output.contractDuration = parseInt(i.contractDuration);
      output.duration = i.duration;
      output.creativeName = i.creativeName;
      output.platform = i.platform;
      output.startDate = i.startDate;
      output.outputFk = i.id;
      talentContractOutput.push(output);
    });
    return talentContractOutput;
  }

  const handleComplete = async (isAucklandStandardContract) => {
    let selectedOutputsList = await createTalentContractOutputList(); 
    if (!editMode) {
      props.handleComplete(selectedOutputsList, isAucklandStandardContract);
    } else {
      console.log("Edit mode saving");
      if (isAucklandStandardContract) {
        props.handleComplete(selectedOutputsList, isAucklandStandardContract);
      } else {
        var payload = contract;
        payload.outputs = JSON.stringify(selectedOutputsList);
        Axios.put(`TalentContracts/PutTalentContract/${contract.id}`, payload);
        Axios.put(`TalentContracts/PutTalentContractOutput/${contract.id}`, selectedOutputsList);
        props.handleModal();
      }
    }
  };

  useEffect(() => {
    console.log("calculating validity");
    let count1 = 0;
    let count2 = 0;
    for (let i = 0; i < outputs.length; i++) {
      if (outputs[i].checked === 1 && outputs[i].contractDuration === null) {
        count1++;
        console.log(count1);
      }
      if (outputs[i].contractDuration !== null) {
        count2++;
        console.log(count2);
      }
    }
    console.log(count1 > 0);
    if (count1 > 0 || count2 === 0) {
      setSelectionsValid(false);
    } else {
      setSelectionsValid(true);
    }
    return () => { };
  }, [outputs]);

  var snackbarErrors = (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={showErrorSnackBar}
      autoHideDuration={2000}
      onClose={() => setShowErrorSnackBar(false)}
    >
      <MuiAlert severity="error">
        Every selected output must have a contract duration.
      </MuiAlert>
    </Snackbar>
  );
  return (
    <div>
      {snackbarErrors}
      <Table
        className={classes.table}
        size="small"
        aria-label="customized table"
        style={{ tableLayout: "fixed", width: "100%", overflow: "hidden" }}
      >
        <EnhancedTableHead
          classes={classes}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={props.outputs ? props.outputs.length : 0}
          editMode={props.editMode}
        />
        <TableBody>
          {outputs ? (
            stableSort(outputs, getComparator(order, orderBy)).map(
              (row, index) => {
                var platform = row.Platform === undefined ? row.platform : row.Platform;
                return (
                  <TableRow
                    key={row.RowId !== undefined ? row.RowId : (row.rowID !== undefined ? row.rowID : row.id)}
                    hover={true}
                    style={{
                      backgroundColor:
                        (row.checked === 1 && !highlightError) ||
                          (row.checked === 1 &&
                            highlightError &&
                            rowinError.indexOf(index) === -1)
                          ? "rgba(0,178,226, 0.05)"
                          : row.checked === 1 &&
                            highlightError &&
                            rowinError.indexOf(index) !== -1
                            ? "rgba(255, 0, 0, .05)"
                            : "white",
                    }}
                  >
                    <StyledTableCell>
                      {row.checked === 0 ? (
                        <Brightness1
                          onClick={
                            !outputsCompleted
                              ? (e) => handleCheck(e, index, row)
                              : null
                          }
                          style={{
                            color: "#c8c8c8",
                            marginLeft: "23px",
                          }}
                        />
                      ) : (
                          <CheckCircle
                            style={{
                              color: "#4dfa59",
                              marginLeft: "23px",
                            }}
                            onClick={
                              !outputsCompleted
                                ? (e) => handleCheck(e, index, row)
                                : null
                            }
                          />
                        )}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {getDatePortion(row.StartDate !== undefined ? row.StartDate : row.startDate)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.CreativeName ? row.CreativeName : (row["Creative Name"] ? row["Creative Name"] : row.creativeName)}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.Duration ? row.Duration : row.duration}</StyledTableCell>
                    <StyledTableCell align="left">
                      {
                        platform.includes(";") ? unique(platform.split(";")).join(", ")
                          : unique(platform.replace(platformPublisherRegex, "").split(",")).join(", ")
                      }
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Autocomplete
                        disabled={row.checked === 0 || outputsCompleted}
                        disableClearable
                        className={classes.autocomplete}
                        {...monthProps}
                        value={row.contractDuration}
                        onInputChange={(event, newInputValue) => {
                          var reg = /^[0-9]*$/;
                          if (reg.test(newInputValue)) {
                            handleChangeMonth(newInputValue, index, row);
                          } else {
                            handleChangeMonth(null, index, row);
                          }
                        }}
                        onChange={(event, newValue) => {
                          console.log(newValue);
                          handleChangeMonth(newValue, index, row);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} margin="normal" />
                        )}
                        filterOptions={(options, params) => {
                          if (
                            row.contractDuration !== null &&
                            row.contractDuration !== undefined &&
                            row.contractDuration !== ""
                          ) {
                            const filtered = monthsArr;
                            const createOption = [row.contractDuration];
                            let newOptions = [];
                            newOptions = createOption.concat(filtered);

                            return newOptions;
                          }
                          return filter(options, params);
                        }}
                      />
                    </StyledTableCell>
                  </TableRow>
                )
              }
            )
          ) : (
              <div></div>
            )}
        </TableBody>
      </Table>
      {outputsCompleted ? (
        <React.Fragment></React.Fragment>
      ) : (
          <div style={{ display: "flex", justifyContent: "end", float: "right" }}>
            <Button
              className={classes.completeButton}
              onClick={() => selectionsValid ? handleComplete(1) : handleHighlightErrors()}
              style={props.scheduleItem.personFkNavigation.agentFk == null ? { display: "none" } : {}}
            >
              New Zealand Standard Contract
            </Button>
            <Button
              className={classes.completeButton}
              onClick={() => selectionsValid ? handleComplete(0) : handleHighlightErrors()}
            >
              Create Camistry Standard
            </Button>
          </div>
        )}
    </div>
  );
};
