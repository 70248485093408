import React, { useState, useEffect } from "react";

import Snacky from "../../Shared/Snacky";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import CustomTableHead from "../../Shared/CustomTableHead";
import LoadingTableSkeleton from "../../Shared/LoadingTableSkeleton";
import axios from "axios";
import "./users.scss";
import { getComparator } from "../../../utils/TableUtils";
import UsersTableRow from "./UsersTableRow";
const tableHeader = [
  {
    field: "firstName",
    name: "Name",
    fieldType: "string",
    alignment: "left",
  },
  {
    field: "email",
    name: "Email",
    fieldType: "string",
    alignment: "left",
  },
  {
    field: "phoneNumner",
    name: "Phone Number",
    fieldType: "string",
    alignment: "left",
  },
  {
    field: "role",
    name: "Role",
    fieldType: "string",
    alignment: "left",
  },
  {
    field: "isArchived",
    name: "Status",
    fieldType: "number",
    alignment: "left",
  },
  {
    field: "",
    name: "",
    fieldType: "string",
    alignment: "left",
  },
];

const Users = (props) => {
  const [filters, setFilters] = useState({
    includeArchive: true,
  });
  const [users, setUsers] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState({
    field: "firstName",
    name: "Name",
    fieldType: "string",
  });
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });

  useEffect(() => {
    getAllUsers();
  }, []);

  const handleOnRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getAllUsers = () => {
    setIsLoadingUsers(true);
    axios
      .get(`Users/GetUsers`, {
        params: filters,
      })
      .then(({ data }) => {
        setUsers(data);
      })
      .catch((err) => {
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to fetch users",
        });
      })
      .finally(() => setIsLoadingUsers(false));
  };

  const updateUserStatus = (userId, isArchived) => {
    axios
      .put(`Users/UpdateUserDisabledStatus/${userId}/${isArchived}`)
      .then(({ data }) => {
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Great! The user has been updated.",
        });
        setUsers(users.map((u) => (u.id === data.id ? data : u)));
      })
      .catch((err) => {
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to update user",
        });
      })
      .finally(() => {});
  };

  const updateUser = (user) => {
    axios
      .put(`Users/UpdateUser/${user.id}`, user)
      .then(({ data }) => {
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Great! The user has been updated.",
        });
        setUsers(users.map((u) => (u.id === data.id ? data : u)));
      })
      .catch((err) => {
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to update user",
        });
      })
      .finally(() => {});
  };

  return (
    <div className="users-container-root">
      <Snacky snackprops={snackBarProps} setSnackBarProps={setSnackBarProps} />
      <Typography variant="h5">Users</Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <CustomTableHead
            headers={tableHeader}
            orderBy={orderBy}
            order={order}
            onRequestSort={handleOnRequestSort}
          />
          <TableBody>
            {isLoadingUsers ? (
              <LoadingTableSkeleton
                loadingMessage="Loading users..."
                colspan="6"
              />
            ) : (
              [...users]
                .sort(getComparator(order, orderBy))
                .map((user) => (
                  <UsersTableRow
                    key={user.id}
                    user={user}
                    updateUser={updateUser}
                    updateUserStatus={updateUserStatus}
                  />
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Users;
