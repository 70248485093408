import React, { useState } from "react";
import "./personGeneralTab.scss";
import PersonGeneralForm from "./PersonGeneralForm";
import GeneralDocumentViewer from "../../../../../Shared/Dialog/GeneralDocumentViewer";
import { Skeleton } from "@material-ui/lab";
import Snacky from "../../../../../Shared/Snacky";
const GeneralTab = ({
  person,
  isLoadingPersonDetails,
  handleUpdatePersonGeneralState,
}) => {
  const [showDocumentViewer, setShowDocumentViewer] = useState(false);
  const [driveId, setDriveId] = useState(null);
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });
  return (
    <>
      <Snacky snackprops={snackBarProps} setSnackBarProps={setSnackBarProps} />
      {showDocumentViewer && driveId && (
        <GeneralDocumentViewer
          modalState={showDocumentViewer}
          handleModal={() => {
            setShowDocumentViewer(false);
            setDriveId(null);
          }}
          headerColor="rgb(33, 127, 139)"
          docType={"PDF"}
          urlFile={`https://docs.google.com/document/d/${driveId}/preview`}
        />
      )}

      <div className="person-general-tab-root">
        {isLoadingPersonDetails ? (
          <>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
          </>
        ) : (
          <>
            <div className="person-general-tab-header-wrapper"></div>
            <div className="person-general-tab-form-wrapper">
              <PersonGeneralForm
                handleUpdatePersonGeneralState={handleUpdatePersonGeneralState}
                setSnackBarProps={setSnackBarProps}
                person={person}
                setDriveId={setDriveId}
                setShowDocumentViewer={setShowDocumentViewer}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default GeneralTab;
