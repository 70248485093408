import axios from "axios";

export const checkIfDisabled = (scheduleItem) => {
  if (
    (scheduleItem.prePostNotes?.trim() === "-" ||
      scheduleItem.prePostNotes?.trim() === "") &&
    scheduleItem.talentContractStatus == null
  ) {
    scheduleItem.contractStatus = "Disabled";
  }
  return scheduleItem;
};

export const onBlurAllocatedRates = (value, scheduleItem, user) => {
  let _scheduleItem = { ...scheduleItem };
  _scheduleItem.allocatedRates = value;
  _scheduleItem.allocatedRatesInNzd = value;

  axios
    .patch(
      `ScheduleItems/PatchScheduleItem/${scheduleItem.id}`,
      _scheduleItem,
      {
        params: {
          user: user,
        },
      }
    )
    .then((res) => {}) //res.data
    .catch((err) => {
      console.log(err);
    });
};

export const getDeliverablesFormat = () => {
  return [
    "IG Feed Post",
    "IG Video Post",
    "IG Reel",
    "IG Carousel",
    "IG Story 1 frame",
    "IG Story 3 frames min",
    "TikTok",
    "TikTok Story frame",
    "Facebook Post",
    "Facebook Story",
    "Youtube Video",
    "Youtube Shorts",
    "RED Photo Post",
    "RED Video Post",
    "Video 30 Seconds",
    "Video 15 Seconds",
    "Video 60 Seconds",
    "Video 9:16",
    "Video 4:5",
    "Video 1:1",
    "Static Image",
    "Static Image 9:16",
    "Static Image 4:5",
    "Static Image 1:1",
    "Final Post",
    "Re-Share Post",
  ];
};
export const getDeliverablesQuantities = () => {
  return Array.from({ length: 20 }, (_, i) => i + 1);
};
