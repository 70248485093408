import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { addZeroIfEndsWithPeriod, filterNumbersOnlyInput, isEmpty, isNumeric } from "../../../utils/GeneralUtils";
import PullFromBudgetAutocomplete from "../../Shared/BudgetItem/PullFromBudgetAutocomplete";
import moment from "moment";
const MediaSpendFormDialog = ({
  open,
  handleClose,
  selectedMediaSpend,
  setSelectedMediaSpend,
  budgetItems,
  isSavingMediaSpend,
  handleSubmit,
  isClientView,
  commissionRate
}) => {
  const initialErrorState = {
    amount: "",
  };

  const [errorForm, setErrorForm] = useState(initialErrorState);
  const [hasBeenInvoiced, setHasBeenInvoiced] = useState(false);

  useEffect(() => {
   checkIfReadOnly()
  }, [])

  const checkIfReadOnly = () => {
    setHasBeenInvoiced(selectedMediaSpend?.mediaInvoice?.status === "AUTHORISED" || selectedMediaSpend?.mediaInvoice?.status === "PAID") 
  }
  

  const handleChange = (field, value) => {
    if(field === "total"){
      if(isClientView){
        const commissionTotal = value * (commissionRate / 100);
        setSelectedMediaSpend({ ...selectedMediaSpend, commissionTotal: commissionTotal, total: value, totalWithoutCommission: value - commissionTotal, unmatchedAmount: value - commissionTotal });
      }else{
        // For supplier cost view, value is the total without commission
        const commissionTotal = value * (commissionRate / (100 - commissionRate));
        setSelectedMediaSpend({ ...selectedMediaSpend, commissionTotal: commissionTotal, total: Number(value) + commissionTotal, totalWithoutCommission: value, unmatchedAmount: value });
      }
    } else {
      setSelectedMediaSpend({ ...selectedMediaSpend, [field]: value });
    }
  };

  const handleSave = () => {
    if (validateForm()) {
      handleSubmit();
    }
  };

  const validateForm = () => {
    let isValidForm = true;
    const tempError = { ...initialErrorState };
    const selectedBudget = budgetItems.find((bi) => bi.budgetId === selectedMediaSpend.budgetId);
    if (Number(selectedMediaSpend.total) > selectedBudget.remaining) {
      tempError.amount = "Not enough budget remaining";
      isValidForm = false;
    }

    if (!isValidForm) {
      setErrorForm(tempError);
    }

    return isValidForm;
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth id="add-budget-dialog">
      <div className="close-and-transfer-dialog-close-icon-container">
        <IconButton
          size="small"
          className="close-and-transfer-dialog-close-icon"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <DialogTitle style={{ display: "grid" }}>
        <Typography variant="h5" className="roboto-bold">{`${selectedMediaSpend?.id ? "Edit" : "Allocate New"
          } Media Spend`}</Typography>
      </DialogTitle>

      <DialogContent>
        <div style={{ display: "grid", gap: "20px" }}>
          <Typography className="roboto-bold">{moment(selectedMediaSpend.billingDate).format("MMMM YYYY")}</Typography>
          <TextField
            variant="filled"
            label="Amount"
            fullWidth
            value={isClientView ? selectedMediaSpend.total : selectedMediaSpend.totalWithoutCommission}
            onChange={(e) => {
              !isEmpty(e.target.value) && isNumeric(e.target.value)
                ? handleChange("total", filterNumbersOnlyInput(e.target.value))
                : handleChange("total", 0);
            }}
            size="small"
            error={hasBeenInvoiced ? true : errorForm.amount ? true : false}
            helperText={hasBeenInvoiced ? "Field locked: invoices have been sent." : errorForm.amount ? errorForm.amount : ""}
            disabled={hasBeenInvoiced}
          />
          <TextField
            variant="filled"
            fullWidth
            disabled
            label="Code"
            value={selectedMediaSpend.code}
          />
        </div>
      </DialogContent>
      <DialogActions>
        {isSavingMediaSpend ? (
          <div style={{ padding: 10 }}>
            <CircularProgress />
          </div>
        ) : (
          <div className="add-budget-popup-action-container">
            <Button
              style={{ marginRight: 5 }}
              className="default-button-grey add-budget-button"
              onClick={(e) => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ marginLeft: 5 }}
              className="default-button add-budget-button"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MediaSpendFormDialog;
