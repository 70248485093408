import React from "react";
import { Typography } from "@material-ui/core";

export const NewScheduleTitle = () => {
  return (
    <Typography
      style={{
        float: "right",
        padding: "3px 7px 3px 7px",
        backgroundColor: "#00b2e2",
        color: "white",
        fontWeight: "bold",
        fontSize: "0.8em",
        marginBottom: "10px",
      }}
    >
      New Schedule
    </Typography>
  );
};

export const EditScheduleTitle = () => {
  return (
    <Typography
      style={{
        float: "right",
        padding: "3px 7px 3px 7px",
        backgroundColor: "#ff9e00",
        color: "white",
        fontWeight: "bold",
        fontSize: "0.8em",
        marginBottom: "10px",
      }}
    >
      Edit Schedule
    </Typography>
  );
};
