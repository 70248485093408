import { Button, TextField } from "@material-ui/core";
import React from "react";

const ContractorFinancialForm = ({
  person,
  bankAccountDetails,
  handleBankDetailsChange,
  isEditing,
}) => {
  const { currency, ir330c, wtexemptionCertificate } = person;
  return (
    <div className="person-financial-tab-root">
      <div className="person-financial-tab-flex-container">
        {currency === "NZD" && ir330c && (
          <div>
            <Button
              className={"default-button-grey"}
              variant="contained"
              target="_blank"
              href={"https://drive.google.com/open?id=" + ir330c}
            >
              View IR330C Form
            </Button>
          </div>
        )}

        {currency === "NZD" && bankAccountDetails?.wtRate === 0 && (
          <div>
            <Button
              className={"default-button-grey"}
              variant="contained"
              target="_blank"
              href={
                "https://drive.google.com/open?id=" + wtexemptionCertificate
              }
            >
              View Exemption Certificate
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContractorFinancialForm;
