import React from "react";

const ThompsonSpencerLogoNoBg = () => {
  return (
    <svg
      width="124"
      height="29"
      viewBox="0 0 124 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.9173 2.44222H0V0.251888H12.6054V2.44222H7.68807V12.3973H4.9173V2.44222Z"
        fill="white"
      />
      <path
        d="M14.3905 0.251888H17.1722V5.13633H25.1122V0.251888H27.8829V12.3863H25.1122V7.30476H17.1722V12.3863H14.3905V0.251888Z"
        fill="white"
      />
      <path
        d="M33.4573 11.8497C32.3293 11.335 31.4641 10.6012 30.8508 9.65937C30.2375 8.71752 29.9309 7.60045 29.9309 6.31911C29.9309 5.02681 30.2375 3.90974 30.8508 2.9679C31.4641 2.02606 32.3403 1.2923 33.4573 0.777568C34.5853 0.26284 35.8995 0 37.4109 0C38.9222 0 40.2364 0.26284 41.3644 0.777568C42.4925 1.2923 43.3686 2.02606 43.9819 2.9679C44.5952 3.90974 44.9128 5.02681 44.9128 6.30816C44.9128 7.60045 44.6061 8.71752 43.9819 9.65937C43.3686 10.6012 42.4925 11.335 41.3644 11.8497C40.2364 12.3644 38.9222 12.6273 37.3999 12.6273C35.8776 12.6273 34.5634 12.3644 33.4464 11.8497H33.4573ZM39.886 9.91125C40.5869 9.5827 41.1344 9.11178 41.5177 8.49849C41.9011 7.8852 42.0872 7.16239 42.0872 6.33006C42.0872 5.48678 41.8901 4.76397 41.5068 4.15068C41.1235 3.54834 40.5759 3.06647 39.875 2.73792C39.1741 2.40937 38.3527 2.23414 37.4109 2.23414C36.469 2.23414 35.6477 2.39841 34.9468 2.72696C34.2458 3.05551 33.6983 3.52644 33.315 4.13973C32.9316 4.75302 32.7345 5.47583 32.7345 6.29721C32.7345 7.14048 32.9316 7.86329 33.315 8.47659C33.6983 9.08988 34.2458 9.5608 34.9468 9.88935C35.6477 10.2179 36.469 10.3822 37.4109 10.3822C38.3527 10.3822 39.1741 10.2179 39.875 9.88935L39.886 9.91125Z"
        fill="white"
      />
      <path
        d="M46.9279 0.251888H50.2243L54.9116 9.35272H54.9664L59.6647 0.251888H62.9502V12.3863H60.4094V3.87689H59.3361L61.4607 1.56609L55.9301 12.3973H53.9917L48.4502 1.56609L50.5638 3.87689H49.5125V12.3973H46.9498V0.251888H46.9279Z"
        fill="white"
      />
      <path
        d="M65.3814 0.251888H68.1631V12.3863H65.3814V0.251888ZM66.7832 6.52719H72.2372C73.0147 6.52719 73.6061 6.34101 74.0223 5.97961C74.4384 5.6182 74.6465 5.10347 74.6465 4.43542V4.41352C74.6465 3.75642 74.4384 3.24169 74.0223 2.88029C73.6061 2.51888 73.0038 2.3327 72.2372 2.3327H66.7832V0.251888H72.6862C73.6718 0.251888 74.5151 0.427115 75.227 0.766616C75.9388 1.10612 76.4864 1.59894 76.8807 2.21224C77.264 2.83648 77.4611 3.55929 77.4611 4.38066C77.4611 5.22394 77.264 5.9577 76.8807 6.58195C76.4974 7.20619 75.9388 7.69902 75.227 8.03852C74.5151 8.38897 73.6718 8.5642 72.6862 8.5642H66.7832V6.51624V6.52719Z"
        fill="white"
      />
      <path
        d="M82.1156 12.2111C81.1628 11.9264 80.4071 11.5102 79.8486 10.9626C79.29 10.415 78.9724 9.74698 78.8739 8.95846L78.852 8.83799H81.5461L81.568 8.9256C81.6665 9.27606 81.8856 9.5827 82.2141 9.82364C82.5427 10.0755 82.9917 10.2617 83.5612 10.3822C84.1307 10.5136 84.8206 10.5793 85.6201 10.5793C86.2991 10.5793 86.8905 10.5136 87.3833 10.3822C87.8761 10.2508 88.2485 10.0536 88.5113 9.80174C88.7742 9.54985 88.8946 9.25416 88.8946 8.91465C88.8946 8.46564 88.7194 8.13708 88.358 7.91805C87.9966 7.69902 87.3833 7.54569 86.4962 7.44713L83.5393 7.19524C82.0499 7.04192 80.9437 6.69147 80.2209 6.13293C79.4981 5.5744 79.1367 4.79683 79.1367 3.78928C79.1367 3.00076 79.3776 2.34366 79.8595 1.78512C80.3414 1.22659 81.0204 0.78852 81.9075 0.481873C82.7946 0.175227 83.8459 0.0219033 85.0506 0.0219033C86.2553 0.0219033 87.3724 0.164275 88.2813 0.45997C89.1903 0.755665 89.8912 1.17183 90.4169 1.70846C90.9426 2.24509 91.2602 2.91314 91.3807 3.67976V3.80023H88.6975L88.6756 3.72357C88.5661 3.36216 88.369 3.05551 88.0733 2.81458C87.7776 2.57364 87.3833 2.38746 86.8795 2.26699C86.3758 2.14653 85.7734 2.08082 85.0506 2.08082C84.3716 2.08082 83.8021 2.14653 83.3312 2.26699C82.8603 2.38746 82.5098 2.57364 82.2689 2.81458C82.028 3.04456 81.9075 3.32931 81.9075 3.65786C81.9075 4.07402 82.0937 4.39162 82.4551 4.61065C82.8165 4.82968 83.4188 4.98301 84.2511 5.07062L87.2409 5.3006C88.2594 5.41012 89.0918 5.5963 89.7379 5.88104C90.3841 6.16579 90.8659 6.53814 91.1835 6.99811C91.5011 7.45808 91.6545 8.03852 91.6545 8.71752C91.6545 9.54985 91.4135 10.2508 90.9316 10.8421C90.4498 11.4226 89.7489 11.8716 88.8289 12.1892C87.9199 12.4958 86.8029 12.6601 85.5106 12.6601C84.2183 12.6601 83.0465 12.5177 82.0937 12.233L82.1156 12.2111Z"
        fill="white"
      />
      <path
        d="M96.7579 11.8497C95.6299 11.335 94.7647 10.6012 94.1514 9.65937C93.5381 8.71752 93.2315 7.60045 93.2315 6.31911C93.2315 5.02681 93.5381 3.90974 94.1514 2.9679C94.7647 2.02606 95.6409 1.2923 96.7579 0.777568C97.886 0.26284 99.2002 0 100.711 0C102.223 0 103.537 0.26284 104.665 0.777568C105.793 1.2923 106.669 2.02606 107.282 2.9679C107.896 3.90974 108.213 5.02681 108.213 6.30816C108.213 7.60045 107.907 8.71752 107.282 9.65937C106.669 10.6012 105.793 11.335 104.665 11.8497C103.537 12.3644 102.223 12.6273 100.701 12.6273C99.1782 12.6273 97.8641 12.3644 96.747 11.8497H96.7579ZM103.187 9.91125C103.887 9.5827 104.435 9.11178 104.818 8.49849C105.202 7.8852 105.388 7.16239 105.388 6.33006C105.388 5.48678 105.191 4.76397 104.807 4.15068C104.424 3.54834 103.877 3.06647 103.176 2.73792C102.475 2.40937 101.653 2.23414 100.711 2.23414C99.7696 2.23414 98.9483 2.39841 98.2474 2.72696C97.5465 3.05551 96.9989 3.52644 96.6156 4.13973C96.2323 4.75302 96.0351 5.47583 96.0351 6.29721C96.0351 7.14048 96.2323 7.86329 96.6156 8.47659C96.9989 9.08988 97.5465 9.5608 98.2474 9.88935C98.9483 10.2179 99.7696 10.3822 100.711 10.3822C101.653 10.3822 102.475 10.2179 103.176 9.88935L103.187 9.91125Z"
        fill="white"
      />
      <path
        d="M110.239 0.251888H112.704L122.221 10.2289L119.537 8.64086H120.709V0.251888H123.414V12.3863H120.961L111.444 2.35461L114.127 3.95355H112.955V12.3863H110.239V0.251888Z"
        fill="white"
      />
      <path
        d="M94.2281 16.1318C94.6224 16.1318 94.9619 16.2742 95.2466 16.548C95.5313 16.8218 95.6628 17.1722 95.6628 17.5774C95.6628 17.8403 95.5971 18.0812 95.4547 18.3112C95.3123 18.5412 95.1261 18.7164 94.8852 18.8369C94.6771 18.9464 94.469 18.9902 94.2391 18.9902C93.9653 18.9902 93.7134 18.9245 93.4834 18.7821C93.2534 18.6397 93.0782 18.4536 92.9577 18.2126C92.8591 18.0045 92.8044 17.7855 92.8044 17.5555C92.8044 17.2817 92.8701 17.0408 93.0125 16.8108C93.1548 16.5808 93.341 16.4056 93.5819 16.2851C93.7791 16.1866 93.9981 16.1318 94.2281 16.1318ZM94.2281 16.4056C93.9105 16.4056 93.6367 16.5151 93.4067 16.7451C93.1877 16.9641 93.0782 17.2379 93.0782 17.5555C93.0782 17.8731 93.1877 18.1579 93.4177 18.3878C93.6477 18.6178 93.9105 18.7273 94.2281 18.7273C94.5457 18.7273 94.8195 18.6069 95.0495 18.3769C95.159 18.2674 95.2356 18.1469 95.3014 18.0045C95.3561 17.8622 95.389 17.7198 95.389 17.5665C95.389 17.2379 95.2795 16.9532 95.0495 16.7341C94.8195 16.5042 94.5457 16.3946 94.2281 16.3946V16.4056ZM93.6477 16.8108H94.3267C94.4362 16.8108 94.5128 16.8108 94.5676 16.8327C94.6224 16.8546 94.6662 16.8656 94.7209 16.9094C94.8304 16.986 94.8852 17.1065 94.8852 17.2708C94.8852 17.3474 94.8742 17.4241 94.8414 17.4679C94.8085 17.5117 94.7757 17.5665 94.71 17.6103C94.7647 17.6431 94.7976 17.6869 94.8195 17.7307C94.8414 17.7745 94.8523 17.8622 94.8523 18.0045C94.8523 18.1469 94.8633 18.2345 94.8742 18.2564C94.8742 18.2893 94.9071 18.3112 94.9619 18.344H94.5347C94.5019 18.2674 94.4909 18.1907 94.4909 18.0921C94.4909 17.9826 94.4909 17.9169 94.48 17.8841C94.469 17.8512 94.4581 17.8184 94.4471 17.8074C94.4252 17.7855 94.3595 17.7745 94.2719 17.7745H94.02V18.355H93.6586V16.8218L93.6477 16.8108ZM94.0091 17.1284V17.4569H94.2719C94.3705 17.4569 94.4471 17.446 94.469 17.4241C94.5019 17.3912 94.5238 17.3474 94.5238 17.2927C94.5238 17.227 94.5019 17.1832 94.469 17.1613C94.4362 17.1394 94.3705 17.1284 94.2719 17.1284H94.0091Z"
        fill="white"
      />
      <path
        d="M92.3663 28.2553C92.3225 28.1348 92.2897 28.0034 92.2568 27.872C92.1801 27.5982 92.1035 27.3244 91.9721 27.0506C91.5449 26.1307 90.7564 25.5064 89.5846 25.1341C89.1684 25.0026 88.7523 24.8931 88.369 24.7946C88.0185 24.7069 87.6461 24.6084 87.2957 24.4989C86.5291 24.2689 86.0034 23.9951 85.5872 23.6337C85.2915 23.3818 85.1163 23.0752 85.0506 22.7247H85.7625C86.2553 22.7247 86.7591 22.7247 87.2628 22.7247C88.6975 22.6918 89.9679 22.1333 91.0193 21.06C91.7421 20.3263 91.9173 19.4063 91.523 18.4097C91.3369 17.9279 91.0083 17.5117 90.5155 17.1284C89.5408 16.3618 88.358 15.9894 86.9014 15.9894C85.6858 15.9894 84.4702 15.9894 83.2545 15.9894H81.5351C81.5351 15.9894 81.4366 15.9894 81.338 15.9894L80.8999 16.0113V28.7372H83.2436V17.0298H84.6454C85.4777 17.0298 86.3319 17.0298 87.1752 17.0627C87.7885 17.0846 88.3142 17.3474 88.6866 17.8293C89.1356 18.4097 89.2889 19.1216 89.1465 20.0744C89.0589 20.6658 88.8289 21.0381 88.4456 21.2243C88.0623 21.4105 87.7228 21.5091 87.4381 21.531C86.8138 21.5748 86.1896 21.5857 85.5653 21.5857C85.3134 21.5857 85.0506 21.5857 84.7987 21.5857C84.733 21.5857 84.6783 21.5857 84.6016 21.5857H84.1307L84.1088 21.9471C83.9993 23.8856 84.8097 25.4079 86.4086 26.2511C87.0767 26.6016 87.7776 26.9192 88.4675 27.2258L88.5113 27.2477C88.8618 27.4011 89.2122 27.5653 89.5517 27.7296C89.8584 27.872 90.2526 28.091 90.4826 28.4634C90.5593 28.5838 90.7236 28.7262 90.9316 28.7372C91.1069 28.7372 91.2712 28.7372 91.4573 28.7372H92.5087L92.3444 28.2334L92.3663 28.2553Z"
        fill="white"
      />
      <path
        d="M2.85838 24.7398L2.57364 24.2689V27.872L2.63935 27.9158C2.77077 28.0034 2.9679 28.091 3.24169 28.1677C3.50453 28.2443 3.78928 28.321 4.11783 28.3977C4.44637 28.4743 4.6435 28.5291 4.74207 28.5619L4.95015 28.6276V27.6091L4.90634 27.5653C4.2821 26.8863 3.59214 25.9335 2.85838 24.7289V24.7398Z"
        fill="white"
      />
      <path
        d="M10.6779 23.2504C10.185 22.8561 9.72508 22.5495 9.30891 22.3414C8.89275 22.1333 8.50944 21.9909 8.16994 21.8924C5.80438 21.2024 4.24924 20.3701 3.54834 19.4063C3.07742 18.7383 3.08837 18.1688 3.6031 17.6541C4.18353 17.0955 5.12538 16.8218 6.42863 16.8546C7.86329 16.8546 8.96941 17.4131 9.72508 18.5302C9.96601 18.8916 10.3384 19.6363 10.8312 20.7424L11.1269 21.4105V17.2051L11.0721 17.1613C10.2289 16.5042 8.72847 16.0989 6.62576 15.9785C5.31156 15.9018 4.12878 16.2085 3.11027 16.8875C2.08082 17.5884 1.52228 18.4536 1.46752 19.4721C1.32515 21.4434 2.6503 22.9109 5.39917 23.8308C5.49773 23.8637 5.65106 23.9075 5.82628 23.9732C6.45053 24.1813 6.95431 24.3565 7.35952 24.4989C7.76473 24.6412 8.16994 24.8055 8.5861 24.9917C8.99131 25.1779 9.33082 25.375 9.57175 25.5721C9.81269 25.7802 10.0427 26.0211 10.2508 26.3059C10.4588 26.5797 10.6122 26.9082 10.7107 27.2696C10.8093 27.642 10.864 28.0801 10.875 28.5729V29L11.1488 28.6605C11.7511 27.8939 12.1016 27.1382 12.1782 26.4154C12.2659 25.6926 12.1563 25.0684 11.8607 24.5755C11.5759 24.0937 11.1816 23.6446 10.6888 23.2394L10.6779 23.2504Z"
        fill="white"
      />
      <path
        d="M23.8856 17.1722C23.0094 16.548 21.8486 16.2413 20.4468 16.2413H14.1386V28.6057H16.6027V22.9766H21.1915C22.2866 22.9766 23.2504 22.648 24.0389 21.98C24.8384 21.3119 25.2436 20.5234 25.2436 19.6254C25.2436 18.6288 24.7946 17.7965 23.8965 17.1613L23.8856 17.1722ZM16.6027 17.1174H19.1764C20.4139 17.0955 21.3338 17.3584 21.8814 17.895C22.3085 18.3112 22.5166 18.9135 22.5166 19.6911C22.5166 20.2387 22.44 20.6986 22.3085 21.0491C22.1662 21.3886 21.969 21.6295 21.6952 21.7938C21.4105 21.9581 21.1039 22.0786 20.7972 22.1333C20.4796 22.1881 20.0744 22.2209 19.5925 22.2209H16.6137V17.1394L16.6027 17.1174Z"
        fill="white"
      />
      <path
        d="M35.7462 27.0616C35.0234 27.412 34.1363 27.5872 33.1397 27.5872H29.4162V22.7795H36.0967V21.7829H29.4162V17.2489H33.1397C33.6764 17.2489 34.202 17.3146 34.7058 17.446C35.2096 17.5774 35.6367 17.7526 35.9872 17.9498C36.3486 18.1579 36.6224 18.3331 36.8195 18.4864C37.0166 18.6397 37.1809 18.7711 37.3014 18.8916L37.7504 19.3406L36.7428 16.2304H26.9301V28.5948H36.7428L37.7723 25.5612L37.2904 26.054C36.9947 26.3607 36.469 26.7002 35.7353 27.0506L35.7462 27.0616Z"
        fill="white"
      />
      <path
        d="M51.4838 25.5283L42.1968 16.2413H38.506L39.5684 17.2379V28.5838H40.565V18.3002L50.6295 28.5619L50.6843 28.6057H52.4366V16.2413H51.4838V25.5283Z"
        fill="white"
      />
      <path
        d="M66.0166 25.0903C65.48 25.7036 64.9105 26.2183 64.341 26.6235C63.7715 27.0287 63.2458 27.3134 62.7749 27.4777C62.2931 27.631 61.9207 27.7406 61.6469 27.7844C61.3841 27.8282 61.1322 27.8501 60.9022 27.8501C59.6537 27.8501 58.6133 27.3573 57.8029 26.3826C56.9815 25.3969 56.5653 24.0827 56.5653 22.4728C56.5653 21.4105 56.7515 20.4249 57.1129 19.5378C57.4743 18.6616 57.9671 17.9826 58.5695 17.5117C59.1718 17.0408 59.8837 16.756 60.6722 16.6575C61.4607 16.5589 62.2931 16.756 63.1692 17.2489C63.5744 17.4679 63.9468 17.8184 64.2424 18.3112C64.5601 18.815 64.8557 19.3625 65.1186 19.943C65.3924 20.5563 65.6333 20.9724 65.8414 21.2243L66.1261 21.5748L66.0276 17.1284L65.929 17.0955C64.9324 16.7122 64.0672 16.4384 63.3554 16.2742C62.6435 16.1099 61.8221 16.0442 60.9241 16.0661C59.7851 16.0661 58.7228 16.2742 57.7919 16.6684C56.8501 17.0736 56.0835 17.5993 55.514 18.2455C54.9445 18.8807 54.5064 19.5706 54.1998 20.3044C53.8931 21.0381 53.7398 21.7719 53.7398 22.4728C53.7398 24.2251 54.4298 25.7255 55.7878 26.952C57.1458 28.1677 58.8542 28.8029 60.8693 28.8138H60.9022C62.534 28.8138 64.1877 28.0582 65.8304 26.5687C65.9728 26.4373 66.2028 26.1964 66.5423 25.835L66.5861 25.7912V24.4332L66.3233 24.696C66.2247 24.7946 66.1152 24.9369 66.0057 25.1012L66.0166 25.0903Z"
        fill="white"
      />
      <path
        d="M76.9902 27.0616C76.2674 27.412 75.3803 27.5872 74.3837 27.5872H70.6601V22.7795H77.3406V21.7829H70.6601V17.2489H74.3837C74.9203 17.2489 75.446 17.3146 75.9498 17.446C76.4536 17.5774 76.8807 17.7526 77.2311 17.9498C77.5925 18.1579 77.8663 18.3331 78.0634 18.4864C78.2606 18.6397 78.4249 18.7711 78.5453 18.8916L78.9943 19.3406L77.9868 16.2304H68.1741V28.5948H77.9868L79.0162 25.5612L78.5344 26.054C78.2387 26.3607 77.713 26.7002 76.9792 27.0506L76.9902 27.0616Z"
        fill="white"
      />
    </svg>
  );
};

export default ThompsonSpencerLogoNoBg;
