import React, { useState, useEffect } from "react";
import "./projectCampaignDetails.scss";
import { Button, TextField, Typography, IconButton } from "@material-ui/core";
import axios from "axios";
import { Autocomplete } from "@material-ui/lab";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";

const ProjectCampaignDetails = ({
  campaign,
  handleOnChange,
  handleSaveCampaign,
  handleOnChangeCampaignObject,
}) => {
  const filter = createFilterOptions();

  const [socialTags, setSocialTags] = useState([]);
  const [newSocialTags, setNewSocialTags] = useState([]);

  const [competitors, setCompetitors] = useState([]);
  const [newCompetitors, setNewCompetitors] = useState([]);

  const [updateSocialTags, setUpdateSocialTags] = useState(false);
  const [updateCompetitors, setUpdateCompetitors] = useState(false);

  useEffect(() => {
    axios
      .get(`Campaign/GetAllCompanyCampaignSocialTags/${campaign.companyFk}`)
      .then((res) => {
        setSocialTags(res.data);
      })
      .catch((e) => {
        console.log("Error getting tags");
        console.log(e);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`Campaign/GetAllCompanyCampaignCompetitors/${campaign.companyFk}`)
      .then((res) => {
        setCompetitors(res.data);
      })
      .catch((e) => {
        console.log("Error getting competitors");
        console.log(e);
      });
  }, []);
  useEffect(() => {
    if (campaign?.socialHashtags) {
      var arr = campaign?.socialHashtags.split(";");
      setNewSocialTags(arr);
    }
  }, []);

  useEffect(() => {
    if (campaign?.competitors) {
      var arr = campaign?.competitors.split(";");
      setNewCompetitors(arr);
    }
  }, []);

  useEffect(() => {
    if (updateSocialTags) {
      handleOnChange("socialHashtags", newSocialTags.join(";"));
      setUpdateSocialTags(false);
    }
    if (updateCompetitors) {
      handleOnChange("competitors", newCompetitors.join(";"));
      setUpdateCompetitors(false);
    }
  }, [updateSocialTags, updateCompetitors]);

  return (
    <div className="project-campaign-details-root">
      <TextField
        label="Campaign Name"
        placeholder="Campaign Name"
        value={campaign?.name}
        variant="outlined"
        onChange={(e) => handleOnChange("name", e.target.value)}
      />
      <div>
        <Autocomplete
          multiple
          id="tags-social"
          options={socialTags}
          getOptionLabel={(option) => option}
          value={newSocialTags}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Social Tags"
              helperText="Please leave blank if not going on creator's feed."
              fullWidth
              onKeyDown={(e) => {
                if (e.key === "Enter" && e.target.value) {
                  setNewSocialTags(newSocialTags.concat(e.target.value));
                  setUpdateSocialTags(true);
                }
              }}
            />
          )}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const createOption = [`${params.inputValue}`];
            let newOptions = [];
            if (params.inputValue !== "") {
              newOptions = createOption.concat(filtered);
            } else {
              newOptions = filtered;
            }
            return newOptions;
          }}
          onChange={(event, newValue) => {
            setNewSocialTags(newValue);
            setUpdateSocialTags(true);
          }}
        />
      </div>
      <div>
        <Autocomplete
          multiple
          id="tags-competitors"
          options={competitors}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Competitiors"
              helperText="Please leave blank if not going on creator's feed."
              fullWidth
              onKeyDown={(e) => {
                if (e.key === "Enter" && e.target.value) {
                  setNewCompetitors(newCompetitors.concat(e.target.value));
                  setUpdateCompetitors(true);
                }
              }}
            />
          )}
          value={newCompetitors}
          onChange={(event, newValue) => {
            setNewCompetitors(newValue);
            setUpdateCompetitors(true);
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const createOption = [`${params.inputValue}`];
            let newOptions = [];
            if (params.inputValue !== "") {
              newOptions = createOption.concat(filtered);
            } else {
              newOptions = filtered;
            }
            return newOptions;
          }}
        />
      </div>
      <TextField
        label="Call To Action"
        helperText="Please leave blank if not going on creator's feed."
        multiline
        minRows={2}
        value={campaign?.callToAction}
        variant="outlined"
        onChange={(e) => handleOnChange("callToAction", e.target.value)}
      />
      <div className="project-campaign-details-button-container">
        <Button
          size="small"
          className="default-button"
          variant="contained"
          onClick={handleSaveCampaign}
        >
          Save Campaign
        </Button>
      </div>
    </div>
  );
};

export default ProjectCampaignDetails;
