import React, { useState, useEffect } from "react";
import axios from "axios";
import TableFilter from "react-table-filter";
import "react-table-filter/lib/styles.css";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { setSnackBarProps } from "../../../actions/snackbar";
import { setDialogProps } from "../../../actions/dialog";
import _ from "lodash";
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
} from "@material-ui/core";
import {
    Close as CloseIcon,
    Edit as EditIcon,
    Delete as DeleteIcon
} from "@material-ui/icons";
import {
    withStyles,
    makeStyles,
    useTheme,
    styled,
} from "@material-ui/core/styles";
import ContractorPopUp from "../../Project/ProjectOutputs/PopUps/ContractorPopUp";
import AgentPopUp from "./AgentPopUp";
import PropTypes from "prop-types";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontWeight: "bold",
    },
    body: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    root: {
        padding: "10px 10px 10px 10px",
        maxHeight: "50px",
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: "#efefef",
        },
    },
}))(TableRow);

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: 12,
        border: "1px solid #dadde9",
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

const headCells = [
    {
        id: "name",
        align: "center",
        label: "Name",
    },
    {
        id: "emailAddress",
        align: "left",
        label: "Email Address",
    },
    {
        id: "phoneNumber",
        align: "left",
        label: "Phone Number",
    },
    {
        id: "contactType",
        align: "left",
        label: "Category",
    },
    {
        id: "roles",
        align: "left",
        label: "Roles",
    }
];

function MemoizedTableBodyPropsAreEqual(prevState, nextState) {
    return prevState.contactList.length === nextState.contactList.length &&
        _.isEqual(prevState.contactList, nextState.contactList);
}

const MemoizedTableBody = React.memo(function MemoizedTableBody(props) {
    const {
        contactList,
        setSelectedContact,
        setOpenContactDetailsPopUp,
        setOpenAgentDetailsPopUp,
    } = props;

    return (
        <TableBody>
            {contactList.length > 0
                ? contactList.map((contact, index) => {
                    return (
                        <StyledTableRow
                            key={`${contact.id}-contact-center-table-row`}
                            hover={true}
                        >
                            <StyledTableCell align="center" className="contact-table-body-cell">
                                <span
                                    className="pointer clickable"
                                    onClick={() => {
                                        setSelectedContact(contact);
                                        if (contact.contactType !== "Agent") {
                                            setOpenContactDetailsPopUp(true);
                                        }
                                        else {
                                            setOpenAgentDetailsPopUp(true)
                                        }
                                    }}
                                >
                                    {contact.name}
                                </span>
                            </StyledTableCell>
                            <StyledTableCell align="center" className="contact-table-body-cell">{contact.emailAddress}</StyledTableCell>
                            <StyledTableCell align="center" className="contact-table-body-cell">{contact.phoneNumber}</StyledTableCell>
                            <StyledTableCell align="center" className="contact-table-body-cell">{contact.contactType}</StyledTableCell>
                            <StyledTableCell align="center" className="contact-table-body-cell">
                                <ul style={{ listStyleType: "none" }}>
                                    {contact.roles.map(role =>
                                        <li>{role}</li>
                                    )}
                                </ul>
                            </StyledTableCell>
                        </StyledTableRow>
                    )
                })
                : <React.Fragment></React.Fragment>
            }
        </TableBody>
    )
}, MemoizedTableBodyPropsAreEqual);

MemoizedTableBody.propTypes = {
    contactList: PropTypes.array.isRequired,
    setSelectedContact: PropTypes.func.isRequired,
    setOpenContactDetailsPopUp: PropTypes.func.isRequired,
    setOpenAgentDetailsPopUp: PropTypes.func.isRequired
};

const ContactCenterTable = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const [contactList, setContactList] = useState([]);
    const [selectedContact, setSelectedContact] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [currentFilter, setCurrentFilter] = useState({});
    const [openContactDetailsPopUp, setOpenContactDetailsPopUp] = useState(false);
    const [openLocationDetailsPopUp, setOpenLocationDetailsPopUp] = useState(false);
    const [openAgentDetailsPopUp, setOpenAgentDetailsPopUp] = useState(false);
    // const [selectedContact, setSelectedContact] = useState(null);

    const getHeaderClass = (cellId) => {
        switch (cellId) {
            default:
                return "contact-center-table-header-cell";
        }
    };

    const handleHeadCell = (cell) => {
        return (
            <th
                filterkey={cell.id}
                className={getHeaderClass(cell.id)}
                alignleft="true"
            >
                {cell.label}
            </th>
        );
    }

    useEffect(() => {
        setContactList(props.contactList)
        setCurrentFilter(props.initialFilters)
    }, [props.contactList])

    return (
        <React.Fragment>
            {openAgentDetailsPopUp && (
                <AgentPopUp
                    modalState={openAgentDetailsPopUp}
                    handleModal={() => setOpenAgentDetailsPopUp(false)}
                    selectedContact={selectedContact}
                    handleEditAgent={props.handleEditAgent}
                    rowUpdated={() => {
                        props.setInitialFilters(currentFilter);
                        props.rowUpdated();
                    }}
                />
            )}
            {openContactDetailsPopUp && (
                <ContractorPopUp
                    handleModal={() => {
                        setSelectedContact(null);
                        setOpenContactDetailsPopUp(false);
                    }}
                    modalState={openContactDetailsPopUp}
                    personId={selectedContact.personId}
                    scheduleItemId={null}
                    reloadPersonList={() => console.log("No reload")}
                    reloadScheduleItemsList={() => console.log("No reload")}
                    type="ContactCenterTable"
                    providerType={selectedContact.contactType}
                    handleEditPerson={props.handleEditPerson}
                    selectedContact={selectedContact}
                    rowUpdated={() => {
                        props.setInitialFilters(currentFilter);
                        props.rowUpdated();
                    }}
                />
            )}
            <Table
                id="contact-table"
                className={classes.table}
                size="small"
                aria-label="customized table"
            >
                <TableFilter
                    rows={contactList}
                    onFilterUpdate={(updatedData, filterConfiguration) => {
                        setCurrentFilter(filterConfiguration);
                        setContactList(updatedData);
                    }}
                    initialFilters={props.initialFilters}
                >
                    {headCells.map((cell) => {
                        return handleHeadCell(cell);
                    })}
                </TableFilter>
                <MemoizedTableBody
                    contactList={contactList}
                    setSelectedContact={setSelectedContact}
                    setOpenContactDetailsPopUp={setOpenContactDetailsPopUp}
                    setOpenAgentDetailsPopUp={setOpenAgentDetailsPopUp}
                />
            </Table>
        </React.Fragment>
    )
}

const mapStateToProps = (state, ownProps) => ({
    snackbar: state.snackbar,
    dialog: state.dialog,
});
export default connect(mapStateToProps, {
    setSnackBarProps,
    setDialogProps,
})(ContactCenterTable);