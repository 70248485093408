import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Table,
    TableHead,
    TableContainer,
    TableCell,
    TableBody,
    TableRow,
    Paper,
    Typography
} from "@material-ui/core";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import "./homeTable.scss";

const OverdueOutputsPopup = (props) => {
    const {
        open,
        handleClose,
        projects
    } = props;
    const history = useHistory();

    const getOverdueProjects = () => {
        return projects?.projects.filter(p => p?.daysOverdue > 0)
    }

    return (
        <Dialog
            id="overdue-outputs-popup-dialog"
            open={open}
            onClose={(e, reason) => {
                if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                    handleClose();
                }
            }}
            aria-labelledby="alert-overdue-outputs-title"
            aria-describedby="alert-overdue-outputs-description"
        >
            <DialogTitle id="alert-overdue-outputs-title">{"Projects with overdue outputs"}</DialogTitle>
            <DialogContent id="alert-overdue-outputs-subtitle">
                <Typography variant='body1'>
                    {`(Note: All other projects will be locked until outputs are delivered or dates are changed)`}
                </Typography>
            </DialogContent>
            <DialogContent>
                <TableContainer
                    component={Paper}
                >
                    <Table
                        size="small"
                        aria-label="customized table"
                        className='overdue-outputs-table'
                    >
                        <TableHead>
                            <TableCell>Project Name</TableCell>
                            <TableCell>Code</TableCell>
                            <TableCell>Customer</TableCell>
                            <TableCell>Deadline</TableCell>
                        </TableHead>
                        <TableBody>
                            {getOverdueProjects().map(p => {
                                return (
                                    <TableRow
                                        key={`${p.id}-overdue-table-row`}
                                        onClick={() => {
                                            history.push(
                                                `/project/home/${p.id.toString().split("-").length == 1
                                                    ? p.id
                                                    : p.id.split("-")[2]
                                                }/0`);
                                        }}
                                    >
                                        <TableCell>{p.projectName}</TableCell>
                                        <TableCell>{p.projectTag}</TableCell>
                                        <TableCell>{p.customerName}</TableCell>
                                        <TableCell>{format(new Date(p.nextDeadline), "dd/MM/yyyy")}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color='primary'
                    onClick={() => handleClose()}
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default OverdueOutputsPopup;
