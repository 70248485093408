import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Axios from "axios";
import { Grid, TextField, Button, Snackbar } from "@material-ui/core";
import "./Admin.css";
import MuiAlert from "@material-ui/lab/Alert";
import Loader from "../Loader";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

export default function MediaOffset() {
  const classes = useStyles();
  const [adminMenu, setAdminMenu] = useState("");
  const [values, setValues] = useState();
  const [deadlineOffset, setDeadlineOffset] = useState();
  const [othersOffset, setOthersOffset] = useState();
  const [successCreate, setSuccessCreate] = useState(false);
  const [failedCreate, setFailedCreate] = useState(false);

  const handleChange = (event, text) => {
    var newValue = event.target.value;
    console.log();
    switch (text) {
      case "TV Deadline Offset:":
        setDeadlineOffset(newValue.trim());
        break;
      case "Others Deadline Offset:":
        setOthersOffset(newValue.trim());
        break;
      default:
        break;
    }
  };

  const closeSuccessCreateSnackbar = () => {
    setSuccessCreate(false);
  };

  const closeFailedCreateSnackbar = () => {
    setFailedCreate(false);
  };

  var snackbarSuccess = (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={successCreate}
      autoHideDuration={2000}
      onClose={closeSuccessCreateSnackbar}
    >
      <MuiAlert severity="success">Successfully Saved Changes!</MuiAlert>
    </Snackbar>
  );

  var snackbarFailed = (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={failedCreate}
      autoHideDuration={2000}
      onClose={closeFailedCreateSnackbar}
    >
      <MuiAlert severity="error">Failed to Save Changes!</MuiAlert>
    </Snackbar>
  );

  const saveChanges = () => {
    Axios.post(`OutputsMedia/PostOffset`, {
      deadlineOffset: deadlineOffset,
      othersOffset: othersOffset,
    })
      .then((res) => {
        console.log(res);
        setSuccessCreate(true);
      })
      .catch((e) => {
        console.log(e);
        setFailedCreate(true);
      });
  };

  const getField = (text, value) => (
    <div className="textfield-padding">
      <TextField
        id={text}
        variant="outlined"
        label={text}
        value={value.toString()}
        onChange={(event) => handleChange(event, text)}
        fullWidth
      />
    </div>
  );

  useEffect(() => {
    Axios.get(`OutputsMedia/GetOffsets`)
      .then((res) => {
        console.log("Offsets");
        console.log(res.data);
        var response = res.data;
        console.log(response.deadlineOffset);
        console.log(response.othersOffset);
        setDeadlineOffset(response.deadlineOffset);
        setOthersOffset(response.othersOffset);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (deadlineOffset != null && othersOffset != null) {
    return (
      <main className={classes.content}>
        <div className="main-content" />
        <Typography variant="h4">Media Outputs Creative Deadline Offset (Working Days)</Typography>
        <Grid container className="table-grid">
          <Grid item xs={12} md={4} lg={3}>
            {getField("TV Deadline Offset:", deadlineOffset)}
            {getField("Others Deadline Offset:", othersOffset)}
            <div className="button-div">
              <Button color="primary" variant="contained" onClick={saveChanges}>
                Save
              </Button>
            </div>
          </Grid>
        </Grid>
        {snackbarSuccess}
        {snackbarFailed}
      </main>
    );
  } else {
    return <Loader />;
  }
}
