import React, { useState, useEffect } from "react";
import {
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  TextField,
} from "@material-ui/core";
import { connect } from "react-redux";
import { setSnackBarProps } from "../../../../actions/snackbar";
import { withStyles } from "@material-ui/core/styles";
import Snacky from "../../../Shared/Snacky";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    //backgroundColor: '#f5f5f9',
    //color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const ProviderTableCell = (props) => {
  const [openContact, setOpenContact] = useState(false);
  const [mouseX, setMouseX] = useState(null);
  const [mouseY, setMouseY] = useState(null);
  const [rightClickOpen, setRightClickOpen] = useState(false);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  useEffect(() => {
    if (openContact) {
      props.setLocationDetailsModalState({ open: true, tab: 2 });
    } else {
      props.setLocationDetailsModalState({ open: false, tab: 0 });
    }
  }, [openContact]);

  useEffect(() => {
    if (!props.locationDetailsModalState.open) {
      setOpenContact(false);
    }
  }, [props.locationDetailsModalState.open]);

  var contact = props.location.primaryContactNavigation;

  const handleEmailClick = (e) => {
    setCopiedToClipboard(true);
    props.setSnackBarProps("info", "Copied to clipboard", true);
    navigator.clipboard.writeText(contact ? contact.emailAddress : "No Email");
  };
  const handlePhoneClick = (e) => {
    setCopiedToClipboard(true);
    props.setSnackBarProps("info", "Copied to clipboard", true);
    navigator.clipboard.writeText(contact ? contact.phoneNumber : "No Phone");
  };

  const openLocationDetailsContacts = (rightClickOpen) => {
    if (!rightClickOpen) {
      setOpenContact(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setCopiedToClipboard(false);
  };

  const handleCloseContext = () => {
    setMouseX(null);
    setMouseY(null);
    setRightClickOpen(false);
    setOpenContact(false);
  };
  const handleContextClick = (event) => {
    event.preventDefault();
    setMouseX(event.clientX - 2);
    setMouseY(event.clientY - 4);
    setRightClickOpen(true);
    setOpenContact(false);
  };

  const rightClickMenu = () => {
    return (
      <Menu
        keepMounted
        open={mouseY !== null}
        onClose={handleCloseContext}
        anchorReference="anchorPosition"
        anchorPosition={
          mouseY !== null && mouseX !== null
            ? { top: mouseY, left: mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={() => {
            setOpenContact(false);
            handlePhoneClick();
            handleCloseContext();
          }}
        >
          Copy Phone
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenContact(false);
            handleEmailClick();
            handleCloseContext();
          }}
        >
          Copy Email Address
        </MenuItem>
      </Menu>
    );
  };

  const hasContact = () => {
    return contact !== null ? (contact.id !== null ? true : false) : false;
  };

  return (
    <HtmlTooltip
      title={
        <div onClick={(event) => event.stopPropagation}>
          <Typography color="inherit">
            {contact ? contact.fullName : "Contact Details"}
          </Typography>
          <b>{"Phone"}</b>
          {": "} <em>{contact ? contact.phoneNumber : ""}</em> <br />
          <b>{"Email"}</b>
          {": "} <em>{contact ? contact.emailAddress : ""}</em>{" "}
        </div>
      }
      disableHoverListener={contact == null}
      interactive
      arrow
      placement="top"
    >
      <Typography component={"div"}>
        {rightClickOpen ? rightClickMenu() : ""}
        {hasContact() ? (
          <p style={{ fontSize: "0.9em" }}>
            Contact:{" "}
            <span
              onClick={() =>
                hasContact() ? openLocationDetailsContacts(rightClickOpen) : ""
              }
              onContextMenu={(event) =>
                hasContact() ? handleContextClick(event) : ""
              }
              className={hasContact() ? "pointer clickable" : ""}
            >
              {contact.fullName}
            </span>
          </p>
        ) : (
          <p style={{ fontSize: "0.9em" }}>No Contact</p>
        )}
        {/* <TextField
          onContextMenu={(event) => {
            handleContextClick(event);
          }}
          onClick={() => openLocationDetailsContacts(rightClickOpen)}
          value={
            contact !== null
              ? contact.id !== null
                ? contact.fullName
                : "No Contact"
              : "No Contact"
          }
          variant="outlined"
          fullWidth
          disabled
          size="small"
          style={{
            marginLeft: "2px",
            marginRight: "2px",
            cursor: "pointer",
          }}
        /> */}
        {props.snackbar.open && (
          <Snacky
            snackprops={props.snackbar}
            setSnackBarProps={props.setSnackBarProps}
          />
        )}
      </Typography>
    </HtmlTooltip>
  );
};

const mapStateToProps = (state, ownProps) => ({
  snackbar: state.snackbar,
});
// https://react-redux.js.org/api/connect
export default connect(mapStateToProps, {
  setSnackBarProps,
})(ProviderTableCell);
