import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { CheckBox, Close } from "@material-ui/icons";
import { set } from "date-fns/esm";
import {
  Paper,
  AppBar,
  Table,
  Typography,
  Toolbar,
  IconButton,
  Modal,
  Input,
  OutlinedInput,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Grid
} from "@material-ui/core";
import Axios from "axios";

import { TalentContractOutputs } from "./TalentContractOutputs";

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    perspective: "1000px"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    height: "600px",
    width: "1000px",
    overflow: "auto",
  },
  paperASC: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    height: "90vH",
    width: "95vW",
    overflow: "auto",
  },
  feeBox: {
    width: 80,
  },
  alignRight: {
    display: "flex",
    justifyContent: "flex-end"
  }
}));

const TalentContract = (props) => {
  const classes = useStyles();
  const [aucklandStandardContract, setAucklandStandardContract] = useState(props.scheduleItem.isAucklandStandardContract);
  const [contractLoaded, setContractLoaded] = useState(false);
  const [fetchAucklandStandard, setFetchAucklandStandard] = useState(false);
  const [driveId, setDriveId] = useState(null);
  const [creatingNewDoc, setCreatingNewDoc] = useState(false);

  const [outputsCompleted, setOutputsCompleted] = useState(
    props.scheduleItem.contractStatus == "Disabled" ||
      props.scheduleItem.contractStatus == "Send"
      ? false
      : true
  );

  const [flipCard, setFlipCard] = useState(aucklandStandardContract == 1 && props.scheduleItem.talentContractStatus == "Draft" ? true : false);
  const handleCompleteContract = (outputs, isAucklandStandard) => {
    if (isAucklandStandard == 1) {
      setFlipCard(true);
      setCreatingNewDoc(true);
    }
    props.handleComplete(outputs, isAucklandStandard);
  }

  useEffect(() => {
    if (aucklandStandardContract !== props.scheduleItem.aucklandStandardContract) {
      setAucklandStandardContract(props.scheduleItem.isAucklandStandardContract);
    }
  }, [props.scheduleItem]);

  useEffect(() => {
    if (flipCard) {
      setFetchAucklandStandard(true);
    }
  }, [flipCard]);

  useEffect(() => {
    if (fetchAucklandStandard) {
      setFetchAucklandStandard(false)
      setTimeout(() => {
        Axios.get(`TalentContracts/GetAucklandContractDriveId/${props.scheduleItem.id}`)
          .then(res => {
            setDriveId(res.data)
          })
          .catch(e => {
            // console.log(e);
          })
          .finally(() => {
            if (driveId == null) {
              setFetchAucklandStandard(true)
            }
            else {
              setContractLoaded(true)
            }
          })
      }, creatingNewDoc ? 5000 : 1000);
    }
  }, [fetchAucklandStandard])

  return (
    <div>
      <Modal
        open={props.modalState}
        onClose={props.handleModal}
        className={classes.modal}
        disableBackdropClick={flipCard}
        disableEscapeKeyDown={flipCard}
      >
        <div className={`flip-card-inner ${flipCard && `flip-card-rotate`}`}>
          {/* Front Card */}
          <div className="flip-card-front" style={flipCard ? { display: "none" } : {}}>
            <AppBar position="static" style={{ background: "#217F8B" }}>
              <Toolbar variant="dense" style={{ paddingRight: 0 }}>
                <Grid container style={{ margin: "0px 0px 0px 0px", padding: "0px 0px 0px 0px" }}>
                  <Grid item xs={4} md={4} lg={4} style={{ margin: "0px 0px 0px 0px", padding: "0px 0px 0px 0px" }}>
                    {/* <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => setFlipCard(true)}
                    >
                      View current contract
                    </Button> */}
                  </Grid>
                  <Grid item xs={4} md={4} lg={4} style={{ margin: "0px 0px 0px 0px", padding: "0px 0px 0px 0px" }}>
                    <Typography
                      variant="subtitle1"
                      align="center"
                      style={{ flexGrow: 1 }}
                    >
                      Restricted Talent Contract
                    </Typography>
                  </Grid>
                  <Grid item xs={4} md={4} lg={4} style={{ margin: "0px 0px 0px 0px", padding: "0px 0px 0px 0px" }}>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end", marginRight: 5 }}
                    >
                      <IconButton
                        aria-haspopup="true"
                        onClick={props.handleModal}
                        color="inherit"
                        size="small"
                      >
                        <Close />
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
            <div className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <TalentContractOutputs
                    projectTag={props.projectTag}
                    projectid={props.projectid}
                    // handleComplete={props.handleComplete}
                    handleComplete={handleCompleteContract}
                    scheduleItem={props.scheduleItem}
                    handleModal={props.handleModal}
                    aucklandStandardContract={aucklandStandardContract}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          {/* Back Card */}
          <div className="flip-card-back" style={!flipCard ? { display: "none" } : {}}>
            <AppBar position="static" style={{ background: "#217F8B" }}>
              <Toolbar variant="dense" style={{ paddingRight: 0 }}>
                <Typography
                  variant="subtitle1"
                  align="center"
                  style={{ flexGrow: 1 }}
                >
                  New Zealand Standard Contract
                </Typography>
                <IconButton
                  aria-haspopup="true"
                  onClick={props.handleModal}
                  color="inherit"
                >
                  <Close />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div className={classes.paperASC}>
              <div style={driveId == null ? {} : { display: "none" }}>
                <h1 className="loading-dots" style={{ display: "flex", justifyContent: "center", fontSize: 40 }}><span>.</span><span>.</span><span>.</span></h1>
              </div>
              <div style={driveId == null ? {} : { display: "none" }}>
                <Typography style={{ display: "flex", justifyContent: "center", fontSize: 40 }}>
                  Please wait this may take a few seconds
                </Typography>
              </div>
              {contractLoaded && (
                <div style={{ height: "100%" }}>
                  <div style={driveId == null ? { display: "none" } : { height: "95%" }}>
                    <iframe
                      src={`https://docs.google.com/document/d/${driveId}/edit`}
                      height="100%"
                      width="100%"
                    >
                    </iframe>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", marginTop: 10 }}>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setFlipCard(false)
                        setDriveId(null)
                        setFetchAucklandStandard(false)
                        setContractLoaded(false)
                        // Axios.patch(`TalentContracts/ClearContractDriveId/${props.scheduleItem.id}`)
                        //   .then(res => {
                        //     console.log("Cleared Contract")
                        //   })
                        //   .catch(e => {
                        //     console.log("Error clearing contract")
                        //   })
                      }}
                    >
                      Back to outputs
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => props.confirmAucklandStandard()}
                    >
                      Mark as Complete
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TalentContract;
