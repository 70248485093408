import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function GooglePlacesEdit(props) {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState(props.locationObj);
  const [options, setOptions] = React.useState([]);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  const changeLocation = async (obj, value) => {
    if (value !== null) {
      const results = await geocodeByAddress(value.description);
      const latLng = await getLatLng(results[0]);
      switch (props.type) {
        case "LocationEdit":
          props.setLatLngDetails({
            latitude: latLng.lat,
            longitude: latLng.lng,
          });
          // if (results[0].formatted_address.split(", ").length === 4) {
          //     props.changeAddress(results[0].formatted_address);
          //     props.setNewLocationAddress(results[0].formatted_address);
          //     value.description = results[0].formatted_address;
          // }
          // else {
          props.changeAddress(value.description);
          props.setNewLocationAddress(value.description);
          // }
          props.setLocationObj(value);
          break;
        case "CompanyEdit":
          break;
        default:
          break;
      }
    }
  };

  React.useEffect(() => {
    console.log("options");
    console.log(options);
  }, [options]);

  // React.useEffect(() => {
  //     // if(inputValue !== null && inputValue !== undefined){
  //     //     props.changeAddress(inputValue);
  //     // }
  // }, [inputValue]);

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions([]);
      return undefined;
    }

    fetch(
      { input: inputValue, componentRestrictions: { country: "nz" } },
      (results) => {
        if (active) {
          setOptions(results || []);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return (
    <Autocomplete
      id="google-map-demo"
      style={{ width: "100%" }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      defaultValue={inputValue}
      autoComplete
      includeInputInList
      disableOpenOnFocus
      onChange={changeLocation}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select a New Location"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          style={{ marginTop: "10px" }}
        />
      )}
      renderOption={(option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {/* {option.structured_formatting.secondary_text} */}
                {option.description}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
