import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import { removeDuplicates } from "../../utils/OutputsHelpers";
import "./Home.css";

export const Output = (props) => {
  // const classes = useStyles();

  var platform = removeDuplicates(props.output.platform);

  return (
    <Draggable draggableId={props.output.id.toString()} index={props.index}>
      {(provided) => (
        <Paper
          className="output-container"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Typography className="typography-output-card">
            {props.output.adDuration + " - " + props.output.creativeName + (platform != null ? (" | " + platform) : "")}
          </Typography>
          {props.output.delivered === 1 && (
            <CheckCircle
              style={{
                color: "#4dfa59",
                float: "right",
                height: 15,
                width: 15,
                backgroundColor: "white",
                marginTop: "-10px",
              }}
            />
          )}
        </Paper>
      )}
    </Draggable>
  );
};
