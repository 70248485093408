import React, { createContext, useContext, useEffect, useState } from "react";

const LOCAL_STORAGE_THEME_MODE = "zeus-theme-mode";
export const ThemeContext = createContext({
  mode: "dark",
});

export const ThemeContextProvider = ({ children }) => {
  const [mode, setMode] = useState("dark");

  const toggleMode = () => {
    const themeName = mode === "dark" ? "light" : "dark";
    localStorage.setItem(LOCAL_STORAGE_THEME_MODE, themeName);
    setMode(themeName);
  };

  const loadDefaultTheme = () => {
    if (localStorage.getItem(LOCAL_STORAGE_THEME_MODE)) {
      localStorage.getItem(LOCAL_STORAGE_THEME_MODE) === "dark"
        ? setMode("dark")
        : setMode("light");
    }
  };

  useEffect(() => {
    loadDefaultTheme();

    return () => {
      loadDefaultTheme();
    };
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        mode,
        toggleMode
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export function useTheme() {
  return useContext(ThemeContext);
}
