import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Switch,
  Typography,
} from "@material-ui/core";
import React from "react";

const InfluencerAutoChaseBtn = ({ isChecked, handleChange, title = "Auto Chase" }) => {
  return (
    <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
      <Typography variant="caption">{title}</Typography>
      <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
      <Typography variant="caption">No</Typography>
        <FormControlLabel
          control={
            <Switch
              checked={isChecked}
              color="primary"
              onChange={(e) => {
                e.stopPropagation();
                handleChange(e.target.checked);
              }}
              size="small"
            />
          }
          label={<Typography variant="caption">Yes</Typography>}
        />
      </div>
    </div>
  );
};

export default InfluencerAutoChaseBtn;
