import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  companies: [],
  projectFilter: {
    organisationId: null,
    users: [],
    companyId: null,
    keyword: "",
    team: [],
    includeApproved: false,
    status: "",
  },
};

const thompsonSpencerProject = createReducer(initialState, {
  UPDATE_PROJECT_FILTER: (state, action) => {
    const { field, value } = action.payload;
    return {
      ...state,
      projectFilter: { 
        ...state.projectFilter, 
        [field]: value },
    };
  },
  UPDATE_COMPANIES: (state, action) => {
    return {
      ...state,
      companies: action.payload,
    };
  }
});

export default thompsonSpencerProject;
