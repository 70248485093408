import React, { useState, useEffect } from "react";
import {
    Modal,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Button,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        height: "auto",
        maxHeight: "80vH",
        width: "500px",
        overflowY: "auto"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const SetMediaAdPersonModal = (props) => {
    const classes = useStyles();
    const [originalUser, setOriginalUser] = useState(null);
    const [user, setUser] = useState(null);
    const [userList, setUserList] = useState(null);

    console.log("Modal")
    console.log(props)
    const getUserDropdown = () => {
        return (
            <div className="textfield-padding">
                <FormControl fullWidth>
                    <InputLabel htmlFor="">User</InputLabel>
                    <Select
                        value={user}
                        onChange={event => setUser(event.target.value)}
                        inputProps={{
                            name: 'User',
                            id: 'user-role-popup',
                        }}
                    >
                        {
                            userList.map(u => {
                                return (
                                    <MenuItem key={u.id} value={u}>{`${u.firstName} ${u.lastName}`}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </div>
        )
    }

    const handleSave = () => {
        if (originalUser.id !== user.id) {
            props.handleCloseModal();
            props.changeCurrentUser(user);
            originalUser.mediaAdsManager = 0;
            user.mediaAdsManager = 1;
            Axios.patch(`MediaAdDocuments/UpdateMediaAdManager/${originalUser.id}`, originalUser)
                .then(res => props.setGetMediaAdPerson(false))
                .catch(e => console.log(e))
            Axios.patch(`MediaAdDocuments/UpdateMediaAdManager/${user.id}`, user)
                .then(res => props.setGetMediaAdPerson(false))
                .catch(e => console.log(e))
        }
    }

    useEffect(() => {
        Axios.get("Users/GetUsers")
            .then((res) => {
                console.log("Users");
                console.log(res.data);
                setUserList(res.data);
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        if (userList !== null) {
            userList.map(u => {
                if (u.mediaAdsManager) {
                    setUser(u);
                    setOriginalUser(u);
                }
            })
        }
    }, [userList])

    if (userList === null) {
        return <React.Fragment></React.Fragment>
    }
    return (
        <div>
            <Modal
                open={props.modalState}
                onClose={() => props.handleCloseModal()}
                className={classes.modal}
            >
                <div>
                    <AppBar position="static" style={{ background: "#3d4977" }}>
                        <Toolbar
                            disableGutters
                            variant="dense"
                        >
                            <Typography
                                variant="subtitle1"
                                align="center"
                                style={{ flexGrow: 1 }}
                            >
                                {`Change Reimbursement Manager`}
                            </Typography>
                            <IconButton
                                onClick={() => props.handleCloseModal()}
                                color="inherit"
                            >
                                <Close />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.paper}>
                        {getUserDropdown()}
                        <div
                            style={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => handleSave()}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default SetMediaAdPersonModal;