import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Axios from "axios";
import {
  Grid,
  Button,
  Snackbar,
  Switch,
  FormControl,
  FormControlLabel,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import "./Admin.css";
import MuiAlert from "@material-ui/lab/Alert";
import Loader from "../Loader";
import { Skeleton, Autocomplete } from "@material-ui/lab";
import Snacky from "../Shared/Snacky";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const System = (props) => {
  const classes = useStyles();
  const [systemSettings, setSystemSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });
  const [expenseCodes, setExpenseCodes] = useState([]);
  const [loadingExpenseCodes, setLoadingExpenseCodes] = useState(false);

  useEffect(() => {
    getSystemSettings();
  }, []);

  useEffect(() => {
    getExpenseCodes();
  }, []);

  const getExpenseCodes = () => {
    setLoadingExpenseCodes(true);
    Axios.get(`ExpenseCodes/GetExpenseCodesByOrganisation/-1`)
      .then(({ data }) => {
        setExpenseCodes(data);
      })
      .catch((e) => {
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to load expense codes",
        });
      })
      .finally(() => setLoadingExpenseCodes(false));
  };

  const getSystemSettings = () => {
    setIsLoading(true);
    Axios.get(`Systems/GetSystemSettings`)
      .then(({ data }) => {
        setSystemSettings(data);
      })
      .catch((e) => {
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to Load Settings",
        });
      })
      .finally(() => setIsLoading(false));
  };

  const saveChanges = () => {
    Axios.post(`Systems/UpdateSystemSettings`, systemSettings)
      .then((res) => {
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Fantastic! Your changes have been saved.",
        });
      })
      .catch((e) => {
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Something went wrong",
        });
      });
  };

  return (
    <main className={classes.content}>
      <div className="main-content" />
      <Typography variant="h4">System</Typography>
      <Snacky snackprops={snackBarProps} setSnackBarProps={setSnackBarProps} />

      {isLoading ? (
        <Skeleton height={250} />
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr",
            gap: 15,
            maxWidth: 300,
            alignItems: "start",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={!!systemSettings?.creativeDeadlineLock}
                color="primary"
                onChange={(e) => {
                  setSystemSettings({
                    ...systemSettings,
                    creativeDeadlineLock: e.target.checked ? 1 : 0,
                  });
                }}
                name="creative-date-lock-switch"
              />
            }
            label="Creative Date Lock"
          />
          <TextField
            fullWidth
            value={systemSettings?.foreignExchangeMargin || 0}
            variant={"outlined"}
            label={"Foreign Exchange Margin"}
            onChange={(e) => {
              setSystemSettings({
                ...systemSettings,
                foreignExchangeMargin: Number(e.target.value),
              });
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
          <Autocomplete
            options={expenseCodes.filter(e => e.accountCode)}
            getOptionLabel={(code) =>
              code.accountCode ? `${code.accountCode} - ${code.accountName} - ${code.organisationFk == 1 ? "Mag" : "TS"}` : ""
            }
            value={
              expenseCodes.find((ec) => ec.accountCode == systemSettings.mediaAccrualAccountCode) ?? ""
            }
            onChange={(e, newValue) => {
              if (newValue) {
                setSystemSettings({
                  ...systemSettings,
                  mediaAccrualAccountCode: newValue?.accountCode,
                });
              } else {
                setSystemSettings({
                  ...systemSettings,
                  mediaAccrualAccountCode: null,
                });
              }
            }}
            size="small"
            style={{ marginTop: 9 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Media accrual account code"
                name="mediaAccrualAccountCode"
                variant="outlined"
              />
            )}
          />
          <Autocomplete
            options={expenseCodes.filter(e => e.accountCode)}
            getOptionLabel={(code) =>
              code.accountCode ? `${code.accountCode} - ${code.accountName} - ${code.organisationFk == 1 ? "Mag" : "TS"}` : ""
            }
            value={
              expenseCodes.find((ec) => ec.accountCode == systemSettings.mediaRevenueAccountCode) ?? ""
            }
            onChange={(e, newValue) => {
              if (newValue) {
                setSystemSettings({
                  ...systemSettings,
                  mediaRevenueAccountCode: newValue?.accountCode,
                });
              } else {
                setSystemSettings({
                  ...systemSettings,
                  mediaRevenueAccountCode: null,
                });
              }
            }}
            size="small"
            style={{ marginTop: 9 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Media revenue account code"
                name="mediaRevenueAccountCode"
                variant="outlined"
              />
            )}
          />
          <Autocomplete
            options={expenseCodes.filter(e => e.accountCode)}
            getOptionLabel={(code) =>
              code.accountCode ? `${code.accountCode} - ${code.accountName} - ${code.organisationFk == 1 ? "Mag" : "TS"}` : ""
            }
            value={
              expenseCodes.find((ec) => ec.accountCode == systemSettings.mediaDeliveryAccountCode) ?? ""
            }
            onChange={(e, newValue) => {
              if (newValue) {
                setSystemSettings({
                  ...systemSettings,
                  mediaDeliveryAccountCode: newValue?.accountCode,
                });
              } else {
                setSystemSettings({
                  ...systemSettings,
                  mediaDeliveryAccountCode: null,
                });
              }
            }}
            size="small"
            style={{ marginTop: 9 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Media delivery account code"
                name="mediaDeliveryAccountCode"
                variant="outlined"
              />
            )}
          />
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={saveChanges}
          >
            Save
          </Button>
        </div>
      )}
    </main>
  );
};

export default System;
