export default function daysLeft(deadline) {
  /* Two working days and an sunday (not working day) */
  var holidays = [
    "01/01/20",
    "01/02/20",
    "01/27/20",
    "02/06/20",
    "04/10/20",
    "04/13/20",
    "04/27/20",
    "06/01/20",
    "10/26/20",
    "12/25/20",
    "12/28/20",
  ];
  var d0 = new Date();
  //   var startDate = parseDate(d0);
  var startDate = d0;
  var d1 = deadline;
  //   var endDate = parseDate(deadline);
  var endDate = deadline;

  // Validate input
  if (endDate < startDate) {
    return 0;
  }

  // Calculate days between dates
  var millisecondsPerDay = 86400 * 1000; // Day in milliseconds
  startDate.setHours(0, 0, 0, 1); // Start just after midnight
  endDate.setHours(23, 59, 59, 999); // End just before midnight
  var diff = endDate - startDate; // Milliseconds between datetime objects
  var days = Math.ceil(diff / millisecondsPerDay);

  // Subtract two weekend days for every week in between
  var weeks = Math.floor(days / 7);
  days -= weeks * 2;

  // Handle special cases
  var startDay = startDate.getDay();
  var endDay = endDate.getDay();

  // Remove weekend not previously removed.
  if (startDay - endDay > 1) {
    days -= 2;
  }
  // Remove start day if span starts on Sunday but ends before Saturday
  if (startDay == 0 && endDay != 6) {
    days--;
  }
  // Remove end day if span ends on Saturday but starts after Sunday
  if (endDay == 6 && startDay != 0) {
    days--;
  }
  /* Here is the code */
  holidays.forEach((day) => {
    var holiday = new Date(day);
    if (holiday >= d0 && holiday <= d1) {
      /* If it is not saturday (6) or sunday (0), substract it */
      //       if ((parseDate(day).getDay() % 6) != 0) {
      if (holiday.getDay() % 6 != 0) {
        days--;
      }
    }
  });
  if (days) {
    return days;
  } else {
    return "---";
  }
}
