import { IconButton, Tooltip, Typography, withStyles } from "@material-ui/core";
import React from "react";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import "./agentTooltipIcon.scss";
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const AgentTooltipIcon = ({ agent, size = "small" }) => {
  return (
    <div>
      <HtmlTooltip
        title={
          !!agent && (
            <div
              className="agent-tooltip-icon-details-container"
              onClick={(event) => event.stopPropagation()}
            >
              <Typography>
                <strong>Agent Contact Details</strong>
              </Typography>
              <Typography variant="caption">
                <strong>{"Name: "}</strong>
                {agent.firstName + " " + agent.lastName}
              </Typography>
              <Typography variant="caption">
                <strong>{"Phone: "}</strong>
                {agent.phoneNumber}
              </Typography>
              <Typography variant="caption">
                <strong>{"Email: "}</strong>
                {agent.emailAddress}
              </Typography>
              <br />
            </div>
          )
        }
        interactive
        arrow
        placement="right"
      >
        <IconButton
          className={size === "small" ? "agent-tooltip-icon-size-small" : ""}
          onClick={(event) => event.stopPropagation()}
          size="small"
          style={{
            padding: 0,
            margin: 0,
          }}
        >
          <ContactMailIcon />
        </IconButton>
      </HtmlTooltip>
    </div>
  );
};

export default AgentTooltipIcon;
