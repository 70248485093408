import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Paper,
  IconButton,
} from "@material-ui/core";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Edit as EditIcon,
  PictureAsPdf as PictureAsPdfIcon
} from "@material-ui/icons";
import MasterPageMediaInvoiceTable from "./MasterPageMediaInvoiceTable";

const MediaMasterPageClientRow = ({ client }) => {
  const [openMediaInvoices, setOpenMediaInvoices] = useState(false);

  const getUniqueMediaInvoices = () => {
    var uniqueMediaInvoices = [];
    for(var i = 0; i < client?.mediaInvoices.length; i++){
      if(uniqueMediaInvoices.findIndex(mi => mi.invoiceNumber == client?.mediaInvoices[i].invoiceNumber) == -1){
        uniqueMediaInvoices.push(client?.mediaInvoices[i])
      }
    }
    return uniqueMediaInvoices;
  }

  const renderBadgeComponent = () => {
    var classes = "badge badge-success";
    var text = "Ready"
    for(var i = 0; i < client?.mediaSpend.length; i++){
      if(client?.mediaSpend[i].mediaInvoice === null) {
        classes="badge badge-warning"
        text = "Not Ready";
        break;
      } 
    }

    return (
      <div className={classes}>
        <Typography variant="caption">
          {text}
        </Typography>
      </div>
    )
  }

  return (
    <Container component={Paper} id="media-master-page-client-row-root">
      <div className="header-container">
        <div style={{display: "flex"}}>
          <IconButton size="small">
            {!openMediaInvoices ? (
              <KeyboardArrowDownIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenMediaInvoices(!openMediaInvoices);
                }}
              />
            ) : (
              <KeyboardArrowUpIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenMediaInvoices(!openMediaInvoices);
                }}
              />
            )}
          </IconButton>
          <Typography variant="h6">
            {client.companyName}
          </Typography>
        </div>
       
        <div className="align-self-center">
          {renderBadgeComponent()}
        </div>
      </div>
      {openMediaInvoices && (
        <MasterPageMediaInvoiceTable
          mediaInvoices={getUniqueMediaInvoices()}
        />
      )}
    </Container >
  );
};

export default MediaMasterPageClientRow;
