import React, { useEffect, useState } from "react";
import HomepageProjectRow from "./HomepageProjectRow";
import { Collapse, Table, TableBody } from "@material-ui/core";
import ConfirmationDialogActions from "../Shared/ConfirmationDialogActions";
import HomepageClientCardFooter from "./HomepageClientCardFooter";

const HomepageClientCard = ({
    client,
    updateProjectStatus,
    hideProjectStatus,
    unhideProjectStatus,
    showHidden,
    department
}) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [projectStatusData, setProjectStatusData] = useState(null);


    return (
        <div className="homepage-client-card">
            {showConfirmation && (
                <ConfirmationDialogActions
                    title={showHidden ? "Unhide project?" : "Mark as hidden?"}
                    open={showConfirmation}
                    contentText={showHidden ? `This will unhide the project and add it back to the master list of active projects`
                        : `This will hide the project from the master list of active projects`}
                    button1Action={() => setShowConfirmation(false)}
                    button1Text={"Go Back"}
                    button1Class="default-button-grey"
                    button2Text={"Confirm"}
                    button2Class="default-button"
                    button2Action={() => {
                        if (showHidden) {
                            unhideProjectStatus(
                                projectStatusData.companyId,
                                projectStatusData.jobId,
                                projectStatusData.projectStatusId);
                        } else {
                            hideProjectStatus(
                                projectStatusData.companyId,
                                projectStatusData.jobId,
                                projectStatusData.projectStatusId);
                        }
                        setShowConfirmation(false);
                    }}
                    handleClose={() => setShowConfirmation(false)}
                />
            )}
            <div className="homepage-client-card-header">
                {client.companyName}
            </div>
            <Collapse in={true}>
                <div>
                    {client.projects.map(p => {
                        return (
                            <div className="homepage-client-card-body">
                                {p.projectStatus.map(ps => {
                                    return (
                                        <div>
                                            <HomepageProjectRow
                                                companyId={client.companyId}
                                                jobId={p.jobId}
                                                projectStatus={ps}
                                                updateProjectStatus={updateProjectStatus}
                                                project={p}
                                            />
                                            <HomepageClientCardFooter
                                                department={department}
                                                setProjectStatusData={setProjectStatusData}
                                                showHidden={showHidden}
                                                client={client}
                                                setShowConfirmation={setShowConfirmation}
                                                projectStatus={ps}
                                                project={p}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </Collapse>
        </div >
    )
}

export default HomepageClientCard;