import { TextField, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import {
  calculateTotalTimeSpentInMinutes,
  handleInputRestriction,
  minutesToHHMM,
  processTimeEntries,
  validateTimeFormat,
} from "../../../utils/TimeTrackerUtils";
import TimeInput from "../../Shared/Inputs/TimeInput";
import TimeTrackerCaptionLabel from "../TimeTrackerCaptionLabel";
import ConfirmationDialogActions from "../../Shared/ConfirmationDialogActions";
import TimeTrackerAllocationInput from "./TimeTrackerAllocationInput";

const TimeTrackerAllocationRow = ({
  datesToDisplay,
  timeEntriesByProjects,
  onTimeEntryChange,
  closeProject,
}) => {
  const [hours, setHours] = useState(() =>
    processTimeEntries(timeEntriesByProjects, datesToDisplay)
  );
  const [selectedProject, setSelectedProject] = useState(null);

  // Memoize processed time entries to avoid unnecessary recalculations
  const processedTimeEntries = useMemo(
    () => processTimeEntries(timeEntriesByProjects, datesToDisplay),
    [timeEntriesByProjects, datesToDisplay]
  );

  // Sync state with processed time entries when dependencies change
  useEffect(() => {
    setHours(processedTimeEntries);
  }, [processedTimeEntries]);

  // Handle time input change
  const handleInputChange = (projectId, date, field, value, timeEntry) => {
    // Validate input value
    //if (!validateTimeFormat(value)) return;

    const { description } = timeEntry;

    setHours((prevHours) => ({
      ...prevHours,
      [projectId]: {
        ...prevHours[projectId],
        [date]: {
          [field]: value,
        },
      },
    }));

    // Trigger the callback to notify parent component of changes
    if (onTimeEntryChange) {
      onTimeEntryChange(projectId, date, value, description, timeEntry);
    }
  };

  const handleDescriptionChange = (
    projectId,
    date,
    field,
    value,
    timeEntry
  ) => {

    const { timeSpentInMinutes } = timeEntry;

    setHours((prevHours) => ({
      ...prevHours,
      [projectId]: {
        ...prevHours[projectId],
        [date]: {
          [field]: value,
        },
      },
    }));

    // Trigger the callback to notify parent component of changes
    if (onTimeEntryChange) {
      onTimeEntryChange(projectId, date, timeSpentInMinutes, value, timeEntry);
    }
  };

  const handleSelectProject = (projectId) => {
    setSelectedProject(projectId);
  };
  const handleCloseProjectPopUp = () => {
    setSelectedProject(null);
  };

  return (
    <>
      {selectedProject && (
        <ConfirmationDialogActions
          title={"Close Project"}
          open={selectedProject}
          contentText={
            "This action will close the project. You can restore it later."
          }
          button1Class={"default-button-grey roboto-regular"}
          button1Text={"Cancel"}
          button1Action={(e) => {
            handleCloseProjectPopUp();
          }}
          button2Class={"default-button roboto-regular"}
          button2Text={"Confirm"}
          button2Action={async (e) => {
            await closeProject(selectedProject);
            handleCloseProjectPopUp();
          }}
          handleClose={() => handleCloseProjectPopUp()}
        />
      )}
      {timeEntriesByProjects.map((project) => (
        <div
          className="time-tracker-hour-allocation-row"
          key={project.projectId}
        >
          <div className="time-tracker-hour-allocation-row-project-name-container add-background text-alignment">
            <Typography
              variant="caption"
              className="time-tracker-project-name"
            >{`${project.jobId ? "[" + project.jobId + "] " : ""}${
              project.projectName
            }`}</Typography>
            <div
              onClick={() => handleSelectProject(project.projectId)}
              className="time-tracker-hour-allocation-row-close-project"
            >
              <Typography variant="caption">close</Typography>
            </div>
          </div>
          <div className="time-tracker-hour-allocation-column-container time-tracker-allocation-container">
            {datesToDisplay.map((date) => {
              const formattedDate = moment(date).format("YYYY-MM-DD");
              return (
                <div key={formattedDate}>
                  <TimeTrackerAllocationInput
                    timeEntry={hours[project.projectId]?.[formattedDate]}
                    initialMinutes={
                      hours[project.projectId]?.[formattedDate]
                        ?.timeSpentInMinutes
                    }
                    onTimeChange={(minutes) =>
                      handleInputChange(
                        project.projectId,
                        formattedDate,
                        "timeSpentInMinutes",
                        minutes,
                        hours[project.projectId]?.[formattedDate]
                      )
                    }

                    onDescriptionChange={(description) =>
                      handleDescriptionChange(
                        project.projectId,
                        formattedDate,
                        "description",
                        description,
                        hours[project.projectId]?.[formattedDate]
                      )
                    }
                  />
                </div>
              );
            })}
          </div>
          <div style={{ minWidth: 50 }}>
            <TimeTrackerCaptionLabel
              value={minutesToHHMM(
                calculateTotalTimeSpentInMinutes(project.timeEntries)
              )}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default TimeTrackerAllocationRow;
