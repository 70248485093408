import {
    Paper,
    TableContainer,
    Typography
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { currencyFormat } from "../../../utils/GeneralUtils";
import CustomTableHead from "../../Shared/CustomTableHead";
import "./clientPeopleOfInfluenceTable.scss";
import "./peopleOfInfluenceReimburesmentTable.scss";

const PeopleOfInfluenceReimbursementTable = ({
    companyId
}) => {
    const [reimbursements, setReimbursements] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showHidden, setShowHidden] = useState(false);

    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState({
        field: "name",
        name: "",
        fieldType: "string",
    });
    const tableHeader = [
        {
            field: "name",
            name: "Talent",
            fieldType: "string",
            alignment: "left",
        },
        {
            field: "category",
            name: "Budget",
            fieldType: "string",
            alignment: "left",
        },
        {
            field: "description",
            name: "Description",
            fieldType: "string",
            alignment: "left",
        },
        {
            field: "amount",
            name: "Amount",
            fieldType: "number",
            alignment: "left",
        },
        {
            field: "billStatus",
            name: "Status",
            fieldType: "number",
            alignment: "left",
        }
    ];

    const handleOnRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const getReimbursmentsForCompany = () => {
        setLoading(true)
        axios.get(`Receipts/GetReimbursementsForCompany/${companyId}`)
            .then(res => {
                setReimbursements(res.data)
            }).catch(e => {

            }).finally(() => {
                setLoading(false)
            })
    }

    const filterReimbursements = () => {
        if (showHidden) {
            return reimbursements;
        } else {
            return reimbursements.filter((r) => {
                return (r.billStatus !== "Rejected" && !r.pastTwoMonths );
            });
        }
    };

    useEffect(() => {
        getReimbursmentsForCompany();
    }, [])

    return (
        <div className="client-people-of-influence-table-root-container">
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "end" }}>
                <Typography
                    variant="caption"
                    className="reimbursement-show-hidden-link-button"
                    onClick={(e) => setShowHidden(!showHidden)}
                >
                    {showHidden ? "Hide Hidden Reimbursements" : "Show Hidden Reimbursements"}
                </Typography>
            </div>
            <TableContainer component={Paper}>
                <table className="custom-table custom-table-border custom-table-hover socialites-projects-table">
                    <CustomTableHead
                        headers={tableHeader}
                        orderBy={orderBy}
                        order={order}
                        onRequestSort={handleOnRequestSort}
                    />
                    <tbody>
                        {filterReimbursements().map(r => {
                            return (
                                <tr className="client-people-of-influence-table-tbody-tr">
                                    <td><Typography variant="caption">{`${r.name}`}</Typography></td>
                                    <td><Typography variant="caption">{`${r.category}`}</Typography></td>
                                    <td><Typography variant="caption">{`${r.description}`}</Typography></td>
                                    <td><Typography variant="caption">{currencyFormat(r.amount)}</Typography></td>
                                    <td><Typography variant="caption">{`${r.billStatus}`}</Typography></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </TableContainer>
            {reimbursements && reimbursements.length === 0 && (
                <div className="purchase-order-table-no-records">
                    <Typography variant="h6">No reimbursements...</Typography>
                </div>
            )}
        </div>
    )
}

export default PeopleOfInfluenceReimbursementTable;