import React, { useEffect, useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { format, parse } from "date-fns";
import { DynamicDropdownPlatform } from "./../OtherOutputs";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {
  Modal,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Input,
  Chip,
  FormControl,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: "600px",
    width: "1000px",
    overflowY: "auto",
  },
}));

const platforms = [
  "TV",
  "VOD",
  "Facebook",
  "Instagram",
  "LinkedIn",
  "Snapchat",
  "Website",
  "Radio",
  "Poster",
  "Photo",
  "Cinema",
  "Tik Tok"
];

const publishers = ["Discovery",  "Sky", "TVNZ"];

const OutputEditPopup = (props) => {
  const classes = useStyles();

  console.log(props.outputDetails);

  const [isEditing, setIsEditing] = React.useState(false);
  const [platform, setPlatform] = React.useState(
    props.outputDetails.platform.replaceAll(/[\"\[\]"]/g, "").split(",")
  );
  const [publisher, setPublisher] = useState(
    props.outputDetails.publisher.replaceAll(/[\"\[\]"]/g, "").split(",")
  );

  const toggleEdit = () => {
    isEditing ? setIsEditing(false) : setIsEditing(true);
  };

  const [output, setOutput] = React.useState({
    id: props.outputDetails.id,
    client: props.outputDetails.client,
    creativeName: props.outputDetails.creativeName,
    duration: props.outputDetails.duration,
    platform: props.outputDetails.platform,
    notes: props.outputDetails.notes,
    publisher: props.outputDetails.publisher,
    startDate: props.outputDetails.startDate,
    subtitles: props.outputDetails.subtitles,
    creativeCode: props.outputDetails.creativeCode,
    geolocation: props.outputDetails.geolocation,
    creativeLink: props.outputDetails.creativeLink,
    targetAudience: props.outputDetails.targetAudience,
    fromMediaCore: props.outputDetails.fromMediaCore,
    projectsFk: props.outputDetails.projectsFk,
    status: props.outputDetails.status,
    keyNumberFkNavigation: props.outputDetails.keyNumberFkNavigation
  });

  const handleOutputChange = (name) => (event) => {
    setOutput({ ...output, [name]: event.target.value });
  };

  const handleDateChange = (name, date) => {
    setOutput({ ...output, [name]: date });
  };

  const handleDropDownChange = (name, value) => {
    setOutput({ ...output, [name]: value });
  };

  const handlePlatformChange = (event, rowObj) => {
    setPlatform(event.target.value);
  };

  const handlePublisherChange = (event, rowObj) => {
    setPublisher(event.target.value);
  };

  useEffect(() => {
    setOutput({
      id: props.outputDetails.id,
      client: props.outputDetails.client,
      creativeName: props.outputDetails.creativeName,
      duration: props.outputDetails.duration,
      platform: props.outputDetails.platform,
      notes: props.outputDetails.notes,
      publisher: props.outputDetails.publisher,
      startDate: props.outputDetails.startDate,
      subtitles: props.outputDetails.subtitles,
      creativeCode: props.outputDetails.creativeCode,
      geolocation: props.outputDetails.geolocation,
      creativeLink: props.outputDetails.creativeLink,
      targetAudience: props.outputDetails.targetAudience,
      fromMediaCore: props.outputDetails.fromMediaCore,
      projectsFk: props.outputDetails.projectsFk,
      status: props.outputDetails.status,
      keyNumberFkNavigation: props.outputDetails.keyNumberFkNavigation
    });
    setPlatform(props.outputDetails.platform.replaceAll(/[\"\[\]"]/g, "").split(","));
  }, []);

  useEffect(() => {
    handleDropDownChange("Platform", platform.join(","));
    if (platform.indexOf("TV") === -1 && platform.indexOf("VOD") === -1) {
      setPublisher([]);
    }
  }, [platform]);

  useEffect(() => {
    handleDropDownChange("Publisher", publisher.join(","));
  }, [publisher]);

  useEffect(() => {
    console.log("output changed edit");
    console.log(output);
  }, [output]);

  return (
    <div>
      <Modal
        aria-labelledby="delivered-output-modal"
        aria-describedby="delivered-output-modal"
        open={props.modalState}
        onClose={props.handleModal}
        className={classes.modal}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <div>
          <AppBar position="static" style={{ background: "#217F8B" }}>
            <Toolbar variant="dense" style={{ paddingRight: 0 }}>
              <Typography
                variant="subtitle1"
                align="center"
                style={{ flexGrow: 1 }}
              >
                Edit Output Details
              </Typography>
              <IconButton
                aria-haspopup="true"
                onClick={toggleEdit}
                style={!isEditing ? {} : { display: "none" }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                color="inherit"
                onClick={() => {
                  toggleEdit();
                  //props.handleSaveChanges();
                  // props.handleRowUpdate(props.outputDetails, output);
                  props.handleSaveEditManualOutput(output)
                }}
                style={isEditing ? {} : { display: "none" }}
              >
                <SaveIcon style={{ color: "#2d2d2d" }} />
              </IconButton>
              <IconButton
                aria-haspopup="true"
                onClick={props.handleModal}
                color="inherit"
                style={!isEditing ? {} : { display: "none" }}
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div className={classes.paper}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={2}>
                {/* Row 1 */}
                <Grid item xs={6} md={6} lg={6}>
                  <TextField
                    name={"Key Number"}
                    label="Key Number"
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={output.keyNumberFkNavigation !== null ? output.keyNumberFkNavigation.keynumber : ""}
                    // onChange={handleOutputChange("Key Number")}
                    // value={output.KeyNumber}
                    // onChange={handleOutputChange("KeyNumber")}
                    disabled={!isEditing}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <TextField
                    name={"Creative Name"}
                    label="Creative Name"
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={output.creativeName}
                    onChange={handleOutputChange("creativeName")}
                    disabled={!isEditing}
                  />
                </Grid>
                {/* Row 2 */}
                <Grid item xs={4} md={4} lg={4}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Creative Deadline"
                    format="dd/MM/yyyy"
                    value={output.startDate}
                    InputAdornmentProps={{ position: "start" }}
                    onChange={function (date, value) {
                      console.log("!!!!!!");
                      console.log(date);
                      console.log(value);
                      handleDateChange("startDate", date);
                    }}
                    disabled={!isEditing}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <TextField
                    label="Target Audience"
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={output.targetAudience}
                    onChange={handleOutputChange("targetAudience")}
                    disabled={!isEditing}
                  />
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <TextField
                    name={"Duration"}
                    label="Duration"
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={output.duration}
                    onChange={handleOutputChange("duration")}
                    disabled={!isEditing}
                  />
                </Grid>
                {/* Row 4 */}
                <Grid item xs={6} md={6} lg={6}>
                  <TextField
                    name={"platform"}
                    label="Platform"
                    variant="outlined"
                    style={!isEditing ? { width: "100%" } : { display: "none" }}
                    value={
                      props.outputDetails.platform.replaceAll(/[\"\[\]"]/g, "") != undefined
                        ? props.outputDetails.platform.replaceAll(/[\"\[\]"]/g, "")
                        : "Not available"
                    }
                    multiline={true}
                    // onChange={(event) => {
                    //   console.log(event);
                    //   //props.handleInputChange(event, props.outputDetails);
                    //   handleOutputChange("platform")
                    // }}
                    disabled={!isEditing}
                  />
                  <InputLabel
                    shrink
                    style={
                      isEditing
                        ? { width: "100%", paddingTop: "0px" }
                        : { display: "none" }
                    }
                  >
                    Platform
                  </InputLabel>
                  <Select
                    multiple
                    value={platform === "" ? null : platform}
                    input={<Input />}
                    renderValue={(selected) => selected.join(", ")}
                    onChange={(event) => handlePlatformChange(event, props.row)}
                    style={isEditing ? { width: "100%" } : { display: "none" }}
                  >
                    {platforms.map((platformName) => (
                      <MenuItem
                        key={platformName}
                        value={platformName}
                        style={{
                          fontSize: "14px",
                          maxHeight: "18px",
                          margin: "1px",
                        }}
                      >
                        <Checkbox
                          checked={platform.indexOf(platformName) > -1}
                        />
                        <ListItemText primary={platformName} />
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <TextField
                    name={"publisher"}
                    label="Publisher"
                    variant="outlined"
                    style={!isEditing ? { width: "100%" } : { display: "none" }}
                    value={
                      props.outputDetails.publisher.replaceAll(/[\"\[\]"]/g, "") != undefined
                        ? props.outputDetails.publisher.replaceAll(/[\"\[\]"]/g, "")
                        : "Not available"
                    }
                    multiline={true}
                    disabled={!isEditing}
                  />
                  <div
                    style={{
                      display:
                        platform.indexOf("TV") === -1 &&
                          platform.indexOf("VOD") === -1
                          ? "none"
                          : "",
                    }}
                  >
                    <InputLabel
                      shrink
                      style={
                        isEditing
                          ? { width: "100%", paddingTop: "0px" }
                          : { display: "none" }
                      }
                    >
                      Publisher
                    </InputLabel>
                    <Select
                      multiple
                      value={publisher}
                      input={<Input />}
                      renderValue={(selected) => selected.join(", ")}
                      onChange={(event) =>
                        handlePublisherChange(event, props.row)
                      }
                      style={
                        isEditing
                          ? { width: "100%", paddingTop: "0px" }
                          : { display: "none" }
                      }
                    >
                      {publishers.map((publisherName) => (
                        <MenuItem
                          key={publisherName}
                          value={publisherName}
                          style={{
                            fontSize: "14px",
                            maxHeight: "18px",
                            margin: "1px",
                          }}
                        >
                          <Checkbox
                            checked={publisher.indexOf(publisherName) > -1}
                          />
                          <ListItemText primary={publisherName} />
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    name={"notes"}
                    label="Notes"
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={output.notes}
                    multiline={true}
                    onChange={handleOutputChange("notes")}
                    disabled={!isEditing}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default OutputEditPopup;
