import React, { useState, useEffect } from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { setRedirectTo } from "../../actions/adminMenu";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import {
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
} from "@material-ui/core";
import {
  Mail,
  AttachMoney,
  Restaurant,
  Description,
  OfflineBolt,
  Feedback,
  Sms,
  LocalHospital,
  Theaters,
  People,
  CreditCard as CreditCardIcon,
  Home as HomeIcon,
  Archive as ArchiveIcon,
  UnfoldMore as UnfoldMoreIcon,
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
  SupervisorAccount as SupervisorAccountIcon,
  AccountBalance as AccountBalanceIcon,
  ContactMail as ContactMailIcon,
  Person as PersonIcon,
  Info as InfoIcon,
  Camera as CameraIcon,
} from "@material-ui/icons";
import { useUserContext } from "../../context/user/UserContext";
import ThompsonSpencerLogoNoBg from "../../assets/images/ThompsonSpencerLogoNoBg";
import { useAuth } from "../../context/auth/AuthContext";

const drawerWidth = "auto";

const useTreeItemStyles = makeStyles((theme) => ({
  root: {},
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  iconContainer: {
    width: 0,
  },
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "right",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarTitle: {
    background: "#182d56",
    width: "100%",
    left: 0,
    alignItems: "center",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    overflowY: "unset",
    border: 0,
    backgroundColor: "#000000",
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

const AdminPanel = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const treeClasses = useTreeItemStyles();
  const { user } = useAuth();
  const [adminList, setAdminList] = useState([
    "Home",
    "Archive",
    "Client Campaigns",
    "Influencer Network",
    "Contact Center",
    "Reconciliation Center",
    // "Media",
    "Admin",
  ]);

  const treeItemClass = {
    root: treeClasses.root,
    content: treeClasses.content,
    expanded: treeClasses.expanded,
    selected: treeClasses.selected,
    group: treeClasses.group,
    label: treeClasses.label,
    iconContainer: treeClasses.iconContainer,
  };

  const handleButtonClick = (value) => {
    if (value !== null) {
      var arr = value.split("/");
      var path = "";
      switch (value) {
        case "Home":
          path = `/`;
          break;
        case "Archive":
          path = `/Archive`;
          break;
        case "Admin":
          path = `/Admin/Home`;
          break;
        case "Contact Center":
          path = `/ContactCenter`;
          break;
        case "Influencer Network":
          path = `/Influencers`;
          break;
        case "Client Campaigns":
          path = `/v2/clients`;
          break;
        case "Reconciliation Center":
          path = `/Finance/ReconCentre`;
          break;
        case "Contracts":
          path = `/Finance/Contracts`;
          break;
        case "Media":
          path = `/Media/Home`;
          break;
        case "Contracts/Releases":
          var endPath = arr.join("-");
          path = `/Admin/${endPath}`;
          break;
        default:
          path = `/Admin/${arr[0]}`;
      }
      history.push(`${path}`);
      props.closeDrawer();
    }
  };

  const getIcon = (item) => {
    switch (item) {
      case "Express Payment":
        return <OfflineBolt />;
      case "Catering":
        return <Restaurant />;
      case "Contracts/Releases":
        return <Description />;
      case "Surveys":
        return <Feedback />;
      case "Email":
        return <Mail />;
      case "SMS":
        return <Sms />;
      case "Contracts":
        return <ContactMailIcon />;
      case "Health & Safety":
        return <LocalHospital />;
      case "Schedule Email":
        return <Mail />;
      case "Users":
        return <People />;
      case "Reimbursement":
        return <CreditCardIcon />;
      case "Home":
        return <HomeIcon />;
      case "Archive":
        return <ArchiveIcon />;
      case "Reconciliation Center":
        return <AttachMoney />;
      case "Admin":
        return <SupervisorAccountIcon />;
      case "Budget Central":
        return <AccountBalanceIcon />;
      case "Contact Center":
        return <PersonIcon />;
      case "Client Campaigns":
        return <AccountTreeIcon />;
      case "Influencer Network":
        return <EmojiPeopleIcon />;
      case "Media":
        return <CameraIcon />;
      default:
        break;
    }
  };

  const getInnerTreeItem = (nodeId, label) => {
    return (
      <TreeItem
        nodeId={nodeId}
        label={label}
        classes={{
          root: treeClasses.root,
          content: treeClasses.content,
          expanded: treeClasses.expanded,
          selected: treeClasses.selected,
          group: treeClasses.group,
          label: treeClasses.label,
        }}
      />
    );
  };

  const getTreeItem = (item) => {
    switch (item) {
      case "Admin":
        return (
          <TreeItem
            nodeId={`${item}`}
            style={(user.role === "Admin" || user?.role === "Super User") ? {} : { display: "none" }}
            onClick={() => handleButtonClick(item)}
            label={
              <ListItem key={item} style={{ color: "#747474" }}>
                <ListItemIcon style={{ color: "#747474" }}>
                  {getIcon(item)}
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItem>
            }
            classes={treeItemClass}
          ></TreeItem>
        );
      case "Reconciliation Center":
        return (
          <TreeItem
            nodeId={`${item}`}
            onClick={() => handleButtonClick(item)}
            label={
              <ListItem key={item} style={{ color: "#747474" }}>
                <ListItemIcon style={{ color: "#747474" }}>
                  {getIcon(item)}
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItem>
            }
            classes={treeItemClass}
          ></TreeItem>
        );
      default:
        return (
          <TreeItem
            nodeId={`${item}`}
            label={
              <ListItem
                key={item}
                onClick={() => handleButtonClick(item)}
                style={{ color: "#747474" }}
              >
                <ListItemIcon style={{ color: "#747474" }}>
                  {getIcon(item)}
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItem>
            }
            classes={treeItemClass}
          />
        );
    }
  };

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
      className="navigation-menu-panel-root"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div>
          <Toolbar className={"navigation-drawer-header"}>
            <Typography style={{ fontWeight: 700 }}>
              {user?.firstName
                ? `Welcome back, ${user?.firstName}!`
                : "Welcome Back!"}
            </Typography>
          </Toolbar>
          <Divider style={{ backgroundColor: "#747474" }} />
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            {adminList.map((item) => {
              return getTreeItem(item);
            })}
          </TreeView>
        </div>
        <div className={"navigation-menu-footer-container"} >
          <Typography variant="caption">powered by</Typography>
          <ThompsonSpencerLogoNoBg />
        </div>
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  redirectTo: state.adminMenu.redirectTo,
  snackbar: state.snackbar,
});

export default connect(mapStateToProps, {
  setRedirectTo,
})(AdminPanel);
