import React, { useState, useEffect } from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MediaAdsTable from "./MediaAdsTable";
import Snacky from "../../Shared/Snacky";
import _ from "lodash";
import "../Admin.css";
import {
    setSnackBarProps
} from "../../../actions/snackbar";
import { format } from "date-fns";
import {
    CircularProgress,
    Grid,
    Button,
    TextField,
    IconButton,
    Tooltip
} from "@material-ui/core";
import {
    CloudDownloadOutlined as CloudDownloadOutlinedIcon
} from '@material-ui/icons';
import SetMediaAdPersonModal from "./SetMediaAdPersonModal";
import ConfirmationDialog from "../../Shared/ConfirmationDialog";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch",
        },
    },
}));

const MediaAdsDashboard = (props) => {
    const classes = useStyles();
    let {
        snackbar,
        setSnackBarProps,
    } = props;
    const [mediaAds, setMediaAds] = useState(null);//null
    const [mediaAdsLoaded, setMediaAdsLoaded] = useState(false);
    const [initialFilters, setInitialFilters] = useState({
        status: ["Approved", "Rejected"],
    });
    const [filterClicked, setFilterClicked] = useState(false);

    const [currentUser, setCurrentUser] = useState(null);
    const [openSetMediaAdPersonModal, setOpenSetMediaAdPersonModal] = useState(false);
    const [getMediaAdPerson, setGetMediaAdPerson] = useState(true);
    const [duplicates, setDuplicates] = useState([]);
    const [totalDueOnScreen, setTotalDueOnScreen] = useState(0.0);
    const [checkedMediaAds, setCheckedMediaAds] = useState([]);

    const [downloadingInvoices, setDownloadingInvoices] = useState(false)
    const [defaultSearchValue, setDefaultSearchValue] = useState("")
    const [openFailedDownloadDiaog, setOpenFailedDownloadDiaog] = useState(false);

    //-------adList Updates----------

    const sendToXero = (mediaAd) => {
        Axios.patch(`MediaAdDocuments/SendAdDocToXero/${mediaAd.id}`, mediaAd)
            .then(res => {
                setSnackBarProps("success", `${mediaAd.client} sent to Xero for Reconciliation`, true)
            })
            .catch(e => {
                setSnackBarProps("warning", `${mediaAd.client} failed to send to Xero`, true)
            })
    }

    const createAndAssignSupplier = (mediaAd, newSupplier) => {
        Axios.post(`MediaSuppliers/CreateAndAssignSupplier`, {
            supplier: newSupplier,
            mediaAdDoc: mediaAd
        })
            .then(res => {
                setSnackBarProps("success", `Created and assigned`, true)
            })
            .catch(e => {
                setSnackBarProps("warning", "Oops! something went wrong. Please try again.", true)
            })
    }

    const rejectAd = (mediaAd) => {
        Axios.patch(`MediaAdDocuments/RejectDocument/${mediaAd.id}`, mediaAd)
            .then(res => {
                setSnackBarProps("success", `${mediaAd.client} ${mediaAd.type} Rejected`, true)
            })
            .catch(e => {
                setSnackBarProps("warning", "Oops! something went wrong. Please try again.", true)
            })
    }

    const updateMediaAd = (key, value, mediaAd, newSupplier = null) => {
        setMediaAds(
            mediaAds.map((ad) => {
                if (ad.id === mediaAd.id) {
                    if (newSupplier == null) {
                        if (key === "status" && value === "Approved") {
                            sendToXero(mediaAd)
                        }
                        else if (key === "status" && value === "Rejected") {
                            rejectAd(mediaAd)
                        }
                    }
                    else {
                        createAndAssignSupplier(mediaAd, newSupplier);
                    }
                }
                if (newSupplier != null) {
                    return (
                        (ad.parseType === mediaAd.parseType) && ad.platform === "No Supplier Found" ? {
                            ...ad,
                            platform: newSupplier.xeroName,
                            gstRateCode: newSupplier.xeroGstRateCode,
                            supplierBankAcc: newSupplier.xeroBankAccount
                        }
                            : ad
                    )
                }
                else {
                    return (
                        ad.id === mediaAd.id ? {
                            ...ad,
                            [key]: value,
                        }
                            : ad
                    )
                }
            })
        );
    }

    const updateMultipleValuesMediaAd = (mediaAd) => {
        setMediaAds(mediaAds.map((m) => {
            if (m.id === mediaAd.id) {
                Axios.patch(`MediaAdDocuments/UpdateMediaAd/${mediaAd.id}`, mediaAd)
                    .then(res => { console.log(res) })
                    .catch(e => { console.log(e) })
            }
            return (
                m.id === mediaAd.id ? {
                    ...m,
                    ["client"]: mediaAd.client,
                    ["platform"]: mediaAd.platform,
                    ["transactionId"]: mediaAd.transactionId,
                    ["transactionDate"]: mediaAd.transactionDate,
                    ["amount"]: mediaAd.amount,
                    ["type"]: mediaAd.type,
                    ["gstRateCode"]: mediaAd.gstRateCode,
                } : m
            )
        }));
    };

    //----------end of adList Updates---------
    //----------checked ads------------
    const addCheckedAd = (id) => {
        var newArr = checkedMediaAds;
        if (newArr.indexOf(id) === -1) {
            setCheckedMediaAds(prevArray => [...prevArray, id]);

        }
    }

    const removeCheckedAd = (id) => {
        var newArr = checkedMediaAds;
        newArr = newArr.filter(e => e !== id);
        setCheckedMediaAds(newArr);
    }

    const multipleSendToXero = () => {
        Axios.patch(`MediaAdDocuments/SendMulltipleAdDocToXero`, {
            idArray: checkedMediaAds
        })
            .then(res => {
                setSnackBarProps("success", `${checkedMediaAds.length} sent to Xero for Reconciliation`, true)
            })
            .catch(e => {
                setSnackBarProps("warning", `${checkedMediaAds.length} failed to send to Xero`, true)
            })
    }

    const multipleReject = () => {
        Axios.patch(`MediaAdDocuments/RejectMultipleDocument`, {
            idArray: checkedMediaAds
        })
            .then(res => {
                setSnackBarProps("success", `${checkedMediaAds.length} sent to Xero for Reconciliation`, true)
            })
            .catch(e => {
                setSnackBarProps("warning", `${checkedMediaAds.length} failed to send to Xero`, true)
            })
    }

    //----------end of checked ads-----------

    const getFilterButtons = () => {
        return (
            <div style={{
                display: "flex",
                justifyContent: "space-around"
            }}>
                <Button color="primary" size="small" variant="contained" style={{ backgroundColor: "#3d4977" }}
                    onClick={() => {
                        setFilterClicked(true);
                        setInitialFilters({});
                    }}
                >
                    Show All
                </Button>
                <Button color="primary" size="small" variant="contained" style={{ backgroundColor: "#3d4977" }}
                    onClick={() => {
                        setFilterClicked(true);
                        setInitialFilters({
                            status: ["Rejected", "Approved"]
                        });
                        setDefaultSearchValue("")
                    }}
                >
                    Show Pending
                </Button>
                <Button color="primary" size="small" variant="contained" style={{ backgroundColor: "#3d4977" }}
                    onClick={() => {
                        setFilterClicked(true);
                        setInitialFilters({
                            status: ["Rejected", "Pending"]
                        });
                        setDefaultSearchValue("")
                    }}
                >
                    Show Approved
                </Button>
                <Button color="primary" size="small" variant="contained" style={{ backgroundColor: "#3d4977" }}
                    onClick={() => {
                        setFilterClicked(true);
                        setInitialFilters({
                            status: ["Approved", "Pending"]
                        });
                        setDefaultSearchValue("")
                    }}
                >
                    Show Rejected
                </Button>
            </div>

        )
    }

    const setTotal = (x) => {
        setTotalDueOnScreen(parseFloat(x));
    };

    const addCommasToFloat = (float) => {
        return float.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    };

    const downloadInvoices = () => {
        setDownloadingInvoices(true);
        Axios.post(`MediaAdDocuments/DownloadInvoices`, checkedMediaAds, {
            params: {
                cacheBustTimestamp: Date.now(), // prevents IE cache problems on re-download
            },
            responseType: "blob",
            headers: {
                Accept: "application/octet-stream",
            },
        })
            .then(res => {
                console.log(res)
                const blob = new Blob([res.data], { type: "application/pdf" });

                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `Invoice_${format(new Date(), "dd-MM-yyyy")}.pdf`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(e => {
                console.log(e);
                setOpenFailedDownloadDiaog(true);
            })
            .finally(() => {
                setDownloadingInvoices(false);
            })
    }

    useEffect(() => {
        if (getMediaAdPerson) {
            setGetMediaAdPerson(false);
            Axios.get("MediaAdDocuments/GetMediaAdsManager")
                .then((res) => {
                    setCurrentUser(res.data);
                })
                .catch((err) => console.log(err));
        }
    }, [getMediaAdPerson]);

    useEffect(() => {
        if (!mediaAdsLoaded) {
            setMediaAdsLoaded(true);
            Axios.get(`MediaAdDocuments/GetMediaAdDocumentsForAdmin`)
                .then(res => {
                    console.log(res.data.mediaAdDocuments)
                    var arr = res.data.mediaAdDocuments;
                    var newAmount = 0.0;

                    setDuplicates(res.data.duplicates)
                    for (var i = 0; i < arr.length; i++) {
                        arr[i].transactionDate = arr[i].transactionDate !== null ? new Date(arr[i].transactionDate) : null;
                        if (arr[i].amount !== null) {
                            newAmount = newAmount + parseFloat(arr[i].amount);
                        }
                    }
                    setMediaAds(res.data.mediaAdDocuments);
                    setTotalDueOnScreen(newAmount);
                    setFilterClicked(true);
                })
                .catch(e => {
                    console.log(e)
                    setMediaAdsLoaded(false);
                })
        }
    }, [mediaAdsLoaded]);

    useEffect(() => {
        if (filterClicked) {
            setFilterClicked(false)
            setCheckedMediaAds([])
        }
    }, [filterClicked])

    return (
        <main className={classes.content}>
            <div className="main-content">
                <Snacky snackprops={snackbar} setSnackBarProps={setSnackBarProps} />
                {openSetMediaAdPersonModal ? (
                    <SetMediaAdPersonModal
                        modalState={true}
                        handleCloseModal={() => setOpenSetMediaAdPersonModal(false)}
                        setGetMediaAdPerson={setGetMediaAdPerson}
                        changeCurrentUser={(user) => setCurrentUser(user)}
                    />
                ) : (
                    <React.Fragment></React.Fragment>
                )}
                {openFailedDownloadDiaog ? (
                    <ConfirmationDialog
                        show={openFailedDownloadDiaog}
                        titleText="Download has failed"
                        contentText="Please try again"
                        handleClose={() => setOpenFailedDownloadDiaog(false)}
                        buttonText="Ok"
                    />
                ) : <React.Fragment></React.Fragment>}
                <Grid container>
                    <Grid item xs={4} md={4} lg={4}>
                        <h1>{"Media Receipts & Invoices"}</h1>
                    </Grid>
                    <Grid item xs={4} md={4} lg={4}>
                        {currentUser !== null ? (
                            <div style={{ marginBottom: 5 }}>
                                <TextField
                                    variant="outlined"
                                    label={"Reimbursement Manager"}
                                    value={`${currentUser.firstName} ${currentUser.lastName}`}
                                    size="small"
                                    disabled
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ marginLeft: "15px" }}
                                    onClick={() => setOpenSetMediaAdPersonModal(true)}
                                >
                                    Change Manager
                                </Button>
                            </div>
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                    </Grid>
                    <Grid item xs={4} md={4} lg={4}>
                        {getFilterButtons()}
                    </Grid>
                    <Grid item xs={3} md={3} lg={3}>
                        {checkedMediaAds.length > 0 && (
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                {downloadingInvoices ? (
                                    <CircularProgress />
                                ) : (
                                    <Tooltip title="Download selected invoices" aria-label="download" arrow>
                                        <IconButton
                                            style={{ cursor: "pointer" }}
                                            fontSize="large"
                                            color="primary"
                                            variant="contained"
                                            onClick={() => downloadInvoices()}
                                        >
                                            <CloudDownloadOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                        {totalDueOnScreen !== null || totalDueOnScreen !== undefined ? (
                            <React.Fragment>
                                <p className="totalAmountDue" style={{ fontWeight: "bold", textAlign: "center" }}>
                                    {`Total:  $${addCommasToFloat(totalDueOnScreen.toFixed(2))}`}
                                </p>
                            </React.Fragment>
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                    </Grid>
                    <Grid item xs={3} md={3} lg={3}>

                    </Grid>
                </Grid>
                {(mediaAds !== null && mediaAds !== undefined && !filterClicked) &&
                    <MediaAdsTable
                        adList={mediaAds}
                        duplicates={duplicates}
                        initialFilters={initialFilters}
                        setInitialFilters={setInitialFilters}
                        setSnackBarProps={setSnackBarProps}
                        updateMediaAd={updateMediaAd}
                        updateMultipleValuesMediaAd={updateMultipleValuesMediaAd}
                        setTotalDueOnScreen={(x) => setTotal(x)}
                        addCheckedAd={addCheckedAd}
                        removeCheckedAd={removeCheckedAd}
                        checkedMediaAds={checkedMediaAds}
                        setCheckedMediaAds={setCheckedMediaAds}
                        multipleSendToXero={multipleSendToXero}
                        multipleReject={multipleReject}
                        defaultSearchValue={defaultSearchValue}
                        handleSearchAllMediaAds={(searchValue) => {
                            setDefaultSearchValue(searchValue);
                            setInitialFilters({});
                            setFilterClicked(true);
                        }}
                    />
                }
                {(mediaAds === null || mediaAds === undefined && filterClicked) &&
                    <CircularProgress />
                }
            </div>
        </main >
    )
}

const mapStateToProps = (state, ownProps) => ({
    snackbar: state.snackbar,
});
export default connect(mapStateToProps, {
    setSnackBarProps,
})(MediaAdsDashboard);