import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { makeStyles, styled, withStyles } from "@material-ui/core/styles";
import {
  Modal,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  FormControl,
  Grid,
  TextField,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import { Close, Delete, AddCircle } from "@material-ui/icons";
import "./preimburse.scss";
import axios from "axios";
import SharedSimpleAlerts from "./SharedSimpleAlerts";
import InformationDialogy from "./InformationDialogy";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useUserContext } from "../../context/user/UserContext";
import { useAuth } from "../../context/auth/AuthContext";

//---------Styling -----------------
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    height: "550px",
    width: "1000px",
    overflowY: "auto",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  container: {
    maxHeight: 350,
  },
}));

const PreimbursePopUp = (props) => {
  const classes = useStyles();
  const { user } = useAuth();
  const [projects, setprojects] = useState([]);
  const [selectedProject, setselectedProject] = useState();
  const [person, setPerson] = useState({
    id: 0,
    firstName: "",
    lastName: "",
  });
  const [preimbursement, setpreimbursement] = useState({
    type: "",
    account: null,
    total: 0,
    shoot: null,
  });
  const {
    handlePreimburseModal,
    preimburseModal,
    personId,
    handleSnacky,
    userId,
  } = props;

  useEffect(() => {
    const getProjects = axios.get("/Projects/GetProjects");
    const getPerson = axios.get(`/People/GetPersonOnly/${personId}`);
    axios
      .all([getProjects, getPerson])
      .then(
        axios.spread((...res) => {
          const projects = res[0].data;
          const person = res[1].data;
          setprojects(projects);
          setPerson(person);
        })
      )
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [shoots, setshoots] = useState();
  const [loadingShoots, setloadingShoots] = useState(false);
  const [accounts, setaccounts] = useState();

  useEffect(() => {
    if (selectedProject) {
      setloadingShoots(true);
      const getSchedules = axios.get(
        `/Schedules/GetSchedules/${selectedProject.id}`
      );
      const getBudget = axios.get(
        `/BudgetItems/GetBudgetForProject/${selectedProject.id}`
      );
      axios
        .all([getSchedules, getBudget])
        .then(
          axios.spread((...res) => {
            const shoots = res[0].data;
            const budget = res[1].data;
            setloadingShoots(false);
            setshoots(shoots);
            setaccounts(budget);
          })
        )
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedProject]);

  const handleItems = (field, value) => {
    setpreimbursement({
      ...preimbursement,
      [field]: value,
    });
  };

  const selectProject = (obj, value) => {
    if (value) {
      setselectedProject({
        id: value.id,
        projectName: value.projectName,
        projectTag: value.projectTag,
      });
    } else {
      setselectedProject();
    }
  };

  const selectShoot = (obj, value) => {
    if (value) {
      setpreimbursement({
        ...preimbursement,
        shoot: value,
      });
    } else {
      setpreimbursement({
        ...preimbursement,
        shoot: null,
      });
    }
  };

  const selectAccount = (obj, value) => {
    if (value) {
      setpreimbursement({
        ...preimbursement,
        account: value,
      });
    } else {
      setpreimbursement({
        ...preimbursement,
        account: null,
      });
    }
  };

  const closePopup = () => {
    setpreimbursement({
      type: "",
      account: null,
      total: 0,
      projectId: 0,
      shoot: null,
    });
    setselectedProject();
    handlePreimburseModal(false);
  };

  const clearFields = () => {
    setpreimbursement({
      type: "",
      account: "",
      total: 0,
      shoot: {},
    });
    setselectedProject();
  };

  const [showInformationDialogy, setShowInformationDialogy] = useState(false);
  const [loading, setloading] = useState(false);

  const sendForPayment = () => {
    // let _items = [...items];
    let checker = false;
    if (
      !preimbursement.total ||
      !preimbursement.account ||
      !preimbursement.type ||
      !preimbursement.shoot
    ) {
      setShowInformationDialogy(true);
      checker = true;
    }

    if (!checker) {
      setloading(true);
      axios
        .post("/Receipts/PostPreimburse", {
          firstName: person.firstName,
          lastName: person.lastName,
          email: person.emailAddress,
          bankAccount: person.bankAccount,
          contractorId: person.id,
          supplier: "Float",
          driveId: "",
          billStatus: "Pending",
          gst: 1,
          camistryCard: 0,
          type: `Preimbursement - ${preimbursement.type}`,
          accountCode: preimbursement.account.costAccount,
          total: preimbursement.total,
          projectId: selectedProject.id,
          scheduleId: preimbursement.shoot.id,
          shootDate: preimbursement.shoot.date,
          personId: user.id,
        })
        .then((res) => {
          setloading(false);
          console.log(res);
          handleSnacky(true, "Success");
          handlePreimburseModal(false);
          clearFields();
        })
        .catch((err) => {
          setloading(false);
          console.log(err);
          handleSnacky(true, "Failed");
        });
    }
  };

  return (
    <Modal
      aria-labelledby="preimburse-modal"
      aria-describedby="preimburse-modal"
      open={preimburseModal}
      // onClose={props.handleModal}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      className={"contractor-pop-up-modal"}
    >
      <div>
        <InformationDialogy
          buttonProps={{
            show: showInformationDialogy,
            titleText: "Missing fields!",
            contentText:
              "Please complete all fields before submiting pre-imbursement",
            buttonColor: "primary",
            buttonText: "Ok",
          }}
          handleButton={() => setShowInformationDialogy(false)}
        />
        <AppBar position="static" style={{ background: "#217F8B" }}>
          <Toolbar variant="dense" style={{ paddingRight: 0 }}>
            <Typography
              variant="subtitle1"
              align="center"
              style={{ flexGrow: 1 }}
            >
              {`Preimbursement for ${person.firstName} ${person.lastName}`}
            </Typography>
            <span style={{ display: "flex" }}>
              <IconButton
                onClick={closePopup}
                color="inherit"
                style={{ color: "black" }}
              >
                <Close />
              </IconButton>
            </span>
          </Toolbar>
        </AppBar>
        <div className={classes.paper}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "5px",
              width: "100%",
            }}
          >
            <FormControl
              component="fieldset"
              className={classes.formControl}
              style={{
                width: "100%",
                marginBottom: "30px",
              }}
            >
              <div style={{ display: "flex" }}>
                <Autocomplete
                  id="preimburse-projects"
                  options={projects}
                  selectOnFocus
                  fullWidth={true}
                  autoHighlight={true}
                  autoSelect={true}
                  clearOnBlur
                  onChange={selectProject}
                  size="small"
                  getOptionLabel={(option) =>
                    `${option.projectTag} - ${option.projectName}`
                  }
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Project" variant="outlined" />
                  )}
                />
                {!selectedProject && (
                  <SharedSimpleAlerts
                    simpleAlertsText="Please select a project"
                    height="40px"
                  />
                )}
              </div>
              {loadingShoots && <CircularProgress />}
              {selectedProject && shoots && (
                <div className="preimburse-div">
                  <div style={{ display: "flex", marginBottom: "15px" }}>
                    <Autocomplete
                      id="preimburse-shoots"
                      options={shoots}
                      selectOnFocus
                      fullWidth={true}
                      autoHighlight={true}
                      autoSelect={true}
                      clearOnBlur
                      onChange={selectShoot}
                      size="small"
                      getOptionLabel={(option) => option.name}
                      style={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Shoot"
                          variant="outlined"
                        />
                      )}
                    />
                    {!preimbursement.shoot && (
                      <SharedSimpleAlerts
                        simpleAlertsText="Please select a shoot"
                        height="40px"
                      />
                    )}
                  </div>
                  <TableContainer
                    component={Paper}
                    className={classes.container}
                  >
                    <Table stickyHeader aria-label="simple table" size="small">
                      <TableHead className="preimburse-table-head">
                        <TableRow>
                          <TableCell className="preimburse-table-head-cell">
                            Item
                          </TableCell>
                          <TableCell className="preimburse-table-head-cell">
                            Account
                          </TableCell>
                          <TableCell className="preimburse-table-head-cell">
                            Remaining
                          </TableCell>
                          <TableCell className="preimburse-table-head-cell">
                            Amount
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <TextField
                              id="item-name"
                              label="Item"
                              variant="outlined"
                              value={preimbursement.type}
                              autoFocus={true}
                              size="small"
                              onChange={(e) =>
                                handleItems("type", e.target.value)
                              }
                              // onBlur={() => handleBlur(index)}
                            />
                          </TableCell>
                          <TableCell>
                            <Autocomplete
                              id="preimburse-accounts"
                              selectOnFocus
                              fullWidth={true}
                              autoHighlight={true}
                              autoSelect={true}
                              clearOnBlur
                              size="small"
                              options={accounts}
                              // value={item.Account}
                              onChange={selectAccount}
                              // onBlur={() => handleBlur(index)}
                              getOptionLabel={(option) => option.category}
                              style={{ width: 300 }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Account"
                                  variant="outlined"
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              id="item-remaining"
                              disabled={true}
                              label="Remaining"
                              variant="outlined"
                              value={
                                preimbursement.account
                                  ? preimbursement.account.totalAmount
                                    ? `$${
                                        Number(
                                          preimbursement.account.totalAmount
                                        ) -
                                        Number(preimbursement.account.quoted)
                                      }`
                                    : 0
                                  : 0
                              }
                              InputProps={
                                preimbursement.account
                                  ? Number(preimbursement.account.totalAmount) -
                                      Number(preimbursement.account.quoted) <=
                                    0
                                    ? { style: { color: "red" } }
                                    : { style: { color: "green" } }
                                  : ""
                              }
                              size="small"
                              // onBlur={() => handleBlur(index)}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              id="item-amount"
                              label="Amount"
                              variant="outlined"
                              value={preimbursement.total}
                              size="small"
                              onChange={(e) =>
                                handleItems("total", e.target.value)
                              }
                              type="number"
                              // onBlur={() => handleBlur(index)}
                            />
                          </TableCell>
                          {/* <TableCell>
                            {items.length > 1 && (
                              <IconButton
                                aria-label="delete"
                                onClick={() => handleDelete()}
                              >
                                <Delete />
                              </IconButton>
                            )}
                          </TableCell> */}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* <div className="preimburse-add-button">
                    <IconButton
                      onClick={addNewItemRow}
                      color="primary"
                      size="medium"
                      edge="start"
                    >
                      <AddCircle className="preimburse-add-button-icon" />
                    </IconButton>
                  </div> */}
                  {/* <div className="preimburse-items-count">
                    <Typography>{getQuantityTotal()}</Typography>
                  </div> */}
                  <div>
                    <Typography color="secondary">
                      * Preimbursements will take up to 24 hours to arrive
                    </Typography>
                  </div>
                  <div className="preimburse-sendforpayment">
                    {!loading ? (
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        onClick={sendForPayment}
                      >
                        Send for payment
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        disabled={true}
                      >
                        Send for payment
                        <span>
                          <CircularProgress color="primary" size={25} />
                        </span>
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </FormControl>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state, ownProps) => ({
});
export default connect(mapStateToProps, {})(PreimbursePopUp);
