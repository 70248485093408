import { Typography } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import {
  calculateTimeSpentByDate,
  minutesToHHMM,
} from "../../../utils/TimeTrackerUtils";
import TimeTrackerCaptionLabel from "../TimeTrackerCaptionLabel";

const TimeTrackerAllocationFooter = ({
  datesToDisplay,
  timeEntriesByProjects,
}) => {
  const [totalTimeSpentByDate, setTotalTimeSpentByDate] = useState({});

  useEffect(() => {
    // Calculate the total time spent by date whenever timeEntriesByProjects changes
    const calculatedTotalTimeSpent = calculateTimeSpentByDate(
      timeEntriesByProjects
    );
    setTotalTimeSpentByDate(calculatedTotalTimeSpent);
  }, [timeEntriesByProjects]);
  return (
    <div className="time-tracker-hour-allocation-row no-hover-state">
      <div className="time-tracker-project-name-container">
        <Typography></Typography>
      </div>
      <div className="time-tracker-hour-allocation-column-container time-tracker-allocation-container">
        {datesToDisplay.map((date, index) => {
          const formattedDate = date.format("YYYY-MM-DD"); // Format the date for comparison
          const totalMinutes = totalTimeSpentByDate[formattedDate] || 0; // Get the total time spent or default to 0

          return (
            <TimeTrackerCaptionLabel
              key={index}
              value={minutesToHHMM(totalMinutes)}
            />
          );
        })}
      </div>
      <div style={{ minWidth: 50 }}></div>
    </div>
  );
};

export default TimeTrackerAllocationFooter;
