import { Typography } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import React from "react";
import { timeAgo } from "../../../utils/TimeHelpers";

const PersonNotesTable = ({ notes }) => {
  return (
    <div className="person-notes-table-root">
      {notes?.length > 0 ? (
        notes.map((noteObj, index) => (
          <div className="person-notes-table-record-container">
            <div className="person-notes-table-record-heading">
              <Typography variant="caption">{noteObj.noteType}</Typography>
              <Typography variant="caption">
                {timeAgo(noteObj.dateAdded)}
              </Typography>
            </div>
            <div>
              <Typography variant="caption">{noteObj.note}</Typography>
              <Typography variant="caption">{" - "}</Typography>
              <Typography variant="caption">{noteObj.user}</Typography>
            </div>
          </div>
        ))
      ) : (
        <div className="person-notes-table-no-records">
          <Typography variant="body2">No Notes</Typography>
        </div>
      )}
    </div>
  );
};

export default PersonNotesTable;
