import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { isEmpty } from "../../../utils/GeneralUtils";
import PullFromBudgetAutocomplete from "../../Shared/BudgetItem/PullFromBudgetAutocomplete";
const MediaCampaignFormDialog = ({
  open,
  handleClose,
  selectedMediaCampaign,
  setSelectedMediaCampaign,
  mediaSuppliers,
  isSavingCampaign,
  handleSubmit,
  mediaCampaigns,
  budgetItems,
}) => {
  const initialErrorState = {
    campaign: "",
    platform: "",
    budget: "",
  };

  const [uniqueCampaigns, setUniqueCampaigns] = useState([]);
  const [errorForm, setErrorForm] = useState(initialErrorState);
  const [initialBudgetId, setInitialBudgetId] = useState(
    selectedMediaCampaign.budgetId
  );

  const handleChange = (field, value) => {
    if (field === "mediaSupplierId") {
      setSelectedMediaCampaign({
        ...selectedMediaCampaign,
        [field]: value?.id,
        mediaSupplier: value,
      });
    } else if (field === "budgetId") {
      setSelectedMediaCampaign({
        ...selectedMediaCampaign,
        [field]: value?.budgetId,
      });
    } else {
      setSelectedMediaCampaign({ ...selectedMediaCampaign, [field]: value });
    }
  };

  const validateForm = () => {
    let isValidForm = true;
    const tempError = { ...initialErrorState };

    const selectedBudget = budgetItems.find(
      (bi) => bi.budgetId === selectedMediaCampaign.budgetId
    );
    if (!selectedMediaCampaign.budgetId) {
      tempError.budget = "Please select a budget";
      isValidForm = false;
    } else if (
      initialBudgetId !== selectedMediaCampaign.budgetId &&
      Number(
        selectedMediaCampaign.mediaSpend
          ?.filter((ms) => ms.budgetId !== selectedMediaCampaign.budgetId)
          .reduce((a, b) => a + (b.total || 0), 0)
      ) > selectedBudget.remaining
    ) {
      tempError.budget = "Not enough budget remaining";
      isValidForm = false;
    }
    if (isEmpty(selectedMediaCampaign?.name)) {
      tempError.campaign = "Please type in the campaign";
      isValidForm = false;
    }
    if (
      isEmpty(
        mediaSuppliers.find(
          (ms) => ms.id === selectedMediaCampaign.mediaSupplierId
        )
      )
    ) {
      tempError.platform = "Please select a platform";
      isValidForm = false;
    }

    if (!isValidForm) {
      setErrorForm(tempError);
    }

    return isValidForm;
  };

  const handleSave = () => {
    if (validateForm()) {
      handleSubmit();
    }
  };

  useEffect(() => {
    var uniqueCampaignNames = [];
    for (var i = 0; i < mediaCampaigns.length; i++) {
      if (!uniqueCampaignNames.includes(mediaCampaigns[i].name)) {
        uniqueCampaignNames.push(mediaCampaigns[i].name);
      }
    }
    setUniqueCampaigns(uniqueCampaignNames);
  }, [mediaCampaigns]);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth id="add-budget-dialog">
      <div className="close-and-transfer-dialog-close-icon-container">
        <IconButton
          size="small"
          className="close-and-transfer-dialog-close-icon"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <DialogTitle style={{ display: "grid" }}>
        <Typography variant="h5" className="roboto-bold">{`${
          selectedMediaCampaign?.id ? "Edit" : "Add New"
        } Media Campaign`}</Typography>
        <DialogContentText style={{ textAlign: "left" }}>
          <Typography className="roboto-regular add-budget-description">
            Enter the Campaign Details.
          </Typography>
        </DialogContentText>
      </DialogTitle>

      <DialogContent>
        <div style={{ display: "grid", gap: "20px" }}>
          <PullFromBudgetAutocomplete
            options={budgetItems.filter((b) => b.isHidden !== 1)}
            handleOnChange={(event, newValue) => {
              handleChange("budgetId", newValue || null);
            }}
            value={budgetItems.find(
              (bi) => bi.budgetId === selectedMediaCampaign.budgetId
            )}
            classes={"media-spend-form-container-pull-from-input"}
            variant="filled"
            errorMessage={
              selectedMediaCampaign.hasSentInvoices &&
              selectedMediaCampaign.budget
                ? "Field locked: invoices have been sent."
                : errorForm.budget
            }
            isReadOnly={
              !!selectedMediaCampaign.hasSentInvoices &&
              !!selectedMediaCampaign.budget
            }
          />
          <Autocomplete
            label="Campaign"
            fullWidth
            freeSolo
            options={uniqueCampaigns}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Campaign"}
                fullWidth={true}
                variant="filled"
                value={selectedMediaCampaign.name}
                onChange={(e) => handleChange("name", e.target.value)}
                error={errorForm.campaign ? true : false}
                helperText={errorForm.campaign ? errorForm.campaign : ""}
              />
            )}
            defaultValue={selectedMediaCampaign.name}
            onChange={(event, option) => {
              handleChange("name", option);
            }}
            size="small"
          />
          <Autocomplete
            value={mediaSuppliers.find(
              (ms) => ms.id === selectedMediaCampaign.mediaSupplierId
            )}
            options={mediaSuppliers}
            onChange={(event, option) => {
              handleChange("mediaSupplierId", option);
            }}
            getOptionLabel={(option) => option.supplierName}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Platform"}
                fullWidth={true}
                variant="filled"
                error={errorForm.platform ? true : false}
                helperText={errorForm.platform ? errorForm.platform : ""}
              />
            )}
            disableClearable
            fullWidth
            size="small"
          />
          <TextField
            variant="filled"
            label="PO Number"
            fullWidth
            value={selectedMediaCampaign.poNumber}
            onChange={(e) => handleChange("poNumber", e.target.value)}
            size="small"
          />
        </div>
      </DialogContent>
      <DialogActions>
        {isSavingCampaign ? (
          <div style={{ padding: 10 }}>
            <CircularProgress />
          </div>
        ) : (
          <div className="add-budget-popup-action-container">
            <Button
              style={{ marginRight: 5 }}
              className="default-button-grey add-budget-button"
              onClick={(e) => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ marginLeft: 5 }}
              className="default-button add-budget-button"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MediaCampaignFormDialog;
