import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
} from "@material-ui/core";
import React from "react";
import MediaSuppliersTableRow from "./MediaSuppliersTableRow";

const MediaSuppliersTable = ({
  suppliers,
  handleChangeSupplier,
  archiveSuppplier,
}) => {
  return (
    <Table size="small" className="media-suppliers-table-root">
      <TableHead>
        <TableCell>
          <Typography>Supplier Name</Typography>
        </TableCell>
        <TableCell style={{ maxWidth: 50 }} align="center">
          <Typography>Rebate Provided</Typography>
        </TableCell>
        <TableCell>
          <Typography>Rebate Rate %</Typography>
        </TableCell>
        <TableCell style={{ maxWidth: 50 }} align="center">
          <Typography>Is GST Registered?</Typography>
        </TableCell>
        <TableCell align="center" style={{ width: 200 }}></TableCell>
        <TableCell align="right"></TableCell>
      </TableHead>
      <TableBody>
        {suppliers.map((supplier, index) => (
          <MediaSuppliersTableRow
            key={index}
            supplier={supplier}
            handleChangeSupplier={handleChangeSupplier}
            archiveSuppplier={archiveSuppplier}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default MediaSuppliersTable;
