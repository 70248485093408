import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  Button,
  DialogActions,
  DialogContent,
} from "@material-ui/core";

const NoOutputsDialog = ({open, handleClose,handleSubmit}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
        Archive Project
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          It looks like there are no more outputs to be delivered. Would you like to close the project?
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={handleClose}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="secondary"
          autoFocus
        >
          Yes, Close it!
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NoOutputsDialog