import { Button } from "@material-ui/core";
import React, { useEffect } from "react";

const ReconciliationActionButton = ({
  handleSubmit,
  allocatedAmount,
  payableAmount,
  recordType,
}) => {
  return (
    <div className="reconcile-action-button-root">
      <Button
        onClick={handleSubmit}
        className={
          (recordType !== "MEDIA" && (allocatedAmount === 0 ||
            payableAmount - allocatedAmount > 1 ||
              payableAmount - allocatedAmount < 0))
            ? "default-disabled-button"
            : "default-button"
        }
        disabled={
          
          (recordType !== "MEDIA" && (allocatedAmount === 0 ||
            payableAmount - allocatedAmount > 1 ||
              payableAmount - allocatedAmount < 0))
        }
        // className={payableAmount - allocatedAmount < 0 || allocatedAmount === 0 ? "default-disabled-button" : "default-button"}
        // disabled={payableAmount - allocatedAmount < 0 || allocatedAmount === 0}
      >
        {recordType === "MEDIA" ? "Save" : "Match and Close"}
        {/* {payableAmount - allocatedAmount > 1 && allocatedAmount !== 0? "Close as Overspend" : "Match and Close"} */}
      </Button>
    </div>
  );
};

export default ReconciliationActionButton;
