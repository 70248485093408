import React from "react";
import {
  Grid,
  InputAdornment,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";

const InfluencerFilters = ({ displayPOISwitch, keywordlookUp, setKeywordlookUp, searchInfluencers, onlyPOI, setOnlyPOI }) => {
  return (
    <Grid container>
      <Grid item xs={12} md={9} lg={9}>
        <div className={"default-text-field-container"}>
          <TextField
            fullWidth
            label="Search Influencers (Press Enter to Search)"
            onChange={(e) => setKeywordlookUp(e.target.value)}
            value={keywordlookUp}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                searchInfluencers();
              }
            }}
            variant="filled"
          />
        </div>
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        {displayPOISwitch && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FormGroup>
              <FormControlLabel
                label={<Typography variant="caption">Show POI influencers</Typography>}
                control={
                  <Switch
                    color="primary"
                    checked={onlyPOI}
                    name="poiFilter"
                    onChange={(e) => {
                      setOnlyPOI(e.target.checked)
                    }}
                  />
                }
              />
            </FormGroup>
          </div>

        )}
      </Grid>
    </Grid>
  );
};

export default InfluencerFilters;
