import React, { useEffect } from "react";
import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  Modal,
  AppBar,
  Toolbar,
  Typography,
  TextField,
  Tab,
  Tabs,
  Paper,
  Box,
  InputAdornment,
  Avatar,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import { useUserContext } from "../../../../../context/user/UserContext";
import { useAuth } from "../../../../../context/auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    height: "80vH",
    width: "80vW",
    overflow: "auto",
  },
  grid: {
    marginTop: "10px",
    marginBottom: "10px",
    marginLeft: "2px",
    marginLeft: "2px",
  },
  blackText: {
    color: "black !important",
  },
}));

function ContactDetailsPopUp(props) {
  const classes = useStyles();
  const { user } = useAuth();

  const [fetchedContact, setFetchedContact] = React.useState(false);
  const [updatingPerson, setUpdatingPerson] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);

  const [contactDetails, setContactDetails] = React.useState({
    firstName: " ",
    lastName: " ",
    fullName: " ",
    birthday: " ",
    phoneNumber: " ",
    emailAddress: " ",
    address: " ",
    personCategory: " ",
    jobTitle: " ",
    rateHour: " ",
    rateHalfDay: " ",
    rateDay: " ",
    rateWeek: " ",
    photoBlob: "",
    parentName: "",
    hasParent: 0,
  });
  const {
    firstName,
    lastName,
    fullName,
    birthday,
    phoneNumber,
    emailAddress,
    address,
    personCategory,
    jobTitle,
    rateHour,
    rateHalfDay,
    rateDay,
    rateWeek,
    photoBlob,
    parentName,
    hasParent,
  } = contactDetails;

  useEffect(() => {
    if (!fetchedContact) {
      Axios.get(`People/GetPerson/${props.person.id}`)
        .then((res) => {
          setFetchedContact(true);
          console.log(res);
          setContactDetails({
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            fullName: res.data.fullName,
            birthday: res.data.birthday,
            phoneNumber: res.data.phoneNumber,
            emailAddress: res.data.emailAddress,
            address: res.data.address,
            personCategory: res.data.personCategory,
            jobTitle: res.data.jobTitle,
            rateHour: res.data.rateHour,
            rateHalfDay: res.data.rateHalfDay,
            rateDay: res.data.rateDay,
            rateWeek: res.data.rateWeek,
            photoBlob: res.data.photoBlob,
            parentName: res.data.parentName,
            hasParent: res.data.hasParent,
          });
        })
        .catch((e) => {
          console.log(e);
          setFetchedContact(true);
        });
    }
  });

  useEffect(() => {});

  const handleContactDetailsChange = (detail) => (event) => {
    if (detail === "emailAddress") {
      setContactDetails({
        ...contactDetails,
        [detail]: event.target.value.trim(),
      });
    } else {
      setContactDetails({ ...contactDetails, [detail]: event.target.value });
    }
  };

  const handleEditChange = () => {
    if (isEditing) {
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  };

  const updatePerson = () => {
    var hour, halfDay, day, week;
    try {
      hour = parseFloat(rateHour);
    } catch (e) {
      console.log(e);
      hour = null;
    }
    try {
      halfDay = parseFloat(rateHalfDay);
    } catch (e) {
      console.log(e);
      halfDay = null;
    }
    try {
      day = parseFloat(rateDay);
    } catch (e) {
      console.log(e);
      day = null;
    }
    try {
      week = parseFloat(rateWeek);
    } catch (e) {
      console.log(e);
      week = null;
    }
    setUpdatingPerson(true);
    Axios.put(`People/UpdateContact/${props.person.id}?user=${user?.email}`, {
      id: props.person.id,
      firstName: firstName,
      lastName: lastName,
      fullName: `${firstName} ${lastName}`,
      birthday: birthday,
      phoneNumber: phoneNumber,
      emailAddress: emailAddress,
      address: address,
      personCategory: personCategory,
      jobTitle: jobTitle,
      rateHour: hour,
      rateHalfDay: halfDay,
      rateDay: day,
      rateWeek: week,
      parentName: parentName,
      hasParent: hasParent,
    })
      .then((res) => {
        console.log(res);
        setUpdatingPerson(false);
        setIsEditing(false);
      })
      .catch((e) => {
        console.log(e);
        setUpdatingPerson(false);
        setIsEditing(false);
      });
  };

  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={props.modalState}
        onClose={props.handleModal}
        className={classes.modal}
        disableBackdropClick={true}
      >
        <div>
          <AppBar position="static" style={{ background: "#217F8B" }}>
            <Toolbar variant="dense">
              <Typography
                variant="subtitle1"
                align="center"
                style={{ flexGrow: 1 }}
              >
                Contact Details
              </Typography>
              <IconButton
                aria-haspopup="true"
                onClick={props.handleModal}
                color="inherit"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Paper className={classes.paper} elevation={3}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton
                variant="contained"
                size="small"
                style={{ margin: "0px 5px 5px 5px", color: "black" }}
                onClick={() => handleEditChange()}
              >
                <EditIcon size="small" />
              </IconButton>
              {!updatingPerson ? (
                <IconButton
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={isEditing ? false : true}
                  style={{ margin: "0px 5px 5px 5px" }}
                  onClick={() => updatePerson()}
                >
                  <SaveIcon />
                </IconButton>
              ) : (
                <CircularProgress size={30} />
              )}
            </div>
            <Grid container>
              {/* Left Side */}
              <Grid item xs={6} md={6} lg={6}>
                <Grid container>
                  <Grid
                    item
                    xs={10}
                    md={10}
                    lg={10}
                    className={classes.grid}
                    style={
                      photoBlob !== null && photoBlob !== ""
                        ? { display: "flex", justifyContent: "center" }
                        : { display: "none" }
                    }
                  >
                    <img
                      alt={fullName}
                      src={photoBlob}
                      style={{ maxHeight: "20vH" }}
                    />
                  </Grid>
                  <Grid item xs={5} md={5} lg={5} className={classes.grid}>
                    <TextField
                      label="First Name"
                      multiline={true}
                      variant="outlined"
                      className={classes.blackText}
                      style={{ width: "100%" }}
                      value={firstName}
                      disabled={isEditing ? false : true}
                      onChange={handleContactDetailsChange("firstName")}
                    />
                  </Grid>
                  <Grid item xs={5} md={5} lg={5} className={classes.grid}>
                    <TextField
                      label="Last Name"
                      multiline={true}
                      variant="outlined"
                      className={classes.blackText}
                      style={{ width: "100%" }}
                      value={lastName}
                      disabled={isEditing ? false : true}
                      onChange={handleContactDetailsChange("lastName")}
                    />
                  </Grid>
                  <Grid item xs={5} md={5} lg={5} className={classes.grid}>
                    <TextField
                      label="Parent Name"
                      multiline={true}
                      variant="outlined"
                      className={classes.blackText}
                      style={{
                        width: "100%",
                        display: hasParent === 0 ? "none" : "",
                      }}
                      value={parentName}
                      disabled={isEditing ? false : true}
                      onChange={handleContactDetailsChange("parentName")}
                    />
                  </Grid>
                  <Grid item xs={5} md={5} lg={5} className={classes.grid}>
                    <TextField
                      label="Birthday"
                      multiline={true}
                      variant="outlined"
                      className={classes.blackText}
                      style={{ width: "100%" }}
                      value={birthday}
                      disabled={isEditing ? false : true}
                      onChange={handleContactDetailsChange("birthday")}
                    />
                  </Grid>
                  <Grid item xs={5} md={5} lg={5} className={classes.grid}>
                    <TextField
                      label="Phone"
                      multiline={true}
                      variant="outlined"
                      className={classes.blackText}
                      style={{ width: "100%" }}
                      value={phoneNumber}
                      disabled={isEditing ? false : true}
                      onChange={handleContactDetailsChange("phoneNumber")}
                    />
                  </Grid>
                  <Grid item xs={10} md={10} lg={10} className={classes.grid}>
                    <TextField
                      label="Email"
                      multiline={true}
                      variant="outlined"
                      className={classes.blackText}
                      style={{ width: "100%" }}
                      value={emailAddress}
                      disabled={isEditing ? false : true}
                      onChange={handleContactDetailsChange("emailAddress")}
                    />
                  </Grid>
                  <Grid item xs={10} md={10} lg={10} className={classes.grid}>
                    <TextField
                      label="Address"
                      multiline={true}
                      variant="outlined"
                      className={classes.blackText}
                      style={{ width: "100%" }}
                      value={address}
                      disabled={isEditing ? false : true}
                      onChange={handleContactDetailsChange("address")}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* Right Side */}
              <Grid item xs={6} md={6} lg={6}>
                <Grid container>
                  <Grid item xs={11} md={11} lg={11} className={classes.grid}>
                    <TextField
                      label="Category"
                      multiline={true}
                      variant="outlined"
                      className={classes.blackText}
                      style={{ width: "100%" }}
                      value={personCategory}
                      disabled={isEditing ? false : true}
                      onChange={handleContactDetailsChange("personCategory")}
                    />
                  </Grid>
                  <Grid item xs={11} md={11} lg={11} className={classes.grid}>
                    <TextField
                      label="Job Title"
                      multiline={true}
                      variant="outlined"
                      className={classes.blackText}
                      style={{ width: "100%" }}
                      value={jobTitle}
                      disabled={isEditing ? false : true}
                      onChange={handleContactDetailsChange("jobTitle")}
                    />
                  </Grid>
                  <Grid item xs={11} md={11} lg={11} className={classes.grid}>
                    <TextField
                      label="Hourly Rate"
                      multiline={true}
                      variant="outlined"
                      className={classes.blackText}
                      style={{ width: "100%" }}
                      value={rateHour}
                      disabled={isEditing ? false : true}
                      onChange={handleContactDetailsChange("rateHour")}
                    />
                  </Grid>
                  <Grid item xs={11} md={11} lg={11} className={classes.grid}>
                    <TextField
                      label="Half-Day Rate"
                      multiline={true}
                      variant="outlined"
                      className={classes.blackText}
                      style={{ width: "100%" }}
                      value={rateHalfDay}
                      disabled={isEditing ? false : true}
                      onChange={handleContactDetailsChange("rateHalfDay")}
                    />
                  </Grid>
                  <Grid item xs={11} md={11} lg={11} className={classes.grid}>
                    <TextField
                      label="Daily Rate"
                      multiline={true}
                      variant="outlined"
                      className={classes.blackText}
                      style={{ width: "100%" }}
                      value={rateDay}
                      disabled={isEditing ? false : true}
                      onChange={handleContactDetailsChange("rateDay")}
                    />
                  </Grid>
                  <Grid item xs={11} md={11} lg={11} className={classes.grid}>
                    <TextField
                      label="Weekly Rate"
                      multiline={true}
                      variant="outlined"
                      className={classes.blackText}
                      style={{ width: "100%" }}
                      value={rateWeek}
                      disabled={isEditing ? false : true}
                      onChange={handleContactDetailsChange("rateWeek")}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </Modal>
    </div>
  );
}

export default ContactDetailsPopUp;
