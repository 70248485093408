import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  IconButton,
} from "@material-ui/core";
import moment from "moment";
import { currencyFormat } from "../../../utils/GeneralUtils";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

const DuplicatesTable = ({ isCheckingForDuplicate, possibleDuplicates }) => {
  return (
    !isCheckingForDuplicate &&
    possibleDuplicates?.length > 0 && (
      <div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Possible duplicates found</TableCell>
                <TableCell>Total</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {possibleDuplicates.map((duplicate) => (
                <TableRow key={duplicate.recordId}>
                  <TableCell>
                    <Typography variant="caption">
                      {duplicate.companyName} {duplicate.name} -{" "}
                      {moment(duplicate.date).format("DD/MM/YYYY")} -{" "}
                      {duplicate.status}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">
                      {currencyFormat(duplicate.total)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <IconButton size="small">
                      <OpenInNewIcon
                        onClick={(e) => {
                          window.open(
                            `https://drive.google.com/file/d/${duplicate.driveId}/view`
                          );
                        }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  );
};

export default DuplicatesTable;
