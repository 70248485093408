import { Typography } from "@material-ui/core";
import React from "react";

const TimeTrackerCaptionLabel = ({ value }) => {
  return (
    <div className={`time-tracker-caption-label-root`}>
      <Typography className={`time-tracker-caption-label-text`}>
        {value}
      </Typography>
    </div>
  );
};

export default TimeTrackerCaptionLabel;
