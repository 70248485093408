import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import axios from "axios";
import React, { useState } from "react";

const ProjectsAutocomplete = ({ variant, handleOnChange, label }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  let timer = null;

  const fetchOptions = async (keyword) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/projects/SearchProjectsForAutocomplete`,
        {
          params: {
            keyword: keyword,
          },
        }
      );
      setOptions(response.data);
    } catch (error) {
      console.error("Error fetching options:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleInputChange = async (event, value) => {
    // Implement debouncing to delay API call
    clearTimeout(timer); // Clear previous timeout
    if (value && value?.length > 3) {
      timer = setTimeout(() => {
        fetchOptions(value);
      }, 500);
    } else {
      setOptions([]);
    }
  };

  return (
    <div>
      <Autocomplete
        options={options}
        loading={loading}
        getOptionLabel={(option) =>
          `${option?.jobId ? "[" + option?.jobId + "] - " : ""}${
            option?.projectName || ""
          }`
        }
        onInputChange={handleInputChange}
        onChange={(e, newValue) => {
            handleOnChange(newValue || null);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label ? label : "Search Projects"}
            variant={variant ? variant : "filled"}
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress size={12} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export default ProjectsAutocomplete;
