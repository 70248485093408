import axios from "axios";
import {
  GET_OUTPUTS,
  SPLICE_OUTPUTS_TO_COLUMN,
  SPLICE_OUTPUTS_FROM_COLUMN,
  SPLICE_ONE_OUTPUT_FROM_COLUMN,
  RESET_OUTPUTS_LOADING,
} from "./types";

//Get outputs
export const getOutputs = () => async (dispatch) => {
  try {
    const res = await axios.get("OutputsV2/GetOutputsForBoard");
    dispatch({
      type: GET_OUTPUTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: "OUTPUTS_ERROR",  
      payload: err,
    });
  }
};

export const spliceOutputsFromColumn = (source, projectId) => async (
  dispatch
) => {
  try {
    dispatch({
      type: SPLICE_OUTPUTS_FROM_COLUMN,
      payload: { source, projectId },
    });
  } catch (err) {
    dispatch({
      type: "OUTPUTS_ERROR",
      payload: err,
    });
  }
};
export const spliceOutputsToColumn = (destination, outputs) => async (
  dispatch
) => {
  try {
    dispatch({
      type: SPLICE_OUTPUTS_TO_COLUMN,
      payload: { destination, outputs },
    });
  } catch (err) {
    dispatch({
      type: "OUTPUTS_ERROR",
      payload: err,
    });
  }
};
export const spliceOneOutputFromColumn = (column, output) => async (
  dispatch
) => {
  try {
    dispatch({
      type: SPLICE_ONE_OUTPUT_FROM_COLUMN,
      payload: { column, output },
    });
  } catch (err) {
    dispatch({
      type: "OUTPUTS_ERROR",
      payload: err,
    });
  }
};
export const resetOutputsLoading = () => async (dispatch) => {
  try {
    dispatch({
      type: RESET_OUTPUTS_LOADING,
      payload: {},
    });
  } catch (err) {
    console.log(err);
  }
};

//Get outputs
export const getOutputsForCalendar = () => async (dispatch) => {
  try {
    axios.get("OutputsV2/GetOutputsForCalendar").then((res) => {
      var opList = res.data;
      const transformTimes = (item) => {
        let tfObj = { ...item };
        tfObj.start = new Date(item.start);
        tfObj.end = new Date(item.end);
        return tfObj;
      };
      opList = opList.map((item) => (item = transformTimes(item)));
      dispatch({
        type: "GET_OUTPUTS_FOR_CALENDAR",
        payload: opList,
      });
    });
  } catch (err) {
    dispatch({
      type: "OUTPUTS_ERROR",
      payload: err,
    });
  }
};

export const getOutputsForCalendarMonthView = () => async (dispatch) => {
  try {
    axios.get("OutputsV2/GetOutputsForCalendarMonthView").then((res) => {
      var opList = res.data;
      const transformTimes = (item) => {
        let tfObj = { ...item };
        tfObj.start = new Date(item.start);
        tfObj.end = new Date(item.end);
        return tfObj;
      };
      opList = opList.map((item) => (item = transformTimes(item)));
      dispatch({
        type: "GET_OUTPUTS_FOR_CALENDAR_MONTH_VIEW",
        payload: opList,
      });
    });
  } catch (err) {
    dispatch({
      type: "GET_OUTPUTS_FOR_CALENDAR_MONTH_VIEW_ERROR",
      payload: err,
    });
  }
};
