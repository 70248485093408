import React from "react";
import MediaSpendBudgetAllocation from "./MediaSpendBudgetAllocation";
import { TextField, Typography } from "@material-ui/core";

const MediaCampaignDetailsRow = ({
  mediaCampaign,
  handleSelectCampaign,
  budgetItems,
  setSnackBarProps,
  handleUpdateMediaSpend,
  fetchAllBudget,
  datesToDisplay,
  isClientView,
  commissionRate,
}) => {
  const handleOnClickCampaign = () => {
    handleSelectCampaign(mediaCampaign);
  };
  return (
    <div className={`media-spend-allocation-row`}>
      <div
        className="add-background text-alignment"
        onClick={handleOnClickCampaign}
      >
        <Typography variant="caption">{`${mediaCampaign.budget?.jobId ? mediaCampaign.budget?.jobId + " - " : ""}${mediaCampaign.name}`.trim()}</Typography>
      </div>
      <div
        className="add-background text-alignment"
        onClick={handleOnClickCampaign}
      >
        <Typography variant="caption">
          {mediaCampaign.mediaSupplier?.supplierName}
        </Typography>
      </div>
      <div
        className="add-background text-alignment"
        onClick={handleOnClickCampaign}
      >
        <Typography variant="caption">{mediaCampaign.poNumber}</Typography>
      </div>
      <div className="media-spend-butget-column-container add-background">
        <MediaSpendBudgetAllocation
          mediaCampaign={mediaCampaign}
          mediaSpendList={mediaCampaign.mediaSpend}
          budgetItems={budgetItems}
          setSnackBarProps={setSnackBarProps}
          handleUpdateMediaSpend={handleUpdateMediaSpend}
          fetchAllBudget={fetchAllBudget}
          datesToDisplay={datesToDisplay}
          isClientView={isClientView}
          commissionRate={commissionRate}
        />
      </div>
    </div>
  );
};

export default MediaCampaignDetailsRow;
