export const greetings = [
    "Hey",
    "Hello",
    "Heya",
    "G'day",
    "Hi",
    "Hii",
    "What's up",
    "How's it going",
    "How's it",
    "Good morning",
    "Good afternoon",
    "Good evening",
    "How have you been",
    "Good night"
]