import { Typography } from "@material-ui/core";
import React from "react";
import Spinner from "./Spinner";

const ProcessingSpinner = ({
  duration = 60,
  unitTime = "seconds",
  header = `Processing your request.`,
  body = `This action can take up to ${duration} ${unitTime}.`
}) => {
  return (
    <div>
      <Spinner />
      <div style={{marginTop: 15,textAlign: "center"}}>
        <Typography variant="h6">
           {header}
        </Typography>
        <Typography variant="h6">
           {body}
        </Typography>
      </div>
    </div>
  );
};

export default ProcessingSpinner;