import React, { useEffect, useState } from "react";
import {
    Button
} from "@material-ui/core";
import {
    Launch as LaunchIcon
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const MasterStatusButton = () => {
    const history = useHistory();
    const goToMasterStatus = (e) => {
        e.preventDefault();
        history.push("/Campaign/MasterStatus");
    };

    return (
        <Button
            className="default-button"
            size="small"
            startIcon={<LaunchIcon />}
            onClick={goToMasterStatus}
        >
            Master Status Page
        </Button>
    )
}

export default MasterStatusButton;