import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";
import { withRouter } from "react-router-dom";

// import CreateCompanyPopUp from "./PopUps/CreateCompanyPopUp";
// import CompanyDetailsPopUp from "./PopUps/CompanyDetailsPopUp";
import CompaniesTable from "./CompaniesTable";

import {
    TextField,
    Paper,
    AppBar,
    Toolbar,
    Typography,
    Input,
    Select,
    FormControl,
    Fab,
    MenuItem,
    FormHelperText,
    IconButton
} from "@material-ui/core";
import {
    Save as SaveIcon,
    Home as HomeIcon,
    AddCircle as AddCircleIcon
} from "@material-ui/icons";

//----------------------------------------
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1
    },
    appBarColor: {
        background: "red"
    },
    stickyAppBar: {
        position: "fixed",
        top: 0,
        width: "100%"
    }
}));

// -----Functions-----------
const HomeButton = withRouter(({ history }) => (
    <HomeIcon
        onClick={() => {
            history.push("/");
        }}
        style={{ cursor: "pointer" }}
    />
));
//-----End of Functions-------

export const Home = (props) => {
    const classes = useStyles();

    const [allCompanies, setAllCompanies] = React.useState([]);
    const [fetchAllCompanies, setFetchAllCompanies] = React.useState(false)

    const getAllCompanies = () => {
        setFetchAllCompanies(false);
        Axios.get(`Companies/GetCompanies`)
            .then(res => {
                console.log(res.data);
                setAllCompanies(res.data);
            })
            .catch(e => {
                console.log(e);
                setAllCompanies([]);
            })
    }

    //-----Use Effects--------------
    React.useEffect(() => {
        setFetchAllCompanies(true)
    }, [])
    React.useEffect(() => {
        getAllCompanies();
    }, [fetchAllCompanies])
    //-----End of Use Effects--------------

    return (
        <div
            style={{
                height: '100vh'
            }}
        >
            <AppBar
                style={{ background: "#3382FF" }}
                className={classes.stickyAppBar}
            >
                <Toolbar variant="dense">
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="home"
                    >
                        <HomeButton />
                    </IconButton>

                    <Typography variant="h6" className={classes.title} align="center">
                        Companies
                    </Typography>
                </Toolbar>
            </AppBar>
            <div style={{ padding: '48px 10px 10px 10px', height: 'auto' }}>
                <CompaniesTable
                    allCompanies={allCompanies}
                    refreshCompanies={() => setFetchAllCompanies(true)}
                />
            </div>
        </div>
    );
};
