import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { useClientContext } from "../../context/client/ClientContext";

const ClientsFilters = ({ keyword, setKeyword}) => {
  const { filter,updateClientFilter } = useClientContext();
  return (
    <div className="client-home-filter-container">
      <TextField
        label="Global Search"
        variant="filled"
        fullWidth={true}
        value={keyword || filter.keyword}
        size="small"
        onChange={(e) => {
          setKeyword(e.target.value);
          if(e.target.value == "" && filter.keyword != ""){
            updateClientFilter("keyword", "");
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 13 || e.key === "Enter") {
            updateClientFilter("keyword",keyword);
          }
        }}
      />
      
      <Autocomplete
        options={["Influencer Spend", "POI Spend", "Media Spend"]}
        getOptionLabel={(option) => option}
        value={filter.budgetCategory}
        onChange={(e, newValue) => {
          updateClientFilter("budgetCategory",newValue || null);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Budget Category"
            size="small"
            fullWidth={true}
            variant="filled"
          />
        )}
      />
    </div>
  );
};

export default ClientsFilters;
