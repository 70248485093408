import React, { useState, useEffect } from "react";
import axios from "axios";
import TableFilter from "react-table-filter";
import "react-table-filter/lib/styles.css";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { setSnackBarProps } from "../../../actions/snackbar";
import { setDialogProps } from "../../../actions/dialog";
import Dialogy from "../../Shared/Dialogy";
import InformationDialogy from "../../Shared/InformationDialogy";
import EditReceiptModal from "./EditReceiptModal";
import ReimbursementButton from "./ReimbursementButton";
import AccountCodePopUp from "./AccountCodePopUp";
import "./Reimbursment.css";
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    Button,
    TableSortLabel,
    Tooltip,
    TextField,
    Checkbox,
    Modal,
    IconButton
} from "@material-ui/core";
import {
    Close as CloseIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Flag as FlagIcon
} from "@material-ui/icons";
import {
    withStyles,
    makeStyles,
    useTheme,
    styled,
} from "@material-ui/core/styles";
import { format } from "date-fns";
import ContractorPopUp from "../../Project/ProjectOutputs/PopUps/ContractorPopUp";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontWeight: "bold",
    },
    body: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    root: {
        padding: "10px 10px 10px 10px",
        maxHeight: "50px",
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: "#efefef",
        },
    },
}))(TableRow);

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: 12,
        border: "1px solid #dadde9",
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

const headCells = [
    {
        id: "date",
        align: "center",
        label: "Date",
    },
    {
        id: "scheduleName",
        align: "left",
        label: "Shoot",
    },
    {
        id: "name",
        align: "left",
        label: "Name",
    },
    {
        id: "supplier",
        align: "left",
        label: "Supplier",
    },
    {
        id: "type",
        align: "left",
        label: "Type",
    },
    {
        id: "remaining",
        align: "left",
        label: "Remaining",
    },
    {
        id: "amount",
        align: "center",
        label: "Amount",
    },
    {
        id: "gst",
        align: "left",
        label: "GST Inc",
    },
    {
        id: "camistryCard",
        align: "left",
        label: "Camistry Card",
    },
    {
        id: "driveId",
        align: "center",
        label: "Receipt"
    },
    {
        id: "billStatus",
        align: "left",
        label: "Status",
    },
    {
        id: "actions",
        align: "left",
        label: "",
    },
];

const ReimbursementTable = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const [reimbursementList, setReimbursementList] = useState(props.reimbursementList);
    const [selectedReceipt, setSelectedReceipt] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [showReceiptDetailsModal, setShowReceiptDetailsModal] = useState(false);
    const [showAccountCodeError, setShowAccountCodeError] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [rowUpdating, setRowUpdating] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [sendOutUpdateRequestDialog, setSendOutUpdateRequestDialog] = useState(false);

    const [selectedContactId, setSelectedContactId] = useState(null);
    const [openContactDetailsPopUp, setOpenContactDetailsPopUp] = useState(false);

    const noReinbursmentItems = () => {
        // props.setTotalDueOnScreen(0);
        return <React.Fragment></React.Fragment>;
    };

    const handleProjectClicked = (receiptObj) => {
        setSelectedReceipt(receiptObj);
        setRedirect(true);
    }

    const handleReceiptItemChange = (event, column, receipt) => {
        var newReceipt = receipt;
        switch (column) {
            case "supplier":
                newReceipt.supplier = event.target.value;
                props.handleReceiptEdit(event.target.value, "supplier", newReceipt)
                break;
            case "type":
                newReceipt.type = event.target.value;
                props.handleReceiptEdit(event.target.value, "type", newReceipt)
                break;
            case "amount":
                newReceipt.amount = event.target.value;
                props.handleReceiptEdit(event.target.value, "amount", newReceipt)
                break;
            case "gst":
                newReceipt.gst = event.target.checked ? 1 : 0;
                props.handleReceiptEdit(event.target.checked ? 1 : 0, "gst", newReceipt)
                break;
            case "camistryCArd":
                newReceipt.camistryCard = event.target.checked ? 1 : 0;
                props.handleReceiptEdit(event.target.checked ? 1 : 0, "camistryCard", newReceipt)
                break;
            default:
                break;
        }
    }

    const getHeaderClass = (cellId) => {
        switch (cellId) {
            case "actions":
                return "reimbursment-head-cell reimbursment-head-cell-actions"
            case "remaining":
                return "reimbursment-head-cell reimbursment-head-cell-remaining"
            case "type":
                return "reimbursment-head-cell reimbursment-head-cell-type"
            case "billStatus":
                return "reimbursment-head-cell reimbursment-head-cell-status"
            case "amount":
                return "reimbursment-head-cell reimbursment-head-cell-amount"
            case "driveId":
                return "reimbursment-head-cell reimbursment-head-cell-driveId"
            case "date":
                return "reimbursment-head-cell reimbursment-head-cell-date"
            case "camistryCard":
                return "reimbursment-head-cell reimbursment-head-cell-camistry-card"
            case "gst":
                return "reimbursment-head-cell reimbursment-head-cell-gst"
            default:
                return "reimbursment-head-cell";
        }
    };

    const handleHeadCell = (cell) => {
        if (cell.id === "date") {
            return (
                <th
                    filterkey={cell.id}
                    className={getHeaderClass(cell.id)}
                    itemDisplayValueFunc={(itemValue) => {
                        if (itemValue !== null) {
                            return itemValue.toDateString();
                        }
                    }}
                    itemSortValueFunc={(itemValue) => {
                        if (itemValue !== "(blank)") {
                            return itemValue.toDateString();
                        }
                    }}
                    align={cell.align}
                >
                    {cell.label}
                </th>
            );
        } else if (cell.id === "camistryCard") {
            return (
                <th
                    filterkey={cell.id}
                    className={getHeaderClass(cell.id)}
                    align={cell.align}
                    itemDisplayValueFunc={(itemValue) => {
                        if (itemValue === 1) {
                            return "Yes"
                        }
                        else if (itemValue === 0) {
                            return "No"
                        }
                        else {
                            return ""
                        }
                    }}
                >
                    {cell.label}
                </th>
            );
        } else if (cell.id === "driveId" || cell.id === "actions") {
            return (
                <th
                    className={getHeaderClass(cell.id)}
                    align={cell.align}
                >
                    {cell.label}
                </th>
            );
        } else {
            return (
                <th
                    filterkey={cell.id}
                    className={getHeaderClass(cell.id)}
                    alignleft="true"
                >
                    {cell.label}
                </th>
            );
        }
    }

    useEffect(() => {
        if (rowUpdating) {
            setRowUpdating(false);
        }
    }, [rowUpdating])

    var totalDue = 0.0;

    if (reimbursementList.length === 0) {
        props.setTotalDueOnScreen(totalDue);
    }

    if (redirect) {
        history.push(`/Project/Home/${selectedReceipt.projectId}/3/${selectedReceipt.scheduleId}/0`);
        return <React.Fragment></React.Fragment>;
    }
    else if (rowUpdating) {
        return <React.Fragment></React.Fragment>
    }
    else {
        return (
            <React.Fragment>
                {openContactDetailsPopUp && selectedContactId !== null && (
                    <ContractorPopUp
                        handleModal={() => {
                            setSelectedContactId(null);
                            setOpenContactDetailsPopUp(false);
                        }}
                        modalState={openContactDetailsPopUp}
                        personId={selectedContactId}
                        scheduleItemId={null}
                        reloadPersonList={() => console.log("No reload")}
                        reloadScheduleItemsList={() => console.log("No reload")}
                        type="ReimbursementTable"
                        //providerType={null}
                        handleEditPerson={() => console.log("No rowUpdate")}
                        //selectedContact={null}
                        rowUpdated={() => console.log("No rowUpdate")}
                    />
                )}
                <Table
                    id="reimbursment-table"
                    className={classes.table}
                    size="small"
                    aria-label="customized table"
                    style={{ tableLayout: "fixed" }}
                >
                    <TableFilter
                        rows={reimbursementList}
                        onFilterUpdate={(updatedData, filterConfiguration) => {
                            setReimbursementList(updatedData);
                        }}
                        initialFilters={props.initialFilters}
                    >
                        {headCells.map((cell) => {
                            return handleHeadCell(cell);
                        })}
                    </TableFilter>
                    <TableBody>
                        {showAccountCodeError && (
                            <AccountCodePopUp
                                modalState={showAccountCodeError}
                                handleCloseModal={() => {
                                    setSelectedReceipt(null);
                                    setShowAccountCodeError(false)
                                }}
                                acceptReceipt={props.handleReceiptEdit}
                                receipt={selectedReceipt}
                            />
                        )}
                        {/* {showAccountCodeError && (
                            <InformationDialogy
                                setDialogProps={setDialogProps}
                                buttonProps={{
                                    show: showAccountCodeError,
                                    titleText: "Account Code Error",
                                    contentText: `Please edit the receipt and fill out the Account Code before accepting.`,
                                    buttonColor: "primary",
                                    buttonText: "Ok",
                                }}
                                handleButton={() => setShowAccountCodeError(false)}
                            />
                        )} */}
                        {selectedReceipt !== null && showWarning && (
                            <Dialogy
                                setDialogProps={setDialogProps}
                                buttonProps={{
                                    show: showWarning,
                                    titleText: "Are you sure you want to delete?",
                                    contentText:
                                        "This will permenantly delete this from the database",
                                    buttonOneColor: "primary",
                                    buttonOneText: "Cancel",
                                    buttonTwoColor: "primary",
                                    buttonTwoText: "Ok",
                                }}
                                handleButtonOne={() => {
                                    setShowWarning(false)
                                    setSelectedReceipt(null);
                                }}
                                handleButtonTwo={() => {
                                    props.handleReceiptDelete(selectedReceipt);
                                    setSelectedReceipt(null);
                                    setShowWarning(false);
                                    axios.delete(`Receipts/DeleteReceipt/${selectedReceipt.id}`, selectedReceipt)
                                        .then((res) => { })//console.log(res.data)
                                        .catch((e) => console.log(e))
                                }}
                            />
                        )}
                        {selectedReceipt !== null && sendOutUpdateRequestDialog && (
                            <Dialogy
                                setDialogProps={setDialogProps}
                                buttonProps={{
                                    show: sendOutUpdateRequestDialog,
                                    titleText: "Missing Bank Account",
                                    contentText:
                                        "Do you want to send out a request to get the bank account number?",
                                    buttonOneColor: "primary",
                                    buttonOneText: "Cancel",
                                    buttonTwoColor: "primary",
                                    buttonTwoText: "Send Request",
                                }}
                                handleButtonOne={() => {
                                    setSendOutUpdateRequestDialog(false)
                                    setSelectedReceipt(null);
                                }}
                                handleButtonTwo={() => {
                                    props.handleRequestBankAccountReceipt({
                                        ...selectedReceipt,
                                        ["billStatus"]: "InfoRequested"
                                    })
                                    setSelectedReceipt(null);
                                    setSendOutUpdateRequestDialog(false);
                                    axios.post(`Receipts/SendUpdateBankAccountRequest/${selectedReceipt.id}/-1/fromReceipt`)
                                        .then((res) => { })//console.log(res.data)
                                        .catch((e) => console.log(e))
                                }}
                            />
                        )}
                        {selectedReceipt !== null && showReceiptDetailsModal && (
                            <EditReceiptModal
                                modalState={showReceiptDetailsModal}
                                handleCloseModal={() => {
                                    setSelectedReceipt(null)
                                    setShowReceiptDetailsModal(false)
                                    setEditMode(false);
                                }}
                                receipt={selectedReceipt}
                                handleReceiptEdit={props.handleReceiptEdit}
                                handleEditReceiptModalSave={props.handleEditReceiptModalSave}
                                setRowUpdating={setRowUpdating}
                                rowUpdating={rowUpdating}
                                editMode={editMode}
                                setEditMode={setEditMode}
                            />
                        )}
                        {reimbursementList.length > 0 && !props.rowDeleted
                            ? reimbursementList.map((row, index) => {
                                if (row.amount !== null) {
                                    totalDue = totalDue + parseFloat(row.amount);
                                }
                                // console.log(index)
                                props.setTotalDueOnScreen(totalDue);
                                var editDisabled = row.billStatus !== "Pending";
                                var deleteDisabled = row.billStatus === "Approved";
                                return (
                                    <StyledTableRow
                                        key={`${row.id}-reimbursment-table-row`}
                                        hover={true}
                                        className={row.remaining < 0 && "reimbursment-table-row-over-budget"}
                                    >
                                        <StyledTableCell align="left" className="reimbursment-body-cell">
                                            {row.date !== null
                                                ? format(row.date, "dd/MM/yyyy")
                                                : null}
                                        </StyledTableCell>
                                        <StyledTableCell align="left" className="reimbursment-body-cell">
                                            <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        <p>
                                                            <b>{row.projectName}</b>
                                                        </p>
                                                        <p>
                                                            <em>{row.scheduleName}</em>
                                                        </p>
                                                    </React.Fragment>
                                                }
                                                placement={"top-start"}
                                            >
                                                <span
                                                    className="clickable"
                                                    onClick={() => handleProjectClicked(row)}
                                                >
                                                    {`${row.scheduleName}`}
                                                </span>
                                            </HtmlTooltip>
                                        </StyledTableCell>
                                        <StyledTableCell align="left" className="reimbursment-body-cell">
                                            {row.personFk !== null ? (
                                                <span
                                                    className="clickable"
                                                    onClick={() => {
                                                        setSelectedContactId(row.personFk);
                                                        setOpenContactDetailsPopUp(true);
                                                    }}
                                                >
                                                    {`${row.name}`}
                                                </span>
                                            ) : row.name}
                                        </StyledTableCell>
                                        <StyledTableCell align="left" className="reimbursment-body-cell">
                                            {row.supplier}
                                            {/* <TextField
                                                disabled={editDisabled}
                                                size="small"
                                                className="reimbursment-text-field"
                                                defaultValue={row.supplier}
                                                onChange={(e) => {
                                                    handleReceiptItemChange(e, "supplier", row)
                                                }}
                                            /> */}
                                        </StyledTableCell>
                                        <StyledTableCell align="left" className="reimbursment-body-cell">
                                            {row.type}
                                            {/* <TextField
                                                disabled//={editDisabled}
                                                size="small"
                                                className="reimbursment-text-field"
                                                defaultValue={row.type}
                                                onChange={(e) => {
                                                    handleReceiptItemChange(e, "type", row)
                                                }}
                                            /> */}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="left"
                                            className={row.remaining < 0 ? `reimbursment-table-remaining-over-budget` : `reimbursment-body-cell`}
                                        >
                                            <span>
                                                {row.remaining >= 0 ? `$${row.remaining}` : `- $${(row.remaining * -1).toFixed(2)}`}
                                            </span>
                                        </StyledTableCell>
                                        <StyledTableCell className="reimbursment-body-cell">
                                            {row.amount >= 0 ? `$${row.amount}` : `- $${row.amount * -1}`}
                                            {/* <TextField
                                                disabled={editDisabled}
                                                size="small"
                                                className="reimbursment-text-field"
                                                defaultValue={row.amount}
                                                onChange={(e) => {
                                                    handleReceiptItemChange(e, "amount", row)
                                                }}
                                            /> */}
                                        </StyledTableCell>
                                        <StyledTableCell className="reimbursment-body-cell">
                                            {/* {row.gst} */}
                                            <Checkbox
                                                disabled={true}//{editDisabled}
                                                defaultChecked={row.gst === 1}
                                                color="primary"
                                                onChange={(e) => {
                                                    handleReceiptItemChange(e, "gst", row)
                                                }}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell className="reimbursment-body-cell">
                                            {/* {row.camistryCard !== null ? (row.camistryCard === 1 ? "Yes" : "No") : ""} */}
                                            <Checkbox
                                                disabled={true}//{editDisabled}
                                                defaultChecked={row.camistryCard === 1}
                                                color="primary"
                                                onChange={(e) => {
                                                    handleReceiptItemChange(e, "camistryCard", row)
                                                }}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell align="center" className="reimbursment-body-cell">
                                            {row.driveId !== null || row.organisationId === 5 ? (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => {
                                                        setSelectedReceipt(row);
                                                        setShowReceiptDetailsModal(true);
                                                    }}
                                                >
                                                    View
                                                </Button>
                                            ) : ("")}
                                        </StyledTableCell>
                                        <StyledTableCell className="reimbursment-body-cell" style={{ padding: "2px 0px 2px 4px" }}>
                                            <ReimbursementButton
                                                receipt={row}
                                                handleOnClick={(value, key, receipt) => {
                                                    props.handleReceiptEdit(value, key, receipt);
                                                    setRowUpdating(true);
                                                }}
                                                accountCode={row.accountCode}
                                                handleAccountCodeError={() => {
                                                    setSelectedReceipt(row)
                                                    setShowAccountCodeError(true)
                                                }}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell className="reimbursment-body-cell">
                                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                                {row.billStatus !== "InfoRequested" && (row.bankAccount === null || row.bankAccount === "") && (
                                                    <IconButton
                                                        style={editDisabled ? { display: "none" } : {}}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            setSelectedReceipt(row);
                                                            setSendOutUpdateRequestDialog(true);
                                                            // setEditMode(true)
                                                        }}
                                                        size="small"
                                                    >
                                                        <FlagIcon style={{ color: 'red' }} />
                                                    </IconButton>
                                                )}
                                                <IconButton
                                                    style={editDisabled ? { display: "none" } : {}}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        setSelectedReceipt(row);
                                                        setShowReceiptDetailsModal(true);
                                                        setEditMode(true)
                                                    }}
                                                    size="small"
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    style={deleteDisabled ? { display: "none" } : {}}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        setSelectedReceipt(row);
                                                        setShowWarning(true);
                                                    }}
                                                    color="black"
                                                    size="small"
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                            })
                            : noReinbursmentItems()
                        }
                    </TableBody>
                </Table>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    snackbar: state.snackbar,
    dialog: state.dialog,
});
export default connect(mapStateToProps, {
    setSnackBarProps,
    setDialogProps,
})(ReimbursementTable);