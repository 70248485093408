import React, { Component, useEffect, useState } from "react";
import Axios from "axios";
import { useLocation, useHistory, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AdminPanel from "./AdminPanel";
import "./Admin.css";
import ExpressPayment from "./ExpressPayment";
import Catering from "./Catering";
import Surveys from "./Surveys";
import Emailer from "../Emailer/TemplateEmailCreator";
import ContractsAndReleases from "./ContractsAndReleases/ContractsAndReleases";
import SpamBot from "./SpamBot";
import MediaOffset from "./MediaOffset";
import SMS from "./SMS";
import HealthAndSafetyMainPage from "./HealthAndSafety/HealthAndSafetyMainPage";
import ScheduleEmail from "./ScheduleEmail";
import Users from "./Users/Users";
import ReimbursementPage from "./Reimbursement/ReimbursementPage";
import CheckInPage from "./CheckIn/CheckInPage";
import MediaPricing from "./MediaPricing";
import TravelContribution from "./TravelContribution";
import MediaAdsDashboard from "./MediaAdsDashboard/MediaAdsDashboard";
import MediaSupplierTable from "./MediaSuppliers/MediaSupplierTable";
import System from "./System";
import RwtReport from "./RwtReport/RwtReport";
import CssBaseline from "@material-ui/core/CssBaseline";
import DashboardIcon from "@material-ui/icons/Dashboard";
import {
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
} from "@material-ui/core";
import {
  Mail,
  AttachMoney,
  Restaurant,
  Description,
  OfflineBolt,
  Feedback,
  Sms,
  LocalHospital,
  Theaters,
  People,
  CreditCard as CreditCardIcon,
  ContactMail as ContactMailIcon,
  CheckCircle as CheckCircleIcon,
  Receipt as ReceiptIcon,
  BusinessCenter as BusinessCenterIcon,
  DirectionsCar,
  Settings as SettingsIcon,
} from "@material-ui/icons";
import { useUserContext } from "../../context/user/UserContext";
import InfluencerOutreachEmail from "./InfluencerOutreachEmail";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import InfluencerAdmin from "./Influencer/InfluencerAdmin";
import BillsDashboard from "./BillsDashboard/BillsDashboard";
import MediaInvoiceSettings from "./InvoiceSettings/MediaInvoiceSettings";
import MediaSuppliers from "./MediaSuppliers/MediaSuppliers";
import ReconciliationCentre from "./ReconCentre/ReconciliationCentre";
import { useAuth } from "../../context/auth/AuthContext";
import Heading from "../Shared/Heading/Heading";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

const AdminHome = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  const [adminMenu, setAdminMenu] = useState("");
  const [pageType, setPageType] = useState("Admin Panel");
  const [adminList, setAdminList] = useState([]);

  useEffect(() => {
    var arr = location.pathname.split("/");
    if (
      arr[1] === "Finance" ||
      arr[2] === "Contracts" ||
      arr[2] === "Reimbursement" ||
      arr[2] === "Check-In" ||
      arr[2] === "RwtReport" ||
      arr[2] === "Reconciliation Centre" ||
      arr[2] === "Bills Dashboard"
    ) {
      setAdminList([
        "Check-In",
        "Contracts",
        "RwtReport",
        "Reimbursement",
        "Reconciliation Centre",
        "Bills Dashboard",
      ]);
      setPageType("Finance");
    } else {
      setAdminList([
        "Express Payment",
        "Catering",
        "Contracts/Releases",
        "Surveys",
        "Email",
        "SMS",
        "SpamBot",
        "Health & Safety",
        "Media Pricing",
        "Schedule Email",
        // "Influencer Outreach Email",
        "Travel Contribution",
        "System",
        "Influencers",
        "Users",
        "Media Suppliers",
      ]);
      setPageType("Admin");
    }
  }, [location.pathname]);

  useEffect(() => {
    var arr = location.pathname.split("/");
    if (arr[2] === "Contracts-Releases") {
      setAdminMenu("Contracts/Releases");
    } else {
      setAdminMenu(arr[2]);
    }
  }, [location.pathname]);

  useEffect(() => {
    var arr = location.pathname.split("/");
    if (arr[2] === "FinanceHome") {
      if (user?.role === "Admin") {
        //||user.email === "accounts@camistry.co.nz"
        setAdminMenu(adminList[3]);
      } else {
        setAdminMenu(adminList[0]);
      }
    } else if (arr[1] === "Media") {
      if (arr[2] === "Home") {
        setAdminMenu(adminList[0]);
      }
    } else if (location.pathname === "/Admin/Home") {
      setAdminMenu(adminList[0]);
    }
  }, [adminList]);
  return (
    <div>
      <div className={classes.root}>
        {adminMenu === "Express Payment" ? (
          <ExpressPayment />
        ) : adminMenu === "Catering" ? (
          <Catering />
        ) : adminMenu === "Contracts/Releases" ? (
          <ContractsAndReleases />
        ) : adminMenu === "Surveys" ? (
          <Surveys />
        ) : adminMenu === "Email" ? (
          <Emailer />
        ) : adminMenu === "SMS" ? (
          <SMS />
        ) : adminMenu === "SpamBot" ? (
          <SpamBot />
        ) : adminMenu === "Health & Safety" ? (
          <HealthAndSafetyMainPage />
        ) : adminMenu === "Schedule Email" ? (
          <ScheduleEmail />
        ) : adminMenu === "Users" ? (
          <Users />
        ) : adminMenu === "Reimbursement" ? (
          <ReimbursementPage />
        ) : adminMenu === "Contracts" ? (
          <SpamBot />
        ) : adminMenu === "Check-In" ? (
          <CheckInPage />
        ) : adminMenu === "Media Ad Receipts & Invoices" ? (
          <MediaAdsDashboard />
        ) : adminMenu === "Media Suppliers" ? (
          <MediaSuppliers />
        ) : adminMenu === "Media Pricing" ? (
          <MediaPricing />
        ) : adminMenu === "Travel Contribution" ? (
          <TravelContribution />
        ) : adminMenu === "System" ? (
          <System />
        ) : adminMenu === "RwtReport" ? (
          <RwtReport />
        ) : adminMenu === "Influencers" ? (
          <InfluencerAdmin />
        ) : adminMenu === "Bills Dashboard" ? (
          <BillsDashboard />
        ) : adminMenu === "Reconciliation Centre" ? (
          <ReconciliationCentre />
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(AdminHome);
