import { Button, Table, TableCell, TableRow, Typography, TableHead } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { currencyFormat } from "../../../utils/GeneralUtils";
import { getTotalAmountFromList } from "../../../utils/BudgetItemsUtils";
import ConfirmationDialogActions from "../../Shared/ConfirmationDialogActions";
import CloseAndTransferBudgetPopup from "./CloseAndTransferBudgetPopup";
import moment from "moment";
import ContractStatusChips from "./ContractStatusChips";

const BudgetAccordionHead = ({
  budgetByProject,
  expanded,
  handleCloseProject,
  handleCloseAndTransfer,
  groupedBudgetsByProjectId,
  handleCloseProjectWithLiability
}) => {
  const [confirmCloseProjectDialog, setConfirmCloseBudgetDialog] = useState(false)
  const [showCloseAndTransferBudgetPopup, setShowCloseAndTransferBudgetPopup] = useState(false)
  const [awaitingSign, setAwaitingSign] = useState(0);
  const [signed, setSigned] = useState(0);
  const [awaitingInvoice, setAwaitingInvoice] = useState(0);

  const handleRedirectToProjecktor = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(
      `https://sales.thompsonspencer.com/job/${budgetByProject[0].jobId}`,
      "_blank"
    );
  };

  const getTotalRemainingByCurrency = () => {
    const totalsByCurrency = {};

    for (const item of budgetByProject) {
      const currency = item.currency || "NZD"; // Default to USD if no currency is specified
      if (!totalsByCurrency[currency]) {
        totalsByCurrency[currency] = 0;
      }
      totalsByCurrency[currency] += item.remaining || 0;
    }
    return totalsByCurrency;
  };

  useEffect(() => {
    var awaitingSignCount = 0;
    var signCount = 0;
    var awaitingInvoiceCount = 0;
    for (var i = 0; i < budgetByProject.length; i++) {
      awaitingSignCount += budgetByProject[i].recordsAwaitingSign;
      signCount += budgetByProject[i].recordsSigned;
      awaitingInvoiceCount += budgetByProject[i].recordsAwaitingInvoice;
    }
    setAwaitingSign(awaitingSignCount);
    setSigned(signCount);
    setAwaitingInvoice(awaitingInvoiceCount);
  }, [budgetByProject])

  const renderRemainingTotals = () => {
    const totals = getTotalRemainingByCurrency();
    return Object.entries(totals).map(([currency, total]) => (
      <Typography
        key={currency}
        style={total > 0 ? { fontSize: 12, color: "#299965" } : { fontSize: 9 }}
        className="roboto-medium"
        align="center"
      >
        {currencyFormat(total, currency)}
      </Typography>
    ));
  };

  return (
    <div className="budget-accordion-header-root">
      {showCloseAndTransferBudgetPopup && (
        <CloseAndTransferBudgetPopup
          budgetByProject={budgetByProject}
          groupedBudgetsByProjectId={groupedBudgetsByProjectId}
          open={showCloseAndTransferBudgetPopup}
          handleClose={() => setShowCloseAndTransferBudgetPopup(false)}
          handleCloseAndTransfer={handleCloseAndTransfer}
          handleCloseProjectWithLiability={handleCloseProjectWithLiability}
        />
      )}
      {confirmCloseProjectDialog && (
        <ConfirmationDialogActions
          title={"Close Project"}
          open={confirmCloseProjectDialog}
          contentText={"This action will archive the project in the system."}
          button1Class={"default-button-grey roboto-regular"}
          button1Text={"Cancel"}
          button1Action={(e) => {
            setConfirmCloseBudgetDialog(false);
          }}
          button2Class={"default-button roboto-regular"}
          button2Text={"Confirm"}
          button2Action={(e) => {
            handleCloseProject(budgetByProject[0].projectId);
            setConfirmCloseBudgetDialog(false);
          }}
          handleClose={() => setConfirmCloseBudgetDialog(false)}
        />
      )}
      <Table className="budget-accordion-header-table">
        <TableHead>
          <TableRow>
            <TableCell className="column1 no-border-cell no-padding-cell-all no-background-cell">
              <div className="budget-accordion-header-job-container">
                <div
                  className={expanded ? "budget-accordion-header-job-number-container" : "budget-accordion-header-job-number-container-closed"}
                  onClick={(e) => {
                    if (budgetByProject[0].jobId) {
                      handleRedirectToProjecktor(e)
                    }
                  }}
                >
                  <Typography variant="caption" className="roboto-medium" style={{ fontSize: 10 }}>
                    {budgetByProject[0].jobId ? `Code: ${budgetByProject[0].jobId}` : `Code: n/a`}
                  </Typography>
                </div>
              </div>
            </TableCell>
            <TableCell className="column2 no-border-cell no-padding-cell-all no-background-cell">
              <Typography className="budget-accordion-header-job-name-container roboto-medium">
                {budgetByProject[0].billingDate ? `${moment(budgetByProject[0].billingDate).format("MMM/YY")} - ${budgetByProject[0].projectName}`
                  : `${budgetByProject[0].projectName}`}
              </Typography>
            </TableCell>
            <TableCell className="column3 no-border-cell no-padding-cell-all no-background-cell" align="right">
              <ContractStatusChips
                awaitingSign={awaitingSign}
                signed={signed}
                awaitingInvoice={awaitingInvoice}
              />
            </TableCell>
            <TableCell className="column4 no-border-cell no-padding-cell-all no-background-cell" align="right">
              <div className="budget-accordion-header-total-column-container close-project-button-header">
                {/* {budgetByProject[0].showClose && (
                  <Button className="default-button roboto-medium close-project-button"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (totalRemaining <= 0) {
                        setConfirmCloseBudgetDialog(true)
                      } else {
                        setShowCloseAndTransferBudgetPopup(true)
                      }
                    }}>
                    Close Project
                  </Button>
                )} */}
              </div>
            </TableCell>
            <TableCell className="column5 no-border-cell no-padding-cell-all no-background-cell">
              <div className="budget-accordion-header-total-column-container remaining-header">
                <Typography variant="caption" className="roboto-medium">Remaining</Typography>
                {renderRemainingTotals()}
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </div>
  );
};

export default BudgetAccordionHead;
