import React, { useState, useEffect } from "react";
import {
    Modal,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Button,
    Grid,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        height: "auto",
        maxHeight: "80vH",
        width: "500px",
        overflowY: "auto"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const NewMediaSupplierModal = (props) => {
    const classes = useStyles();
    const [xeroName, setXeroName] = useState("");
    const [xeroBankAcc, setXeroBankAcc] = useState("");
    // const [xeroOutputCode, setXeroOutputCode] = useState("");
    // const [xeroAccountCode, setXeroAccountCode] = useState("");


    return (
        <div>
            <Modal
                open={props.modalState}
                onClose={() => props.handleModal()}
                className={classes.modal}
            >
                <div>
                    <AppBar position="static" style={{ background: "#3d4977" }}>
                        <Toolbar
                            disableGutters
                            variant="dense"
                        >
                            <Typography
                                variant="subtitle1"
                                align="center"
                                style={{ flexGrow: 1 }}
                            >
                                {`New Supplier`}
                            </Typography>
                            <IconButton
                                onClick={() => props.handleModal()}
                                color="inherit"
                            >
                                <Close />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.paper}>
                        <div
                            style={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <TextField
                                        label="Contact Name"
                                        size="small"
                                        value={xeroName}
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        onChange={e => setXeroName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <TextField
                                        label="Bank Account Number"
                                        size="small"
                                        value={xeroBankAcc}
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        onChange={e => setXeroBankAcc(e.target.value)}
                                    />
                                </Grid>
                                {/* <Grid item xs={12} md={12} lg={12}>
                                    <div className="textfield-padding">
                                        <FormControl fullWidth>
                                            <InputLabel>Gst Rate Code</InputLabel>
                                            <Select
                                                id={`gst-rate-code-dropdown`}
                                                value={xeroOutputCode}
                                                onChange={event => setXeroOutputCode(event.target.value)}
                                                inputProps={{
                                                    name: 'Gst Rate Code',
                                                    id: 'gst-rate-code-popup',
                                                }}
                                            >
                                                <MenuItem key={"INPUT2"} value={"INPUT2"}>{"INPUT2"}</MenuItem>
                                                <MenuItem key={"OUTPUT2"} value={"OUTPUT2"}>{"OUTPUT2"}</MenuItem>
                                                <MenuItem key={"NONE"} value={"NONE"}>{"NONE"}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <TextField
                                        label="Account Code"
                                        size="small"
                                        value={xeroAccountCode}
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        onChange={e => setXeroAccountCode(e.target.value)}
                                        type="number"
                                    />
                                </Grid> */}
                                <Grid item xs={12} md={12} lg={12}>
                                    {xeroBankAcc.trim() !== "" && xeroName.trim() !== "" && (//&& xeroOutputCode.trim() !== "" && xeroAccountCode.trim() !== ""
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() => {
                                                if(xeroBankAcc.trim() !== "" && xeroName.trim() !== "" ){//&& xeroOutputCode.trim() !== ""
                                                    props.updateMediaAd("", "", props.selectedAd, {
                                                        supplierName: xeroName,
                                                        xeroName: xeroName,
                                                        xeroGstRateCode: "OUTPUT2",//xeroOutputCode,
                                                        xeroAccountCode: "206",//parseInt(xeroAccountCode),
                                                        xeroBankAccount: xeroBankAcc
                                                    })
                                                    props.updatedRow()
                                                    props.handleModal()
                                                }
                                            }}
                                        >
                                            Save
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default NewMediaSupplierModal;