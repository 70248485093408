export const setDialogProps = (open, number) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_DIALOG_PROPS",
      payload: {
        open,
        number,
      },
    });
  } catch (err) {
    dispatch({
      type: "SET_DIALOG_PROPS_ERROR",
      payload: err,
    });
  }
};
