import React, { useEffect, useState } from "react";
import { useUserContext } from "../../../context/user/UserContext";
import { useAuth } from "../../../context/auth/AuthContext";
import axios from "axios";
import "./clientPeopleOfInfluence.scss";
import Snacky from "../../Shared/Snacky";
import { v1 as uuidv1 } from "uuid";
import AddNewPOIInfluencerButton from "./AddNewPOIInfluencerButton";
import { format } from "date-fns";
import { Skeleton } from "@material-ui/lab";
import PeopleOfInfluenceForm from "./PeopleOfInfluenceForm";
import ClientPeopleOfInfluenceTable from "./ClientPeopleOfInfluenceTable";
import { getLastDayCurrentMonth } from "../../../utils/TimeHelpers";
import moment from "moment";
import UpdatePoiInfluenceDialog from "../../InfluencersNetwork/UpdatePoiInfluenceDialog";
import GeneralDocumentViewer from "../../Shared/Dialog/GeneralDocumentViewer";
import PeopleOfInfluenceReimbursementPoup from "./PeopleOfInfluenceReimbursementPoup";
import PeopleOfInfluenceReimbursementTable from "./PeopleOfInfluenceReimbursementTable";
const ClientPeopleOfInfluence = ({
  budgetItems,
  companyId,
  fetchAllBudget,
}) => {
  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [isSavingForm, setIsSavingForm] = useState(false);
  const [poiInfluencers, setPoiInfluencers] = useState([]);
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);
  const [openCreateNewInfluencer, setOpenCreateNewInfluencer] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [showDocumentViewer, setShowDocumentViewer] = useState(false);
  const [showReimbursementPopup, setShowReimbursementPopup] = useState(false);
  const [reloadReimbursementTable, setReloadReimbursementTable] = useState(false);
  const [searchPaidInvoices, setSearchPaidInvoices] = useState(null);

  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });

  useEffect(() => {
    searchPoiInfluencers();
  }, [searchPaidInvoices]);

  const searchPoiInfluencers = () => {
    setIsLoading(true);
    axios
      .get(`influencer/GetPeopleOfInfluenceScheduleItemsByCompany/${companyId}`,  {
        params: {
          searchPaidInvoices: searchPaidInvoices
        }
      })
      .then(({ data }) => {
        setPoiInfluencers(data);
      })
      .finally(() => setIsLoading(false));
  };

  const handleSelectInfluencer = (influencer) => {
    setSelectedInfluencer(influencer);
  };
  const handleAddNewInfluencer = () => {
    setSelectedInfluencer({
      startTime: getLastDayCurrentMonth().format("YYYY-MM-DD"),
      allocatedRates: 0,
      usageInMonths: 3,
      exclusivityInMonths: 3,
      invoiceStatus: null,
      scheduleSentStatus: null,
      isStaff: 2,
      isClient: 0,
      companyFk: companyId,
      contractStatus: "InvoicePending",
      singleUseParam: uuidv1(),
      talentContractType: "BuyOut",
      talentContractStatus: "Complete",
      roleFk: 175,
      createdBy: user?.email,
      talentContract: [
        {
          dateSent: moment(),
          status: "Signed",
          dateSigned: moment(),
          signedBy: user?.email,
        },
      ],
    });
  };

  const deleteScheduleItem = (item) => {
    axios
      .delete(`ScheduleItems/DeleteScheduleItem/${item.id}`)
      .then((res) => {
        fetchAllBudget();
        setPoiInfluencers(poiInfluencers.filter((i) => i.id !== item.id));
        setSnackBarProps({
          severity: "success",
          text: "All Done! The talent record has been removed",
          open: true,
        });
        //props.getBudget(props.projectId);
      })
      .catch((error) => {
        console.log(error);
        setSnackBarProps({
          severity: "warning",
          text: "Failed to delete item",
          open: true,
        });
      });
  };

  const handleOnChange = (field, value) => {
    if (field === "budget") {
      setSelectedInfluencer({
        ...selectedInfluencer,
        category: value?.projectCategory || null,
        budgetId: value?.budgetId || null,
      });
    } else if (field === "person") {
      setSelectedInfluencer({
        ...selectedInfluencer,
        providerName: value?.firstName + " " + value?.lastName,
        personFk: value.id,
      });
    } else if (field === "budgetAndPerson") {
      setSelectedInfluencer({
        ...selectedInfluencer,
        providerName: value?.influencer?.firstName + " " + value?.lastName,
        personFk: value?.influencer?.id,
        category: value?.budget?.projectCategory || null,
        budgetId: value?.budget?.budgetId || null,
      });
    } else {
      setSelectedInfluencer({ ...selectedInfluencer, [field]: value });
    }
  };

  const handleSaveScheduleItem = () => {
    setIsSavingForm(true);
    axios
      .post(
        `/ScheduleItems/PostPoiTalentScheduleItemFromPrePost/${companyId}`,
        selectedInfluencer
      )
      .then(({ data }) => {
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Talent has been added.",
        });
        setPoiInfluencers([...poiInfluencers, data]);
        fetchAllBudget();
        setSelectedInfluencer(null);
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Something went wrong.",
        });
      })
      .finally(() => {
        setIsSavingForm(false);
      });
  };

  const updateScheduleItem = (scheduleItem) => {
    axios
      .patch(
        `/ScheduleItems/PatchScheduleItem/${scheduleItem.id}`,
        scheduleItem,
        {
          params: {
            user: user?.email,
          },
        }
      )
      .then(({ data }) => {
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Record has been updated.",
        });
        setPoiInfluencers(
          poiInfluencers.map((i) =>
            i.id === scheduleItem.id ? scheduleItem : i
          )
        );
        fetchAllBudget();
        setSelectedInfluencer(null);
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps({
          severity: "warning",
          text: "Hm, it looks like something went wrong.",
          open: true,
        });
      });
  };

  const handleChangeInvoiceMonth = (scheduleItem) => {
    updateScheduleItem(scheduleItem);
  };

  const handleOpenCreateNewInfluencer = () => {
    setOpenCreateNewInfluencer(true);
  };

  const handleCloseCreateNewInfluencer = () => {
    setOpenCreateNewInfluencer(false);
    setSelectedInfluencer(null);
  };

  const handleCreateNewInfluencer = async (influencer, ir330c = null) => {
    const influencerAlreadyRegistered = await axios.post(
      `Influencer/CheckIfExists`,
      influencer
    );
    if (influencerAlreadyRegistered?.data) {
      setSnackBarProps({
        open: true,
        severity: "warning",
        text: "This Influencer is already registered in our database.",
      });
    } else {
      axios
        .post(`Influencer/SaveInfluencer`, influencer)
        .then(({ data }) => {
          setSnackBarProps({
            open: true,
            severity: "success",
            text: "Your changes have been saved!",
          });
          if (ir330c !== null) {
            const reader = new FileReader();
            reader.onload = (e) => {
              // e.target.result contains the base64-encoded data
              const base64Data = e.target.result.split(",")[1];
              console.log(base64Data);
              let form = new FormData();
              form.append("ir330c", base64Data);
              axios
                .post(`PDF/PostInflucencerIR330C/${data.id}`, form, {
                  headers: { "content-type": "multipart/form-data" },
                })
                .catch((e) => console.log(e));
            };

            reader.readAsDataURL(ir330c);
          }
          handleCloseCreateNewInfluencer();
        })
        .catch((err) => {
          console.log(err);
          setSnackBarProps({
            open: true,
            severity: "warning",
            text: "Hm, it looks like something went wrong.",
          });
        });
    }
  };

  const handleOpenPdf = (e, scheduleItemId) => {
    setPdfUrl(`/invoice/GetXeroInvoiceAsPdfFromScheduleItem/${scheduleItemId}/influencer/5`);
    setShowDocumentViewer(true);
  };

  useEffect(() => {
    if(reloadReimbursementTable){{
      setReloadReimbursementTable(false);
    }}
  }, [reloadReimbursementTable])

  return (
    <div className="client-people-of-influence-root">
      <Snacky snackprops={snackBarProps} setSnackBarProps={setSnackBarProps} />
      {selectedInfluencer === null && (
        <AddNewPOIInfluencerButton
          handleClick={handleAddNewInfluencer}
          handleOpenCreateNewInfluencer={handleOpenCreateNewInfluencer}
          handleReimbursementClick={() => {
            setShowReimbursementPopup(true);
          }}
        />
      )}
      {showReimbursementPopup && (
        <PeopleOfInfluenceReimbursementPoup
          open={showReimbursementPopup}
          handleClose={() => { setShowReimbursementPopup(false) }}
          budgetItems={budgetItems}
          handleAferSave={() => {
            fetchAllBudget();
            setReloadReimbursementTable(true)
          }}
        />)}
      {showDocumentViewer && pdfUrl && (
        <GeneralDocumentViewer
          modalState={showDocumentViewer}
          handleModal={() => {
            setPdfUrl("");
            setShowDocumentViewer(false);
          }}
          headerColor="#000"
          docType={"PDF"}
          urlFile={pdfUrl}
        />
      )}
      {openCreateNewInfluencer && (
        <UpdatePoiInfluenceDialog
          selectedInfluencer={selectedInfluencer}
          open={openCreateNewInfluencer}
          handleClose={handleCloseCreateNewInfluencer}
          handleSubmit={handleCreateNewInfluencer}
        />
      )}
      {isSavingForm && <Skeleton height={150} />}
      {!isSavingForm && selectedInfluencer && (
        <PeopleOfInfluenceForm
          budgetItems={budgetItems}
          poiInfluencer={selectedInfluencer}
          handleOnChange={handleOnChange}
          handleSaveScheduleItem={handleSaveScheduleItem}
        />
      )}
      <ClientPeopleOfInfluenceTable
        isLoading={isLoading}
        poiInfluencers={poiInfluencers}
        handleSelectInfluencer={handleSelectInfluencer}
        removeScheduleItem={deleteScheduleItem}
        handleChangeInvoiceMonth={handleChangeInvoiceMonth}
        handleOpenPdf={handleOpenPdf}
        setPoiInfluencers={setPoiInfluencers}
        snackBarProps={snackBarProps}
        setSnackBarProps={setSnackBarProps}
        setSearchPaidInvoices={setSearchPaidInvoices}
        searchPaidInvoices={searchPaidInvoices}
      />
      {reloadReimbursementTable && <Skeleton height={150} />}
      {!reloadReimbursementTable && (
        <PeopleOfInfluenceReimbursementTable
          companyId={companyId}
        />
      )}
    </div>
  );
};

export default ClientPeopleOfInfluence;
