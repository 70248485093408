import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import "../BudgetTransfer/budgetTransfer.scss";

const CloseAndTransferBudgetPopup = ({
  open,
  handleClose,
  handleCloseAndTransfer,
  budgetByProject,
  groupedBudgetsByProjectId,
  handleCloseProjectWithLiability
}) => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [closeType, setCloseType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseAndTransferClick = () => {
    var budgetsToTransferForPayload = [];
    for (var i = 0; i < budgetByProject.length; i++) {
      if (budgetByProject[i].remaining > 0) {
        budgetsToTransferForPayload.push({
          id: budgetByProject[i].budgetId,
          remaining: budgetByProject[i].remaining
        })
      }
    }

    if (budgetsToTransferForPayload.length > 0) {
      handleCloseAndTransfer({
        fromProject: budgetByProject[0].projectId,
        toProject: selectedProject[0].projectId,
        budgetsToTransfer: budgetsToTransferForPayload
      })
    }
  }

  const SelectionSection = () => {
    return (
      <React.Fragment>
        <DialogContent>
          <DialogContentText style={{ textAlign: "center" }}>
            <Typography className="roboto-regular">It looks like there is still some budget remaining. What would you like to do with the remaining budget?</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {isLoading ? <div style={{ padding: 10 }}><CircularProgress /></div> :
            <div className="select-section-action-container">
              <Button className="default-button roboto-regular"
                disabled={isLoading}
                onClick={(e) => {
                  setIsLoading(true);
                  handleCloseProjectWithLiability(budgetByProject[0].projectId);
                }}>
                Close Project
              </Button>
              <Button className="default-button roboto-regular"
                disabled={isLoading}
                onClick={(e) => {
                  setCloseType("Transfer");
                }}>
                Transfer to another project
              </Button>
            </div>}
        </DialogActions>
      </React.Fragment>
    )
  }

  const TransferSection = () => {
    return (
      <React.Fragment>
        <DialogContent>
          <DialogContentText style={{ textAlign: "center" }}>
            <Typography className="roboto-regular">Please select the project you would like to transfer the remaining budget to</Typography>
          </DialogContentText>
          <div>
            <Autocomplete
              value={selectedProject}
              options={groupedBudgetsByProjectId}
              getOptionLabel={(option) => option[0].projectName}
              getOptionDisabled={(option) => option[0].projectName === budgetByProject[0].projectName}
              onChange={(e, newValue) => {
                setSelectedProject(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="roboto-regular"
                  label="Project"
                  size="small"
                  fullWidth={true}
                  variant="filled"
                />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions style={{ textAlign: "center" }}>
          {isLoading ? <div style={{ padding: 10 }}><CircularProgress /></div> :
            <React.Fragment>
              <Button className="default-button-grey roboto-regular" onClick={(e) => {
                setCloseType(null);
              }}>
                Go Back
              </Button>
              <Button className="default-button roboto-regular" onClick={(e) => {
                setIsLoading(true);
                handleCloseAndTransferClick();
              }}>
                Transfer & Close
              </Button>
            </React.Fragment>}
        </DialogActions>
      </React.Fragment>
    )
  }

  return (
    <Dialog
      open={open}
      id="budget-close-and-transfer-dialog"
      onClose={(e, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}>
      <Grid container>
        <Grid item xs={1} md={1} lg={1}></Grid>
        <Grid item xs={10} md={10} lg={10}>
          <DialogTitle style={{ textAlign: "center" }} className="roboto-regular">
            {closeType === null ? "Please select an option" : "Transfer Budget"}
          </DialogTitle>
        </Grid>
        <Grid item xs={1} md={1} lg={1}>
          <div className="close-and-transfer-dialog-close-icon-container">
            <IconButton size="small" className="close-and-transfer-dialog-close-icon"
              onClick={() => {
                if (!isLoading) {
                  handleClose();
                }
              }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
        </Grid>
      </Grid>
      {closeType === null && SelectionSection()}
      {closeType === "Transfer" && TransferSection()}
    </Dialog>
  );
};

export default CloseAndTransferBudgetPopup;
