import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { setSnackBarProps } from "../../../../actions/snackbar";
import {
  spliceProjectActiveItem,
  editProjectActiveItem,
  closeProjectAndSendAllInvoices,
  resetActiveItemStore,
} from "../../../../actions/closeProject";
import { Modal, Button, AppBar, Typography } from "@material-ui/core";
import "../Outputs.scss";
import { InvoicesModalTable } from "./InvoicesModalTable";
import { ContractsModalTable } from "./ContractsModalTable";
import { setLoaderProps } from "../../../../actions/loader";
import { useUserContext } from "../../../../context/user/UserContext";
import { useAuth } from "../../../../context/auth/AuthContext";

const CloseProjectModal = (props) => {
  let {
    openActiveItemsModal,
    setOpenActiveItemsModal,
    setOpenContactDetails,
    setCloseProject,
    activeItems,
    setActiveItem,
    setContact,
    setLoaderProps,
    setLocation,
    setOpenLocationDetails,
    closeProjectAndSendAllInvoices,
    spliceProjectActiveItem,
    editProjectActiveItem,
    preArchiveCheck,
  } = props;
  const [ignoredItems, setIgnoredItems] = useState([]);
  const { user } = useAuth();
  useEffect(() => {
    if (activeItems.length === 0) {
      setCloseProject(false);
      setOpenActiveItemsModal(false);
    }
  }, [activeItems]);
  const handleShowPersonDetails = (item) => {
    if (item.category != "Location Details") {
      setActiveItem({ id: item.id, category: item.category });
      setContact({ id: item.personFk });
      setOpenContactDetails(true);
    } else {
      openLocationDetails(item.locationId);
    }
  };
  const handleIgnoreItem = (id) => {
    if (ignoredItems.indexOf(id) === -1) {
      setIgnoredItems([...ignoredItems, id]);
    } else {
      setIgnoredItems(ignoredItems.filter((itemId) => itemId !== id));
    }
  };

  const openLocationDetails = (locationId) => {
    axios
      .get(`Locations/GetLocation/${locationId}`)
      .then((res) => {
        console.log(res);
        setLocation(res.data);
        setOpenLocationDetails(true);
      })
      .catch((e) => {
        console.log("Fetch Location Failed");
        console.log(e);
      });
  };
  const handleArchiveAnyway = () => {
    setLoaderProps(true);
    closeProjectAndSendAllInvoices(
      activeItems,
      {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email
      });
    resetActiveItemStore();
    setOpenActiveItemsModal(false);
    setCloseProject(false);
    preArchiveCheck();
  };
  return (
    <Modal
      open={openActiveItemsModal}
      onClose={() => {
        setOpenActiveItemsModal(false);
        setCloseProject(false);
      }}
      className="active-items-modal"
    >
      <div className="active-items-modal-body">
        <AppBar position="static" className="active-items-modal-title">
          <Typography align="center">Active Items</Typography>
        </AppBar>
        <InvoicesModalTable
          activeItems={activeItems.filter(
            (item) => item.contractStatus !== "Pending"
          )}
          setActiveItem={setActiveItem}
          handleIgnoreItem={handleIgnoreItem}
          ignoredItems={ignoredItems}
          handleShowPersonDetails={handleShowPersonDetails}
          setContact={setContact}
          setLocation={setLocation}
        />
        <ContractsModalTable
          activeItems={activeItems.filter(
            (item) => item.contractStatus === "Pending"
          )}
          editProjectActiveItem={editProjectActiveItem}
          spliceProjectActiveItem={spliceProjectActiveItem}
          setContact={setContact}
          handleShowPersonDetails={handleShowPersonDetails}
        />
        <div className="active-items-modal-button-panel">
          <Button
            onClick={() => {
              setOpenActiveItemsModal(false);
              setCloseProject(false);
            }}
            color="primary"
            style={{ margin: 5 }}
          >
            Cancel Archive
          </Button>
          <Button
            disabled={
              activeItems.filter((i) => i.contractStatus === "Pending").length >
              0
            }
            onClick={() => {
              handleArchiveAnyway();
            }}
            style={
              activeItems.filter((i) => i.contractStatus === "Pending").length >
                0
                ? {
                  backgroundColor: "rgb(0,128,0,.5)",
                  color: "gray",
                  margin: 5,
                  cursor: "pointer",
                }
                : {
                  backgroundColor: "green",
                  color: "white",
                  margin: 5,
                  cursor: "pointer",
                }
            }
            autoFocus
          >
            Archive and Send
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state, ownProps) => ({
  activeItems: state.closeProject.activeItemsForProject,
  unsignedContracts: state.closeProject.unsignedContracts,
  snackbar: state.snackbar,
});
// closeProjectAndSendAllInvoices
export default connect(mapStateToProps, {
  setSnackBarProps,
  setLoaderProps,
  spliceProjectActiveItem,
  editProjectActiveItem,
  closeProjectAndSendAllInvoices,
})(CloseProjectModal);
