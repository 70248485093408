import {
  Button,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState, useCallback, useRef } from "react";
import "./mediaSpendAllocation.scss";
import { Autocomplete } from "@material-ui/lab";
import { generateUniqueHash, isEmpty } from "../../../utils/GeneralUtils";
import MediaSpendAllocationTable from "./MediaSpendAllocationTable";
import axios from "axios";
import MediaCampaignFormDialog from "./MediaCampaignFormDialog";
import MediaCampaignInvoice from "./MediaInvoice/MediaCampaignInvoice";
import debounce from "lodash/debounce";
import TvSpotlistDialog from "./TvSpotlist/TvSpotlistDialog";
import { isInternationalClient } from "../../../utils/utils";
import MediaForeignCurrencyNote from "./MediaForeignCurrencyNote";
const MediaCampaigns = ({
  budgetItems,
  companyId,
  setSnackBarProps,
  fetchAllBudget,
  organisationId,
  company,
}) => {
  const debounceTimeoutRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSavingCampaign, setIsSavingCampaign] = useState(false);
  const [originalMediaCampaigns, setOriginalMediaCampaigns] = useState([]);
  const [mediaCampaigns, setMediaCampaigns] = useState([]);
  const [mediaSuppliers, setMediaSuppliers] = useState([]);
  const [openMediaCampaignFormDialog, setOpenMediaCampaignFormDialog] =
    useState(false);
  const [selectedMediaCampaign, setSelectedMediaCampaign] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [openTvSpotlistDialog, setOpenTvSpotlistDialog] = useState(false);

  useEffect(() => {
    searchMediaCampaigns();
    getMediaSuppliers();
  }, []);

  const getMediaSuppliers = () => {
    axios
      .get(`/mediasuppliers/GetMediaSuppliers`)
      .then(({ data }) => {
        setMediaSuppliers(data);
      })
      .catch((err) => console.log(err));
  };

  const searchMediaCampaigns = () => {
    //make request to backend by companyId
    setIsLoading(true);
    axios
      .get(`/mediacampaign/GetMediaCampaigns/${companyId}`)
      .then(({ data }) => {
        setOriginalMediaCampaigns(data);
        setMediaCampaigns(data);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleCloseFormDialog = () => {
    setSelectedMediaCampaign(null);
    setOpenMediaCampaignFormDialog(false);
  };

  const handleAddNewMedia = () => {
    setSelectedMediaCampaign({
      name: "",
      companyId: companyId,
    });
    setOpenMediaCampaignFormDialog(true);
  };

  const handleSaveMediaCampaign = () => {
    setIsSavingCampaign(true);
    axios
      .post(`/mediacampaign/UpdateMediaCampaign`, selectedMediaCampaign)
      .then(async ({ data }) => {
        if (selectedMediaCampaign?.id) {
          await fetchAllBudget();
          setOriginalMediaCampaigns(
            originalMediaCampaigns.map((mc) => (mc.id === data.id ? data : mc))
          );
          setMediaCampaigns(
            mediaCampaigns.map((mc) => (mc.id === data.id ? data : mc))
          );
        } else {
          setOriginalMediaCampaigns([...originalMediaCampaigns, data]);
          setMediaCampaigns([...mediaCampaigns, data]);
        }

        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Great! Your changes have been saved.",
        });
        handleCloseFormDialog();
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to save changes",
        });
      })
      .finally(() => setIsSavingCampaign(false));
  };

  const handleSelectCampaign = (campaign) => {
    setSelectedMediaCampaign(campaign);
    setOpenMediaCampaignFormDialog(true);
  };

  const handleUpdateMediaSpend = (mediaCampaignId, updatedMediaSpend) => {
    updateOriginalMediaCampaigns(mediaCampaignId, updatedMediaSpend);
    updateFilteredMediaCampaigns(mediaCampaignId, updatedMediaSpend);
  };

  const updateFilteredMediaCampaigns = (mediaCampaignId, updatedMediaSpend) => {
    // Find the index of the media campaign in the mediaCampaigns array
    const campaignIndex = mediaCampaigns.findIndex(
      (campaign) => campaign.id === mediaCampaignId
    );

    if (campaignIndex !== -1) {
      // Get a copy of the media campaign object
      const updatedCampaign = { ...mediaCampaigns[campaignIndex] };

      // Find the index of the media spend record in the mediaSpend array
      const spendIndex = updatedCampaign.mediaSpend.findIndex(
        (spend) => spend.id === updatedMediaSpend.id
      );

      if (spendIndex !== -1) {
        // Update the existing media spend record
        updatedCampaign.mediaSpend[spendIndex] = updatedMediaSpend;
      } else {
        // Add the new media spend record to the mediaSpend array
        updatedCampaign.mediaSpend.push(updatedMediaSpend);
      }

      // Update the media campaign in the mediaCampaigns array
      const updatedMediaCampaigns = [...mediaCampaigns];
      updatedMediaCampaigns[campaignIndex] = updatedCampaign;

      // Update the state with the updated media campaigns
      setMediaCampaigns(updatedMediaCampaigns);
    }
  };
  const updateOriginalMediaCampaigns = (mediaCampaignId, updatedMediaSpend) => {
    // Find the index of the media campaign in the originalMediaCampaigns array
    const campaignIndex = originalMediaCampaigns.findIndex(
      (campaign) => campaign.id === mediaCampaignId
    );

    if (campaignIndex !== -1) {
      // Get a copy of the media campaign object
      const updatedCampaign = { ...originalMediaCampaigns[campaignIndex] };

      // Find the index of the media spend record in the mediaSpend array
      const spendIndex = updatedCampaign.mediaSpend.findIndex(
        (spend) => spend.id === updatedMediaSpend.id
      );

      if (spendIndex !== -1) {
        // Update the existing media spend record
        updatedCampaign.mediaSpend[spendIndex] = updatedMediaSpend;
      } else {
        // Add the new media spend record to the mediaSpend array
        updatedCampaign.mediaSpend.push(updatedMediaSpend);
      }

      // Update the media campaign in the mediaCampaigns array
      const updatedMediaCampaigns = [...originalMediaCampaigns];
      updatedMediaCampaigns[campaignIndex] = updatedCampaign;

      // Update the state with the updated media campaigns
      setOriginalMediaCampaigns(updatedMediaCampaigns);
    }
  };

  const handleKeywordChange = (value) => {
    const newKeyword = value;
    setKeyword(newKeyword);

    // Clear the existing debounce timeout
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    // Set a new debounce timeout
    debounceTimeoutRef.current = setTimeout(() => {
      filterCampaigns(newKeyword);
    }, 300);
  };
  // Function to filter the campaigns
  const filterCampaigns = (keyword) => {
    if (keyword) {
      const filteredCampaigns = originalMediaCampaigns.filter(
        (campaign) =>
          (campaign.name &&
            campaign.name.toLowerCase().includes(keyword.toLowerCase())) ||
          (campaign.poNumber &&
            campaign.poNumber.toLowerCase().includes(keyword.toLowerCase())) ||
          (campaign.mediaSupplierId &&
            mediaSuppliers.length > 0 &&
            mediaSuppliers.find(
              (supplier) =>
                supplier.id === campaign.mediaSupplierId &&
                supplier.supplierName
                  .toLowerCase()
                  .includes(keyword.toLowerCase())
            ))
      );
      setMediaCampaigns(filteredCampaigns);
    } else {
      setMediaCampaigns(originalMediaCampaigns);
    }
  };

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, []);

  const handleOpenTvSpotlistDialog = () => {
    setOpenTvSpotlistDialog(true);
  };

  const handleCloseTvSpotlistDialog = () => {
    setOpenTvSpotlistDialog(false);
  };

  return (
    <div className="media-campaigns-root">
      {openTvSpotlistDialog && (
        <TvSpotlistDialog
          open={openTvSpotlistDialog}
          handleClose={handleCloseTvSpotlistDialog}
          company={company}
          companyId={companyId}
        />
      )}
      {openMediaCampaignFormDialog && selectedMediaCampaign && (
        <MediaCampaignFormDialog
          open={openMediaCampaignFormDialog}
          handleClose={handleCloseFormDialog}
          setSelectedMediaCampaign={setSelectedMediaCampaign}
          selectedMediaCampaign={selectedMediaCampaign}
          mediaSuppliers={mediaSuppliers}
          handleSubmit={handleSaveMediaCampaign}
          isSavingCampaign={isSavingCampaign}
          mediaCampaigns={mediaCampaigns}
          budgetItems={budgetItems}
        />
      )}
      <div className="media-campaigns-section-paper">
        <div>
          <Typography>{`Media Planner`}</Typography>
          {isInternationalClient(company) && (
            <MediaForeignCurrencyNote currency={company.currency} fontSize={12}/>
          )}
        </div>
        <MediaSpendAllocationTable
          mediaCampaigns={mediaCampaigns}
          mediaSuppliers={mediaSuppliers}
          isLoading={isLoading}
          handleSelectCampaign={handleSelectCampaign}
          budgetItems={budgetItems}
          setSnackBarProps={setSnackBarProps}
          handleUpdateMediaSpend={handleUpdateMediaSpend}
          fetchAllBudget={fetchAllBudget}
          handleAddNewMedia={handleAddNewMedia}
          company={company}
          handleKeywordChange={handleKeywordChange}
          keyword={keyword}
          originalMediaCampaigns={originalMediaCampaigns}
          handleOpenTvSpotlistDialog={handleOpenTvSpotlistDialog}
        />
      </div>

      <div className="media-campaigns-section-paper">
        <Typography>Invoicing</Typography>
        <MediaCampaignInvoice
          companyId={companyId}
          organisationId={-1}
          company={company}
        />
      </div>
    </div>
  );
};

export default MediaCampaigns;
