import React from "react";
import CompanyFinancialForm from "./CompanyFinancialForm";
import ContractorFinancialForm from "./ContractorFinancialForm";
import { Switch, TextField, Typography } from "@material-ui/core";

const PersonFinancialForm = ({
  person,
  bankAccountDetails,
  handleBankDetailsChange,
  isEditing,
  invoiceAs,
}) => {
  return (
    <div className="person-financial-tab-root">
      <div className="person-financial-tab-flex-container">
        <TextField
          label={invoiceAs === "contractor" ? "Account Name" : "Company Name"}
          value={bankAccountDetails?.accountName || ""}
          disabled={!isEditing}
          variant="outlined"
          fullWidth
          onChange={(e) => {
            handleBankDetailsChange("accountName", e.target.value);
          }}
        />
        <TextField
          label="Bank Account Number"
          value={bankAccountDetails?.accountNumber || ""}
          disabled={!isEditing}
          variant="outlined"
          fullWidth
          onChange={(e) => {
            handleBankDetailsChange("accountNumber", e.target.value);
          }}
        />
        {person?.currency === "AUD" && (
          <TextField
            label="BSB - Bank State Branch"
            value={bankAccountDetails?.branchCode || ""}
            disabled={!isEditing}
            variant="outlined"
            fullWidth
            onChange={(e) => {
              handleBankDetailsChange("branchCode", e.target.value);
            }}
          />
        )}
      </div>
      {person?.currency === "NZD" && (
        <>
          <div className="person-financial-tab-flex-container person-financial-tab-flex-container-extra-gap">
            <Typography variant="h6">Is GST Registered?:</Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={
                  bankAccountDetails?.isTaxRegistered
                    ? { color: "grey" }
                    : {
                        borderColor: isEditing ? "#3f51b5" : "grey",
                      }
                }
              >
                No
              </div>
              <div>
                <Switch
                  color="primary"
                  checked={!!bankAccountDetails?.isTaxRegistered}
                  name="bcOrIc"
                  disabled={isEditing ? false : true}
                  onChange={(e) =>
                    handleBankDetailsChange(
                      "isTaxRegistered",
                      e.target.checked ? 1 : 0
                    )
                  }
                  className={isEditing ? "blueSwitch" : ""}
                />
              </div>
              <div
                style={
                  bankAccountDetails?.isTaxRegistered
                    ? {
                        borderColor: isEditing ? "#3f51b5" : "grey",
                      }
                    : { color: "grey" }
                }
              >
                Yes
              </div>
            </div>
          </div>
          <div className="person-financial-tab-flex-container">
            {(invoiceAs === "contractor" ||
              !!bankAccountDetails?.isTaxRegistered) && (
              <TextField
                label={invoiceAs === "contractor" ? "IRD" : "GST Number"}
                value={bankAccountDetails?.taxNumber || ""}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  handleBankDetailsChange("taxNumber", e.target.value);
                }}
              />
            )}
            {invoiceAs === "contractor" && (
              <TextField
                label={"WT Rate"}
                value={bankAccountDetails?.wtRate}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  handleBankDetailsChange("wtRate", e.target.value);
                }}
              />
            )}
          </div>
        </>
      )}

      {invoiceAs === "contractor" && (
        <ContractorFinancialForm
          isEditing={isEditing}
          person={person}
          bankAccountDetails={bankAccountDetails}
          handleBankDetailsChange={handleBankDetailsChange}
        />
      )}
    </div>
  );
};

export default PersonFinancialForm;
