export const getDefaultAvatar = (influencer) => {
  if (influencer?.socialMediaProfile.length > 0) {
    var av = influencer.socialMediaProfile[0].avatar;
    if (influencer.primarySocial == "INSTAGRAM") {
      var foundProfile = influencer.socialMediaProfile.find(
        (s) => s.application === "INSTAGRAM"
      );
      if (foundProfile != null) {
        av = foundProfile.avatar;
      }
    } else if (influencer.primarySocial == "TIKTOK") {
      var foundProfile = influencer.socialMediaProfile.find(
        (s) => s.application === "TIKTOK"
      );
      if (foundProfile != null) {
        av = foundProfile.avatar;
      }
    }
    return av;
  } else {
    return "https://source.boringavatars.com/beam";
  }
};
