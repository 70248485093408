import React, { useEffect, useState } from "react";
import "./approvalRequestForm.scss";
import { Button, TextField } from "@material-ui/core";
import EmailEditor from "../../../SendSchedule/EmailEditor";
import ProcessingSpinner from "../../../../../Shared/Spinner/ProcessingSpinner";
import axios from "axios";
import { isEmpty } from "../../../../../../utils/GeneralUtils";
import { useUserContext } from "../../../../../../context/user/UserContext";
import { getFirstName } from "../../../../../../utils/PersonUtils";
import { useAuth } from "../../../../../../context/auth/AuthContext";

const ApprovalRequestForm = ({
  project,
  setSnackBarProps,
  scheduleId,
  handleCloseForm,
  handleApprovalRequestSentCallback
}) => {
  const { user } = useAuth();
  const [emailTemplate, setEmailTemplate] = useState(null);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [recipientEmails, setRecipientEmails] = useState("");
  const [recipientName, setRecipientName] = useState("");

  useEffect(() => {
    getEmailTemplate();
    setRecipientEmails(project?.contactEmail);
    setRecipientName(getFirstName(project?.contactName));
    console.log(project);
  }, []);

  const getEmailTemplate = () => {
    axios
      .get(`HtmlTemplate/GetHtmlTemplate/RequestClientApproval`)
      .then(({ data }) => {
        setEmailTemplate(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSendEmail = () => {
    setIsSendingEmail(true);
    axios
      .post(
        `Influencer/SendApprovalRequestEmail/${project?.id}/${scheduleId}/${project.token}`,
        {
          contactEmail: recipientEmails,
          contactFirstName: recipientName,
          body: emailTemplate.template,
          subject: emailTemplate.name,
          userEmail: user.email,
          userFirstName: user.firstName,
          userLastName: user.lastName,
        }
      )
      .then(({ data }) => {
        if (data && data.length > 0) {
          setSnackBarProps({
            open: true,
            severity: "success",
            text: "Email Sent! Fingers crossed for a positive response!",
          });
          handleApprovalRequestSentCallback(data)
          handleCloseForm();
        } else {
          setSnackBarProps({
            open: true,
            severity: "warning",
            text: "Oops! Email Delivery Failed. Please double-check the recipient's email address and try again. If the issue persists, reach out to our support team for assistance.",
          });
        }
      })
      .catch((e) => {
        console.log(e);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Oops! Email Delivery Failed. Please double-check the recipient's email address and try again. If the issue persists, reach out to our support team for assistance.",
        });
      })
      .finally(() => setIsSendingEmail(false));
  };

  return (
    <div>
      {isSendingEmail ? (
        <ProcessingSpinner duration={60} unitTime={"seconds"} />
      ) : (
        <div className="approval-request-form-content-container">
          <div className="approval-request-form-inputs-wrapper">
            <TextField
              fullWidth
              label="Recipient Name"
              onChange={(e) => setRecipientName(e.target.value?.trim())}
              value={recipientName}
              variant="filled"
            />
            <TextField
              fullWidth
              label="Client Email - Separate by , (comma) to add multiple emails"
              onChange={(e) => setRecipientEmails(e.target.value)}
              value={recipientEmails}
              variant="filled"
              onBlur={(e) =>
                setRecipientEmails(e.target.value?.replace(/,/g, ", "))
              }
            />
          </div>
          <div>
            {emailTemplate && (
              <EmailEditor
                emailBodyHtml={emailTemplate?.template}
                emailSubject={emailTemplate?.name}
                setEmailBodyHtml={(value) =>
                  setEmailTemplate({ ...emailTemplate, template: value })
                }
                setEmailSubject={(value) =>
                  setEmailTemplate({ ...emailTemplate, name: value })
                }
              />
            )}
          </div>
        </div>
      )}
      <div className="approval-request-form-footer-container">
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={handleSendEmail}
          disabled={isSendingEmail || isEmpty(recipientEmails)}
        >
          Send Now
        </Button>
      </div>
    </div>
  );
};

export default ApprovalRequestForm;
