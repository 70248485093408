import React, { useState } from "react";
import { format } from "date-fns";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from "@material-ui/core";
import "../Outputs.scss";
import ContractButton from "../../Schedule/ScheduleItemTable/ContractButton";
import LocationContractButton from "../../Schedule/ScheduleLocation/LocationContractButton";

export const ContractsModalTable = (props) => {
  let {
    activeItems,
    handleShowPersonDetails,
    spliceProjectActiveItem,
    editProjectActiveItem,
  } = props;
  return (
    <div
      style={{ display: activeItems.length === 0 && "none" }}
      className="table-red"
    >
      <div
        className="title-container"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "5px",
        }}
      >
        <Typography className="active-items-modal-text" align="center">
          This project has some contracts that have not been signed.
        </Typography>
      </div>
      <Table className="active-items-modal-table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Person</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Due</TableCell>
            <TableCell>Fee</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activeItems.length > 0 &&
            activeItems.map((item, index) => {
              return (
                <TableRow key={item.id}>
                  <TableCell
                    className={item.category != "Catering" && "clickable"}
                    onClick={() => handleShowPersonDetails(item)}
                  >
                    {item.providerName}
                  </TableCell>
                  <TableCell>{item.category}</TableCell>
                  <TableCell>
                    {format(new Date(item.due), "dd/MM/yyyy")}
                  </TableCell>
                  <TableCell>${item.allocatedRates}</TableCell>
                  <TableCell>
                    {item.locationId !== null ? (
                      <LocationContractButton
                        item={item}
                        sl={item}
                        spliceProjectActiveItem={spliceProjectActiveItem}
                        editProjectActiveItem={editProjectActiveItem}
                        parentIsClosingProject={true}
                      />
                    ) : (
                      <ContractButton
                        providerType={item.category}
                        si={item}
                        spliceProjectActiveItem={spliceProjectActiveItem}
                        editProjectActiveItem={editProjectActiveItem}
                        parentIsClosingProject={true}
                      />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </div>
  );
};
