import React, { useState, useEffect } from "react";
import TableFilter from "react-table-filter";
import { format } from "date-fns";
import "react-table-filter/lib/styles.css";
import "./MediaAds.css";
import _ from "lodash";
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Button,
    Tooltip,
    Checkbox,
    TextField,
    InputAdornment,
    Typography
} from "@material-ui/core";
import {
    Search as SearchIcon
} from '@material-ui/icons';
import {
    numberWithThousandsSeperator
} from "../../../utils/utils";
import EditMediaAdModal from "./EditMediaAd";
import MediaAdsButton from "./MediaAdsButton";
import DuplicatesModal from "./DuplicatesModal";
import NewMediaSupplierModal from "./NewMediaSupplierModal";
import { Check } from "@material-ui/icons";
import PropTypes from "prop-types";

const headCells = [
    {
        id: "checkbox",
        label: "",
        cssClass: "media-ads-table-checkbox-header",
        textAlign: "left"
    },
    {
        id: "client",
        label: "Client",
        cssClass: "media-ads-table-client-header-cell",
        textAlign: "left"
    },
    {
        id: "platform",
        label: "Platform",
        cssClass: "media-ads-table-platform-header-cell",
        textAlign: "left"
    },
    {
        id: "transactionId",
        label: "Reference",
        cssClass: "media-ads-table-reference-header-cell",
        textAlign: "left"
    },
    {
        id: "transactionDate",
        label: "Transaction Date",
        cssClass: "media-ads-table-transaction-date-header-cell",
        textAlign: "left"
    },
    {
        id: "amount",
        label: "NZD",
        cssClass: "media-ads-table-amount-header-cell",
        textAlign: "left"
    },
    {
        id: "type",
        label: "Type",
        cssClass: "media-ads-table-type-header-cell",
        textAlign: "left"
    },
    {
        id: "driveId",
        label: "Document",
        cssClass: "media-ads-table-document-header-cell",
        textAlign: "center"
    },
    {
        id: "status",
        label: "Status",
        cssClass: "",
        textAlign: "center"
    },
];

function MemoizedTableBodyPropsAreEqual(prevState, nextState) {
    return _.isEqual(prevState.adList, nextState.adList)
        && _.isEqual(prevState.initialFilters, nextState.initialFilters)
        && _.isEqual(prevState.checkedAll, nextState.checkedAll)
        && _.isEqual(prevState.checkedMediaAds, nextState.checkedMediaAds);
}

const MemoizedTableBody = React.memo(function MemoizedTableBody(props) {
    const {
        adList,
        duplicates,
        updateMediaAd,
        setTotalDueOnScreen,
        getButton,
        setRowUpdating,
        setOpenNewSupplierModal,
        setSelectedAd,
        initialFilters,
        checkedMediaAds,
        addCheckedAd,
        removeCheckedAd,
        checkedAll
    } = props;

    var totalDue = 0.0;

    return (
        <TableBody className="media-ads-table-body" >
            {
                adList.length > 0
                    ? adList.map((mediaAd, index) => {
                        var hasDuplicate = duplicates.includes(mediaAd.transactionId);

                        if (mediaAd.platform === "Google Adword") {
                            hasDuplicate = false;
                            for (var i = 0; i < adList.length; i++) {
                                if (adList[i].transactionId === mediaAd.transactionId && adList[i].id !== mediaAd.id
                                    && adList[i].client === mediaAd.client) {
                                    hasDuplicate = true;
                                    break;
                                }
                            }
                        }

                        if (mediaAd.amount !== null) {
                            totalDue = totalDue + parseFloat(mediaAd.amount);
                            setTotalDueOnScreen(totalDue);
                        }

                        return (
                            <TableRow
                                key={`${mediaAd.id}-media-ad-table-row`}
                                hover={true}
                                className={hasDuplicate ? "media-ad-table-row-orange" : ""}
                            >
                                <TableCell align="left" className="media-ad-table-body-checkbox">
                                    <Checkbox
                                        checked={checkedMediaAds.indexOf(mediaAd.id) > -1}
                                        onChange={e => e.target.checked ? addCheckedAd(mediaAd.id) : removeCheckedAd(mediaAd.id)}
                                    />
                                </TableCell>
                                <TableCell align="left" className="media-ad-table-body-cell">{mediaAd.client}</TableCell>
                                <TableCell align="left" className="media-ad-table-body-cell">{mediaAd.platform}</TableCell>
                                <TableCell align="left" className="media-ad-table-body-cell">{mediaAd.transactionId}</TableCell>
                                <TableCell align="left" className="media-ad-table-body-cell">
                                    {mediaAd.transactionDate !== null && format(new Date(mediaAd.transactionDate), "d LLL yyyy")}
                                </TableCell>
                                <TableCell align="left" className="media-ad-table-body-cell">
                                    {mediaAd.amount !== null ? `$${numberWithThousandsSeperator(mediaAd.amount)}` : "N/A"}
                                </TableCell>
                                <TableCell align="left" className="media-ad-table-body-cell">{mediaAd.type}</TableCell>
                                <TableCell align="center" className="media-ad-table-body-cell">
                                    {getButton(mediaAd, "view")}
                                </TableCell>
                                <TableCell align="center" className="media-ad-table-body-cell">
                                    {mediaAd.platform !== "No Supplier Found" ? (
                                        <MediaAdsButton
                                            mediaAd={mediaAd}
                                            setSnackBarProps={props.setSnackBarProps}
                                            updateMediaAd={(value, key, receipt) => {
                                                updateMediaAd(value, key, receipt);
                                                setRowUpdating(true);
                                            }}
                                        />
                                    ) : (
                                        <Button
                                            variant="contained"
                                            style={{
                                                backgroundColor: "#702963",
                                                color: "white"
                                            }}
                                            onClick={() => {
                                                setOpenNewSupplierModal(true)
                                                setSelectedAd(mediaAd)
                                            }}
                                        >
                                            Create Xero Contact
                                        </Button>
                                    )}
                                    {hasDuplicate && getButton(mediaAd, "duplicate")}
                                </TableCell>
                            </TableRow>
                        )
                    })
                    : <React.Fragment></React.Fragment>
            }
        </ TableBody>
    )
}, MemoizedTableBodyPropsAreEqual);

MemoizedTableBody.propTypes = {
    adList: PropTypes.array.isRequired,
    duplicates: PropTypes.array.isRequired,
    updateMediaAd: PropTypes.func.isRequired,
    setTotalDueOnScreen: PropTypes.func.isRequired,
    getButton: PropTypes.func.isRequired,
    setRowUpdating: PropTypes.func.isRequired,
    setOpenNewSupplierModal: PropTypes.func.isRequired,
    setSelectedAd: PropTypes.func.isRequired,
    initialFilters: PropTypes.object.isRequired
};

const MediaAdsTable = (props) => {
    const [adList, setAdList] = useState()
    const [duplicates, setDuplicates] = useState();
    const [selectedAd, setSelectedAd] = useState(null);
    const [openDoc, setOpenDoc] = useState(false);
    const [rowUpdating, setRowUpdating] = useState(false);
    const [openDuplicatesModal, setOpenDuplicatesModal] = useState(false);
    const [openNewSupplierModal, setOpenNewSupplierModal] = useState(false);
    const [checkedAll, setCheckedAll] = useState(false);

    const [searchValue, setSearchValue] = useState(props.defaultSearchValue);
    const [filteredMediaAds, setFilteredMediaAds] = useState([]);
    const [usedDefaultFilter, setUsedDefaultFilter] = useState(false);

    const handleHeadCell = (cell) => {
        if (cell.id === "transactionDate") {//format date for filter
            return (
                <th
                    filterkey={cell.id}
                    className={`media-ads-table-header-cell ${cell.cssClass}`}
                    itemDisplayValueFunc={(itemValue) => {
                        if (itemValue !== null) {
                            return itemValue.toDateString();
                        }
                    }}
                    itemSortValueFunc={(itemValue) => {
                        if (itemValue !== "(blank)") {
                            return itemValue.toDateString();
                        }
                    }}
                    alignleft="true"
                >
                    <div style={{ textAlign: cell.textAlign }}>{cell.label}</div>
                </th>
            );
        }
        else if (cell.id === "gstRateCode") {
            return (
                <th
                    filterkey={cell.id}
                    className={`media-ads-table-header-cell ${cell.cssClass}`}
                    itemDisplayValueFunc={(itemValue) => {
                        return itemValue === "OUTPUT2" || itemValue === "INPUT2" ? "GST Inc." : "No GST"
                    }}
                    alignleft="true"
                >
                    <div style={{ textAlign: cell.textAlign }}>{cell.label}</div>
                </th>
            );
        }
        else if (cell.id === "driveId" || cell.id === "status") {//No Filter
            return (
                <th
                    className={`media-ads-table-header-cell ${cell.cssClass}`}
                    key={`${cell.id}-media-ads-table-header`}
                    alignleft="true"
                >
                    <div style={{ textAlign: cell.textAlign }}>{cell.label}</div>
                </th>
            );
        }
        else if (cell.id === "checkbox") {
            return (
                <th
                    className={`media-ads-table-header-cell ${cell.cssClass}`}
                    key={`${cell.id}-media-ads-table-header`}
                    alignleft="true"
                >
                    <div style={{ textAlign: cell.textAlign }}>
                        <Checkbox
                            checked={checkedAll}
                            onChange={e => {
                                if (e.target.checked) {
                                    setCheckedAll(true);
                                }
                                else if (!e.target.checked) {
                                    setCheckedAll(false);
                                }
                            }}
                        />
                    </div>
                </th>
            );
        }
        else {
            return (
                <th
                    className={`media-ads-table-header-cell ${cell.cssClass}`}
                    key={`${cell.id}-media-ads-table-header`}
                    filterkey={cell.id}
                    alignleft="true"
                >
                    <div style={{ textAlign: cell.textAlign }}>{cell.label}</div>
                </th>
            );
        }
    }

    const getButton = (mediaAd, type) => {
        if (type === "view") {
            return (
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        setSelectedAd(mediaAd);
                        setOpenDoc(true);
                    }}
                >
                    View
                </Button>
            )
        }
        else if (type === "duplicate") {
            return (
                <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                        setSelectedAd(mediaAd);
                        setOpenDuplicatesModal(true)
                    }}
                    style={{ marginTop: "10px" }}
                >
                    View Duplicates
                </Button>
            )
        }
    }

    useEffect(() => {
        if (rowUpdating) {
            setRowUpdating(false);
        }
    }, [rowUpdating])

    useEffect(() => {
        if (checkedAll) {
            var checkedIds = [];
            filteredMediaAds.forEach(ad => {
                checkedIds.push(ad.id);
            });
            props.setCheckedMediaAds(checkedIds);
        }
        else {
            props.setCheckedMediaAds([]);
        }
    }, [checkedAll])

    useEffect(() => {
        setAdList(props.adList);
        setFilteredMediaAds(props.adList);
        if (selectedAd !== null) {
            setSelectedAd(props.adList.find(e => e.id === selectedAd.id))
        }
    }, [props.adList])

    useEffect(() => {
        setDuplicates(props.duplicates);
    }, [props.duplicates])

    useEffect(() => {
        if (!usedDefaultFilter && adList !== undefined && props.defaultSearchValue !== "") {
            setUsedDefaultFilter(true);
            const searchValueLower = searchValue.toLowerCase();

            setFilteredMediaAds(adList.filter(ad => (
                ad.client.toLowerCase().includes(searchValueLower) ||
                ad.transactionId.toLowerCase().includes(searchValueLower) ||
                ad.platform.toLowerCase().includes(searchValueLower) ||
                ad.type.toLowerCase().includes(searchValueLower)
            )))
        }
    }, [adList])

    useEffect(() => {
        if (filteredMediaAds !== undefined && filteredMediaAds.length === 0) {
            props.setTotalDueOnScreen(0);
        }
    }, [filteredMediaAds])

    if (rowUpdating || adList === undefined) {
        return <React.Fragment></React.Fragment>
    }
    else {
        // var totalDue = 0.0;
        return (
            <React.Fragment>
                {openDuplicatesModal && (
                    <DuplicatesModal
                        modalState={openDuplicatesModal}
                        handleModal={() => {
                            setSelectedAd(null)
                            setOpenDuplicatesModal(false)
                        }}
                        adList={props.adList}
                        selectedAd={selectedAd}
                    />
                )}
                {openNewSupplierModal && (
                    <NewMediaSupplierModal
                        modalState={openNewSupplierModal}
                        handleModal={() => {
                            setSelectedAd(adList.find(e => e.id === selectedAd.id))
                            setOpenNewSupplierModal(false)
                        }}
                        selectedAd={selectedAd}
                        updateMediaAd={props.updateMediaAd}
                        updatedRow={() => setRowUpdating(true)}
                    />
                )}
                {openDoc && (
                    <EditMediaAdModal
                        modalState={openDoc}
                        handleModal={() => {
                            setSelectedAd(null);
                            setOpenDoc(false);
                        }}
                        docType="PDF"
                        driveId={selectedAd.driveId}
                        headerTitle={`${selectedAd.client} ${selectedAd.type}`}
                        headerColor="#3d4977"
                        selectedAd={selectedAd}
                        setRowUpdating={setRowUpdating}
                        updateMultipleValuesMediaAd={props.updateMultipleValuesMediaAd}
                        updateMediaAd={props.updateMediaAd}
                        setSnackBarProps={props.setSnackBarProps}
                        type="Edit"
                        openNewMediaSupplierModal={() => setOpenNewSupplierModal(true)}
                    />
                )}
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                    placeholder="Search here"
                    size="small"
                    value={searchValue}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                        if (e.target.value === "") {
                            setFilteredMediaAds(adList)
                            setCheckedAll(false);
                        }
                        setSearchValue(e.target.value)
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            if (searchValue !== null && searchValue !== "") {
                                const searchValueLower = searchValue.toLowerCase();

                                setFilteredMediaAds(adList.filter(ad => (
                                    ad.client.toLowerCase().includes(searchValueLower) ||
                                    ad.transactionId.toLowerCase().includes(searchValueLower) ||
                                    ad.platform.toLowerCase().includes(searchValueLower) ||
                                    ad.type.toLowerCase().includes(searchValueLower)
                                )))
                            }
                            else {
                                setFilteredMediaAds(adList);
                            }
                            setCheckedAll(false);
                        }
                    }}
                />
                {searchValue !== null && searchValue !== undefined && searchValue.trim() !== ""
                    && filteredMediaAds.length === 0 && Object.keys(props.initialFilters).length > 0 ? (
                    <Typography
                        align="center" variant="body1" color="textPrimary"
                        style={{
                            width: "100%",
                            fontFamily: "Arial, Helvetica, sans-serif !important",
                            height: "40px !important",
                            backgroundColor: "white !important",
                            color: "black !important",
                            fontWeight: "bold !important",
                        }}
                        gutterBottom
                    >
                        Nothing found. Click <a style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => props.handleSearchAllMediaAds(searchValue)}>here</a> to search all records instead.
                    </Typography>
                ) : searchValue !== null && searchValue !== undefined && searchValue.trim() !== ""
                    && filteredMediaAds.length === 0 ? (
                    <Typography
                        align="center" variant="body1" color="textPrimary"
                        style={{
                            width: "100%",
                            fontFamily: "Arial, Helvetica, sans-serif !important",
                            height: "40px !important",
                            backgroundColor: "white !important",
                            color: "black !important",
                            fontWeight: "bold !important",
                        }}
                        gutterBottom
                    >
                        {`Sorry no results found`}
                    </Typography>
                ) : (
                    <React.Fragment></React.Fragment>
                )}
                <Table
                    id="media-ad-table"
                    size="small"
                >
                    <TableFilter
                        rows={filteredMediaAds}
                        onFilterUpdate={(updatedData, filterConfiguration) => {
                            setAdList(updatedData);
                            setFilteredMediaAds(updatedData);
                        }}
                        initialFilters={props.initialFilters}
                    >
                        {headCells.map((cell) => {
                            return handleHeadCell(cell);
                        })}
                    </TableFilter>
                    <MemoizedTableBody
                        adList={filteredMediaAds}
                        duplicates={duplicates}
                        updateMediaAd={props.updateMediaAd}
                        setTotalDueOnScreen={props.setTotalDueOnScreen}
                        getButton={getButton}
                        setRowUpdating={setRowUpdating}
                        setOpenNewSupplierModal={setOpenNewSupplierModal}
                        setSelectedAd={setSelectedAd}
                        initialFilters={props.initialFilters}
                        checkedMediaAds={props.checkedMediaAds}
                        addCheckedAd={props.addCheckedAd}
                        removeCheckedAd={props.removeCheckedAd}
                        checkedAll={checkedAll}
                    />
                </Table>
            </React.Fragment>
        )
    }
}

export default MediaAdsTable;