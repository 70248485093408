import React, { useEffect, useState } from "react";
import TimeTrackerAllocationRow from "./TimeTrackerAllocationRow";
import moment from "moment";
import TimeTrackerAllocationHeader from "./TimeTrackerAllocationHeader";
import TimeTrackerAllocationFooter from "./TimeTrackerAllocationFooter";

const TimeTrackerAllocationTable = ({ datesToDisplay, timeEntriesByProjects, handleTimeEntryChange, closeProject }) => {

  return (
    <div className="time-tracker-allocation-table-root">
      <TimeTrackerAllocationHeader datesToDisplay={datesToDisplay} />
      <TimeTrackerAllocationRow
        datesToDisplay={datesToDisplay}
        timeEntriesByProjects={timeEntriesByProjects}
        onTimeEntryChange={handleTimeEntryChange}
        closeProject={closeProject}
      />
      <TimeTrackerAllocationFooter datesToDisplay={datesToDisplay} timeEntriesByProjects={timeEntriesByProjects}/>
    </div>
  );
};

export default TimeTrackerAllocationTable;
