import { Button, TextField } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import React, { useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import axios from "axios";
import { useAuth } from "../../../context/auth/AuthContext";

const PersonNotesForm = ({ personId, handleSaveCallback, displayForm = true }) => {
  const { user } = useAuth();
  const [shouldAddNew, setShouldAddNew] = useState(displayForm);
  const [noteObj, setNoteObj] = useState({
    personId: personId,
    note: "",
    user: user?.email,
    noteType: "COMMENT"
  });

  const handleToggleAddNew = () => {
    setShouldAddNew(!shouldAddNew);
  };

  const handleOnChangeNoteObj = (field, value) => {
    setNoteObj({ ...noteObj, [field]: value });
  };

  const handleSaveNote = () => {
    axios
      .post(`/PersonNotes/AddNewNote`, noteObj)
      .then(({ data }) => {
        handleSaveCallback(data);
      })
      .catch(() => {
        console.error("Failed to save ratings");
        handleSaveCallback(null);
      });
  };

  return (
    <div className="person-notes-form-root">
      {shouldAddNew ? (
        <>
          <div className="person-notes-form-comment-container">
            <TextField
              size="small"
              label="Note"
              value={noteObj.note || ""}
              variant="outlined"
              onChange={(e) => handleOnChangeNoteObj("note", e.target.value)}
            />
            <Button
              size="small"
              variant="outlined"
              className="default-button-grey"
              startIcon={<SaveIcon />}
              onClick={handleSaveNote}
            >
              Save New Note
            </Button>
          </div>
        </>
      ) : (
        <div className="person-notes-form-add-new-container">
          <Button
            size="small"
            variant="outlined"
            className="default-button-grey"
            startIcon={<AddIcon />}
            onClick={handleToggleAddNew}
          >
            Add New Note
          </Button>
        </div>
      )}
    </div>
  );
};

export default PersonNotesForm;
