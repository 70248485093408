import React, { Fragment, useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import Layout from "./components/Layout";
import { AllProjects } from "./components/Home";
import Admin from "./components/Admin/AdminHome";

import Home from "./components/Project/Home";
import { Home as CompaniesHomePage } from "./components/Companies/Home";
import { ScheduleLocation } from "./components/Project/Schedule/ScheduleLocation/ScheduleLocation";
import HomeBoard from "./components/Home/HomeBoard";
import MainHome from "./components/Home/MainHome";
import "./custom.scss";
import CalendarView from "./components/Home/CalendarView/CalendarView";
import AuthenticatedRoute from "./components/Shared/AuthenticatedRoute";
import GAuth from "./components/Shared/GAuth";
import ContactCenter from "./components/Admin/ContactCenter/ContactCenter";
import HomeTable from "./components/Home/HomeTable";
import { Helmet } from "react-helmet";
import AuthLoader from "./components/Shared/AuthLoader";
import Login from "./components/Shared/Login";
import { useUserContext } from "./context/user/UserContext";
import ReimbursementPage from "./components/Admin/Reimbursement/ReimbursementPage";
import Users from "./components/Admin/Users/Users";
import axios from "axios";
import { auth } from "./config/firebase";
import Influencers from "./components/InfluencersNetwork/Influencers";
import InfluencerSearch from "./components/InfluencerSearch/InfluencerSearch";
import { useAuth } from "./context/auth/AuthContext";
import ClientsHome from "./components/Home/ClientsHome";
import ClientProjects from "./components/Client/Client";
import MasterStatus from "./components/Client/MasterStatus/MasterStatus";
import BillsDashboard from "./components/Admin/BillsDashboard/BillsDashboard";
import PayablesTable from "./components/Admin/BillsDashboard/PayablesTable/PayablesTable";
import MediaMasterPage from "./components/Client/Media/MasterPage/MediaMasterPage";
import "./components/Shared/loadingDots.scss";
import ReconciliationCentre from "./components/Admin/ReconCentre/ReconciliationCentre";
import RwtReport from "./components/Admin/RwtReport/RwtReport";
import { useDataContext } from "./context/data/DataContext";
import Catering from "./components/Admin/Catering";
import ContractsAndReleases from "./components/Admin/ContractsAndReleases/ContractsAndReleases";
import ExpressPayment from "./components/Admin/ExpressPayment";
import Emailer from "./components/Emailer/TemplateEmailCreator";
import HealthAndSafetyMainPage from "./components/Admin/HealthAndSafety/HealthAndSafetyMainPage";
import SMS from "./components/Admin/SMS";
import SpamBot from "./components/Admin/SpamBot";
import MediaSuppliers from "./components/Admin/MediaSuppliers/MediaSuppliers";
import InfluencerAdmin from "./components/Admin/Influencer/InfluencerAdmin";
import System from "./components/Admin/System";
import OrganisationHomepage from "./components/Homepage/OrganisationHomepage.jsx";
import TimeTracker from "./components/TimeTracker/TimeTracker.jsx";
import ContactSearchPage from "./components/ContactSearch/ContactSearchPage.jsx";
// import HomeTest from "./components/Project/HomeTest";
// import Axios from "axios";

const App = () => {
  const location = useLocation();
  const { allUsers, getAllUsers } = useUserContext();
  const { user, hasAdminAccess } = useAuth();
  const [firstPath, setFirstPath] = useState(null);
  const {
    industries,
    getIndustries,
    ageCategories,
    getAgeCategories,
  } = useDataContext();

  // Redirect to thompsonspencer  when navigating to production.magnesium.nz
  if (window.location.hostname === "production.magnesium.nz") {
    window.location.replace(
      "https://production.thompsonspencer.com" +
        window.location.pathname +
        window.location.search
    );
  }

  useEffect(() => {
    if (!document.getElementById("googlemaps")) {
      console.log("Google Maps Loaded");
      const script = document.createElement("script");
      script.id = "googlemaps";
      script.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyAox3wr9Tu4BfJMItRbvSBpSOuqbf6at7Q&libraries=places,geometry";
      document.body.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (user?.email) {
      fetchSharedData();
    }
  }, [user]);

  useEffect(() => {
    if (allUsers?.length === 0) {
      getAllUsers();
    }
  }, [allUsers]);

  useEffect(() => {
    if (firstPath == null) {
      setFirstPath(location.pathname);
    }
  }, [location.pathname]);

  // useEffect(() => {
  //   if (auth?.currentUser) {
  //     axios.interceptors.request.use(
  //       async (config) => {
  //         config.headers.Authorization =
  //           "Bearer " + (await auth?.currentUser?.getIdToken());
  //         return config;
  //       },
  //       async (error) => {
  //         return Promise.reject(error);
  //       }
  //     );
  //   }
  // }, [auth?.currentUser]);

  const fetchSharedData = () => {
    if (industries?.length === 0) {
      getIndustries();
    }
    if (ageCategories?.length === 0) {
      getAgeCategories();
    }
  };
  return (
    <Fragment>
      <Helmet>
        <script>
          {process.env.REACT_APP_ADMIN_PANEL === "Production" &&
            (function (h, o, t, j, a, r) {
              h.hj =
                h.hj ||
                function () {
                  (h.hj.q = h.hj.q || []).push(arguments);
                };
              h._hjSettings = { hjid: 3477203, hjsv: 6 };
              a = o.getElementsByTagName("head")[0];
              r = o.createElement("script");
              r.async = 1;
              r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
              a.appendChild(r);
            })(
              window,
              document,
              "https://static.hotjar.com/c/hotjar-",
              ".js?sv="
            )}
        </script>
      </Helmet>
      {/* <Redirect
        exact
        push
        from="/index.html"
        to={{
          pathname: "/auth",
          state: {
            endpoint:
              firstPath !== undefined && firstPath !== "/auth"
                ? firstPath
                : "/",
          },
        }}
      /> */}
      <Layout location={location}>
        {/* <AuthenticatedRoute exact path="/Projects" component={AllProjects} /> */}
        <AuthenticatedRoute exact path="/Calendar" component={CalendarView} />
        <AuthenticatedRoute exact path="/Archive" component={AllProjects} />
        {/* <Route exact path="/Admin" component={Admin} /> */}

        {/* <AuthenticatedRoute exact path={"/Admin/Check-In"} component={Admin} /> */}
        <AuthenticatedRoute
          exact
          path={"/Finance/Reimbursement"}
          component={ReimbursementPage}
        />
        {hasAdminAccess() && (
          <>
            <AuthenticatedRoute
              exact
              path={"/Admin/:adminMenu?"}
              component={Admin}
            />
            <AuthenticatedRoute
              exact
              path={"/Finance/FinanceHome"}
              component={Admin}
            />
            <AuthenticatedRoute
              exact
              path={"/Finance/Contracts"}
              component={Admin}
            />
            <AuthenticatedRoute
              exact
              path={"/Finance/Check-In"}
              component={Admin}
            />
            <AuthenticatedRoute
              exact
              path={"/Finance/RwtReport"}
              component={RwtReport}
            />
            <AuthenticatedRoute
              exact
              path={"/Finance/ReconCentre/:type?/:id?"}
              component={ReconciliationCentre}
            />
            <AuthenticatedRoute
              exact
              path={"/admin/catering"}
              component={Catering}
            />
            <AuthenticatedRoute
              exact
              path={"/admin/contracts-releases"}
              component={ContractsAndReleases}
            />
            <AuthenticatedRoute
              exact
              path={"/admin/express-payment"}
              component={ExpressPayment}
            />
            <AuthenticatedRoute
              exact
              path={"/admin/email"}
              component={Emailer}
            />
            <AuthenticatedRoute
              exact
              path={"/admin/health-and-safety"}
              component={HealthAndSafetyMainPage}
            />
            <AuthenticatedRoute
              exact
              path={"/admin/sms-templates"}
              component={SMS}
            />
            <AuthenticatedRoute
              exact
              path={"/admin/spambot"}
              component={SpamBot}
            />
            <AuthenticatedRoute
              exact
              path={"/admin/media-suppliers"}
              component={MediaSuppliers}
            />
            <AuthenticatedRoute
              exact
              path={"/admin/influencers"}
              component={InfluencerAdmin}
            />
            <AuthenticatedRoute
              exact
              path={"/admin/system"}
              component={System}
            />
          </>
        )}

        <AuthenticatedRoute
          path="/Project/Home/:id/:tab/:scheduleid?/:tab?"
          component={Home}
          exact
        />
        <AuthenticatedRoute
          exact
          path="/Companies/Home"
          component={CompaniesHomePage}
        />
        <AuthenticatedRoute
          exact
          path="/ScheduleLocation"
          component={ScheduleLocation}
        />
        <AuthenticatedRoute exact path="/" component={MainHome} />
        <AuthenticatedRoute exact path="/v2/clients" component={ClientsHome} />
        <AuthenticatedRoute exact path="/HomeTable" component={HomeTable} />
        <AuthenticatedRoute
          exact
          path="/Contact/Search"
          component={ContactSearchPage}
        />
        <AuthenticatedRoute exact path="/Influencers" component={Influencers} />
        <AuthenticatedRoute
          path="/Influencers/instagram-search"
          component={InfluencerSearch}
          exact
        />
        <AuthenticatedRoute
          path="/Client/Home/:id/:tabValue?/:campaignId?"
          component={ClientProjects}
          exact
        />
        <AuthenticatedRoute
          path="/Campaign/MasterStatus"
          component={MasterStatus}
          exact
        />
        <AuthenticatedRoute
          path="/Media/MasterMedia"
          component={MediaMasterPage}
          exact
        />
        <AuthenticatedRoute
          path="/Finance/BillsDashboard"
          component={BillsDashboard}
          exact
        />
        <AuthenticatedRoute
          path="/Finance/Payables"
          component={PayablesTable}
          exact
        />
        <AuthenticatedRoute
          path="/project-status"
          component={OrganisationHomepage}
          exact
        />
        <AuthenticatedRoute
          path="/time-tracker"
          component={TimeTracker}
          exact
        />
        {/* <Route exact path="/Media/Home" component={Admin} /> */}
        {/* <Route exact path={"/Media/Media Ad Receipts & Invoices"} component={Admin} /> */}
        {/* <Route exact path={"/Media/Media Suppliers"} component={Admin} /> */}
        {/* <Route
        path="/Test/Test/:id/:tab/:scheduleid?/:tab?"
        component={HomeTest}
        exact
      /> */}
      </Layout>
    </Fragment>
  );
};

export default App;
