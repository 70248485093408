import Axios from "axios";
import {
  GET_PROJECTS,
  COPY_PROJECT,
  SPLICE_PROJECT,
  RETURN_OUTPUT,
  SWAP_PROJECTS,
  MERGE_ORPHAN_SIBLINGS,
  RETURN_SHELL_OUTPUTS_TO_OWNER,
  UPDATE_REAL_PROJECT_PROGRESS,
  UPDATE_SHELL_PROJECT_PROGRESS,
  MERGE_ORPHANS_TO_REAL_PROJECT_ON_DROP,
  MERGE_OUTPUTS_TO_SHELL_PROJECT_ONSHELL_PROJECT_DROP,
  RENDER_ORPHAN_SHELLS,
  SPLICE_ONE_FROM_SHELL,
  SPLICE_ONE_FROM_REAL,
  SORT_PROJECTS,
  RENDER_ORPHAN_OUTPUTS,
  RESET_PROJECTS_LOADING,
  RESORT_PROJECTS,
  RESET_ORPHANS,
} from "../actions/types";

const initialState = {
  projects: [],
  O: [],
  E: [],
  C: [],
  S: [],
  Sc: [],
  Sch: [],
  Sh: [],
  loading: true,
  error: {},
  lockProductionCore: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROJECTS:
      return {
        ...state,
        projects: payload,
        O: payload.filter(
          (proj) => proj.progressCode == null || proj.progressCode == "O"
        ),
        Sc: payload
          .filter((proj) => proj.progressCode == "Sc")
          .sort((a, b) => a.tMinus - b.tMinus),
        Sch: payload
          .filter((proj) => proj.progressCode == "Sch")
          .sort((a, b) => a.tMinus - b.tMinus),
        E: payload
          .filter((proj) => proj.progressCode == "E")
          .sort((a, b) => a.tMinus - b.tMinus),
        Sh: payload
          .filter((proj) => proj.progressCode == "Sh")
          .sort((a, b) => a.tMinus - b.tMinus),
        C: payload
          .filter((proj) => proj.progressCode == "C")
          .sort((a, b) => a.tMinus - b.tMinus),
        loading: false,
        lockProductionCore: payload
          .filter((proj) => proj.daysToDeadline == 0)
          .some((element, index, array) => {
            return element.daysOverdue > 0 && element.project.isInternalProject !== 1;
          }),
      };
    case SORT_PROJECTS:
      return {
        ...state,
      };
    case COPY_PROJECT:
      // payload =  ownerProject, parentProject, output, source, destination,
      if (payload.parentProject.progressCode == null) {
        payload.parentProject.progressCode = "O";
      }
      var output = payload.output;
      output.orphanStage = payload.destination.code;
      var newProject = {
        ...payload.parentProject,
        id:
          "O-" +
          payload.output.id +
          "-" +
          payload.output.projectFk +
          "-" +
          payload.destination.destination,
        progressCode: payload.destination.code,
        outputs: [output],
      };
      state[payload.destination.destination].push(newProject);
      var outputsForParent =
        state[payload.parentProject.progressCode][
          state[payload.parentProject.progressCode].indexOf(
            payload.parentProject
          )
        ].outputs;
      var location = outputsForParent.find((o) => o.id == payload.output.id);
      outputsForParent.splice(outputsForParent.indexOf(location), 1);
      Axios.put(`Outputs/UpdateOutputProgress/${output.id}`, output);
      return {
        ...state,
      };
    case SPLICE_PROJECT:
      var indexToSpliceAt = state[payload.parentProject.progressCode].indexOf(
        payload.parentProject
      );
      state[payload.parentProject.progressCode].splice(indexToSpliceAt, 1);
      return {
        ...state,
      };
    case RETURN_OUTPUT:
      var ownerProjectOutputs =
        state[payload.ownerProject.progressCode][
          state[payload.ownerProject.progressCode].indexOf(payload.ownerProject)
        ].outputs;
      ownerProjectOutputs.splice(ownerProjectOutputs.length, 0, payload.output);
      ownerProjectOutputs.forEach((output) => (output.orphanStage = null));
      ownerProjectOutputs.forEach((output) =>
        Axios.put(`Outputs/UpdateOutputProgress/${output.id}`, output)
      );
      return {
        ...state,
      };
    case MERGE_ORPHAN_SIBLINGS:
      state[payload.parentProject.progressCode][
        state[payload.parentProject.progressCode].indexOf(payload.parentProject)
      ].outputs.push(payload.output);
      state[payload.parentProject.progressCode][
        state[payload.parentProject.progressCode].indexOf(payload.parentProject)
      ].outputs.forEach(
        (output) => (output.orphanStage = payload.parentProject.progressCode)
      );
      var output = payload.output;
      output.orphanStage = payload.parentProject.progressCode;
      Axios.put(`Outputs/UpdateOutputProgress/${output.id}`, output);
      return {
        ...state,
      };
    case RETURN_SHELL_OUTPUTS_TO_OWNER:
      var shell = state[payload.source.droppableId][payload.source.index];
      shell.outputs.forEach((output) => (output.orphanStage = null));
      var owner = payload.ownerProject;
      owner.outputs = [...owner.outputs, ...shell.outputs];
      state[payload.source.droppableId].splice(payload.source.index, 1);
      // Still to handle = the orphan code of those outputs in DB and state.
      shell.outputs.forEach((output) =>
        Axios.put(`Outputs/UpdateOutputProgress/${output.id}`, output)
      );

      return {
        ...state,
      };
    case SWAP_PROJECTS:
      var shell =
        state[payload.parentShell.progressCode][
          state[payload.parentShell.progressCode].indexOf(payload.parentShell)
        ];
      shell.outputs.forEach((output) => (output.orphanStage = null));
      shell.outputs.forEach((output) =>
        Axios.put(`Outputs/UpdateOutputProgress/${output.id}`, output)
      );
      shell.id = payload.ownerId;

      state[payload.ownerProgressCode].splice(
        state[payload.ownerProgressCode].indexOf(
          state[payload.ownerProgressCode].find(
            (proj) => proj.id == payload.ownerId
          )
        ),
        1
      );
      return {
        ...state,
      };
    case UPDATE_REAL_PROJECT_PROGRESS:
      //destination, source, draggableId
      var thisProject = state[payload.source.droppableId][payload.source.index];
      thisProject.progressCode = payload.destination.draggableId;
      state[payload.destination.droppableId].splice(0, 0, thisProject);
      state[payload.source.droppableId].splice(payload.source.index, 1);
      state.projects.find(
        (project) => project.id == payload.draggableId.split("-")[1]
      ).progressCode = payload.destination.droppableId;
      Axios.put(
        `Projects/UpdateProjectProgress/${payload.draggableId.split("-")[1]}`,
        thisProject
      );
      return {
        ...state,
      };
    case UPDATE_SHELL_PROJECT_PROGRESS:
      var thisProject = state[payload.source.droppableId][payload.source.index];
      thisProject.progressCode = payload.destination.droppableId;
      thisProject.outputs.forEach(
        (output) => (output.orphanStage = payload.destination.droppableId)
      );
      thisProject.outputs.forEach((output) => {
        Axios.put(`Outputs/UpdateOutputProgress/${output.id}`, output);
      });
      var newIdArr = thisProject.id.split("-");
      newIdArr[3] = payload.destination.droppableId;
      var newId = newIdArr.join("-");
      thisProject.id = newId;
      state[payload.destination.droppableId].splice(0, 0, thisProject);
      state[payload.source.droppableId].splice(payload.source.index, 1);
      return {
        ...state,
      };
    case MERGE_ORPHANS_TO_REAL_PROJECT_ON_DROP:
      state[payload.source.droppableId][payload.source.index].outputs = [
        ...state[payload.source.droppableId][payload.source.index].outputs,
        ...payload.children,
      ];
      state[payload.source.droppableId][payload.source.index].outputs.forEach(
        (output) => {
          output.orphanStage = null;
        }
      );
      state[payload.source.droppableId][payload.source.index].outputs.forEach(
        (output) => {
          Axios.put(`Outputs/UpdateOutputProgress/${output.id}`, output);
        }
      );
      var ShellsinDest = state[payload.destination.droppableId].filter((proj) =>
        isNaN(proj.id)
      );
      var thisParentShell = ShellsinDest.find(
        (proj) => proj.id.split("-")[2] == payload.children[0].projectFk
      );
      state[payload.destination.droppableId].splice(
        state[payload.destination.droppableId].indexOf(thisParentShell),
        1
      );
      return {
        ...state,
      };
    case MERGE_OUTPUTS_TO_SHELL_PROJECT_ONSHELL_PROJECT_DROP:
      state[payload.source.droppableId][payload.source.index].outputs = [
        ...state[payload.source.droppableId][payload.source.index].outputs,
        ...payload.children,
      ];
      state[payload.destination.droppableId].push(
        state[payload.source.droppableId][payload.source.index]
      );
      state[payload.source.droppableId].splice(payload.source.index, 1);
      var shellProjArr = state[payload.destination.droppableId].filter((proj) =>
        isNaN(proj.id)
      );
      var destinationProj = shellProjArr.find(
        (proj) => proj.id.split("-")[2] == payload.children[0].projectFk
      );
      state[payload.destination.droppableId].splice(
        state[payload.destination.droppableId].indexOf(destinationProj),
        1
      );
      return {
        ...state,
      };
    // Render Shells
    case RENDER_ORPHAN_SHELLS:
      var orphans = payload.outputs.filter(
        (output) => output.orphanStage != null
      );
      var obj = orphans.reduce(
        (acc, cur) => ({ ...acc, [cur.projectFk + "-" + cur.orphanStage]: [] }),
        {}
      );
      orphans.forEach((orphan) =>
        orphan.projectFk + "-" + orphan.orphanStage in obj
          ? obj[orphan.projectFk + "-" + orphan.orphanStage].push(orphan)
          : orphan
      );
      for (let key in obj) {
        var parent = state.projects.find(
          (proj) => proj.id == key.split("-")[0]
        );
        var filteredArr = [];
        let idsArr = [];
        obj[key].forEach((op) => idsArr.push(op.id));
        for (let i = 0; i < parent.outputs.length; i++) {
          if (idsArr.indexOf(parent.outputs[i].id) == -1) {
            filteredArr.push(parent.outputs[i]);
          }
        }
        parent.outputs = filteredArr;
        var shell = {
          ...parent,
          outputs: obj[key],
          id:
            "O-" +
            obj[key][0].id +
            "-" +
            obj[key][0].projectFk +
            "-" +
            obj[key][0].orphanStage,
          progressCode: obj[key][0].orphanStage,
        };
        state[obj[key][0].orphanStage].push(shell);
      }
      return {
        ...state,
      };
    case RENDER_ORPHAN_OUTPUTS:
      var noProject = action.payload.outputs.filter((op) => op.projectFk === 0);
      console.log(action.payload);
      // Group orphans by CampaignName
      var campaignNames = {};
      for (let i = 0; i < noProject.length; i++) {
        if (!(noProject[i].campaignName in campaignNames)) {
          campaignNames[noProject[i].campaignName] = [];
          campaignNames[noProject[i].campaignName].push(noProject[i]);
        } else {
          campaignNames[noProject[i].campaignName] = [
            ...campaignNames[noProject[i].campaignName],
            noProject[i],
          ];
        }
      }
      for (let key in campaignNames) {
        var shell = {
          projectName: key,
          customerName: campaignNames[key][0].customerName,
          projectTag: campaignNames[key][0].creativeCode,
          outputs: campaignNames[key],
          id: "NoProject-" + key,
        };
        state.S.push(shell);
      }
      return {
        ...state,
      };
    case SPLICE_ONE_FROM_SHELL:
      state[payload.shellProject.progressCode][
        state[payload.shellProject.progressCode].indexOf(payload.shellProject)
      ].outputs.splice(
        state[payload.shellProject.progressCode][
          state[payload.shellProject.progressCode].indexOf(payload.shellProject)
        ].outputs.indexOf(payload.output),
        1
      );
      return {
        ...state,
      };
    case SPLICE_ONE_FROM_REAL:
      var outputs =
        state[payload.project.progressCode][
          state[payload.project.progressCode].indexOf(payload.project)
        ].outputs;
      outputs.splice(
        outputs.indexOf(outputs.find((op) => op.id == payload.output.id)),
        1
      );
      return {
        ...state,
      };
    case RESORT_PROJECTS:
      state[payload.destinationColumn].sort((a, b) => a.tMinus - b.tMinus);
      return {
        ...state,
      };
    case RESET_PROJECTS_LOADING:
      return initialState;
    case RESET_ORPHANS:
      return {
        ...state,
        S: [],
      };
    default:
      return state;
  }
}
