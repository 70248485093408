export const cateringPolygon = [
  [-36.80973586719327, 174.59761926053721],
  [-36.78775994610024, 174.64340993283946],
  [-36.78233802032609, 174.66930934308726],
  [-36.771720761594295, 174.66071554540355],
  [-36.72870819214818, 174.65727158902843],
  [-36.72286071364464, 174.69306298612315],
  [-36.67658114567677, 174.73846742032723],
  [-36.676340212594404, 174.74790879605965],
  [-36.69417582536342, 174.76106234906868],
  [-36.758231156164065, 174.7712976586695],
  [-36.82863114632702, 174.81459924100594],
  [-36.83331988848238, 174.79764768003182],
  [-36.8437783546277, 174.82378312467296],
  [-36.843726838595245, 174.8630184781428],
  [-36.855488765196554, 174.9043137681361],
  [-36.885597915487686, 174.93677922605235],
  [-36.90196928892715, 174.95345183728892],
  [-36.93389537219188, 174.93500896810252],
  [-36.941073040057375, 174.9130845916148],
  [-36.975134158199936, 174.9045498025294],
  [-37.05215161715262, 175.0227209544171],
  [-37.07789539008914, 174.99614750326134],
  [-37.09486781935217, 174.94382484556266],
  [-37.088037332351966, 174.921483657359],
  [-37.08438220885844, 174.93511813222693],
  [-37.079551549987855, 174.93068554735936],
  [-37.074172741212806, 174.92728293075348],
  [-37.06122226018027, 174.92270929544213],
  [-37.05874337970186, 174.88970109342296],
  [-37.01619641100466, 174.82734509824473],
  [-37.00461347990257, 174.7804386269923],
  [-36.96656244470498, 174.77413007138927],
  [-36.93339796992234, 174.72368308423717],
  [-36.95474904198808, 174.62327190755565],
  [-36.88892738906055, 174.6027691018458],
  [-36.864725330714464, 174.58182641385753],
];

export const wellingtonPolygon = [
  [-41.274260917706606, 174.76256540821026],
  [-41.26780183885938, 174.76906708286236],
  [-41.26314864827275, 174.76689985797833],
  [-41.25913228304097, 174.77243593738507],
  [-41.244024344554006, 174.78496721790268],
  [-41.21987907756381, 174.79277781055404],
  [-41.21122334000156, 174.80127504871322],
  [-41.216120328032815, 174.82340863750412],
  [-41.19105445953231, 174.91781166599228],
  [-41.14760134637593, 174.99100110338165],
  [-41.16255941528276, 175.0078856091304],
  [-41.17494733404927, 175.01632786200477],
  [-41.18939984713574, 175.01859030530883],
  [-41.19688324158073, 174.99517395005657],
  [-41.196099532891886, 174.9717575948043],
  [-41.235853874489386, 174.9317913393779],
  [-41.23991503437088, 174.89689580009414],
  [-41.23099038673077, 174.88250708983375],
  [-41.22567969741687, 174.8591919879718],
  [-41.23457027563889, 174.838966790895],
  [-41.24604108937672, 174.82080153034164],
  [-41.26471318956183, 174.78814295337628],
  [-41.27084195932001, 174.7880571226878],
  [-41.280840295974066, 174.79020288989972],
  [-41.282089980371474, 174.78464535282086],
  [-41.286048500074685, 174.78080442951153],
  [-41.289192570673364, 174.79046038196515],
  [-41.28574214663416, 174.803764138679],
  [-41.29103057253924, 174.80427912280987],
  [-41.29544745533112, 174.80559953604657],
  [-41.30347491161999, 174.806319134464],
  [-41.306166746115295, 174.80019172376964],
  [-41.311630739167974, 174.8005445300553],
  [-41.31662683431822, 174.80335299449428],
  [-41.31581798968177, 174.80884809238202],
  [-41.31404212769486, 174.80970833309206],
  [-41.31036131616098, 174.8122871213968],
  [-41.29903957203798, 174.8170116769135],
  [-41.28341379598859, 174.82628332509418],
  [-41.2889233469757, 174.83450209697213],
  [-41.2958051611409, 174.8370665305185],
  [-41.297504751629916, 174.835516334572],
  [-41.30099828561389, 174.83268130007528],
  [-41.307844354257924, 174.8331078317407],
  [-41.312670135258145, 174.83126343885107],
  [-41.317176756019805, 174.82830614774912],
  [-41.32155414492745, 174.83410358687178],
  [-41.32624043022363, 174.83940556571045],
  [-41.329227975794176, 174.83750752864054],
  [-41.33041076900654, 174.82685476134597],
  [-41.33303408690558, 174.82117041205962],
  [-41.33559285288408, 174.8067313325487],
  [-41.329486208511135, 174.79637676044686],
  [-41.3336955995727, 174.7928439798482],
  [-41.34069791328188, 174.7948541398418],
  [-41.347699474176075, 174.79205778128073],
  [-41.34602667476334, 174.78670774120866],
  [-41.342549553295285, 174.78496259005254],
  [-41.3466116257471, 174.78098584099604],
  [-41.34551872940577, 174.77288921889271],
  [-41.335475921623406, 174.77337588416592],
  [-41.33381035416541, 174.7674681631476],
  [-41.326472921336475, 174.76816940514192],
  [-41.32565104076053, 174.75738567959772],
  [-41.312258829788384, 174.761279001783],
  [-41.30827765316784, 174.76130994298683],
  [-41.306568947725886, 174.7548486930703],
  [-41.29683221297418, 174.76304054260254],
  [-41.29173884481473, 174.7178706099889],
  [-41.2838386596456, 174.71784340469804],
  [-41.27813066136901, 174.72948917303998],
  [-41.274046304896494, 174.7483231266655],
  [-41.27552964493348, 174.75606513674376],
];
