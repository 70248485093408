import React, { useState, useEffect } from "react";
import TableFilter from "react-table-filter";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { setSnackBarProps } from "../../../actions/snackbar";
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Button,
} from "@material-ui/core";
import {
    withStyles,
    makeStyles,
    useTheme,
    styled,
} from "@material-ui/core/styles";
import ContractorPopUp from "../../Project/ProjectOutputs/PopUps/ContractorPopUp";
import Snacky from "../../Shared/Snacky";

import "./CheckIn.css"

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontWeight: "bold",
    },
    body: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    root: {
        padding: "10px 10px 10px 10px",
        maxHeight: "50px",
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: "#efefef",
        },
    },
}))(TableRow);

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: 12,
        border: "1px solid #dadde9",
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    table: {
        minWidth: 750,
    }
}));

const headCells = [
    {
        id: "date",
        align: "center",
        label: "Date",
    },
    {
        id: "shootName",
        align: "center",
        label: "Shoot",
    },
    {
        id: "name",
        align: "center",
        label: "Name",
    },
    {
        id: "acknowledge",
        align: "center",
        label: "Acknowledge",
    },
];

const CheckInTable = (props) => {
    let { setSnackBarProps, snackbar } = props;
    let history = useHistory();
    var classes = useStyles();
    const [checkInList, setCheckInList] = useState(props.checkInList);

    const [redirectToShoot, setRedirectToShoot] = useState(false);
    const [selectedCheckInItem, setSelectedCheckInItem] = useState(null);

    const [openContactDetailsPopUp, setOpenContactDetailsPopUp] = useState(false);

    const getHeaderClass = (cellId) => {
        switch (cellId) {
            case "acknowledge":
                return "check-in-table-header-cell acknowledge-header-cell";
            default:
                return "check-in-table-header-cell";
        }
    };

    const handleHeadCell = (cell) => {
        if (cell.id === "date") {
            return (
                <th
                    filterkey={cell.id}
                    className={getHeaderClass(cell.id)}
                    itemDisplayValueFunc={(itemValue) => {
                        if (itemValue !== null) {
                            return itemValue.toDateString();
                        }
                    }}
                    itemSortValueFunc={(itemValue) => {
                        if (itemValue !== "(blank)") {
                            return itemValue.toDateString();
                        }
                    }}
                    align={cell.align}
                >
                    {cell.label}
                </th>
            );
        }
        else if (cell.id === "acknowledge") {
            return (
                <th
                    className={getHeaderClass(cell.id)}
                    align={cell.align}
                >
                    {cell.label}
                </th>
            );
        }
        else {
            return (
                <th
                    filterkey={cell.id}
                    className={getHeaderClass(cell.id)}
                    align={cell.align}
                >
                    {cell.label}
                </th>
            );
        }
    }

    const handleShootClicked = (chedkInItem) => {
        setSelectedCheckInItem(chedkInItem);
        setRedirectToShoot(true);
    }

    if (redirectToShoot) {
        var projectId = selectedCheckInItem.projectId;
        var scheduleId = selectedCheckInItem.scheduleId;
        history.push(`/Project/Home/${projectId}/3/${scheduleId}`);
        return <React.Fragment></React.Fragment>;
    }
    else {
        return (
            <React.Fragment>
                <Table
                    id="check-in-table"
                    className={classes.table}
                    size="small"
                >
                    <TableFilter
                        rows={checkInList}
                        onFilterUpdate={(updatedData, filterConfiguration) => {
                            props.setInitialFilters(filterConfiguration);
                            // setCurrentFilter(filterConfiguration);
                            setCheckInList(updatedData);
                        }}
                        initialFilters={props.initialFilters}
                    >
                        {headCells.map((cell) => {
                            return handleHeadCell(cell);
                        })}
                    </TableFilter>
                    <TableBody>
                        {openContactDetailsPopUp && (
                            <ContractorPopUp
                                handleModal={() => {
                                    setSelectedCheckInItem(null);
                                    setOpenContactDetailsPopUp(false);
                                }}
                                modalState={openContactDetailsPopUp}
                                personId={selectedCheckInItem.personId}
                                scheduleItemId={selectedCheckInItem.scheduleId}
                                reloadPersonList={() => console.log("No reload")}
                                reloadScheduleItemsList={() => console.log("No reload")}
                                type="CheckInTable"
                                providerType={selectedCheckInItem.personType}
                                selectedCheckInItem={selectedCheckInItem}
                                handleEditCheckInItem={props.handleEditCheckInItem}
                            />
                        )}
                        {checkInList.length > 0
                            ? checkInList.map((checkIn, index) => {
                                return (
                                    <StyledTableRow
                                        key={`${checkIn.id}-check-in-table-row`}
                                        hover={true}
                                    >
                                        <StyledTableCell className="check-in-table-body-cell">
                                            {checkIn.date !== null
                                                ? format(checkIn.date, "dd/MM/yyyy")
                                                : null}
                                        </StyledTableCell>
                                        <StyledTableCell className="check-in-table-body-cell">
                                            <span
                                                className="clickable"
                                                onClick={() => handleShootClicked(checkIn)}
                                            >
                                                {checkIn.shootName}
                                            </span>
                                        </StyledTableCell>
                                        <StyledTableCell className="check-in-table-body-cell">
                                            <span
                                                className="clickable"
                                                onClick={() => {
                                                    setOpenContactDetailsPopUp(true);
                                                    setSelectedCheckInItem(checkIn);
                                                }}
                                            >
                                                {checkIn.name}
                                            </span>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" className="check-in-table-body-cell">
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                onClick={() => props.dismissItem(checkIn)}
                                            >
                                                Dismiss
                                            </Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                            })
                            : <React.Fragment></React.Fragment>
                        }
                    </TableBody>
                </Table>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    snackbar: state.snackbar,
});
export default connect(mapStateToProps, {
    setSnackBarProps,
})(CheckInTable);