import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
} from '@material-ui/core';
import {
    format
} from "date-fns";

const PickupConfirmation = (props) => {
    const [hungryClassic, setHungryClassic] = useState(0);
    const [hungrySimpleSalad, setHungrySimpleSalad] = useState(0);
    const [vegetarian, setVegetarian] = useState(0);
    const [vegan, setVegan] = useState(0);
    const [glutenFree, setGlutenFree] = useState(0);
    const [dairyFree, setDairyFree] = useState(0);
    const [pickupContact, setPickupContact] = useState(props.pickupContact);
    const [totalCost, setTotalCost] = useState(0.0);
    const [mealCount, setMealCount] = useState(0);
    const [gstIncCost, setGstIncCost] = useState(null);
    const [kidsGstIncCost, setKidsGstIncCost] = useState(null);
    const [kidsMeal, setKidsMeal] = useState(0);
    const [showPlaceOrder, setShowPlaceOrder] = useState(false);

    const placeOrder = () => {
        axios.post(`Schedules/SendPickupCateringOrder`, {
            address: `5 Galatos Street, Auckland CBD, Auckland 1010`,
            startTime: format(props.pickupTime, "HH:mm aa"),
            date: format(new Date(props.scheduleDate), "dd/MM/yyyy"),
            classic: hungryClassic,
            simpleSalad: hungrySimpleSalad,
            vegetarian: vegetarian,
            vegan: vegan,
            glutenFree: glutenFree,
            dairyFree: dairyFree,
            kidsMeal: kidsMeal,
            contactName: pickupContact.firstName + " " + pickupContact.lastName,
            contactPhone: pickupContact.phoneNumber,
            scheduleId: props.scheduleId,
            cost: totalCost.toFixed(2),
            mealCost: gstIncCost,
            kidsMealCost: kidsGstIncCost,
            deliveryCost: "0",
            mealCount: mealCount,
        })
        axios.post(`Catering/PostCatering`, {
            orderJson: JSON.stringify({
                address: `5 Galatos Street, Auckland CBD, Auckland 1010`,
                startTime: format(props.pickupTime, "HH:mm aa"),
                date: format(new Date(props.scheduleDate), "dd/MM/yyyy"),
                classic: hungryClassic,
                simpleSalad: hungrySimpleSalad,
                vegetarian: vegetarian,
                vegan: vegan,
                glutenFree: glutenFree,
                dairyFree: dairyFree,
                kidsMeal: kidsMeal,
                contactName: pickupContact.firstName + " " + pickupContact.lastName,
                contactPhone: pickupContact.phoneNumber,
                contactEmail: pickupContact.emailAddress,
                deliveryCost: "0",
                gstExclusiveDeliveryCost: "0",
                mealCost: gstIncCost,
                kidsMealCost: kidsGstIncCost,
                mealCount: mealCount,
            }),
            peopleJson: JSON.stringify(props.allPeople),
            scheduleFk: props.scheduleId,
            status: "Sent",
            type: "Pickup",
            cost: totalCost.toFixed(2),
            mealCount: mealCount
        })
        props.editSchedule(
            "cateringOrdered",
            1,
            props.schedule,
            1
        );
        props.handleClose();
        props.closeCaterPopup();
    }

    useEffect(() => {
        axios.get(`CateringFinances/GetLastCateringFinance`)
            .then(res => {
                var cateringFinance = res.data;
                if (cateringFinance.gstInc === 1) {
                    setGstIncCost(cateringFinance.mealPrice)
                }
                else {
                    //It is exclusive of GST
                    var inclusivePrice = (cateringFinance.mealPrice * 1.15);
                    setGstIncCost(inclusivePrice.toFixed(2))
                }
                if (cateringFinance.kidsMealGstInc === 1) {
                    setKidsGstIncCost(cateringFinance.kidsMealPrice)
                }
                else {
                    //It is exclusive of GST
                    var inclusiveKidsPrice = (cateringFinance.kidsMealPrice * 1.15);
                    setKidsGstIncCost(inclusivePrice.toFixed(2))
                }

                var classic = 0;
                var vegetarian = 0;
                var vegan = 0;
                var glutenFree = 0;
                var dairyFree = 0;
                var kidsMeal = 0;
                let people = props.checkedPeople;

                //Creates the Order
                people.map(person => {
                    switch (person.mealPreference) {
                        case "Classic":
                            if (person.isChild) {
                                kidsMeal++;
                            }
                            else {
                                classic++;
                            }
                            break;
                        case "Vegetarian":
                            vegetarian++
                            break;
                        case "Vegan":
                            vegan++;
                            break;
                        case "Gluten Free":
                            glutenFree++;
                            break;
                        case "Dairy Free":
                            dairyFree++;
                            break;
                        default:
                            break;
                    }
                })
                //----Original----
                if ((classic % 2) === 1) {
                    setHungryClassic((Math.round(classic / 2)));
                    setHungrySimpleSalad(Math.floor(classic / 2));
                }
                else {
                    setHungryClassic((classic / 2));
                    setHungrySimpleSalad(classic / 2);
                }
                //----Winter----
                //80:20 to salad
                // setHungryClassic((Math.round(classic * 0.8)));
                // setHungrySimpleSalad(Math.round(classic * 0.2));
                //--------------

                setVegetarian(vegetarian);
                setVegan(vegan);
                setGlutenFree(glutenFree);
                setDairyFree(dairyFree);
                setKidsMeal(kidsMeal);
                setShowPlaceOrder(true);
            })
            .catch(e => {
                console.log(e);
            })
    }, [])

    useEffect(() => {
        var cost = (gstIncCost * (
            hungryClassic +
            hungrySimpleSalad +
            vegetarian +
            vegan +
            glutenFree +
            dairyFree
        )) + (kidsGstIncCost * kidsMeal);
        var count = (
            hungryClassic +
            hungrySimpleSalad +
            vegetarian +
            vegan +
            glutenFree +
            dairyFree +
            kidsMeal
        );

        setTotalCost(cost);
        setMealCount(count);
    }, [hungryClassic, hungrySimpleSalad, vegetarian, vegan, dairyFree, glutenFree, kidsMeal])

    return (
        <Dialog
            open={props.modalState}
            onClose={props.handleClose}
            scroll={'paper'}
        >
            <DialogTitle>Eat My Lunch Pickup Order</DialogTitle>
            <DialogContent dividers={true}>
                <DialogContentText>
                    {
                        <div>
                            <b>{`Pickup Address:`}</b>
                            <br />
                            {`5 Galatos Street, Auckland CBD, Auckland 1010`}
                            <br />
                            <br />
                            <b>{`Pickup Contact:`}</b>
                            <br />
                            {`${pickupContact.firstName} ${pickupContact.lastName}`}
                            <br />
                            <br />
                            <b>{`Orders:`}</b>
                            {
                                showPlaceOrder ?
                                    <React.Fragment>
                                        <br />
                                        {
                                            hungryClassic === 0 ?
                                                ""
                                                :
                                                <div>
                                                    {`Hungry Classic: ${hungryClassic}`}
                                                    <br />
                                                </div>
                                        }
                                        {
                                            hungrySimpleSalad === 0 ?
                                                ""
                                                :
                                                <div>
                                                    {`Hungry Simple Salad: ${hungrySimpleSalad}`}
                                                    <br />
                                                </div>
                                        }
                                        {
                                            vegetarian === 0 ?
                                                ""
                                                :
                                                <div>
                                                    {`Classic Vegetarian: ${vegetarian}`}
                                                    <br />
                                                </div>
                                        }
                                        {
                                            glutenFree === 0 ?
                                                ""
                                                :
                                                <div>
                                                    {`Classic Gluten Friendly: ${glutenFree}`}
                                                    <br />
                                                </div>
                                        }
                                        {
                                            vegan === 0 ?
                                                ""
                                                :
                                                <div>
                                                    {`Classic Vegan: ${vegan}`}
                                                    <br />
                                                </div>
                                        }
                                        {
                                            dairyFree === 0 ?
                                                ""
                                                :
                                                <div>
                                                    {`Classic Dairy Friendly: ${dairyFree}`}
                                                    <br />
                                                </div>
                                        }
                                        {
                                            kidsMeal === 0 ?
                                                ""
                                                :
                                                <div>
                                                    {`Kids Meal: ${kidsMeal}`}
                                                    <br />
                                                </div>
                                        }
                                    </React.Fragment>
                                    : <CircularProgress />
                            }
                            {/* <br />
                            <b>{`Cost: $`}</b>{totalCost.toFixed(2)} */}
                        </div>
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    Cancel
                </Button>
                <Button
                    disabled={!showPlaceOrder}
                    onClick={() => {
                        placeOrder();
                        props.handleClose();
                    }}
                    color="primary">
                    Place Order
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default PickupConfirmation;