import React, { useEffect, useRef, useState } from "react";
import "./influencers.scss";
import Header from "../Header";
import axios from "axios";
import InfluencerFilters from "./InfluencerFilters";
import { Button, Container, Divider, Typography } from "@material-ui/core";
import InfluencersLookUpTable from "./InfluencersLookUpTable";
import Snacky from "../Shared/Snacky";
import UpdateInfluencerDialog from "./UpdateInfluencerDialog";
import ContractorPopUp from "../Project/ProjectOutputs/PopUps/ContractorPopUp";
import UpdatePoiInfluenceDialog from "./UpdatePoiInfluenceDialog";
import AddInfluencerButton from "./AddInfluencerButton";
import FilterButtonCustom from "../Shared/Buttons/FilterButonCustom";

const Influencers = ({ parent, addInfluencerToProject, cateogries = [] }) => {
  const bottomElementRef = useRef(null);
  const emptySearchFilter = {
    industries: [],
    misc: [],
    ageCategories: [],
    type: [],
    rating: []
  };

  const [keywordlookUp, setKeywordlookUp] = useState("");
  const [influencers, setInfluencers] = useState([]);
  const [filteredInfluencers, setFilteredInfluencers] = useState([]);
  const [onlyPOI, setOnlyPOI] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);
  const [openUpdateInfluencerDialog, setOpenUpdateInfluencerDialog] = useState(false);
  const [personCategoryToBeAdded, setPersonCategoryToBeAdded] = useState(null);
  const [openUpdatePoiInfluenceDialog, setOpenUpdatePoiInfluenceDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [ageCategories, setAgeCategories] = useState([]);
  const [influencerSearchFilter, setInfluencerSearchFilter] = useState(emptySearchFilter);
  const [applyFilterClicked, setApplyFilterClicked] = useState(false);


  //Infinite Scroll

  useEffect(() => {
    if (!isLoading && hasMoreData && !isLoadingMore) {
      const observer = new IntersectionObserver(
        async (entries) => {
          if (entries[0].isIntersecting) {
            setIsLoadingMore(true); // prevent making multiple calls
            await searchInfluencers();
            setIsLoadingMore(false);
          }
        },
        { threshold: [1] }
      ); // By specifying [1] as the threshold value, you're instructing the Intersection Observer to trigger when the target element becomes fully visible.

      if (bottomElementRef.current) {
        observer.observe(bottomElementRef.current);
      }

      return () => {
        if (bottomElementRef.current) {
          observer.unobserve(bottomElementRef.current);
        }
      };
    }
  }, [isLoading, hasMoreData, isLoadingMore]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const isAtBottom =
  //       window.innerHeight + window.scrollY >= document.body.offsetHeight - 200;

  //     if (isAtBottom) {
  //       searchInfluencers();
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [currentPage, isLoading]);

  useEffect(() => {
    searchInfluencers();
    getCategories();
    getGenerations();
  }, []);

  useEffect(() => {
    if (onlyPOI) {
      setFilteredInfluencers(influencers.filter(i => i.organisationFk === 5));
    } else {
      setFilteredInfluencers(influencers.filter(i => i.organisationFk !== 5));
    }
  }, [influencers, onlyPOI])

  useEffect(() => {
    if (keywordlookUp) {
      setCurrentPage(0);
    }
  }, [keywordlookUp]);

  useEffect(() => {
    if (applyFilterClicked) {
      setApplyFilterClicked(false);
      searchInfluencers(true);
    }
  }, [applyFilterClicked])

  const handleSearchInfluencers = () => {
    setHasMoreData(true)
    searchInfluencers(true);
  };

  const handleApplyFilter = () => {
    setHasMoreData(true);
    setCurrentPage(0);
    setApplyFilterClicked(true);
  };

  const handleClearFilter = () => {
    setInfluencerSearchFilter(emptySearchFilter);
    setHasMoreData(true);
    setCurrentPage(0);
    setApplyFilterClicked(true);
  };

  const checkType = () => {
    var includesInfluencer = influencerSearchFilter.type.findIndex(m => m == "Influencer") > -1
    var includesContentCreator = influencerSearchFilter.type.findIndex(m => m == "Content Creator") > -1
    if (includesInfluencer && includesContentCreator) {
      return "Both";
    } else if (includesInfluencer) {
      return "Influencer"
    } else if (includesContentCreator) {
      return "Content Creator"
    }
    return ""
  }

  const showClearFilter = () => {
    return influencerSearchFilter.ageCategories.length > 0 || influencerSearchFilter.industries.length > 0 || 
          influencerSearchFilter.misc.length > 0 || influencerSearchFilter.type.length > 0 || influencerSearchFilter.rating.length > 0;
  }

  const searchInfluencers = (shouldBypass = false) => {
    if (!shouldBypass) {
      if (!hasMoreData || isLoading || isLoadingMore) {
        return; // No more data to fetch
      }
    }
    setIsLoading(true);
    var industries = influencerSearchFilter.industries.map(i => i.id);
    var ageCategories = influencerSearchFilter.ageCategories.map(i => i.id);
    axios
      .post(`Influencer/SearchInfluencers`, {
        industries: industries,
        ageCategories: ageCategories,
        rating: influencerSearchFilter.rating,
        hasPets: influencerSearchFilter.misc.findIndex(m => m == "Has pets") > -1,
        hasKids: influencerSearchFilter.misc.findIndex(m => m == "Has kids") > -1,
        type: checkType()
      }, {
        params: {
          keyword: keywordlookUp,
          page: currentPage,
        },
      })
      .then(({ data }) => {
        if (data.length === 0) {
          setHasMoreData(false);
          if (currentPage == 0) {
            setInfluencers([]);
          }
        } else {
          if (currentPage === 0) {
            setInfluencers(data);
          } else {
            setInfluencers((prevInfluencers) => [...prevInfluencers, ...data]);
          }
          setCurrentPage(currentPage + 1);
        }
      })
      .finally(() => setIsLoading(false));

    return;
  };

  const handleEditInfluencer = (influencer) => {
    setSelectedInfluencer(influencer);
    setOpenUpdateInfluencerDialog(true);
  };

  const handleCloseUpdateInfluencerDialog = () => {
    setSelectedInfluencer(null);
    setOpenUpdateInfluencerDialog(false);
    setPersonCategoryToBeAdded(null);
    setOpenUpdatePoiInfluenceDialog(false);
  };

  const handleSaveInfluencer = (influencer) => {
    if (influencer) {
      setInfluencers([influencer]);
      setSnackBarProps({
        open: true,
        severity: "success",
        text: "Your changes have been saved!",
      });
    } else {
      setSnackBarProps({
        open: true,
        severity: "warning",
        text: "Hm, it looks like something went wrong.",
      });
    }
  };

  const handleUpdatePrimarySocial = (influencer) => {
    if (influencer) {
      var copyOfInfluencers = [...influencers];
      var newInfluencers = copyOfInfluencers.map((i) => {
        if (i.id === influencer.id) {
          return influencer;
        } else {
          return i;
        }
      });
      setInfluencers(newInfluencers);
      setSnackBarProps({
        open: true,
        severity: "success",
        text: "Your changes have been saved!",
      });
    } else {
      setSnackBarProps({
        open: true,
        severity: "warning",
        text: "Hm, it looks like something went wrong.",
      });
    }
  };

  const handleCreateNewInfluencer = async (influencer, ir330c = null) => {
    const influencerAlreadyRegistered = await axios.post(
      `Influencer/CheckIfExists`,
      influencer
    );
    if (influencerAlreadyRegistered?.data) {
      setSnackBarProps({
        open: true,
        severity: "warning",
        text: "This Influencer is already registered in our database.",
      });
    } else {
      setIsSaving(true);
      axios
        .post(`Influencer/SaveInfluencer`, influencer)
        .then(({ data }) => {
          setInfluencers([data]);
          setSnackBarProps({
            open: true,
            severity: "success",
            text: "Your changes have been saved!",
          });
          if (ir330c !== null) {
            const reader = new FileReader();
            reader.onload = (e) => {
              // e.target.result contains the base64-encoded data
              const base64Data = e.target.result.split(',')[1];
              console.log(base64Data);
              let form = new FormData();
              form.append("ir330c", base64Data);
              axios.post(`PDF/PostInflucencerIR330C/${data.id}`, form, {
                headers: { "content-type": "multipart/form-data" },
              }).catch((e) => console.log(e))
            };

            reader.readAsDataURL(ir330c);
          }
          setSelectedInfluencer(null);
          handleCloseUpdateInfluencerDialog();
        })
        .catch((err) => {
          console.log(err);
          setSnackBarProps({
            open: true,
            severity: "warning",
            text: "Hm, it looks like something went wrong.",
          });
        })
        .finally(() => setIsSaving(false));
    }
  };

  const getCategories = () => {
    axios.get(`Industry/GetIndustries`)
      .then(res => {
        setIndustries(res.data)
      }).catch(e => {

      })
  }

  const getGenerations = () => {
    axios.get(`AgeCategories/GetAgeCategories`)
      .then(res => {
        setAgeCategories(res.data)
      }).catch(e => {

      })
  }

  return (
    <div>
      <Container maxWidth="lg">
        <div
          className="influencers-root"
        >
          <Snacky
            snackprops={snackBarProps}
            setSnackBarProps={setSnackBarProps}
          />
          {!!personCategoryToBeAdded && (
            <UpdateInfluencerDialog
              selectedInfluencer={selectedInfluencer}
              open={!!personCategoryToBeAdded}
              handleClose={handleCloseUpdateInfluencerDialog}
              handleSubmit={handleCreateNewInfluencer}
              personCategoryToBeAdded={personCategoryToBeAdded}
              isSaving={isSaving}
            />
          )}
          {openUpdatePoiInfluenceDialog && (
            <UpdatePoiInfluenceDialog
              selectedInfluencer={selectedInfluencer}
              open={openUpdatePoiInfluenceDialog}
              handleClose={() => {
                setSelectedInfluencer(null);
                setOpenUpdatePoiInfluenceDialog(false);
              }}
              handleSubmit={handleCreateNewInfluencer}
              isSaving={isSaving}
            />
          )}
          {openUpdateInfluencerDialog && (
            <ContractorPopUp
              handleModal={handleCloseUpdateInfluencerDialog}
              modalState={openUpdateInfluencerDialog}
              personId={selectedInfluencer?.id}
              scheduleItemId={null}
              reloadPersonList={() => console.log("No reload")}
              reloadScheduleItemsList={() => console.log("No reload")}
              type="Influencer"
              providerType={selectedInfluencer.personCategory}
              handleEditPerson={handleSaveInfluencer}
              selectedContact={selectedInfluencer}
              rowUpdated={() => { }}
            />
          )}
          {parent !== "dialog" && (
            <div className="influencers-header-container">
              <Typography variant="h4">Influencer Network</Typography>
            </div>
          )}
          <div className="influencers-create-new-container">
            <div className="influencers-create-new-button-container">
              <AddInfluencerButton
                handleNew={(type) => {
                  setSelectedInfluencer(null);
                  setPersonCategoryToBeAdded(type);
                }}
                handleNewPoiInfluencer={() => {
                  setSelectedInfluencer(null);
                  setOpenUpdatePoiInfluenceDialog(true);
                }}
              />
            </div>
          </div>
          <div className="influencers-search-bar-container">
            <InfluencerFilters
              searchInfluencers={handleSearchInfluencers}
              keywordlookUp={keywordlookUp}
              setKeywordlookUp={setKeywordlookUp}
              onlyPOI={onlyPOI}
              displayPOISwitch={parent !== "dialog"}
              setOnlyPOI={setOnlyPOI}
            />
          </div>
          <div className="influencers-filter-container">
            <FilterButtonCustom
              title={"Type"}
              options={["Influencer", "Content Creator"]}
              spanClass="influencer-filter-button-container"
              selectedOptions={influencerSearchFilter.type}
              setSelectedOptions={value => {
                var newFilter = {
                  ...influencerSearchFilter,
                  type: value
                }
                setInfluencerSearchFilter(newFilter);
              }}
            />
            <FilterButtonCustom
              title={"Categories"}
              options={industries}
              spanClass="influencer-filter-button-container"
              filterType="industry"
              selectedOptions={influencerSearchFilter.industries}
              setSelectedOptions={value => {
                var newFilter = {
                  ...influencerSearchFilter,
                  industries: value
                }
                setInfluencerSearchFilter(newFilter);
              }}
            />
            <FilterButtonCustom
              title={"Generation"}
              options={ageCategories}
              spanClass="influencer-filter-button-container"
              filterType="ageCategory"
              selectedOptions={influencerSearchFilter.ageCategories}
              setSelectedOptions={value => {
                var newFilter = {
                  ...influencerSearchFilter,
                  ageCategories: value
                }
                setInfluencerSearchFilter(newFilter);
              }}
            />
            <FilterButtonCustom
              title={"Refine"}
              options={["Has pets", "Has kids"]}
              spanClass="influencer-filter-button-container"
              selectedOptions={influencerSearchFilter.misc}
              setSelectedOptions={value => {
                var newFilter = {
                  ...influencerSearchFilter,
                  misc: value
                }
                setInfluencerSearchFilter(newFilter);
              }}
            />
            <FilterButtonCustom
              title={"Rating"}
              options={[5, 4.5, 4, 3.5, 3, 2.5, 2, 1.5, 1]}
              // [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5]
              spanClass="influencer-filter-button-container"
              selectedOptions={influencerSearchFilter.rating}
              setSelectedOptions={value => {
                var newFilter = {
                  ...influencerSearchFilter,
                  rating: value
                }
                setInfluencerSearchFilter(newFilter);
              }}
              filterType={"rating"}
            />
            <div className="filter-button-filled" onClick={handleApplyFilter}>
              Apply Filters
            </div>
            <div className={showClearFilter() ? "filter-button-filled" : "hidden"} onClick={handleClearFilter}>
              Clear Filters
            </div>
          </div>
          <div>
            {!isLoading && filteredInfluencers.length === 0 && (//influencers
              <div className="influencers-no-records-container">
                <Typography variant="h5">No Influencers Found</Typography>
              </div>
            )}
            <InfluencersLookUpTable
              isLoading={isLoading}
              influencers={filteredInfluencers}
              handleEditInfluencer={handleEditInfluencer}
              displayAddToProject={parent === "dialog"}
              addInfluencerToProject={addInfluencerToProject}
              handleSaveInfluencer={handleUpdatePrimarySocial}
            />
          </div>
          <div ref={bottomElementRef}></div>
        </div>
      </Container>
    </div>
  );
};

export default Influencers;
