import React, { useEffect, useState } from "react";
import "./TalentAgencyPopup.css";
import Axios from "axios";
import {
  Modal,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Grid,
  TextField,
  Snackbar,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import MuiAlert from "@material-ui/lab/Alert";

const TalentAgencyPopUp = ({
  companyId,
  modalState,
  handleModal
}) => {

  const [getCompany, setGetCompany] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", type: "" });
  const [isSaving, setIsSaving] = useState(false);
  const [talentCompany, setTalentCompany] = useState({
    name: null,
    bankAccount: null,
    gstregistered: null,
    gstnumber: null,
  });
  const { name, bankAccount, gstregistered, gstnumber } = talentCompany;

  const handleTalentCompanyChange = (key) => (event) => {
    var value = null;
    if (key === "gstregistered") {
      value = event.target.checked ? 1 : 0;
    } else {
      value = event.target.value;
    }
    setTalentCompany({ ...talentCompany, [key]: value });
  };

  const handleSave = () => {
    setIsSaving(true);
    Axios.put(`Companies/UpdateTalentAgencyFinanceDetails/${companyId}`, {
      id: companyId,
      name: name,
      bankAccount: bankAccount,
      gstregistered: 1,
      gstnumber: gstnumber,
    })
      .then(() => setSnackbar({ open: true, message: "Successfully Saved Changes!", type: "success" }))
      .catch(() => setSnackbar({ open: true, message: "Failed to Save Changes!", type: "error" }))
      .finally(() => setIsSaving(false));
  };

  useEffect(() => {
    if (getCompany) {
      setGetCompany(false);
      Axios.get(`Companies/GetCompany/${companyId}`)
        .then((res) => {
          var talentCompany = res.data;
          setTalentCompany({
            name: talentCompany.name,
            bankAccount: talentCompany.bankAccount,
            gstregistered: 1,
            gstnumber: talentCompany.gstnumber,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  });

  return (
    <div>
      <Modal
        open={modalState}
        onClose={handleModal}
        className={"right-aligned-popup"}
      >
        <div>
          <AppBar position="static" className="header-talent-agency">
            <Toolbar variant="dense" style={{ paddingRight: 0 }}>
              <Typography
                variant="subtitle1"
                align="center"
                style={{ flexGrow: 1 }}
              >
                Agency Finance Details
              </Typography>
              <span style={{ display: "flex" }}>
                {/* <IconButton
                  variant="contained"
                  className={isEditing ? "header-action-active" : "header-action-hidden"}
                  onClick={() => {
                    handleSave();
                    setIsEditing(false);
                  }}
                >
                  <SaveIcon />
                </IconButton>
                <IconButton
                  variant="contained"
                  className={isEditing ? "header-action-hidden" : "header-action-active"}
                  onClick={() => setIsEditing(true)}
                >
                  <EditIcon />
                </IconButton> */}
                <IconButton
                  onClick={handleModal}
                  className="header-action-active"
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </Toolbar>
          </AppBar>
          <div className={"paper-match-left-popup"}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  label="Agency Name"
                  defaultValue={" "}
                  value={name}
                  disabled={!isEditing}
                  multiline={true}
                  variant="outlined"
                  style={{ width: "100%" }}
                  onChange={handleTalentCompanyChange("name")}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  label="Bank Account"
                  defaultValue={" "}
                  value={bankAccount}
                  disabled={!isEditing}
                  multiline={true}
                  variant="outlined"
                  style={{ width: "100%" }}
                  onChange={handleTalentCompanyChange("bankAccount")}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  label="GST Number"
                  multiline={true}
                  variant="outlined"
                  style={{ width: "100%" }}
                  defaultValue={" "}
                  value={gstnumber}
                  disabled={isEditing ? false : true}
                  onChange={handleTalentCompanyChange("gstnumber")}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                {isEditing ? (
                  <Button
                    variant="contained"
                    size="small"
                    disabled={isSaving}
                    className={isSaving ? "default-button-grey" : "default-button"}
                    onClick={() => {
                      handleSave();
                      setIsEditing(false);
                    }}
                  >
                    Save Financial Changes
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    className={"default-button"}
                    onClick={(e) => setIsEditing(true)}
                  >
                    Click To Edit
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
          <Snackbar
            open={snackbar.open}
            autoHideDuration={2000}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            <MuiAlert severity={snackbar.type}>{snackbar.message}</MuiAlert>
          </Snackbar>
        </div>
      </Modal>
    </div>
  );
}

export default TalentAgencyPopUp;
