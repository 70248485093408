import { Collapse, IconButton, TableCell, TableRow } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { currencyFormat } from "../../../../utils/GeneralUtils";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CustomTableRowScheduleItem from "./CustomTableRowScheduleItem";

const CustomTableRowBudgetItem = ({ budgetItemBills, setSnackBarProps }) => {
  const [openAllocated, setOpenAllocated] = useState(false);
  return (
    <Fragment>
      <TableRow>
        <TableCell className="client-budget-table-icon-column">
        {budgetItemBills.scheduleItems?.length > 0 && (
            <>
              <IconButton aria-label="expand row" size="small">
                {openAllocated ? (
                  <KeyboardArrowDownIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenAllocated(!openAllocated);
                    }}
                  />
                ) : (
                  <KeyboardArrowUpIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenAllocated(!openAllocated);
                    }}
                  />
                )}
              </IconButton>
            </>
          )}
        </TableCell>
        <TableCell className="expense-table-budget-item-column">
          <em>{budgetItemBills.budgetItemCategory}</em>
        </TableCell>
        <TableCell>
          {currencyFormat(Number(budgetItemBills.quotedByBudgetItem))}
        </TableCell>
        <TableCell>
          {currencyFormat(Number(budgetItemBills.allocatedByBudgetItem))}
        </TableCell>
        <TableCell>
          {currencyFormat(Number(budgetItemBills.remainingByBudgetItem))}
        </TableCell>
      </TableRow>
      {budgetItemBills.scheduleItems?.length > 0 &&
        openAllocated &&
        budgetItemBills.scheduleItems.map((scheduleItem, indexSch) => (
          <CustomTableRowScheduleItem
            key={indexSch}
            scheduleItem={scheduleItem}
            setSnackBarProps={setSnackBarProps}
          />
        ))}
    </Fragment>
  );
};

export default CustomTableRowBudgetItem;
