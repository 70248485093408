import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import InputMask from "react-input-mask";
import {
  handleInputRestriction,
  HHmmToMinutes,
  minutesToHHMM,
  validateTimeFormat,
} from "../../../utils/TimeTrackerUtils";

const TimeInput = ({
  initialMinutes = 0,
  onTimeChange,
  mask = "99:99",
  displayEmptyWhenZero = true,
}) => {
  const [timeString, setTimeString] = useState("");

  // Handle input change
  const handleInputChange = (e) => {
    const value = e.target.value;
    setTimeString(value);
  };

  const handleBlur = () => {
    const minutes = HHmmToMinutes(timeString);
    // Ensure the input matches HH:mm format before processing
    if (minutes !== initialMinutes) {
      if (validateTimeFormat(timeString)) {
        // Only call onTimeChange if the new value is different from the initial value
        onTimeChange(minutes); // Pass the minutes back to the parent component
      } else {
        // Reset to 0
        onTimeChange(0);
      }
    }
  };

  // Initialize the timeString state when the initialMinutes prop changes
  useEffect(() => {
    setTimeString(
      displayEmptyWhenZero && initialMinutes === 0
        ? ""
        : minutesToHHMM(initialMinutes)
    );
  }, [initialMinutes]);

  return (
    <InputMask
      mask={mask}
      maskChar={"_"}
      value={timeString}
      onChange={handleInputChange}
      onBlur={handleBlur}
    >
      {(inputProps) => (
        <TextField
          className="default-text-field-container"
          {...inputProps}
          InputProps={{
            disableUnderline: true, // Disable the bottom border
          }}
          fullWidth
        />
      )}
    </InputMask>
  );
};

export default TimeInput;
