import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Modal,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Table,
  TableRow,
  TableCell,
  Button,
} from "@material-ui/core";

import { Close } from "@material-ui/icons";

import "./AddAgentPopUp.css";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minHeight: "auto",
    width: "auto",
    overflow: "hidden",
  },
}));

function ExistingContactsPopup(props) {
  useEffect(() => {
    console.log(props);
  }, []);
  const classes = useStyles();
  return (
    <div>
      <Modal
        open={props.modalState}
        onClose={props.handleModal}
        className={classes.modal}
      >
        <div>
          <AppBar position="static" style={{ background: "#217F8B" }}>
            <Toolbar variant="dense" style={{ paddingRight: 0 }}>
              <Typography
                variant="subtitle1"
                align="center"
                style={{ flexGrow: 1 }}
              >
                Similar Record Found!
              </Typography>
              <IconButton onClick={() => props.handleModal()} color="inherit">
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div className={classes.paper}>
            <Table>
              {props.duplicates.map((contact, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {contact.firstName.charAt(0).toUpperCase() +
                        contact.firstName.slice(1)}
                    </TableCell>
                    <TableCell>
                      {contact.lastName.charAt(0).toUpperCase() +
                        contact.lastName.slice(1)}
                    </TableCell>
                    <TableCell>{contact.emailAddress}</TableCell>
                    <TableCell>{contact.phoneNumber}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        className="SelectExisting"
                        onClick={() =>
                          props.selectExisting({
                            id: contact.id,
                            firstName:
                              contact.firstName.charAt(0).toUpperCase() +
                              contact.firstName.slice(1),
                            lastName:
                              contact.lastName.charAt(0).toUpperCase() +
                              contact.lastName.slice(1),
                            phoneNumber: contact.phoneNumber,
                            emailAddress: contact.emailAddress,
                          })
                        }
                      >
                        Select Existing
                      </Button>
                      <Button
                        variant="contained"
                        className="EditRecord"
                        onClick={() => props.handleEdit(contact)}
                      >
                        Edit Record
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Table>
            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => props.handleContinue()}
              >
                Create New & Continue
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ExistingContactsPopup;
