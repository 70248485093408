import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
  compositeDecorator,
  Modifier
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import Typography from "@material-ui/core/Typography";
import Axios from "axios";
import { Button, Snackbar } from "@material-ui/core";
import "../Admin.css";
import MuiAlert from "@material-ui/lab/Alert";
import Loader from "../../Loader";
import { getCurrentTime } from "../../../utils/TimeHelpers";
import { MergeTagOption } from "../../../utils/ToolbarCustomOptions";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

export default function ContractorContract() {
  const classes = useStyles();
  const [successCreate, setSuccessCreate] = useState(false);
  const [failedCreate, setFailedCreate] = useState(false);
  const [contractHtml, setContractHtml] = useState(null);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [render, setRender] = useState(false);

  const closeSuccessCreateSnackbar = () => {
    setSuccessCreate(false);
  };

  const closeFailedCreateSnackbar = () => {
    setFailedCreate(false);
  };

  var snackbarSuccess = (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={successCreate}
      autoHideDuration={2000}
      onClose={closeSuccessCreateSnackbar}
    >
      <MuiAlert severity="success">Successfully Saved Changes!</MuiAlert>
    </Snackbar>
  );

  var snackbarFailed = (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={failedCreate}
      autoHideDuration={2000}
      onClose={closeFailedCreateSnackbar}
    >
      <MuiAlert severity="error">Failed to Save Changes!</MuiAlert>
    </Snackbar>
  );

  useEffect(() => {
    Axios.get(`ActualContracts/GetActualContracts/LocationRelease`)
      .then((res) => {
        console.log("res.data");
        console.log(res.data);
        setContractHtml(res.data.contract);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (contractHtml !== null) {
      const blocksFromHTML = convertFromHTML(contractHtml);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(state, compositeDecorator));
      setRender(true);
    }
  }, [contractHtml]);

  const clearContract = () => {
    console.log("current state");
    console.log(editorState);
    setEditorState(EditorState.createEmpty());
    setContractHtml("");
  };

  const saveChanges = () => {
    Axios.post(`ActualContracts/PostActualContracts`, {
      contract: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      category: "LocationRelease",
      dateStamp: getCurrentTime(),
    })
      .then((res) => {
        console.log(res);
        setSuccessCreate(true);
      })
      .catch((e) => {
        console.log(e);
        setFailedCreate(true);
      });
  };

  const onEditorStateChange = (editorState) => {
    // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setEditorState(editorState);
  };

  if (render) {
    return (
      <main className={classes.content}>
        <div className="main-content" />
        <div style={{ display: "flex" }}>
          <Typography variant="h4">Location Release</Typography>
          <div className="contracts-buttons-div">
            <Button
              variant="contained"
              className="contract-button"
              onClick={clearContract}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="contract-button"
              onClick={saveChanges}
            >
              Save
            </Button>
          </div>
        </div>
        <div className="text-editor-container">
          <Editor
            editorState={editorState}
            wrapperClassName="text-editor-wrapper"
            editorClassName="text-editor"
            onEditorStateChange={onEditorStateChange}
            handlePastedText={() => false}
            //toolbarCustomButtons={[<MergeTagOption onChange={onEditorStateChange} editorState={editorState}/>]}
          />
        </div>

        {snackbarSuccess}
        {snackbarFailed}
      </main>
    );
  } else {
    return <Loader />;
  }
}
