import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Button,
    TextField,
    CircularProgress
} from "@material-ui/core";
import Axios from "axios";

const NoBankAccountWarning = (props) => {
    const {
        open,
        handleClose,
        personId,
        setGettingReimbursmentInfo
    } = props;

    const [loading, setLoading] = useState(false);

    return (
        <Dialog
            open={open}
            onClose={() => handleClose()}
        >
            <DialogTitle style={{ textAlign: "center" }}>
                {"Missing bank account"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText style={{ textAlign: "center" }}>
                    {"This person does not have a bank account assigned to them. Please go back and enter their bank account before proceeding or send request to the customer asking for their bank account number"}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ textAlign: "center" }}>
                <Button
                    disabled={loading}
                    onClick={() => handleClose()}
                >
                    Cancel
                </Button>
                <Button
                    disabled={loading}
                    onClick={() => {
                        setLoading(true);
                        Axios.post(`Receipts/SendUpdateBankAccountRequest/${-1}/${personId}/fromPerson`)
                            .then(res => {
                                setGettingReimbursmentInfo(true);
                                handleClose();
                            }).catch(() => {
                                
                            }).finally(() => {
                                setLoading(false);
                            })
                    }}
                >
                    {loading ? <CircularProgress/> : `Send Request`}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NoBankAccountWarning;
