/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { setSnackBarProps } from "../../../../actions/snackbar";
import { connect } from "react-redux";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { getCurrentTime, convertDatetimeToMysqlFormat } from "../../../../utils/TimeHelpers";
import {
  Modal,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Grid,
  Button,
  CircularProgress
} from "@material-ui/core";
import { Close, ArrowBackIos } from "@material-ui/icons";
import PeopleList from "./PeopleList";
import EmailEditor from "./EmailEditor";
import PreviewEmail from "./PreviewEmail";
import "./SendSchedule.scss";
import Loader from "../../../Loader";
import Snacky from "../../../Shared/Snacky";
import { useUserContext } from "../../../../context/user/UserContext";
import { useAuth } from "../../../../context/auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minHeight: "auto",
    maxHeight: "90vH",
    width: "80vW",
    overflow: "hidden",
  },
}));

const SendSchedulePopUp = (props) => {
  const { user, } = useAuth();
  const classes = useStyles();
  const [peopleList, setPeopleList] = useState(props.peopleList);
  const [emailBodyHtml, setEmailBodyHtml] = useState(props.emailTemplate.body);
  const [emailSubject, setEmailSubject] = useState(props.emailTemplate.subject);
  const [openPreviewEmail, setOpenPreviewEmail] = useState(false);
  const [previewComponents, setPreviewComponents] = useState(null);
  const [enablePreview, setEnablePreview] = useState();
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    let form = new FormData();
    form.set('list', JSON.stringify(props.peopleList));
    Axios.post(`Schedules/GetLocationAddress/${props.scheduleId}`, form, {
      headers: { "content-type": "multipart/form-data" },
    })
      .then((res) => {
        console.log("people list new");
        console.log(res.data);
        setPeopleList(res.data);
        setEnablePreview(true);
      })
      .catch((err) => console.log(err));
  }, []);

  const updatePerson = (p) => {
    var newList = peopleList.map((person, index) => {
      if (person.personId === p.personId) {
        person = p;
      }
      return person;
    })
    setPeopleList(newList);
  };

  const handleAllCheckedClick = () => {
    var newList = peopleList.map((person, index) => {
      person.willReceive = true;
      return person;
    });
    setPeopleList(newList);
  };


  const handleAllUncheckedClick = () => {
    var newList = peopleList.map((person, index) => {
      person.willReceive = false;
      return person;
    });
    setPeopleList(newList);
  };

  const sendOutSchedules = () => {
    
    setIsSending(true);
    let senderFullName = `${user.firstName} ${user.lastName}`;
    let senderFirstName = user.firstName;
    let senderEmail = user.email;

    const regex1 = RegExp('<img.*?src="(.*?)"', 'g');
    const htmlString = emailBodyHtml;
    let srcArr;
    let base64Arr = [];

    while ((srcArr = regex1.exec(htmlString)) !== null) {
      console.log(`Found ${srcArr[1]}. Next starts at ${regex1.lastIndex}.`);
      base64Arr.push(srcArr[1])
    }

    console.log("base64Arr")
    console.log(base64Arr)

    //send out email to each person who is checked
    Axios.post(`Schedules/SendSchedulePdf`, {
      people: peopleList,
      htmlBody: emailBodyHtml,
      subject: emailSubject,
      senderFullName: senderFullName,
      senderFirstName: senderFirstName,
      senderEmail: senderEmail,
      base64Arr: base64Arr
    })
      .then(res => {
        props.setSnackBarProps("success", "Successfully sent schedules", true);
        var newList = peopleList.map((person, index) => {
          console.log(index);
          if (person.willReceive) {
            console.log(`Updating person ${person.firstName}`)
            console.log(new Date())
            var p = person;
            p.scheduleLastSent = convertDatetimeToMysqlFormat(new Date());
            person = p;
          }
          return person;
        })
        setPeopleList(newList);
      })
      .catch(e => {
        console.log(e);
        props.setSnackBarProps("warning", "Failed to send schedule", true);
      })
      .finally(() => {
        setIsSending(false);
        props.handleModal();
      })
  }
      
  const handlePreview = (person) => {
    let SenderEmail = user.email;
    Axios.post(`Schedules/PreviewSchedule/${props.scheduleId}`, {
      people: [person],
      htmlBody: emailBodyHtml,
      subject: emailSubject,
      SenderEmail: SenderEmail
    })
      .then((res) => {
        console.log(res);
        setPreviewComponents({
          person: res.data.people[0],
          htmlBody: res.data.htmlBody,
          subject: res.data.subject,
        });
        setOpenPreviewEmail(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleClosePreview = () => {
    setPreviewComponents(null);
    setOpenPreviewEmail(false);
  };

  useEffect(() => {
    console.log(emailBodyHtml);
  }, [emailBodyHtml]);

  if (isSending) {
    return (
      <Loader />
    )
  }
  return (
    <div>
      {openPreviewEmail && previewComponents !== null ? (
        <PreviewEmail
          modalState={openPreviewEmail}
          handleModal={handleClosePreview}
          previewComponents={previewComponents}
        />
      ) : (
          <React.Fragment></React.Fragment>
        )}
      <Modal
        open={props.modalState}
        onClose={props.handleModal}
        className={classes.modal}
      >
        <div>
          <AppBar position="static" style={{ background: "#217F8B" }}>
            <Toolbar variant="dense" style={{ paddingRight: 0 }}>
              <Typography
                variant="subtitle1"
                align="center"
                style={{ flexGrow: 1 }}
              >
                Send Schedule
              </Typography>
              <IconButton onClick={props.handleModal} color="inherit">
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div className={classes.paper}>
            <div style={{ display: "flex" }}>
              <div style={{ maxHeight: "80vH", overflowY: "auto", width: "100%"}}>
                <PeopleList
                  peopleList={peopleList}
                  updatePerson={updatePerson}
                  handleAllCheckedClick={handleAllCheckedClick}
                  handleAllUncheckedClick={handleAllUncheckedClick}
                  handlePreview={handlePreview}
                  enablePreview={enablePreview}
                />
              </div>
              <div>
                <EmailEditor
                  emailBodyHtml={emailBodyHtml}
                  emailSubject={emailSubject}
                  setEmailBodyHtml={setEmailBodyHtml}
                  setEmailSubject={setEmailSubject}
                />
              </div>
            </div>
            <div className="send-button-container">
              {enablePreview ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => sendOutSchedules()}
                >
                  Send
                </Button>
              ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => sendOutSchedules()}
                    disabled
                  >
                    Send
                    <CircularProgress className="people-list-enable-preview-button" />
                  </Button>
                )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

//export default SendSchedulePopUp;

const mapStateToProps = (state, ownProps) => ({
  snackbar: state.snackbar,
});
export default connect(mapStateToProps, {
  setSnackBarProps,
})(SendSchedulePopUp);
