import { IconButton, TableCell, TableRow, Typography } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import "./customPayableTableRow.scss";
import { currencyFormat } from "../../../../utils/GeneralUtils";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import moment from "moment";
import CustomPayableTableRowDetails from "./CustomPayableTableRowDetails";
import axios from "axios";
import ConfirmationDialogActions from "../../../Shared/ConfirmationDialogActions";

const CustomPayableTableRow = ({
  payable,
  handleUpdatePayablesList,
  setSnackBarProps,
  companies,
  handleSelectPayable,
  accountCodes,
}) => {
  const [openAllocated, setOpenAllocated] = useState(false);
  const [localPayable, setLocalPayable] = useState(payable);
  const [hasChanges, setHasChanges] = useState(false);
  const [openConfirmSend, setOpenConfirmSend] = useState(false);
  const [openRemoveConfirmation, setOpenRemoveConfirmation] = useState(false);
  const [isSendingToXero, setIsSendingToXero] = useState(false);

  useEffect(() => {
    if (payable) setLocalPayable(payable);
  }, [payable]);

  const handleChangePayable = (field, value) => {
    setHasChanges(true);

    if (field === "companyId") {
      setLocalPayable({
        ...localPayable,
        [field]: value?.id || null,
        client: value?.name || "",
      });
    } else if (field === "platform") {
      setLocalPayable({
        ...localPayable,
        [field]: value?.Name || null,
        supplierBankAcc: value?.BankAccountDetails || null,
        xeroId: value?.ContactID || null,
      });
    } else if (field === "accountCode") {
      setLocalPayable({
        ...localPayable,
        [field]: value?.accountCode || null,
        gstRateCode: value?.taxType || null,
      });
    } else {
      setLocalPayable({ ...localPayable, [field]: value });
    }
  };
  const handleSavePayable = () => {
    axios
      .put(
        `mediaAdDocuments/PutMediaAdDocument/${localPayable.id}`,
        localPayable
      )
      .then(({ data }) => {
        handleUpdatePayablesList(localPayable);
        handleSelectPayable(localPayable)
        setHasChanges(false)
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Fantastic! Your changes have been saved.",
        });
      })
      .catch((err) => {
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to update invoice",
        });
      });
  };

  const handleClickSendToXero = () => {
    setOpenConfirmSend(true);
  };

  const handleConfirmSendToXero = () => {
    setIsSendingToXero(true)
    axios
      .patch(`mediaAdDocuments/SendBillToXero/${payable.id}`, payable)
      .then(({ data }) => {
        handleUpdatePayablesList({
          ...localPayable,
          xeroInvoiceId: data.xeroInvoiceId,
        });
        setOpenConfirmSend(false);
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "All Done! Invoice has been sent to xero",
        });
      })
      .catch((err) => {
        console.error(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to send bill to xero.",
        });
      })
      .finally(() => setIsSendingToXero(false));
  };

  const handleViewPayableInXero = (e) => {
    e.preventDefault();
    window.open(
      `https://go.xero.com/AccountsPayable/Edit.aspx?InvoiceID=${payable.xeroInvoiceId}`,
      "_blank"
    );
  };

  const handleRemovePayable = () => {
    setLocalPayable({ ...localPayable, status: "Rejected" });
    setOpenRemoveConfirmation(true);
  };

  const handleConfirmRemovePayable = () => {
    axios
      .put(
        `mediaAdDocuments/PutMediaAdDocument/${localPayable.id}`,
        localPayable
      )
      .then(({ data }) => {
        handleUpdatePayablesList(localPayable, true);
        setOpenRemoveConfirmation(false);
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Fantastic! Your changes have been saved.",
        });
      })
      .catch((err) => {
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to remove invoice",
        });
      });
  };
  return (
    <Fragment>
      {openConfirmSend && (
        <ConfirmationDialogActions
          title={"Are you sure?"}
          open={openConfirmSend}
          contentText={isSendingToXero ? "Processing..." : "This action will send the invoice to Xero"}
          button1Action={() => setOpenConfirmSend(false)}
          button1Text={"Cancel"}
          button2Text={"Send to Xero"}
          button2Action={handleConfirmSendToXero}
        />
      )}
      {openRemoveConfirmation && (
        <ConfirmationDialogActions
          title={"Are you sure?"}
          open={openRemoveConfirmation}
          contentText={
            "This action will remove the bill from the list."
          }
          button1Action={() => {
            setLocalPayable({ ...localPayable, status: "Pending" });
            setOpenRemoveConfirmation(false);
          }}
          button1Text={"Cancel"}
          button2Text={"Remove"}
          button2Action={handleConfirmRemovePayable}
        />
      )}
      <TableRow
        onClick={(e) => {
          handleSelectPayable(payable);
          setOpenAllocated(true);
        }}
      >
        <TableCell className="client-budget-table-icon-column">
          <>
            <IconButton aria-label="expand row" size="small">
              {openAllocated ? (
                <KeyboardArrowDownIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelectPayable(null);
                    setOpenAllocated(!openAllocated);
                  }}
                />
              ) : (
                <KeyboardArrowUpIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelectPayable(payable);
                    setOpenAllocated(!openAllocated);
                  }}
                />
              )}
            </IconButton>
          </>
        </TableCell>
        <TableCell>
          <div className="custom-payable-table-row-details">
            <div className="custom-payable-table-row-field-wrapper">
              <Typography variant="caption">
                <strong>{"Supplier: "}</strong>
              </Typography>
              {payable.platform ? (
                <Typography variant="caption">{payable.platform}</Typography>
              ) : (
                <ErrorOutlineIcon color="error" fontSize="small" />
              )}
            </div>
            <div className="custom-payable-table-row-field-wrapper">
              <Typography variant="caption">
                <strong>{"Client: "}</strong>
              </Typography>
              {payable.client ? (
                <Typography variant="caption">{payable.client}</Typography>
              ) : (
                <ErrorOutlineIcon color="error" fontSize="small" />
              )}
            </div>
            <div className="custom-payable-table-row-field-wrapper">
              <Typography variant="caption">
                <strong>{"Invoice Date: "}</strong>
              </Typography>
              {payable.transactionDate ? (
                <Typography variant="caption">
                  {moment(payable.transactionDate).format("DD/MM/YYYY")}
                </Typography>
              ) : (
                <ErrorOutlineIcon color="error" fontSize="small" />
              )}
            </div>
          </div>
        </TableCell>
        <TableCell className="custom-payable-table-row-field-wrapper">
          {payable.amount ? (
            <Typography variant="caption">
              {currencyFormat(Number(payable.amount))}
            </Typography>
          ) : (
            <ErrorOutlineIcon color="error" fontSize="small" />
          )}
        </TableCell>
        <TableCell>
         
          {payable.budgetId ? (
            <Typography variant="caption" style={{ color: "green" }}>
              Allocated
            </Typography>
          ) : (
            <Typography variant="caption">Not Allocated</Typography>
          )}
          <div>
            {payable.xeroInvoiceId ? (
              <Typography
                variant="caption"
                className="custom-payable-table-row-link"
                onClick={handleViewPayableInXero}
              >
                View in Xero
              </Typography>
            ) : payable.xeroId &&
              payable.supplierBankAcc &&
              payable.amount &&
              payable.accountCode ? (
              <Typography
                variant="caption"
                className="custom-payable-table-row-link"
                onClick={handleClickSendToXero}
              >
                Send to Xero now
              </Typography>
            ) : (
              <div></div>
            )}
          </div>
        </TableCell>
      </TableRow>
      {openAllocated && (
        <TableRow className="custom-payable-table-row-details-wrapper">
          <TableCell colSpan={4}>
            <CustomPayableTableRowDetails
              payable={localPayable}
              handleOnChange={handleChangePayable}
              handleSavePayable={handleSavePayable}
              companies={companies}
              hasChanges={hasChanges}
              accountCodes={accountCodes}
              handleRemovePayable={handleRemovePayable}
            />
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
};

export default CustomPayableTableRow;
