import React, { Fragment } from "react";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import { Tooltip, Typography, IconButton } from "@material-ui/core";
import { withStyles, styled, makeStyles } from "@material-ui/core/styles";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const AddTravel = (props) => {
  return (
    <HtmlTooltip
      title={<Typography color="inherit">Add travel allowance</Typography>}
      interactive
      arrow
      placement="top"
    >
      <span>
        <IconButton aria-label="equipment">
          <DirectionsCarIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
        </IconButton>
      </span>
    </HtmlTooltip>
  );
};

export default AddTravel;
