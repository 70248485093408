import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "../auth/AuthContext";
import { useHistory } from "react-router-dom";
const initialState = {
  user: "",
  email: "",
  password: "",
  token: "",
  errorMessage: "",
  oAuthSignedIn: false,
  userList: [],
  allUsers: [],
  activeUser: { id: 0, firstName: "" },
};

export const UserContext = createContext({});

export const UserContextProvider = ({ children }) => {
  const [state, setState] = useState(initialState);
  const { logout } = useAuth();
  const history = useHistory()

  const reduceUsers = (usersList) => {
    var userArr = [];
    usersList.forEach((element) => {
      userArr.push(element.email);
    });
    return userArr;
  };

  const updateOAuthLogin = (status, email) => {
    setState({
      ...state,
      oAuthSignedIn: status,
      email: email,
    });
  };

  const updateOAuthStatus = (status) => {
    setState({
      ...state,
      oAuthSignedIn: status,
    });
  };

  const updateOAuthEmail = (email) => {
    setState({
      ...state,
      email: email,
    });
  };

  const idActiveUser = async (authUser, allUsers) => {
    if (!authUser?.uid) {
      setState({
        ...state,
        email: authUser?.email,
        activeUser: { id: 0, firstName: "" },
      });
    } else {
      if (allUsers?.length > 0) {
        const user = allUsers.find((u) => u.email === authUser.email);
        if (user) {
          if (user.isArchived) {
            logout();
            history.push("/login")
          } else {
            setState({
              ...state,
              email: user.email,
              oAuthSignedIn: true,
              activeUser: user,
            });
          }
        } else {
          const config = {
            headers: { Authorization: `Bearer ${authUser.accessToken}` },
          };
          const res = await axios.post(
            "Users/PostUser",
            {
              firstName: authUser?.displayName.substring(
                0,
                authUser?.displayName?.indexOf(" ")
              ),
              lastName: authUser?.displayName
                .substring(authUser?.displayName?.indexOf(" "))
                ?.trim(),
              role: "Editor",
              firebaseUid: authUser?.uid,
              mediaAdsManager: 0,
              email: authUser.email,
            },
            config
          );
          if (res.data) {
            setState({
              ...state,
              email: res.data.email,
              oAuthSignedIn: true,
              activeUser: res.data,
            });
            const usersRes = await axios.get("Users/GetUsersForAuth");
            if (usersRes.status !== "500") {
              setState({
                ...state,
                userList: reduceUsers(usersRes.data),
                allUsers: usersRes.data,
              });
            }
          }
        }
      } else {
        const usersRes = await axios.get("Users/GetUsersForAuth");
        if (usersRes.status !== "500") {
          setState({
            ...state,
            userList: reduceUsers(usersRes.data),
            allUsers: usersRes.data,
          });
        }
      }
    }
  };

  const getAllUsers = async () => {
    try {
      const res = await axios.get("Users/GetUsersForAuth");
      if (res.status !== "500") {
        setState({
          ...state,
          userList: reduceUsers(res.data),
          allUsers: res.data,
        });
      } else {
        setState({
          ...state,
          errorMessage: res.data,
        });
      }
    } catch (err) {
      console.log(err);
      setState({
        ...state,
        errorMessage: err,
      });
    }
  };

  return (
    <UserContext.Provider
      value={{
        user: state.user,
        email: state.email,
        password: state.password,
        token: state.token,
        errorMessage: state.errorMessage,
        oAuthSignedIn: state.oAuthSignedIn,
        userList: state.userList,
        allUsers: state.allUsers,
        activeUser: state.activeUser,
        getAllUsers,
        idActiveUser,
        updateOAuthStatus,
        updateOAuthEmail,
        updateOAuthLogin,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export function useUserContext() {
  return useContext(UserContext);
}
