import {
  Badge,
  Button,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import { format } from "date-fns";
import React, { useState } from "react";
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);
const InfluencersOutreachTable = ({
  scheduleItemsDto,
  handleGlobalCheckboxTick,
  handleCheckboxChange,
  handlePreview,
}) => {
  const [allCheckbox, setAllCheckbox] = useState(false);

  const handleAllCheckbox = (event) => {
    setAllCheckbox(event.target.checked);
    handleGlobalCheckboxTick(event.target.checked);
  };
  return (
    <div>
      <Table size="small">
        <TableHead>
          <TableCell padding="checkbox">
            <Checkbox checked={allCheckbox} onChange={handleAllCheckbox} />
          </TableCell>
          <TableCell>
            <b>Name</b>
          </TableCell>
          <TableCell>
            <b>Email</b>
          </TableCell>
          <TableCell>
            <b>Preview</b>
          </TableCell>
          <TableCell>
            <b>Last Sent</b>
          </TableCell>
        </TableHead>
        <TableBody>
          {scheduleItemsDto?.map((dto, index) => (
            <TableRow key={index}>
              {dto.scheduleItemStatus === "Signed" ? (
                <TableCell padding="checkbox">
                  
                </TableCell>
              ) : (
                <TableCell padding="checkbox">
                  {dto.scheduleItemDeliverables?.length > 0 && dto.scheduleItemDeliverables.every(
                    (deliverable) => deliverable.dueDate !== null
                  ) ? (
                    <Checkbox
                      checked={dto.willReceive}
                      onChange={(event) =>
                        handleCheckboxChange(
                          dto.scheduleItemId,
                          event.target.checked
                        )
                      }
                    />
                  ) : (
                    <HtmlTooltip
                      title={
                        "Unable to Outreach! Some Deliverables are missing the DUE DATE."
                      }
                      interactive
                      arrow
                      placement="top"
                    >
                      <Badge badgeContent={"!"} color="secondary">

                      </Badge>
                    </HtmlTooltip>
                  )}
                </TableCell>
              )}
              <TableCell>{`${dto.firstName} ${dto.lastName}`}</TableCell>
              <TableCell>
                {/* TODO: Check agents implementation for influencers */}
                {dto.hasNoEmail === 0 ? (
                  `${dto.email}`
                ) : (
                  <HtmlTooltip
                    title={
                      "No personal email detected. We will use agent's email"
                    }
                    interactive
                    arrow
                    placement="top"
                  >
                    <Badge badgeContent={"!"} color="secondary">
                      {dto.email}
                    </Badge>
                  </HtmlTooltip>
                )}
              </TableCell>
              <TableCell>
                <Button
                  size="small"
                  variant="contained"
                  className="default-button"
                  onClick={() => handlePreview(dto)}
                >
                  Preview
                </Button>
              </TableCell>
              {dto.scheduleItemStatus === "Signed" ?
                <TableCell>
                  Signed
                </TableCell> :
                <TableCell>
                  {dto?.outreachDate === null
                    ? "Not Sent"
                    : format(new Date(dto?.outreachDate), "dd/MM/yy")}
                </TableCell>}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default InfluencersOutreachTable;
