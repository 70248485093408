import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    Switch,
    Radio,
    RadioGroup,
    FormControl,
    FormLabel,
    Chip,
    FormGroup
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import Spinner from "../../../Shared/Spinner/Spinner";
import axios from "axios";
import "./accountContactDetails.scss";

const AccountContactDetailsDialog = ({
    open,
    handleClose,
    companyId,
    setSnackBarProps
}) => {
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [allAccountsEmails, setAllAccountsEmails] = useState([]);
    const [companyDetails, setCompanyDetails] = useState(null);
    const [ccAccountsEmailInput, setCcAccountsEmailInput] = useState("");

    const defaultDescriptionLine = "{{month}} Media - {{jobId}} {{campaign}} - {{platform}} {{poNumber}}";
    const splitByOptions = [
        {
            id: "CAMPAIGN_NAME",
            value: "Campaign name",
        },
        {
            id: "PLATFORM",
            value: "Platform",
        },
        {
            id: "PO_NUMBER",
            value: "PO Number",
        },
        {
            id: null,
            value: "No Splitting",
        },
    ];

    const getAccountEmailChips = () => {
        return allAccountsEmails.map((email) => {
            return (
                <Chip
                    variant="outlined"
                    label={email}
                    size="small"
                    onDelete={() => {
                        var newArr = [...allAccountsEmails];
                        setAllAccountsEmails(newArr.filter((e) => e !== email));
                    }}
                    style={{ margin: 5 }}
                />
            );
        });
    };

    const getCompanyData = () => {
        setLoading(true);
        axios.get(`Companies/GetCompanyAccountDetails/${companyId}`)
            .then(({ data }) => {
                setCompanyDetails(data);
                setAllAccountsEmails(data.accountsEmailsCc ? data.accountsEmailsCc.split(";") : [])
            }).catch(e => {
                console.log(e);
            }).then(() => {
                setLoading(false);
            })
    }

    const handleSave = () => {
        setSaving(true);
        var body = {};
        if (allAccountsEmails.length > 0) {
            body = {
                ...companyDetails,
                accountsEmailsCc: allAccountsEmails.join(";")
            }
        } else {
            body = {
                ...companyDetails,
                accountsEmailsCc: null
            }
        }
        axios.put(`Companies/UpdateCompanyAccountDetails/${companyDetails.id}`, body)
            .then(({ data }) => {
                setSnackBarProps({
                    open: true,
                    severity: "success",
                    text: "Account details have been saved.",
                });
                handleClose();
            }).catch(e => {
                console.log(e);
                setSnackBarProps({
                    open: true,
                    severity: "warning",
                    text: "Failed to save new settings.",
                });
            }).then(() => {
                setSaving(false);
            })
    }

    useEffect(() => {
        getCompanyData();
    }, [])

    return (
        <Dialog open={open} maxWidth="md" fullWidth id="account-contact-details-dialog">
            <DialogTitle>
                <div className="title-container">
                    <Typography variant="h6" className="roboto-bold">Account Contact Details</Typography>
                    <div className="close-icon-container">
                        <IconButton
                            size="small"
                            onClick={handleClose}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                {loading ?
                    <Spinner /> :
                    <div className="dialog-body-container">
                        <TextField
                            id="primary-accounts-email"
                            size="small"
                            label="Primay Accounts Email"
                            fullWidth
                            variant="filled"
                            value={companyDetails.primaryAccountsEmail}
                            onChange={(e) => {
                                setCompanyDetails({
                                    ...companyDetails,
                                    primaryAccountsEmail: e.target.value
                                })
                            }}
                        />
                        <TextField
                            value={
                                companyDetails?.mediaInvoiceDescriptionLine ||
                                defaultDescriptionLine
                            }
                            label="Description Line"
                            size="small"
                            fullWidth={true}
                            variant="filled"
                            onChange={(e) =>
                                setCompanyDetails({
                                    ...companyDetails,
                                    mediaInvoiceDescriptionLine: e.target.value,
                                })
                            }
                        />
                        <div>
                            <TextField
                                id="primary-accounts-email"
                                size="small"
                                label="Add CC accounts email"
                                fullWidth
                                variant="filled"
                                value={ccAccountsEmailInput}
                                onChange={(e) => { setCcAccountsEmailInput(e.target.value) }}
                                onKeyDown={(e) => {
                                    if (
                                        e.key === "Enter" &&
                                        e.target.value !== null &&
                                        e.target.value !== undefined &&
                                        e.target.value !== ""
                                    ) {
                                        var newArr = [...allAccountsEmails];
                                        newArr.push(e.target.value.trim());
                                        setAllAccountsEmails(newArr);
                                        setCcAccountsEmailInput("");
                                    }
                                }}
                            />
                            {getAccountEmailChips()}
                        </div>
                        <div>
                            <FormControl component="fieldset">
                                <FormLabel>Payment Terms:</FormLabel>
                                <RadioGroup aria-label="payment-terms" name="payment-terms" value={companyDetails.paymentTerms}
                                    onChange={(e) => {
                                        setCompanyDetails({
                                            ...companyDetails,
                                            paymentTerms: e.target.value
                                        })
                                    }}>
                                    <FormControlLabel value="20" control={<Radio />} label="20th month following" />
                                    <FormControlLabel value="30" control={<Radio />} label="30th month following" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div>
                            <FormControlLabel
                                control={
                                    <Switch
                                        color="primary"
                                        onChange={(e) =>
                                            setCompanyDetails({
                                                ...companyDetails,
                                                isSingleFileAttachment: e.target.checked ? 1 : 0,
                                                isSingleEmailPerInvoice: e.target.checked ? 0 : companyDetails.isSingleEmailPerInvoice
                                            })
                                        }
                                        checked={companyDetails.isSingleFileAttachment == 1}
                                    />
                                }
                                label="Combine invoices into one single PDF"
                                labelPlacement="end"
                            />
                            {companyDetails?.isSingleFileAttachment != 1 ? (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="primary"
                                            onChange={(e) =>
                                                setCompanyDetails({
                                                    ...companyDetails,
                                                    isSingleEmailPerInvoice: e.target.checked ? 1 : 0
                                                })
                                            }
                                            checked={companyDetails.isSingleEmailPerInvoice == 1}
                                        />
                                    }
                                    label="Send one email per invoice"
                                    labelPlacement="end"
                                />
                            ) : <div> </div>}
                        </div>
                        <div>
                            <FormLabel>Split Invoices By:</FormLabel>
                            <FormGroup id="split-by-form-group">
                                {splitByOptions.map((option) => (
                                    <FormControlLabel
                                        key={option.id}
                                        control={
                                            <Checkbox
                                                checked={
                                                    companyDetails?.mediaInvoiceBy === option.id
                                                }
                                                onChange={(e) =>
                                                    setCompanyDetails({
                                                        ...companyDetails,
                                                        mediaInvoiceBy: option.id,
                                                    })
                                                }
                                                name="splitBy"
                                            />
                                        }
                                        label={option.value}
                                    />
                                ))}
                            </FormGroup>
                        </div>
                    </div>
                }
            </DialogContent>
            <DialogActions>
                {(saving ? (
                    <div style={{ padding: 10 }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <div className="account-contact-details-action-container">
                        <Button
                            style={{ marginRight: 5 }}
                            className="default-button-grey add-budget-button"
                            onClick={(e) => {
                                handleClose();
                            }}
                            disabled={saving}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ marginLeft: 5 }}
                            className="default-button add-budget-button"
                            onClick={handleSave}
                            disabled={loading || saving}
                        >
                            Save
                        </Button>
                    </div>
                ))}
            </DialogActions>
        </Dialog>
    );
};

export default AccountContactDetailsDialog;
