import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
  compositeDecorator,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import Typography from "@material-ui/core/Typography";
import Axios from "axios";
import { Button, Snackbar, TextField } from "@material-ui/core";
import "./Admin.css";
import MuiAlert from "@material-ui/lab/Alert";
import Loader from "../Loader";
import { getCurrentTime } from "../../utils/TimeHelpers";
import { SendScheduleMergeTagOption as MergeTagOptions } from "../../utils/ToolbarCustomOptions";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));
const InfluencerContentReminderEmail = () => {
  const classes = useStyles();
  const [successCreate, setSuccessCreate] = useState(false);
  const [failedCreate, setFailedCreate] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [render, setRender] = useState(false);
  useEffect(() => {
    getEmailTemplate();
  }, []);
  useEffect(() => {
    if (emailTemplate?.template) {
      const blocksFromHTML = convertFromHTML(emailTemplate.template);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(state, compositeDecorator));
      setRender(true);
    }
  }, [emailTemplate]);

  const getEmailTemplate = () => {
    Axios.get(`HtmlTemplate/GetHtmlTemplate/InfluencerContentReminderEmail`)
      .then(({ data }) => {
        setEmailTemplate(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const closeSuccessCreateSnackbar = () => {
    setSuccessCreate(false);
  };

  const closeFailedCreateSnackbar = () => {
    setFailedCreate(false);
  };

  var snackbarSuccess = (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={successCreate}
      autoHideDuration={2000}
      onClose={closeSuccessCreateSnackbar}
    >
      <MuiAlert severity="success">Successfully Saved Changes!</MuiAlert>
    </Snackbar>
  );

  var snackbarFailed = (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={failedCreate}
      autoHideDuration={2000}
      onClose={closeFailedCreateSnackbar}
    >
      <MuiAlert severity="error">Failed to Save Changes!</MuiAlert>
    </Snackbar>
  );

  const clearEmailTemplate = () => {
    setEditorState(EditorState.createEmpty());
    getEmailTemplate();
  };

  const saveChanges = () => {
    Axios.put(`htmltemplate/UpdateHtmlTemplate`, {
      ...emailTemplate,
      template: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    })
      .then((res) => {
        setSuccessCreate(true);
      })
      .catch((e) => {
        console.log(e);
        setFailedCreate(true);
      });
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleChange = (event) => {
    setEmailTemplate({ ...emailTemplate, name: event.target.value });
  };

  if (render) {
    return (
      <main className={classes.content}>
        <div className="main-content" />
        <div
          style={{ display: "flex", marginLeft: "auto", marginRight: "0px" }}
        >
          <Typography variant="h4" style={{ marginLeft: "15px" }}>
            Influencer Content Reminder Email Template
          </Typography>
          <div className="textfield-padding-schedule">
            <TextField
              variant="outlined"
              label="Subject"
              value={emailTemplate.name}
              onChange={(event) => handleChange(event)}
            />
          </div>
          <div className="contracts-buttons-div-schedule">
            <Button
              variant="contained"
              className="contract-button"
              onClick={clearEmailTemplate}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="contract-button"
              onClick={saveChanges}
            >
              Save
            </Button>
          </div>
        </div>
        <div className="text-editor-container">
          <Editor
            editorState={editorState}
            wrapperClassName="text-editor-wrapper"
            editorClassName="text-editor-schedule"
            onEditorStateChange={onEditorStateChange}
            handlePastedText={() => false}
            toolbarCustomButtons={[
              <MergeTagOptions
                onChange={onEditorStateChange}
                editorState={editorState}
              />,
            ]}
          />
        </div>

        {snackbarSuccess}
        {snackbarFailed}
      </main>
    );
  } else {
    return <Loader />;
  }
};

export default InfluencerContentReminderEmail;
