import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { getUserRoles } from "../../../utils/GeneralUtils";

const UserDetailDialog = ({ open, handleClose, user, updateUser }) => {
  const [localUser, setLocalUser] = useState(user);

  const handleChange = (field, value) => {
    setLocalUser({ ...localUser, [field]: value });
  };
  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <div className="close-and-transfer-dialog-close-icon-container">
        <IconButton
          size="small"
          className="close-and-transfer-dialog-close-icon"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <DialogTitle style={{ display: "grid" }}>
        <Typography
          variant="h5"
          className="roboto-bold"
        >{`Update User Details`}</Typography>
        <DialogContentText style={{ textAlign: "left" }}>
          <Typography className="roboto-regular add-budget-description">
            Enter the user details.
          </Typography>
        </DialogContentText>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "grid",
            gap: "20px",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <TextField
            variant="filled"
            label="First Name"
            fullWidth
            value={localUser.firstName}
            onChange={(e) => handleChange("firstName", e.target.value)}
            size="small"
          />
          <TextField
            variant="filled"
            label="Last Name"
            fullWidth
            value={localUser.lastName}
            onChange={(e) => handleChange("lastName", e.target.value)}
            size="small"
          />
          <TextField
            variant="filled"
            label="Email"
            fullWidth
            value={localUser.email}
            onChange={(e) => handleChange("email", e.target.value)}
            size="small"
          />
          <TextField
            variant="filled"
            label="Phone Number"
            fullWidth
            value={localUser.phoneNumber}
            onChange={(e) => handleChange("phoneNumber", e.target.value)}
            size="small"
          />
          <Autocomplete
            options={getUserRoles()}
            value={localUser.role}
            onChange={(event, option) => {
              handleChange("role", option);
            }}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Role"}
                fullWidth={true}
                variant="filled"
              />
            )}
            disableClearable
            fullWidth
            size="small"
          />
        </div>
      </DialogContent>
      <DialogActions style={{ textAlign: "center" }}>
        <Button onClick={handleClose} className="default-button-grey">
          Cancel
        </Button>
        <Button
          className="default-button"
          onClick={async () => {
            await updateUser(localUser);
            handleClose();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserDetailDialog;
