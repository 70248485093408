import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import {
    ButtonGroup,
    Grid,
    Grow,
    Button,
    Popper,
    Paper,
    MenuList,
    MenuItem,
    ClickAwayListener,
} from "@material-ui/core";
import "./MediaAds.css";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const changeButton = (contractStatus) => {
    switch (contractStatus) {
        case "Pending":
            return {
                color: "#FFA500",
                options: ["Pending", "Send To Xero", "Reject"],
            };
        case "Approved":
            return {
                color: "#008000",
                options: ["Approved"],
            };
        case "Rejected":
            return {
                color: "#FF0000",
                options: ["Rejected"],
            };
        default:
            return { color: "#c8c8c8", options: ["Error"] };
    }
};

const MediaAdsButton = (props) => {
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [buttonProps, setButtonProps] = useState(
        changeButton(props.mediaAd.status)
    );

    const anchorRef = useRef(null);
    let mediaAd = props.mediaAd;

    useEffect(() => {
        setButtonProps(changeButton(mediaAd.status));
    }, [mediaAd.status]);

    const handleClick = () => { };

    const adIsValid = () => {
        if (mediaAd.client.trim() == "" ||
            mediaAd.amount == null ||
            mediaAd.platform.trim() == "" ||
            mediaAd.transactionId.trim() == "" ||
            mediaAd.transactionDate == null ||
            mediaAd.driveId == null || mediaAd.driveId.trim() == "" ||
            mediaAd.gstRateCode == null || mediaAd.gstRateCode.trim() == "" ||
            mediaAd.accountCode == null) {
            return false;
        }
        return true;
    }

    const handleMenuItemClick = (event, index) => {
        if (buttonProps.options[index] === "Send To Xero") {
            if (adIsValid()) {
                props.updateMediaAd("status", "Approved", mediaAd)
            }
            else {
                props.setSnackBarProps("warning", `Cannot send. One of the fields is missing.`, true)
            }
        } else if (buttonProps.options[index] === "Reject") {
            props.updateMediaAd("status", "Rejected", mediaAd)
        }
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        setOpen(false);
    };

    return (
        <Grow in={true} mountOnEnter unmountOnExit>
            <Grid container alignItems="center">
                <Grid item xs={12}>
                    <ButtonGroup
                        variant="contained"
                        ref={anchorRef}
                        aria-label="split button"
                        size="small"
                        style={{
                            width: "120px",
                            backgroundColor: buttonProps.color,
                        }}
                    >
                        <Button
                            onClick={handleClick}
                            style={{
                                padding: "0px 2px 0px 2px",
                                width: "120px",
                                backgroundColor: buttonProps.color,
                                color: "white",
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                            }}
                        >
                            {buttonProps.options[selectedIndex]}
                        </Button>
                        <Button
                            size="small"
                            aria-controls={open ? "split-button-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-label="select declined option"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                            style={{
                                width: "15px",
                                backgroundColor: buttonProps.color,
                                color: "white",
                            }}
                        >
                            <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        style={{
                            zIndex: "999",
                        }}
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === "bottom" ? "center top" : "center bottom",
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu">
                                            {buttonProps.options.map(
                                                (option, index) =>
                                                    index > 0 && (
                                                        <MenuItem
                                                            key={option}
                                                            disabled={index === 0}
                                                            selected={index === selectedIndex}
                                                            onClick={(event) =>
                                                                handleMenuItemClick(event, index)
                                                            }
                                                        >
                                                            {option}
                                                        </MenuItem>
                                                    )
                                            )}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Grid>
            </Grid>
        </Grow>
    );
};

MediaAdsButton.propTypes = {
    mediaAd: PropTypes.object.isRequired,
    setSnackBarProps: PropTypes.func.isRequired,
    updateMediaAd: PropTypes.func.isRequired
}

export default MediaAdsButton;
