import React, { useEffect, useState } from "react";
import ClientDeliverablesRow from "./ClientDeliverablesRow";

const ClientDeliverableTable = ({
    clientDeliveries
}) => {

    return (<div className="table-responsive">
        <table className="custom-table custom-table-striped roboto-regular">
            <thead>
                <tr>
                    <th className="roboto-bold media-delivery-table-head"></th>
                    <th className="roboto-bold media-delivery-table-head">Created On</th>
                    <th className="roboto-bold media-delivery-table-head">Title</th>
                    <th className="roboto-bold media-delivery-table-head">Key Number</th>
                    <th className="roboto-bold media-delivery-table-head">Duration</th>
                    <th className="roboto-bold media-delivery-table-head">Status</th>
                    <th className="roboto-bold media-delivery-table-head">First Air Date</th>
                    <th className="roboto-bold media-delivery-table-head text-center">Quantity</th>
                    <th className="roboto-bold media-delivery-table-head text-center">AdStream</th>
                    <th className="roboto-bold media-delivery-table-head text-center">Cab</th>
                    <th className="roboto-bold media-delivery-table-head text-center">Grand Total</th>
                </tr>
            </thead>
            <tbody>
                {clientDeliveries.map((delivery) => (
                    <ClientDeliverablesRow
                        key={delivery.id}
                        delivery={delivery}
                    />
                ))}
            </tbody>
        </table>
    </div>)
}

export default ClientDeliverableTable;