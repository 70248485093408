import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";
import "./Admin.css";
import {
  TextField,
  Button,
  Typography,
  Grid,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Loader from "../Loader";
import { PermDeviceInformationOutlined } from "@material-ui/icons";
import parse from "autosuggest-highlight/parse";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const TravelContribution = (props) => {
  const classes = useStyles();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [travelContribution, settravelContribution] = useState({
    travelDistance: null,
    rate: null,
  });
  const [successCreate, setSuccessCreate] = useState(false);
  const [failedCreate, setFailedCreate] = useState(false);

  useEffect(() => {
    Axios.get("TravelContributions/GetTravelContribution")
      .then(({ data }) => {
        settravelContribution(data);
        setDataLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveChanges = () => {
    Axios.put("TravelContributions/PutTravelContribution/", {
      id: 1,
      travelDistance: travelContribution.travelDistance,
      rate: parseFloat(travelContribution.rate),
    })
      .then((res) => {
        setSuccessCreate(true);
      })
      .catch((err) => {
        setFailedCreate(true);
      });
  };

  const handleChange = (event, text) => {
    var newValue = event.target.value;
    switch (text) {
      case "Minimum distance":
        settravelContribution({
          ...travelContribution,
          travelDistance: newValue,
        });
        break;
      case "Rate":
        settravelContribution({
          ...travelContribution,
          rate: newValue,
        });
        break;
      default:
        break;
    }
  };

  const getField = (text, value, type) => (
    <div className="textfield-padding">
      <TextField
        id={text}
        label="Outlined"
        variant="outlined"
        label={text}
        value={value.toString()}
        onChange={(event) => handleChange(event, text)}
        fullWidth
        type={type}
      />
    </div>
  );

  const closeSuccessCreateSnackbar = () => {
    setSuccessCreate(false);
  };

  const closeFailedCreateSnackbar = () => {
    setFailedCreate(false);
  };

  const snackbarSuccess = (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={successCreate}
      autoHideDuration={2000}
      onClose={closeSuccessCreateSnackbar}
    >
      <MuiAlert severity="success">Successfully Saved Changes!</MuiAlert>
    </Snackbar>
  );

  const snackbarFailed = (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={failedCreate}
      autoHideDuration={2000}
      onClose={closeFailedCreateSnackbar}
    >
      <MuiAlert severity="error">Failed to Save Changes!</MuiAlert>
    </Snackbar>
  );

  return (
    <main className={classes.content}>
      <div className="main-content" />
      <Typography variant="h4">Travel Contribution</Typography>
      {dataLoaded && (
        <Grid container className="table-grid">
          <Grid item xs={12} md={4} lg={3}>
            {getField(
              "Minimum distance",
              travelContribution.travelDistance,
              "number"
            )}
            {getField("Rate", travelContribution.rate, "number")}
            <div className="button-div">
              <Button color="primary" variant="contained" onClick={saveChanges}>
                Save
              </Button>
            </div>
          </Grid>
        </Grid>
      )}
      {snackbarSuccess}
      {snackbarFailed}
    </main>
  );
};

export default TravelContribution;
