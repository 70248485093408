import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import { Close as CloseIcon } from "@material-ui/icons";
import moment from "moment";
import ProcessingSpinner from "../../../Shared/Spinner/ProcessingSpinner";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import axios from "axios";
import "./tvSpotlistDialog.scss";
import { get3monthsBeforeAndAfterCurrentDate } from "../../../../utils/TimeHelpers";
import Snacky from "../../../Shared/Snacky";
import ProcessingSpinnerDialog from "../../../Shared/Spinner/ProcessingSpinnerDialog";
const TvSpotlistDialog = ({ open, handleClose, companyId, company }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [tvSpotlistRecords, setTvSpotlistRecords] = useState([]);
  const [selectedBillingMonth, setSelectedBillingMonth] = useState(moment());
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });
  useEffect(() => {
    getTvSpotlistRecords();
  }, []);

  const getTvSpotlistRecords = () => {
    setIsLoading(true);
    axios
      .get(`mediacampaign/GetTvSpotlistByCompanyId/${companyId}`)
      .then(({ data }) => {
        setTvSpotlistRecords(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  };

  const generateTvSpotlist = () => {
    setIsGenerating(true);
    axios
      .post(
        `mediacampaign/CreateTvSpotlist/${companyId}/${
          selectedBillingMonth.month() + 1
        }/${selectedBillingMonth.year()}`
      )
      .then(({ data }) => {
        setTvSpotlistRecords([data, ...tvSpotlistRecords]);
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "All Done! The file is now available.",
        });
      })
      .catch((err) => {
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Oh Snap! Something went wrong. Dev Team has been notified.",
        });
      })
      .finally(() => setIsGenerating(false));
  };

  const handleOpenFile = (fileId) => {
    window.open(
      `https://docs.google.com/spreadsheets/d/${fileId}/edit`,
      "_blank"
    );
  };
  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <Snacky snackprops={snackBarProps} setSnackBarProps={setSnackBarProps} />
      {isGenerating && <ProcessingSpinnerDialog loading={isGenerating} />}
      <div className="close-and-transfer-dialog-close-icon-container">
        <IconButton
          size="small"
          className="close-and-transfer-dialog-close-icon"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <DialogTitle style={{ display: "grid" }}>
        <Typography
          variant="h5"
          className="roboto-bold"
        >{`TV Spotlist`}</Typography>
      </DialogTitle>
      <DialogContent>
        <div className="tv-spotlist-dialog-action-container">
          <Autocomplete
            value={
              selectedBillingMonth
                ? {
                    display: selectedBillingMonth.format("MMMM YYYY"),
                    value: selectedBillingMonth,
                  }
                : null
            }
            options={get3monthsBeforeAndAfterCurrentDate()}
            onChange={(event, option) => {
              setSelectedBillingMonth(moment(option.value));
            }}
            getOptionLabel={(option) => option.display}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Month"}
                fullWidth
                size="small"
                variant="outlined"
              />
            )}
            disableClearable
            className="autocomplete-full-width"
          />

          <Button
            className={"default-button add-budget-button"}
            size="small"
            onClick={(e) => generateTvSpotlist(companyId)}
          >
            Generate TV Spotlist
          </Button>
        </div>
      </DialogContent>
      <DialogContent>
        <div>
          {isLoading ? (
            <>
              <Skeleton height={20} />
              <Skeleton height={20} />
              <Skeleton height={20} />
            </>
          ) : (
            <div className="tv-spotlist-dialog-record-container">
              {tvSpotlistRecords.map((record, index) => (
                <div className="tv-spotlist-dialog-record-card" key={index}>
                  <Typography variant="h5">
                    {moment(record?.date).format("MMM YYYY")}
                  </Typography>
                  {record?.fileId ? (
                    <Button
                      startIcon={<LaunchIcon />}
                      onClick={(e) => handleOpenFile(record?.fileId)}
                    >
                      Go to File
                    </Button>
                  ) : (
                    <Typography variant="caption">Not Available</Typography>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TvSpotlistDialog;
