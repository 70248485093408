import axios from "axios";
import { createAction } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { GET_BUDGET } from "./budgetTypes";

// This return all associated data for the schedule wrapper component
export const resetSchedule = createAction("RESET_SCHEDULE");
export const resetProject = createAction("RESET_PROJECT");
export const addScheduleLocation = createAction("ADD_SCHEDULE_LOCATION");
export const updateLocationContact = createAction("UPDATE_LOCATION_CONTACT");
export const resetScheduleTable = createAction("RESET_SCHEDULE_TABLE");
export const promoteScheduleLocation = createAction("PROMOTE_RETURN_SCHEDULE");
export const noLocationAlert = createAction("NO_LOCATION_ALERT");

export const editLocationContract = (location, prev, next) => async (
  dispatch
) => {
  try {
    dispatch({
      type: "EDIT_SCHEDULE_LOCATION",
      payload: { location, field: "contractStatus", value: next },
    });
  } catch (err) {
    dispatch({
      type: "EDIT_SCHEDULE_LOCATION_ERROR",
      payload: err,
    });
  }
};

export const getSchedule = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`Schedules/GetSchedule/${id}`);
    dispatch({
      type: "GET_SCHEDULE",
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: "GET_SCHEDULE_ERROR",
      payload: err,
    });
  }
};

export const createSchedule = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_SCHEDULE",
      payload: {
        name: "",
        date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        scheduleLocations: [],
        projectsFk: id,
      },
    });
  } catch (err) {
    dispatch({
      type: "CREATE_SCHEDULE_ERROR",
      payload: err,
    });
  }
};

export const createNewSchedule = (
  schedule,
  newLocation,
  scheduleLocation
) => async (dispatch) => {
  try {
    var res = await axios.post(`Schedules/PostSchedule`, {
      ...schedule,
      startTime: format(
        new Date(schedule.scheduleLocations[0].startTime),
        "yyyy-MM-dd HH:mm:ss"
      ),
      endTime: format(
        new Date(schedule.scheduleLocations[0].endTime),
        "yyyy-MM-dd HH:mm:ss"
      ),
      scheduleLocations: null,
    });
    dispatch({
      type: "CREATE_NEW_SCHEDULE",
      payload: res.data,
    });
    axios
      .post(`ScheduleLocation/PostScheduleLocation`, {
        ...scheduleLocation,
        scheduleFk: res.data.id,
        locationFk: newLocation.id,
        locationFkNavigation: newLocation,
      })
      .then((res) => {
        console.log(res.data);
        dispatch({
          type: "ADD_SCHEDULE_LOCATION_ON_CREATE",
          payload: res.data,
        });
      });
  } catch (err) {
    dispatch({
      type: "CREATE_NEW_SCHEDULE_ERROR",
      payload: err,
    });
  }
};

export const updateScheduleProgress = (field, value, schedule) => async (
  dispatch
) => {
  try {
    axios.patch(`Schedules/PatchScheduleProgress/${schedule.id}`, {
      ...schedule,
      [field]: value,
    });
    try {
      dispatch({
        type: "EDIT_SCHEDULE",
        payload: { field, value },
      });
    } catch (err) {
      dispatch({
        type: "EDIT_SCHEDULE_ERROR",
        payload: err,
      });
    }
  } catch (err) {
    dispatch({
      type: "UPDATE_PROGRESS_ERROR",
      payload: err,
    });
  }
};

export const cancelCatering = (schedule, catering) => async (dispatch) => {
  await axios.post(`Catering/CancelCateringOrder/${schedule.id}/${catering.id}`, catering)
    .then((res) => {
      dispatch({
        type: "EDIT_SCHEDULE",
        payload: {
          field: "cateringOrdered",
          value: 0,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: "EDIT_SCHEDULE_ERROR",
        payload: err,
      });
    });
};

export const rescheduleCatering = (schedule, catering, newDate) => async (dispatch) => {
  await axios.post(`Catering/RescheduleCateringOrder/${schedule.id}/${catering.id}`, {
    previousCatering: catering,
    newDate: newDate
  })
    .then((res) => {
      dispatch({
        type: "UPDATE_CATERING",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: "RESCHEDULE_CATERING_ERROR",
        payload: err,
      });
    });
};

export const editSchedule = (field, value, schedule, makeAxios = 1) => async (
  dispatch
) => {
  if (schedule.id != undefined) {
    if (makeAxios == 1 && field !== "date") {
      axios.put(`Schedules/PutSchedule/${schedule.id}`, {
        ...schedule,
        [field]: value,
      });
    }
    if (field === "date" && schedule.id != undefined) {
      axios.put(`Schedules/UpdateAllDatesForSchedule/${schedule.id}/${value}`);
      try {
        dispatch({
          type: "EDIT_SCHEDULE_DATE",
          payload: {
            value,
          },
        });
      } catch (err) {
        dispatch({
          type: "EDIT_SCHEDULE_ERROR",
          payload: err,
        });
      }
    }
  }
  try {
    dispatch({
      type: "EDIT_SCHEDULE",
      payload: {
        field: field,
        value: value,
      },
    });
  } catch (err) {
    dispatch({
      type: "EDIT_SCHEDULE_ERROR",
      payload: err,
    });
  }
};
// This data is for the use of the schedules table
export const getSchedulesForTable = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`Schedules/GetSchedulesForTable/${id}`);
    dispatch({
      type: "GET_SCHEDULES_FOR_TABLE",
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: "SCHEDULES_ERROR",
      payload: err,
    });
  }
};

export const deleteScheduleLocation = (sl, schedule, location) => async (
  dispatch
) => {
  try {
    if (schedule.scheduleLocations.length === 1) {
      dispatch(noLocationAlert(true));
    } else {
      if (location.id !== "blank") {
        axios
          .delete(`ScheduleLocation/DeleteScheduleLocation/${sl.id}`)
          .then((res) => {
            axios
              .get(`Schedules/GetSandETimes/${schedule.id}`)
              .then((res) =>
                dispatch({ type: "UPDATE_SCHEDULE_TIMES", payload: res.data })
              );
          });
      }
      dispatch({
        type: "DELETE_SCHEDULE_LOCATION",
        payload: { sl },
      });
    }
  } catch (err) {
    dispatch({
      type: "ERROR",
      payload: err,
    });
  }
};

export const changeLocation = (oldLocation, newLocation, sl) => async (
  dispatch
) => {
  try {
    // This is a new schedule location
    if (oldLocation.id === "blank") {
      var res = await axios.post(`ScheduleLocation/PostScheduleLocation`, {
        ...sl,
        locationFk: newLocation.id,
        locationFkNavigation: newLocation,
      });
      dispatch({
        type: "CHANGE_LOCATION_ON_CREATE",
        payload: { oldLocation, newLocation, id: res.data.id },
      });
    } else {
      // This is an existing entry, changing it's location
      axios.patch(`ScheduleLocation/PatchScheduleLocation/${sl.id}`, {
        ...sl,
        locationFk: newLocation.id,
        locationFkNavigation: newLocation,
      });
      dispatch({
        type: "CHANGE_LOCATION",
        payload: { oldLocation, newLocation, id: sl.id },
      });
    }
  } catch (err) {
    dispatch({
      type: "ERROR",
      payload: err,
    });
  }
};

export const editScheduleLocation = (
  schedule = {},
  scheduleLocation,
  field,
  value,
  makeAxios = 1
) => async (dispatch) => {
  if (field === "startTime" || field === "endTime") {
    value = schedule.date.substring(0, 10) + "T" + value;
  }
  try {
    if (makeAxios == 1) {
      axios
        .patch(
          `ScheduleLocation/PatchScheduleLocation/${scheduleLocation.id}`,
          {
            ...scheduleLocation,
            [field]: value,
          }
        )
        .then((res) => {
          if (field === "startTime" || field === "endTime") {
            axios
              .get(`Schedules/GetSandETimes/${scheduleLocation.scheduleFk}`)
              .then((res) =>
                dispatch({ type: "UPDATE_SCHEDULE_TIMES", payload: res.data })
              );
          }
        });
    }
    dispatch({
      type: "EDIT_SCHEDULE_LOCATION",
      payload: { scheduleLocation, field, value },
    });
    if (field == "fee" && value == null) {
      axios.post(
        `LocationContracts/CancelLocationContract/${scheduleLocation.locationFk}/${scheduleLocation.scheduleFk}`
      );
      dispatch({
        type: "EDIT_SCHEDULE_LOCATION",
        payload: { scheduleLocation, field: "contractStatus", value: "Send" },
      });
    }
  } catch (err) {
    dispatch({
      type: "EDIT_SCHEDULE_LOCATION_ERROR",
      payload: err,
    });
  }
};

export const getProject = (projectId) => async (dispatch) => {
  try {
    await axios.get(`/Projects/GetProjects/${projectId}`).then((res) =>
      dispatch({
        type: "GET_PROJECT",
        payload: { project: res.data },
      })
    );
  } catch (err) {
    dispatch({
      type: "GET_PROJECT_ERROR",
      payload: err,
    });
  }
};

export const editProject = (key, value, project) => async (dispatch) => {
  try {
    axios
      .patch(`/Projects/PatchProjectProgress/${project.id}`, {
        id: project.id,
        outputStage: project.outputStage,
        scriptStage: project.scriptStage,
        prePostStage: project.prePostStage,
        scheduleStage: project.scheduleStage,
      })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    dispatch({
      type: "EDIT_PROJECT",
      payload: { key, value },
    });
  } catch (err) {
    dispatch({
      type: "EDIT_PROJECT_ERROR",
      payload: err,
    });
  }
};

export const getScheduleDates = () => async (dispatch) => {
  try {
    await axios.get(`Schedules/GetAllActiveScheduleDates`).then((res) =>
      dispatch({
        type: "GET_SCHEDULE_DATES",
        payload: res.data,
      })
    );
  } catch (err) {
    dispatch({
      type: "GET_SCHEDULE_DATES_ERROR",
      payload: err,
    });
  }
};

export const archiveSchedule = (id, index) => async (dispatch) => {
  try {
    axios.post(`/Schedules/ArchiveSchedule/${id}`);
    dispatch({
      type: "ARCHIVE_SCHEDULE",
      payload: { id, index },
    });
    dispatch({
      type: "SET_SNACKBAR_PROPS",
      payload: { severity: "success", text: "Schedule Archived", open: true },
    });
  } catch (err) {
    dispatch({
      type: "ARCHIVE_SCHEDULE_ERROR",
      payload: err,
    });
    dispatch({
      type: "SET_SNACKBAR_PROPS",
      payload: {
        severity: "failure",
        text: "Couldn't Archive Schedule",
        open: true,
      },
    });
  }
};
