import React, { useEffect, useState, useRef } from "react";
import {
  AppBar,
  Typography,
  Collapse,
  Toolbar,
  Paper,
  Tooltip,
} from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import { withStyles } from "@material-ui/core/styles";
import "./Home.css";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { getFirstDeadline, convertDate } from "../../utils/OutputsHelpers";
import daysLeft from "../../utils/workingDaysLeft";
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: 12,
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export const OrphanProjectCard = (props) => {
  const [showOutputs, setshowOutputs] = useState(null);
  const [deadline, setDeadline] = useState();
  const [tMinus, setTMinus] = useState();

  useEffect(() => {
    if (props.project.outputs.length > 0) {
      var firstDeadline = getFirstDeadline(props.project.outputs);
      if (firstDeadline !== undefined) {
        setDeadline(convertDate(firstDeadline));
        setTMinus(daysLeft(new Date(firstDeadline)));
      } else {
        setDeadline(firstDeadline);
        setTMinus(firstDeadline);
      }
    }
  }, [props.project.outputs]);
  useEffect(() => {
    setshowOutputs(null);
  }, [props.openAll]);
  const toggleCardOutputs = () => {
    if (showOutputs === null) {
      setshowOutputs(false);
    } else if (showOutputs === false) {
      setshowOutputs(true);
    } else if (showOutputs === true) {
      setshowOutputs(false);
    }
  };

  return (
    <Paper
      className="droppable"
      elevation={6}
      style={{
        display: new Date(props.project.deadline) < new Date() ? "none" : "",
      }}
    >
      <HtmlTooltip
        title={
          <React.Fragment>
            <em>{props.project.projectName}</em>{" "}
            <p>{props.project.projectTag}</p>{" "}
            <u>{props.project.customerName}</u>{" "}
          </React.Fragment>
        }
      >
        <AppBar position="static" className="project-appbar">
          <Toolbar variant="dense" className="project-toolbar">
            {showOutputs || props.openAll ? (
              <ArrowDropDownIcon
                onClick={toggleCardOutputs}
                style={
                  deadline != null
                    ? { cursor: "default", float: "left" }
                    : { visibility: "hidden", float: "left" }
                }
              />
            ) : (
              <ArrowRightIcon
                onClick={toggleCardOutputs}
                style={
                  deadline != null
                    ? { cursor: "default", float: "left" }
                    : { visibility: "hidden", float: "left" }
                }
              />
            )}
            <Typography className="typography-project-appbar">
              {props.project.customerName + " - " + props.project.projectName}
            </Typography>
          </Toolbar>
        </AppBar>
      </HtmlTooltip>
      <Collapse in={showOutputs === null ? props.openAll : showOutputs}>
        {props.project.outputs.map((output, index) => {
          return (
            <Paper key={output.id} className="output-container" elevation={4}>
              <Typography className="typography-output-card">
                {output.adDuration +
                  " - " +
                  output.creativeName +
                  (output.platform != null ? " | " + output.platform : "")}
              </Typography>
              {output.delivered == 1 && (
                <CheckCircle
                  style={{
                    color: "#4dfa59",
                    float: "right",
                    height: 15,
                    width: 15,
                    backgroundColor: "white",
                    marginTop: "-10px",
                  }}
                />
              )}
            </Paper>
          );
        })}
      </Collapse>
      <div>
        {props.project.outputs.length > 0 ? (
          <div className="project-card-footer">
            <div className={tMinus > 5 ? "deadline-green" : "deadline-red"}>
              <Typography className="project-card-footer-typography">
                {deadline}
              </Typography>
            </div>
            {tMinus === undefined ? (
              <div
                style={
                  tMinus === undefined
                    ? { display: "flex", width: "-webkit-fill-available" }
                    : { display: "flex" }
                }
              >
                <div className="all-delivered">
                  <Typography className="project-card-footer-typography">
                    {"All Delivered"}
                  </Typography>
                </div>
                <CheckCircleIcon className="all-delivered-check" />
              </div>
            ) : (
              <div
                className={
                  tMinus > 5 || tMinus === undefined
                    ? "tMinus-green"
                    : "tMinus-red"
                }
              >
                <Typography className="project-card-footer-typography">
                  {`T- ${tMinus}`}
                </Typography>
              </div>
            )}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </Paper>
  );
};
