import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { checkEmail } from "../../../../../../utils/Validation";
import axios from "axios";
import { useDataContext } from "../../../../../../context/data/DataContext";
import { Autocomplete } from "@material-ui/lab";
import ConfirmationDialogWithInput from "../../../../../Shared/ConfirmationDialogWithInput";
import { useUserContext } from "../../../../../../context/user/UserContext";
import { useAuth } from "../../../../../../context/auth/AuthContext";

const PersonGeneralForm = ({
  person,
  setDriveId,
  setSnackBarProps,
  setShowDocumentViewer,
  handleUpdatePersonGeneralState,
}) => {
  const { user } = useAuth();
  const { industries, ageCategories } = useDataContext();
  const [localPerson, setLocalPerson] = useState(person);
  const [hasChanges, setHasChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showBlacklistConfirmation, setShowBlacklistConfirmation] = useState(false);
  const [blacklistReason, setBlacklistReason] = useState(null);

  useEffect(() => {
    setLocalPerson({ ...localPerson, subIndustriesId: localPerson.personIndustries.map(({ industryId }) => industryId) });
  }, []);

  const handleGeneralInfoChange = (field, value) => {
    if (field === "personIndustries") {
      setLocalPerson({ ...localPerson, [field]: value, subIndustriesId: value.map(({ industryId }) => industryId) });
    } else {
      setLocalPerson({ ...localPerson, [field]: value });
    }
    setHasChanges(true);
  };

  const handleUpdateBlacklist = (isBlacklisted, reason = null) => {
    if (isBlacklisted == 1) {
      setBlacklistReason(reason);
      handleGeneralInfoChange("isBlacklisted", 1);
    } else {
      setBlacklistReason(null);
      handleGeneralInfoChange("isBlacklisted", 0);
    }
    setHasChanges(true);
  };

  const handleSavePersonDetails = () => {
    setIsSaving(true);
    var dto = {...localPerson};
    if(blacklistReason){
      dto.blacklistReason = blacklistReason;
      dto.userEmail = user.email;
    }
    axios
      .put(`people/updatePerson`, dto)
      .then(({ data }) => {
        handleUpdatePersonGeneralState(data);
        setHasChanges(false);
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Great! Person details saved successfully",
        });
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Whops... Failed to save person details",
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  };
  return (
    <div className="person-general-form-root">
      {showBlacklistConfirmation && (
        <ConfirmationDialogWithInput
          open={showBlacklistConfirmation}
          handleClose={() => {
            handleGeneralInfoChange("isBlacklisted", 0);
            setShowBlacklistConfirmation(false);
          }}
          title={"Blacklist person"}
          description={"Please add a reason for the blacklist and click save"}
          submitLabel={"Continue"}
          handleSubmit={(inputValue) => {
            handleUpdateBlacklist(1, inputValue);
            setShowBlacklistConfirmation(false);
          }}
          textFieldLabel={"Note"}
        />
      )}
      <div className="person-general-form-grid-columns-container">
        <TextField
          label="First Name"
          value={localPerson.firstName}
          variant="outlined"
          size="small"
          onChange={(e) => handleGeneralInfoChange("firstName", e.target.value)}
        />
        <TextField
          label="Last Name"
          value={localPerson.lastName}
          variant="outlined"
          size="small"
          onChange={(e) => handleGeneralInfoChange("lastName", e.target.value)}
        />
        {localPerson.hasParent === 1 && (
          <TextField
            label="Last Name"
            value={localPerson.parentName}
            variant="outlined"
            size="small"
            onChange={(e) =>
              handleGeneralInfoChange("parentName", e.target.value)
            }
          />
        )}
        <TextField
          label="Phone"
          value={localPerson.phoneNumber}
          variant="outlined"
          size="small"
          onChange={(e) =>
            handleGeneralInfoChange("phoneNumber", e.target.value)
          }
        />
        <TextField
          label="Email"
          value={localPerson.emailAddress}
          variant="outlined"
          size="small"
          error={checkEmail(localPerson.emailAddress)}
          helperText={
            checkEmail(localPerson.emailAddress)
              ? "Not a valid email address"
              : null
          }
          onChange={(e) =>
            handleGeneralInfoChange("emailAddress", e.target.value)
          }
        />

        <Autocomplete
          options={industries}
          getOptionLabel={(option) => option.name}
          value={
            industries.find((i) => i.id == localPerson.mainIndustryId) ?? ""
          }
          onChange={(e, newValue) => {
            handleGeneralInfoChange("mainIndustryId", newValue?.id || null);
          }}
          size="small"
          renderInput={(params) => (
            <TextField {...params} label="Main Category" variant="outlined" />
          )}
        />
        <Autocomplete
          multiple
          options={industries}
          getOptionLabel={(option) => option.name}
          value={
            localPerson.personIndustries?.map(({ industryId }) =>
              industries.find((i) => i.id === industryId)
            ) ?? ""
          }
          onChange={(e, newValue) => {
            handleGeneralInfoChange(
              "personIndustries",
              newValue.map((option) => ({ industryId: option.id })) || []
            );
          }}
          size="small"
          renderInput={(params) => (
            <TextField {...params} label="Sub Industries" variant="outlined" />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={option.id}
                label={option.name}
                style={{
                  backgroundColor: option.baseColour,
                  color: "black",
                  margin: "-2px 5px",
                  height: 25
                }}
                {...getTagProps({ index })}
              />
            ))
          }
        />
        <Autocomplete
          options={ageCategories}
          getOptionLabel={(option) => option.generation}
          value={ageCategories.find((i) => i.id == localPerson.ageCategoryId)}
          onChange={(e, newValue) => {
            handleGeneralInfoChange("ageCategoryId", newValue?.id || null);
          }}
          size="small"
          renderInput={(params) => (
            <TextField {...params} label="Age Category" variant="outlined" />
          )}
        />
        <FormControl variant="outlined" fullWidth size="small">
          <InputLabel>Meal Preference</InputLabel>
          <Select
            label="Meal Preference"
            value={localPerson.mealPreference}
            onChange={(e) =>
              handleGeneralInfoChange("mealPreference", e.target.value)
            }
            variant="outlined"
          >
            <MenuItem value={"Classic"}>Classic</MenuItem>
            <MenuItem value={"Vegetarian"}>Vegetarian</MenuItem>
            <MenuItem value={"Vegan"}>Vegan</MenuItem>
            <MenuItem value={"Gluten Free"}>Gluten Free</MenuItem>
            <MenuItem value={"Dairy Free"}>Dairy Free</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div>
        <FormControlLabel
          className="default-checkbox"
          control={
            <Checkbox
              checked={localPerson.hasChildren}
              onChange={(e) =>
                handleGeneralInfoChange("hasChildren", e.target.checked ? 1 : 0)
              }
              name="hasChildren"
            />
          }
          label="Has Children"
        />
        <FormControlLabel
          className="default-checkbox"
          control={
            <Checkbox
              checked={localPerson.hasPets}
              onChange={(e) =>
                handleGeneralInfoChange("hasPets", e.target.checked ? 1 : 0)
              }
              name="hasPets"
            />
          }
          label="Has Pets"
        />
        <FormControlLabel
          className="default-checkbox"
          control={
            <Checkbox
              checked={localPerson.isBlacklisted == 1}
              onChange={(e) => {
                if (e.target.checked) {
                  setShowBlacklistConfirmation(true);
                } else {
                  handleUpdateBlacklist(e.target.checked)
                }
                //handleGeneralInfoChange("isBlacklisted", e.target.checked ? 1 : 0)
              }}
              name="isBlacklisted"
            />
          }
          label="Is Blacklisted"
        />
        <FormControlLabel
          className="default-checkbox"
          control={
            <Checkbox
              checked={localPerson.enableSms}
              onChange={(e) =>
                handleGeneralInfoChange("enableSms", e.target.checked ? 1 : 0)
              }
              name="enableSms"
            />
          }
          label="Enable SMS Notifications"
        />
      </div>
      <div style={{ display: "flex", gap: 20, justifyContent: "end" }}>
        {(person.personCategory === "Influencer" || person.personCategory === "Content Creator") &&
          localPerson.masterAgreementFileId && (
            <Button
              className={"default-button"}
              variant="contained"
              size="small"
              onClick={() => {
                setShowDocumentViewer(true);
                setDriveId(localPerson.masterAgreementFileId);
              }}
            >
              Master Influencer Agreement
            </Button>
          )}

        {isSaving ? (
          <CircularProgress size={30} />
        ) : (
          <Button
            className={
              isSaving || !hasChanges
                ? "default-disabled-button"
                : "default-button"
            }
            variant="contained"
            disabled={isSaving || !hasChanges}
            onClick={handleSavePersonDetails}
          >
            Save General Details
          </Button>
        )}
      </div>
    </div>
  );
};

export default PersonGeneralForm;
