import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Axios from "axios";
import { setDialogProps } from "../../../actions/dialog";
import { setSnackBarProps } from "../../../actions/snackbar";
import Snacky from "../../Shared/Snacky";
import "../Admin.css";
import "./MediaSupplier.scss";
import {
    Grid,
    Paper,
    Typography,
} from "@material-ui/core";
import AlertDialog from "../../Shared/Dialog/AlertDialog";
import ProcessingSpinner from "../../Shared/Spinner/ProcessingSpinner";
import SuppliersList from "./SuppliersList";
import SupplierDetails from "./SupplierDetails";
import ConfirmationDialogActions from "../../Shared/ConfirmationDialogActions";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch",
        },
        table: {
            minWidth: 700,
        },
    },
}));

const MediaSupplierTable = (props) => {
    const emptySupplier = {
        supplierName: "",
        xeroName: "",
        xeroGstRateCode: "",
        xeroAccountCode: "",
        xeroBankAccount: "",
        remittanceEmail: "",
        accountsEmail: "",
        paymentMethodType: "NZ_BANK",
        paymentDay: "20",
        address: "",
        marginRebate: 10,
        marginThreshold: 5,
        mediaSupplierFiles: [],
    }

    const [suppliers, setSuppliers] = useState([]);
    const [expenseCodes, setExpenseCodes] = useState([]);
    const [supplier, setSupplier] = useState(emptySupplier);
    const [suppliersLoaded, setSuppliersLoaded] = useState(false);
    const [expenseCodesLoaded, setExpenseCodesLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [openChangesDialog, setOpenChangesDialog] = useState(false);
    const [saving, setSaving] = useState(false);
    const [archiveSupplierWarning, setArchiveSupplierWarning] = useState(false);
    const [snackBarProps, setSnackBarProps] = useState({
        open: false,
        severity: "success",
        text: "",
    });

    const classes = useStyles();

    const handleOpenChangesDialog = () => {
        setOpenChangesDialog(true);
    };
    const handleCloseChanges = () => {
        setHasChanges(false);
        setOpenChangesDialog(false);
    };

    const handleSelectSupplier = (id = null) => {
        if (hasChanges) {
            setOpenChangesDialog(true);
        } else {
            if (id) {
                setSupplier(suppliers.find((s) => s.id == id));
            } else {
                setSupplier(emptySupplier);
            }
        }
    };

    const getMediaSuppliers = () => {
        setLoading(true);
        Axios.get("MediaSuppliers/GetMediaSuppliers")
            .then(res => {
                setSuppliers(res.data);
            })
            .catch(e => {
                setSuppliersLoaded(false);
            }).finally(() => {
                setLoading(false);
            })
    };

    const getExpenseCodes = () => {
        Axios.get("MediaSuppliers/GetCodes")
            .then(res => {
                setExpenseCodes(res.data);
            }).catch(e => console.log(e))
            .then(() => {

            })
    }

    const handleOnChangeSupplier = (e) => {
        setSupplier({ ...supplier, [e.target.name]: e.target.value });
        setHasChanges(true);
    };

    const handleGSTChange = (hasGST) => {
        if (supplier.xeroAccountCode != "") {
            let code = expenseCodes.find(
                (e) => e.accountCode == supplier.xeroAccountCode
            );
            setSupplier({
                ...supplier,
                xeroGstRateCode: code.taxType,
            });
            setSnackBarProps({
                open: true,
                severity: "warning",
                text: `${code.accountName} must ${code.taxType === "NONE" ? "not have GST" : "have GST"}`,
            });
        } else if (hasGST) {
            setSupplier({
                ...supplier,
                xeroGstRateCode: "INPUT_2",
            });
        } else {
            setSupplier({ ...supplier, xeroGstRateCode: "NONE" });
        }
        setHasChanges(true);
    };

    const accountCodeChanged = (expenseCode, hasGst) => {
        if (expenseCode) {
            setSupplier({
                ...supplier,
                xeroGstRateCode: expenseCode.taxType,
                xeroAccountCode: expenseCode.accountCode,
            });
        }
        setHasChanges(true);
    };

    const saveSupplier = () => {
        setSaving(true)
        Axios.post(`MediaSuppliers/SaveSupplier`, supplier)
            .then(res => {
                var payload = res.data;
                setSupplier(payload);
                if (suppliers.find((supplier) => supplier.id === payload.id)) {
                    setSuppliers(suppliers.map((supplier) =>
                        supplier.id === payload.id ? payload : supplier
                    ));
                } else {
                    setSuppliers([...suppliers, payload]);
                }
                setSnackBarProps({
                    open: true,
                    severity: "success",
                    text: "Fantastic! Your changes have been saved.",
                });
                setHasChanges(false);
            }).catch((error) => {
                console.log(error)
                setSnackBarProps({
                    open: true,
                    severity: "warning",
                    text: "Failed to save.",
                });
            }).finally(() => {
                setSaving(false);
            })
    }

    const archiveSuppplier = (supplierId) => {
        Axios.delete(`MediaSuppliers/ArchiveSupplier/${supplierId}`)
            .then(res => {
                var payload = res.data;
                setSuppliers(suppliers.filter((supplier) => supplier.id !== payload.id));
                setSupplier(emptySupplier);
                setSnackBarProps({
                    open: true,
                    severity: "success",
                    text: "Successfully archived supplier.",
                });
            }).catch(e => {
                console.log(e)
                setSnackBarProps({
                    open: true,
                    severity: "warning",
                    text: "Failed to archive.",
                });
            })
    }

    useEffect(() => {
        if (!suppliersLoaded) {
            getMediaSuppliers();
            setSuppliersLoaded(true);
        }
    }, [suppliersLoaded])

    useEffect(() => {
        if (!expenseCodesLoaded) {
            getExpenseCodes();
            setExpenseCodesLoaded(true);
        }
    }, [expenseCodesLoaded])

    return (
        <main className={classes.content}>
            <div className="main-content" />
            <Snacky snackprops={snackBarProps} setSnackBarProps={setSnackBarProps} />
            {archiveSupplierWarning && (
                <ConfirmationDialogActions
                    title={"Close Project"}
                    open={archiveSupplierWarning}
                    contentText={"This action will archive the project in the system."}
                    button1Class={"default-button-grey roboto-regular"}
                    button1Text={"Cancel"}
                    button1Action={(e) => {
                        setArchiveSupplierWarning(false);
                    }}
                    button2Class={"default-button roboto-regular"}
                    button2Text={"Confirm"}
                    button2Action={(e) => {
                        archiveSuppplier(supplier.id);
                        setArchiveSupplierWarning(false);
                    }}
                    handleClose={() => setArchiveSupplierWarning(false)}
                />
            )}
            <AlertDialog
                open={openChangesDialog}
                handleClose={handleOpenChangesDialog}
                title="Would you like to SAVE your changes?"
                contentText="We found changes for this supplier, would you like to save them?"
                buttonText1="Discard"
                buttonText2="Save"
                buttonAction1={handleCloseChanges}
                buttonAction2={saveSupplier}
            />
            {loading ? (
                <ProcessingSpinner duration={60} unitTime={"seconds"} />
            ) : (
                <div style={{ height: "85%" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={3} md={3} lg={3}>
                            <Typography variant="h6">Media Suppliers</Typography>
                            <Paper>
                                <SuppliersList
                                    suppliers={suppliers}
                                    handleSelectSupplier={handleSelectSupplier}
                                    selectedSupplier={supplier}
                                />

                            </Paper>
                        </Grid>
                        <Grid item xs={9} md={9} lg={9}>
                            <SupplierDetails
                                supplier={supplier}
                                handleOnChangeSupplier={handleOnChangeSupplier}
                                handleGSTChange={handleGSTChange}
                                accountCodeChanged={accountCodeChanged}
                                saveChanges={saveSupplier}
                                handleArchiveSupplier={() => setArchiveSupplierWarning(true)}
                                expenseCodes={expenseCodes}
                                setSnackBarProps={setSnackBarProps}
                                saving={saving}
                            />
                        </Grid>
                    </Grid>
                </div>
            )}
        </main >
    )
}

export default MediaSupplierTable;