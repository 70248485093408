import { Button, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "./MediaSupplier.scss";
import MediaSuppliersTable from "./MediaSuppliersTable";
import Snacky from "../../Shared/Snacky";
import { isEmpty } from "../../../utils/GeneralUtils";

const initialSupplier = {
    supplierName: "",
    marginRebate: null,
    xeroGstRateCode: "INPUT2"
}
const MediaSuppliers = () => {
  const [updatedSuppliers, setUpdatedSuppliers] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });

  useEffect(() => {
    getMediaSuppliers();
  }, []);

  const getMediaSuppliers = () => {
    setIsLoading(true);
    axios
      .get("MediaSuppliers/GetMediaSuppliers")
      .then((res) => {
        setSuppliers(res.data);
      })
      .catch((e) => {
        console.log(e);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: `Unable to Pull Media Suppliers`,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangeSupplier = (supplier) => {
    if (supplier.id) {
      const existingSupplierToUpdate = updatedSuppliers.find(
        (s) => s.id === supplier.id
      );
      if (existingSupplierToUpdate) {
        setUpdatedSuppliers(
          updatedSuppliers.map((s) => (s.id === supplier.id ? supplier : s))
        );
      } else {
        setUpdatedSuppliers([...updatedSuppliers, supplier]);
      }
      setSuppliers(suppliers.map((s) => (s.id === supplier.id ? supplier : s)));
    } else {
        setSuppliers(suppliers.map((s) => (s.id ? s : supplier)));
        const existingSupplierToUpdate = updatedSuppliers.find(
            (s) => isEmpty(s.id));
          if (existingSupplierToUpdate) {
            setUpdatedSuppliers(
              updatedSuppliers.map((s) => (isEmpty(s.id) ? supplier : s))
            );
          } else {
            setUpdatedSuppliers([...updatedSuppliers, supplier]);
          }
    }
  };

  const archiveSuppplier = (supplierId) => {
    axios
      .delete(`MediaSuppliers/ArchiveSupplier/${supplierId}`)
      .then((res) => {
        var payload = res.data;
        setSuppliers(
          suppliers.filter((supplier) => supplier.id !== payload.id)
        );
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Successfully archived supplier.",
        });
      })
      .catch((e) => {
        console.log(e);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Failed to archive.",
        });
      });
  };

  const handleSaveSuppliers = () => {
    setIsSaving(true);
    axios
      .post(`MediaSuppliers/SaveSuppliersList/`, updatedSuppliers.filter(s => !isEmpty(s.supplierName)))
      .then(({ data }) => {
        // Create a Set of existing supplier IDs for efficient lookup
        getMediaSuppliers()
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Successfully saved suppliers.",
        });
        setUpdatedSuppliers([]);
      })
      .catch((e) => {
        console.log(e);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Failed to save.",
        });
      })
      .finally(() => setIsSaving(false));
  };

  return (
    <div style={{ padding: 30, display: "grid", backgroundColor: "white" }}>
      <Snacky snackprops={snackBarProps} setSnackBarProps={setSnackBarProps} />

      <Typography variant="h5">Media Suppliers</Typography>
      {isLoading ? (
        <div style={{ display: "grid" }}>
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Skeleton height={20} />
        </div>
      ) : (
        <div className="media-suppliers-root">
          {suppliers.length === 0 ? (
            <div
              className="media-suppliers-paper"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 30,
                padding: 30,
              }}
            >
              <Typography>
                <strong>No Media Supplliers Found</strong>
              </Typography>
            </div>
          ) : (
            <div
              style={{
                display: "grid",
                gap: 5,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                  gap: 10,
                }}
              >
                {!isAddingNew && (
                  <Button
                    onClick={() => {
                        setIsAddingNew(true)
                        console.log([initialSupplier, ...suppliers])
                        setSuppliers([initialSupplier, ...suppliers])
                    }}
                    className={"default-button-grey"}
                  >
                    Add New
                  </Button>
                )}

                {updatedSuppliers.length > 0 && (
                  <Button
                    onClick={handleSaveSuppliers}
                    className={"default-button"}
                  >
                    Save
                  </Button>
                )}
              </div>
              <MediaSuppliersTable
                suppliers={suppliers}
                handleChangeSupplier={handleChangeSupplier}
                archiveSuppplier={archiveSuppplier}
                handleSaveSuppliers={handleSaveSuppliers}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MediaSuppliers;
