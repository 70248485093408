import React, { useState, useEffect } from "react";
import {
  Modal,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Paper,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Switch,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Card,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { Close, Edit, Save } from "@material-ui/icons";
import Axios from "axios";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import { setDialogProps } from "../../../actions/dialog";
import { getExpenseCodes } from "../../../actions/budget";
import InformationDialogy from "../../Shared/InformationDialogy";
import ReimbursementButton from "./ReimbursementButton";
import "./Reimbursment.css";
import Loader from "../../Loader";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  paper: {
    backgroundColor: "#fefefe", //theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),
    height: "auto",
    maxHeight: "80vH",
    width: "1000px",
    overflowY: "auto",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const EditModalReceipt = (props) => {
  const classes = useStyles();
  const [expenseName, setExpenseName] = useState({});
  const [receipt, setReceipt] = useState(null);
  const [supplier, setSupplier] = useState(null);
  const [type, setType] = useState(null);
  const [accountCode, setAccountCode] = useState(null);
  const [amount, setAmount] = useState(null);
  const [bankAccount, setBankAccount] = useState(null);
  const [gst, setGst] = useState(null);
  const [camistryCard, setCamistryCard] = useState(null);
  const [saving, setSaving] = useState(false);
  const [other, setOther] = useState(null);
  const [showSaveWarning, setShowSaveWarning] = useState(false);
  const [showAccountCodeError, setShowAccountCodeError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [imgError, setImgError] = useState(false);

  let { expenseCodes, getExpenseCodes } = props;

  const handleChange = (event, text) => {
    switch (text) {
      case "Supplier":
        setSupplier(event.target.value);
        break;
      case "Type":
        setType(event.target.value);
        break;
      case "AccountCode":
        setAccountCode(event.target.value);
        break;
      case "Amount":
        setAmount(event.target.value);
        break;
      case "BankAccount":
        setBankAccount(event.target.value);
        break;
      case "Other *":
        setOther(event.target.value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getExpenseCodes();
  }, []);

  const handleCheckBoxChange = (event, text) => {
    switch (text) {
      case "Gst (Inc)":
        setGst(event.target.checked ? 1 : 0);
        break;
      case "Camistry Card":
        setCamistryCard(event.target.checked ? 1 : 0);
        break;
      default:
        break;
    }
  };

  const getTextField = (text, value) => (
    <div className="textfield-padding">
      <TextField
        id={text}
        disabled={!props.editMode}
        variant="outlined"
        label={text}
        value={value.toString()}
        onChange={(event) => handleChange(event, text)}
        fullWidth
        size="small"
        error={value === null || value.toString().trim() === ""}
      />
    </div>
  );

  const getDisabledTextField = (text, value) => (
    <div className="textfield-padding">
      <TextField
        id={text}
        disabled
        variant="outlined"
        label={text}
        value={
          text !== "Date" || value === ""
            ? value.toString()
            : format(new Date(value), "dd-MM-yyyy")
        }
        fullWidth
        size="small"
        multiline
      />
    </div>
  );

  const getCheckBox = (text, value) => {
    return (
      <FormControlLabel
        disabled={!props.editMode}
        control={
          <Checkbox
            checked={value === 1}
            onChange={(event) => handleCheckBoxChange(event, text)}
            name={text}
            disabled={!props.editMode}
            color="primary"
          />
        }
        label={text}
      />
    );
  };

  useEffect(() => {
    setReceipt(props.receipt);
    setSupplier(props.receipt.supplier);
    setType(props.receipt.type);
    setAccountCode(props.receipt.accountCode);
    setAmount(props.receipt.amount);
    setBankAccount(props.receipt.bankAccount);
    setGst(props.receipt.gst);
    setCamistryCard(props.receipt.camistryCard);
  }, []);

  const handleExpenseCode = (obj, value) => {
    setExpenseName(value);
    setAccountCode(value.accountCode);
  };

  const getTypeDropdown = () => {
    var otherValue = null;
    if (getValueOrEmptyString(type).includes("Other -") || getValueOrEmptyString(type).includes("Preimbursement -")) {
      otherValue = getValueOrEmptyString(type);
    }
    return (
      <div className="textfield-padding">
        <FormControl fullWidth disabled={!props.editMode}>
          <InputLabel htmlFor="receipt-type-popup">Receipt Type</InputLabel>
          <Select
            value={getValueOrEmptyString(type)}
            onChange={(event) => {
              setType(event.target.value);
              setOther(null);
            }}
            inputProps={{
              name: "Type",
              id: "receipt-type-popup",
            }}
          >
            <MenuItem value={""} disabled>
              Select A Type
            </MenuItem>
            <MenuItem value={"Props"}>Props / Wardrobe</MenuItem>
            <MenuItem value={"Travel"}>Travel</MenuItem>
            <MenuItem value={"Fuel"}>Fuel Camistry Cars</MenuItem>
            <MenuItem value={"Catering"}>On Set Catering</MenuItem>
            <MenuItem value={"Other"}>Other</MenuItem>
            {otherValue != null ? (
              <MenuItem value={otherValue}>{`${otherValue}`}</MenuItem>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </Select>
        </FormControl>
      </div>
    );
  };

  const getExpenseCodeCombo = () => {
    return (
      <Autocomplete
        style={{ marginRight: "5px", marginLeft: "5px" }}
        options={expenseCodes}
        getOptionLabel={(option) => option.accountName}
        disableClearable
        disabled={!props.editMode}
        value={expenseName}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            label="Account Name"
            placeholder="Account Name *"
            fullWidth
            size="small"
            error={accountCode === null}
          />
        )}
        ListboxProps
        onChange={handleExpenseCode}
      />
    );
  };

  const getValueOrEmptyString = (value) => {
    if (value == null) {
      return "";
    } else {
      return value;
    }
  };

  const handleSave = () => {
    if (
      (type === "Other" && other === null) ||
      (type === "Other" && other.toString().trim() === "")
    ) {
      setShowSaveWarning(true);
    } else {
      props.setEditMode(false);
      var newType = null;
      if (type.includes("Other")) {
        if (type.includes("Other -")) {
          newType = type;
        } else {
          newType = `${type} - ${other}`;
        }
      } else {
        newType = type;
      }

      var newReceipt = receipt;
      newReceipt["supplier"] = supplier;
      newReceipt["type"] = newType;
      newReceipt["accountCode"] = accountCode;
      newReceipt["amount"] = amount;
      newReceipt["bankAccount"] = bankAccount;
      newReceipt["gst"] = gst;
      newReceipt["camistryCard"] = camistryCard;
      props.handleEditReceiptModalSave(newReceipt);
      props.setRowUpdating(true);
    }
  };

  useEffect(() => {
    console.log("Receipt");
    console.log(receipt);
  }, [receipt]);

  const imageLoaded = () => {
    setLoading(false);
  };

  const imageError = () => {
    setImgError(true);
  };

  if (receipt === null) {
    return <React.Fragment></React.Fragment>;
  }
  return (
    <div>
      <Modal
        open={props.modalState}
        onClose={() => props.handleCloseModal()}
        className={classes.modal}
        disableBackdropClick
      >
        <div>
          {showSaveWarning && (
            <InformationDialogy
              setDialogProps={setDialogProps}
              buttonProps={{
                show: showSaveWarning,
                titleText: "Woops!",
                contentText: `Please fill out the receipt type`,
                buttonColor: "primary",
                buttonText: "Ok",
              }}
              handleButton={() => setShowSaveWarning(false)}
            />
          )}
          {showAccountCodeError && (
            <InformationDialogy
              setDialogProps={setDialogProps}
              buttonProps={{
                show: showAccountCodeError,
                titleText: "Account Code Error",
                contentText: `Please fill out the Account Code before accepting`,
                buttonColor: "primary",
                buttonText: "Ok",
              }}
              handleButton={() => setShowAccountCodeError(false)}
            />
          )}
          <AppBar position="static" style={{ background: "#217F8B" }}>
            <Toolbar disableGutters variant="dense">
              <Typography
                variant="subtitle1"
                align="center"
                style={{ flexGrow: 1 }}
              >
                {`Receipt Details`}
              </Typography>
              {receipt.billStatus === "Pending" ? (
                props.rowUpdating ? (
                  <CircularProgress />
                ) : props.editMode ? (
                  <IconButton
                    onClick={() => {
                      handleSave();
                    }}
                    color="black"
                    size="small"
                    style={{}}
                  >
                    <Save />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => {
                      props.setEditMode(true);
                    }}
                    color="black"
                    size="small"
                  >
                    <Edit />
                  </IconButton>
                )
              ) : (
                <React.Fragment></React.Fragment>
              )}
              <IconButton
                onClick={() => props.handleCloseModal()}
                color="black"
                size="small"
              >
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div className={classes.paper}>
            <div
              style={{
                display: "flex",
              }}
            >
              <Card
                elevation={9}
                style={{
                  width: "100%",
                  marginRight: 5,
                  display: "flex",
                  maxHeight: "70vH",
                }}
              >
                {receipt.driveId ? (
                  <React.Fragment>
                    <div style={{ display: !loading && "none" }}>
                      <CircularProgress />
                    </div>
                    <div style={{ display: loading && "none" }}>
                      {!imgError && (
                        <img
                          // src={`https://drive.google.com/uc?id=${receipt.driveId}&export=view`}
                          src={`https://drive.google.com/file/d/${receipt.driveId}/preview`}
                          className={"receipt-image"}
                          onLoad={imageLoaded}
                          onError={imageError}
                        />
                      )}
                    </div>
                    {imgError && (
                      <div style={{ width: "100%", height: "100%" }}>
                        <iframe
                          //src={`https://drive.google.com/uc?id=${receipt.driveId}&export=view`}
                          src={`https://drive.google.com/file/d/${receipt.driveId}/preview`}
                          className={"document-image"}
                          width={"100%"}
                          height={"100%"}
                          onLoad={imageLoaded}
                          allow="clipboard-read; clipboard-write"
                        ></iframe>
                      </div>
                    )}
                  </React.Fragment>
                ) : ""}
              </Card>
              <Card elevation={9} style={{ width: "100%", marginLeft: 5 }}>
                <div
                  id="receipt-details-reimburse-button-container"
                  className="textfield-padding"
                  style={props.editMode ? { display: "none" } : {}}
                >
                  <ReimbursementButton
                    receipt={receipt}
                    handleOnClick={props.handleReceiptEdit}
                    accountCode={accountCode}
                    handleAccountCodeError={() => {
                      setShowAccountCodeError(true);
                    }}
                  />
                </div>
                {getDisabledTextField(
                  "Date",
                  getValueOrEmptyString(receipt.date)
                )}
                {getDisabledTextField(
                  "Project",
                  getValueOrEmptyString(receipt.projectName)
                )}
                {receipt.organisationId !== 5 && getDisabledTextField(
                  "Shoot",
                  getValueOrEmptyString(receipt.scheduleName)
                )}
                {receipt.organisationId === 5 && getDisabledTextField(
                  "Description",
                  getValueOrEmptyString(receipt.description)
                )}
                {getDisabledTextField(
                  "Name",
                  getValueOrEmptyString(receipt.name)
                )}
                {receipt.organisationId !== 5 && getTextField("Supplier", getValueOrEmptyString(supplier))}
                {receipt.organisationId !== 5 && getTypeDropdown()}
                {type === "Other" &&
                  getTextField("Other *", getValueOrEmptyString(other))}
                {type.startsWith("Other") && getExpenseCodeCombo()}
                {getDisabledTextField(
                  "AccountCode",
                  getValueOrEmptyString(accountCode)
                )}
                {getTextField("Amount", getValueOrEmptyString(amount))}
                {getTextField(
                  "BankAccount",
                  getValueOrEmptyString(bankAccount)
                )}
                <div
                  className="textfield-padding"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {getCheckBox("Gst (Inc)", gst)}
                  {receipt.organisationId !== 5 && getCheckBox("Camistry Card", camistryCard)}
                </div>
                {receipt.organisationId !== 5 && getDisabledTextField(
                  "Bill Link",
                  getValueOrEmptyString(receipt.billLink)
                )}
              </Card>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  dialog: state.dialog,
  expenseCodes: state.budget.expenseCodes,
});
export default connect(mapStateToProps, {
  setDialogProps,
  getExpenseCodes,
})(EditModalReceipt);
