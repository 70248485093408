import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";

export const DeleteItemDialog = (props) => {
  var location = useLocation();
  let {
    openDeleteItemDialog,
    setOpenDeleteItemDialog,
    deleteScheduleItem,
    scheduleItem,
    setActiveScheduleItem,
    setSnackBarProps,
    table,
  } = props;
  return (
    <Dialog
      open={openDeleteItemDialog}
      onClose={() => setOpenDeleteItemDialog(false)}
    >
      <DialogTitle id="delete-scheduleItem-dialog-title">
        {"Are you sure you want to delete this item?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-scheduleItem-dialog-description">
          Deleting this will permanently remove it from database
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDeleteItemDialog(false)} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            deleteScheduleItem(
              scheduleItem,
              table,
              location.pathname.split("/")[3]
            );
            setSnackBarProps("success", "Item deleted", true);
            setActiveScheduleItem();
            setOpenDeleteItemDialog(false);
          }}
          color="secondary"
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const InvoicesDialog = (props) => {
  let { showInvoicesModal, setShowInvoicesModal } = props;

  return (
    <Dialog
      open={showInvoicesModal}
      onClose={() => setShowInvoicesModal(false)}
    >
      <DialogTitle style={{ textAlign: "center" }} id="switch-dialog-title">
        Sorry, can't delete this item
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: "center" }}>
          It has already been invoiced
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setShowInvoicesModal(false);
          }}
          color="primary"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const CancelContractDialog = (props) => {
  let { showContractsModal, setShowContractsModal } = props;
  return (
    <Dialog
      open={showContractsModal}
      onClose={() => setShowContractsModal(false)}
    >
      <DialogTitle style={{ textAlign: "center" }} id="switch-dialog-title">
        Sorry, can't delete this item
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: "center" }}>
          Please cancel the contract first before attempting it again
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setShowContractsModal(false);
          }}
          color="primary"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
