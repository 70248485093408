import moment from "moment";
import dayjs from "dayjs";

export const addressErrorText = "Could not find address";

export function contains(array, text) {
  for (var i = 0; i < array.length; i++) {
    if (array[i] === text) return true;
  }
  return false;
}

export function unique(array) {
  var arr = [];
  for (var i = 0; i < array.length; i++) {
    if (!contains(arr, array[i])) {
      arr.push(array[i]);
    }
  }
  return arr;
}

export function numberWithThousandsSeperator(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getValueOrEmptyString = (value) => {
  if (value === null || value === undefined) {
    return "";
  } else {
    return value;
  }
};

export const isObjectEmpty = (obj) => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({});
};

export const getFirstStartTime = (currentSchedule) => {
  var startTime = null;
  if (!isObjectEmpty(currentSchedule)) {
    var scheduleLocations = currentSchedule.scheduleLocations;

    for (var i = 0; i < scheduleLocations.length; i++) {
      var newDate = new Date(scheduleLocations[i].startTime);
      if (startTime === null || newDate < new Date(startTime)) {
        startTime = newDate;
      }
    }
  }
  return startTime;
};

export const getLastEndTime = (currentSchedule) => {
  var endTime = null;
  if (!isObjectEmpty(currentSchedule)) {
    var scheduleLocations = currentSchedule.scheduleLocations;

    for (var i = 0; i < scheduleLocations.length; i++) {
      var newDate = new Date(scheduleLocations[i].endTime);
      if (endTime === null || newDate > new Date(endTime)) {
        endTime = newDate;
      }
    }
  }
  return endTime;
};

export const getLocationAddress = (currentSchedule, item) => {
  var address = addressErrorText;
  if (!isObjectEmpty(currentSchedule)) {
    var scheduleLocations = currentSchedule.scheduleLocations;
    for (var i = 0; i < scheduleLocations.length; i++) {
      var scheduleLocationStartTime = new Date(scheduleLocations[i].startTime);
      var scheduleLocationEndTime = new Date(scheduleLocations[i].endTime);
      var itemStartTime = new Date(item.startTime);

      if (
        itemStartTime >= scheduleLocationStartTime &&
        itemStartTime <= scheduleLocationEndTime
      ) {
        address = scheduleLocations[i].locationFkNavigation.address;
        break;
      }
    }
  }
  return address.split(",")[0];
};

export const sortArrayByStartTime = (array) => {
  array.sort(function (a, b) {
    return new Date(b.startTime) - new Date(a.startTime);
  });

  return array;
};

export const checkIfScheduleItemsValid = (currentSchedule) => {
  console.log("checkIfScheduleItemsValid");
  if (
    !isObjectEmpty(currentSchedule) &&
    getValueOrEmptyString(currentSchedule.scheduleItem) !== ""
  ) {
    var scheduleItems = currentSchedule.scheduleItem;

    for (var i = 0; i < scheduleItems.length; i++) {
      var address = getLocationAddress(currentSchedule, scheduleItems[i]);
      if (address === addressErrorText) {
        return false;
      }
    }
  }
  return true;
};

export const checkIfLocationTimeValid = (newStartTime, st) => {//currentSchedule
  console.log("checkLocationTime");
  // if (!isObjectEmpty(currentSchedule) && getValueOrEmptyString(currentSchedule.scheduleItem) !== "") {
  //   var scheduleItems = currentSchedule.scheduleItem;

  //   for(var i = 0; i < scheduleItems.length; i++) {
  //     if(new Date(scheduleItems[i].startTime).getTime() < newStartTime.getTime()) {
  //       return false;
  //     }
  //   }
  // }
  // return true;
  if (new Date(st).getTime() < newStartTime.getTime()) {
    return false;
  }
  return true
};

export const getMonthsArray = (numMonths) => {
  const monthsArray = [];

  for (let i = 1; i <= numMonths; i++) {
    const monthObject = { id: i, value: `${i} Month${i > 1 ? 's' : ''}` };
    monthsArray.push(monthObject);
  }

  return monthsArray;
}
export const getDeliveryTypeArray = () => {
  return ["Paid", "Organic", "Reshare"];
}
export const calculateDateDifference = (startDate, endDate) => {
  // Check if startDate and endDate are valid date strings
  if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
    return 0;
  }

  // Calculate the difference in days
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);
  const differenceInDays = endMoment.diff(startMoment, 'days');

  return differenceInDays;
}

export const get12MonthsAheadAnd1MonthBackArr = () => {
  var now = dayjs();
  var firstDayOfMonth = dayjs(`${now.format("YYYY")}-${now.format("MM")}-01`)
  var lastDay = firstDayOfMonth.add(-1, 'day');
  var startDate = dayjs(`${lastDay.format("YYYY")}-${lastDay.format("MM")}-${lastDay.daysInMonth()}`)
  var dateArr = [];
  for (var i = 0; i < 13; i++) {
    if (i !== 0) {
      var tempDate = dayjs(`${startDate.format("YYYY")}-${startDate.format("MM")}-01`);
      tempDate = tempDate.add(1, 'month');
      lastDay = tempDate.daysInMonth();
      startDate = dayjs(`${tempDate.format("YYYY")}-${tempDate.format("MM")}-${lastDay}`)
    }
    dateArr.push({
      display: `${startDate.format("MMMM")} ${startDate.format("YYYY")}`,
      value: startDate.format("YYYY-MM-DD")
    })
  }

  return dateArr;
}

export const isInternationalClient = ({currency = "NZD"}) => {
  return currency !== "NZD";
}