import React, { useState } from "react";
import "./projectCampaignProgress.scss";
import { Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import ProjectCampaignStepper from "./ProjectCampaignStepper";
import ConfirmationDialogActions from "../../../../../Shared/ConfirmationDialogActions";
import ContentApprovedDialog from "../ContentApproved/ContentApprovedDialog";

const ProjectCampaignProgress = ({
  scheduleItems,
  handleManualClientApproval,
  handleUpdateScheduleItemContractStatus,
  handleManualContentReview,
  handleManualContentApproval,
}) => {
  const [selectedScheduleItem, setSelectedScheduleItem] = useState(null);
  const [openClientApprovalConfirmation, setOpenClientApprovalConfirmation] =
    useState(false);
  const [
    openContentApprovedOnConfirmation,
    setOpenContentApprovedOnConfirmation,
  ] = useState(false);
  const [
    openContentReviewedOnConfirmation,
    setOpenContentReviewedOnConfirmation,
  ] = useState(false);
  const handleClickClientApprovalStep = (scheduleItem) => {
    setSelectedScheduleItem(scheduleItem);
    setOpenClientApprovalConfirmation(true);
    setSelectedScheduleItem(scheduleItem);
  };

  const handleClickReviewedOnStep = (scheduleItem) => {
    setSelectedScheduleItem(scheduleItem);
    setOpenContentReviewedOnConfirmation(true);
    setSelectedScheduleItem(scheduleItem);
  };

  const handleClickContentApprovedOnStep = (scheduleItem) => {
    setSelectedScheduleItem(scheduleItem);
    setOpenContentApprovedOnConfirmation(true);
    setSelectedScheduleItem(scheduleItem);
  };

  const handleConfirmClientApproval = async () => {
    await handleManualClientApproval(selectedScheduleItem);
    handleCloseConfirmationModal();
  };

  const handleConfirmCompleteReview = async (scheduleItemId) => {
    await handleManualContentReview(selectedScheduleItem);
    handleCloseConfirmationModal();
  };

  const handleConfirmContentApproval = async (scheduleItemId, status) => {
    await handleManualContentApproval(selectedScheduleItem, status);
    handleCloseConfirmationModal();
  };
  const handleCloseConfirmationModal = () => {
    setSelectedScheduleItem(null);
    setOpenClientApprovalConfirmation(false);
    setOpenContentReviewedOnConfirmation(false);
    setOpenContentApprovedOnConfirmation(false);
  };

  return (
    <>
      {openClientApprovalConfirmation && (
        <ConfirmationDialogActions
          title={"Are you sure?"}
          open={openClientApprovalConfirmation}
          contentText={"This action will approve the influencer"}
          button1Action={handleCloseConfirmationModal}
          button1Text={"Cancel"}
          button2Text={"Submit"}
          button2Action={handleConfirmClientApproval}
        />
      )}
      {openContentReviewedOnConfirmation && (
        <ConfirmationDialogActions
          title={"Are you sure?"}
          open={openContentReviewedOnConfirmation}
          contentText={"This action will complete content review"}
          button1Action={handleCloseConfirmationModal}
          button1Text={"Cancel"}
          button2Text={"Confirm"}
          button2Action={handleConfirmCompleteReview}
        />
      )}
      {openContentApprovedOnConfirmation && (
        <ContentApprovedDialog
          open={openContentApprovedOnConfirmation}
          scheduleItemId={selectedScheduleItem?.id}
          handleClose={handleCloseConfirmationModal}
          handleUpdateCallback={handleConfirmContentApproval}
        />
      )}
      <div className="project-campaign-progress-root">
        {scheduleItems?.length > 0 &&
          scheduleItems.map((si, index) => (
            <div
              key={index}
              className="project-campaign-progress-scheduleitem-container"
            >
              <ProjectCampaignStepper
                scheduleItem={si}
                handleClickClientApprovalStep={handleClickClientApprovalStep}
                handleUpdateScheduleItemContractStatus={
                  handleUpdateScheduleItemContractStatus
                }
                handleClickContentApprovedOnStep={
                  handleClickContentApprovedOnStep
                }
                handleClickReviewedOnStep={handleClickReviewedOnStep}
              />
            </div>
          ))}
      </div>
    </>
  );
};

export default ProjectCampaignProgress;
