import React, { useEffect, useState } from "react";
import {
  TextField,
} from "@material-ui/core";
import "./multiCurrencyInputField.scss";
import CurrencyDropdownMenu from "./CurrencyDropdownMenu";
import CurrencyExchangeDialog from "./CurrencyExchangeDialog";
import _ from "lodash";

const MultiCurrencyInputField = ({
  totalAmount,
  nzdTotalAmount,
  handleChange,
  currency = "NZD",
  size = "medium",
  placeholder = "",
  variant = "outlined",
  label = "Amount",
  isReadOnly = false,
  lockDropdown = false,
  partOfCurrencyExchangeForm = true,
  baseCurrency = "NZD"
}) => {
  const [openCurrencyExchangeDialog, setOpenCurrencyExchangeDialog] =
    useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(currency);
  const [tempTotal, setTempTotal] = useState(totalAmount);

  useEffect(() => {
    setSelectedCurrency(currency);
  }, [currency]);

  const handleChangeCurrencyDropdown = (_selectedCurrency) => {
    setSelectedCurrency(_selectedCurrency.value);
    if (_selectedCurrency.value !== baseCurrency) {
      handleChangeOpenExchangeDialog();
    } else {
      handleChange(_selectedCurrency.value, totalAmount, totalAmount);
    }
  };

  const handleChangeCloseExchangeDialog = () => {
    setSelectedCurrency(currency);
    setOpenCurrencyExchangeDialog(false);
  };

  const handleChangeOpenExchangeDialog = () => {
    setOpenCurrencyExchangeDialog(true);
  };

  const handleOnFocus = () => {
    if (selectedCurrency !== baseCurrency) {
      handleChangeOpenExchangeDialog();
    }
  };

  const handleChangeForeignCurrency = (
    _selectedCurrency,
    total,
    totalInNzd
  ) => {
    handleChange(_selectedCurrency, total, totalInNzd);
    setOpenCurrencyExchangeDialog(false);
  };

  useEffect(() => {
    if (!partOfCurrencyExchangeForm) {
      setTempTotal(totalAmount);
    }
  }, [totalAmount]);

  return (
    <div className="multi-currency-input-root-container">
      {openCurrencyExchangeDialog && selectedCurrency !== baseCurrency && (
        <CurrencyExchangeDialog
          open={openCurrencyExchangeDialog}
          handleSubmit={handleChangeForeignCurrency}
          handleClose={handleChangeCloseExchangeDialog}
          currency={selectedCurrency}
          baseCurrency={baseCurrency}
          foreignAmount={totalAmount}
          nzdAmount={nzdTotalAmount}
          lockDropdown={lockDropdown}
        />
      )}
      <div className="multi-currency-input-field-container">
        <CurrencyDropdownMenu
          size={size}
          originalCurrency={selectedCurrency}
          handleChangeCurrency={handleChangeCurrencyDropdown}
          isReadOnly={isReadOnly || lockDropdown}
        />
        <TextField
          fullWidth
          value={partOfCurrencyExchangeForm ? totalAmount || 0 : tempTotal || 0}
          variant={variant}
          size={size}
          label={label}
          placeholder={placeholder}
          onChange={(e) => {
            if (partOfCurrencyExchangeForm) {
              if (currency?.toUpperCase() === selectedCurrency) {
                handleChange(
                  selectedCurrency,
                  Number(e.target.value),
                  Number(e.target.value)
                );
              } else {
                handleOnFocus();
              }
            } else {
              const inputValue = e.target.value;
              const isValidInput = /^(?:\d+(\.\d*)?|\.\d*)?$/.test(inputValue);

              if (isValidInput) {
                if (currency?.toUpperCase() === selectedCurrency) {
                  const inputWithNoLeadingZeros = inputValue.replace(/^0+/, "");
                  setTempTotal(inputWithNoLeadingZeros);
                  handleChange(selectedCurrency, inputValue);
                } else {
                  handleOnFocus();
                }
              }
            }
          }}
          disabled={isReadOnly}
          onFocus={handleOnFocus}
        />
      </div>
    </div>
  );
};

export default MultiCurrencyInputField;
