import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Define custom styles using makeStyles from Material-UI
const useStyles = makeStyles(() => ({
  root: () => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
  }),
  colorCircle: ({ size, selected }) => ({
    width: size,
    height: size,
    borderRadius: "50%",
    display: "inline-block",
    cursor: "pointer",
    boxShadow: selected ? "0 0 5px 2px rgba(0,0,0,0.5)" : "none",
    border: selected ? "2px solid #000" : "2px solid transparent",
    transition: "border 0.2s, box-shadow 0.2s",
  }),
  colorCircle: ({ size }) => ({
    width: size,
    height: size,
    borderRadius: "50%",
    display: "inline-block",
    cursor: "pointer",
  }),
}));
const CircleColourPicker = ({
  colors,
  selectedColor,
  onChange,
  circleSize = 28,
}) => {
  const classes = useStyles({ size: circleSize });
  return (
    <div className={classes.root}>
      {colors.map((color, index) => {
        const isSelected = color?.toUpperCase() === selectedColor?.toUpperCase();
        const circleClasses = `${classes.colorCircle}`;

        return (
          <span
            key={index}
            style={isSelected ? { backgroundColor: color, border: `3px solid ${color}` } : {border: `3px solid ${color}`}}
            className={circleClasses}
            onClick={() => onChange(color)}
          />
        );
      })}
    </div>
  );
};

export default CircleColourPicker;
