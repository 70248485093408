export const blankOutputObject = (project, newRowId) => {
  return {
    client: project.customerName,
    creativeName: "",
    duration: null,
    platform: "",
    notes: "",
    publisher: "",
    startDate: new Date(),
    subtitles: 0,
    creativeCode: null,
    geolocation: null,
    creativeLink: "",
    targetAudience: "",
    fromMediaCore: 0,
    projectsFk: project.id,
    status: 0,
    keyNumberFkNavigation: null
  }
};

/*
  return {
    TableID: "",
    AccountsEmail: project.accountsContactEmail,
    AccountsName: project.accountsContactName,
    Budget: "",
    ["CAB Approval Status"]: "",
    ["Campaign Name"]: "",
    CheckedAndUploadedColumnID: null,
    CheckedByColumnID: "",
    ClientEmail: project.userEmail,
    CreativeCode: null,
    CreativeDeadline: null, //new Date(),
    CreativeName: "",
    ["creativeLink"]: "",
    CreativeLinkColumnID: "",
    ["Customer Name"]: project.customerName,
    Duration: null,
    EndDate: null, //new Date(),
    Geolocations: "",
    ["Invoice Date"]: "",
    InvoiceDate: "",
    InvoiceLinkColumnID: "",
    InvoicePO: "",
    ["Key Number"]: "",
    //KeyNumber: "",
    ["notes"]: "",
    ["Output Code"]: "",
    Platform: "",
    Publisher: "",
    RowID: newRowId,
    RowNumber: null,
    SendToCabColumnID: "",
    SentToAdstreamColumnID: "",
    SheetID: null,
    StartDate: new Date(),
    Subtitles: false,
    ["Target Audience"]: "",
  };
*/

export const backTransformRowObject = (rowObj, project) => {
  return {
    TableID: rowObj.tableID,
    AccountsEmail: project.accountsContactEmail,
    AccountsName: project.accountsContactName,
    Budget: rowObj.budget,
    ["CAB Approval Status"]: rowObj.cabApprovalStatus,
    ["Campaign Name"]: rowObj.campaignName,
    CheckedAndUploadedColumnID: rowObj.checkedAndUploadedColumnID,
    CheckedByColumnID: rowObj.checkedByColumnID,
    ClientEmail: project.userEmail,
    CreativeCode: rowObj.creativeCode,
    CreativeDeadline: rowObj.creativeDeadline, //new Date(),
    CreativeName: rowObj.creativeName,
    ["creativeLink"]: rowObj.creativeLink,
    CreativeLinkColumnID: rowObj.creativeLinkColumnID,
    ["Customer Name"]: project.customerName,
    Duration: rowObj.duration,
    EndDate: rowObj.endDate, //new Date(),
    Geolocations: rowObj.geolocations,
    ["Invoice Date"]: rowObj.invoiceDate,
    InvoiceDate: rowObj.invoiceDate,
    InvoiceLinkColumnID: rowObj.invoiceLinkColumnID,
    InvoicePO: rowObj.invoicePO,
    ["Key Number"]: rowObj.keyNumber,
    //KeyNumber: "",
    ["notes"]: rowObj.notes,
    ["Output Code"]: rowObj.outputCode,
    Platform: rowObj.platform,
    Publisher: rowObj.publisher,
    RowID: rowObj.rowId,
    RowNumber: rowObj.rowNumber,
    SendToCabColumnID: rowObj.sendToCabColumnID,
    SentToAdstreamColumnID: rowObj.sentToAdstreamColumnID,
    SheetID: rowObj.sheetID,
    StartDate: rowObj.startDate,
    Subtitles: rowObj.subtitles,
    ["Target Audience"]: rowObj.targetAudience,
  };
};

export const forwardTransform = (rowObj, project) => {
  return {
    tableID: rowObj.TableID,
    accountsContactEmail: rowObj.AccountsEmail,
    accountsContactName: rowObj.AccountsName,
    budget: rowObj.Budget,
    cabApprovalStatus: rowObj["CAB Approval Status"],
    campaignName: rowObj["Campaign Name"],
    checkedAndUploadedColumnID: rowObj.CheckedAndUploadedColumnID,
    checkedByColumnID: rowObj.CheckedByColumnID,
    userEmail: project.ClientEmail,
    creativeCode: rowObj.CreativeCode,
    creativeDeadline: rowObj.CreativeDeadline, //new Date(),
    creativeName: rowObj.CreativeName,
    creativeLink: rowObj["creativeLink"],
    creativeLinkColumnID: rowObj.CreativeLinkColumnID,
    customerName: project.customerName,
    duration: rowObj.Duration,
    endDate: rowObj.EndDate, //new Date(),
    geolocations: rowObj.Geolocations,
    invoiceDate: rowObj["Invoice Date"],
    invoiceLinkColumnID: rowObj.InvoiceLinkColumnID,
    invoicePO: rowObj.InvoicePO,
    keyNumber: rowObj["Key Number"],
    //KeyNumber: "",
    notes: rowObj["notes"],
    outputCode: rowObj["Output Code"],
    platform: rowObj.Platform,
    publisher: rowObj.Publisher,
    rowId: rowObj.RowID,
    rowNumber: rowObj.RowNumber,
    sendToCabColumnID: rowObj.SendToCabColumnID,
    sentToAdstreamColumnID: rowObj.SentToAdstreamColumnID,
    sheetID: rowObj.SheetID,
    startDate: rowObj.StartDate,
    subtitles: rowObj.Subtitles,
    targetAudience: rowObj["Target Audience"],
  };
};
