import {
  AppBar,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import Spinner from "../Spinner/Spinner";
import ProcessingSpinner from "../Spinner/ProcessingSpinner";

const GeneralDocumentViewer = (props) => {
  const [loading, setLoading] = useState(true);
  const [docType, setDocType] = useState(props.docType);

  const imageLoaded = () => {
    setLoading(false);
  };

  const getFileUrl = () => {
    if (props.driveId) {
      return `https://drive.google.com/uc?id=${props.driveId}&export=view`;
    } else if (props.urlFile) {
      return props.urlFile;
    }
  };
  return (
    <Modal
      open={props.modalState}
      onClose={() => props.handleModal()}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <div>
        <AppBar position="static" style={{ background: props.headerColor }}>
          <Toolbar variant="dense" style={{ paddingRight: 0 }}>
            <Grid container>
              <Grid item xs={3} md={3} lg={3}></Grid>
              <Grid item xs={6} md={6} lg={6}>
                <Typography
                  variant="h3"
                  align="center"
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    margin: "0px 0px 5px 0px",
                  }}
                >
                  {props.headerTitle}
                </Typography>
              </Grid>
              <Grid item xs={3} md={3} lg={3}>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <IconButton
                    variant="contained"
                    onClick={() => {
                      window.open(getFileUrl());
                    }}
                    style={{ color: "white" }}
                  >
                    <OpenInBrowserIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => props.handleModal()}
                    color="inherit"
                    style={{ color: "white" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </span>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <div
          style={{
            backgroundColor: "#000",
            padding: 2,
            height: "80vH",
            maxHeight: "80vH",
            width: "1000px",
            overflowY: "auto",
          }}
        >
          {/* <div style={{ display: !loading && "none" }}>
            <h1
              className="loading-dots"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </h1>
          </div> */}
          {docType === "Image" && (
            <Card
              elevation={9}
              style={{
                display: loading && "none",
                width: "100%",
                display: "flex",
                maxHeight: "80vH",
              }}
            >
              <div>
                <img
                  alt=""
                  src={getFileUrl()}
                  className={"document-image"}
                  onLoad={imageLoaded}
                  onError={() => setDocType("PDF")}
                />
              </div>
            </Card>
          )}
          {loading &&
            <ProcessingSpinner
              header="Fetching PDF"
              body=""
            />
          }
          {docType === "PDF" && (
            <div
              style={{
                display: loading && "none",
                width: "100%",
                height: "99%",
              }}
            >
              <iframe
                title={"pdf-document"}
                src={getFileUrl()}
                className={"document-image"}
                width={"100%"}
                height={"100%"}
                onLoad={imageLoaded}
                style={{ border: "1px solid #000" }}
                allow="clipboard-read; clipboard-write"
              ></iframe>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default GeneralDocumentViewer;
