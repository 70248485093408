import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import axios from "axios";
import { debounce, isEqual } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { getCurrencyFlag } from "../../../utils/GeneralUtils";

const PersonAutocomplete = ({
  size = "small",
  variant = "outlined",
  label = "Person",
  categories = [],
  person,
  handleSelectPerson,
  companyId = null,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const lastQueryParamsRef = useRef({
    query: "",
    categories: [],
    companyId: null,
  });

  useEffect(() => {
    if (person?.id && person?.firstName && person?.lastName) {
      const existingPerson = {
        firstName: person?.firstName,
        lastName: person?.lastName,
        id: person?.id,
      };
      setOptions((prevOptions) => [...prevOptions, existingPerson]);
    }
  }, [person]);

  const fetchOptions = async (query, categories, companyId) => {
    try {
      setLoading(true);
      const response = await axios.post(`people/searchPersons`, categories, {
        params: { keyword: query, companyId },
      });
      setOptions(response.data || []);
    } catch (error) {
      console.error("Error fetching person:", error);
      setOptions([]);
    } finally {
      setLoading(false);
    }
  };

  // Debounced version of fetchOptions to avoid multiple API calls
  const debouncedFetchOptions = useMemo(
    () =>
      debounce((query) => {
        fetchOptions(query, categories, companyId);
      }, 300),
    [categories, companyId]
  );

  useEffect(() => {
    const trimmedQuery = inputValue.trim();

    // Always trigger fetchOptions when:
    // - "Staff" is in categories
    // - "Client" is in categories AND companyId is not null
    const shouldFetch =
      categories.includes("Staff") ||
      (categories.includes("Client") && companyId);

    // Parameters to check against last query
    const currentQueryParams = {
      query: trimmedQuery,
      categories,
      companyId,
    };

    if (
      (shouldFetch || trimmedQuery) &&
      !isEqual(currentQueryParams, lastQueryParamsRef.current)
    ) {
      // Update the last query parameters reference
      lastQueryParamsRef.current = currentQueryParams;
      debouncedFetchOptions(trimmedQuery);
    } else if (!trimmedQuery && !shouldFetch) {
      // Clear options if no need to fetch
      setOptions([]);
    }
  }, [inputValue, categories, debouncedFetchOptions, companyId]);

  return (
    <Autocomplete
      freeSolo
      value={person || null}
      options={options}
      getOptionLabel={(option) =>
        option?.firstName +
        " " +
        option?.lastName +
        " " +
        getCurrencyFlag(option.currency, true)
      }
      loading={loading}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, newValue) => {
        handleSelectPerson(newValue || null);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size={size}
          variant={variant}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default PersonAutocomplete;
