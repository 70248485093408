import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from "@material-ui/core";
import React from "react";
import ProcessingSpinner from "./ProcessingSpinner";

const ProcessingSpinnerDialog = (props) => {
    return (
        <Dialog
            open={props.loading}
            aria-labelledby="processing-spinner-dialog-title"
            aria-describedby="processing-spinner-dialog-description"
        >
            <DialogTitle id="processing-spinner-dialog-title" style={{textAlign: "center"}}>
                <Typography variant="h5">{"Processing your request. Hang in there!"}</Typography>
            </DialogTitle>
            <DialogContent>
                <ProcessingSpinner duration={60} unitTime="seconds" />
            </DialogContent>
        </Dialog>
    );
};

export default ProcessingSpinnerDialog;