import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";
import "./Admin.css";
import {
    TextField,
    Button,
    Typography,
    Grid,
    Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Loader from "../Loader";
import { PermDeviceInformationOutlined } from "@material-ui/icons";
import parse from "autosuggest-highlight/parse";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch",
        },
    },
}));

const MediaPricing = (props) => {
    const classes = useStyles();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [id, setId] = useState(null);
    const [cabPrice, setCabPrice] = useState(null);
    const [adstreamPrice, setAdstreamPrice] = useState(null);
    const [successCreate, setSuccessCreate] = useState(false);
    const [failedCreate, setFailedCreate] = useState(false);

    const closeSuccessCreateSnackbar = () => {
        setSuccessCreate(false);
    };

    const closeFailedCreateSnackbar = () => {
        setFailedCreate(false);
    };

    const snackbarSuccess = (
        <Snackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            open={successCreate}
            autoHideDuration={2000}
            onClose={closeSuccessCreateSnackbar}
        >
            <MuiAlert severity="success">Successfully Saved Changes!</MuiAlert>
        </Snackbar>
    );

    const snackbarFailed = (
        <Snackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            open={failedCreate}
            autoHideDuration={2000}
            onClose={closeFailedCreateSnackbar}
        >
            <MuiAlert severity="error">Failed to Save Changes!</MuiAlert>
        </Snackbar>
    );

    const handleChange = (event, text) => {
        var newValue = event.target.value;
        switch (text) {
            case "CAB":
                setCabPrice(newValue);
                break;
            case "Adstream":
                setAdstreamPrice(newValue);
                break;
            default:
                break;
        }
    };

    const getField = (text, value, type) => (
        <div className="textfield-padding">
            <TextField
                id={text}
                label="Outlined"
                variant="outlined"
                label={text}
                value={value.toString()}
                onChange={(event) => handleChange(event, text)}
                fullWidth
                type={type}
            />
        </div>
    );

    const saveChanges = () => {
        Axios.put(`MediaPriceSettings/PutMediaPriceSettings/${id}`, {
            id: id,
            cab:  parseFloat(cabPrice),
            adstream: parseFloat(adstreamPrice)
        })
        .then(res => {
            setSuccessCreate(true)
        })
        .catch(err => {
            setFailedCreate(true)
        })
    }

    useEffect(() => {
        Axios.get("MediaPriceSettings/GetMediaPriceSettings")
        .then(res => {
            setId(res.data.id)
            setCabPrice(res.data.cab)
            setAdstreamPrice(res.data.adstream)
            setDataLoaded(true);
        })
        .catch(err => {
            console.log(err)
        })
    }, [])

    return (
        <main className={classes.content}>
            <div className="main-content" />
            <Typography variant="h4">Media Pricing</Typography>
            {
                dataLoaded && (
                    <Grid container className="table-grid">
                        <Grid item xs={12} md={4} lg={3}>
                            {getField("CAB", cabPrice, "number")}
                            {getField("Adstream", adstreamPrice, "number")}
                            <div className="button-div">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={saveChanges}
                                >
                                    Save
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                )
            }
            {snackbarSuccess}
            {snackbarFailed}
        </main>
    )
}

export default MediaPricing;