import Axios from "axios";

export const setRedirectTo = (redirectTo) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_REDIRECT_TO",
      payload: {
        redirectTo,
      },
    });
  } catch (err) {
    dispatch({
      type: "SET_REDIRECT_TO_ERROR",
      payload: err,
    });
  }
};

export const getProjectNames = () => async (dispatch) => {
  try {
    const res = await Axios.get("Projects/ReturnListOfActiveProjects");
    dispatch({
      type: "GET_PROJECT_NAMES",
      payload: {
        projectNames: res.data,
      },
    });
  } catch (err) {
    dispatch({
      type: "GET_PROJECT_NAMES_ERROR",
      payload: err,
    });
  }
};

export const setProjectFilter = (projectFilter) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_PROJECT_FILTER",
      payload: {
        projectFilter,
      },
    });
  } catch (err) {
    dispatch({
      type: "SET_PROJECT_FILTER_ERROR",
      payload: err,
    });
  }
};
