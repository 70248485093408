import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Tooltip,
    Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./customAccordion.scss";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const CustomAccordionControlled = ({
    children,
    title,
    alertMessage,
    disabled = false,
    defaultExpanded = true
}) => {
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);

    const handleChange = (event, expanded) => {
        setIsExpanded(expanded);
    };

    return (
        <Accordion
            className="custom-accordion-root"
            disabled={disabled}
            defaultExpanded={defaultExpanded}
            onChange={handleChange}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div
                    className={`custom-accordion-title-container ${alertMessage ? "custom-accordion-title-alert" : ""}`}
                >
                    <Typography variant="h6">{title}</Typography>
                    {alertMessage && (
                        <Tooltip title={alertMessage}>
                            <ErrorOutlineIcon />
                        </Tooltip>
                    )}
                </div>
            </AccordionSummary>
            <AccordionDetails>
                {isExpanded && children}
            </AccordionDetails>
        </Accordion>
    );
};

export default CustomAccordionControlled;
