import React from "react";
import { Container, Typography } from "@material-ui/core";
import TimeTrackerAllocation from "./Allocation/TimeTrackerAllocation";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import "./timeTracker.scss";
import CustomHtmlTooltip from "../Shared/Tooltip/CustomHtmlTooltip";

const infoDescription =
  "Please enter time in <b>minutes</b>, not fractions. Eg, 1 hour 30 minutes should be <b>01:30</b>, not 1.5.";
const TimeTracker = () => {
  return (
    <Container maxWidth="lg">
      <div className="time-tracker-root">
        <div className="time-tracker-heading-container">
          <Typography>Time Tracker</Typography>
          <CustomHtmlTooltip title={infoDescription}>
            <InfoOutlinedIcon fontSize="small" />
          </CustomHtmlTooltip>
        </div>
        <TimeTrackerAllocation />
      </div>
    </Container>
  );
};

export default TimeTracker;
