import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
    legendItem: {
      display: 'flex',
      alignItems: 'center',
    },
    colorCircle: {
      width: 20,
      height: 15,
      borderRadius: '20%',
      marginRight: theme.spacing(1),
    },
  }));

// Define the LegendItem component
const LegendItem = ({ color, label }) => {
  const classes = useStyles();

  return (
    <Box className={classes.legendItem}>
      <Box className={classes.colorCircle} style={{ backgroundColor: color }} />
      <Typography variant="caption">{label}</Typography>
    </Box>
  );
};

const ClientMediaInvoiceStatusLegend = () => {
  const legendItems = [
    { color: "#66b98f", label: "Invoices sent" },
    { color: "#f3b581", label: "Invoices created but not sent" },
    { color: "#d10000", label: "Media planned but invoices not created" },
    { color: "#ececec", label: "Media budget available but no media planned" },
  ];

  return (
    <div style={{display: "flex", gap: 15}} >
      {legendItems.map((item, index) => (
        <div key={index}>
          <LegendItem color={item.color} label={item.label} />
        </div>
      ))}
    </div>
  );
};

export default ClientMediaInvoiceStatusLegend;
