import React, { useState, useEffect } from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField,
    Typography
} from "@material-ui/core";
import {
    Close as CloseIcon
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { getXMonthsBackAnd1MonthForwardArr } from "../../../../utils/TimeHelpers";
import moment from "moment";
import ProcessingSpinner from "../../../Shared/Spinner/ProcessingSpinner";
import "./mediaDeliveries.scss";
import ClientDeliverableTable from "./ClientDeliverablesTable";
import Axios from "axios";
import Spinner from "../../../Shared/Spinner/Spinner";

const AdStreamDeliverablesDialog = ({
    open,
    handleClose,
    selectedBillingMonth,
    setSelectedBillingMonth,
    clientDeliveries = [],
    handleGenerateDeliveryInvoices,
    isGeneratingInvoices
}) => {
    const handleSubmit = async () => {
        await handleGenerateDeliveryInvoices(selectedBillingMonth, clientDeliveries);
        handleClose();
    };

    return (
        <Dialog
            id="adstream-deliverables-dialog"
            open={open}
            maxWidth="lg"
            fullWidth
            onClose={(e, reason) => {
                if (reason !== "backdropClick" && reason !== "escapeKeyDown" && !isGeneratingInvoices) {
                    handleClose();
                }
            }}>
            <DialogTitle>
                <div className="media-deliveries-dialog-header">
                    <Typography className="roboto-bold title">Media Deliveries</Typography>
                    <div></div>
                    <div></div>
                    <div className="close-button-container">
                        <IconButton
                            size="small"
                            onClick={() => {
                                if(!isGeneratingInvoices){
                                    handleClose()
                                }
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="media-deliveries-dialog-content">
                    <div>
                        <Autocomplete
                            disabled={isGeneratingInvoices}
                            value={
                                selectedBillingMonth
                                    ? {
                                        display: selectedBillingMonth.format("MMMM YYYY"),
                                        value: selectedBillingMonth,
                                    }
                                    : null
                            }
                            options={getXMonthsBackAnd1MonthForwardArr(11)}
                            onChange={(event, option) => {
                                setSelectedBillingMonth(moment(option.value));
                            }}
                            getOptionLabel={(option) => option.display}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={"Month"}
                                    size="small"
                                    variant="outlined"
                                />
                            )}
                            disableClearable
                        />
                    </div>
                    <div></div>
                    <div></div>
                    {isGeneratingInvoices ? <React.Fragment></React.Fragment> :
                        <Button
                            className={clientDeliveries.length == 0 ? "default-disabled-button" : "default-button add-budget-button"}
                            size="small"
                            disabled={clientDeliveries.length == 0}
                            onClick={(e) => handleSubmit()}
                        >
                            Generate Delivery Invoices
                        </Button>
                    }
                </div>
            </DialogContent>
            <DialogContent>
                <div className="media-deliveries-table-container">
                    {isGeneratingInvoices ? <ProcessingSpinner duration={60} unitTime={"seconds"} />
                        : <ClientDeliverableTable clientDeliveries={clientDeliveries} />
                    }
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default AdStreamDeliverablesDialog;
