import {
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { currencyFormat } from "../../../../utils/GeneralUtils";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CustomTableRowBudgetItem from "./CustomTableRowBudgetItem";
import { useHistory } from "react-router-dom";

const CustomTableRowProject = ({
  projectBills,
  setSnackBarProps,
  companyId,
}) => {
  const history = useHistory();
  const [openAllocated, setOpenAllocated] = useState(false);

  const handleRedirectProject = () => {
    if (projectBills?.organisationFk === 1) {
      history.push(`/project/home/${projectBills.projectId}/0`);
    } else if (projectBills?.organisationFk > 1 && companyId) {
      history.push(
        `/Client/Home/${companyId}/${
          projectBills.campaignFk ? `1/${projectBills.campaignFk}` : ""
        }`
      );
    }
  };
  return (
    <Fragment>
      <TableRow>
        <TableCell className="client-budget-table-icon-column">
          {projectBills.budgetItems?.length > 0 && (
            <>
              <IconButton aria-label="expand row" size="small">
                {openAllocated ? (
                  <KeyboardArrowDownIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenAllocated(!openAllocated);
                    }}
                  />
                ) : (
                  <KeyboardArrowUpIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenAllocated(!openAllocated);
                    }}
                  />
                )}
              </IconButton>
            </>
          )}
        </TableCell>
        <TableCell className="expense-table-project-name-column">
          <div className="expense-table-project-links-container">
            {companyId ? (
              <Typography variant="caption" onClick={handleRedirectProject} className="expense-table-project-name-link">
                {projectBills.projectName}
              </Typography>
            ) : (
              <Typography variant="caption">
                {projectBills.projectName}
              </Typography>
            )}
            {projectBills.jobId && (
              <a
                href={`https://sales.thompsonspencer.com/job/${projectBills.jobId}`}
                target="_blank"
              >
                <Typography variant="caption">(projecktor)</Typography>
              </a>
            )}
          </div>
        </TableCell>
        <TableCell>
          {currencyFormat(Number(projectBills.quotedByProject))}
        </TableCell>
        <TableCell>
          {currencyFormat(Number(projectBills.allocatedByProject))}
        </TableCell>
        <TableCell>
          {currencyFormat(Number(projectBills.remainingByProject))}
        </TableCell>
      </TableRow>
      {projectBills.budgetItems?.length > 0 &&
        openAllocated &&
        projectBills.budgetItems.map((budgetItemBills, indexBudget) => (
          <CustomTableRowBudgetItem
            key={indexBudget}
            budgetItemBills={budgetItemBills}
            setSnackBarProps={setSnackBarProps}
          />
        ))}
    </Fragment>
  );
};

export default CustomTableRowProject;
