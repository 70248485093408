import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import {
  Modal,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  TextField,
  Button,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import "../Location/locationStyles.css";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { checkEmail } from "../../../../utils/Validation";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    width: "100%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minHeight: 390,
    maxHeight: "50vH",
    width: "25vW",
    overflowY: "auto",
  },
  paperMatchContractorPopUp: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minHeight: 550,
    width: "25vW",
    overflow: "hidden",
  },
}));

const filter = createFilterOptions();

function ScopeOfWorkPopUp(props) {
  const classes = useStyles();
  console.log("PrePost Notes");
  console.log(props);

  const [note, setNote] = useState(
    props.scheduleItem.prePostNotes === "-"
      ? null
      : props.scheduleItem.prePostNotes
  );
  const [notesCompleted, setNotesCompleted] = useState(
    props.scheduleItem.contractStatus !== "Send" &&
      props.scheduleItem.contractStatus !== "Disabled"
  );

  return (
    <div>
      <Modal
        open={props.modalState}
        onClose={props.handleModal}
        className={classes.modal}
      >
        <div>
          <AppBar position="static" style={{ background: "#217F8B" }}>
            <Toolbar variant="dense" style={{ paddingRight: 0 }}>
              <Typography
                variant="subtitle1"
                align="center"
                style={{ flexGrow: 1 }}
              >
                Scope Of Work
              </Typography>
              <IconButton
                aria-label="Close Agent Popup"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={props.handleModal}
                color="inherit"
              >
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div className={classes.paper}>
            <TextField
              disabled={notesCompleted}
              variant={"outlined"}
              multiline={true}
              fullWidth={true}
              label={"Scope of Work"}
              value={note}
              onChange={(event) => {
                setNote(event.target.value);
              }}
              rows={16}
              placeholder={
                "Example:\n\n Delivery of 12 unique story board images \n\n Design as per brief \n - Including all changes"
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div
              style={{
                marginTop: 10,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {notesCompleted ? (
                <React.Fragment></React.Fragment>
              ) : (
                <Button
                  variant={"contained"}
                  onClick={() => {
                    props.handleComplete(note);
                  }}
                  color="primary"
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ScopeOfWorkPopUp;
