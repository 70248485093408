import React, { useEffect, useState } from "react";
import Loader from "./Loader";
import Snacky from "./Shared/Snacky";
import axios from "axios";
import "./Projects.css";
import "./Home/clientsHome.scss";
import { withStyles, styled } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  TablePagination,
} from "@material-ui/core";
import { resetOrphans } from "../actions/projects";
import { useHistory } from "react-router-dom";
import { setSnackBarProps } from "../actions/snackbar";
import { connect } from "react-redux";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

//----------------------------------------
const StyledPaper = styled(Paper)({
  width: "100%",
});

const ProjectsTable = (props) => {
  let history = useHistory();
  const [projects, setProjects] = useState({
    archivedProjects: [],
    totalProjects: 0,
    loading: true,
  });
  const [sorting, setSorting] = useState({
    jobId: null,
    projectTag: null,
    projectName: null,
    customerName: null,
  });
  const [projectToUnarchive, setProjectToUnarchive] = useState();
  const [showUnarchiveModal, setShowUnarchiveModal] = useState(false);
  const [pagination, setPagination] = useState({
    highestPage: 0,
    rowsPerPage: 25,
    page: 0,
  });
  const [paginationNavClicked, setPaginationNavClicked] = useState(false);
  const [keyword, setKeyword] = useState("")

  useEffect(() => {
    if (projects.loading === true) {
      populateProjectsData();
    }
  }, []);

  useEffect(() => {
    props.resetProject();
    props.resetBudget();
    props.resetScheduleTable();
    props.resetSchedule();
    props.resetScheduleItems();
    props.resetOrphans();
  }, []);

  useEffect(() => {
    populateProjectsData();
  }, [pagination]);

  const populateProjectsData = () => {
    axios.get("/Projects/SearchArchivedProjects", {
      params: {
        keyword,
        pageSize: pagination.rowsPerPage,
        page: pagination.page
      }
    })
      .then((res) => {
        setProjects({
          archivedProjects: res.data.projects,
          loading: false,
          totalProjects: res.data.totalProjects
        });
      }).catch(e => {
        console.log(e);
      });
  };

  const unarchiveProject = () => {
    axios
      .put(`/Projects/ArchiveProject/${projectToUnarchive.id}`)
      .then((res) => {
        props.setSnackBarProps("success", "Project restored", true);
        setProjects({
          ...projects,
          archivedProjects: projects.archivedProjects.filter(
            (proj) => proj.id !== projectToUnarchive.id
          )
        });
        setProjectToUnarchive();
      })
      .catch(function (error) {
        props.setSnackBarProps("failure", "Failed to restore project", true);
        console.log(error);
      });
  };

  const unarchiveModal = () => {
    return (
      <Dialog
        open={showUnarchiveModal}
        onClose={() => setShowUnarchiveModal(false)}
      >
        <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
          Re-activate project?
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="center">
            This project is archived. Do you want to re-activate this project
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => {
              setShowUnarchiveModal(false);
              setProjectToUnarchive();
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              unarchiveProject();
              setShowUnarchiveModal(false);
            }}
            color="secondary"
            autoFocus
          >
            Re-activate project
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const sortUp = (field) => {
    setProjects({
      ...projects,
      [props.view]: projects[props.view].sort((a, b) =>
        a[field].localeCompare(b[field])
      ),
    });
    setSorting({ ...sorting, [field]: "up" });
  };

  const sortDown = (field) => {
    setProjects({
      ...projects,
      [props.view]: projects[props.view].sort((a, b) =>
        b[field].localeCompare(a[field])
      ),
    });
    setSorting({ ...sorting, [field]: "down" });
  };

  const handleSearch = (keyword) => {
    populateProjectsData()
  }

  if (projects.loading) {
    return <Loader />;
  } else {
    return (
      <div>
        <div className="default-text-field-container" style={{ marginBottom: "20px" }}>
          <TextField
            id="keyword"
            label="Global Search"
            variant="filled"
            fullWidth={true}
            value={keyword}
            size="small"
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 13 || e.key === "Enter") {
                handleSearch(keyword);
              }
            }}
            onBlur={(e) => {
              handleSearch(keyword);
            }}
          />
        </div>
        <StyledPaper>
          {showUnarchiveModal && unarchiveModal()}
          {props.snackbar.open && (
            <Snacky
              snackprops={props.snackbar}
              setSnackBarProps={props.setSnackBarProps}
            />
          )}
          <TableContainer>
            <table className="custom-table custom-table-border custom-table-hover socialites-projects-table">
              <thead>
                <tr>
                  <td>
                    <div style={{ display: "flex" }}>
                      <Typography variant="subtitle1">Job{" "}</Typography>
                      {sorting.jobId == null ||
                        sorting.jobId == "down" ? (
                        <ArrowDropDown
                          style={{ cursor: "pointer" }}
                          onClick={() => sortUp("jobId")}
                        />
                      ) : (
                        <ArrowDropUp
                          style={{ cursor: "pointer" }}
                          onClick={() => sortDown("jobId")}
                        />
                      )}
                    </div>
                  </td>
                  <td>
                    <div style={{ display: "flex" }}>
                      <Typography variant="subtitle1">Mag Code{" "}</Typography>
                      {sorting.projectTag == null ||
                        sorting.projectTag == "down" ? (
                        <ArrowDropDown
                          style={{ cursor: "pointer" }}
                          onClick={() => sortUp("projectTag")}
                        />
                      ) : (
                        <ArrowDropUp
                          style={{ cursor: "pointer" }}
                          onClick={() => sortDown("projectTag")}
                        />
                      )}
                    </div>
                  </td>
                  <td>
                    <div style={{ display: "flex" }}>
                      <Typography variant="subtitle1">Name</Typography>
                      {sorting.projectName == null ||
                        sorting.projectName == "down" ? (
                        <ArrowDropDown
                          style={{ cursor: "pointer" }}
                          onClick={() => sortUp("projectName")}
                        />
                      ) : (
                        <ArrowDropUp
                          className="project-table-sort-arrow"
                          style={{ cursor: "pointer" }}
                          onClick={() => sortDown("projectName")}
                        />
                      )}
                    </div>
                  </td>
                  <td>
                    <div style={{ display: "flex" }}>
                      <Typography variant="subtitle1">Customer</Typography>
                      {sorting.customerName == null ||
                        sorting.customerName == "down" ? (
                        <ArrowDropDown
                          style={{ cursor: "pointer" }}
                          onClick={() => sortUp("customerName")}
                        />
                      ) : (
                        <ArrowDropUp
                          style={{ cursor: "pointer" }}
                          onClick={() => sortDown("customerName")}
                        />
                      )}
                    </div>
                  </td>
                  <td><Typography variant="subtitle1">Internal Contact</Typography></td>
                  <td><Typography variant="subtitle1">Action</Typography></td>
                </tr>
              </thead>
              <tbody>
                {projects.archivedProjects.map(
                  (project) => (
                    <tr key={project.id}>
                      <td style={{width: 80}}>
                        <div className="budget-accordion-header-job-number-container-closed"
                          onClick={() => window.open(`https://sales.thompsonspencer.com/job/${project.jobId}`)}>
                          <Typography variant="caption" className="roboto-medium" style={{ fontSize: 10 }}>{`Code: ${project.jobId}`}</Typography>
                        </div>
                      </td>
                      <td
                        onClick={() =>
                          history.push("/project/home/" + project.id + "/0")
                        }
                      >
                        <Typography variant="caption">{project.projectTag}</Typography>
                      </td>
                      <td
                        onClick={() =>
                          history.push("/project/home/" + project.id + "/0")
                        }
                      >
                        <Typography variant="caption">
                          {project.projectName}
                        </Typography>
                      </td>
                      <td
                        onClick={() =>
                          history.push("/project/home/" + project.id + "/0")
                        }
                      >
                        <Typography variant="caption">
                          {project.customerName}
                        </Typography>
                      </td>
                      <td
                        onClick={() =>
                          history.push("/project/home/" + project.id + "/0")
                        }
                      >
                        <Typography variant="caption">
                          {project.userEmail}
                        </Typography>
                      </td>
                      <td>
                        {props.view === "archivedProjects" ? (
                          <Button
                            onClick={() => {
                              setShowUnarchiveModal(true);
                              setProjectToUnarchive(project);
                            }}
                            className="default-button"
                          >
                            Unarchive
                          </Button>
                        ) : (
                          <div></div>
                        )}
                        <Button
                          style={{ visibility: "hidden", cursor: "arrow" }}
                        >
                          <KeyboardArrowRightIcon />
                        </Button>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </TableContainer>
          <TablePagination
            component="div"
            count={projects.totalProjects}
            rowsPerPage={pagination.rowsPerPage}
            page={pagination.page}
            onPageChange={(event, newPage) => {
              if (newPage > pagination.highestPage) {
                setPaginationNavClicked(true);
              }
              setPagination({
                ...pagination,
                page: newPage,
              });
            }}
            onRowsPerPageChange={(event) => {
              setPagination({
                rowsPerPage: parseInt(event.target.value, 10),
                highestPage: -1,
                page: 0,
              });
            }}
          />
        </StyledPaper>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => ({
  snackbar: state.snackbar,
});
// https://react-redux.js.org/api/connect
export default connect(mapStateToProps, {
  setSnackBarProps,
  resetOrphans,
})(ProjectsTable);
