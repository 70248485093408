export const checkIfContainsBudget = (budgetItems, value) => {
  if (budgetItems?.length > 0) {
    const influencerBudget = budgetItems.find((bi) =>
      bi?.category?.toLowerCase().includes(value?.toLowerCase())
    );
    if (influencerBudget) {
      return true;
    }
  }
  return false;
};

export const calculateRemainingBudget = (budgetItems, scheduleItems, type) => {
  const quoted = budgetItems.reduce((remainingBudget, budget) => {
    if (budget.category === type) {
      return remainingBudget + budget?.quoted || 0;
    }
    return remainingBudget;
  }, 0);
  let allocated = 0;

  for (let i = 0; i < scheduleItems.length; i++) {
    const item = scheduleItems[i];

    if (item.category === type) {
      allocated += Number(item?.allocatedRates || 0);
    }
  }
  console.log("quoted", quoted, "allocated", allocated);
  return quoted - allocated;
};

export const groupByProjectId = (budgetItems) => {
  if (!budgetItems || budgetItems.length === 0) {
    return [];
  }

  return budgetItems.reduce((result, item) => {
    const projectId = item.projectId;
    if (!result[projectId]) {
      result[projectId] = [];
    }
    result[projectId].push(item);
    return result;
  }, []);
};
export const getTotalAmountFromList = (field, budgetList) => {
  let total = 0;
  for (const item of budgetList) {
    if (item[field] && typeof item[field] === "number") {
      total += item[field];
    } else {
      total += Number(item[field]);
    }
  }
  return total;
};

export const groupBudgetWithJobIdByProjectId = (budgetItems) => {
  // Check if the budgetItems array is null, undefined, or empty
  if (!budgetItems || budgetItems.length === 0) {
    return [];
  }

  // Use reduce to group the budget items by projectId only if they have a jobId
  return budgetItems.reduce((result, item) => {
    // Check if the item has a jobId
    if (item.jobId) {
      const projectId = item.projectId;

      if (!result[projectId]) {
        result[projectId] = [];
      }

      result[projectId].push(item);
    }

    return result;
  }, []);
};

export const getMockBudget = () => {
  return [
    {
      "budgetId": 4568,
      "projectCategory": "Ecomist - AUSTRALIA - Influencer Spend",
      "isHidden": 0,
      "projectId": 897,
      "projectName": "Ecomist - AUSTRALIA",
      "category": "Influencer Spend",
      "quoted": 3001.96,
      "allocated": 3000,
      "remaining": 1.9600000000000364,
      "billingDate": "2023-07-31T00:00:00",
      "jobId": 4540,
      "records": [
        {
          "name": "Purchase Order for Day Management - INFLUENCERS - Ecomist - AUSTRALIA - Influencer Spend - NZD 3000.00",
          "total": 3000,
          "totalInNzd": 3000,
          "status": "AUTHORISED",
          "campaignName": "",
          "campaignId": null,
          "person": null,
          "type": "Purchase Order"
        }
      ]
    },
    {
      "budgetId": 5174,
      "projectCategory": "Ecomist Social, Influencer & Media Retainer  - Influencer Spend",
      "isHidden": 0,
      "projectId": 1177,
      "projectName": "Ecomist Social, Influencer & Media Retainer ",
      "category": "Influencer Spend",
      "quoted": 2962.96,
      "allocated": 1800,
      "remaining": 1162.96,
      "billingDate": "2023-09-30T00:00:00",
      "jobId": 4827,
      "records": [
        {
          "name": "Purchase Order for Annelise Kumri - Influencer: September content  - Ecomist Social, Influencer & Media Retainer  - Influencer Spend - NZD 1200.00",
          "total": 1200,
          "totalInNzd": 1200,
          "status": "AUTHORISED",
          "campaignName": "",
          "campaignId": null,
          "person": null,
          "type": "Purchase Order"
        },
        {
          "name": "Purchase Order for Joanne Helm - Influencer: September content  - Ecomist Social, Influencer & Media Retainer  - Influencer Spend - NZD 600.00",
          "total": 600,
          "totalInNzd": 600,
          "status": "AUTHORISED",
          "campaignName": "",
          "campaignId": null,
          "person": null,
          "type": "Purchase Order"
        }
      ]
    },
    {
      "budgetId": 5179,
      "projectCategory": "Ecomist Social, Influencer & Media Retainer  - Influencer Spend",
      "isHidden": 0,
      "projectId": 1177,
      "projectName": "Ecomist Social, Influencer & Media Retainer ",
      "category": "Influencer Spend",
      "quoted": 3277.96,
      "allocated": 3275,
      "remaining": 2.9600000000000364,
      "billingDate": "2023-10-30T00:00:00",
      "jobId": 4827,
      "records": [
        {
          "name": "Nicholas  Pearce ",
          "total": 3275,
          "totalInNzd": 3275,
          "status": "Pending",
          "campaignName": null,
          "campaignId": null,
          "person": {
            "id": 2652,
            "firstName": "Nicholas ",
            "lastName": "Pearce ",
            "address": "2/113 Tweed Coast Road Bogangar 2488 NSW",
            "phoneNumber": "0439 300 530",
            "emailAddress": "",
            "photoBlob": null,
            "birthday": null,
            "bankAccount": null,
            "ird": null,
            "website": null,
            "jobTitle": null,
            "personCategory": "Influencer",
            "notes": null,
            "mealPreference": null,
            "currentContractStatus": null,
            "isFirstAider": null,
            "firstAidExpiryDate": null,
            "companyFk": null,
            "rateHour": null,
            "rateDay": null,
            "rateHalfDay": null,
            "rateWeek": null,
            "accountsClientFk": null,
            "fullName": "Nicholas  Pearce ",
            "birthdayDdmm": null,
            "wtexemptionCertificate": null,
            "isArchived": null,
            "locationFk": null,
            "gstRegistered": 1,
            "wtrate": null,
            "bcname": null,
            "bcbankAccount": null,
            "bcird": null,
            "bcisGstreg": 1,
            "bcwtr": null,
            "bcisWt": null,
            "bcorIc": "a",
            "requestInfoStatus": null,
            "ir330c": null,
            "singleUseParam": null,
            "agentFk": 139,
            "enableSms": 1,
            "firstSms": 0,
            "hasParent": 0,
            "parentName": null,
            "accountsDetailFk": null,
            "emailsCc": null,
            "isOnboardingRequired": 0,
            "updateActiveCampaign": null,
            "activeCampaignId": null,
            "personcol": null,
            "updateToken": null,
            "updateTokenCreation": null,
            "organisationFk": 2,
            "signatureFile": null,
            "domainSharedContactId": null,
            "primarySocial": "INSTAGRAM",
            "token": "98cd8996-2ce2-48d2-b1a0-84e42a16f21d",
            "bio": null,
            "xeroContactId": null,
            "xeroEmployeeId": null,
            "employeeDetailsFileId": null,
            "invalidIrd": 0,
            "masterAgreementFileId": "1rhCOvXWB2wvUBJdOYRc45hBbmKLSIGSJLoejSzvtFWk",
            "hasSignedMasterAgreement": null,
            "currency": "AUD",
            "masterAgreementPdfDriveId": null,
            "isInternal": 0,
            "accountsDetailFkNavigation": null,
            "agentFkNavigation": {
              "id": 139,
              "companyFk": 474,
              "firstName": "Maddison ",
              "lastName": "Magri ",
              "emailAddress": "maddy@daymgmt.com.au",
              "phoneNumber": "0431255483",
              "enableSms": 1,
              "firstSms": 0,
              "updateActiveCampaign": null,
              "companyFkNavigation": null,
              "contractorContract": [],
              "person": [],
              "talentContract": []
            },
            "allContracts": null,
            "bankAccountNavigation": null,
            "companyFkNavigation": null,
            "location": [],
            "nps": [],
            "organisationFkNavigation": null,
            "personHistory": [],
            "personImage": [],
            "personNote": [],
            "prePostProduction": [],
            "projectsAssigned": [],
            "receipt": [],
            "scheduleItem": [],
            "scheduleItemClient": [],
            "scheduleItemDeliverables": [],
            "scheduleItemStaff": [],
            "socialMediaProfile": [],
            "transaction": [],
            "locationFkNavigation": []
          },
          "type": "Influencer"
        }
      ]
    },
    {
      "budgetId": 5184,
      "projectCategory": "Ecomist Social, Influencer & Media Retainer  - Influencer Spend",
      "isHidden": 0,
      "projectId": 1177,
      "projectName": "Ecomist Social, Influencer & Media Retainer ",
      "category": "Influencer Spend",
      "quoted": 2962.96,
      "allocated": 2633.67,
      "remaining": 329.28999999999996,
      "billingDate": "2023-11-30T00:00:00",
      "jobId": 4827,
      "records": [
        {
          "name": "Tegan  Porter",
          "total": 1500,
          "totalInNzd": 1533.67,
          "status": "Signed",
          "campaignName": null,
          "campaignId": null,
          "person": {
            "id": 2807,
            "firstName": "Tegan ",
            "lastName": "Porter",
            "address": "16c Shelley Street, Heidelberg Heights",
            "phoneNumber": "0417 157 600",
            "emailAddress": "",
            "photoBlob": null,
            "birthday": null,
            "bankAccount": null,
            "ird": null,
            "website": null,
            "jobTitle": null,
            "personCategory": "Influencer",
            "notes": null,
            "mealPreference": null,
            "currentContractStatus": null,
            "isFirstAider": null,
            "firstAidExpiryDate": null,
            "companyFk": null,
            "rateHour": null,
            "rateDay": null,
            "rateHalfDay": null,
            "rateWeek": null,
            "accountsClientFk": null,
            "fullName": "Tegan  Porter",
            "birthdayDdmm": null,
            "wtexemptionCertificate": null,
            "isArchived": null,
            "locationFk": null,
            "gstRegistered": null,
            "wtrate": null,
            "bcname": null,
            "bcbankAccount": null,
            "bcird": null,
            "bcisGstreg": null,
            "bcwtr": null,
            "bcisWt": null,
            "bcorIc": null,
            "requestInfoStatus": null,
            "ir330c": null,
            "singleUseParam": null,
            "agentFk": 156,
            "enableSms": 1,
            "firstSms": 0,
            "hasParent": 0,
            "parentName": null,
            "accountsDetailFk": null,
            "emailsCc": null,
            "isOnboardingRequired": 0,
            "updateActiveCampaign": null,
            "activeCampaignId": null,
            "personcol": null,
            "updateToken": null,
            "updateTokenCreation": null,
            "organisationFk": 2,
            "signatureFile": null,
            "domainSharedContactId": null,
            "primarySocial": "INSTAGRAM",
            "token": "f1f0954e-eea7-4422-a443-f0a189a9e562",
            "bio": "Tegan, a Melbourne-based mum and content creator, takes delight in showcasing her home decor, documenting her life with her children, and sharing cute outings. We highly recommend collaborating with Tegan, as we are confident that she will create exceptional, high-quality content that aligns perfectly with the Ecomist profile.",
            "xeroContactId": null,
            "xeroEmployeeId": null,
            "employeeDetailsFileId": null,
            "invalidIrd": 0,
            "masterAgreementFileId": "1607nUHh2DmdfEJ7ZXNUMTsAkKuP5jmCJaFLx6zES4Kw",
            "hasSignedMasterAgreement": 1,
            "currency": "AUD",
            "masterAgreementPdfDriveId": "1D6LUVAyM_XkakVIMrkmh5Kw1CzUDTiFR",
            "isInternal": 0,
            "accountsDetailFkNavigation": null,
            "agentFkNavigation": {
              "id": 156,
              "companyFk": 500,
              "firstName": "Amy ",
              "lastName": "Walton ",
              "emailAddress": "amy@societyeightmanagement.com",
              "phoneNumber": "000",
              "enableSms": 1,
              "firstSms": 0,
              "updateActiveCampaign": null,
              "companyFkNavigation": null,
              "contractorContract": [],
              "person": [],
              "talentContract": []
            },
            "allContracts": null,
            "bankAccountNavigation": null,
            "companyFkNavigation": null,
            "location": [],
            "nps": [],
            "organisationFkNavigation": null,
            "personHistory": [],
            "personImage": [],
            "personNote": [],
            "prePostProduction": [],
            "projectsAssigned": [],
            "receipt": [],
            "scheduleItem": [],
            "scheduleItemClient": [],
            "scheduleItemDeliverables": [],
            "scheduleItemStaff": [],
            "socialMediaProfile": [],
            "transaction": [],
            "locationFkNavigation": []
          },
          "type": "Influencer"
        },
        {
          "name": "Tamara  Parish",
          "total": 1100,
          "totalInNzd": 1100,
          "status": "Pending",
          "campaignName": null,
          "campaignId": null,
          "person": {
            "id": 2851,
            "firstName": "Tamara ",
            "lastName": "Parish",
            "address": null,
            "phoneNumber": "",
            "emailAddress": "tamaraparish1@outlook.com",
            "photoBlob": null,
            "birthday": null,
            "bankAccount": null,
            "ird": null,
            "website": null,
            "jobTitle": null,
            "personCategory": "Influencer",
            "notes": null,
            "mealPreference": null,
            "currentContractStatus": null,
            "isFirstAider": null,
            "firstAidExpiryDate": null,
            "companyFk": null,
            "rateHour": null,
            "rateDay": null,
            "rateHalfDay": null,
            "rateWeek": null,
            "accountsClientFk": null,
            "fullName": "Tamara  Parish",
            "birthdayDdmm": null,
            "wtexemptionCertificate": null,
            "isArchived": null,
            "locationFk": null,
            "gstRegistered": null,
            "wtrate": null,
            "bcname": null,
            "bcbankAccount": null,
            "bcird": null,
            "bcisGstreg": null,
            "bcwtr": null,
            "bcisWt": null,
            "bcorIc": null,
            "requestInfoStatus": null,
            "ir330c": null,
            "singleUseParam": null,
            "agentFk": null,
            "enableSms": 1,
            "firstSms": 0,
            "hasParent": 0,
            "parentName": null,
            "accountsDetailFk": null,
            "emailsCc": null,
            "isOnboardingRequired": 0,
            "updateActiveCampaign": null,
            "activeCampaignId": null,
            "personcol": null,
            "updateToken": null,
            "updateTokenCreation": null,
            "organisationFk": 2,
            "signatureFile": null,
            "domainSharedContactId": null,
            "primarySocial": "INSTAGRAM",
            "token": "a2ba8c70-4326-4dad-84fe-a616581e7d16",
            "bio": "Tamara, a mum of three from NSW, shares glimpses of her fun and beautiful life, with a focus on her home, children, and family. The Ecomist campaign aligns perfectly with her profile, making her an ideal choice for collaboration.",
            "xeroContactId": null,
            "xeroEmployeeId": null,
            "employeeDetailsFileId": null,
            "invalidIrd": 0,
            "masterAgreementFileId": "1UujcKvv7kz7_1PkeczsU2qjrHMS5h12myJk5nPgmSAI",
            "hasSignedMasterAgreement": null,
            "currency": "NZD",
            "masterAgreementPdfDriveId": null,
            "isInternal": 0,
            "accountsDetailFkNavigation": null,
            "agentFkNavigation": null,
            "allContracts": null,
            "bankAccountNavigation": null,
            "companyFkNavigation": null,
            "location": [],
            "nps": [],
            "organisationFkNavigation": null,
            "personHistory": [],
            "personImage": [],
            "personNote": [],
            "prePostProduction": [],
            "projectsAssigned": [],
            "receipt": [],
            "scheduleItem": [],
            "scheduleItemClient": [],
            "scheduleItemDeliverables": [],
            "scheduleItemStaff": [],
            "socialMediaProfile": [],
            "transaction": [],
            "locationFkNavigation": []
          },
          "type": "Influencer"
        }
      ]
    },
    {
      "budgetId": 5189,
      "projectCategory": "Ecomist Social, Influencer & Media Retainer  - Influencer Spend",
      "isHidden": 0,
      "projectId": 1177,
      "projectName": "Ecomist Social, Influencer & Media Retainer ",
      "category": "Influencer Spend",
      "quoted": 2962.96,
      "allocated": 2600,
      "remaining": 362.96000000000004,
      "billingDate": "2023-12-30T00:00:00",
      "jobId": 4827,
      "records": [
        {
          "name": "Alhana  Friend ",
          "total": 1240.87,
          "totalInNzd": 1400,
          "status": "AwaitingPayment",
          "campaignName": null,
          "campaignId": null,
          "person": {
            "id": 2852,
            "firstName": "Alhana ",
            "lastName": "Friend ",
            "address": "15 combara ave Caringbah NSW 2229",
            "phoneNumber": "0407937589",
            "emailAddress": "alhana.friend@gmail.com",
            "photoBlob": null,
            "birthday": null,
            "bankAccount": "553236646",
            "ird": null,
            "website": null,
            "jobTitle": null,
            "personCategory": "Influencer",
            "notes": null,
            "mealPreference": null,
            "currentContractStatus": null,
            "isFirstAider": null,
            "firstAidExpiryDate": null,
            "companyFk": null,
            "rateHour": null,
            "rateDay": null,
            "rateHalfDay": null,
            "rateWeek": null,
            "accountsClientFk": null,
            "fullName": "Alhana  Friend ",
            "birthdayDdmm": null,
            "wtexemptionCertificate": null,
            "isArchived": null,
            "locationFk": null,
            "gstRegistered": 0,
            "wtrate": null,
            "bcname": null,
            "bcbankAccount": "553236646",
            "bcird": null,
            "bcisGstreg": 0,
            "bcwtr": null,
            "bcisWt": null,
            "bcorIc": "ic",
            "requestInfoStatus": null,
            "ir330c": null,
            "singleUseParam": null,
            "agentFk": null,
            "enableSms": 1,
            "firstSms": 0,
            "hasParent": 0,
            "parentName": null,
            "accountsDetailFk": null,
            "emailsCc": null,
            "isOnboardingRequired": 0,
            "updateActiveCampaign": null,
            "activeCampaignId": null,
            "personcol": null,
            "updateToken": null,
            "updateTokenCreation": null,
            "organisationFk": 2,
            "signatureFile": null,
            "domainSharedContactId": null,
            "primarySocial": "INSTAGRAM",
            "token": "e8b07ac5-9741-4afb-a8ab-f31ac270c919",
            "bio": "Alhana embodies the essence of aesthetics, focusing on motherhood, self-care, and home decor. With her beautiful and refined approach, we believe Ecomist products would seamlessly complement her profile.",
            "xeroContactId": null,
            "xeroEmployeeId": null,
            "employeeDetailsFileId": null,
            "invalidIrd": 0,
            "masterAgreementFileId": "1g26p7HBbEUzImnDi0sW5qd_4lZInT_ZglkBuhvBpw24",
            "hasSignedMasterAgreement": 1,
            "currency": "AUD",
            "masterAgreementPdfDriveId": "16wAxtMjHmsZ6durCxvuahqNKRdTEb9yQ",
            "isInternal": 0,
            "accountsDetailFkNavigation": null,
            "agentFkNavigation": null,
            "allContracts": null,
            "bankAccountNavigation": null,
            "companyFkNavigation": null,
            "location": [],
            "nps": [],
            "organisationFkNavigation": null,
            "personHistory": [],
            "personImage": [],
            "personNote": [],
            "prePostProduction": [],
            "projectsAssigned": [],
            "receipt": [],
            "scheduleItem": [],
            "scheduleItemClient": [],
            "scheduleItemDeliverables": [],
            "scheduleItemStaff": [],
            "socialMediaProfile": [],
            "transaction": [],
            "locationFkNavigation": []
          },
          "type": "Influencer"
        },
        {
          "name": "Sonal  Anand",
          "total": 1063.56,
          "totalInNzd": 1200,
          "status": "AwaitingPayment",
          "campaignName": null,
          "campaignId": null,
          "person": {
            "id": 2853,
            "firstName": "Sonal ",
            "lastName": "Anand",
            "address": "3 Tahiti Place , Kings Langley -2147",
            "phoneNumber": "0432097982",
            "emailAddress": "sonalayaansh@gmail.com",
            "photoBlob": null,
            "birthday": null,
            "bankAccount": "300893219",
            "ird": null,
            "website": null,
            "jobTitle": null,
            "personCategory": "Influencer",
            "notes": null,
            "mealPreference": null,
            "currentContractStatus": null,
            "isFirstAider": null,
            "firstAidExpiryDate": null,
            "companyFk": null,
            "rateHour": null,
            "rateDay": null,
            "rateHalfDay": null,
            "rateWeek": null,
            "accountsClientFk": null,
            "fullName": "Sonal  Anand",
            "birthdayDdmm": null,
            "wtexemptionCertificate": null,
            "isArchived": null,
            "locationFk": null,
            "gstRegistered": 0,
            "wtrate": null,
            "bcname": null,
            "bcbankAccount": "300893219",
            "bcird": null,
            "bcisGstreg": 0,
            "bcwtr": null,
            "bcisWt": null,
            "bcorIc": "ic",
            "requestInfoStatus": null,
            "ir330c": null,
            "singleUseParam": null,
            "agentFk": null,
            "enableSms": 1,
            "firstSms": 0,
            "hasParent": 0,
            "parentName": null,
            "accountsDetailFk": null,
            "emailsCc": null,
            "isOnboardingRequired": 0,
            "updateActiveCampaign": null,
            "activeCampaignId": null,
            "personcol": null,
            "updateToken": null,
            "updateTokenCreation": null,
            "organisationFk": 2,
            "signatureFile": null,
            "domainSharedContactId": null,
            "primarySocial": "INSTAGRAM",
            "token": "3d9324c3-5aab-4079-98f9-7cc36660d9f5",
            "bio": "Sonal, a mum and wife content creator, offers glimpses into her life in Australia. Her kind and relatable approach makes her a great fit for Ecomist campaigns. We recommend considering her for collaboration as her profile aligns seamlessly with the campaign goals.",
            "xeroContactId": null,
            "xeroEmployeeId": null,
            "employeeDetailsFileId": null,
            "invalidIrd": 0,
            "masterAgreementFileId": "1ISiDwkWhDvYpMfJXKexp-jP35QBVFUaWrWe8wxCUufk",
            "hasSignedMasterAgreement": 1,
            "currency": "AUD",
            "masterAgreementPdfDriveId": "14xcwRwZViaHpWmvrPJSZPu9Nc86xQ-z6",
            "isInternal": 0,
            "accountsDetailFkNavigation": null,
            "agentFkNavigation": null,
            "allContracts": null,
            "bankAccountNavigation": null,
            "companyFkNavigation": null,
            "location": [],
            "nps": [],
            "organisationFkNavigation": null,
            "personHistory": [],
            "personImage": [],
            "personNote": [],
            "prePostProduction": [],
            "projectsAssigned": [],
            "receipt": [],
            "scheduleItem": [],
            "scheduleItemClient": [],
            "scheduleItemDeliverables": [],
            "scheduleItemStaff": [],
            "socialMediaProfile": [],
            "transaction": [],
            "locationFkNavigation": []
          },
          "type": "Influencer"
        }
      ]
    },
    {
      "budgetId": 5194,
      "projectCategory": "Ecomist Social, Influencer & Media Retainer  - Influencer Spend",
      "isHidden": 0,
      "projectId": 1177,
      "projectName": "Ecomist Social, Influencer & Media Retainer ",
      "category": "Influencer Spend",
      "quoted": 2962.96,
      "allocated": 1604.9,
      "remaining": 1358.06,
      "billingDate": "2024-01-30T00:00:00",
      "jobId": 4827,
      "records": [
        {
          "name": "Purchase Order for Society Eight Management - Influencer: Tegan Porter - Ecomist Social, Influencer & Media Retainer  - Influencer Spend - AUD 1500.00",
          "total": 1500,
          "totalInNzd": 1604.9,
          "status": "AUTHORISED",
          "campaignName": "",
          "campaignId": null,
          "person": null,
          "type": "Purchase Order"
        }
      ]
    },
    {
      "budgetId": 5199,
      "projectCategory": "Ecomist Social, Influencer & Media Retainer  - Influencer Spend",
      "isHidden": 0,
      "projectId": 1177,
      "projectName": "Ecomist Social, Influencer & Media Retainer ",
      "category": "Influencer Spend",
      "quoted": 2962.96,
      "allocated": 0,
      "remaining": 2962.96,
      "billingDate": "2024-02-29T00:00:00",
      "jobId": 4827,
      "records": []
    },
    {
      "budgetId": 5204,
      "projectCategory": "Ecomist Social, Influencer & Media Retainer  - Influencer Spend",
      "isHidden": 0,
      "projectId": 1177,
      "projectName": "Ecomist Social, Influencer & Media Retainer ",
      "category": "Influencer Spend",
      "quoted": 2962.96,
      "allocated": 0,
      "remaining": 2962.96,
      "billingDate": "2024-03-29T00:00:00",
      "jobId": 4827,
      "records": []
    },
    {
      "budgetId": 5279,
      "projectCategory": "Ecomist Social, Influencer & Media Retainer  - Influencer Spend",
      "isHidden": 0,
      "projectId": 1177,
      "projectName": "Ecomist Social, Influencer & Media Retainer ",
      "category": "Influencer Spend",
      "quoted": 0,
      "allocated": 0,
      "remaining": 0,
      "billingDate": "2023-10-31T00:00:00",
      "jobId": 4841,
      "records": []
    },
    {
      "budgetId": 4567,
      "projectCategory": "Ecomist - AUSTRALIA - Media Spend Media Management and Media costs",
      "isHidden": 0,
      "projectId": 897,
      "projectName": "Ecomist - AUSTRALIA",
      "category": "Media Spend Media Management and Media costs",
      "quoted": 7037.04,
      "allocated": 7000,
      "remaining": 37.039999999999964,
      "billingDate": "2023-07-31T00:00:00",
      "jobId": 4540,
      "records": [
        {
          "name": "10/10/2023 - 31/10/2023",
          "total": 7000,
          "totalInNzd": 7000,
          "status": null,
          "campaignName": "Testinh",
          "campaignId": null,
          "person": null,
          "type": "Media"
        }
      ]
    },
    {
      "budgetId": 5173,
      "projectCategory": "Ecomist Social, Influencer & Media Retainer  - Media Spend Media Management and Media costs",
      "isHidden": 0,
      "projectId": 1177,
      "projectName": "Ecomist Social, Influencer & Media Retainer ",
      "category": "Media Spend Media Management and Media costs",
      "quoted": 7037.04,
      "allocated": 0,
      "remaining": 7037.04,
      "billingDate": "2023-09-30T00:00:00",
      "jobId": 4827,
      "records": []
    },
    {
      "budgetId": 5178,
      "projectCategory": "Ecomist Social, Influencer & Media Retainer  - Media Spend Media Management and Media costs",
      "isHidden": 0,
      "projectId": 1177,
      "projectName": "Ecomist Social, Influencer & Media Retainer ",
      "category": "Media Spend Media Management and Media costs",
      "quoted": 7037.04,
      "allocated": 0,
      "remaining": 7037.04,
      "billingDate": "2023-10-30T00:00:00",
      "jobId": 4827,
      "records": []
    },
    {
      "budgetId": 5183,
      "projectCategory": "Ecomist Social, Influencer & Media Retainer  - Media Spend Media Management and Media costs",
      "isHidden": 0,
      "projectId": 1177,
      "projectName": "Ecomist Social, Influencer & Media Retainer ",
      "category": "Media Spend Media Management and Media costs",
      "quoted": 7037.04,
      "allocated": 0,
      "remaining": 7037.04,
      "billingDate": "2023-11-30T00:00:00",
      "jobId": 4827,
      "records": []
    },
    {
      "budgetId": 5188,
      "projectCategory": "Ecomist Social, Influencer & Media Retainer  - Media Spend Media Management and Media costs",
      "isHidden": 0,
      "projectId": 1177,
      "projectName": "Ecomist Social, Influencer & Media Retainer ",
      "category": "Media Spend Media Management and Media costs",
      "quoted": 7037.04,
      "allocated": 0,
      "remaining": 7037.04,
      "billingDate": "2023-12-30T00:00:00",
      "jobId": 4827,
      "records": []
    },
    {
      "budgetId": 5193,
      "projectCategory": "Ecomist Social, Influencer & Media Retainer  - Media Spend Media Management and Media costs",
      "isHidden": 0,
      "projectId": 1177,
      "projectName": "Ecomist Social, Influencer & Media Retainer ",
      "category": "Media Spend Media Management and Media costs",
      "quoted": 7037.04,
      "allocated": 0,
      "remaining": 7037.04,
      "billingDate": "2024-01-30T00:00:00",
      "jobId": 4827,
      "records": []
    },
    {
      "budgetId": 5198,
      "projectCategory": "Ecomist Social, Influencer & Media Retainer  - Media Spend Media Management and Media costs",
      "isHidden": 0,
      "projectId": 1177,
      "projectName": "Ecomist Social, Influencer & Media Retainer ",
      "category": "Media Spend Media Management and Media costs",
      "quoted": 7037.04,
      "allocated": 0,
      "remaining": 7037.04,
      "billingDate": "2024-02-29T00:00:00",
      "jobId": 4827,
      "records": []
    },
    {
      "budgetId": 5203,
      "projectCategory": "Ecomist Social, Influencer & Media Retainer  - Media Spend Media Management and Media costs",
      "isHidden": 0,
      "projectId": 1177,
      "projectName": "Ecomist Social, Influencer & Media Retainer ",
      "category": "Media Spend Media Management and Media costs",
      "quoted": 7037.04,
      "allocated": 0,
      "remaining": 7037.04,
      "billingDate": "2024-03-29T00:00:00",
      "jobId": 4827,
      "records": []
    }
  ]
};
