import React, { useState, useEffect } from "react";
import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import LocationsTable from "./LocationsTable";
import { Close, Clear } from "@material-ui/icons";
import {
  Modal,
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  CircularProgress,
  TextField,
  InputAdornment,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    height: "500px",
    width: "1000px",
  },
}));

function NewLocationPopUp(props) {
  const classes = useStyles();
  const [fetchedLocations, setFetchedLocations] = useState(false);
  const [allLocations, setAllLocations] = useState([]);
  const [currentOptions, setCurrentOptions] = useState([]);
  const [reloadImageSlider, setReloadImageSlider] = useState(true);
  const [searchText, setSearchText] = React.useState("");
  let timer = null;

  useEffect(() => {
    if (!fetchedLocations) {
      // Axios.get("Locations/GetLocationsWithImages")
      //   .then((res) => {
      //     setFetchedLocations(true);
      //     setAllLocations(res.data);
      //     setCurrentOptions(res.data);
      //     setReloadImageSlider(false);
      //     setReloadImageSlider(true);
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //   });

      searchLocationsWithImages("");
    }
  }, [fetchedLocations]);

  const handleInputChange = async (value) => {
    // Implement debouncing to delay API call
    clearTimeout(timer); // Clear previous timeout
    if (value) {
      timer = setTimeout(() => {
        searchLocationsWithImages(value);
      }, 500);
    }
  };

  const searchLocationsWithImages = (keyword) => {
    Axios.post("Locations/SearchLocarionsWithImages", {
      keyword: keyword,
      recordCount: 20,
    })
      .then((res) => {
        setFetchedLocations(true);
        setAllLocations(res.data);
        setCurrentOptions(res.data);
        setReloadImageSlider(false);
        setReloadImageSlider(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const changeCurrentOptions = (newOptions) => {
    setCurrentOptions(newOptions);
  };

  return (
    <div>
      <Modal
        open={props.modalState}
        onClose={props.handleModal}
        className={classes.modal}
      >
        <div>
          <AppBar position="static" style={{ background: "#000000" }}>
            <Toolbar variant="dense" style={{ paddingRight: 0 }}>
              <Typography
                variant="subtitle1"
                align="center"
                style={{ flexGrow: 1 }}
              >
                Find Location
              </Typography>
              <IconButton
                onClick={props.handleModal}
                style={{ color: "white" }}
              >
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div className={classes.paper}>
            <div
              style={{
                margin: "0px 5px 5px 0px",
                display: "grid",
                gridTemplateColumns: "0.8fr 0.2fr",
                gap: 15,
              }}
            >
              <TextField
                label="Search All Locations"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  handleInputChange(e.target.value);
                }}
                variant="outlined"
                placeholder="Search Locations"
                size="small"
                fullWidth
              />
              <Button
                variant="contained"
                className="default-button"
                size="small"
                onClick={props.handleNewLocationModal}
              >
                Create a new location instead
              </Button>
            </div>

            {fetchedLocations && reloadImageSlider ? (
              <LocationsTable
                locations={currentOptions}
                allLocations={allLocations}
                setCurrentOptions={changeCurrentOptions}
                setLocation={props.setLocation}
                refreshLocations={() => {
                  setFetchedLocations(false);
                }}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div>
                  <CircularProgress />
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default NewLocationPopUp;
