import React, { useState, useEffect } from "react";
import "./masterStatusItem.scss";
import { Divider, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper } from "@material-ui/core";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon
} from "@material-ui/icons";
import MasterStatusItemRow from "./MasterStatusItemRow";

const MasterStatusItem = ({ clientsDto }) => {
  const [openCampaigns, setOpenCampaigns] = useState(true);
  return (
    <div className="master-status-item-root">
      <div className="expenses-table-records-container">
        <TableContainer component={Paper}>
          <Table size="small" className="master-status-root-container">
            <TableHead>
              <TableRow>
                <TableCell className="master-status-table-icon-column">
                  <IconButton aria-label="expand row" size="small">
                    {!openCampaigns ? (
                      <KeyboardArrowDownIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenCampaigns(!openCampaigns);
                        }}
                      />
                    ) : (
                      <KeyboardArrowUpIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenCampaigns(!openCampaigns);
                        }}
                      />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell colSpan={2}>
                  <Typography variant="h6">
                    {clientsDto.companyName}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            {openCampaigns && (
              <TableBody>
                {clientsDto?.projects?.length > 0 &&
                  clientsDto?.projects.map((campaign) => (
                    <MasterStatusItemRow campaign={campaign} key={`${campaign.campaignId}-master-status-item-row`} />
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default MasterStatusItem;
