import React, { useEffect, useState } from "react";
import Axios from "axios";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Fab,
  MenuItem,
  Select,
  TextField,
  Grid,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { PersonAdd, Search, Close } from "@material-ui/icons";
import ContactDetailsPopUp from "./ContactDetailsPopUp";
import AddContactPopUp from "../../NewContact/AddContactPopUp";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    // maxHeight: 440,
    // minHeight: 440
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const StyledFab = withStyles((theme) => ({
  root: {
    background: "#00b2e2",
    color: "white",
    "&:hover": {
      background: "#008cb3",
    },
  },
}))(Fab);

const ContactTable = (props) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedPerson, setSelectedPerson] = useState({});
  const [accountsContact, setAccountsContact] = useState(0);
  const [accessContact, setAccessContact] = useState(0);
  const [everythingContact, setEverythingContact] = useState(0);
  const [personDetailsModalState, setPersonDetailsModalState] = useState(false);
  const [fetchedContacts, setFetchedContacts] = useState(false);
  const [locationContact, setLocationContact] = useState();
  const [
    addContactModalHasBeenOpened,
    setAddContactModalHasBeenOpened,
  ] = useState(false);
  const [addContactModalState, setAddContactModalState] = useState(false);
  const handleAddContactModal = () => {
    if (addContactModalState) {
      setAddContactModalState(false);
    } else {
      setAddContactModalState(true);
      setAddContactModalHasBeenOpened(true);
    }
  };

  const handlePersonDetailsModal = () => {
    if (personDetailsModalState) {
      setPersonDetailsModalState(false);
      setFetchedContacts(false);
    } else {
      setPersonDetailsModalState(true);
    }
  };

  const onContactSelect = (person) => {
    setSelectedPerson(person);
    handlePersonDetailsModal();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const reloadLocationContact = () => {
    setFetchedContacts(false);
  };

  const handleRemoveContactFromLocation = (contact) => {
    setLocationContact("");
    Axios.put(
      `Locations/RemoveContactFromLocation/${contact.id}/${props.location.id}`
    ).then((response) => console.log(response));
  };

  useEffect(() => {
    if (!fetchedContacts) {
      Axios.get(`Locations/GetLocationContacts/${props.location.id}`)
        .then((res) => {
          console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
          console.log(res.data.primaryContactNavigation);
          setLocationContact(res.data.primaryContactNavigation);
          setFetchedContacts(true);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  });

  if (fetchedContacts) {
    return (
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <StyledFab
            size="small"
            style={{
              position: "absolute",
              zIndex: "10",
              marginTop: "-20px",
              marginLeft: "-20px",
            }}
            onClick={() => handleAddContactModal()}
          >
            <PersonAdd />
          </StyledFab>
          <AddContactPopUp
            handleModal={() => handleAddContactModal()}
            handleAddContactModal={() => handleAddContactModal()}
            modalState={addContactModalState}
            locationId={props.location.id}
            category={"Location Contact"}
            // reloadLocationContacts={reloadLocationContacts}
          />
          <Table stickyHeader size="small" aria-label="sticky table">
            <TableHead>
              <TableRow>
                {/* <TableCell>Photo</TableCell> */}
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fetchedContacts && locationContact !== "" ? (
                <TableRow>
                  {/* <TableCell onClick={() => onContactSelect(person)}>
                          <Avatar
                            alt={person.fullName}
                            src={
                              person.photoBlob !== null || person.photoBlob !== ""
                                ? person.photoBlob
                                : ""
                            }
                          />
                        </TableCell> */}
                  <TableCell onClick={() => onContactSelect(locationContact)}>
                    {locationContact.fullName}
                  </TableCell>
                  <TableCell onClick={() => onContactSelect(locationContact)}>
                    {locationContact.emailAddress}
                  </TableCell>
                  <TableCell onClick={() => onContactSelect(locationContact)}>
                    {/* updated 16.04.2020 */}
                    {locationContact.phoneNumber}

                    {/* {
                          everythingContact === person.id
                            ? "Everything"
                            : accessContact === person.id
                            ? "Access Only"
                            : accountsContact === person.id
                            ? "Accounts"
                            : ""
                            } */}
                    {/* <Grid container spacing={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
                            <Grid item xs={6} md={6} lg={6}>
                              <TextField
                                value={
                                  everythingContact === person.id
                                    ? "Everything"
                                    : accessContact === person.id
                                      ? "Access"
                                      : accountsContact === person.id
                                        ? "Accounts"
                                        : " "
                                }
                                label={
                                  "Current Type"
                                }
                                variant='outlined'
                                size='small'
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                              />
                            </Grid>
                            <Grid item xs={6} md={6} lg={6} >
                              {
                                props.isEditing ?
                                  <FormControl className={classes.formControl}>
                                    <InputLabel shrink style={{ paddingTop: '0px' }}>
                                      Change Type
                                </InputLabel>
                                    <Select
                                      labelId="select-label"
                                      id="demo-simple-select"
                                      width="100%"
                                      onClick={(event) => event.stopPropagation()}
                                      onChange={(event) => {
                                        event.stopPropagation();
  
                                        var contactType = event.target.value;
                                        console.log(contactType);
                                        var primary = 0;
                                        var secondary = 0;
                                        var accounts = 0;
  
                                        if (contactType === "everything" && everythingContact.id !== person.id) {
                                          primary = person.id;
                                        }
                                        if (contactType === "access" && accessContact.id !== person.id) {
                                          secondary = person.id;
                                        }
                                        if (contactType === "accounts" && accountsContact.id !== person.id) {
                                          accounts = person.id;
                                        }
                                        console.log(primary);
                                        console.log(secondary);
                                        console.log(accounts);
                                        Axios.put(`Locations/UpdateContactTypes/${props.location.id}`, {
                                          Everything: primary,
                                          Access: secondary,
                                          Accounts: accounts
                                        })
                                          .then(res => {
                                            setFetchedContacts(false);
                                          })
                                          .catch(e => {
                                            console.log(e);
                                          })
                                      }}
                                    >
                                      <MenuItem value={"everything"}>Everything</MenuItem>
                                      <MenuItem value={"access"}>Access Only</MenuItem>
                                      <MenuItem value={"accounts"}>Accounts</MenuItem>
                                    </Select>
                                  </FormControl>
                                  : ""
                              }
                            </Grid>
                          </Grid> */}
                  </TableCell>
                  <TableCell>
                    <Close
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleRemoveContactFromLocation(locationContact)
                      }
                    />
                  </TableCell>
                </TableRow>
              ) : (
                <div>No contacts to display</div>
              )}
              {personDetailsModalState ? (
                <ContactDetailsPopUp
                  person={selectedPerson}
                  modalState={personDetailsModalState}
                  handleModal={() => handlePersonDetailsModal()}
                />
              ) : (
                ""
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={props.location.person.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        /> */}
      </Paper>
    );
  } else {
    return <div></div>;
  }
};

export default ContactTable;
