import React, { useState, useEffect } from "react";
import Axios from "axios";
import "../../../custom.scss";
import {
    Button,
    Select,
    MenuItem,
    CircularProgress,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    FormHelperText,
} from "@material-ui/core";

const Slides = (props) => {
    const slideType = props.slideType;
    const [slides, setSlides] = useState(props.slidesFromParent ? props.slidesFromParent : []);

    const [newSlideName, setNewSlideName] = useState("");
    const [localSlideName, setLocalSlideName] = useState("");
    const [slidesLoaded, setSlidesLoaded] = useState(props.slidesFromParent ? true : false);
    const [creatingSlide, setCreatingSlide] = useState(false);
    const [selectedSlide, setSelectedSlide] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [createdNew, setCreatedNew] = useState(false);


    const addSlide = () => {
        setShowLoader(true);
        Axios
            .put(`ProjectSlides/CreateExtraSlide/${slideType}/${newSlideName}/${props.project.id}`)
            .then((res) => {
                console.log(`Successfully created the ${slideType} slide`);
                setSlidesLoaded(false);
                setCreatedNew(true);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                setShowLoader(false);
                setCreatingSlide(false);
            });
    };

    const deleteSlide = () => {
        setShowLoader(true);
        setSelectedSlide(null);
        Axios.delete(`ProjectSlides/DeleteProjectSlide/${selectedSlide.id}`)
            .then((res) => {
                console.log(res);
                setSlidesLoaded(false);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                setShowLoader(false);
            });
    };

    const updateSlideName = () => {
        setShowLoader(true);
        Axios.put(`ProjectSlides/PutProjectSlide/${selectedSlide.id}`, {
            id: selectedSlide.id,
            name: localSlideName,
            projectFk: props.project.id,
            driveId: selectedSlide.driveId,
            type: slideType
        })
            .then((res) => {
                console.log(res);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                setShowLoader(false);
                setSlidesLoaded(false);
            });
    };

    useEffect(() => {
        if (!slidesLoaded) {
            setSlidesLoaded(true);
            Axios.get(`ProjectSlides/GetSlides/${slideType}/${props.project.id}`)
                .then(res => {
                    setSlides(res.data);
                })
                .catch(e => {
                    console.log(e);
                })
        }
    }, [slidesLoaded])

    useEffect(() => {
        if (createdNew) {
            setSelectedSlide(slides[slides.length - 1]);
            setCreatedNew(false);
        } else if (slides.length > 0) {
            if (selectedSlide == null) {
                setSelectedSlide(slides[0]);
            }
            else {
                slides.map(slide => {
                    if (slide.id === selectedSlide.id) {
                        setSelectedSlide(slide);
                    }
                })
            }
        }
    }, [slides]);

    useEffect(() => {
        if (selectedSlide !== null && selectedSlide !== undefined) {
            if (selectedSlide.name !== null) {
                setLocalSlideName(selectedSlide.name);
            } else {
                setLocalSlideName(`Main ${slideType} Slide`);
            }
        }
    }, [selectedSlide]);

    return (
        <React.Fragment>
            {/* Upper Section */}
            {selectedSlide && props.openInNewTabButton(`https://docs.google.com/presentation/d/${selectedSlide.driveId}/edit?usp=sharing`)}
            {creatingSlide && !showLoader ? (
                <div style={props.project?.organisationFk !== 1 ? {display: "none"} : {}}>
                    <TextField
                        disabled={showLoader}
                        className="margin-sides-10"
                        value={newSlideName}
                        placeholder={"Enter New Slide Name"}
                        onChange={(event) => setNewSlideName(event.target.value)}
                        size="small"
                        autoFocus
                    />
                    <Button variant="contained" size="small" className="margin-sides-10"
                        disabled={showLoader}
                        onClick={() => {
                            setNewSlideName("");
                            setCreatingSlide(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button variant="contained" size="small" color="primary" className="margin-sides-10"
                        onClick={() => addSlide()}
                        disabled={showLoader}
                    >
                        Create
                    </Button>
                </div>
            ) : (
                <Button
                    variant="contained"
                    disabled={showLoader || props.project.isArchived === 1}
                    color="primary"
                    className="margin-sides-10"
                    onClick={() => setCreatingSlide(true)}
                    size="small"
                    style={props.project?.organisationFk !== 1 ? {display: "none"} : {}}
                >
                    {`Create new ${slideType} slide`}
                </Button>
            )}
            {!creatingSlide && slidesLoaded && (
                <FormControl style={props.project?.organisationFk !== 1 ? {display: "none"} : {}}>
                    <Select
                        className={"margin-sides-10"}
                        disabled={showLoader}
                        onChange={(event) => {
                            setSelectedSlide(event.target.value);
                        }}
                        value="Change Slide"
                    >
                        <MenuItem key={100} value="Change Slide" disabled>
                            Change Slide
                        </MenuItem>
                        {slides.map(slide => {
                            return (
                                <MenuItem key={slide.id} value={slide}>
                                    {slide.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <FormHelperText>
                        Slide Selector
                    </FormHelperText>
                </FormControl>
            )}
            {selectedSlide !== null && selectedSlide !== undefined && !creatingSlide ? (
                <div style={props.project?.organisationFk !== 1 ? {display: "none"} : {}}>
                    <TextField
                        value={localSlideName}
                        onChange={(event) => {
                            setLocalSlideName(event.target.value);
                        }}
                        disabled={showLoader || props.project.isArchived === 1}
                        helperText="Current Slide"
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={showLoader || selectedSlide.id == 1 || props.project.isArchived === 1}
                        className="margin-sides-10"
                        onClick={() => updateSlideName()}
                        size="small"
                    >
                        Save Name
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={showLoader || selectedSlide.id == 1 || props.project.isArchived === 1}
                        className="margin-sides-10"
                        onClick={() => deleteSlide()}
                        size="small"
                    >
                        Delete Slide
                    </Button>
                </div>
            ) : (
                <React.Fragment></React.Fragment>
            )}
            {showLoader ? <CircularProgress /> : <React.Fragment></React.Fragment>}
            {/* End of Upper Section */}
            <div className="responsive-container-slides">
                {selectedSlide && (
                    <iframe
                        title="slides"
                        className="responsive-iframe"
                        src={props.project.isArchived === 1 ?
                            `https://docs.google.com/presentation/d/${selectedSlide.driveId}/preview`
                            : `https://docs.google.com/presentation/d/${selectedSlide.driveId}/edit?usp=sharing`}
                    ></iframe>)
                }
            </div>
        </React.Fragment>
    );
};

export default Slides;
