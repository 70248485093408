import React, { useState, useEffect } from "react";
import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import AddCommonHazardPopup from "./AddCommonHazardPopup";
import HandSContactsList from "./HansSContactsList";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
  compositeDecorator,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import {
  Typography,
  Grid,
  Paper,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  TextField,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { ExpandLess, ExpandMore, Delete } from "@material-ui/icons";
import "./HealthAndSafety.css";
import draftToHtml from "draftjs-to-html";
import { MergeTagOption } from "../../../utils/ToolbarCustomOptions";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const mergeTags = [{ name: "H&S Lead", value: "{H&SLead}" }];

const HealthAndSafetyMainPage = (props) => {
  const classes = useStyles();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [render, setRender] = useState(false);

  const [generalHazardBrief, setGeneralHazardBrief] = useState("");
  const [commonHazardsList, setCommonHazardsList] = useState([]);

  const [selectedHazard, setSelectedHazard] = useState(null);
  const [selectedHazardMessage, setSelectedHazardMessage] = useState(null);
  const [showHazardPopup, setShowHazardPopup] = useState(false);
  const [showSelectedSaveLoader, setShowSelectedSaveLoader] = useState(false);
  const [showGeneralSaveLoader, setShowGeneralSaveLoader] = useState(false);
  const [relaodBrief, setReloadBrief] = useState(true);
  const [reloadCommonHazards, setReloadCommonHazards] = useState(true);

  const onEditorStateChange = (editorState) => {
    console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setEditorState(editorState);
  };

  const clearContract = () => {
    console.log("current state");
    console.log(editorState);
    setEditorState(EditorState.createEmpty());
    setGeneralHazardBrief("");
  };

  const handleSelectedSave = () => {
    setShowSelectedSaveLoader(true);
    Axios.put(`Hazards/PutHazard/${selectedHazard.id}`, {
      id: selectedHazard.id,
      photoLinks: selectedHazard.photoLinks,
      risk: selectedHazard.risk,
      mediationPlan: selectedHazard.mediationPlan,
      scale: selectedHazard.scale,
      locationFk: selectedHazard.locationfk,
      isGeneral: 1,
      description: selectedHazardMessage,
    })
      .then((res) => {})
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setReloadCommonHazards(true);
      });
  };

  const renderSelectedSaveButton = () => {
    if (selectedHazard !== null) {
      return (
        <div className="saveButton">
          {showSelectedSaveLoader ? (
            <CircularProgress />
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={handleSelectedSave}
            >
              Save
            </Button>
          )}
        </div>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const handleGeneralBriefSave = () => {
    setShowGeneralSaveLoader(true);
    Axios.post(`HealthAndSafetyBriefings/PostHealthAndSafetyBriefing`, {
      brief: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      dateStamp: new Date(),
    })
      .then((res) => {})
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setShowGeneralSaveLoader(false);
      });
  };

  const renderGeneralSaveButton = () => {
    return (
      <div className="saveButton">
        {showGeneralSaveLoader ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleGeneralBriefSave}
          >
            Save
          </Button>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (relaodBrief) {
      setReloadBrief(false);
      Axios.get(`HealthAndSafetyBriefings/GetCurrentHealthAndSafetyBriefing/0`)
        .then((res) => {
          console.log(res.data);
          setGeneralHazardBrief(res.data.brief);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [relaodBrief]);

  useEffect(() => {
    if (reloadCommonHazards) {
      setReloadCommonHazards(false);
      Axios.get(`Hazards/GetCommonHazards`)
        .then((res) => {
          console.log(res.data);
          setCommonHazardsList(res.data);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setShowSelectedSaveLoader(false);
        });
    }
  }, [reloadCommonHazards]);

  useEffect(() => {
    if (generalHazardBrief !== null) {
      const blocksFromHTML = convertFromHTML(generalHazardBrief);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(state, compositeDecorator));
      setRender(true);
    }
  }, [generalHazardBrief]);

  return (
    <main className={classes.content}>
      <div className="main-content" />
      <Typography variant="h4">{`Health & Safety`}</Typography>
      <Grid container spacing={2}>
        <AddCommonHazardPopup
          setShowHazardPopup={setShowHazardPopup}
          showHazardPopup={showHazardPopup}
          setReloadCommonHazards={setReloadCommonHazards}
        />
        <Grid item xs={3} md={3} lg={3}>
          <Paper
            style={{
              maxHeight: "calc(100vh - 200px)",
              minHeight: "calc(50vh - 200px)",
              overflow: "auto",
              marginTop: "30px",
            }}
          >
            <List
              component="nav"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  {`Most Common Health & Safety Hazards`}
                </ListSubheader>
              }
            >
              {commonHazardsList.map((hazard, index) => (
                <ListItem
                  key={hazard.id}
                  button
                  onClick={() => {
                    console.log(hazard);
                    setSelectedHazard(hazard);
                    if (
                      hazard.description === null ||
                      hazard.description.trim() === ""
                    ) {
                      setSelectedHazardMessage("");
                    } else {
                      setSelectedHazardMessage(hazard.description);
                    }
                  }}
                >
                  <ListItemText primary={`${hazard.risk}`} />
                  <Delete
                    className="remove-from-hands-list"
                    onClick={() => {
                      Axios.delete(`Hazards/DeleteHazard/${hazard.id}`);
                      setCommonHazardsList(
                        commonHazardsList.filter((h) => h.id !== hazard.id)
                      );
                    }}
                  />
                </ListItem>
              ))}
            </List>
            <Button
              onClick={() => setShowHazardPopup(true)}
              variant="contained"
              color="primary"
              className="add-new-hazard-btn"
            >
              Add New
            </Button>
          </Paper>
          <Paper
            style={{
              maxHeight: "calc(100vh - 200px)",
              minHeight: "calc(50vh - 200px)",
              overflow: "auto",
              marginTop: "5px",
              width: "100%",
            }}
          >
            <HandSContactsList />
          </Paper>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <Paper
            style={{
              maxHeight: "calc(100vh - 200px)",
              minHeight: "calc(50vh - 200px)",
              overflow: "auto",
              marginTop: "30px",
              width: "100%",
            }}
          >
            <div style={{ margin: 10 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography variant="h6">
                    {`Description for: ${
                      selectedHazard !== null
                        ? selectedHazard.risk
                        : "Nothing Selected"
                    }`}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    id="selected-hazard-textfield"
                    disabled={selectedHazard === null ? true : false}
                    multiline
                    fullWidth
                    rowsMax={20}
                    rows={20}
                    variant="outlined"
                    value={selectedHazardMessage}
                    onChange={(event) => {
                      setSelectedHazardMessage(event.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            {renderSelectedSaveButton()}
          </Paper>
        </Grid>
        <Grid item xs={5} md={5} lg={5}>
          <Paper
            style={{
              maxHeight: "calc(100vh - 200px)",
              minHeight: "calc(50vh - 200px)",
              overflow: "auto",
              marginTop: "30px",
              width: "100%",
            }}
          >
            <div style={{ margin: 10 }}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6} lg={6}>
                  <Typography variant="h6">General Hazard Brief</Typography>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      verticalAlign: "top",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    {renderGeneralSaveButton()}
                  </div>
                </Grid>
              </Grid>
            </div>
            <div style={{ margin: 10 }}>
              {/* <TextField
                                id="general-hazard-textfield"
                                disabled={relaodBrief}
                                multiline
                                fullWidth
                                rowsMax={20}
                                rows={20}
                                variant="outlined"
                                value={generalHazardBrief}
                                onChange={(event) => {
                                    getCursorPos();
                                    setGeneralHazardBrief(event.target.value)
                                }}
                            /> */}
              {render ? (
                <div className="">
                  <Editor
                    editorState={editorState}
                    wrapperClassName=""
                    editorClassName="safety-brief-text-editor"
                    onEditorStateChange={onEditorStateChange}
                    handlePastedText={() => false}
                    toolbarCustomButtons={[
                      <MergeTagOption
                        onChange={onEditorStateChange}
                        editorState={editorState}
                      />,
                    ]}
                  />
                </div>
              ) : (
                <CircularProgress />
              )}
            </div>
            {/* {renderGeneralSaveButton()} */}
          </Paper>
        </Grid>
      </Grid>
    </main>
  );
};

export default HealthAndSafetyMainPage;
