import React, { useEffect, useState } from "react";
import {
  getOutputs,
  resetOutputsLoading,
  spliceOutputsFromColumn,
  spliceOutputsToColumn,
  spliceOneOutputFromColumn,
} from "../../actions/outputs";
import {
  copyProject,
  getProjects,
  mergeOrphanSiblings,
  mergeOrphansToRealProjectOnDrop,
  mergeOutputsToShellProjectOnShellProjectDrop,
  renderOrphanOutputs,
  renderOrphanShells,
  resetOrphans,
  resetProjectLoading,
  resortProjects,
  returnOutput,
  returnShellOutputsToOwner,
  spliceOneFromReal,
  spliceOneFromShell,
  spliceProject,
  swapProjects,
  updateRealProjectProgress,
  updateShellProjectProgress,
} from "../../actions/projects";
import {
  getScheduleDates,
  resetProject,
  resetSchedule,
  resetScheduleTable,
} from "../../actions/schedules";
import { resetScheduleItems } from "../../actions/scheduleItems";
import { resetBudget } from "../../actions/budget";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DnDBoard from "./DnDBoard";
import Header from "../Header";
import Dialogy from "../Shared/Dialogy";
import { setDialogProps } from "../../actions/dialog";

const HomeBoard = ({
  copyProject,
  getOutputs,
  getProjects,
  getScheduleDates,
  mergeOrphanSiblings,
  mergeOrphansToRealProjectOnDrop,
  mergeOutputsToShellProjectOnShellProjectDrop,
  outputs: { outputs },
  projects: { projects, loading },
  renderOrphanOutputs,
  renderOrphanShells,
  resetOrphans,
  resetOutputsLoading,
  resetProject,
  resetProjectLoading,
  resetSchedule,
  resetScheduleItems,
  resetScheduleTable,
  resortProjects,
  returnOutput,
  returnShellOutputsToOwner,
  spliceOneFromReal,
  spliceOneFromShell,
  spliceOneOutputFromColumn,
  spliceOutputsFromColumn,
  spliceOutputsToColumn,
  spliceProject,
  swapProjects,
  updateRealProjectProgress,
  updateShellProjectProgress,
  setShowHomeTable,
  setShowInternalProjects,
  showInternalProjects,
}) => {
  useEffect(() => {
    getOutputs();
    console.log("HomeBoard calling getProjects")
    getProjects();
    getScheduleDates();
  }, [getOutputs, getProjects]);

  useEffect(() => {
    if (projects.loading == false && outputs.loading == false) {
      renderOrphanShells(outputs.outputs);
      renderOrphanOutputs(outputs.outputs);
    }
  }, [projects.loading, outputs.loading]);
  useEffect(() => {
    resetProject();
    resetScheduleTable();
    resetSchedule();
    resetScheduleItems();
  }, []);

  useEffect(() => {
    if(projects){
      if(projects.lockProductionCore && !overdueWarningAlreadyDisplayed){
        setShowOverdueWarning(true);
        setOverdueWarningAlreadyDisplayed(true);
      }
    };
  }, [projects]);

  const [openAll, setOpenAll] = useState(false);
  const [showExtraInfo, setShowExtraInfo] = useState(false);

  const [showOverdueWarning, setShowOverdueWarning] = useState(false);
  const [overdueWarningAlreadyDisplayed, setOverdueWarningAlreadyDisplayed] = useState(true);

  const toggleOpenAll = () => {
    if (openAll === true) {
      setOpenAll(false);
    } else {
      setOpenAll(true);
    }
  };
  const toggleShowExtraInfo = () => {
    console.log("settingShowExtraInfo");
    if (showExtraInfo === true) {
      setShowExtraInfo(false);
    } else {
      setShowExtraInfo(true);
    }
  };
  const getFilteredProjects = (projects) => {
    if (showInternalProjects) {
      return projects;
    }
    return projects.filter(p => p.project?.isInternalProject !== 1)
  };

  return (
    <div>
      {projects !== null ? (
        <Dialogy
          setDialogProps={setDialogProps}
          buttonProps={{
            show: showOverdueWarning,
            titleText: "Overdue Delivery",
            contentText: "There is an output that is overdue, other projects will be locked until this has been handled (Note: Overdue project is highlighted in red)",
            buttonOneColor: "primary",
            buttonOneText: "Cancel",
            buttonTwoColor: "primary",
            buttonTwoText: "Ok",
          }}
          handleButtonOne={() => setShowOverdueWarning(false)}
          handleButtonTwo={() => setShowOverdueWarning(false)}
        />
      ) : <div>Loading...</div>}
      <Header
        openAll={openAll}
        parent="KanbanBoard"
        resetScheduleItems={resetScheduleItems}
        showExtraInfo={showExtraInfo}
        toggleOpenAll={toggleOpenAll}
        toggleShowExtraInfo={toggleShowExtraInfo}
        setShowHomeTable={setShowHomeTable}
        setShowInternalProjects={setShowInternalProjects}
        showInternalProjects={showInternalProjects}
      />
      {projects !== null ? (
        <DnDBoard
          copyProject={copyProject}
          mergeOrphanSiblings={mergeOrphanSiblings}
          mergeOrphansToRealProjectOnDrop={mergeOrphansToRealProjectOnDrop}
          mergeOutputsToShellProjectOnShellProjectDrop={
            mergeOutputsToShellProjectOnShellProjectDrop
          }
          openAll={openAll}
          outputs={outputs}
          projects={projects}
          resetOrphans={resetOrphans}
          resetOutputsLoading={resetOutputsLoading}
          resetProjectLoading={resetProjectLoading}
          resortProjects={resortProjects}
          returnOutput={returnOutput}
          returnShellOutputsToOwner={returnShellOutputsToOwner}
          showExtraInfo={showExtraInfo}
          spliceOneFromReal={spliceOneFromReal}
          spliceOneFromShell={spliceOneFromShell}
          spliceOneOutputFromColumn={spliceOneOutputFromColumn}
          spliceOutputsFromColumn={spliceOutputsFromColumn}
          spliceOutputsToColumn={spliceOutputsToColumn}
          spliceProject={spliceProject}
          swapProjects={swapProjects}
          updateRealProjectProgress={updateRealProjectProgress}
          updateShellProjectProgress={updateShellProjectProgress}
          getFilteredProjects={getFilteredProjects}
          lockProductionCore={projects.lockProductionCore}
          displayOverdueWarning={() => setShowOverdueWarning(true)}
        />
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

HomeBoard.propTypes = {
  copyProject: PropTypes.func.isRequired,
  getOutputs: PropTypes.func.isRequired,
  getProjects: PropTypes.func.isRequired,
  mergeOrphanSiblings: PropTypes.func.isRequired,
  mergeOutputsToShellProjectOnShellProjectDrop: PropTypes.func.isRequired,
  outputs: PropTypes.object.isRequired,
  resetProject: PropTypes.func.isRequired,
  resetProjectLoading: PropTypes.func.isRequired,
  resetSchedule: PropTypes.func.isRequired,
  resetScheduleTable: PropTypes.func.isRequired,
  resortProjects: PropTypes.func.isRequired,
  returnOutput: PropTypes.func.isRequired,
  returnShellOutputsToOwner: PropTypes.func.isRequired,
  spliceOneFromReal: PropTypes.func.isRequired,
  spliceOneFromShell: PropTypes.func.isRequired,
  spliceOneOutputFromColumn: PropTypes.func.isRequired,
  swapProjects: PropTypes.func.isRequired,
  updateRealProjectProgress: PropTypes.func.isRequired,
  updateShellProjectProgress: PropTypes.func.isRequired,
};
// https://react-redux.js.org/using-react-redux/connect-mapstate
const mapStateToProps = (state) => ({
  outputs: {
    C: state.C,
    E: state.E,
    O: state.O,
    Sc: state.Sc,
    Sch: state.Sch,
    Sh: state.Sh,
    outputs: state.outputs,
  },
  projects: {
    C: state.C,
    E: state.E,
    O: state.O,
    Sc: state.Sc,
    Sch: state.Sch,
    Sh: state.Sh,
    projects: state.projects,
  },
});
// https://react-redux.js.org/api/connect
export default connect(mapStateToProps, {
  copyProject,
  getOutputs,
  getProjects,
  getScheduleDates,
  mergeOrphanSiblings,
  mergeOrphansToRealProjectOnDrop,
  mergeOutputsToShellProjectOnShellProjectDrop,
  renderOrphanOutputs,
  renderOrphanShells,
  resetBudget,
  resetOrphans,
  resetOutputsLoading,
  resetProject,
  resetProjectLoading,
  resetSchedule,
  resetScheduleItems,
  resetScheduleTable,
  resortProjects,
  returnOutput,
  returnShellOutputsToOwner,
  spliceOneFromReal,
  spliceOneFromShell,
  spliceOneOutputFromColumn,
  spliceOutputsFromColumn,
  spliceOutputsToColumn,
  spliceProject,
  swapProjects,
  updateRealProjectProgress,
  updateShellProjectProgress,
  setDialogProps
})(HomeBoard);
