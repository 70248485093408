import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
} from "@material-ui/core";
import React from "react";
import ReconcilePurchaseOrderTableRow from "./ReconcilePurchaseOrderTableRow";

const ReconcilePurchaseOrderTable = ({
  reconLines,
  payable,
  handleUpdateSelectedReconLines,
  handleRemoveSelectedReconLine,
  setSnackBarProps
}) => {
  return (
    <Table className="reconcile-centre-table-root">
      <TableHead>
        <TableCell
          className="reconcile-table-head-cell-root"
          style={{ width: 70 }}
        >
          <Typography variant="caption">Date</Typography>
        </TableCell>
        <TableCell className="reconcile-table-head-cell-root">
          <Typography variant="caption">PO Number</Typography>
        </TableCell>
        <TableCell
          className="reconcile-table-head-cell-root"
          style={{ minWidth: 100 }}
        >
          <Typography variant="caption">Supplier</Typography>
        </TableCell>
        <TableCell
          className="reconcile-table-head-cell-root"
          style={{ width: 180 }}
        >
          <Typography variant="caption">Service Name</Typography>
        </TableCell>
        <TableCell className="reconcile-table-head-cell-root">
          <Typography variant="caption">Amount</Typography>
        </TableCell>
        {/* <TableCell className="reconcile-table-head-cell-root">
          <Typography variant="caption">Use All</Typography>
        </TableCell> */}
        <TableCell
          className="reconcile-table-head-cell-root"
          style={{ width: 70 }}
        >
          <Typography variant="caption">Use Partial</Typography>
        </TableCell>
        <TableCell
          className="reconcile-table-head-cell-root"
          style={{ width: 120 }}
        ></TableCell>
      </TableHead>
      <TableBody>
        {reconLines.map((reconLine, index) => (
          <ReconcilePurchaseOrderTableRow
            id={`${reconLine.recordType}-${reconLine.recordId}`}
            key={index}
            reconLine={reconLine}
            payable={payable}
            handleUpdateSelectedReconLines={handleUpdateSelectedReconLines}
            handleRemoveSelectedReconLine={handleRemoveSelectedReconLine}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default ReconcilePurchaseOrderTable;
