import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Close as CloseIcon } from "@material-ui/icons";
const SendSingleClientMediaInvoiceDialog = ({
  open,
  handleClose,
  handleSubmit,
  isSendingInvoices,
  company,
}) => {
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      className="default-dialog"
      onClose={(e, reason) => {
        if (reason !== "backdropClick" && !isSendingInvoices) {
          handleClose();
        }
      }}
    >
      <div className="close-icon-container">
        <IconButton
          size="small"
          onClick={() => {
            if (!isSendingInvoices) {
              handleClose();
            }
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <DialogTitle>
        <Typography className={"default-title"}>{"Send Invoices"}</Typography>
        <Typography className="default-description">
          Please confirm all details before sending the invoices.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="media-campaign-invoice-divider">
            <div className="media-campaign-invoice-details-container">
              <Typography
                variant="caption"
                className="media-campaign-invoice-label"
              >
                Send invoices to
              </Typography>
              <Typography
                variant="caption"
                className="media-campaign-invoice-label-value"
              >
                {company?.primaryAccountsEmail}
              </Typography>
            </div>
            {company?.accountsEmailsCc && (
              <div className="media-campaign-invoice-details-container">
                <Typography
                  variant="caption"
                  className="media-campaign-invoice-label"
                >
                  Send a copy to
                </Typography>
                <Typography
                  variant="caption"
                  className="media-campaign-invoice-label-value"
                >
                  {company?.accountsEmailsCc}
                </Typography>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        {isSendingInvoices ? (
          <div style={{ padding: 10 }}>
            <CircularProgress />
          </div>
        ) : (
          <div className="default-action-container">
            <Button
              style={{ marginRight: 5 }}
              className="default-button-grey"
              onClick={(e) => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ marginLeft: 5 }}
              className="default-button"
              onClick={() => {
                handleSubmit();
              }}
            >
              Send
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SendSingleClientMediaInvoiceDialog;
