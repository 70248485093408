import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  client: [],
  staff: [],
  contractor: [],
  talent: [],
  allPeople: [],
  lClient: [],
  lStaff: [],
  lContractor: [],
  lTalent: [],
  peopleLoading: true,
};
const people = createReducer(initialState, {
  GET_CLIENTS: (state, action) => {
    return {
      ...state,
      client: action.payload,
    };
  },
  GET_STAFF: (state, action) => {
    return {
      ...state,
      staff: action.payload,
    };
  },
  GET_CONTRACTORS: (state, action) => {
    return {
      ...state,
      contractor: action.payload,
    };
  },
  GET_TALENT: (state, action) => {
    return {
      ...state,
      talent: action.payload,
    };
  },
  GET_ALL_PEOPLE: (state, action) => {
    return {
      ...state,
      client: action.payload.clients,
      talent: action.payload.talent,
      contractor: action.payload.contractors,
      staff: action.payload.staff,
      allPeople: [
        ...action.payload.clients,
        ...action.payload.talent,
        ...action.payload.contractors,
        ...action.payload.staff,
      ],
      peopleLoading: false,
    };
  },
  GET_LOWERCASE_PEOPLE: (state, action) => {
    return {
      ...state,
      [action.payload.category]: action.payload.data,
    };
  },
  // It's only necessary to reset the clients between projects, as they are the only people that change
  RESET_CLIENTS: (state, action) => {
    return {
      ...state,
      clients: [],
    };
  },
});

export default people;
