import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  client: [],
  staff: [],
  contractor: [],
  talent: [],
  loadingScheduleItems: true,
};
// .sort((a, b) => (a.startTime >= b.startTime ? 1 : -1))
const scheduleItems = createReducer(initialState, {
  GET_SCHEDULE_ITEMS: (state, action) => {
    return {
      ...state,
      client: action.payload.clients.sort((a, b) =>
        a.startTime >= b.startTime ? 1 : -1
      ),
      staff: action.payload.staff.sort((a, b) =>
        a.startTime >= b.startTime ? 1 : -1
      ),
      contractor: action.payload.contractors.sort((a, b) =>
        a.startTime >= b.startTime ? 1 : -1
      ),
      talent: action.payload.talent.sort((a, b) =>
        a.startTime >= b.startTime ? 1 : -1
      ),
      loadingScheduleItems: false,
    };
  },
  EDIT_SCHEDULE_ITEM: (state, action) => {
    state[action.payload.table] = state[action.payload.table].map((si) =>
      si.id == action.payload.scheduleItem.id
        ? { ...si, [action.payload.key]: action.payload.value }
        : si
    );
  },
  POST_SCHEDULE_ITEM: (state, action) => {
    state[action.payload.table] = [
      ...state[action.payload.table],
      action.payload.scheduleItem,
    ];
  },
  POST_SCHEDULE_ITEM_ERROR: (state, action) => {
    console.log(action);
  },
  DELETE_SCHEDULE_ITEM: (state, action) => {
    state[action.payload.table] = state[action.payload.table].filter(
      (si) => si.id !== action.payload.scheduleItem.id
    );
  },
  RESET_SCHEDULE_ITEMS: (state, action) => {
    return {
      initialState,
    };
  },
  UPDATE_SCHEDULE_ITEM_DATES: (state, action) => {
    console.log(action.payload);
    state.client.forEach((si) => {
      si.startTime = action.payload.value + "T" + si.startTime.substr(11, 8);
      si.endTime = action.payload.value + "T" + si.endTime.substr(11, 8);
    });
    state.staff.forEach((si) => {
      si.startTime = action.payload.value + "T" + si.startTime.substr(11, 8);
      si.endTime = action.payload.value + "T" + si.endTime.substr(11, 8);
    });
    state.contractor.forEach((si) => {
      si.startTime = action.payload.value + "T" + si.startTime.substr(11, 8);
      si.endTime = action.payload.value + "T" + si.endTime.substr(11, 8);
    });
    state.talent.forEach((si) => {
      si.startTime = action.payload.value + "T" + si.startTime.substr(11, 8);
      si.endTime = action.payload.value + "T" + si.endTime.substr(11, 8);
    });
  },
  SORT_SCHEDULE_ITEMS: (state, action) => {
    state[action.payload.table].sort((a, b) =>
      a.startTime >= b.startTime ? 1 : -1
    );
  },
  DELETE_SCHEDULE_ITEM_ERROR: (state, action) => {
    console.log(action);
  },
});

export default scheduleItems;
