import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import axios from "axios";
import AgentTooltipIcon from "../../Shared/Tooltip/AgentTooltipIcon";
import { capitalizeWord, currencyFormat } from "../../../utils/GeneralUtils";
import { getTotalAmountFromList } from "../../../utils/BudgetItemsUtils";
import moment from "moment";
import BudgetTotalTable from "./BudgetTotalTable";
import BudgetItemTotalTable from "./BudgetItemTotalTable";
import ContractorPopUp from "../../Project/ProjectOutputs/PopUps/ContractorPopUp";
import GeneralDocumentViewer from "../../Shared/Dialog/GeneralDocumentViewer";
import {
  DescriptionSharp as DescriptionSharpAsIcon
} from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";

const BudgetItemDetails = ({ budget, setSnackBarProps, records, fetchingRecords }) => {
  const [openUpdatePerson, setOpenUpdatePerson] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [showDocumentViewer, setShowDocumentViewer] = useState(null);

  const [purchaseOrderUrl, setPurchaseOrderUrl] = useState("");
  const [invoiceUrl, setInvoiceUrl] = useState("");

  const handleSavePerson = (selectedPerson) => {
    if (selectedPerson) {
      setSnackBarProps({
        open: true,
        severity: "success",
        text: "Your changes have been saved!",
      });
    } else {
      setSnackBarProps({
        open: true,
        severity: "warning",
        text: "Hm, it looks like something went wrong.",
      });
    }
  };

  const handleStatusText = (record) => {
    switch (record.status) {
      case "AwaitingPayment":
        return <React.Fragment>
          <div>Awaiting Payment</div>
          <div>{moment(record.paymentDate).format("DD/MM/YYYY")}</div>
        </React.Fragment>
      case "Paid":
        return <React.Fragment>
          <div>Paid</div>
          <div>{moment(record.paidDate).format("DD/MM/YYYY")}</div>
        </React.Fragment>
      case "Pending":
        if (record.type === "Influencer" || record.type === "Content Creator") {
          return <React.Fragment>
            <div>{`${record.person.firstName} has not signed the contract yet`}</div>
          </React.Fragment>
        }
      default:
        return <div>{record.status}</div>
    }
  }

  const handlePreviewPurchaseOrder = (poId) => {
    if (poId) {
      setPurchaseOrderUrl(`/purchaseOrder/GetPurchaseOrderAsPdf/${poId}`);
      setShowDocumentViewer(true);
    }
  };

  const handlePreviewInvoice = (invoiceId) => {
    if (invoiceId) {
      setInvoiceUrl(`invoice/GetXeroInvoiceAsPdf/${invoiceId}/2`)
      setShowDocumentViewer(true)
    }
  };

  return (
    <>
      {openUpdatePerson && selectedPerson?.id && (
        <ContractorPopUp
          handleModal={() => {
            setSelectedPerson(null);
            setOpenUpdatePerson(false);
          }}
          modalState={openUpdatePerson}
          personId={selectedPerson?.id}
          scheduleItemId={null}
          reloadPersonList={() => console.log("No reload")}
          reloadScheduleItemsList={() => { }}
          type="Influencer"
          providerType={selectedPerson.personCategory}
          handleEditPerson={handleSavePerson}
          selectedContact={selectedPerson}
          rowUpdated={() => { }}
        />
      )}
      {showDocumentViewer && purchaseOrderUrl && (
        <GeneralDocumentViewer
          modalState={showDocumentViewer}
          handleModal={() => {
            setPurchaseOrderUrl("");
            setShowDocumentViewer(false);
          }}
          headerColor="#000"
          docType={"PDF"}
          urlFile={purchaseOrderUrl}
        />
      )}
      {showDocumentViewer && invoiceUrl && (
        <GeneralDocumentViewer
          modalState={showDocumentViewer}
          handleModal={() => {
            setInvoiceUrl("");
            setShowDocumentViewer(false);
          }}
          headerColor="#000"
          docType={"PDF"}
          urlFile={invoiceUrl}
        />
      )}
      {/* {budget.records?.length > 0 && ( */}
      <div className="budget-item-details-root">
        <div style={{ display: "flex", gap: 20, alignItems: "center", justifyContent: "space-between" }}>
          <p className="budget-item-details-budget-name roboto-medium">{budget.category} {!!budget.billingDate
            ? " - " + moment(budget.billingDate).format("MMM/YY")
            : ""}{" "}</p>
          <Typography variant="caption">{budget.invoiceVia !== "HUB" && budget.category?.includes("Media Spend") ? `Media spend has been pre-billed` : ""}</Typography>
        </div>
        <div>
          <Typography variant="caption">
            {budget.approvedByText ? `${budget.approvedByText}. ` : ""}
            {budget.screenshotLink ? <a href={`${budget.screenshotLink}`}>Download screenshot</a> : ""}
          </Typography>
          {fetchingRecords ?
            <Table className="budget-item-details-table-root">
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Table> :
            <Table className="budget-item-details-table-root">
              <TableHead style={records?.length > 0 ? {} : { display: "none" }}>
                <TableRow>
                  <TableCell className="column1 no-border-cell no-padding-cell roboto-medium"><strong>Details</strong></TableCell>
                  <TableCell className="column2 no-border-cell no-padding-cell roboto-medium"><strong>Department</strong></TableCell>
                  <TableCell className="column3 no-border-cell no-padding-cell roboto-medium"><strong>Status</strong></TableCell>
                  <TableCell className="column4 no-border-cell no-padding-cell roboto-medium"><strong>Doc</strong></TableCell>
                  <TableCell className="column5 no-border-cell no-padding-cell roboto-medium"><strong>Amount</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {records.map((record, index) => (
                  <TableRow>
                    <TableCell className="column1">
                      {record.person ? (
                        <div className="client-budget-table-flex-container">
                          <span
                            className={`client-budget-table-link`}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setSelectedPerson(record.person);
                              setOpenUpdatePerson(true);
                            }}
                          >
                            {`${record.name} `}
                          </span>
                          {!!record.person.agentFkNavigation && (
                            <div>
                              <AgentTooltipIcon
                                agent={record.person.agentFkNavigation}
                                size="small"
                              />
                            </div>
                          )}
                          <div>
                            <Typography variant="caption">
                              {` ${record.person?.currency &&
                                record.person?.currency !== "NZD"
                                ? `${record.person?.currency} ${record.total}`
                                : ""
                                }`}
                            </Typography>
                          </div>
                        </div>
                      ) : (
                        `${record.name}`
                      )}
                    </TableCell>
                    <TableCell className="column2">
                      {record.type && (
                        <span> {record.type} </span>
                      )}{" "}
                      {/* {record.campaignName && (
                      <span
                        className={`${
                          record.campaignId ? "client-budget-table-link" : ""
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                            if (record.campaignId) {
                              handlePreLoadCampaign(record.campaignId);
                            }
                        }}
                      >
                        {record.campaignName}
                      </span>
                    )}{" "} */}
                    </TableCell>
                    <TableCell className="column3">
                      {handleStatusText(record)}
                    </TableCell>
                    <TableCell className="column4">
                      {record.id !== null && (
                        <IconButton size="small" onClick={() => {
                          if ((record.type === "Influencer" || record.type === "Content Creator") && record.xeroId !== null) {
                            handlePreviewInvoice(record.xeroId);
                          } else if (record.type === "Purchase Order") {
                            handlePreviewPurchaseOrder(record.id);
                          }
                        }}>
                          <DescriptionSharpAsIcon />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell className="column5">
                      <div>{currencyFormat(Number(record.total), record.currency)}</div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          }
        </div>
        <BudgetItemTotalTable budget={budget} />
      </div>
      {/* )} */}
    </>
  );
};

export default BudgetItemDetails;
