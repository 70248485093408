import React, { useEffect, useState } from "react";
import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import {
    Modal,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Paper
} from "@material-ui/core";
import {
    Close as CloseIcon
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {},
    icon: {
        color: "rgba(255, 255, 255, 0.54)",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
        height: "90vH",
        width: "95vW",
        overflow: "auto",
    },
    feeBox: {
        width: 80,
    },
}));

const AucklandStandardContract = (props) => {
    var classes = useStyles();
    const [contractLoaded, setContractLoaded] = useState(false);
    const [iframeLoading, setIframeLoading] = useState(true);
    const [driveId, setDriveId] = useState(null);

    useEffect(() => {
        if (!contractLoaded) {
            Axios.get(`TalentContracts/GetAucklandContractDriveId/${props.scheduleItem.id}`)
                .then(res => {
                    setDriveId(res.data)
                })
                .catch(e => {
                    console.log(e);
                })
                .finally(() => {
                    setIframeLoading(false)
                    setContractLoaded(true)
                })
        }
    }, [contractLoaded])

    const checkForStatus = (statusArray) => {
        for (var i = 0; i < statusArray.length; i++) {
            if (props.scheduleItem.contractStatus === statusArray[i]) {
                return true;
            }
        }
        return false;
    }

    return (
        <div>
            <Modal
                open={props.modalState}
                onClose={props.handleModal}
                className={classes.modal}
            >
                <div>
                    <AppBar position="static" style={{ background: "#217F8B" }}>
                        <Toolbar variant="dense" style={{ paddingRight: 0 }}>
                            <Typography
                                variant="subtitle1"
                                align="center"
                                style={{ flexGrow: 1 }}
                            >
                                New Zealand Standard Contract
                            </Typography>
                            <IconButton
                                aria-haspopup="true"
                                onClick={props.handleModal}
                                color="inherit"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.paper}>
                        {contractLoaded && (
                            <div style={{ height: "100%" }}>
                                <div style={iframeLoading ? {} : { display: "none" }}>
                                    <h1 className="loading-dots" style={{ display: "flex", justifyContent: "center" }}><span>.</span><span>.</span><span>.</span></h1>
                                </div>
                                <div style={iframeLoading ? { display: "none" } : { height: "100%" }}>
                                    <iframe
                                        src={
                                            checkForStatus(["Send", "Pending"]) ?
                                                `https://docs.google.com/document/d/${driveId}/edit` : `https://drive.google.com/file/d/${driveId}/preview`//`https://drive.google.com/uc?id=${driveId}&export=view`
                                        }
                                        height="100%"
                                        width="100%"
                                    >
                                    </iframe>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default AucklandStandardContract;