import React, { useState, useEffect } from "react";
import "./masterStatusItem.scss";
import {
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Button,
} from "@material-ui/core";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@material-ui/icons";
import ProjectCampaignStepper from "../../Project/Schedule/PrePost/InfluencerNetwork/Campaign/ProjectCampaignStepper";
import ApprovalRequestDialog from "../../Project/Schedule/PrePost/InfluencerNetwork/ApprovalRequest/ApprovalRequestDialog";
import OutreachDialog from "../../Project/Schedule/PrePost/InfluencerNetwork/Outreach/OutreachDialog";
import Snacky from "../../Shared/Snacky";
import ContentApprovedDialog from "../../Project/Schedule/PrePost/InfluencerNetwork/ContentApproved/ContentApprovedDialog";
import { useHistory } from "react-router-dom";
import ContractStatusChips from "../Budget/ContractStatusChips";

const MasterStatusItemRow = ({ campaign }) => {
  const history = useHistory();
  const [openStepper, setOpenStepper] = useState(false);
  const [openApprovalRequestDialog, setOpenApprovalRequestDialog] =
    useState(false);
  const [openOutreachDialog, setOpenOutreachDialog] = useState(false);
  const [scheduleItems, setScheduleItems] = useState(null);
  const [selectedScheduleItem, setSelectedScheduleItem] = useState(null);
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });
  const [
    openContentApprovedOnConfirmation,
    setOpenContentApprovedOnConfirmation,
  ] = useState(false);

  useEffect(() => {
    if (campaign.scheduleItems) {
      setScheduleItems(campaign.scheduleItems);
    }
  }, [campaign.scheduleItems]);

  const handleClickContentApprovedOnStep = (scheduleItem) => {
    setSelectedScheduleItem(scheduleItem);
    setOpenContentApprovedOnConfirmation(true);
  };

  const handleApprovalRequestSentCallback = (sentScheduleItems) => {
    setScheduleItems(
      scheduleItems.map((si) => {
        var sentSchduleItem = sentScheduleItems.find((s) => s.id === si.id);
        if (sentSchduleItem) {
          return {
            ...si,
            scheduleItemStatusFkNavigation:
              sentSchduleItem.scheduleItemStatusFkNavigation,
          };
        }

        return si;
      })
    );
  };

  const handleCloseConfirmationModal = () => {
    setSelectedScheduleItem(null);
    setOpenContentApprovedOnConfirmation(false);
  };

  const handleConfirmContentApproval = async (scheduleItemId, status) => {
    await handleUpdateScheduleItemStatus(scheduleItemId, status);
    handleCloseConfirmationModal();
  };

  const handleUpdateScheduleItemStatus = async (
    scheduleItemId,
    scheduleItemStatus
  ) => {
    setScheduleItems(
      scheduleItems.map((si) =>
        si.id === scheduleItemId
          ? { ...si, scheduleItemStatusFkNavigation: scheduleItemStatus }
          : si
      )
    );
  };

  return (
    <React.Fragment>
      <Snacky snackprops={snackBarProps} setSnackBarProps={setSnackBarProps} />
      {openApprovalRequestDialog && scheduleItems.length > 0 && (
        <ApprovalRequestDialog
          open={openApprovalRequestDialog}
          handleClose={() => setOpenApprovalRequestDialog(false)}
          scheduleItems={scheduleItems}
          scheduleId={scheduleItems[0]?.scheduleFk}
          setSnackBarProps={setSnackBarProps}
          project={scheduleItems[0]?.scheduleFkNavigation?.projectsFkNavigation}
          handleApprovalRequestSentCallback={handleApprovalRequestSentCallback}
        />
      )}
      {openContentApprovedOnConfirmation && (
        <ContentApprovedDialog
          open={openContentApprovedOnConfirmation}
          scheduleItemId={selectedScheduleItem?.id}
          handleClose={handleCloseConfirmationModal}
          handleUpdateCallback={handleConfirmContentApproval}
        />
      )}
      {openOutreachDialog && scheduleItems.length > 0 && (
        <OutreachDialog
          open={openOutreachDialog}
          handleClose={() => setOpenOutreachDialog(false)}
          scheduleItems={scheduleItems}
          scheduleId={scheduleItems[0]?.scheduleFk}
          setSnackBarProps={setSnackBarProps}
        />
      )}
      <TableRow
        key={`${campaign.campaignName}-master-status-row-campaign-name`}
      >
        <TableCell className="master-status-table-icon-column"></TableCell>
        <TableCell className="master-status-table-icon-column">
          <IconButton aria-label="expand row" size="small">
            {!openStepper ? (
              <KeyboardArrowDownIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenStepper(!openStepper);
                }}
              />
            ) : (
              <KeyboardArrowUpIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenStepper(!openStepper);
                }}
              />
            )}
          </IconButton>
        </TableCell>
        <TableCell>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>
              <span className="link"
                onClick={() => history.push(`/Client/Home/${campaign.companyId}/1/${campaign.campaignId}`)}
              >
                {campaign.campaignName}
              </span>
            </Typography>
            <ContractStatusChips
              awaitingSign={campaign.awaitingSign}
              signed={campaign.signed}
              awaitingInvoice={campaign.awaitingInvoice}
            />
          </div>
        </TableCell>
      </TableRow>
      {openStepper && campaign && scheduleItems && (
        <TableRow
          key={`${campaign.campaignName}-master-status-row-campaign-stepper`}
        >
          <TableCell colSpan={3}>
            {/* <div className="project-influencer-network-sent-client-btn">
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => setOpenApprovalRequestDialog(true)}
              >
                Send to Client
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => setOpenOutreachDialog(true)}
              >
                Influencer Outreach
              </Button>
            </div> */}
            {scheduleItems.map((si, index) => (
              <ProjectCampaignStepper
                key={`${si.id}-stepper`}
                scheduleItem={si}
                handleClickClientApprovalStep={() => { }}
                handleUpdateScheduleItemContractStatus={() => { }}
                handleClickContentApprovedOnStep={
                  handleClickContentApprovedOnStep
                }
                handleClickReviewedOnStep={() => { }}
                hideButtons={true}
              />
            ))}
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

export default MasterStatusItemRow;
