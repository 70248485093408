import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";
import EmailEditor from "react-email-editor";
import { withRouter } from "react-router-dom";
import { withStyles, List } from "@material-ui/core";
import Snacky from "../Shared/Snacky";
import { setSnackBarProps } from "../../actions/snackbar";
import { connect } from "react-redux";
import {
  TextField,
  Paper,
  AppBar,
  Toolbar,
  Typography,
  FormControl,
  Fab,
  FormHelperText,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  CircularProgress,
} from "@material-ui/core";
import {
  Home,
  Inbox as InboxIcon,
  MailOutline as MailOutlineIcon,
} from "@material-ui/icons";
import "./emailer.css";
//----------------------------------------
const HomeButton = withRouter(({ history }) => (
  <Home
    onClick={() => {
      history.push("/");
    }}
    style={{ cursor: "pointer" }}
  />
));

const StyledFab = withStyles((theme) => ({
  root: {
    background: "#00B2E2",
    color: "white",
    "&:hover": {
      background: "#008CB3",
    },
  },
}))(Fab);

const TemplateEmailCreator = (props) => {
  const [body, setBody] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailId, setEmailId] = useState();
  const [saveRecord, setSaveRecord] = useState(false);
  const [showSaveLoader, setShowSaveLoader] = useState(false);
  const [emails, setEmails] = useState([]);
  const [designJson, setDesignJson] = useState([]);
  const [templateName, setTemplateName] = useState(null); //
  const [emailsFetched, setEmailsFetched] = useState(false);
  const [componentMounted, setComponentMounted] = useState(false);
  const handleTextFieldChange = (event) => {
    setEmailSubject(event.target.value);
  };
  const handleSelectChange = (email) => {
    setTemplateName(email.name);
    loadDesign(email.designJson, email.subject, email.id);
  };
  const editorContainer = useRef(null);
  const onLoad = () => {
    if (componentMounted && editorContainer?.current) {
      editorContainer.current.editor.setMergeTags([
        { name: "Contractor First Name", value: "{ContractorFirstName}" },
        { name: "Contractor Last Name", value: "{ContractorLastName}" },
        {
          name: "Location Contact First Name",
          value: "{LocationContactFirstName}",
        },
        {
          name: "Location Contact Last Name",
          value: "{LocationContactLastName}",
        },
        { name: "Agent First Name", value: "{AgentFirstName}" },
        { name: "Agent Last Name", value: "{AgentLastName}" },
        { name: "Talent First Name", value: "{TalentFirstName}" },
        { name: "Talent Last Name", value: "{TalentLastName}" },
        { name: "Talent Agency", value: "{TalentAgency}" },
        { name: "Service", value: "{Service}" },
        { name: "Address", value: "{Address}" },
        { name: "Fee", value: "{AllocatedRates}" },
        { name: "Date", value: "{Date}" },
        { name: "Location Price", value: "{Pricing}" },
        { name: "Company Name", value: "{CompanyName}" },
        { name: "Project Name", value: "{ProjectName}" },
      ]);
    }
  };

  useEffect(() => {
    setComponentMounted(true);
  }, []);

  useEffect(() => {
    if (templateName !== null) {
      setShowSaveLoader(true);
      Axios.put(`EmailTemplates/PutEmailTemplate/${emailId}`, {
        id: emailId,
        subject: emailSubject,
        body: body,
        designJson: JSON.stringify(designJson),
        name: templateName,
      })
        .then((res) => {
          console.log(res.data);
          setSaveRecord(false);
          // setEmailSubject("");
          setEmailsFetched(false);
          // setEditMode(false);
          props.setSnackBarProps("success", "Template Saved", true);
        })
        .catch((err) => {
          console.log(err);
          props.setSnackBarProps("warning", "Failed to save template", true);
        })
        .finally(() => {
          setShowSaveLoader(false);
        });
    }
  }, [designJson]);

  const exportHtml = () => {
    editorContainer.current.editor.exportHtml((htmlEmailTemplate) => {
      setBody(htmlEmailTemplate.html);
    });
    editorContainer.current.editor.saveDesign((design) => {
      console.log(design);
      setDesignJson(design);
    });
  };

  useEffect(() => {
    if (saveRecord === false) {
      setSaveRecord(true);
      console.log(designJson);
      console.log("Design Json updated");
    }
  }, [designJson, saveRecord]);

  useEffect(() => {
    if (!emailsFetched) {
      Axios.get("EmailTemplates/GetEmailTemplate")
        .then((res) => setEmails(res.data))
        .catch((err) => console.log(err));
    }
  }, [saveRecord]);

  const loadDesign = (designJsonToLoad, subject, id) => {
    editorContainer.current.editor.loadDesign(JSON.parse(designJsonToLoad));
    setEmailSubject(subject);
    setEmailId(id);
  };

  return (
    <div>
      <Paper className="emailer-div">
        <AppBar position="static" style={{ background: "#00B2E2" }}>
          <Toolbar variant="dense">
            <HomeButton />
            <Typography
              variant="subtitle1"
              align="center"
              style={{ flexGrow: 1 }}
            >
              Template Email Creator
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container>
          {props.snackbar.open && (
            <Snacky
              snackprops={props.snackbar}
              setSnackBarProps={props.setSnackBarProps}
            />
          )}
          <Grid item xs={2} md={2} lg={2}>
            <div
              style={{
                display: "flex",
              }}
            >
              <Button
                color="primary"
                variant="contained"
                className="brandCover"
              >
                Camistry
              </Button>
              <FormControl style={{ margin: "5px" }}>
                <FormHelperText>Email subject</FormHelperText>
                <TextField
                  variant="outlined"
                  name="subject"
                  placeholder={"Subject"}
                  value={emailSubject}
                  onChange={handleTextFieldChange}
                />
              </FormControl>
              {showSaveLoader ? (
                <CircularProgress />
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => exportHtml()}
                  className="saveButton"
                >
                  Save
                </Button>
              )}
              {/* <StyledFab className="saveButton" onClick={() => exportHtml()}>
                <Save />
              </StyledFab> */}
            </div>
            <Paper
              style={{
                maxHeight: "calc(100vh - 150px)",
                overflow: "auto",
              }}
            >
              <List component="nav">
                {emails.map((email, index) => {
                  return (
                    <ListItem
                      key={email.id}
                      button
                      onClick={() => {
                        handleSelectChange(email);
                      }}
                      className={
                        templateName === email.name
                          ? "selectedEmailTemplate"
                          : ""
                      }
                    >
                      <ListItemText primary={`${email.name}`} />
                    </ListItem>
                  );
                })}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={10} md={10} lg={10}>
            <EmailEditor
              ref={editorContainer}
              onReady={onLoad}
              minHeight={"calc(100vh - 50px)"}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  snackbar: state.snackbar,
});
// https://react-redux.js.org/api/connect
export default connect(mapStateToProps, {
  setSnackBarProps,
})(TemplateEmailCreator);
