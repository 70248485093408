import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  open: false,
};
const loader = createReducer(initialState, {
  SET_LOADER_PROPS: (state, action) => {
    return {
      ...state,
      open: action.payload.open,
    };
  },
});

export default loader;
