import React, { useEffect, useMemo, useState } from "react";
import {
  currencyFormat,
  getCurrencies,
  isNumeric,
} from "../../../../utils/GeneralUtils";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import MultiCurrencyInputField from "../../../CurrencyExchange/MultiCurrencyInputField";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import { getMonthsArray } from "../../../../utils/utils";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import axios from "axios";
import ConfirmationDialogActions from "../../../Shared/ConfirmationDialogActions";
import { useUserContext } from "../../../../context/user/UserContext";
import { useAuth } from "../../../../context/auth/AuthContext";
import OutreachFormInfluencerFields from "./OutreachFormInfluencerFields";
import OutreachFormContentCreatorFields from "./OutreachFormContentCreatorFields";

const OutreachForm = ({
  record,
  handleOnChange,
  budgetItems,
  isReadOnly,
  handleChangeAllocatedRate,
  handleSaveScheduleItem,
  updateLocalRecordState,
  hasChanges,
  handeSaveChanges,
  setOpenDeleteItemDialog,
  setShowInvoicesModal,
}) => {
  // Memoize the currencies to avoid recalculating on each render
  const currencies = useMemo(() => getCurrencies(), []);

  const { user } = useAuth();
  const [selectedBudget, setSelectedBudget] = useState(() =>
    budgetItems.find(
      (bi) =>
        bi.budgetId === record.budgetId ||
        bi.projectCategory === record.category
    )
  );
  const [openConfirmRevokeContract, setOpenConfirmRevokeContract] =
    useState(false);

  useEffect(() => {
    if (record?.budgetId !== selectedBudget?.budgetId) {
      setSelectedBudget(
        budgetItems.find(
          (bi) =>
            bi.budgetId === record.budgetId ||
            bi.projectCategory === record.category
        )
      );
    }
  }, [record?.budgetId, budgetItems]);

  const handleRevokeContract = () => {
    axios
      .put(
        `/talentContracts/UpdateOnCancelSignedContract/${record.id}`,
        { dateCancelled: new Date() },
        {
          params: {
            user: user?.email,
          },
        }
      )
      .then(async ({ data }) => {
        const _record = {
          ...record,
          contractStatus: "Send",
        };
        updateLocalRecordState(_record);
        await handleSaveScheduleItem(_record);
        setOpenConfirmRevokeContract(false);
      });
  };

  return (
    <>
      {openConfirmRevokeContract && (
        <ConfirmationDialogActions
          title={"Are you sure?"}
          open={openConfirmRevokeContract}
          contentText={"This action will cancel the current contract"}
          button1Action={() => setOpenConfirmRevokeContract(false)}
          button1Text={"Go Back"}
          button2Text={"Confirm"}
          button2Action={handleRevokeContract}
        />
      )}

      <div className="influencer-outreach-details-body">
        <TextField
          fullWidth
          label="Bio"
          placeholder="Bio"
          variant="outlined"
          multiline
          onChange={(e) => handleOnChange("description", e.target.value)}
          value={record?.description || ""}
          disabled={isReadOnly()}
        />
        <div className="influencer-outreach-details-2columns-grid">
          {selectedBudget &&
          record?.personFkNavigation?.currency === selectedBudget.currency ? (
            <TextField
              label="Fee"
              placeholder="Fee"
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {currencies.find((c) => c.value === selectedBudget.currency)
                      ?.symbol || "$"}
                  </InputAdornment>
                ),
              }}
              value={record?.allocatedRates}
              onChange={(e) =>
                isNumeric(e.target.value) || e.target.value === ""
                  ? handleChangeAllocatedRate(
                      selectedBudget.currency || "NZD",
                      e.target.value,
                      e.target.value
                    )
                  : {}
              }
              disabled={isReadOnly()}
            />
          ) : (
            <MultiCurrencyInputField
              totalAmount={record?.allocatedRates || 0}
              nzdTotalAmount={record?.allocatedRatesInNzd || 0}
              size="small"
              currency={record?.personFkNavigation?.currency}
              handleChange={handleChangeAllocatedRate}
              isReadOnly={isReadOnly()}
              lockDropdown={true}
              baseCurrency={selectedBudget?.currency}
            />
          )}

          {budgetItems?.length > 0 && (
            <Autocomplete
              options={budgetItems.filter(
                (b) => b.isHidden !== 1 && b.remaining > 0
              )}
              value={selectedBudget}
              getOptionLabel={(option) =>
                `${option.projectCategory} ${
                  !!option.billingDate
                    ? " - " + moment(option.billingDate).format("MMM/YY")
                    : ""
                } - ${currencyFormat(
                  Number(option.remaining, option.currency)
                )} remaining`
              }
              onChange={(event, newValue) => {
                setSelectedBudget(newValue || null);
                handleOnChange("budget", newValue || null);
              }}
              renderOption={(option) => {
                return (
                  <li key={option.budgetId}>
                    <Typography variant="caption">
                      {`${option.projectCategory} ${
                        !!option.billingDate
                          ? " - " + moment(option.billingDate).format("MMM/YY")
                          : ""
                      } - ${currencyFormat(
                        Number(option.remaining),
                        option.currency
                      )} remaining`}
                    </Typography>
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Pull From Budget"
                  variant="outlined"
                />
              )}
              disableClearable
              fullWidth
              disabled={isReadOnly()}
            />
          )}
        </div>
        {record.scheduleItemType !== "Content Creator" && (
          <OutreachFormInfluencerFields
            record={record}
            handleOnChange={handleOnChange}
            isReadOnly={isReadOnly}
          />
        )}

        <div className="influencer-outreach-details-2columns-grid">
          <div lassName="influencer-deliverable-item-field-grid">
            <FormControlLabel
              control={
                <Checkbox
                  disabled={isReadOnly()}
                  color="primary"
                  checked={record.shouldSendProduct ? true : false}
                  onChange={(e) => {
                    handleOnChange(
                      "shouldSendProduct",
                      e.target.checked ? 1 : 0
                    );
                  }}
                />
              }
              label="Product Supplied"
            />
          </div>
          {record?.shouldSendProduct === 1 && (
            <React.Fragment>
              <div className="influencer-deliverable-item-field-fullwidth">
                <TextField
                  disabled={isReadOnly()}
                  label="What products will they receive?"
                  size="small"
                  value={record.productProvided}
                  fullWidth
                  variant="outlined"
                  onChange={(e) => {
                    handleOnChange("productProvided", e.target.value);
                  }}
                />
              </div>
              <div className="influencer-deliverable-item-field-fullwidth"></div>
            </React.Fragment>
          )}
          {!isReadOnly() && (
            <div className="influencer-outreach-details-2columns-grid">
              <Button
                size="small"
                startIcon={<SaveIcon />}
                color="primary"
                variant="contained"
                disabled={!hasChanges || record?.contractStatus === "Invoiced"}
                className={`${
                  hasChanges ? "influencer-schedule-table-row-btn-wiggle" : ""
                }`}
                onClick={handeSaveChanges}
              >
                Save
              </Button>
              <Button
                size="small"
                startIcon={<DeleteIcon />}
                color="primary"
                variant="contained"
                onClick={() => {
                  record?.contractStatus !== "Invoiced"
                    ? setOpenDeleteItemDialog(true)
                    : setShowInvoicesModal(true);
                }}
              >
                Delete
              </Button>
            </div>
          )}
          {record?.contractStatus === "Signed" && (
            <div>
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<HighlightOffIcon />}
                onClick={(e) => setOpenConfirmRevokeContract(true)}
              >
                Revoke Current Contract
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OutreachForm;
