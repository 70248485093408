import React, { useState, useEffect } from "react";
import { withStyles, styled, makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { setSnackBarProps } from "../../../../actions/snackbar";
import {
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Slide,
  Grid,
  InputAdornment,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { contractorOrStaff } from "../../../../ProvisionUtils";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import ContractorPopUp from "../../ProjectOutputs/PopUps/ContractorPopUp";
import TalentContract from "../TalentContract/TalentContract";
import "../TalentContract/talentContractButtonPanelStyles.css";
import PrePostContractButton from "./PrePostContractButton";
import PrePostTalentSwitchPanel from "./PrePostTalentSwitchPanel";
import ScopeOfWorkPopUp from "./ScopeOfWorkPopUp";
import Snacky from "../../../Shared/Snacky";
import { ProviderTableCell } from "../ScheduleItemTable/ProviderTableCell";
import {
  RightClickMenu,
  RightClickAgentMenu,
} from "../ScheduleItemTable/CellMenus";
import PrePostNotes from "./PrePostNotes";
import { DeleteItemDialog, InvoicesDialog } from "./Dialogs";
import AucklandStandardContract from "../TalentContract/AucklandStandardContract";
import AddEquipmentAndTravel from "../../../Shared/Contractors/AddEquipmentAndTravel";
import TravelReimbursmentOnlyButton from "../ScheduleItemTable/PopUps/TravelReimbursmentOnlyButton";
import RolloverButton from "../ScheduleItemTable/RolloverButton";
import {
  checkIfDisabled,
  onBlurAllocatedRates,
} from "../../../../utils/ScheduleItemUtils";
import { useAuth } from "../../../../context/auth/AuthContext";
import CustomTableHead from "../../../Shared/CustomTableHead";
import MultiCurrencyInputField from "../../../CurrencyExchange/MultiCurrencyInputField";

const tableHeader = [
  {
    field: "date",
    name: "Budget",
    fieldType: "string",
    alignment: "left",
  },
  {
    field: "roleFk",
    name: "Role",
    fieldType: "string",
    alignment: "left",
  },
  {
    field: "personFk",
    name: "Who",
    fieldType: "string",
    alignment: "left",
  },
  {
    field: "allocatedRate",
    name: "Fee",
    fieldType: "number",
    alignment: "left",
  },
  {
    field: "startTime",
    name: "Due Date",
    fieldType: "date",
    alignment: "left",
  },
  {
    field: "personFk",
    name: "",
    fieldType: "string",
    alignment: "left",
  },
  {
    field: "personFk",
    name: "",
    fieldType: "string",
    alignment: "left",
  },
  {
    field: "personFk",
    name: "",
    fieldType: "string",
    alignment: "left",
  },
  {
    field: "personFk",
    name: "",
    fieldType: "string",
    alignment: "left",
  },
];

//----------------------------------------
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  margin: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  alignRight: {
    marginLeft: "auto",
    marginRight: 0,
  },
  margin: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  tableHeader: {
    fontSize: 13,
    fontWeight: "bold",
    paddingLeft: "10px",
    paddingRight: "2px",
  },
  removeMarginPadding: {
    padding: "0px 0px 0px 0px",
    margin: "0px 0px 0px 0px",
  },
  switchBase: {
    color: "primary",
    "&$checked": {
      color: "primary",
    },
    "&$checked + $track": {
      backgroundColor: "primary",
    },
  },
  checked: {
    color: "#3f51b5",
  },
  track: {
    color: "#3f51b5",
    backgroundColor: "#3f51b5",
  },
  thumb: {
    color: "#3f51b5",
  },
  disabled: {
    color: "#dbdbdb",
  },
}));

const StyledPaper = styled(Paper)({
  width: "100%",
});

const StyledButton = styled(Button)({
  color: "#000000",
  borderRadius: "20%",
});

const initialState = {
  mouseX: null,
  mouseY: null,
};

const PrePostTable = (props) => {
  const classes = useStyles();
  const { user } = useAuth();

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState({
    field: "date",
    name: "Date",
    fieldType: "date",
  });

  const [state, setState] = useState(initialState);
  const [openContactDetailsPopUp, setOpenContactDetailsPopUp] = useState(false);
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);
  const [currentProvider, setCurrentProvider] = useState(null);
  const [currentScheduleItemId, setCurrentScheduleItemId] = useState(null);
  const [currentRightClickedItem, setCurrentRightClickedItem] = useState(null);
  const [rightClickOpen, setRightClickOpen] = useState(false);
  const [showInvoicesModal, setShowInvoicesModal] = useState(false);
  const [talentModalState, setTalentModalState] = useState(false);
  const [activeScheduleItem, setActiveScheduleItem] = useState();
  const [currentRightClickedAgent, setCurrentRightClickedAgent] =
    useState(null);
  const [rightClickAgentOpen, setRightClickAgentOpen] = useState(false);

  const [aucklandStandardModalState, setAucklandStandardModalState] =
    useState(false);

  const [lineIndex, setlineIndex] = useState(false);

  const [openPrePostNotes, setOpenPrePostNotes] = useState(false);

  const handleOnRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClosePrePostNotes = () => {
    setOpenPrePostNotes(false);
  };
  const handleCloseContext = () => {
    setState(initialState);
    setRightClickOpen(false);
    setRightClickAgentOpen(false);
  };

  const handleCloseAgentContext = () => {
    setState(initialState);
    setRightClickAgentOpen(false);
    setRightClickOpen(false);
  };

  const handleContextClick = (event, rowObj) => {
    if (rightClickOpen == true) {
      event.preventDefault();
      setState(initialState);
      setCurrentRightClickedItem();
      setRightClickOpen(false);
    } else {
      event.preventDefault();
      setState({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
      setCurrentRightClickedItem(rowObj);
      setRightClickOpen(true);
    }
  };

  const handleAgentContextClick = (event, rowObj) => {
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    setCurrentRightClickedAgent(rowObj);
    setRightClickAgentOpen(true);
  };
  const handleDeleteItemDialogClose = () => {
    setOpenDeleteItemDialog(false);
    setActiveScheduleItem();
  };

  const onEditAllocatedRates = (value, scheduleItem) => {
    props.handleScheduleItemEdit(value, "allocatedRates", scheduleItem);
  };

  const onEditStartTime = (value, scheduleItem) => {
    props.handleScheduleItemEdit(
      format(value, "yyyy-MM-dd HH:mm:ss"),
      "startTime",
      scheduleItem
    );
  };

  const onEditContractStatus = (value, scheduleItem) => {
    props.handleScheduleItemEdit(value, "contractStatus", scheduleItem);
  };

  const handleTalentModal = () => {
    if (talentModalState) {
      setTalentModalState(false);
    } else {
      setTalentModalState(true);
    }
  };
  const handleCompleteTalentContract = (outputs, isAucklandStandard) => {
    if (isAucklandStandard) {
      props.handleCompleteAucklandContract(activeScheduleItem, outputs);
    } else {
      props.completeTalentContract(activeScheduleItem, outputs);
      setActiveScheduleItem();
      setTalentModalState(false);
    }
  };

  const confirmAucklandStandard = () => {
    props.confirmAucklandStandard(activeScheduleItem);
    setActiveScheduleItem();
    setTalentModalState(false);
  };

  const handleCompletePrePostNotes = (notes) => {
    props.completePrePostNotes(activeScheduleItem, notes);
    setActiveScheduleItem();
    setOpenPrePostNotes(false);
  };

  const handleAucklandStandardModal = () => {
    if (aucklandStandardModalState) {
      setAucklandStandardModalState(false);
    } else {
      setAucklandStandardModalState(true);
    }
  };

  

  return (
    <div>
      {aucklandStandardModalState && (
        <AucklandStandardContract
          scheduleItem={activeScheduleItem}
          modalState={aucklandStandardModalState}
          handleModal={handleAucklandStandardModal}
        />
      )}
      {talentModalState && activeScheduleItem && (
        <TalentContract
          modalState={talentModalState}
          handleModal={handleTalentModal}
          projectTag={props.projectTag}
          projectid={props.projectid}
          scheduleItem={activeScheduleItem}
          setActiveScheduleItem={setActiveScheduleItem}
          handleComplete={handleCompleteTalentContract}
          handleScheduleItemEdit={props.handleScheduleItemEdit}
          parent="prePostTable"
          confirmAucklandStandard={confirmAucklandStandard}
        />
      )}
      {openPrePostNotes && (
        <ScopeOfWorkPopUp
          modalState={openPrePostNotes}
          handleModal={handleClosePrePostNotes}
          scheduleItem={activeScheduleItem}
          setActiveScheduleItem={setActiveScheduleItem}
          handleComplete={handleCompletePrePostNotes}
        />
      )}
      {openContactDetailsPopUp && (
        <ContractorPopUp
          handleModal={() => {
            setOpenContactDetailsPopUp(false);
          }}
          modalState={openContactDetailsPopUp}
          personId={currentProvider.id}
          scheduleItemId={currentScheduleItemId}
          reloadPersonList={() => props.getContractors()}
          reloadScheduleItemsList={() => props.reloadScheduleItemsList()}
          type="EditScheduleItem"
          providerType={currentProvider.personCategory}
        />
      )}
      {openDeleteItemDialog && (
        <DeleteItemDialog
          handleDeleteItemDialogClose={handleDeleteItemDialogClose}
          setShowInvoicesModal={setShowInvoicesModal}
          deleteScheduleItem={props.deleteScheduleItem}
          scheduleItem={activeScheduleItem}
          setSnackBarProps={setSnackBarProps}
          openDeleteItemDialog={openDeleteItemDialog}
        />
      )}
      {showInvoicesModal && (
        <InvoicesDialog
          setShowInvoicesModal={setShowInvoicesModal}
          showInvoicesModal={showInvoicesModal}
          setActiveScheduleItem={setActiveScheduleItem}
        />
      )}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <table className="custom-table custom-table-border custom-table-hover ts-default-table">
          <CustomTableHead
            headers={tableHeader}
            orderBy={orderBy}
            order={order}
            onRequestSort={handleOnRequestSort}
          />

          <tbody>
            {props.scheduleItems.map((scheduleItem, index) => {
              console.log("scheduleItem " + index);
              console.log(scheduleItem);
              return (
                <Slide
                  key={scheduleItem.id}
                  direction={
                    index % 4 === 0
                      ? "up"
                      : index % 3 === 0
                      ? "right"
                      : index % 2 === 0
                      ? "down"
                      : "left"
                  }
                  in={true}
                  mountOnEnter
                  unmountOnExit
                >
                  <tr key={scheduleItem.id} hover role="checkbox">
                    <td>
                      <Typography variant="caption">
                        {scheduleItem.category}
                      </Typography>
                    </td>
                    <td>
                      <Typography
                        variant="caption"
                        style={{
                          width: "auto", //160%
                        }}
                      >
                        {scheduleItem.roleFkNavigation !== null
                          ? scheduleItem.roleFkNavigation.fieldRole
                          : null}
                      </Typography>
                    </td>
                    <td
                      onContextMenu={(event) => {
                        setCurrentScheduleItemId(scheduleItem.id);
                        handleContextClick(event, index, scheduleItem);
                      }}
                    >
                      <ProviderTableCell
                        scheduleItem={scheduleItem}
                        providerType={
                          props.providerType !== "Contractor"
                            ? "Talent"
                            : "Contractor"
                        }
                        parent={"PrePost"}
                        setCurrentProvider={setCurrentProvider}
                        setCurrentScheduleItemId={setCurrentScheduleItemId}
                        setOpenContactDetailsPopUp={setOpenContactDetailsPopUp}
                        handleAgentContextClick={handleAgentContextClick}
                      />
                      {rightClickOpen &&
                      currentScheduleItemId === scheduleItem.id ? (
                        <RightClickMenu
                          state={state}
                          scheduleItem={scheduleItem}
                          handleCloseContext={handleCloseContext}
                          setSnackBarProps={props.setSnackBarProps}
                        />
                      ) : (
                        ""
                      )}
                      {rightClickAgentOpen &&
                      currentScheduleItemId === scheduleItem.id ? (
                        <RightClickAgentMenu
                          state={state}
                          scheduleItem={scheduleItem}
                          handleCloseContext={handleCloseContext}
                          handleCloseAgentContext={handleCloseAgentContext}
                          setSnackBarProps={props.setSnackBarProps}
                        />
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {scheduleItem.talentContractType !== null &&
                        (scheduleItem.personFkNavigation?.currency === "NZD" ? (
                          <TextField
                            disabled={
                              (scheduleItem.contractStatus !== "Send" &&
                                scheduleItem.contractStatus !== "Disabled") ||
                              scheduleItem.category === "Unpaid"
                                ? true
                                : false
                            }
                            value={scheduleItem.allocatedRates}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            style={{
                              width: "auto",
                              marginLeft: "5px",
                              marginRight: "5px",
                            }}
                            onChange={(e) =>
                              onEditAllocatedRates(e.target.value, scheduleItem)
                            }
                            onBlur={(e) =>
                              onBlurAllocatedRates(
                                e.target.value,
                                scheduleItem,
                                user?.email
                              )
                            }
                          />
                        ) : (
                          <MultiCurrencyInputField
                            totalAmount={scheduleItem?.allocatedRates || 0}
                            nzdTotalAmount={
                              scheduleItem?.allocatedRatesInNzd || 0
                            }
                            size="small"
                            currency={
                              scheduleItem?.personFkNavigation?.currency
                            }
                            handleChange={(currency, amount, amountInNZD) => props.handleChangeAllocatedRate(currency, amount, amountInNZD, scheduleItem)}
                            isReadOnly={
                              (scheduleItem.contractStatus !== "Send" &&
                                scheduleItem.contractStatus !== "Disabled") ||
                              scheduleItem.category === "Unpaid"
                                ? true
                                : false
                            }
                            lockDropdown={true}
                          />
                        ))}
                    </td>
                    <td style={{ width: "auto" }}>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          className={classes.removeMarginPadding}
                          alignItems="center"
                        >
                          <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            disabled={
                              scheduleItem.contractStatus !== "Send"
                                ? true
                                : false
                            }
                            minDateMessage={null}
                            id={`start-time-picker-prepost-${index}`}
                            value={scheduleItem.startTime}
                            onChange={(date, value) => {
                              if (date instanceof Date && !isNaN(date)) {
                                onEditStartTime(date, scheduleItem);
                              }
                            }}
                            KeyboardButtonProps={{
                              style: {
                                ...{
                                  padding: "0px",
                                  margin: "0px",
                                },
                              },
                            }}
                            className={classes.margin}
                            style={{
                              minWidth: "120px",
                              maxWidth: "120px",
                            }}
                          />
                        </Grid>
                      </Grid>
                    </td>
                    <td
                      style={{ width: "auto" }}
                      onClick={
                        scheduleItem.equipmentHire != null &&
                        scheduleItem.category.toLowerCase().trim() !==
                          "unpaid" &&
                        (scheduleItem.contractStatus == "Send" ||
                          scheduleItem.contractStatus == "Disabled")
                          ? () => setlineIndex(index)
                          : ""
                      }
                    >
                      {scheduleItem.talentContractStatus == null && (
                        <AddEquipmentAndTravel
                          scheduleItem={scheduleItem}
                          projectId={props.projectid}
                          scheduleId={scheduleItem.scheduleFk}
                          lineIndex={lineIndex}
                          compIndex={index}
                          parent={"prepost"}
                          reloadPrepostScheduleItemsList={() =>
                            props.reloadScheduleItemsList()
                          }
                        />
                      )}
                      {scheduleItem.talentContractStatus === null &&
                        scheduleItem.category === "Unpaid" && (
                          <td>
                            <TravelReimbursmentOnlyButton
                              scheduleItem={scheduleItem}
                              projectId={props.projectid}
                              scheduleId={scheduleItem.scheduleFk}
                            />
                          </td>
                        )}
                    </td>
                    <td align="right">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          verticalAlign: "center",
                          justifyContent: "center",
                        }}
                      >
                        {scheduleItem.talentContractStatus != null && (
                          <PrePostTalentSwitchPanel
                            scheduleItem={scheduleItem}
                            handleTalentModal={handleTalentModal}
                            setActiveScheduleItem={setActiveScheduleItem}
                            switchContractType={props.switchContractType}
                            handleAucklandStandardModal={() =>
                              handleAucklandStandardModal()
                            }
                          />
                        )}
                        {scheduleItem.talentContractStatus == null && (
                          <PrePostNotes
                            scheduleItem={checkIfDisabled(scheduleItem)}
                            setActiveScheduleItem={setActiveScheduleItem}
                            setOpenPrePostNotes={setOpenPrePostNotes}
                          />
                        )}
                        {scheduleItem.contractStatus === "Invoiced" &&
                        scheduleItem.talentContractType === "Restricted" &&
                        scheduleItem.personFkNavigation !== null &&
                        scheduleItem.personFkNavigation.agentFk !== null ? (
                          <RolloverButton
                            providerType={
                              scheduleItem.talentContractStatus == null
                                ? "Contractor"
                                : "Talent"
                            }
                            si={scheduleItem}
                            color="primary"
                            projectId={props.projectid}
                            projectName={props.projectName}
                          />
                        ) : (
                          <PrePostContractButton
                            providerType={
                              scheduleItem.talentContractStatus == null
                                ? "Contractor"
                                : "Talent"
                            }
                            // si={scheduleItem}
                            project={props.project}
                            si={checkIfDisabled(scheduleItem)}
                            handleOnClick={
                              scheduleItem.contractStatus !== "Disabled" &&
                              onEditContractStatus
                            }
                          />
                        )}
                      </div>
                    </td>
                    <td>
                      <StyledButton>
                        <DeleteIcon
                          onClick={() => {
                            setActiveScheduleItem(scheduleItem);
                            scheduleItem.contractStatus !== "Invoiced"
                              ? setOpenDeleteItemDialog(true)
                              : setShowInvoicesModal(true);
                          }}
                          style={{ color: "rgba(0, 0, 0, 0.54)" }}
                        />
                      </StyledButton>
                    </td>
                    <td style={{ padding: 0 }}>
                      <Typography
                        style={{
                          float: "right",
                          padding: "3px 5px 3px 5px",
                          backgroundColor: "rgba(0, 0, 0, 0.54)",
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "0.8em",
                          borderBottomLeftRadius: 5,
                          borderTopLeftRadius: 5,
                          //marginBottom: "10px",
                        }}
                      >
                        {scheduleItem.talentContractStatus == null
                          ? "Contractor"
                          : "Talent"}
                      </Typography>
                    </td>
                  </tr>
                </Slide>
              );
            })}
          </tbody>
        </table>
        <Snacky
          snackprops={props.snackbar}
          setSnackBarProps={props.setSnackBarProps}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  snackbar: state.snackbar,
});
// https://react-redux.js.org/api/connect
export default connect(mapStateToProps, {
  setSnackBarProps,
})(PrePostTable);
