import React, { useEffect, useState } from "react";
import {
  Close,
  RadioButtonUnchecked,
  RadioButtonChecked,
  Add,
  Save,
} from "@material-ui/icons";
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  TextField,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Select,
  MenuItem,
  InputLabel,
  makeStyles,
  Fab,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";

import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "30px",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    minWidth: 180,
  },
}));

export const AddExistingContact = (props) => {
  const classes = useStyles();
  const [allPeople, setAllPeople] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [peopleFetched, setPeopleFetched] = useState(false);
  const [type, setType] = useState([0, 0, 0]);
  const [focusType, setFocusType] = useState("everything");
  const [contactFocus, setContactFocus] = useState({ fullName: "" });
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const StyledFab = withStyles((theme) => ({
    root: {
      background: "#00b2e2",
      color: "white",
      "&:hover": {
        background: "#008cb3",
      },
    },
  }))(Fab);

  useEffect(() => {
    if (!peopleFetched) {
      Axios.get("/People/GetPeople")
        .then((response) => {
          setPeopleFetched(true);
          setAllPeople(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  });

  const focusContact = (e, value) => {
    if (value !== null) {
      setContactFocus(value);
    }
  };

  const handleSubmit = () => {
    if (contacts !== null) {
      setShowLoader(true);
      contacts.forEach((contact) => {
        Axios.put(
          `/Locations/AddContactToLocation/${props.locationId}/${contact.id}`
        )
          .then((response) => {
            console.log(response);
            Axios.put(
              `Locations/PutContactTypes/${props.locationId}/${type[0]}/${type[1]}/${type[2]}`
            ).then((response) => {
              console.log(response.data);
              props.reloadContacts();
              setShowLoader(false);
            });
          })
          .catch((error) => {
            console.log(error);
            setShowLoader(false);
          });
      });
    }
  };

  const handleCheckBoxChange = (event, value, id) => {
    if (event.target.value === "manager" && value === false) {
      let typeArr = [];
      typeArr[0] = 0;
      typeArr[1] = type[1];
      typeArr[2] = type[2];
      setType(typeArr);
    } else if (event.target.value === "manager" && value === true) {
      let typeArr = [];
      typeArr[0] = id;
      typeArr[1] = type[1];
      typeArr[2] = type[2];
      setType(typeArr);
    } else if (event.target.value === "access" && value === false) {
      let typeArr = [];
      typeArr[1] = 0;
      typeArr[0] = type[0];
      typeArr[2] = type[2];
      setType(typeArr);
    } else if (event.target.value === "access" && value === true) {
      let typeArr = [];
      typeArr[1] = id;
      typeArr[0] = type[0];
      typeArr[2] = type[2];
      setType(typeArr);
    } else if (event.target.value === "accounts" && value === false) {
      let typeArr = [];
      typeArr[2] = 0;
      typeArr[0] = type[0];
      typeArr[1] = type[1];
      setType(typeArr);
    } else if (event.target.value === "accounts" && value === true) {
      let typeArr = [];
      typeArr[2] = id;
      typeArr[0] = type[0];
      typeArr[1] = type[1];
      setType(typeArr);
    }
    console.log(type);
  };

  const handleRemoveContact = (contact) => {
    setContacts(contacts.filter((item) => item !== contact));
  };

  const addContactToList = (contact) => {
    if (contact) {
      setContacts([...contacts, contact]);
    } else {
      if (contactFocus && contacts.indexOf(contactFocus) === -1) {
        setContacts([...contacts, contactFocus]);
        if (focusType === "everything") {
          let accessId = type[1];
          let accountsId = type[2];
          setType([contactFocus.id, accessId, accountsId]);
        } else if (focusType === "access") {
          let everythingId = type[0];
          let accountsId = type[2];
          setType([everythingId, contactFocus.id, accountsId]);
        } else if (focusType === "accounts") {
          let accessId = type[1];
          let everythingId = type[0];
          setType([everythingId, accessId, contactFocus.id]);
        }
      }
    }
    setContactFocus({ fullName: "" });
  };

  const toggleAddMultipleContacts = () => {
    addContactToList();
  };

  const setNewContact = (newContact) => {
    addContactToList(newContact);
  };

  const handleTypeChange = (event) => {
    if (event.target.value === "everything") {
      let accountsId = type[2];
      let accessId = type[1];
      setType([contactFocus.id, accessId, accountsId]);
      setFocusType(event.target.value);
    } else if (event.target.value === "access") {
      let accountsId = type[2];
      let everythingId = type[0];
      setType([everythingId, contactFocus.id, accountsId]);
      setFocusType(event.target.value);
    } else if (event.target.value === "accounts") {
      let accessId = type[1];
      let everythingId = type[0];
      setFocusType(event.target.value);
      setType([everythingId, accessId, contactFocus.id]);
    }
  };

  return (
    <Grid>
      <Grid>
        <div style={{ display: "flex" }}>
          <Autocomplete
            id="add-location-contact-autocomplete"
            options={allPeople}
            value={contactFocus}
            getOptionLabel={(option) => option.fullName}
            style={{ width: "60%", marginRight: "1%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Contact"
                variant="outlined"
                fullWidth
              />
            )}
            onChange={(e, value) => focusContact(e, value)}
          />

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
              Type
            </InputLabel>
            <Select
              labelId="select-label"
              id="demo-simple-select"
              value={focusType}
              onChange={handleTypeChange}
              labelWidth={labelWidth}
            >
              <MenuItem value={"everything"}>Everything</MenuItem>
              <MenuItem value={"access"}>Access Only</MenuItem>
              <MenuItem value={"accounts"}>Accounts</MenuItem>
            </Select>
          </FormControl>
          <div
            style={{
              display: "flex",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            <StyledFab
              size="small"
              id="add-multiple-contact-button"
              style={{ marginLeft: "15px", marginRight: "15px" }}
              aria-label="add multiple contacts"
              onClick={() => toggleAddMultipleContacts()}
            >
              <Add />
            </StyledFab>
          </div>
        </div>
        {contacts !== null
          ? contacts.map((contact, index) => (
              <Table key={contact.id} size="small" aria-label="contacts table">
                <TableBody>
                  <TableRow key={contact.id}>
                    <TableCell style={{ padding: "0" }}>
                      <Close
                        style={{ marginBottom: "-1%", cursor: "pointer" }}
                        onClick={() => {
                          handleRemoveContact(contact);
                        }}
                      />
                      {contact.fullName}
                    </TableCell>
                    <TableCell style={{ padding: "0", width: "39%" }}>
                      <div style={{ display: "flex" }}>
                        <FormControlLabel
                          label="Everything"
                          control={
                            <Checkbox
                              icon={<RadioButtonUnchecked />}
                              checkedIcon={<RadioButtonChecked />}
                              checked={type[0] === contact.id}
                              onChange={(event, value) =>
                                handleCheckBoxChange(event, value, contact.id)
                              }
                              value="manager"
                              inputProps={{
                                "aria-label": "primary checkbox",
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label="Access"
                          control={
                            <Checkbox
                              icon={<RadioButtonUnchecked />}
                              checkedIcon={<RadioButtonChecked />}
                              checked={type[1] === contact.id}
                              onChange={(event, value) =>
                                handleCheckBoxChange(event, value, contact.id)
                              }
                              value="access"
                              inputProps={{
                                "aria-label": "primary checkbox",
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label="Accounts"
                          control={
                            <Checkbox
                              icon={<RadioButtonUnchecked />}
                              checkedIcon={<RadioButtonChecked />}
                              checked={type[2] === contact.id}
                              onChange={(event, value) =>
                                handleCheckBoxChange(event, value, contact.id)
                              }
                              value="accounts"
                              inputProps={{
                                "aria-label": "Primary checkbox",
                              }}
                            />
                          }
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ))
          : ""}
      </Grid>
      {contacts.length > 0 ? (
        <StyledFab
          size="small"
          id="save-existing-contacts-button"
          style={{ marginLeft: "0px", marginRight: "15px", marginTop: "15px" }}
          aria-label="Save these contacts"
          onClick={() => handleSubmit()}
        >
          <Save />
        </StyledFab>
      ) : (
        ""
      )}
      <FormControl style={{ marginTop: "15px", marginRight: "10px" }}>
        <CircularProgress
          size={30}
          style={showLoader ? {} : { display: "none" }}
        />
      </FormControl>
    </Grid>
  );
};
