import React, { useEffect } from "react";
import "./updateInfluencerForm.scss";
import { useState } from "react";
import {
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
  InputAdornment,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  FormLabel,
  Switch,
  Fab,
  AppBar,
  Toolbar,
  FormGroup,
} from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { Search as SearchIcon } from "@material-ui/icons";
import {
  getCurrencies,
  isEmpty,
  validateEmail,
} from "../../utils/GeneralUtils";
import axios from "axios";
import InfluencerBasicCard from "./InfluencerBasicCard";
import { Autocomplete } from "@material-ui/lab";
import AddAgentPopUp from "../Project/Schedule/NewContact/AddAgentPopUp";
import CurrencyDropdownMenu from "../CurrencyExchange/CurrencyDropdownMenu";
import { useDataContext } from "../../context/data/DataContext";

const initialInfluencerState = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  phoneNumber: "",
  currency: "NZD",
  socialMediaProfile: [],
};

const initialSocialMediaProfile = {
  id: 0,
  application: "",
  avatar: "",
  profileUsername: "",
  profileDescription: "",
  followers: null,
  posts: null,
  videos: null,
  hearts: null,
  shares: null,
};

const initialErrorState = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  agent: "",
};
const UpdateInfluencerForm = ({
  handleCancel,
  handleSubmit,
  selectedInfluencer,
  insideEditModal = false,
  isEditing = false,
  setSocialMediaProfiles,
  setShouldMoveDialog,
  personCategory,
  isSaving,
}) => {
  const { industries, ageCategories } = useDataContext();
  const [influencer, setInfluencer] = useState({
    ...initialInfluencerState,
    personCategory: personCategory,
  });
  const [errorForm, setErrorForm] = useState(initialErrorState);
  const [instagramDetails, setInstagramDetails] = useState(null);
  const [tiktokDetails, setTikTokDetails] = useState(null);
  const [searchTermInstagram, setSearchTermInstagram] = useState(null);
  const [instagramLoading, setInstagramLoading] = useState(false);
  const [searchTermTikTok, setSearchTermTikTok] = useState(null);
  const [tiktokLoading, setTiktokLoading] = useState(false);
  const [isManagedByAgent, setIsManagedByAgent] = useState(false);
  const [personSubCatogory, setPersonSubCatogory] = useState(null);
  const [agentList, setAgentList] = useState([]);
  const [agent, setAgent] = useState(null);
  const [showAddAgentContact, setShowAddAgentContact] = useState(false);

  useEffect(() => {
    if (selectedInfluencer) {
      if (selectedInfluencer.socialMediaProfile) {
        var instagramProfile = selectedInfluencer.socialMediaProfile.find(
          (s) => s.application === "INSTAGRAM"
        );
        var tiktokProfile = selectedInfluencer.socialMediaProfile.find(
          (s) => s.application === "TIKTOK"
        );

        if (instagramProfile) {
          setInstagramDetails(instagramProfile);
          setSearchTermInstagram(instagramProfile.influencerId);
        }
        if (tiktokProfile) {
          setTikTokDetails(tiktokProfile);
          setSearchTermTikTok(tiktokProfile.influencerId);
        }
      }
      setInfluencer(selectedInfluencer);
    }
    fetchAgents();
  }, []);

  const fetchAgents = () => {
    axios
      .get(`Agents/GetAgents`, { params: { organisationId: 2 } })
      .then((res) => {
        setAgentList(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChangeAgent = (field, value) => {
    setAgent({ ...agent, [field]: value });
  };
  const handleChangeInput = (field, value) => {
    setInfluencer({ ...influencer, [field]: value });
  };

  const validateForm = () => {
    let isValidForm = true;
    const tempError = { ...initialErrorState };

    if (isEmpty(influencer.firstName)) {
      tempError.firstName = "Required field";
      isValidForm = false;
    }

    if (isEmpty(influencer.lastName)) {
      tempError.lastName = "Required field";
      isValidForm = false;
    }

    if (!isManagedByAgent && !validateEmail(influencer.emailAddress)) {
      tempError.emailAddress = "Enter a valid email";
      isValidForm = false;
    }

    if (isManagedByAgent && !agent) {
      tempError.agent = "Please enter details for the agent";
      isValidForm = false;
    }

    if (!isValidForm) {
      setErrorForm(tempError);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }

    return isValidForm;
  };

  const handleSave = () => {
    if (validateForm()) {
      handleSubmit(influencer);
    }
  };

  const handleInstagramSearch = () => {
    setInstagramLoading(true);
    axios
      .get(`Influencer/GetInstagramBasic/${searchTermInstagram}`)
      .then((res) => {
        if (res.data.error) {
          setInstagramDetails(null);
          let profiles = [...influencer.socialMediaProfile];
          setInfluencer({
            ...influencer,
            socialMediaProfile: profiles.filter(
              (p) => p.application !== "INSTAGRAM"
            ),
          });
          if (isEditing && insideEditModal) {
            setSocialMediaProfiles(
              profiles.filter((p) => p.application !== "INSTAGRAM")
            );
          }
        } else {
          var newProfile = {
            ...initialSocialMediaProfile,
            followers: res.data.business_discovery.followers_count,
            posts: res.data.business_discovery.media_count,
            profileUsername: res.data.business_discovery.name,
            avatar: res.data.business_discovery.profile_picture_url,
            profileDescription: res.data.business_discovery.biography,
            application: "INSTAGRAM",
            influencerId: res.data.business_discovery.username,
          };
          setInstagramDetails(newProfile);
          let profiles = [...influencer.socialMediaProfile];
          profiles = profiles.filter((p) => p.application !== "INSTAGRAM");
          profiles.push(newProfile);
          setInfluencer({ ...influencer, socialMediaProfile: profiles });
          if (isEditing && insideEditModal) {
            setSocialMediaProfiles(profiles);
          }
        }
      }).catch((e) => {
        console.log(e);
        setInstagramDetails(null);
        let profiles = [...influencer.socialMediaProfile];
        profiles = profiles.filter((p) => p.application !== "INSTAGRAM");
        setInfluencer({ ...influencer, socialMediaProfile: profiles });
        if (isEditing && insideEditModal) {
          setSocialMediaProfiles(profiles);
        }
      })
      .finally(() => setInstagramLoading(false));
  };

  const handleTikTokSearch = () => {
    setTiktokLoading(true);
    axios
      .get(`Influencer/GetTikTokBasic/${searchTermTikTok}`)
      .then((res) => {
        if (res.data.error) {
          setTikTokDetails(null);
          let profiles = [...influencer.socialMediaProfile];
          setInfluencer({
            ...influencer,
            socialMediaProfile: profiles.filter(
              (p) => p.application !== "TIKTOK"
            ),
          });
          if (isEditing && insideEditModal) {
            setSocialMediaProfiles(
              profiles.filter((p) => p.application !== "TIKTOK")
            );
          }
        } else {
          var newProfile = {
            ...initialSocialMediaProfile,
            influencerId: res.data.userInfo.user.uniqueId,
            application: "TIKTOK",
            avatar: res.data.userInfo.user.avatarMedium,
            profileUsername: res.data.userInfo.user.nickname,
            profileDescription: res.data.userInfo.user.signature,
            followers: res.data.userInfo.stats.followerCount,
            posts: 0,
            hearts: res.data.userInfo.stats.heartCount,
            videos: res.data.userInfo.stats.videoCount,
          };
          setTikTokDetails(newProfile);
          let profiles = [...influencer.socialMediaProfile];
          profiles = profiles.filter((p) => p.application !== "TIKTOK");
          profiles.push(newProfile);
          setInfluencer({ ...influencer, socialMediaProfile: profiles });
          if (isEditing && insideEditModal) {
            setSocialMediaProfiles(profiles);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTiktokLoading(false));
  };

  const handleSaveAgent = (agentDetails) => {
    axios
      .post(`Agents/PostAgent`, agentDetails)
      .then((res) => {
        setAgent(res.data);
        handleChangeInput("agentFkNavigation", res.data);
        fetchAgents();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      {showAddAgentContact && !insideEditModal && (
        <AddAgentPopUp
          modalState={showAddAgentContact}
          handleModal={() => {
            setShowAddAgentContact(false);
            setShouldMoveDialog(false);
          }}
          organisationId={2}
          handleSave={handleSaveAgent}
          parent={"AddInfuencerContact"}
        />
      )}
      <div className="update-influencer-form-container">
        {!insideEditModal && (
          <div className="update-influencer-form-wrapper">
            <TextField
              fullWidth
              size="small"
              label="First Name*"
              onChange={(e) => handleChangeInput("firstName", e.target.value)}
              value={influencer?.firstName}
              variant="outlined"
              error={errorForm.firstName ? true : false}
              helperText={errorForm.firstName ? errorForm.firstName : ""}
            />
            <TextField
              fullWidth
              size="small"
              label="Last Name*"
              onChange={(e) => handleChangeInput("lastName", e.target.value)}
              value={influencer?.lastName}
              variant="outlined"
              error={errorForm.lastName ? true : false}
              helperText={errorForm.lastName ? errorForm.lastName : ""}
            />

            {!isEmpty(influencer?.firstName) &&
              !isEmpty(influencer.lastName) && (
                <>
                  <Autocomplete
                    options={industries}
                    getOptionLabel={(option) => option.name}
                    value={
                      industries.find(
                        (i) => i.id == influencer?.mainIndustryId
                      ) ?? ""
                    }
                    onChange={(e, newValue) => {
                      handleChangeInput("mainIndustryId", newValue?.id || null);
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Main Category"
                        variant="outlined"
                      />
                    )}
                  />
                  <Autocomplete
                    options={ageCategories}
                    getOptionLabel={(option) => option.generation}
                    value={ageCategories.find(
                      (i) => i.id == influencer?.ageCategoryId
                    )}
                    onChange={(e, newValue) => {
                      handleChangeInput("ageCategoryId", newValue?.id || null);
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Age Category"
                        variant="outlined"
                      />
                    )}
                  />
                </>
              )}

            {!isEmpty(influencer.firstName) &&
              !isEmpty(influencer.lastName) && (
                <div className="update-influencer-form-currency-wrapper">
                  <div className="update-influencer-form-currency-container">
                    <CurrencyDropdownMenu
                      size={"small"}
                      originalCurrency={influencer.currency}
                      handleChangeCurrency={(currencyObj) =>
                        handleChangeInput("currency", currencyObj.value)
                      }
                      selectedCurrencies={getCurrencies().filter(
                        (c) => c.value === "NZD" || c.value === "AUD"
                      )}
                    />
                  </div>

                  <div className="update-influencer-switch-wrapper">
                    <div className="update-influencer-form-switch-container">
                      <FormLabel
                        component="legend"
                        style={{
                          color: "#2d2d2d",
                        }}
                      >
                        <Typography variant="caption">
                          {`Is also ${personCategory === "Influencer"
                            ? "a Content Creator"
                            : "an Influencer"
                            }?`}
                        </Typography>
                      </FormLabel>
                      <div>
                        <Typography variant="caption">No</Typography>
                      </div>
                      <FormGroup>
                        <FormControlLabel
                          label={<Typography variant="caption">Yes</Typography>}
                          control={
                            <Switch
                              color="primary"
                              checked={influencer?.personSubCategory}
                              name="subCategory"
                              onChange={(e) => {
                                handleChangeInput(
                                  "personSubCategory",
                                  e.target.checked
                                    ? personCategory === "Influencer"
                                      ? "Content Creator"
                                      : "Influencer"
                                    : null
                                );
                              }}
                              size="small"
                              className={"blueSwitch"}
                            />
                          }
                        />
                      </FormGroup>
                    </div>
                    <div className="update-influencer-form-switch-container">
                      <FormLabel
                        component="legend"
                        style={{
                          color: "#2d2d2d",
                        }}
                      >
                        <Typography variant="caption">Has Agent: </Typography>
                      </FormLabel>
                      <div>
                        <Typography variant="caption">No</Typography>
                      </div>
                      <FormGroup>
                        <FormControlLabel
                          label={<Typography variant="caption">Yes</Typography>}
                          control={
                            <Switch
                              color="primary"
                              checked={isManagedByAgent}
                              name="bcOrIc"
                              onChange={(e) => {
                                setIsManagedByAgent(!isManagedByAgent);
                                if (!isManagedByAgent) {
                                  setAgent(null);
                                }
                              }}
                              size="small"
                              className={"blueSwitch"}
                            />
                          }
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
              )}

            {isManagedByAgent && (
              <>
                <div className="update-influencer-form-select-agent-container">
                  <Autocomplete
                    label="Agent"
                    clearOnEscape
                    options={agentList}
                    getOptionLabel={(option) =>
                      option.companyFkNavigation !== null
                        ? `${option.firstName} ${option.lastName} - ${option.companyFkNavigation.name}`
                        : `${option.firstName} ${option.lastName} - No Company`
                    }
                    value={agent}
                    style={{
                      width: "auto",
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select an Agent *"
                        variant="outlined"
                        size="small"
                        fullWidth
                        error={errorForm.agent ? true : false}
                        helperText={errorForm.agent ? errorForm.agent : ""}
                      />
                    )}
                    onChange={(e, newValue) => {
                      setAgent(newValue);
                      handleChangeInput("agentFkNavigation", newValue);
                    }}
                  />
                  <Fab
                    size="small"
                    className="update-influencer-form-fab"
                    onClick={() => {
                      setShowAddAgentContact(true);
                      setShouldMoveDialog(true);
                    }}
                  >
                    <PersonAddIcon />
                  </Fab>
                </div>
              </>
            )}

            {!isManagedByAgent &&
              !isEmpty(influencer?.firstName) &&
              !isEmpty(influencer.lastName) && (
                <>
                  <TextField
                    fullWidth
                    size="small"
                    label={`${isManagedByAgent
                      ? "Influencer Email Address (Optional)"
                      : "Email Address"
                      }`}
                    onChange={(e) =>
                      handleChangeInput("emailAddress", e.target.value)
                    }
                    value={influencer?.emailAddress}
                    variant="outlined"
                    error={errorForm.emailAddress ? true : false}
                    helperText={errorForm.emailAddress ? errorForm.email : ""}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    label={`${isManagedByAgent
                      ? "Influencer Phone Number (Optional)"
                      : "Phone Number"
                      }`}
                    onChange={(e) =>
                      handleChangeInput("phoneNumber", e.target.value)
                    }
                    value={influencer?.phoneNumber}
                    variant="outlined"
                  />
                </>
              )}
          </div>
        )}

        {(insideEditModal ||
          (!isEmpty(influencer?.firstName) &&
            !isEmpty(influencer.lastName) &&
            ((isManagedByAgent && agent) ||
              (!isManagedByAgent && !isEmpty(influencer.emailAddress))))) && (
            <div
              className={`${insideEditModal ? "update-influencer-form-double-input" : ""
                }`}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                  marginBottom: 20,
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <TextField
                    disabled={insideEditModal && !isEditing}
                    label="Find Instagram"
                    variant="outlined"
                    size="small"
                    onChange={(e) => {
                      try {
                        var filteredValue = e.target.value.replace("@", "");
                        setSearchTermInstagram(filteredValue);
                      } catch (e) {
                        setSearchTermInstagram(e.target.value);
                      }
                    }}
                    value={searchTermInstagram}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleInstagramSearch();
                      }
                    }}
                    placeholder="Type Id and press 'Enter'"
                    style={{ width: "90%" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">@</InputAdornment>
                      ),
                    }}
                  />
                  <div
                    style={{
                      width: "10%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {instagramLoading ? (
                      <CircularProgress />
                    ) : (
                      <IconButton
                        disabled={insideEditModal && !isEditing}
                        onClick={() => handleInstagramSearch()}
                      >
                        <SearchIcon />
                      </IconButton>
                    )}
                  </div>
                </div>
                {instagramDetails && (
                  <InfluencerBasicCard
                    type="instagram"
                    influencer={instagramDetails}
                  />
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                  marginBottom: 20,
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <TextField
                    disabled={insideEditModal && !isEditing}
                    label="Find TikTok"
                    variant="outlined"
                    size="small"
                    onChange={(e) => {
                      try {
                        var filteredValue = e.target.value.replace("@", "");
                        setSearchTermTikTok(filteredValue);
                      } catch (e) {
                        setSearchTermTikTok(e.target.value);
                      }
                    }}
                    value={searchTermTikTok}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleTikTokSearch();
                      }
                    }}
                    placeholder="Type Id and press 'Enter'"
                    style={{ width: "90%" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">@</InputAdornment>
                      ),
                    }}
                  />
                  <div
                    style={{
                      width: "10%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {tiktokLoading ? (
                      <CircularProgress />
                    ) : (
                      <IconButton
                        disabled={insideEditModal && !isEditing}
                        onClick={() => handleTikTokSearch()}
                      >
                        <SearchIcon />
                      </IconButton>
                    )}
                  </div>
                </div>
                {tiktokDetails && (
                  <InfluencerBasicCard type="tiktok" influencer={tiktokDetails} />
                )}
              </div>
            </div>
          )}
        {!insideEditModal && (
          <div className="update-influencer-form-actions">
            {isSaving ? (
              <CircularProgress />
            ) : (
              <>
                <Button className="default-button-grey" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  className="default-button"
                  onClick={handleSave}
                >
                  {"Save"}
                </Button>
              </>
            )}
          </div>
        )}
        {insideEditModal && (
          <div className="update-influencer-form-actions">
            <Button
              color="primary"
              variant="contained"
              className="default-button"
              onClick={handleSubmit}
            >
              {"Save"}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdateInfluencerForm;
