import React from "react";
import { Typography, Switch, FormLabel, Grid } from "@material-ui/core";

export const AgentSwitch = (props) => {
  let { requiredInfo, handleRequiredInfoChange, category } = props;
  return (
    <Grid
      component="label"
      container
      alignItems="center"
      spacing={1}
      style={{
        display: category !== "Contractor" && "none",
      }}
    >
      <Grid
        item
        xs={6}
        md={6}
        lg={6}
        style={{ marginTop: "auto", marginBottom: "auto" }}
      >
        <FormLabel
          component="legend"
          style={{
            color: "#2d2d2d",
            fontSize: "0.85em",
            marginLeft: 12,
          }}
        >
          <Typography variant="caption">Has Agent: </Typography>
        </FormLabel>
      </Grid>
      <Grid
        item
        style={
          requiredInfo.hasAgent === 1
            ? { color: "grey" }
            : { borderBottom: "solid", borderColor: "#3f51b5" }
        }
      >
        <Typography variant="caption">No</Typography>
      </Grid>
      <Grid item>
        <Switch
          size="small"
          color="primary"
          checked={requiredInfo.hasAgent === 1 ? true : false}
          name="bcOrIc"
          onChange={handleRequiredInfoChange("hasAgent")}
          className={"blueSwitch"}
        />
      </Grid>
      <Grid
        item
        style={
          requiredInfo.hasAgent === 1
            ? { borderBottom: "solid", borderColor: "#3f51b5" }
            : { color: "grey" }
        }
      >
        <Typography variant="caption">Yes</Typography>
      </Grid>
    </Grid>
  );
};
