import React, { useEffect, useState } from "react";
import "./influencerBasicDetails.scss";
import { Avatar, Chip, Typography } from "@material-ui/core";
import { RightClickAvatarMenu } from "./RightClickAvatarMenu";
import InstagramIcon from "../../icons/InstagramIcon";
import TikTokIcon from "../../icons/TikTokIcon";
import { Skeleton } from "@material-ui/lab";
import AgentTooltipIcon from "../Shared/Tooltip/AgentTooltipIcon";
import BrandBadge from "../Shared/Badge/BrandBadge";
import PersonRatingsIcon from "../Shared/Person/PersonRatingsIcon";
import IndustryBadge from "../Shared/Badge/IndustryBadge";

const initialState = {
  mouseX: null,
  mouseY: null,
};

const InfluencerBasicDetails = ({
  influencer,
  handleEditInfluencer,
  handleSaveInfluencer,
  displayIndustry = true
}) => {
  const [avatar, setAvatar] = useState("https://source.boringavatars.com/beam");
  const [state, setState] = useState(initialState);
  const [rightClickOpen, setRightClickOpen] = useState(false);
  const [isLoadingAvatar, setIsLoadingAvatar] = useState(true);

  useEffect(() => {
    if (influencer.socialMediaProfile.length > 0) {
      var av = influencer.socialMediaProfile[0].avatar;
      if (influencer.primarySocial == "INSTAGRAM") {
        var foundProfile = influencer.socialMediaProfile.find(
          (s) => s.application === "INSTAGRAM"
        );
        if (foundProfile != null) {
          av = foundProfile.avatar;
        }
      } else if (influencer.primarySocial == "TIKTOK") {
        var foundProfile = influencer.socialMediaProfile.find(
          (s) => s.application === "TIKTOK"
        );
        if (foundProfile != null) {
          av = foundProfile.avatar;
        }
      }
      setAvatar(av);
    } else {
      setAvatar("https://source.boringavatars.com/beam");
    }
    setTimeout(() => {
      setIsLoadingAvatar(false);
    }, 2000);
  }, [influencer]);

  const handleSelectInfluencer = () => {
    handleEditInfluencer(influencer);
  };

  const handleCloseContext = () => {
    setState(initialState);
    setRightClickOpen(false);
  };

  const handleContextClick = (event) => {
    if (rightClickOpen == true) {
      event.preventDefault();
      setState(initialState);
      setRightClickOpen(false);
    } else {
      event.preventDefault();
      setState({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
      setRightClickOpen(true);
    }
  };

  const preventBubbling = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="influencer-basic-details-name-column">
      {rightClickOpen && (
        <RightClickAvatarMenu
          state={state}
          handleCloseContext={handleCloseContext}
          influencer={influencer}
          handleSaveInfluencer={handleSaveInfluencer}
        />
      )}
      <div
        className="influencer-basic-details-avatar"
        onContextMenu={(event) => handleContextClick(event)}
      >
        {isLoadingAvatar ? (
          <Skeleton variant="circle" width={70} height={70} />
        ) : (
          <Avatar
            className="influencer-basic-details-avatar-image"
            alt="avatar"
            src={avatar}
          />
        )}
      </div>
      <div className="influencer-basic-details-wrapper">
        <>
          {influencer.organisationFk === 5 && (
            <BrandBadge
              organisationId={5}
              organisationName={null}
              size="small"
            />
          )}
          <div className="influencer-basic-details-flex-space-between">
            <div>
              <span
                className="influencer-basic-details-flex-container pointer clickable"
                onClick={handleSelectInfluencer}
              >
                <Typography variant="subtitle2" className="roboto-regular">
                  {influencer.firstName + " " + influencer.lastName}
                </Typography>
                {!!influencer.agentFkNavigation && (
                  <div className="influencer-basic-details-grid-container">
                    <AgentTooltipIcon
                      agent={influencer.agentFkNavigation}
                      size="small"
                    />
                  </div>
                )}
              </span>
            </div>

            {(influencer.mainIndustryId && displayIndustry )&& (
              <IndustryBadge industryId={influencer.mainIndustryId} />
            )}
          </div>

          <div className="influencer-basic-details-grid-container">
            <Typography variant="caption">{influencer.emailAddress}</Typography>
            <Typography variant="caption">{influencer.phoneNumber}</Typography>
          </div>

          <div className="influencer-basic-details-profiles-container">
            {influencer.socialMediaProfile.length > 0 &&
              influencer.socialMediaProfile.map((profile, index) => {
                return profile.application === "INSTAGRAM" ? (
                  <a
                    href={`https://www.instagram.com/${profile.influencerId}`}
                    target="_blank"
                    onClick={preventBubbling}
                  >
                    <InstagramIcon />
                  </a>
                ) : profile.application === "TIKTOK" ? (
                  <a
                    href={`https://www.tiktok.com/@${profile.influencerId}`}
                    target="_blank"
                    onClick={preventBubbling}
                  >
                    <TikTokIcon />
                  </a>
                ) : (
                  <></>
                );
              })}
          </div>
        </>
        <div>
          <PersonRatingsIcon
            ratings={influencer.personRatings}
            styles={{ fontSize: "0.65rem" }}
          />
        </div>
      </div>
    </div>
  );
};

export default InfluencerBasicDetails;
