import { getApps, initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
}
const app = initializeApp(firebaseConfig)

if (!getApps.length) {
  if (typeof window !== "undefined") {

  }
}
const auth = getAuth(app)
export { auth }