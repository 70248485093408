import React, { useState, useEffect } from "react";
import {
    Modal,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Card,
    Grid,
    TextField,
    Button,
    InputLabel,
    FormControl,
    Select,
    setUser,
    MenuItem,
    setRef,
    FormControlLabel,
    Checkbox
} from "@material-ui/core";
import {
    Close as CloseIcon,
    OpenInBrowser as OpenInBrowserIcon,
    Edit as EditIcon,
    Save as SaveIcon
} from "@material-ui/icons";
import {
    MuiPickersUtilsProvider,
    DatePicker
} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import { getValueOrEmptyString, numberWithThousandsSeperator } from "../../../utils/utils";
import MediaAdsButton from "./MediaAdsButton";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
    },
    paper: {
        backgroundColor: "#fefefe",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
        height: "80vH",
        maxHeight: "80vH",
        width: "80vW",
        overflowY: "auto",
    }
}));

const EditMediaAd = (props) => {
    const classes = useStyles();

    const [mediaSuppliers, setMediaSuppliers] = useState([]);
    const [suppliersLoaded, setSuppliersLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [docType, setDocType] = useState(props.docType);

    const [editMode, setEditMode] = useState(false);
    const [mediaAd, setMediaAd] = useState(null);
    const [client, setClient] = useState(null);
    const [platform, setPlatform] = useState(null);
    const [transactionId, setTransactionId] = useState(null);
    const [transactionDate, setTransactionDate] = useState(null);
    const [amount, setAmount] = useState(null);
    const [type, setType] = useState(null);
    const [gstRateCode, setGstRateCode] = useState(null);
    const [gstCheckBox, setGstCheckbox] = useState(false);

    const imageLoaded = () => {
        setLoading(false);
    };

    const handleChange = (event, text) => {
        switch (text) {
            case "Client":
                setClient(event.target.value);
                break;
            case "Platform":
                setPlatform(event.target.value);
                break;
            case "Reference":
                setTransactionId(event.target.value);
                break;
            case "Transaction Date":
                setTransactionDate(event.target.value);
                break;
            case "Amount NZD":
                setAmount(event.target.value);
                break;
            case "Type":
                setType(event.target.value);
                break;
            default:
                break;
        }
    };

    const handleSave = () => {
        setEditMode(false);
        var newMediaAd = mediaAd;
        newMediaAd["client"] = client;
        newMediaAd["platform"] = platform;
        newMediaAd["transactionId"] = transactionId;
        newMediaAd["transactionDate"] = transactionDate;
        newMediaAd["amount"] = amount;
        newMediaAd["type"] = type;
        newMediaAd["gstRateCode"] = gstRateCode;
        props.updateMultipleValuesMediaAd(newMediaAd);
        props.setRowUpdating(true);
        // setRefreshingData(true);
    }

    const getTextField = (text, value, type) => (
        <div className="textfield-padding">
            <TextField
                id={text}
                disabled={!editMode}
                variant="outlined"
                label={text}
                value={
                    text === "Transaction Date" ?
                        format(value, "dd/MM/yyyy") :
                        value.toString()
                }
                onChange={(event) => handleChange(event, text)}
                fullWidth
                error={value === null || value.toString().trim() === ""}
                type={type}
            />
        </div>
    );

    const getDatePicker = (text, value) => {
        return (
            <div className="textfield-padding">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        label={text}
                        value={value}
                        format="dd/MM/yyyy"
                        onChange={setTransactionDate}
                        fullWidth
                    />
                </MuiPickersUtilsProvider>
            </div>
        )
    }

    const getGstRateCodeDropdown = () => {
        return (
            <div className="textfield-padding">
                <FormControl fullWidth>
                    <InputLabel>Gst Rate Code</InputLabel>
                    <Select
                        id={`gst-rate-code-dropdown`}
                        disabled={!editMode}
                        value={gstRateCode}
                        onChange={event => setGstRateCode(event.target.value)}
                        inputProps={{
                            name: 'Gst Rate Code',
                            id: 'gst-rate-code-popup',
                        }}
                    >
                        <MenuItem key={"INPUT2"} value={"INPUT2"}>{"INPUT2"}</MenuItem>
                        <MenuItem key={"OUTPUT2"} value={"OUTPUT2"}>{"Inc. GST"}</MenuItem>
                        <MenuItem key={"NONE"} value={"NONE"}>{"No Gst"}</MenuItem>
                    </Select>
                </FormControl>
            </div>
        )
    }

    const getGstRateCodeCheckbox = () => {
        return (
            <div className="textfield-padding">
                <FormControlLabel
                    disabled={!editMode}
                    control={
                        <Checkbox name="gst-checkbox" checked={gstCheckBox}
                            onChange={(e) => {
                                setGstCheckbox(true)
                                setGstRateCode("OUTPUT2")
                            }} />
                    }
                    label="GST Inc"
                />
                <FormControlLabel
                    disabled={!editMode}
                    control={
                        <Checkbox name="no-gst-checkbox" checked={!gstCheckBox}
                            onChange={(e) => {
                                setGstCheckbox(false)
                                setGstRateCode("NONE")
                            }} />
                    }
                    label="No GST"
                />
            </div>
        )
    }

    const getPlatformDropdown = () => {
        return (
            <div className="textfield-padding">
                <FormControl fullWidth>
                    <InputLabel>Platform</InputLabel>
                    <Select
                        id={`platform-dropdown`}
                        disabled={!editMode}
                        value={platform}
                        onChange={event => setPlatform(event.target.value)}
                        inputProps={{
                            name: 'Platform',
                            id: 'platform-popup',
                        }}
                    >
                        {
                            mediaSuppliers.map(supplier =>
                                <MenuItem key={`media-supplier-item-${supplier.id}`} value={supplier.xeroName}>{supplier.xeroName}</MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            </div>
        )
    }

    useEffect(() => {
        if (!suppliersLoaded) {
            setSuppliersLoaded(true);
            Axios.get(`MediaSuppliers/GetMediaSuppliers`)
                .then(res => {
                    setMediaSuppliers(res.data);
                })
                .catch(e => {
                    setSuppliersLoaded(false)
                })
        }
    }, [suppliersLoaded])

    useEffect(() => {
        setMediaAd(props.selectedAd)
        setClient(props.selectedAd.client);
        setPlatform(props.selectedAd.platform);
        setTransactionId(props.selectedAd.transactionId);
        setTransactionDate(props.selectedAd.transactionDate);
        setAmount(props.selectedAd.amount);
        setType(props.selectedAd.type);
        setGstRateCode(props.selectedAd.gstRateCode);
        setGstCheckbox(props.selectedAd.gstRateCode == "NONE" ? false : props.selectedAd.gstRateCode == "OUTPUT2" ? true : false);
    }, [])

    return (
        <Modal
            open={props.modalState}
            onClose={() => props.handleModal()}
            className={classes.modal}
        >
            <div>
                <AppBar position="static" style={{ background: props.headerColor }}>
                    <Toolbar variant="dense" style={{ paddingRight: 0 }}>
                        <Grid container>
                            <Grid item xs={3} md={3} lg={3}></Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <Typography
                                    variant="h6"
                                    align="center"
                                    style={{ flexGrow: 1, display: "flex", alignItems: "center", justifyContent: "center", height: "100%", margin: "0px 0px 5px 0px" }}
                                >
                                    {props.headerTitle}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}>
                                <span style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", height: "100%" }}>
                                    {
                                        props.type === "Edit" && (editMode ?
                                            <IconButton
                                                variant="contained"
                                                style={{ color: "white" }}
                                                onClick={() => handleSave()}
                                            >
                                                <SaveIcon />
                                            </IconButton>
                                            :
                                            <IconButton
                                                variant="contained"
                                                style={{ color: "white", display: `${props.selectedAd.status !== "Pending" ? "none" : ""}` }}
                                                onClick={() => setEditMode(true)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        )
                                    }
                                    <IconButton
                                        onClick={() => props.handleModal()}
                                        color="inherit"
                                        style={{ color: "white" }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </span>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <div className={classes.paper}>
                    <div
                        style={{
                            display: "flex",
                            height: "100%"
                        }}
                    >
                        <Card
                            elevation={9}
                            style={{
                                width: "100%",
                                marginRight: 5,
                                display: "flex",
                                maxHeight: "80vH",
                            }}
                        >
                            <div style={{ display: !loading && "none" }}>
                                <h1 className="loading-dots" style={{ display: "flex", justifyContent: "center" }}><span>.</span><span>.</span><span>.</span></h1>
                            </div>
                            {docType === "Image" && (
                                <Card
                                    elevation={9}
                                    style={{
                                        display: loading && "none",
                                        width: "100%",
                                        display: "flex",
                                        maxHeight: "80vH"
                                    }}
                                >
                                    <div>
                                        <img
                                            src={`https://drive.google.com/uc?id=${props.driveId}&export=view`}
                                            className={"document-image"}
                                            onLoad={imageLoaded}
                                            onError={() => setDocType("PDF")}
                                        />
                                    </div>
                                </Card>
                            )}
                            {docType === "PDF" && (
                                <div style={{ display: loading && "none", width: "100%", height: "100%" }}>
                                    <iframe
                                        // src={`https://drive.google.com/uc?id=${props.driveId}&export=preview`}
                                        // src={`https://drive.google.com/viewerng/viewer?embedded=true&url=https://drive.google.com/uc?id=${props.driveId}&export=view`}
                                        src={`https://drive.google.com/file/d/${props.driveId}/preview`}
                                        className={"document-image"}
                                        width={"100%"}
                                        height={"100%"}
                                        onLoad={imageLoaded}
                                        allow="clipboard-read; clipboard-write"
                                    ></iframe>
                                </div>
                            )}
                        </Card>
                        {mediaAd && (
                            <Card elevation={9} style={{ width: "100%", marginLeft: 5 }}>
                                {getTextField("Client", getValueOrEmptyString(client), "text")}
                                {
                                    editMode && suppliersLoaded ?
                                        getPlatformDropdown()
                                        : getTextField("Platform", getValueOrEmptyString(platform), "text")
                                }
                                {getTextField("Reference", getValueOrEmptyString(transactionId), "text")}
                                {
                                    editMode ?
                                        getDatePicker("Transaction Date", getValueOrEmptyString(transactionDate))
                                        : getTextField("Transaction Date", getValueOrEmptyString(transactionDate), "text")
                                }
                                {
                                    editMode ?
                                        getTextField("Amount NZD", getValueOrEmptyString(amount), "number")
                                        : getTextField("Amount NZD", getValueOrEmptyString(numberWithThousandsSeperator(amount), "text"))
                                }
                                {getTextField("Type", getValueOrEmptyString(type), "text")}
                                {getGstRateCodeCheckbox()}
                                {
                                    props.type === "Edit" && (
                                        <div
                                            id="media-ad-button-edit-modal-container"
                                            style={editMode ? { display: "none" } : {}}
                                        >
                                            {platform !== "No Supplier Found" ? (
                                                <MediaAdsButton
                                                    mediaAd={props.selectedAd}
                                                    setSnackBarProps={props.setSnackBarProps}
                                                    updateMediaAd={(value, key, receipt) => {
                                                        props.updateMediaAd(value, key, receipt);
                                                        props.setRowUpdating(true);
                                                        props.handleModal();
                                                    }}
                                                />
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        backgroundColor: "#702963",
                                                        color: "white"
                                                    }}
                                                    onClick={props.openNewMediaSupplierModal}
                                                >
                                                    Create Xero Contact
                                                </Button>
                                            )}
                                        </div>
                                    )
                                }
                            </Card>
                        )}
                    </div>
                </div>
            </div >
        </Modal >
    );
};

export default EditMediaAd;