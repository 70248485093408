import React, { useState, useEffect } from "react";
import Dropzone from 'react-dropzone';
import { makeStyles } from "@material-ui/core/styles";
import {
    Modal,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Button
} from "@material-ui/core";
import { Close, ArrowBackIos, Image } from "@material-ui/icons";
import "./SendSchedule.scss";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "auto",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        // minHeight: "auto",
        // minWidth: "25vW",
        // maxWidth: "80vW",
        maxHeight: "80vH",
        width: "auto",
        overflow: "auto",
    },
}));

const heightOptions = {
    small: "300px",
    medium: "500px",
    large: "800px",
    auto: "auto"
}

const widthOptions = {
    small: "300px",
    medium: "500px",
    large: "800px",
    auto: "auto"
}

export const CustomImageUpload = (props) => {
    const classes = useStyles();
    const [existing, setExisting] = useState();
    const [open, setOpen] = useState(false);
    const [imageFiles, setImageFiles] = useState([]);
    const [imgSrc, setImgSrc] = useState(null);
    const [width, setWidth] = useState(widthOptions["small"])
    const [height, setHeight] = useState(heightOptions["auto"])

    console.log("props for CustomImageUpload")
    console.log(props)

    const onDrop = (files) => {
        console.log("onDrop");
        console.log(files);
        files.map((file) => {
            Object.assign(file, {
                preview: URL.createObjectURL(file),
            })
            console.log("file")
            console.log(file)
            uploadImageCallBack(file);
        })
        setImageFiles(files);
    }

    const addImageFromState = () => {
        var alt = "";
        props.onChange(imgSrc, height, width, alt);
        clearState();
        setOpen(false);
    };

    const clearState = () => {
        setImgSrc(null);
        setImageFiles([]);
    }

    const uploadImageCallBack = (img) => {
        return new Promise(
            (resolve, reject) => {
                try {
                    console.log("img");
                    console.log(img);
                    var blob = img;

                    console.log('Blob - ', blob);

                    var reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = function () {
                        var base64String = reader.result;

                        var response = {
                            data: {
                                link: base64String,
                                type: img.type
                            }
                        }
                        setImgSrc(base64String)
                        resolve(response);
                    }

                }
                catch (error) {
                    reject(error);
                };
            }
        );
    }

    useEffect(() => {
        console.log("imageFiles")
        console.log(imageFiles)
    }, [imageFiles])

    return (
        <div>
            <Modal
                open={open}
                onClose={() => {
                    clearState();
                    setOpen(false);
                }}
                className={classes.modal}
            >
                <div>
                    <AppBar position="static" style={{ background: "#217F8B" }}>
                        <Toolbar variant="dense" style={{ paddingRight: 0 }}>
                            {existing ? (
                                <IconButton color="inherit">
                                    <ArrowBackIos onClick={() => setExisting(false)} />
                                </IconButton>
                            ) : (
                                    ""
                                )}
                            <Typography
                                variant="subtitle1"
                                align="center"
                                style={{ flexGrow: 1 }}
                            >
                                Add Image
                            </Typography>
                            <IconButton onClick={props.handleModal} color="inherit">
                                <Close onClick={() => setOpen(false)} />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.paper}>
                        <Dropzone
                            onDrop={onDrop}
                            multiple={false}
                            accept="image/*"
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} className={imgSrc === null ? "drop-email-image-here-nothing" : "drop-email-image-here-something"}>
                                    <input {...getInputProps()} />
                                    <span> Drag 'n' drop some files here, or click to select files</span>
                                    {
                                        imageFiles.length > 0 ?
                                            <div>
                                                <div>
                                                    {
                                                        imageFiles.map((file) => {
                                                            return (
                                                                <img height={height} width={width} src={file.preview} />
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div> : null
                                    }
                                </div>
                            )}
                        </Dropzone>
                        <div className="insert-image-size-button-container" style={imgSrc === null ? { display: "none" } : {}}>
                            <Button color="primary" variant="contained" size="small" onClick={() => setWidth(widthOptions["small"])}>Small</Button>
                            <Button color="primary" variant="contained" size="small" onClick={() => setWidth(widthOptions["medium"])}>Medium</Button>
                            <Button color="primary" variant="contained" size="small" onClick={() => setWidth(widthOptions["large"])}>Large</Button>
                            <Button color="primary" variant="contained" size="small" onClick={() => setWidth(widthOptions["auto"])}>Original</Button>
                        </div>
                        <div className="center" style={imgSrc === null ? { display: "none" } : {}}>
                            <Button
                                style={{
                                    backgroundColor: "#008000",
                                    color: "white"
                                }}
                                variant="contained"
                                size="medium"
                                fullwidth
                                onClick={() => addImageFromState()}
                            >
                                Use This Image
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Button
                size="small"
                variant="outlined"
                onClick={() => setOpen(true)}
            >
                <Image />
            </Button>
        </div>
    );
}
