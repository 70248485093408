import React from "react";
import PayableRow from "./PayableRow";

const PayablesList = ({
  payables,
  mediaSuppliers,
  companies,
  setSnackBarProps,
  searchRelatedMediaSpend,
  resetRelatedRecords,
  setSelectedPayable,
  handleUpdatePayablesList,
  setSelectedReconLines,
  removePayablefromList,
  addNewPayableToList,
  setIsClosingPayable,
  setIsLoadingPayables,
  selectedPayable,
  staff,
  projects,
  searchRelatedPurchaseOrder,
  resetPayables,
  selectedReconLines
}) => {
  return (
    <div className="reconciliation-centre-payables-list-root">
      {payables.map((payable, index) => (
        <PayableRow
          payable={payable}
          key={index}
          mediaSuppliers={mediaSuppliers}
          companies={companies}
          setSnackBarProps={setSnackBarProps}
          searchRelatedMediaSpend={searchRelatedMediaSpend}
          searchRelatedPurchaseOrder={searchRelatedPurchaseOrder}
          resetRelatedRecords={resetRelatedRecords}
          setSelectedPayable={setSelectedPayable}
          handleUpdatePayablesList={handleUpdatePayablesList}
          setSelectedReconLines={setSelectedReconLines}
          removePayablefromList={removePayablefromList}
          addNewPayableToList={addNewPayableToList}
          setIsClosingPayable={setIsClosingPayable}
          setIsLoadingPayables={setIsLoadingPayables}
          selectedPayable={selectedPayable}
          staff={staff}
          projects={projects}
          resetPayables={resetPayables}
          selectedReconLines={selectedReconLines}
        />
      ))}
    </div>
  );
};

export default PayablesList;
