import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";
import {
  Button,
  Snackbar,
  TextField,
  Paper,
  AppBar,
  Toolbar,
  Typography,
  Input,
  Select,
  FormControl,
  Fab,
  MenuItem,
  FormHelperText,
  IconButton,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  List,
  InputLabel,
  CircularProgress,
} from "@material-ui/core";
import "./Admin.css";
import Snacky from "../Shared/Snacky";
import { setSnackBarProps } from "../../actions/snackbar";
import { connect } from "react-redux";
import Loader from "../Loader";
import "./SMS.css";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const mergeTags = [
  { name: "Location Contact First Name", value: "{LocationContactFirstName}" },
  { name: "Location Contact Last Name", value: "{LocationContactLastName}" },
  { name: "Company Name", value: "{CompanyName}" },
  { name: "Project Name", value: "{ProjectName}" },
  { name: "Contractor First Name", value: "{ContractorFirstName}" },
  { name: "Contractor Last Name", value: "{ContractorLastName}" },
  { name: "Allocated Rates", value: "{AllocatedRates}" },
  { name: "Agent First Name", value: "{AgentFirstName}" },
  { name: "Agent Last Name", value: "{AgentLastName}" },
  { name: "Talent First Name", value: "{TalentFirstName}" },
  { name: "Talent Last Name", value: "{TalentLastName}" },
  { name: "Talent Agency", value: "{TalentAgency}" },
  { name: "Url", value: "{Url}" },
  { name: "First Name", value: "{FirstName}" },
  { name: "Start Time", value: "{StartTime}" },
  { name: "Shoot Address", value: "{Address}" },
  { name: "Last Name", value: "{LastName}" },
  { name: "Location Contact First Name", value: "{LocationContactFirstName}" },
  { name: "Link", value: "{Link}" },
];

const SMS = (props) => {
  const classes = useStyles();
  const [loadSms, setLoadSms] = useState(true);
  const [smsMessages, setSmsMessages] = useState();

  const [selectedSms, setSelectedSms] = useState(null);
  const [selectedSmsMessage, setSelectedSmsMessage] = useState(null);
  const [selectedDelay, setSelectedDelay] = useState(null);

  const [currentTag, setCurrentTag] = useState(null);
  const [showSaveLoader, setShowSaveLoader] = useState(false);

  const handleSaveSms = () => {
    if (Number.isNaN(parseInt(selectedDelay))) {
      props.setSnackBarProps("warning", "Please use a valid delay", true);
    } else {
      setShowSaveLoader(true);
      Axios.put(`Sms/PutSms/${selectedSms.id}`, {
        id: selectedSms.id,
        type: selectedSms.type,
        message: selectedSmsMessage,
        delay: parseInt(selectedDelay),
      })
        .then((res) => {
          console.log(res);
          setLoadSms(true);
          props.setSnackBarProps("success", "Template Saved", true);
        })
        .catch((e) => {
          console.log(e);
          props.setSnackBarProps("warning", "Failed to save template", true);
        })
        .finally(() => {
          setShowSaveLoader(false);
        });
    }
  };

  const renderSaveButton = () => {
    if (selectedSms !== null) {
      return (
        <div className="smsSaveButton">
          {showSaveLoader ? (
            <CircularProgress />
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleSaveSms()}
            >
              Save
            </Button>
          )}
        </div>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const insertMergeTag = (areaId, text) => {
    var txtarea = document.getElementById(areaId);
    var scrollPos = txtarea.scrollTop;
    var strPos = 0;
    var br =
      txtarea.selectionStart || txtarea.selectionStart == "0"
        ? "ff"
        : document.selection
        ? "ie"
        : false;
    if (br == "ie") {
      txtarea.focus();
      var range = document.selection.createRange();
      range.moveStart("character", -txtarea.value.length);
      strPos = range.text.length;
    } else if (br == "ff") strPos = txtarea.selectionStart;

    var front = txtarea.value.substring(0, strPos);
    var back = txtarea.value.substring(strPos, txtarea.value.length);
    txtarea.value = front + text + back;
    strPos = strPos + text.length;
    if (br == "ie") {
      txtarea.focus();
      var range = document.selection.createRange();
      range.moveStart("character", -txtarea.value.length);
      range.moveStart("character", strPos);
      range.moveEnd("character", 0);
      range.select();
    } else if (br == "ff") {
      txtarea.selectionStart = strPos;
      txtarea.selectionEnd = strPos;
      txtarea.focus();
    }
    txtarea.scrollTop = scrollPos;
    setSelectedSmsMessage(txtarea.value);
  };

  const getCursorPos = () => {
    var ctl = document.getElementById("sms-textfield");
    var startPos = ctl.selectionStart;
    var endPos = ctl.selectionEnd;
    console.log(startPos + ", " + endPos);
  };

  const handleDelayChange = (event) => {
    // var numbers = /^[-+]?[0-9]+$/;
    // if(event.target.value.match(numbers)){
    //   setSelectedDelay(parseInt(event.target.value));
    // }
    setSelectedDelay(event.target.value);
  };

  useEffect(() => {
    if (loadSms) {
      Axios.get(`Sms/GetSms`)
        .then((res) => {
          console.log("res.data");
          console.log(res.data);
          setSmsMessages(res.data);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoadSms(false);
        });
    }
  }, [loadSms]);

  useEffect(() => {
    console.log("Changed");
    console.log(selectedSmsMessage);
  }, [selectedSmsMessage]);

  if (smsMessages) {
    return (
      <main className={classes.content}>
        <div className="main-content">
          {props.snackbar.open && (
            <Snacky
              snackprops={props.snackbar}
              setSnackBarProps={props.setSnackBarProps}
            />
          )}
          <Typography variant="h4">SMS</Typography>
          <Grid container spacing={2}>
            <Grid item xs={3} md={3} lg={3}>
              <div>
                <Paper
                  style={{
                    maxHeight: "calc(100vh - 250px)",
                    minHeight: "calc(100vh - 250px)",
                    overflow: "auto",
                    marginTop: "30px",
                  }}
                >
                  <List component="nav">
                    {smsMessages.map((sms, index) => {
                      return (
                        <ListItem
                          key={sms.id}
                          button
                          className={
                            selectedSms !== null
                              ? sms.id === selectedSms.id
                                ? "selectedSms"
                                : ""
                              : ""
                          }
                          onClick={() => {
                            console.log(sms);
                            setSelectedSms(sms);
                            if (sms.delay === null) {
                              setSelectedDelay(0);
                            } else {
                              setSelectedDelay(sms.delay);
                            }
                            if (
                              sms.message === null ||
                              sms.message.trim() === ""
                            ) {
                              setSelectedSmsMessage("");
                            } else {
                              setSelectedSmsMessage(sms.message);
                            }
                          }}
                        >
                          <ListItemText primary={`${sms.type}`} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Paper>
              </div>
            </Grid>
            <Grid item xs={9} md={9} lg={9}>
              <Paper
                style={{
                  maxHeight: "calc(100vh - 200px)",
                  minHeight: "calc(100vh - 200px)",
                  overflow: "auto",
                  marginTop: "30px",
                  width: "100%",
                }}
              >
                <div style={{ margin: 10 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={4} md={4} lg={4}>
                      <Typography variant="h6">
                        Selected SMS:{" "}
                        {selectedSms !== null
                          ? selectedSms.type
                          : "Nothing Selected"}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={4} lg={4}>
                      {selectedSms !== null ? (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Typography variant="h6">
                            Insert Merge Tag:{" "}
                          </Typography>
                          <Select
                            value={currentTag}
                            onChange={(event) => {
                              //setCurrentTag(event.target.value)
                              insertMergeTag(
                                "sms-textfield",
                                event.target.value
                              );
                            }}
                          >
                            {mergeTags.map((tag) => {
                              return (
                                <MenuItem value={tag.value}>
                                  {tag.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </Grid>
                    <Grid item xs={4} md={4} lg={4}>
                      {selectedSms !== null ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Typography variant="h6" style={{ margin: 5 }}>
                            Delay In Minutes
                          </Typography>
                          <TextField
                            id="delay-textfield"
                            variant="outlined"
                            className="delayTextField"
                            value={selectedDelay}
                            onChange={handleDelayChange}
                          />
                        </div>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </Grid>
                  </Grid>
                </div>
                <div style={{ margin: 10 }}>
                  <TextField
                    id="sms-textfield"
                    multiline
                    fullWidth
                    disabled={selectedSms === null ? true : false}
                    variant="outlined"
                    className="smsTextArea"
                    value={selectedSmsMessage}
                    onChange={(event) => {
                      getCursorPos();
                      setSelectedSmsMessage(event.target.value);
                    }}
                    rowsMax={20}
                    rows={20}
                  />
                </div>
                {renderSaveButton()}
              </Paper>
            </Grid>
          </Grid>
        </div>
      </main>
    );
  } else {
    return <Loader />;
  }
};

const mapStateToProps = (state, ownProps) => ({
  snackbar: state.snackbar,
});

export default connect(mapStateToProps, {
  setSnackBarProps,
})(SMS);
