import { format, getDay } from "date-fns";

function alphabetically(ascending) {

  return function (a, b) {

    // equal items sort equally
    if (a.deadline === b.deadline) {
        return 0;
    }
    // nulls sort after anything else
    else if (a.deadline === null) {
        return 1;
    }
    else if (b.deadline === null) {
        return -1;
    }
    // otherwise, if we're ascending, lowest sorts first
    else if (ascending) {
        return a.deadline < b.deadline ? -1 : 1;
    }
    // if descending, highest sorts first
    else { 
        return a.deadline < b.deadline ? 1 : -1;
    }

  };

}

export function getFirstDeadline(outputs) {
  // if (outputs[0].projectFk === 136) {
  // }
  let undeliveredOutputs = outputs.filter((output) => output.delivered === 0);
  // outputs.sort((a, b) => (a.deadline > b.deadline ? 1 : -1));
  // return outputs[0].deadline;

  undeliveredOutputs.sort(alphabetically(true));
  console.log("_________outputs");
  console.log(undeliveredOutputs);
  return undeliveredOutputs.length === 0
    ? undefined
    : undeliveredOutputs[0].deadline;
}

export function convertDateOnly(inputFormat) {
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  var d = new Date(inputFormat);
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
}

export function convertDate(inputFormat) {
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  var d = new Date(inputFormat);

  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return (
    days[d.getDay()] +
    ", " +
    [pad(d.getDate()), pad(d.getMonth() + 1)].join("/")
  );
}

const returnMonthsFrom262String = (month) => {
  var months = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };
  return months[month];
};

export function removeDuplicates(items) {
  if (items) {
    const uniqueValues = [...new Set(JSON.parse(items))];
    if (uniqueValues.includes("undefined")) {
      let index = uniqueValues.indexOf("undefined");
      uniqueValues.splice(index, 1);
    }
    var uniqueItems = uniqueValues.join(", ");
    return uniqueItems;
  } else {
    return null;
  }
}
