import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import {
    Modal,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Grid,
    Button,
} from "@material-ui/core";
import { Close, ArrowBackIos } from "@material-ui/icons";
import "./SendSchedule.scss";
import { useUserContext } from "../../../../context/user/UserContext";
import { useAuth } from "../../../../context/auth/AuthContext";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        minHeight: "auto",
        maxHeight: "80vH",
        width: "40vW",
        overflow: "hidden",
    },
}));

const PreviewEmail = (props) => {
    const { user, } = useAuth();
    const classes = useStyles();
    const [checked, setChecked] = useState(false);
    const fullname = `${user.firstName} ${user.lastName}`;
    const email = user.email;

    useEffect(() => {
        if (checked) {
            document.getElementById("email-body").innerHTML = props.previewComponents.htmlBody;
        }
    }, [checked])

    useEffect(() => {
        //Allows components with ids to be set
        setChecked(true);
    }, [])

    return (
        <div>
            <Modal
                open={props.modalState}
                onClose={props.handleModal}
                className={classes.modal}
            >
                <div>
                    <AppBar position="static" style={{ background: "#217F8B" }}>
                        <Toolbar variant="dense" style={{ paddingRight: 0 }}>
                            <Typography
                                variant="subtitle1"
                                align="center"
                                style={{ flexGrow: 1 }}
                            >
                                Preview Email
                            </Typography>
                            <IconButton onClick={props.handleModal} color="inherit">
                                <Close />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className={`${classes.paper} arial`}>
                        <div className="arial">From: {`${fullname} <${email}>`}</div>
                        <div className="arial">To: {`${props.previewComponents.person.firstName} ${props.previewComponents.person.lastName} <${props.previewComponents.person.email}>`}</div>
                        <hr className="preview-hr" />
                        <div className="arial">{props.previewComponents.subject}</div>
                        <hr className="preview-hr" />
                        <div id="email-body"></div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

// export default PreviewEmail;

const mapStateToProps = (state, ownProps) => ({
});
export default connect(mapStateToProps, {
})(PreviewEmail);