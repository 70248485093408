import React from "react";
import { IconButton } from "@material-ui/core";
import { DescriptionOutlined, ArrowForward } from "@material-ui/icons";

const PrePostNotes = (props) => {
  let { scheduleItem, setActiveScheduleItem, setOpenPrePostNotes } = props;
  const styles = {
    icon: {
      width: 25,
      height: 25,
    },
  };
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
      }}
    >
      <div
        style={
          scheduleItem.prePostNotes !== "-" &&
          scheduleItem.prePostNotes !== null &&
          scheduleItem.prePostNotes.trim() !== ""
            ? {
                padding: "2px",
                fontSize: "10px",
                margin: "auto",
              }
            : {
                padding: "2px",
                fontSize: "10px",
                margin: "auto",
                color: "red",
              }
        }
      >
        {scheduleItem.prePostNotes !== "-" &&
        scheduleItem.prePostNotes !== null &&
        scheduleItem.prePostNotes.trim() !== "" ? (
          ""
        ) : (
          <ArrowForward />
        )}
      </div>
      <div>
        <IconButton
          style={{
            width: 30,
            height: 30,
            backgroundColor:
              scheduleItem.prePostNotes !== "-" &&
              scheduleItem.prePostNotes !== null &&
              scheduleItem.prePostNotes.trim() !== ""
                ? "#4dfa59"
                : "#c8c8c8",
            color: "white",
            margin: "auto",
            marginLeft: 10,
            marginRight: 10,
            padding: 0,
          }}
          onClick={() => {
            setActiveScheduleItem(scheduleItem);
            setOpenPrePostNotes(true);
          }}
        >
          <DescriptionOutlined style={styles.icon} />
        </IconButton>
      </div>
    </div>
  );
};

export default PrePostNotes;
