import React from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

const Snacky = (props) => {
  const { open, severity, text } = props.snackprops;
  const { setSnackBarProps } = props;
  if(!open){
    return "";
  } else {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={5000}
        onClose={() => setSnackBarProps({ open: false, severity: severity, text: text })}
      >
        <MuiAlert severity={severity}>{text}</MuiAlert>
      </Snackbar>
    );
  }
};

export default Snacky;
