import {
  IconButton,
  InputAdornment,
  Paper,
  TableContainer,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomTableHead from "../../Shared/CustomTableHead";
import axios from "axios";
import LoadingTableSkeleton from "../../Shared/LoadingTableSkeleton";
import { getComparator } from "../../../utils/TableUtils";
import "./purchaseOrderTable.scss";
import moment from "moment";
import { currencyFormat } from "../../../utils/GeneralUtils";
import DeleteIcon from "@material-ui/icons/Delete";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ConfirmationDialogActions from "../../Shared/ConfirmationDialogActions";
import GeneralDocumentViewer from "../../Shared/Dialog/GeneralDocumentViewer";
import { Search as SearchIcon } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";

const tableHeader = [
  {
    field: "date",
    name: "Date",
    fieldType: "date",
    alignment: "left",
  },
  {
    field: "xeroContactName",
    name: "Supplier",
    fieldType: "string",
    alignment: "left",
  },
  {
    field: "purchaseOrderNumber",
    name: "PO Number",
    fieldType: "string",
    alignment: "left",
  },
  {
    field: "serviceName",
    name: "Service Name",
    fieldType: "string",
    alignment: "left",
  },
  {
    field: "total",
    name: "Total",
    fieldType: "string",
    alignment: "left",
  },
  {
    field: "status",
    name: "Status",
    fieldType: "string",
    alignment: "left",
  },
  {
    field: "serviceName",
    name: "",
    fieldType: "string",
    alignment: "left",
  },
];

const PurchaseOrderTable = ({
  purchaseOrders,
  isLoading,
  handleSelectPurchaseOrder,
  removePurchaseOrder,
  keyword,
  setKeyword,
  searchPOs,
}) => {
  const [showDeleted, setShowDeleted] = useState(false);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState({
    field: "date",
    name: "Date",
    fieldType: "date",
  });

  const [selectedPo, setSelectedPo] = useState(null);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [isRemovingPo, setIsRemovingPo] = useState(false);
  const [purchaseOrderUrl, setPurchaseOrderUrl] = useState("");
  const [showDocumentViewer, setShowDocumentViewer] = useState(false);

  const handleOnRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleRowClick = (e, po) => {
    e.preventDefault();
    handleSelectPurchaseOrder(po);
  };

  const handleRemovePurchaseOrder = (e, po) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedPo(po);
    setOpenRemoveDialog(true);
  };

  const handleCloseRemoveDialog = () => {
    setSelectedPo(null);
    setOpenRemoveDialog(false);
    setIsRemovingPo(false);
  };

  const handleConfirmPoRemoval = async () => {
    setIsRemovingPo(true);
    await removePurchaseOrder(selectedPo);
    handleCloseRemoveDialog();
  };

  const handleOpenPdf = (e, poId) => {
    setPurchaseOrderUrl(`/purchaseOrder/GetPurchaseOrderAsPdf/${poId}`);
    setShowDocumentViewer(true);
  };

  const filterPos = () => {
    if (showDeleted) {
      return purchaseOrders;
    } else {
      return purchaseOrders.filter((po) => {
        return po.status !== "DELETED";
      });
    }
  };

  return (
    <div className="purchase-order-table-root-container">
      {openRemoveDialog && !!selectedPo && (
        <ConfirmationDialogActions
          title={"Are you sure?"}
          open={openRemoveDialog}
          contentText={
            isRemovingPo
              ? "Processing..."
              : "This action will remove the Purchase Order"
          }
          button1Action={handleCloseRemoveDialog}
          button1Text={"Cancel"}
          button2Text={"Confirm"}
          button2Action={handleConfirmPoRemoval}
        />
      )}
      {showDocumentViewer && purchaseOrderUrl && (
        <GeneralDocumentViewer
          modalState={showDocumentViewer}
          handleModal={() => {
            setPurchaseOrderUrl("");
            setShowDocumentViewer(false);
          }}
          headerColor="#000"
          docType={"PDF"}
          urlFile={purchaseOrderUrl}
        />
      )}
      <div className="purchase-order-table-filter-container">
        <TextField
          label="Global Search (Press Enter to Search)"
          variant="filled"
          fullWidth={true}
          onChange={(e) => setKeyword(e.target.value)}
          value={keyword}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              searchPOs();
            }
          }}
        />
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Typography
            variant="caption"
            className="clients-home-link-button"
            onClick={(e) => setShowDeleted(!showDeleted)}
          >
            {showDeleted ? "Hide Deleted" : "Show Hidden"}
          </Typography>
        </div>
      </div>
      <TableContainer component={Paper}>
        <table className="custom-table custom-table-border custom-table-hover socialites-projects-table">
          <CustomTableHead
            headers={tableHeader}
            orderBy={orderBy}
            order={order}
            onRequestSort={handleOnRequestSort}
          />
          <tbody>
            {filterPos()
              // .filter((c) =>
              //   c.xeroContactName.toLowerCase().includes(keyword.toLowerCase()) ||
              //   c.budgetCategory.toLowerCase().includes(keyword.toLowerCase()) ||
              //   c.purchaseOrderNumber.toLowerCase().includes(keyword.toLowerCase()) ||
              //   c.serviceName.toLowerCase().includes(keyword.toLowerCase())
              // )
              .sort(getComparator(order, orderBy))
              .map((po) => (
                <tr key={po?.id} className="purchase-order-table-tbody-tr">
                  <td>
                    <Typography variant="caption">
                      {moment(po.date).format("DD/MM/YYYY")}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="caption">
                      {po.xeroContactName}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="caption">
                      {po.purchaseOrderNumber}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="caption">{po.serviceName}</Typography>
                  </td>

                  <td>
                    <Typography variant="caption">
                      {currencyFormat(po.total, po.currency)}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="caption">{po.status}</Typography>
                  </td>
                  <td>
                    {po.status !== "DELETED" && (
                      <div>
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={(e) => handleRemovePurchaseOrder(e, po)}
                        >
                          <DeleteIcon />
                        </IconButton>

                        <IconButton
                          aria-label="pdf"
                          size="small"
                          onClick={(e) => handleOpenPdf(e, po.id)}
                        >
                          <PictureAsPdfIcon />
                        </IconButton>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            {isLoading && (
              <tr className="purchase-order-table-tbody-tr">
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </TableContainer>
      {!isLoading && purchaseOrders?.length === 0 && (
        <div className="purchase-order-table-no-records">
          <Typography variant="h6">Nothing here...</Typography>
        </div>
      )}
    </div>
  );
};

export default PurchaseOrderTable;
