import React, { useState } from "react";
import {
  Modal,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Paper,
  TextField,
  Button,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import axios from "axios";
import "./HealthAndSafety.css";

const AddCommonHazardPopup = (props) => {
  let initialState = {
    risk: "",
    mediation: "",
    scale: "",
    isGeneral: 1,
    description: "",
  };
  const [submit, setSubmit] = useState(false);
  const [newHazard, setNewHazard] = useState(initialState);
  const addNewHazard = () => {
    if (newHazard.risk !== "" && newHazard.description != "") {
      setSubmit(false);
      props.setShowHazardPopup(false);
      axios
        .post(`Hazards/PostHazard`, newHazard)
        .then((res) => {
          console.log(res);
          setNewHazard(initialState);
          props.setReloadCommonHazards(true);
        })
        .catch((err) => console.log(err));
    } else {
      setSubmit(true);
    }
  };
  return (
    <div>
      <Modal
        open={props.showHazardPopup}
        onClose={() => props.setShowHazardPopup(false)}
        className="add-contact-modal"
      >
        <div>
          <Paper>
            <AppBar className="add-contact-modal-appbar" position="static">
              <Toolbar
                variant="dense"
                style={{ paddingRight: 0, backgroundColor: "#000000" }}
              >
                <Typography
                  variant="subtitle1"
                  align="center"
                  style={{ flexGrow: 1 }}
                >
                  Enter Details
                </Typography>
                <IconButton
                  onClick={() => props.setShowHazardPopup(false)}
                  color="inherit"
                >
                  <Close onClick={() => props.setShowHazardPopup(false)} />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div className="add-contact-modal-text-input-container">
              <TextField
                className="modal-text-input"
                variant="outlined"
                error={newHazard.risk === "" && submit === true}
                value={newHazard.risk}
                onChange={(e) =>
                  setNewHazard({ ...newHazard, risk: e.target.value })
                }
                placeholder="Risk*"
              />
              <TextField
                className="modal-text-input"
                variant="outlined"
                error={newHazard.description === "" && submit === true}
                value={newHazard.description}
                onChange={(e) =>
                  setNewHazard({ ...newHazard, description: e.target.value })
                }
                placeholder="Description*"
              />
              <TextField
                className="modal-text-input"
                variant="outlined"
                value={newHazard.mediation}
                onChange={(e) =>
                  setNewHazard({ ...newHazard, mediation: e.target.value })
                }
                placeholder="Mediation"
              />
              <TextField
                className="modal-text-input"
                variant="outlined"
                value={newHazard.scale}
                onChange={(e) =>
                  setNewHazard({ ...newHazard, scale: e.target.value })
                }
                placeholder="Scale (Number)"
              />

              <Button
                className="submit-button"
                onClick={() => {
                  addNewHazard();
                }}
              >
                Submit
              </Button>
            </div>
          </Paper>
        </div>
      </Modal>
    </div>
  );
};

export default AddCommonHazardPopup;
