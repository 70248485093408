import axios from "axios";

export const getAllPeople = (id) => async (dispatch) => {
  try {
    var rOne = axios.get(`People/GetTalent`);
    var rTwo = axios.get(`People/GetContractors`);
    var rThree = axios.get(`People/GetStaff`);
    var rFour = axios.get(`People/GetClients/${id}`);
    axios.all([rOne, rTwo, rThree, rFour]).then(
      axios.spread((...responses) => {
        let talent = responses[0].data;
        let contractors = responses[1].data;
        let staff = responses[2].data;
        let clients = responses[3].data;
        dispatch({
          type: "GET_ALL_PEOPLE",
          payload: { talent, contractors, staff, clients },
        });
      })
    );
  } catch (err) {
    dispatch({
      type: "GET_ALL_PEOPLE_ERROR",
      payload: err,
    });
  }
};
export const getTalent = () => async (dispatch) => {
  try {
    var rOne = axios.get(`People/GetTalent`);
    axios.all([rOne]).then(
      axios.spread((...responses) => {
        let talent = responses[0].data;

        dispatch({
          type: "GET_TALENT",
          payload: talent,
        });
      })
    );
  } catch (err) {
    dispatch({
      type: "GET_TALENT_ERROR",
      payload: err,
    });
  }
};
export const getContractors = () => async (dispatch) => {
  try {
    var rOne = axios.get(`People/GetContractors`);
    axios.all([rOne]).then(
      axios.spread((...responses) => {
        let contractors = responses[0].data;

        dispatch({
          type: "GET_CONTRACTORS",
          payload: contractors,
        });
      })
    );
  } catch (err) {
    dispatch({
      type: "GET_CONTRACTORS_ERROR",
      payload: err,
    });
  }
};
export const getStaff = () => async (dispatch) => {
  try {
    var rOne = axios.get(`People/GetStaff`);
    axios.all([rOne]).then(
      axios.spread((...responses) => {
        let staff = responses[0].data;

        dispatch({
          type: "GET_STAFF",
          payload: staff,
        });
      })
    );
  } catch (err) {
    dispatch({
      type: "GET_STAFF_ERROR",
      payload: err,
    });
  }
};
export const getClients = (id) => async (dispatch) => {
  try {
    var rOne = axios.get(`People/GetClients/${id}`);
    axios.all([rOne]).then(
      axios.spread((...responses) => {
        let clients = responses[0].data;
        dispatch({
          type: "GET_CLIENTS",
          payload: clients,
        });
      })
    );
  } catch (err) {
    dispatch({
      type: "GET_CLIENTS_ERROR",
      payload: err,
    });
  }
};

export const getLowerCasePeople = (category) => async (dispatch) => {
  try {
    var res = await axios.get(
      `People/GetLowerCasePeopleForComparison/${category}`
    );
    dispatch({
      type: "GET_LOWERCASE_PEOPLE",
      payload: { data: res.data, category: "l" + category },
    });
  } catch (err) {
    dispatch({
      type: "GET_LOWERCASE_PEOPLE_ERROR",
      payload: err,
    });
  }
};
