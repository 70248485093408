import React, { Fragment, useState } from "react";
import "./personFinancialTab.scss";
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import CurrencyDropdownMenu from "../../../../CurrencyExchange/CurrencyDropdownMenu";
import { getCurrencies } from "../../../../../utils/GeneralUtils";
import SimpleAlerts from "../CompleteInfoNotification";
import AgentFinancialForm from "./AgentFinancialForm";
import ContractorFinancialForm from "./ContractorFinancialForm";
import CompanyFinancialForm from "./CompanyFinancialForm";
import PersonFinancialForm from "./PersonFinancialForm";
import { useUserContext } from "../../../../../context/user/UserContext";
import LockIcon from "@material-ui/icons/Lock";
import { useAuth } from "../../../../../context/auth/AuthContext";

const initialBankAccountDetails = {
  accountNumber: null,
  bankName: null,
  personId: null,
  companyId: null,
  countryCode: "NZ",
  accountName: null,
  branchCode: null,
  taxNumber: null,
  wtRate: null,
  isTaxRegistered: 1,
  invoiceAs: null,
};

const agentInitialState = {
  id: null,
  companyFk: null,
  companyFkNavigation: null,
  firstName: null,
  lastName: null,
  emailAddress: null,
  phoneNumber: null,
  enableSms: null,
};
const FinancialTab = ({
  person,
  handleSaveBankAccountDetails,
  setHasFinancialChanges,
  setShowAddAgentContact,
  handleUpdateAgent,
  setShowTalentAgencyPopUp,
  showTalentAgencyPopUp,
  showAddAgentContact,
}) => {
  const { user } = useAuth();

  const [localPerson, setLocalPerson] = useState(person);
  const [bankAccountDetails, setBankAccountDetails] = useState(
    person.bankAccountNavigation
  );
  const [agent, setAgent] = useState(person.agentFkNavigation);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [invoiceAs, setInvoiceAs] = useState(
    person.agentFkNavigation
      ? "agent"
      : person.bankAccountNavigation
      ? person.bankAccountNavigation.invoiceAs
      : null
  );

  const handleBankDetailsChange = (field, value) => {
    setBankAccountDetails({ ...bankAccountDetails, [field]: value });
    setHasFinancialChanges(true);
  };

  const handleChangeInvoiceAs = (value) => {
    setInvoiceAs(value);
    if (value !== "agent" && !bankAccountDetails) {
      setBankAccountDetails({ ...initialBankAccountDetails, invoiceAs: value });
    } else if (value !== "agent" && bankAccountDetails) {
      const wtRate = value === "contractor" ? bankAccountDetails.wtRate : null;
      setBankAccountDetails({
        ...bankAccountDetails,
        invoiceAs: value,
        wtRate: wtRate,
      });
    }
    setHasFinancialChanges(true);
  };

  const handleSelectAgent = (e, value, shouldSaveChanges = false) => {
    setAgent(value);
    if (shouldSaveChanges) {
      handleUpdateAgent(value);
      setIsEditing(false);
    } else {
      setHasFinancialChanges(true);
    }
  };

  const handleAgentOnChange = (field, value) => {
    setAgent({ ...agent, [field]: value });
    setHasFinancialChanges(true);
  };

  const handleSubmit = async () => {
    if (invoiceAs === "agent") {
      handleUpdateAgent(agent);
    } else {
      await handleSaveBankAccountDetails(
        localPerson.currency,
        bankAccountDetails
      );
    }
    setIsEditing(false);
  };

  return (
    <div className="person-financial-tab-root">
      <div className="person-financial-tab-flex-container person-financial-tab-flex-container-extra-gap">
        <Typography variant="h6">Currency:</Typography>
        <CurrencyDropdownMenu
          isReadOnly={!isEditing}
          size={"small"}
          originalCurrency={localPerson?.currency}
          handleChangeCurrency={(currencyObj) =>
            setLocalPerson({ ...localPerson, currency: currencyObj.value })
          }
          selectedCurrencies={getCurrencies().filter(
            (c) => c.value === "NZD" || c.value === "AUD"
          )}
        />
      </div>
      <div className="person-financial-tab-flex-container person-financial-tab-flex-container-extra-gap">
        <Typography variant="h6">Invoice as:</Typography>
        <Fragment>
          <div>
            <RadioGroup
              value={invoiceAs}
              onChange={(e) => handleChangeInvoiceAs(e.target.value)}
              row={true}
            >
              <FormControlLabel
                value={"contractor"}
                control={<Radio disabled={isEditing ? false : true} />}
                label="Contractor"
              />
              <FormControlLabel
                value={"company"}
                control={<Radio disabled={isEditing ? false : true} />}
                label="Company"
              />
              <FormControlLabel
                value={"agent"}
                control={<Radio disabled={isEditing ? false : true} />}
                label="Via Agent"
              />
            </RadioGroup>
          </div>
          <div>
            {invoiceAs ? (
              ""
            ) : isEditing ? (
              <SimpleAlerts text={"Select an option to start"} />
            ) : (
              <SimpleAlerts
                text={"Please go to edit mode and select an option to start"}
              />
            )}
          </div>
        </Fragment>
      </div>
      {!!invoiceAs &&
        (invoiceAs !== "agent" ? (
          <PersonFinancialForm
            isEditing={isEditing}
            person={localPerson}
            bankAccountDetails={bankAccountDetails}
            handleBankDetailsChange={handleBankDetailsChange}
            invoiceAs={invoiceAs}
          />
        ) : (
          <AgentFinancialForm
            agent={agent}
            isEditing={isEditing}
            handleSelectAgent={handleSelectAgent}
            setShowAddAgentContact={setShowAddAgentContact}
            handleAgentOnChange={handleAgentOnChange}
            setShowTalentAgencyPopUp={setShowTalentAgencyPopUp}
            showTalentAgencyPopUp={showTalentAgencyPopUp}
            showAddAgentContact={showAddAgentContact}
            currency={localPerson.currency}
            person={localPerson}
          />
        ))}

      {(user?.role === "Admin" || user?.role === "Super User") ? (
        <div className="person-financial-tab-footer">
          {isEditing ? (
            <Button
              variant="contained"
              size="small"
              disabled={isSaving}
              className={isSaving ? "default-button-grey" : "default-button"}
              onClick={handleSubmit}
            >
              Save Financial Changes
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              className={"default-button"}
              onClick={(e) => setIsEditing(true)}
            >
              Click To Edit
            </Button>
          )}
        </div>
      ) : (
        <div className="person-financial-tab-footer">
          <Button
            variant="contained"
            size="small"
            className={"default-button-grey"}
            disabled={true}
            startIcon={<LockIcon />}
          >
            No Permission To Update Financial Details
          </Button>
        </div>
      )}
    </div>
  );
};

export default FinancialTab;
