import React, { useEffect, useState } from "react";

import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import ApprovalRequestForm from "./ApprovalRequestForm";

const ApprovalRequestDialog = ({
  open,
  handleClose,
  project,
  setSnackBarProps,
  scheduleId,
  handleApprovalRequestSentCallback
}) => {
  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
        <div>
          <AppBar position="static" style={{ background: "#217F8B" }}>
            <Toolbar variant="dense" style={{ paddingRight: 0 }}>
              <Typography
                variant="subtitle1"
                align="center"
                style={{ flexGrow: 1 }}
              >
                Request Client Approval
              </Typography>
              <IconButton onClick={handleClose} color="inherit">
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>
        </div>
        <ApprovalRequestForm
          project={project}
          setSnackBarProps={setSnackBarProps}
          scheduleId={scheduleId}
          handleCloseForm={handleClose}
          handleApprovalRequestSentCallback={handleApprovalRequestSentCallback}
        />
      </Dialog>
    </div>
  );
};

export default ApprovalRequestDialog;
