import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";

const ProjectOutputsTable = ({
  project,
  outputs,
  selectedOutput,
  setSelectedOutput,
  handleDeliverOutputDialog,
  setSelectedProject,
  selectedProject,
}) => {
  const [showAllOutputs, setShowAllOutputs] = useState(false);
  const [filteredOutputs, setFilteredOutputs] = useState([]);

  useEffect(() => {
    if (outputs.length > 0) {
      setFilteredOutputs(outputs.filter((o) => o.delivered === 0));
    }
  }, [outputs]);

  const formatStringifiedStringArray = (stringifiedArray) => {
    var charArray = [...stringifiedArray];
    var finalString = "";
    charArray.forEach((c) => {
      if (c !== '"' && c !== "[" && c !== "]") {
        finalString = finalString + c;
      }
    });
    var arr = finalString.split(",");
    return [...new Set(arr)].join(", ");
  };

  const handleDeliverOutput = (output) => {
    if(selectedProject == null){
      setSelectedProject(project);
    }
    handleDeliverOutputDialog(output, project);
  };

  return (
    <TableContainer component={Paper}>
      <Table
        size="small"
        aria-label="customized table"
        style={{ tableLayout: "fixed" }}
      >
        <TableHead>
          <TableCell style={{width: 154}}>{`Creative Name`}</TableCell>
          <TableCell style={{width: 124}}>{`Platform`}</TableCell>
          <TableCell style={{width: 120}}>{`Duration`}</TableCell>
          <TableCell style={{width: 124}}>{`Deadline`}</TableCell>
          <TableCell style={{width: 130}}></TableCell>
        </TableHead>

        <TableBody>
          {showAllOutputs ? (
            outputs.map((output) => (
              <TableRow key={output.id}>
                <TableCell>{output.creativeName}</TableCell>
                <TableCell>
                  {formatStringifiedStringArray(output.platform)}
                </TableCell>
                <TableCell>{output.adDuration}</TableCell>
                <TableCell>
                  {output.deadline &&
                    format(new Date(output.deadline), "iiii, dd MMM yyyy")}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))
          ) : filteredOutputs.length > 0 ? (
            filteredOutputs.map((output) => (
              <TableRow key={output.id}>
                <TableCell>{output.creativeName}</TableCell>
                <TableCell>
                  {formatStringifiedStringArray(output.platform)}
                </TableCell>
                <TableCell>{output.adDuration}</TableCell>
                <TableCell>
                  {output.deadline &&
                    format(new Date(output.deadline), "iiii, dd MMM yyyy")}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={(e) => handleDeliverOutput(output.output)}
                  >
                    Deliver
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5}>All outputs have been delivered</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProjectOutputsTable;
