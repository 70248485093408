import React from "react";
import { Menu, MenuItem } from "@material-ui/core";

export const RightClickMenu = (props) => {
    let { handleCloseContext, setSnackBarProps, state, spamItem } = props;
    return (
        <Menu
            keepMounted
            open={state.mouseY !== null}
            onClose={handleCloseContext}
            anchorReference="anchorPosition"
            anchorPosition={
                state.mouseY !== null && state.mouseX !== null
                    ? { top: state.mouseY, left: state.mouseX }
                    : undefined
            }
        >
            <MenuItem disabled>
                {spamItem.firstName !== null &&
                    spamItem.lastName !== null
                    ? `${spamItem.firstName} ${spamItem.lastName}`
                    : `${spamItem.firstName}`}
            </MenuItem>
            <MenuItem
                onClick={() => {
                    setSnackBarProps("info", "Copied to clipboard", true);
                    navigator.clipboard.writeText(
                        spamItem.phone
                            ? spamItem.phone
                            : "No Phone Number"
                    );
                    handleCloseContext();
                }}
            >
                Copy Phone
            </MenuItem>
            <MenuItem
                onClick={() => {

                    setSnackBarProps("info", "Copied to clipboard", true);
                    navigator.clipboard.writeText(
                        spamItem.email
                            ? spamItem.email
                            : "No Email Address"
                    );
                    handleCloseContext();
                }}
            >
                Copy Email Address
            </MenuItem>
        </Menu>
    );
};
